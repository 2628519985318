import { Chip, Divider, Stack, TextField, Typography, Button, IconButton, Dialog } from "@mui/material";
import Modal from "components/common/Modal";
import CustomDropdown from "components/common/Dropdown";
import NegitiveIcon from "./EmployeeTimeOffDetails/Icons/NegitiveIcon";
import PositiveIcon from "./EmployeeTimeOffDetails/Icons/PositiveIcon";
import { leavesTypesDropdown } from "./Constants";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { usePermissions } from "components/Hook";
import { addSwappedLeaves } from "./TimeOffAdminServices/EmployeeTimeOffDetailsService";
import { showSnackbar } from "store/actions/Utility";

const AddSwappedLeaves = ({ open, closeModal, employeeLeavesData, onSuccess }) => {
  const { loggedInUser } = usePermissions();
  const dispatch = useDispatch();

  const getEmployeeDetails = useSelector((state) => state.GetEmployeeDetails.getEmployeeDetails);

  const [swappedLeave, setSwappedLeave] = useState({
    noOfLeaves: 0,
  });

  const [totalSwappedLeaveCount, setTotalSwappedLeaveCount] = useState(0);
  const [daysAdded, setDaysAdded] = useState(0);

  const handleIncrement = () => {
    const days = swappedLeave.noOfLeaves + 1;
    setSwappedLeave({
      ...swappedLeave,
      noOfLeaves: days,
    });
    setDaysAdded(days - totalSwappedLeaveCount);
  };

  const handleDecrement = () => {
    if (swappedLeave.noOfLeaves > 0) {
      const days = swappedLeave.noOfLeaves - 1;
      setSwappedLeave({
        ...swappedLeave,
        noOfLeaves: days,
      });
      setDaysAdded(days - totalSwappedLeaveCount);
    }
  };

  const handleTakenLeaveChange = (e) => {
    const value = parseInt(e.target.value, 10) || 0;
    setSwappedLeave({
      ...swappedLeave,
      noOfLeaves: value >= 0 ? value : 0,
    });
  };

  const handleSwapLeave = async () => {
    const payload = {
      noOfLeaves: swappedLeave?.noOfLeaves,
      employeeId: getEmployeeDetails?.payload?.employeeId,
      actionBy: loggedInUser?.userId,
      actionByName: loggedInUser?.name,
      actionByRole: loggedInUser?.permissions?.[0],
    };

    try {
      const res = await addSwappedLeaves(payload);
      if (res?.success) {
        onSuccess();
        dispatch(showSnackbar({ dispatch, type: "success", message: "Leave Created Successfully!" }));
        closeModal();
      } else {
        dispatch(showSnackbar({ dispatch, type: "error", message: res?.message }));
      }
    } catch (error) {
      dispatch(showSnackbar({ dispatch, type: "error", message: error?.message }));
    }
  };

  useEffect(() => {
    if (employeeLeavesData?.length) {
      let swappedLeaveData = employeeLeavesData?.find((leave) => leave?.leaveType === "Swapped");
      if (swappedLeaveData?.noOfLeaves < 0) {
        swappedLeaveData = {
          ...swappedLeaveData,
          noOfLeaves: 0,
        };
      }
      setSwappedLeave(swappedLeaveData);
      setTotalSwappedLeaveCount(swappedLeaveData?.noOfLeaves);
    }
  }, [employeeLeavesData]);

  return (
    <Dialog
      open={open}
      // title="Edit Leaves Taken"
      sx={{ "& .MuiDialog-paper": { pt: "31px", borderRadius: "12px !important", width: "334px" } }}
    >
      <div className="add-swaped-leaves-title">Add swapped leaves</div>

      <Divider sx={{ border: "1px solid #D9DAF2", my: "18px" }} />

      <div className="leave-taken-off-taken">Total swapped leaves</div>
      <div className="leave-taken-off-taken-description"> Add or remove the number of days from the total</div>

      <Stack direction="row" spacing={1} sx={{ mb: "16px", ml: "18px" }} alignItems="center">
        <IconButton onClick={handleDecrement} sx={{ p: "0px" }}>
          <NegitiveIcon />
        </IconButton>

        <input
          className="employee-taken-leaves-count"
          value={swappedLeave?.noOfLeaves}
          onChange={handleTakenLeaveChange}
        />
        <IconButton onClick={handleIncrement} sx={{ p: "0px" }}>
          <PositiveIcon />
        </IconButton>
      </Stack>

      <div className="swapped-days">{daysAdded} days added</div>

      <Divider sx={{ border: "1px solid #D9DAF2", my: "18px" }} />

      <Stack direction="row" spacing={1} sx={{ ml: "34px", mb: "25px" }}>
        <Button
          variant="text"
          sx={{
            borderRadius: "99px",
            height: "40px",
          }}
          onClick={() => closeModal()}
        >
          <Typography variant="h5" className="save-buttontext" color="#3D43BB">
            Cancel
          </Typography>
        </Button>

        <Button
          variant="contained"
          disableElevation
          sx={{
            borderRadius: "42px",
            width: "136px",
            height: "38px",
            background: "#131599",
          }}
          onClick={handleSwapLeave}
        >
          <Typography variant="h6" color="white" className="save-buttontext">
            Save Change
          </Typography>
        </Button>
      </Stack>
    </Dialog>
  );
};

export default AddSwappedLeaves;
