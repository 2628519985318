import { call, put, takeEvery } from "redux-saga/effects";
//actions
import {
    UserDetailsSuccess,
    UserDetailsFailure,
} from "../../actions/UserDetails";
//constant
import { USER_DETAILS } from "../../constants/UserDetails";
//request type
import getRequest from "../../../util/APIHelperGet";
import { baseUrl } from "../../../util/APIBaseUrl";

export const fetchUserDetailsRequest = async (actions) => {
    // eslint-disable-next-line no-return-await
    return await getRequest(`${baseUrl}/customer/summary`)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return error;
        });
};

function* fetchUserDetails(params) {
    try {
        let response = yield call(fetchUserDetailsRequest, params);
        yield put(UserDetailsSuccess(response));
    } catch (error) {
        console.error(error);
        yield put(UserDetailsFailure(error));
    }
}

export default function* rootSaga() {
    yield takeEvery(USER_DETAILS, fetchUserDetails);
}
