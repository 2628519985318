import { baseUrl } from "util/APIBaseUrl";
import getRequest from "util/APIHelperGet";
import postRequest from "util/APIHelperPost";

export const getEmployeePayoneerStatus = async () => {
  const { payload } = await getRequest(`${baseUrl}/payoneer/payee-status`);
  if (payload?.error) {
    throw new Error(payload?.error[0]?.description);
  }
  return payload;
};

export const getPayoneerRegistrationLink = async () => {
  const {
    payload: { registrationLink, error },
  } = await postRequest(`${baseUrl}/payoneer/create-registration-link`);
  if (error) {
    throw new Error(error[0]?.description);
  }
  return registrationLink;
};

export const getEmployeeBalance = async () => {
  const { payload } = await getRequest(`${baseUrl}/payoneer/payee-balance`);
  if (payload?.error) {
    throw new Error(payload?.error[0]?.description);
  }
  return payload;
};

export const employeePayoneerConsent = async () => {
  const {
    payload: { authorizationLink, error },
  } = await getRequest(`${baseUrl}/payoneer/authorization-link`);
  if (error) {
    throw new Error(error[0]?.description);
  }
  return authorizationLink;
};

export const revokePayoneerAccessToken = async () => {
  let {
    payload: { error },
  } = await getRequest(`${baseUrl}/payoneer/revoke-access-token`);
  if (error) {
    throw new Error(error[0]?.description);
  }
};

export const saveEmployeeAccessToken = async (code) => {
  let body = {
    code,
  };
  body = JSON.stringify(body);
  const request = `${baseUrl}/payoneer/payee-access-token`;
  let {
    payload: { error },
  } = await postRequest(request, body);
  if (error) {
    throw new Error(error[0]?.description);
  }
};
