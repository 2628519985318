import React from "react";
import PropTypes from "prop-types";
import { Button, CircularProgress } from "@mui/material";

import "./button.scss";

function CustomButton({
  label,
  onClick,
  variant = "contained",
  color = "primary",
  size = "medium",
  startIcon = null,
  endIcon = null,
  isDisabled = false,
  isLoading = false,
  isFullWidth = false,
  className = "",
  ...other
}) {
  function getColorClass() {
    if (isLoading) {
      return `button-${variant}-loading`;
    }
    return isDisabled ? `button-${variant}-disabled` : `button-${variant}-${color}`;
  }

  function getSizeClass() {
    switch (size) {
      case "small":
        return "button-small button-small-padding";
      case "large":
        return "button-large button-large-padding";
      default:
        return "button-medium button-medium-padding";
    }
  }

  return (
    <Button
      className={`${getColorClass()} ${getSizeClass()} ${className} button min-w-max-content`}
      size={size}
      variant={variant}
      disabled={isDisabled}
      onClick={!isLoading ? onClick : null}
      startIcon={startIcon}
      endIcon={endIcon}
      fullWidth={isFullWidth}
      {...other}
    >
      {isLoading ? <CircularProgress size={25} color="inherit" /> : label}
    </Button>
  );
}

CustomButton.propTypes = {
  label: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  variant: PropTypes.oneOf(["contained", "outlined", "text"]),
  color: PropTypes.oneOf([
    "inherit-white",
    "inherit-text",
    "primary",
    "secondary",
    "error",
    "info",
    "success",
    "warning",
  ]),
  size: PropTypes.oneOf(["small", "medium", "large"]),
  startIcon: PropTypes.node,
  endIcon: PropTypes.node,
  isDisabled: PropTypes.bool,
  isLoading: PropTypes.bool,
  isFullWidth: PropTypes.bool,
};

CustomButton.defaultProps = {
  variant: "contained",
  color: "primary",
  size: "medium",
  startIcon: null,
  endIcon: null,
  isDisabled: false,
  isLoading: false,
  isFullWidth: false,
};

export default CustomButton;
