import React from "react";

const SuccessIcon = () => {
  return (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M19.6087 6.48454L6.48352 19.6097L5.08737 18.2136L18.2125 5.08839H10.7223V3.11365H21.5834V13.9748H19.6087V6.48454Z"
        fill="#005048"
      />
    </svg>
  );
};

export default SuccessIcon;
