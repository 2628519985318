import { call, put, takeEvery } from "redux-saga/effects";
import {
  GoogleSigunpSuccess,
  GoogleSigunpFailure,
} from "../../actions/Google/Google_signup";
import { GOOGLE_SIGNUP } from "../../constants/Google/Google_signup";
import postRequest from "../../../util/APIHelperPost";
import { baseUrl } from "../../../util/APIBaseUrl";

export const fetchGoogleSignupRequest = async (actions) => {
  let body = {
    code: actions?.payload?.googleToken,
    google: true,
  };

  body = JSON.stringify(body);

  return await postRequest(
    `${baseUrl}/${actions.payload.request}`,
    body,
    true,
    actions.payload.method
  )
    .then((response) => response)
    .catch((error) => {
      throw error;
    });
};

function* fetchGoogleSignup(params) {
  try {
    let response = yield call(fetchGoogleSignupRequest, params);
    yield put(GoogleSigunpSuccess(response));
  } catch (error) {
    console.error(error);
    yield put(GoogleSigunpFailure(error));
  }
}

export default function* rootSaga() {
  yield takeEvery(GOOGLE_SIGNUP, fetchGoogleSignup);
}
