//employee constants
import { ADD_PROFILE_DATA } from "../../constants/Settings/AddProfileData";

const INIT_STATE = {
    addProfileData: {},
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case ADD_PROFILE_DATA:
            return {
                ...state,
                addProfileData: action.payload,
            };

        default:
            return state;
    }
};
