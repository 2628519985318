import React from "react";
// material ui
import { Box, Button, Grid, Typography } from "@mui/material";
// redux dispatch
import { useSelector } from "react-redux";
// Icons
import { AiOutlineEdit } from "react-icons/ai";
// router
import { useNavigate, useParams } from "react-router-dom";
// files
import { HiOutlineOfficeBuilding } from "react-icons/hi";
// common functions
import { convertDate, getValue } from "../../../CommonUtils";

function PersonalInfo() {
  const navigate = useNavigate();
  const params = useParams();
  // redux state
  const customerDetails = useSelector((state) => state.GetCustomerDetails.customerDetails);

  return (
    <>
      <Grid item container direction="row" justifyContent="space-between" alignItems="center" sx={{ pb: 3.5 }}>
        <Grid item>
          <Box display="flex" flexDirection="column" rowGap={1.25}>
            <HiOutlineOfficeBuilding color="secondary" size="27" />
            <Typography variant="h3" fontSize="15px">
              Company Information
            </Typography>
          </Box>
        </Grid>

        <Grid item textAlign="end" alignItems="flex-end">
          <Button
            size="small"
            id="edit"
            variant="contained"
            disableElevation
            color="primaryAlt"
            endIcon={<AiOutlineEdit />}
            sx={{
              borderRadius: "5px",
              // fontFamily: "Cairo-Medium",
              // fontWeight: "Bold",
            }}
            onClick={() => {
              if (customerDetails?.ok) {
                navigate("/main/addCustomer", {
                  state: {
                    navigatedFor: "editCustomer",
                    redirectTo: "details",
                    editCustomerId: params?.id,
                  },
                });
              }
            }}
          >
            <Typography variant="h5">Edit</Typography>
          </Button>
        </Grid>
      </Grid>

      <Grid item container direction="column" rowSpacing={1.5} xs={12}>
        <Grid item>
          <Box display="flex" alignItems="center" justifyContent="space-between">
            <Typography variant="h6" color="#8386A2" fontSize="13px">
              On Boarding Date
            </Typography>
            <Typography variant="h5" id="on-borading-date">
              {convertDate(customerDetails?.payload?.onBoardingDate)}
            </Typography>
          </Box>
        </Grid>

        <Grid item>
          <Box display="flex" alignItems="center" justifyContent="space-between">
            <Typography variant="h6" color="#8386A2" fontSize="13px">
              Country
            </Typography>
            <Typography variant="h5" id="country">
              {getValue(customerDetails?.payload?.country)}
            </Typography>
          </Box>
        </Grid>

        <Grid item>
          <Box display="flex" alignItems="center" justifyContent="space-between">
            <Typography variant="h6" color="#8386A2" fontSize="13px">
              Industry
            </Typography>
            <Typography variant="h5" id="industry">
              {getValue(customerDetails?.payload?.industry)}
            </Typography>
          </Box>
        </Grid>

        <Grid item>
          <Box display="flex" alignItems="start" justifyContent="space-between">
            <Typography variant="h6" color="#8386A2" fontSize="13px">
              Company Address
            </Typography>
            <Box sx={{ wordBreak: "break-all" }}>
              <Typography variant="h5" textAlign="end" id="address">
                {" "}
                {getValue(customerDetails?.payload?.addressLine1 ? customerDetails.payload?.addressLine1 : "-")}
              </Typography>
              <Typography variant="h5" textAlign="end" id="complete-address">
                {getValue(customerDetails?.payload?.city ? `${customerDetails.payload.city} , ` : "") +
                  (customerDetails?.payload?.state ? `${customerDetails.payload.state} , ` : "") +
                  (customerDetails?.payload?.zip ? `${customerDetails.payload?.zip} , ` : "") +
                  (customerDetails?.payload?.country ? customerDetails.payload?.country : "")}
              </Typography>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </>
  );
}

export default PersonalInfo;
