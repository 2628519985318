import {
    DROPDOWN_DATA,
    DROPDOWN_DATA_SUCCESS,
    DROPDOWN_DATA_FAILURE,
} from "../constants/DropdownData";

// for employee information
export const DropdownData = (data) => {
    return {
        type: DROPDOWN_DATA,
        payload: data,
    };
};
export const DropdownDataSuccess = (payload) => {
    return {
        type: DROPDOWN_DATA_SUCCESS,
        payload,
    };
};
export const DropdownDataFailure = (payload) => {
    return {
        type: DROPDOWN_DATA_FAILURE,
        payload,
    };
};
