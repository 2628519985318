import {
    RESET_GET_CUSTOMER_PAYMENT_BY_ID,
    GET_CUSTOMER_PAYMENT_BY_ID,
    GET_CUSTOMER_PAYMENT_BY_ID_SUCCESS,
    GET_CUSTOMER_PAYMENT_BY_ID_FAILURE,
} from "../../constants/Customers/GetCustomerPaymentById";

// for employee information
export const ResetGetCustomerPaymentById = (data) => {
    return {
        type: RESET_GET_CUSTOMER_PAYMENT_BY_ID,
        payload: data,
    };
};

export const GetCustomerPaymentById = (data) => {
    return {
        type: GET_CUSTOMER_PAYMENT_BY_ID,
        payload: data,
    };
};

export const GetCustomerPaymentByIdSuccess = (payload) => {
    return {
        type: GET_CUSTOMER_PAYMENT_BY_ID_SUCCESS,
        payload,
    };
};

export const GetCustomerPaymentByIdFailure = (payload) => {
    return {
        type: GET_CUSTOMER_PAYMENT_BY_ID_FAILURE,
        payload,
    };
};
