import { Typography } from "@mui/material";
import DoneIcon from "@mui/icons-material/Done";
import vivoErrorPage from "../../../assets/images/vivoErrorPage.png";
import Button from "components/Button/Button";
import EastIcon from "@mui/icons-material/East";

function ErrorPage({ onStartNow }) {
  return (
    <div className="errorPageParentDiv">
      <div className="errorPageExperience">
        <div>
          <img src={vivoErrorPage} className="vivoErrorPageExperience" />
        </div>
        <Typography
          variant="h4"
          gutterBottom
          sx={{
            color: "#292A3D",
            textAlign: "center",
            fontFamily: "Poppins",
            fontSize: "22px",
            fontStyle: "normal",
            fontWeight: 600,
            lineHeight: "31px",
            letterSpacing: "0.1px",
          }}
        >
          Oops!
        </Typography>
        <Typography
          paragraph
          sx={{
            color: "#52537A",
            textAlign: "center",
            fontFamily: "Poppins",
            fontSize: "14px",
            fontStyle: "normal",
            fontWeight: 400,
            lineHeight: "normal",
            letterSpacing: "0.1px",
          }}
        >
          We were unable to load this information. Please reload this page. If the issue persists, contact your account
          manager.
        </Typography>

        <Button variant="secondary" size={"md"} onClick={() => window.location.reload()}>
          Reload page
        </Button>
      </div>
    </div>
  );
}

export default ErrorPage;
