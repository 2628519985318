import React, { useMemo } from "react";
import { useSelector } from "react-redux";

import "./EarningsBanner.css";
import { usePermissions } from "components/Hook.js";
import getRequest from "util/APIHelperGet";
import { baseUrl } from "util/APIBaseUrl";
import { get } from "react-hook-form";
import { convertDate } from "components/Screens/CommonUtils";

const EarningsBanner = (props) => {
  const salaryInfo = useSelector((state) => state.SalaryInfo);
  const { loggedInUser } = usePermissions();

  const payrollData = useSelector((state) => state?.EmployeeSalaryDetails?.employeeSalaryDetails);

  const latestPayrollExecuted = useMemo(() => {
    if (payrollData?.payload?.rows?.length)
      return payrollData?.payload?.rows.filter((payroll) => payroll.status === "paid");
    return [];
  }, [payrollData]);

  const downloadSlip = async () => {
    const response = await getRequest(
      `${baseUrl}/employee/${loggedInUser?.userId}/payroll/${
        latestPayrollExecuted?.[latestPayrollExecuted?.length - 1]?.payrollId
      }?action=pdf`,
      true,
      "export"
    );

    if (!response?.ok) {
      return;
    }
    const url = window.URL.createObjectURL(response?.payload);
    const link = document.createElement("a");
    link.style.display = "none";
    link.href = url;

    link.download = "Salary Slip";
    document.body.appendChild(link);
    link.click();
    window.URL.revokeObjectURL(url);
    // alert("your file has downloaded!");
  };

  return (
    <>
      <div className="banner-conatiner">
        <img src={"../assets/Images/finance.svg"} className="banner-finance-img" />
        <div className="banner-text-container">
          <div className="banner-text-typography">
            {`Have a great month, ${props.userDetails.name}! 
            ${latestPayrollExecuted[latestPayrollExecuted?.length - 1]?.status === "paid" ? "You got paid" : ""}`}
            {latestPayrollExecuted[latestPayrollExecuted?.length - 1]?.paidOn
              ? ` on
              ${convertDate(latestPayrollExecuted[latestPayrollExecuted?.length - 1]?.paidOn)}
              `
              : null}
          </div>
          <div>
            {latestPayrollExecuted[latestPayrollExecuted?.length - 1]?.status === "paid" ? (
              <button className="banner-text-btn" onClick={downloadSlip}>
                Download payslip
              </button>
            ) : null}
          </div>
        </div>
      </div>
    </>
  );
};

export default EarningsBanner;
