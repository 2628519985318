import { call, put, takeEvery } from "redux-saga/effects";
//actions
import {
  PayrollSummarySuccess,
  PayrollSummaryFailure,
} from "../../actions/PayrollSummary";
//constant
import { PAYROLL_SUMMARY } from "../../constants/PayrollSummary";
//request type
import getRequest from "../../../util/APIHelperGet";
import { baseUrl } from "../../../util/APIBaseUrl";

export const fetchPayrollSummaryRequest = async (actions) => {
  return await getRequest(
    `${baseUrl}/customer/${actions.payload.customerID}/payrollSummary?` +
      new URLSearchParams({
        limit: actions.payload.rowsPerPage,
      })
  )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

function* fetchPayrollSummary(params) {
  try {
    let response = yield call(fetchPayrollSummaryRequest, params);
    yield put(PayrollSummarySuccess(response));
  } catch (error) {
    console.error(error);
    yield put(PayrollSummaryFailure(error));
  }
}

export default function* rootSaga() {
  yield takeEvery(PAYROLL_SUMMARY, fetchPayrollSummary);
}
