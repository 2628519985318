import {
  RESET_GOOGLE_SIGNUP,
  GOOGLE_SIGNUP,
  GOOGLE_SIGNUP_SUCCESS,
  GOOGLE_SIGNUP_FAILURE,
} from "../../constants/Google/Google_signup";

// for user authentication

export const ResetGoogleSigunp = (data) => {
  return {
    type: RESET_GOOGLE_SIGNUP,
    payload: data,
  };
};

export const GoogleSigunp = (data) => {
  return {
    type: GOOGLE_SIGNUP,
    payload: data,
  };
};
export const GoogleSigunpSuccess = (payload) => {
  return {
    type: GOOGLE_SIGNUP_SUCCESS,
    payload,
  };
};
export const GoogleSigunpFailure = (payload) => {
  return {
    type: GOOGLE_SIGNUP_FAILURE,
    payload,
  };
};
