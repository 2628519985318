//employee constants
import { RESET_SHOW_SNACKBAR, SHOW_SNACKBAR } from '../../constants/Snackbar';

const INIT_STATE = {
  snackbar: {
    open: false,
    type: '',
    message: '',
  },
};

export default (payload = INIT_STATE, action) => {
  switch (action.type) {
    case SHOW_SNACKBAR:
      return {
        snackbar: {
          open: true,
          type: action?.payload?.type,
          message: action?.payload?.message,
        },
      };

    case RESET_SHOW_SNACKBAR:
      return INIT_STATE;

    default:
      return payload;
  }
};
