import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Grid, Typography, Box } from "@mui/material";
import { Helmet } from "react-helmet";

import PageHeader from "components/common/PageHeader";

import { GetPlatformConfig } from "store/actions/PlatformConfig";

import "../termsandpolicy.css";

const CustomerPrivacyPolicy = () => {
  const dispatch = useDispatch();
  const platformConfig = useSelector((state) => state.PlatformConfig.platformConfig);
  const loadingplatformConfig = useSelector((state) => state.PlatformConfig.loadingplatformConfig);

  useEffect(() => {
    if (!platformConfig?.payload && !loadingplatformConfig) {
      dispatch(GetPlatformConfig());
    }
  }, []);

  return (
    <>
      <Helmet>
        <title>Edge | Customer Policy</title>
      </Helmet>
      <Box sx={{ overflow: "auto" }}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <PageHeader title={"Privacy Policy"} subTitle={"Last Updated: January 31, 2024"} />
          </Grid>
          <Grid item xs={12} container justifyContent="center" alignItems="center">
            <Grid item lg={8} md={8} sm={10} xs={10} sx={{ marginY: 6 }}>
              <Typography className="label-terms">
                Welcome to onedge.co (the <b className="label-terms">“Edge Platform”</b>) which is owned and operated by
                Edge Services & Solutions LLC and our affiliated entities (
                <b className="label-terms">“Edge”, “Company”, “we”</b> or <b className="label-terms">“us”</b>).We
                respect your privacy and are committed to protecting it through compliance with this Privacy Policy.
              </Typography>
              <br />
              <Typography className="label-terms">
                This Privacy Policy describes and governs the manner in which we collect, use, maintain and disclose
                information about you when you download, install, register with, access, or use the Edge Platform and
                our other online services (collectively, the <b className="label-terms">“Services”</b>).
              </Typography>
              <br />
              <Typography className="label-terms">
                Please read this Privacy Policy carefully before you start to use the Services. By accessing and/or
                using the Services, you accept and agree to be bound and abide by this Privacy Policy and our Terms of
                Service available at{" "}
                <a href={platformConfig?.payload?.TERMS_URL} className="link-class" target="blank">
                  {platformConfig?.payload?.TERMS_URL}
                </a>{" "}
                (the <b className="label-terms">“Terms of Service”</b>) incorporated herein by reference and to comply
                with all applicable laws, rules and regulations (collectively,{" "}
                <b className="label-terms">“Applicable Law”</b>). If you do not want to agree to this Privacy Policy and
                the Terms of Service, you must not access or use the Services.
              </Typography>
              <br />
              <Typography className="label-terms">
                <span className="terms-list-title">Personal Information</span>
              </Typography>
              <Typography className="label-terms">
                We may collect personal information from you in a variety of ways, including the following key
                categories and types of personal information:
                <ol start="1">
                  <li>
                    <Typography className="terms-list-items">
                      <b className="terms-list-text2">General personal information: </b>
                      Name, username and password used as login credentials, address, email address and phone number,
                      date of birth, and any other personal information requested by our team to comply with applicable
                      laws.
                    </Typography>
                  </li>
                  <li>
                    {" "}
                    <Typography className="terms-list-items">
                      <b className="terms-list-text2">Professional or employment-related information: </b>
                      Professional or employment information shared by you, including positions you have held, previous
                      employers, and your past educational experience.
                    </Typography>
                  </li>
                  <li>
                    {" "}
                    <Typography className="terms-list-items">
                      <b className="terms-list-text2">Information you upload: </b>
                      Any content you provide or upload using our Services.
                    </Typography>
                  </li>
                  <li>
                    {" "}
                    <Typography className="terms-list-items">
                      <b className="terms-list-text2">Information about your transaction: </b>
                      We will collect information from use of the Services and information related to the fees we charge
                      for use of our platform, including information you provide to us when you use our website and/or
                      other online services to purchase our Services, including but not limited to general transaction
                      details and timing related to your transaction.
                    </Typography>
                  </li>

                  <li>
                    {" "}
                    <Typography className="terms-list-items">
                      <b className="terms-list-text2">Geolocation data: </b>
                      The general region or area from which you access our Services.
                    </Typography>
                  </li>

                  <li>
                    {" "}
                    <Typography className="terms-list-items">
                      <b className="terms-list-text2">Internet or other online activity: </b>
                      Referral channels that led you to our Services, browsing and click history, device information,
                      browser information, operating system information, IP address, page views, button clicks, and
                      information about how you navigate our Services.
                    </Typography>
                  </li>

                  <li>
                    {" "}
                    <Typography className="terms-list-items">
                      <b className="terms-list-text2">Inferences: </b>
                      We may make inferences based on the information we collect.
                    </Typography>
                  </li>

                  <li>
                    {" "}
                    <Typography className="terms-list-items">
                      <b className="terms-list-text2">Information from public sources: </b>
                      We may obtain information about you and your business or project on the internet or through
                      offline sources and other public or commercial sources.
                    </Typography>
                  </li>
                </ol>
              </Typography>
              <br />

              <Typography className="label-terms">
                <span className="terms-list-title">How We Use Collected Information</span>
              </Typography>
              <Typography className="label-terms">
                We may collect and use your personal information for the following purposes:
                <ol start="1">
                  <li>
                    {" "}
                    <Typography className="terms-list-items">
                      <b className="terms-list-text2">To provide and improve customer service. </b>
                      We use your contact information to answer your questions and respond to your requests and
                      inquiries, provide you technical support, notify you of changes to the Services, and maintain and
                      improve functionality of our Services.
                    </Typography>
                  </li>
                  <li>
                    {" "}
                    <Typography className="terms-list-items">
                      <b className="terms-list-text2">To provide the Services. </b>
                      We use personal information to provide you the Services. For example, we may use your personal
                      information to enable academic and other professional associates or referees to create reference
                      letters using our Services.
                    </Typography>
                  </li>
                  <li>
                    {" "}
                    <Typography className="terms-list-items">
                      <b className="terms-list-text2">For analytical purposes. </b>
                      We use aggregated information collected detailing your internet activity, browsing history and
                      geolocation data to analyze preferences, trends and statistics relating to the usage of our
                      Services. We may also use your activity on our Services in an aggregate way in order to improve
                      our Services. We may also use your information to protect the Company, our Services and our
                      website, and to prevent fraud, theft and misconduct.
                    </Typography>
                  </li>
                  <li>
                    {" "}
                    <Typography className="terms-list-items">
                      <b className="terms-list-text2">For relevant updates, marketing and promotional purposes. </b>
                      We use your contact information, employment-related information, other personal information and
                      various inferences described above to send you relevant articles, blog posts, newsletters,
                      podcasts, offers, products and other information we think may be relevant to you and to notify you
                      of changes to this Privacy Policy. We may also use information about your use of our Services in
                      an anonymized and aggregate way to analyze trends and statistics and to promote and market our
                      Services.
                    </Typography>
                  </li>

                  <li>
                    {" "}
                    <Typography className="terms-list-items">
                      <b className="terms-list-text2">To ensure compliance. </b>
                      We use your personal information to enforce and comply with the law, including to conduct an
                      investigation, to protect the property and rights belonging to us or a third party, to protect the
                      safety of the public or any person, or to prevent or stop activity we may consider to be, or to
                      pose a risk of being, illegal, fraudulent, unethical or legally actionable activity.
                    </Typography>
                  </li>
                </ol>
              </Typography>
              <br />

              <Typography className="label-terms">
                <span className="terms-list-title">How We Collect Your Personal Information</span>
              </Typography>

              <Typography className="label-terms">
                We collect personal information from the following sources:
                <ol start="1">
                  <li>
                    {" "}
                    <Typography className="terms-list-items">
                      <b className="terms-list-text2">To provide and improve customer service. </b>
                      We use your contact information to answer your questions and respond to your requests and
                      inquiries, provide you technical support, notify you of changes to the Services, and maintain and
                      improve functionality of our Services.
                    </Typography>
                    <br />
                  </li>
                  <li>
                    {" "}
                    <Typography className="terms-list-items">
                      <b className="terms-list-text2">Directly. </b>
                      We collect personal information directly from you. When you register for the Services, submit
                      information in an online form, request information from us, upload content to the platform, or
                      otherwise communicate with us or our support personnel, you may provide us with information, for
                      example, your name, email address, and personal information you wish to be included in a reference
                      letter. By providing us with this information, you consent to your information being collected,
                      used, disclosed, processed and stored by us in accordance with this Privacy Policy.
                    </Typography>
                    <br />
                  </li>
                  <li>
                    {" "}
                    <Typography className="terms-list-items">
                      <b className="terms-list-text2">Passively. </b>
                      We and our service providers collect personal information from you passively from the internet and
                      other automatic data collection tools, such as cookies and web beacons, which are summarized
                      below:
                    </Typography>
                    <br />
                    <ol type="a">
                      <li className="terms-list-text">
                        <b>Cookies (or browser cookies).</b> A cookie is a small file placed on the hard drive of your
                        computer. You may refuse to accept browser cookies by activating the appropriate setting on your
                        browser. However, if you select this setting you may be unable to access certain parts of our
                        website. Unless you have adjusted your browser setting so that it will refuse cookies, our
                        system will issue cookies when you direct your browser to our website.
                      </li>
                      <br />
                      <li className="terms-list-text">
                        <b> Web Beacons.</b> Pages of our website may contain small electronic files known as web
                        beacons (also referred to as clear gifs, pixel tags, and single-pixel gifs) that permit the
                        Company, for example, to count users who have visited those pages or and for other related
                        website statistics (for example, recording the popularity of certain website content and
                        verifying system and server integrity).
                      </li>
                      <br />
                      <li className="terms-list-text">
                        <b> Tracking Tools.</b> We use the following tracking tools:
                      </li>
                      <br />
                      <ul>
                        <li className="terms-list-text">
                          <b className="link-class">Google Analytics and Adwords: </b>
                          We use Google tools such as Google Analytics and Google Adwords to understand how users use
                          our website. These analytics service providers use cookies or other tracking technologies to
                          help us analyze how users interact with and use our Services, compile reports about activity
                          on our website, and provide us other services. The technologies may collect information such
                          as your IP address, time of visit, whether you are a return visitor, any referring website,
                          general location, and other information. Google Analytics provides an opt-out for its tool{" "}
                          <a
                            href="https://tools.google.com/dlpage/gaoptout"
                            style={{ color: "black" }}
                            className="link-class"
                            target="blank"
                          >
                            here.
                          </a>
                        </li>
                        <li className="terms-list-text">
                          <b className="link-class">Heap: </b>
                          We use services provided by Heap Inc. (<b className="label-terms">“Heap”</b>) to monitor how
                          users interact with our Services for the purposes of improving and understanding the nature of
                          how users use our products. Heap may collect anonymous data on our users’ interactions within
                          our website, which includes pages users visit, how users interact with our website, and users'
                          IP address and users' device information (device model, operating system, and version). For
                          further details, please see Heap's privacy policy at{" "}
                          <a href="https://www.heap.io/privacy." className="link-class" target="blank">
                            https://www.heap.io/privacy.
                          </a>
                        </li>
                        <li className="terms-list-text">
                          <b className="link-class">Other tracking tools: </b>
                          We may deploy other automatic tracking tools in the future. Similar to the tools we currently
                          use, such future tracking tools will track data about our visitors only in the aggregate and
                          on an anonymized basis and will not reveal personal information about you.
                        </li>
                      </ul>
                    </ol>
                  </li>
                </ol>
              </Typography>
              <br />
              <Typography className="label-terms">
                <span className="terms-list-title">When We Share Your Personal Information with Third Parties</span>
                <ol start="1">
                  <li>
                    {" "}
                    <Typography className="terms-list-items">
                      <b className="terms-list-text2">With the consent of the user. </b>
                      We may share your personal information with third parties with your consent or as requested by
                      you, or in connection with interactions you engage in through the Services.
                    </Typography>
                  </li>
                  <br />
                  <li>
                    {" "}
                    <Typography className="terms-list-items">
                      <b className="terms-list-text2">In connection with payments. </b>
                      We use a third-party payment service provided by Stripe, Inc. (
                      <b className="label-terms">“Stripe”</b>), to process all payments made through the Services, and
                      for related analytics and business services. If you make a payment through the Services, you will
                      receive an email confirmation automatically generated by stripe.com. Any personal information,
                      including credit card and other payment information, that you provide through Stripe’s website may
                      be collected by Stripe and by us for purposes of processing the payment, and will be subject to
                      Stripe’s privacy policy. Stripe collects identifying information about the devices that connect to
                      its services. Stripe uses this information to operate and improve the services it provides to us,
                      including for fraud detection. We have no control over, and shall not be responsible for, Stripe’s
                      use of information collected through Stripe’s website. PLEASE NOTE THAT THIS POLICY DOES NOT
                      ADDRESS THE COLLECTION AND USE OF INFORMATION THROUGH STRIPE’S WEBSITE OR STRIPE’S PRIVACY OR
                      INFORMATION PRACTICES. You can learn more about Stripe and read its privacy policy at{" "}
                      <a href="https://stripe.com/privacy." className="link-class" target="blank">
                        https://stripe.com/privacy.
                      </a>{" "}
                      We also use Plaid Inc. (<b className="label-terms">“Plaid”</b>), to facilitate communication
                      between your bank and credit card provider for purposes of facilitating transactions made through
                      the Services, and for related analytics and business services. Any personal information, including
                      credit card and other payment information, that you provide through Plaid’s website may be
                      collected by Plaid and by us for purposes of processing the payment, and will be subject to
                      Plaid’s privacy policy. Plaid collects identifying information about the devices that connect to
                      its services. Plaid uses this information to operate and improve the services it provides to us,
                      including for fraud detection. We have no control over, and shall not be responsible for, Plaid’s
                      use of information collected through Plaid’s website. PLEASE NOTE THAT THIS POLICY DOES NOT
                      ADDRESS THE COLLECTION AND USE OF INFORMATION THROUGH PLAID’S WEBSITE OR PLAID’S PRIVACY OR
                      INFORMATION PRACTICES. You can learn more about Plaid and read its privacy policy at{" "}
                      <a href="https://plaid.com/legal/#consumers." className="link-class" target="blank">
                        https://plaid.com/legal/#consumers.
                      </a>
                    </Typography>
                  </li>
                  <br />
                  <li>
                    {" "}
                    <Typography className="terms-list-items">
                      <b className="terms-list-text2">With service providers who take action on our behalf. </b>
                      For example, we may share general personal information with third-party service providers to
                      facilitate the usage of our Services or we may share information with vendors and contractors who
                      conduct email marketing for us or who assist us with developing and providing the Services and
                      customer support. Such service providers may reside outside the United States and in countries
                      that may not provide the same level of protections as the laws of the United States.
                    </Typography>
                  </li>
                  <br />
                  <li>
                    {" "}
                    <Typography className="terms-list-items">
                      <b className="terms-list-text2">To comply with applicable law. </b>
                      We may share your information with the appropriate authorities without your consent if we believe
                      disclosure is in accordance with, or required by, any applicable law, including in response to
                      lawful requests by public authorities to provide information to deter and combat fraud,
                      money-laundering, securities violation, data and privacy breaches or for other legal purposes.
                    </Typography>
                  </li>
                  <br />
                  <li>
                    {" "}
                    <Typography className="terms-list-items">
                      <b className="terms-list-text2">To protect the rights and property of the Company. </b>
                      We may also share your personal information with third parties to protect the property and rights
                      belonging to us or a third party, to protect the safety of the public or any person, or to prevent
                      or stop activity we may consider to be, or to pose a risk of being, illegal, fraudulent, unethical
                      or legally actionable activity.
                    </Typography>
                  </li>
                  <br />
                  <li>
                    {" "}
                    <Typography className="terms-list-items">
                      <b className="terms-list-text2">In connection with sale of the Company. </b>
                      In the event of a reorganization, merger, or sale we may transfer your personal information we
                      collect to the relevant third party.
                    </Typography>
                  </li>
                </ol>
              </Typography>
              <br />
              <Typography className="label-terms">
                <span className="terms-list-title">Third-Party Links and Websites</span>
              </Typography>
              <Typography className="label-terms">
                Our Services may contain advertising, links or other content from the websites and services of our
                partners, suppliers, advertisers, sponsors, licensors and other third parties and we use third-party
                payment processors to facilitate all payments between users of our Services (collectively, the
                “Third-Party Platform”). We do not control the content or links that appear on these Third-Party
                Platforms and are not responsible for the practices employed by such Third-Party Platform. In addition,
                these Third-Party Platforms may have their own privacy policies and customer service policies. Browsing
                and interacting on any of these Third-Party Platform, are subject to such Third-Party Platform’ own
                terms and policies.
              </Typography>
              <br />

              <Typography className="label-terms">
                <span className="terms-list-title">Our Content on Third-Party Platform</span>
              </Typography>
              <Typography className="label-terms">
                Our Services may be provided on or hosted on a third-party platform or otherwise make use of Third-Party
                Platform. We do not control the content or links that appear on these Third-Party Platform and are not
                responsible for the practices employed by such Third-Party Platform. Your browsing and interacting on
                any of these Third-Party Platform are subject to such Third-Party Platform’ own terms and policies. Any
                visit you make to those other services is at your own risk. Such third parties’ use of any information
                you share is governed by the third party’s privacy policy. The Services may also use third-party service
                remarketing services to advertise to previous visitors to the Services on such Third-Party Platform.
                Such third parties may use tracking technology (e.g., cookies) to serve ads to you based on your past
                activity on our Services and other websites and applications, subject to their own privacy policies.
              </Typography>
              <br />

              <Typography className="label-terms">
                <span className="terms-list-title">Your Choices</span>
              </Typography>
              <Typography className="label-terms">
                You have certain choices on how we treat your personal information, described below:
                <ol start="1">
                  <li>
                    {" "}
                    <Typography className="terms-list-items">
                      <b className="terms-list-text2">Modifications to Personal Information. </b>
                      You may review and request modifications to your personal information by editing your profile
                      directly on our website or by contacting us at{" "}
                      <a href="mailto:privacy@onedge.co" className="link-class">
                        privacy@onedge.co
                      </a>{" "}
                      Please note that Personal Information is stored for as long as reasonably necessary and
                      proportionate to achieve the purposes for which the Personal Information was collected or
                      processed. If you provide us with updated information, we will do our best to keep your
                      information accurate and up-to-date. We will make good-faith efforts to make requested changes in
                      any active database as soon as practicable.
                    </Typography>
                  </li>
                  <li>
                    {" "}
                    <Typography className="terms-list-items">
                      <b className="terms-list-text2">Marketing Communications. </b>
                      You can opt out of promotional marketing communications by unsubscribing through the Services or
                      contacting us at{" "}
                      <a href="mailto:marketing@onedge.co" className="link-class">
                        marketing@onedge.co.
                      </a>{" "}
                      We may give choices about other emails and communications you receive from us. If you opt out, we
                      may still send you non-promotional communications, such as those about your account or our ongoing
                      business.
                    </Typography>
                  </li>
                  <li>
                    {" "}
                    <Typography className="terms-list-items">
                      <b className="terms-list-text2">Ad Choices. </b>
                      Some advertisements and other content may be delivered by third-party advertising networks and
                      advertisers that may use cookies and similar and/or your advertiser ID for mobile devices to
                      collect and track information such as demographic information, inferred interests, aggregated
                      information, and activity to assist them in delivering advertising that is more relevant to your
                      interests. To find out more about third-party advertising networks and similar entities that use
                      these technologies, see www.aboutads.info. If you would like to opt-out of such ad networks’ and
                      services’ advertising practices, you may find a service provider (for example,{" "}
                      <a href="www.aboutads.info/choices" className="link-class" target="blank">
                        www.aboutads.info/choices
                      </a>{" "}
                      ) to opt out in desktop and mobile web browsers.
                    </Typography>
                  </li>
                  <li>
                    {" "}
                    <Typography className="terms-list-items">
                      <b className="terms-list-text2">Tracking Technology. </b>
                      You may turn off part or all of our tracking software that has been placed on your computer by
                      following the instructions on your browser. On a mobile device, you may turn off part or all of
                      mobile tracking through your mobile device settings. However, if you prevent the use of tracking
                      software or tracking through your mobile device, it will be more difficult, and may be impossible,
                      for you to use the Service or portions of the Services.
                    </Typography>
                  </li>

                  <li>
                    {" "}
                    <Typography className="terms-list-items">
                      <b className="terms-list-text2">Location and Analytics. </b>
                      If we use Third-Party Platform to automatically collect your personal information, such
                      Third-Party Platform may offer you a choice to stop the automatic collection of your information.
                      Please see our “How we collect your personal information” above with respect to our tracking
                      tools.
                    </Typography>
                  </li>

                  <li>
                    {" "}
                    <Typography className="terms-list-items">
                      <b className="terms-list-text2">Cookies. </b>
                      Most web browsers are set by default to accept cookies. You can usually set your browser to remove
                      or reject cookies. Please note if you choose to reject, this could affect the availability and
                      functionality of our Services.
                    </Typography>
                  </li>
                </ol>
              </Typography>
              <br />
              <Typography className="label-terms">
                <span className="terms-list-title">Where Your Information Is Stored</span>
              </Typography>
              <Typography className="label-terms">
                We store your information in the United States. However, we may transfer your information to our service
                providers who may reside in other countries.
              </Typography>
              <Typography className="label-terms">
                By using the Services or otherwise providing information to us, you understand and consent to having any
                personal information transferred to and processed in the United States.
              </Typography>
              <br />
              <Typography className="label-terms">
                <span className="terms-list-title">Information Security</span>
              </Typography>
              <Typography className="label-terms">
                We use commercially reasonable security technologies and procedures to help protect your personal
                information from unauthorized access, use or disclosure. However, we cannot guarantee the complete
                safety of your information. It is your responsibility to keep your information confidential.
              </Typography>
              <br />

              <Typography className="label-terms">
                <span className="terms-list-title">How Long We Keep Your Information</span>
              </Typography>
              <Typography className="label-terms">
                We retain, store and use your information for the least amount of time necessary to fulfill the purpose
                for which it was collected in accordance with data retention policies and applicable law.
              </Typography>
              <br />

              <Typography className="label-terms">
                <span className="terms-list-title">Our Services Are Not Intended For Children</span>
              </Typography>
              <Typography className="label-terms">
                We do not knowingly collect personal information, as defined under the Children’s Online Privacy
                Protection Act, directly from children under 18 years old. We do not knowingly collect information,
                including personal information, from such individuals. If we obtain actual knowledge that we have
                collected such information from children, then we will promptly delete it. If you believe we have
                mistakenly collected information from children listed above, please contact us at{" "}
                <a href="mailto:privacy@onedge.co" className="link-class">
                  privacy@onedge.co
                </a>
                .
              </Typography>
              <br />

              <Typography className="label-terms">
                <span className="terms-list-title">
                  "Do Not Track" Policy as Required by California Online Privacy Protection Act (“CalOPPA”)
                </span>
              </Typography>
              <Typography className="label-terms">
                Our Service does not respond to Do Not Track (<b className="label-terms">“DNT”</b>) signals. However,
                some third-party websites do keep track of your browsing activities. If you are visiting such websites,
                you can set your preferences in your web browser to inform websites that you do not want to be tracked.
                You can enable or disable DNT by visiting the preferences or settings page of your web browser.
              </Typography>
              <br />

              <Typography className="label-terms">
                <span className="terms-list-title">California’s Shine the Light Law</span>
              </Typography>
              <Typography className="label-terms">
                If you are a California resident, California’s "Shine the Light" law (Civil Code Section § 1798.83)
                permits users of our Services to request certain information regarding our disclosure of personal
                information to third parties for their direct marketing purposes and to opt-out of the sharing of your
                personal information with such third parties. To make such a request, please send an email to{" "}
                <a href="mailto:privacy@onedge.co" className="link-class">
                  privacy@onedge.co
                </a>
                .
              </Typography>
              <br />

              <Typography className="label-terms">
                <span className="terms-list-title">Usage and Deletion of Personal Information</span>
              </Typography>

              <Typography className="label-terms">
                If you are not a California resident, you may request what personal information we have collected, used
                and disclosed about you as well as the identity of the third parties to which we have disclosed your
                personal information. You may also request deletion of your personal information. Please note that we
                may not delete all of your information if: (1) we need it to complete a service as requested by you or
                perform under a contract we have with you; (2) we need such information to repair any errors to our
                Services or detect data security violations; (3) we need such information to protect against fraud or
                illegal activity or to comply with applicable law; or (4) we determine in our sole discretion that we
                need such information to protect our rights or ensure compliance with our Terms of Service. Please note
                that if we delete your personal information, we may not be able to provide you the Services with the
                same functionality. To make any request for personal information or deletion, please send an email to{" "}
                <a href="mailto:privacy@onedge.co" className="link-class">
                  privacy@onedge.co
                </a>
                .
              </Typography>
              <br />

              <Typography className="label-terms">
                <span className="terms-list-title">Deidentified Information</span>
              </Typography>

              <Typography className="label-terms">
                Where we maintain or use deidentified information, we will continue to maintain and use the deidentified
                information only in a deidentified fashion and will not attempt to re-identify the information.
              </Typography>
              <br />

              <Typography className="label-terms">
                <span className="terms-list-title">Changes to this Privacy Policy</span>
              </Typography>

              <Typography className="label-terms">
                We have the discretion to update this Privacy Policy at any time. Updates to this Privacy Policy will be
                posted to our website from time to time with notice provided to you via the email address we have on
                record. We encourage you to frequently check this page for any changes. While we may make reasonable
                attempts to notify you when this Privacy Policy is updated, you acknowledge and agree that it is your
                responsibility to review this Privacy Policy periodically and become aware of modifications. Subject to
                applicable law, your continued use of our Services shall be deemed an acceptance of our revised policy.
              </Typography>
              <br />

              <Typography className="label-terms">
                <span className="terms-list-title">You Can Contact Us</span>
              </Typography>
              <Typography className="label-terms">
                If you have any questions about this Privacy Policy, you can email us at{" "}
                <a href="mailto:privacy@onedge.co" className="link-class">
                  privacy@onedge.co
                </a>
                .
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default CustomerPrivacyPolicy;
