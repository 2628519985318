import LeaveItem from "./LeaveItem";

import { convertDate } from "components/Screens/CommonUtils";

import "./LeaveList.css";
import moment from "moment";

const LeaveList = ({ selectedDateRange, employeesTimeout = [] }) => {
  return (
    <div className="container leaves-list">
      <div className="flex">
        {/* TODO: Implement functionality in phase 2 timeoff */}

        {/* <div className="upcoming-leaves-range-label-wrapper">
          <img src={Chevron} className="upcoming-leaves-chevron-left" alt="chevron" />
          <div className="upcoming-leaves-range-label ">Previous</div>
        </div>

        <div className="upcoming-leaves-range-label-wrapper upcoming-leaves-range-right">
          <div className="upcoming-leaves-range-label">Upcoming</div>
          <img src={Chevron} className="upcoming-leaves-chevron-right" alt="chevron" />
        </div> */}

        <div className="flex gap-05 calendar-dot-indicator-wrapper">
          <span className="align-self-center calendar-dot-indicator-orange" />

          <p className="body-md align-self-center">Leaves</p>

          <span className="align-self-center calendar-dot-indicator-green" />

          <p className="body-md align-self-center">Holidays</p>
        </div>
      </div>
      <div className="flex items-center">
        <div className="rectangle rectangle-multiple mr-1"></div>
        <div>
          <p style={{ fontFamily: "Poppins-Regular" }} className="title" id="date-range-selected">
            {moment(selectedDateRange?.startDate).format("MMM DD, YYYY")}{" "}
            {convertDate(selectedDateRange?.startDate) !== convertDate(selectedDateRange?.endDate) &&
              ` - ${moment(selectedDateRange?.endDate).format("MMM DD, YYYY")}`}
          </p>
          <p style={{ fontFamily: "Poppins-Regular" }} className="label-sm" id="people-out-from-office">
            People out this day : {employeesTimeout?.length}
          </p>
        </div>
      </div>
      {employeesTimeout.length > 0 ? (
        <div className="mt-1 space-y-1">
          {employeesTimeout?.map((emp) => (
            <span>
              <LeaveItem emp={emp} />
            </span>
          ))}
        </div>
      ) : (
        <div
          style={{
            borderRadius: "12px",
            marginTop: "1rem",
            background: "#FBFAFF",
          }}
        >
          <p
            style={{
              fontFamily: "Poppins-Regular",
              color: "#989AD1",
              padding: "2rem 3.5rem",
              margin: "auto",
            }}
            className="label-md"
          >
            No Employees out today
          </p>
        </div>
      )}
    </div>
  );
};

export default LeaveList;
