//employee constants
import {
  RESET_EDIT_COMPANY_INFO,
  EDIT_COMPANY_INFO,
  EDIT_COMPANY_INFO_SUCCESS,
  EDIT_COMPANY_INFO_FAILURE,
} from "../../constants/Settings/EditCompanyInfo";

const INIT_STATE = {
  editCompanyInfo: {},
  loadingEditCompanyInfo: true,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case RESET_EDIT_COMPANY_INFO:
      return INIT_STATE;

    case EDIT_COMPANY_INFO:
      return {
        ...state,
        loadingEditCompanyInfo: true,
      };
    case EDIT_COMPANY_INFO_SUCCESS:
      return {
        ...state,
        loadingEditCompanyInfo: false,
        editCompanyInfo: action.payload,
      };
    // eslint-disable-next-line no-fallthrough
    case EDIT_COMPANY_INFO_FAILURE:
      return {
        ...state,
        loadingEditCompanyInfo: false,
        editCompanyInfo: "Error in  getting Club Data",
      };
    default:
      return state;
  }
};
