import { call, put, takeEvery } from "redux-saga/effects";
//actions
import {
  employeeSalaryInfoSuccess,
  employeeSalaryInfoFailure,
} from "../../../actions/Workforce/EmployeeDetils/SalaryInfo";
//constant
import { SALARY_INFO } from "../../../constants/Workforce/EmployeeDetils/SalaryInfo";
//request type
import getRequest from "../../../../util/APIHelperGet";
import { baseUrl } from "../../../../util/APIBaseUrl";

export const fetchEmployeeSalaryInfoRequest = async (actions) => {
  return await getRequest(`${baseUrl}/${actions.payload.request}`)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

function* fetchEmployeeSalaryInfo(params) {
  try {
    let response = yield call(fetchEmployeeSalaryInfoRequest, params);
    yield put(employeeSalaryInfoSuccess(response));
  } catch (error) {
    console.error(error);
    yield put(employeeSalaryInfoFailure(error));
  }
}

export default function* rootSaga() {
  yield takeEvery(SALARY_INFO, fetchEmployeeSalaryInfo);
}
