import {
    RESET_ASSIGN_CUSTOMER_TO_EMPLOYEE,
    ASSIGN_CUSTOMER_TO_EMPLOYEE,
    ASSIGN_CUSTOMER_TO_EMPLOYEE_SUCCESS,
    ASSIGN_CUSTOMER_TO_EMPLOYEE_FAILURE,
} from "../../constants/Customers/AssignCustomertoEmployee";

// for employee information

export const ResetAssignCustomertoEmployee = (data) => {
    return {
        type: RESET_ASSIGN_CUSTOMER_TO_EMPLOYEE,
        payload: data,
    };
};

export const AssignCustomertoEmployee = (data) => {
    return {
        type: ASSIGN_CUSTOMER_TO_EMPLOYEE,
        payload: data,
    };
};
export const AssignCustomertoEmployeeSuccess = (payload) => {
    return {
        type: ASSIGN_CUSTOMER_TO_EMPLOYEE_SUCCESS,
        payload,
    };
};
export const AssignCustomertoEmployeeFailure = (payload) => {
    return {
        type: ASSIGN_CUSTOMER_TO_EMPLOYEE_FAILURE,
        payload,
    };
};
