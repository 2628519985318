import { useState, useEffect } from "react";
import { Grid, List, ListItem, Typography } from "@mui/material";
import PersonalInfo from "./PersonalInfo/NewPersonalProfile";

import { settingPages } from "components/Screens/SettingV2/SettingPages.enumn";

import { useSelector, useDispatch } from "react-redux";

import { GetCustomerUserandCompanyProfileData } from "store/actions/Settings/CustomerUser&CompanyProfileData";
import { EditUserProfile, ResetEditUserProfile } from "store/actions/Settings/EditUserProfile";
import { changeUserPasswordInfo, ResetChangeUserPassword } from "store/actions/Settings/ChangeUserPassword";

import { baseUrl } from "util/APIBaseUrl";
import { getDateFromUtcFormat, getValue } from "components/Screens/CommonUtils";
import PageTitle from "components/Widgets/PageTitle";
import PageLoader from "components/common/PageLoader";
import { Helmet } from "react-helmet";

let getProfileDataRequest = "";
let editUserProfileRequest = "";
let changeUserPasswordRequest = "";

const SettingsProfileData = {
  name: "",
  firstName: "",
  lastName: "",
  email: "",
  country: { key: null, value: "None" },
  phoneNumber: "",
  birthday: "",
  timeZone: { key: null, value: "None" },
  imageBlob: "",
  loadFromUrl: false,
  formData: null,
};

const userPassword = {
  currentPassword: "",
  newPassword: "",
  confirmPassword: "",
};

function index(props) {
  const dispatch = useDispatch();
  //component state
  const [settingPage, setSettingPage] = useState(settingPages.PROFILE_PAGE);
  const [profileData, setProfileData] = useState(SettingsProfileData);
  const [openAlert, setOpenAlert] = useState(false);
  const [changedPassword, SetChangePassword] = useState(userPassword);
  const [passwordMatchFlag, setPasswordMatchFlag] = useState(false);

  //redux state
  const changeUserPassword = useSelector((state) => state.ChangeUserPassword.changeUserPassword);
  const loadingChangeUserPassword = useSelector((state) => state.ChangeUserPassword.loadingChangeUserPassword);

  const customerUserandCompanyProfileData = useSelector(
    (state) => state.CustomerUserandCompanyProfileData.customerUserandCompanyProfileData
  );
  const loadingCustomeruserandCompanyProfileData = useSelector(
    (state) => state.CustomerUserandCompanyProfileData.loadingCustomeruserandCompanyProfileData
  );

  const editUserProfile = useSelector((state) => state.EditUserProfile.editUserProfile);
  const loadingEditUserProfile = useSelector((state) => state.EditUserProfile.loadingEditUserProfile);

  //functions
  const postLogo = async (actions) => {
    const response = await fetch(`${baseUrl}/${actions.request}`, {
      method: "POST",
      headers: {},
      body: actions.formData,
    });
    if (!response.ok) {
      return;
    }
    const data = await response.json();
  };

  const handleAlertClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenAlert(false);
  };

  const handleSettingPagechange = (edit) => {
    setSettingPage(edit);
  };

  const callbackFromMultiSelect = (option, key) => {
    profileData[key] = option;
    setProfileData({
      ...profileData,
    });
  };

  const callbackFromDatePicker = (targetName, newValue) => {
    setProfileData({
      ...profileData,
      [targetName]: newValue,
    });
  };

  const handleAddProfile = () => {
    let loggedInUser = {};
    let logoRequest = "";

    loggedInUser = JSON.parse(localStorage.getItem("loggedInUser"));

    if (settingPage == settingPages.EDIT_PERSONAL_INFO) {
      if (profileData.firstName != "" && profileData.lastName != "" && profileData.email) {
        if (loggedInUser?.type == "customer") {
          editUserProfileRequest = `customerUser/${loggedInUser?.userId}/editprofile`;
          logoRequest = `customerUser/${loggedInUser?.userId}/logo`;
        } else if (loggedInUser?.type == "edge") {
          editUserProfileRequest = `user/${loggedInUser?.userId}/editprofile`;
          logoRequest = `user/${loggedInUser?.userId}/logo`;
        }

        if (profileData?.formData) {
          postLogo({
            request: logoRequest,
            formData: profileData?.formData,
          });
        }

        dispatch(
          EditUserProfile({
            userProfileData: profileData,
            request: editUserProfileRequest,
          })
        );
      } else {
        setOpenAlert(true);
      }
    } else if (settingPage == settingPages.EDIT_PASSWORD) {
      if (changedPassword.newPassword != "" && changedPassword.currentPassword != "") {
        if (changedPassword.newPassword == changedPassword.confirmPassword) {
          setPasswordMatchFlag(false);
          if (loggedInUser?.type == "customer") {
            changeUserPasswordRequest = `customerUser/${loggedInUser?.userId}/changePassword`;
          } else if (loggedInUser?.type == "edge") {
            changeUserPasswordRequest = `user/${loggedInUser?.userId}/changePassword`;
          }
          dispatch(
            changeUserPasswordInfo({
              userPassword: changedPassword,
              request: changeUserPasswordRequest,
              userEmail: loggedInUser?.email,
            })
          );
        } else {
          setPasswordMatchFlag(true);
          setOpenAlert(true);
        }
      } else {
        setOpenAlert(true);
      }
    }
  };

  const selectProfilePicture = (imageBlob, form_data) => {
    setProfileData({
      ...profileData,
      imageBlob: imageBlob,
      formData: form_data,
      loadFromUrl: false,
    });
  };

  const handleCancelButton = () => {
    setSettingPage(settingPages.PROFILE_PAGE);
    setOpenAlert(false);
    dispatch(ResetChangeUserPassword({}));
    SetChangePassword(userPassword);
    setPasswordMatchFlag(false);
  };

  const handlePasswordChange = (event) => {
    SetChangePassword({
      ...changedPassword,
      [event.target.name]: event.target.value,
    });
  };

  const getError = (errors) => {
    if (errors != null && errors != undefined) {
      return errors.map((error, index) => {
        return (
          <List
            sx={{
              listStyleType: "disc",
              paddingRight: "0px !important",
              pl: 3,
              "& .MuiListItem-root": {
                paddingRight: "0px",
                paddingLeft: "0px",
                paddingTop: "0px",
                paddingBottom: "0px",
              },
            }}
          >
            <ListItem
              sx={{
                display: "list-item",
              }}
            >
              <Typography variant="h6" color="secondary" fontSize="12px">
                {(error?.msg ? error.msg : error?.description ? error.description : "") +
                  " " +
                  (error?.param ? error?.param : "")}
              </Typography>
            </ListItem>
          </List>
        );
      });
    } else {
      return null;
    }
  };

  //methods
  useEffect(() => { }, [profileData]);

  useEffect(() => {
    if (settingPage == settingPages.PROFILE_PAGE) {
      SetChangePassword(userPassword);
    }
  }, [settingPage]);

  useEffect(() => {
    let countryOption = [];
    let timeZoneOption = [];
    let dropdownData = {};
    let loggedInUser = {};
    let userProfileUrl = "";

    let userAdditionalInfo = JSON.parse(sessionStorage.getItem("userAdditionalInfo"));

    dropdownData = JSON.parse(sessionStorage.getItem("dropdownData"));
    loggedInUser = JSON.parse(localStorage.getItem("loggedInUser"));

    if (Object.keys(loggedInUser).length && loggedInUser.userId != undefined) {
      if (loggedInUser?.type == "customer") {
        userProfileUrl = `${baseUrl}/customerUser/${loggedInUser?.userId}/logo?id=${userAdditionalInfo?.pictureVersion}`;
      } else if (loggedInUser?.type == "edge") {
        userProfileUrl = `${baseUrl}/user/${loggedInUser?.userId}/logo?id=${userAdditionalInfo?.pictureVersion}`;
      }
    }

    if (dropdownData != undefined && dropdownData != null && Object.keys(dropdownData).length && dropdownData?.ok) {
      countryOption = dropdownData?.payload?.country.filter(
        (country) => country?.countryCode == customerUserandCompanyProfileData?.payload?.country
      );

      timeZoneOption = dropdownData?.payload?.timezone?.filter(
        (timeZone) => timeZone.timezone == customerUserandCompanyProfileData?.payload?.timezone
      );
    }
    setProfileData({
      ...profileData,
      firstName: getValue(customerUserandCompanyProfileData?.payload?.firstName),
      lastName: getValue(customerUserandCompanyProfileData?.payload?.lastName),
      email: getValue(customerUserandCompanyProfileData?.payload?.email),
      phoneNumber: getValue(customerUserandCompanyProfileData?.payload?.phoneNumber),
      birthday: getDateFromUtcFormat(customerUserandCompanyProfileData?.payload?.dateOfBirth),
      country: {
        key: countryOption[0]?.countryCode2,
        value: countryOption[0]?.countryCode,
      },
      timeZone: {
        key: timeZoneOption?.[0]?.timezone,
        value: timeZoneOption?.[0]?.timezone,
      },
      imageBlob: userProfileUrl,
      loadFromUrl: true,
    });
  }, [customerUserandCompanyProfileData]);

  useEffect(() => {
    let loggedInUser = {};
    loggedInUser = JSON.parse(localStorage.getItem("loggedInUser"));
    setPasswordMatchFlag(false);
    if (editUserProfile?.ok) {
      dispatch(
        GetCustomerUserandCompanyProfileData({
          request: getProfileDataRequest,
        })
      );
      dispatch(ResetEditUserProfile({}));
      setSettingPage(settingPages.PROFILE_PAGE);
    }
  }, [editUserProfile]);

  useEffect(() => {
    let loggedInUser = {};
    loggedInUser = JSON.parse(localStorage.getItem("loggedInUser"));
    if (changeUserPassword?.ok) {
      dispatch(
        GetCustomerUserandCompanyProfileData({
          request: getProfileDataRequest,
        })
      );
      dispatch(ResetChangeUserPassword({}));
      setSettingPage(settingPages.PROFILE_PAGE);
    } else if (changeUserPassword?.ok == false) {
      setOpenAlert(true);
    } else {
      setOpenAlert(false);
    }
  }, [changeUserPassword]);

  useEffect(() => {
    let loggedInUser = {};
    loggedInUser = JSON.parse(localStorage.getItem("loggedInUser"));

    dispatch(ResetChangeUserPassword({}));
    setOpenAlert(false);
    if (loggedInUser != null && Object.keys(loggedInUser).length && loggedInUser.userId != undefined) {
      if (loggedInUser?.type == "customer") {
        getProfileDataRequest = `customerUser/${loggedInUser?.userId}`;
      } else if (loggedInUser?.type == "edge") {
        getProfileDataRequest = `user/${loggedInUser?.userId}`;
      }
    }

    dispatch(
      GetCustomerUserandCompanyProfileData({
        request: getProfileDataRequest,
      })
    );
  }, []);

  return (
    <>
      <Helmet>
        <title>{`Edge | Profile`}</title>
      </Helmet>
      {loadingChangeUserPassword ||
        loadingCustomeruserandCompanyProfileData ||
        loadingEditUserProfile ? (
        <PageLoader />
      ) : (
        <></>
      )}
      <Grid
        sx={{
          background: "#FBFAFF",
          minHeight: "calc(100vh - 51px)",
          marginTop: "5px",
        }}
      >
        <PageTitle
          background="#FBFAFF"
          page={{
            title: "Profile Details",
            linkName: "Account",
            link: "",
          }}
        />

        <Grid
          sx={{
            maxWidth: "1076px",
            width: "100%",
            margin: "0px auto",
            marginTop: "24px",
            paddingTop: "0px",
          }}
        >
          <PersonalInfo handleSettingPagechange={handleSettingPagechange} />
        </Grid>
      </Grid>
    </>
  );
}

export default index;
