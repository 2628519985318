import React, { createRef, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { usePermissions } from "components/Hook";

import AlertError from "components/Widgets/AlertError";
import ImageCropModal from "components/common/ImageCropperModal";
import AvatarIcon from "components/Icons/AvatarIcon";
import { UserAdditionalInfo } from "store/actions/UserAdditionalInfo";

import { baseUrl } from "util/APIBaseUrl";
import { EditOutlined } from "@mui/icons-material";

function readFile(file) {
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.addEventListener("load", () => resolve(reader.result), false);
    reader.readAsDataURL(file);
  });
}

const loadImageFormData = {
  loadFromUrl: true,
  imagePreviewUrl: null,
  imageBlob: null,
};

function ProfilePicture(props) {
  const { page } = props;
  const photoInput = createRef();
  const dispatch = useDispatch();

  const { loggedInUser, loggedInUserType } = usePermissions();

  const [imageSrc, setImageSrc] = useState({});
  const [openAlert, setOpenAlert] = useState(false);
  const [userProfileUrl, setUserProfileUrl] = useState(null);
  const [showImageCropperModal, setShowImageCropperModal] = useState(false);
  const [isFileUploadError, setIsFileUploadError] = useState(false);

  const userAdditionalInfo = useSelector((state) => state.UserAdditionalInfo.userAdditionalInfo);

  const postLogo = async (actions) => {
    try {
      document.body.style.cursor = "wait";
      await fetch(`${baseUrl}/${actions.request}`, {
        method: "POST",
        headers: {},
        body: actions.formData,
      });

      const req = {
        customer: `customer/${loggedInUser?.customerId}/info`,
        edge: `user/${loggedInUser.userId}/summary`,
        employee: `employee/${loggedInUser.userId}`,
      };

      dispatch(UserAdditionalInfo(req[loggedInUserType]));
      setShowImageCropperModal(false);
      document.body.style.cursor = "default";
    } catch (error) {
      setOpenAlert(true);
      setShowImageCropperModal(false);
      document.body.style.cursor = "default";
    }
  };

  const uploadProfilePic = () => {
    photoInput.current.click();
  };

  const handleImageChange = async (e) => {
    e.preventDefault();

    const file = e.target.files[0];
    e.target.value = "";

    if (file.size / 1024 / 1024 > 2) setIsFileUploadError(true);
    else setIsFileUploadError(false);

    const imageDataUrl = await readFile(file);

    setImageSrc(imageDataUrl);
    setShowImageCropperModal(true);
  };

  const getPictureVersion = () => {
    const pictureVersion = {
      companyProfile: userAdditionalInfo?.customerPictureVersion,
      personalProfile: userAdditionalInfo?.pictureVersion,
    };
    return pictureVersion[page];
  };

  const handleSave = async (param) => {
    const formData = new FormData();
    formData?.append("file", param);

    const req = {
      personalProfile: {
        customer: `customerUser/${loggedInUser?.userId}/logo`,
        edge: `user/${loggedInUser?.userId}/logo`,
      },

      companyProfile: {
        customer: `customer/${loggedInUser?.customerId}/logo`,
        edge: `user/${loggedInUser?.userId}/logo`,
      },
    };

    await postLogo({
      request: req[page][loggedInUserType],
      formData,
    });
  };

  useEffect(() => {
    const req = {
      personalProfile: {
        customer: `customerUser/${loggedInUser?.userId}/logo?id=${getPictureVersion()}`,
        edge: `user/${loggedInUser?.userId}/logo?id=${getPictureVersion()}`,
      },

      companyProfile: {
        customer: `customer/${loggedInUser?.customerId}/logo?id=${getPictureVersion()}`,
        edge: `user/${loggedInUser?.userId}/logo?id=${getPictureVersion()}`,
      },
    };

    setUserProfileUrl(`${baseUrl}/${req[page][loggedInUserType]}`);
  }, [page, userAdditionalInfo]);

  return (
    <>
      <ImageCropModal
        open={showImageCropperModal}
        onClose={() => setShowImageCropperModal(false)}
        imageSrc={imageSrc}
        onSave={handleSave}
        isFileUploadError={isFileUploadError}
        errorMessage="The file you've selected is too large. Please upload an image file file that is 2MB or smaller."
      />

      <div className="flex flex-col space-y-2">
        <div className="profile-img-container">
          <div className="relative cursor-pointer" onClick={uploadProfilePic}>
            <AvatarIcon
              size={{
                width: "144px",
                height: "144px",
                borderRadius: "999px",
              }}
              source={
                loadImageFormData?.loadFromUrl ? userProfileUrl : `data:image/png;base64,${loadImageFormData.imageBlob}`
              }
            />

            <div className="edit-upload-icon">
              <input
                type="file"
                accept="image/*"
                onChange={handleImageChange}
                ref={photoInput}
                style={{ display: "none" }}
              />
              <EditOutlined className="color-icon-primary" />
            </div>
          </div>
        </div>
        <AlertError
          openAlert={openAlert}
          setOpenAlert={setOpenAlert}
          errors={[
            {
              ok: false,
              payload: {
                error: [
                  {
                    name: "Forbidden.",
                    statusCode: 413,
                    isOperational: false,
                    description: "An error occured while uploading photo.",
                  },
                ],
              },
            },
          ]}
        />
      </div>
    </>
  );
}

export default ProfilePicture;
