import React, { useEffect, useState } from "react";

import EditDeleteMenu from "./EditOrDeleteMenu";
import EmployeeModal from "./Modal";
import Button from "components/common/Button";
import TimeLine from "components/common/Timeline";
import { getYearFromDate } from "components/Screens/CommonUtils";
import { usePermissions } from "components/Hook";
import PageLoader from "components/common/PageLoader";
import { getEmployeeEducationById } from "services/BambooHrTabs/education";

import AddIcon from "@mui/icons-material/Add";
import SchoolOutlinedIcon from "@mui/icons-material/SchoolOutlined";

import "./education-tab.scss";

function EducationTab({ employeeId }) {
  const { loggedInUserType } = usePermissions();

  const [education, setEducation] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [role, setRole] = useState("");
  const [educationModal, setEducationModal] = useState({
    show: false,
    mode: "Add",
    data: null,
  });

  const getEmployeeEducation = async () => {
    setIsLoading(true);
    const educationResp = await getEmployeeEducationById(employeeId);
    const { ok, payload } = educationResp;
    if (ok) {
      setEducation(payload);
    }
    setIsLoading(false);
  };

  const getDate = (startDate, endDate) => {
    const startYear = getYearFromDate(startDate);
    const endYear = getYearFromDate(endDate);

    return `${startYear} - ${endYear}`;
  };

  const handleEducationModalSuccess = async () => {
    setEducationModal({ show: false, mode: "Add", data: null });
    await getEmployeeEducation();
  };

  useEffect(() => {
    if (loggedInUserType === "edge") setRole("admin");
    else if (loggedInUserType === "employee") setRole("employee");
    else if (loggedInUserType === "customer") setRole("customer");
    getEmployeeEducation();
  }, [employeeId]);

  return (
    <>
      {isLoading && <PageLoader />}
      <EmployeeModal
        employeeId={employeeId}
        open={educationModal.show}
        mode={educationModal.mode}
        data={educationModal.data}
        closeModal={() => setEducationModal({ show: false, mode: "Add", data: null })}
        onSuccess={handleEducationModalSuccess}
      />

      <div className="flex items-center justify-between">
        <p className="heading-h4-semibold color-text-heading-primary">Education</p>

        {role !== "customer" && !!education?.length && (
          <AddIcon
            className="cursor-pointer"
            onClick={() => setEducationModal({ show: true, mode: "Add", data: null })}
          />
        )}
      </div>

      {education?.length === 0 && role === "employee" && (
        <div className="para-body-m-regular color-text-body-primary "> Add your 2 most recent education degrees.</div>
      )}

      {!education?.length ? (
        <div>
          {role === "admin" && !isLoading && (
            <div className="mt-05 bhr-education-container">
              <SchoolOutlinedIcon className="bhr-school-icon" />
              <p className="para-body-m-regular">Employee has not added any education yet.</p>
              <Button
                size="small"
                variant="outlined"
                onClick={() => setEducationModal({ show: true, mode: "Add", data: null })}
                label="Add Education"
              />
            </div>
          )}
          {role === "employee" && !isLoading && (
            <div className="mt-15">
              <Button
                isFullWidth
                variant="outlined"
                size="large"
                onClick={() => setEducationModal({ show: true, mode: "Add", data: null })}
                label="Add Education"
                startIcon={<AddIcon />}
              />
            </div>
          )}
          {role === "customer" && !isLoading && (
            <div className="no-eduaction-container">
              <p className="para-body-l-regular text-center color-text-body-primary">No education added yet</p>
            </div>
          )}
        </div>
      ) : (
        <TimeLine
          timelineData={education?.map((item) => {
            return {
              id: item?.educationId,
              title: item?.degreeName || "N/A",
              company: item?.schoolName || "N/A",
              icon: role !== "customer" && <EditDeleteMenu setOpenModal={setEducationModal} menuItem={item} />,
              date: getDate(item?.startDate, item?.endDate),
            };
          })}
        />
      )}
    </>
  );
}

export default EducationTab;
