//auth constants
import {
  USER_DETAILS,
  USER_DETAILS_SUCCESS,
  USER_DETAILS_FAILURE,
} from "../constants/UserDetails";

const INIT_STATE = {
  userDetils: {},
  loadingUserDetails: true,
};

export default (state = INIT_STATE, action) => {
  //
  switch (action.type) {
    case USER_DETAILS:
      return {
        ...state,
        loadingUserDetails: true,
      };
    case USER_DETAILS_SUCCESS:
      return {
        ...state,
        loadingUserDetails: false,
        userDetils: action.payload,
      };

    // eslint-disable-next-line no-fallthrough
    case USER_DETAILS_FAILURE:
      // localStorage.removeItem("accessToken");
      return {
        ...state,
        loadingUserDetails: false,
        userDetils: "Error in  getting Club Data",
      };
    default:
      return state;
  }
};
