//material ui theme
import { createTheme } from "@mui/material/styles";

export const Theme = createTheme({
  palette: {
    primary: {
      //main: "#1161D5", //Blue
      // main: "#40CAC1", //aquaGreen
      main: "#3D43BB",
    },

    primary1Color: {
      main: "#3D43BB", //lighter
    },

    primaryAlt: {},

    secondary: {
      //main: "#43425D", // dark grey
      main: "#3D43BB",
      // main: "#FFC200",
    },

    warning: {
      // main: "#F54F5E", // red
      main: "#F64A14", // Orange
    },

    pending: {
      main: "#DAE6F8",
    },

    success: {
      main: "#38E25D", // green
    },

    info: {
      main: "#cccccc", // light gray
    },

    muted: {
      main: "#a5a5a5",
    },
    contrastThreshold: 3,
    tonalOffset: 0.2,
  },

  typography: {
    useNextVariants: true,

    button: {
      textTransform: "none",
    },

    //headline
    h1: {
      fontFamily: "Poppins-Bold",
      letterSpacing: "0.02em",
      fontSize: "26px",
      fontWeight: "bold",
      color: "#080D46",
    },
    //Form label text field or card content workforce emplyee peronal grid
    h2: {
      fontSize: "14px",
      //fontWeight: "500",
      fontFamily: "Poppins-Regular",
      color: "#080D46",
    },
    // workforce paper heading and title
    h3: {
      fontSize: "13px",
      fontFamily: "Poppins-Medium",
      color: "#080D46",
      //fontWeight: "bold",
    },
    //Employee Summery , dates
    h3Lite: {
      fontSize: "14px",
      fontFamily: "Poppins-Light",
      color: "grey",
    },

    //headers
    h4: {
      fontSize: "22px",
      fontFamily: "Poppins-Bold",
      fontWeight: "bold",
      color: "#080D46",
    },

    //table Headers
    h5: {
      fontSize: "13px",
      //fontWeight: "bolder",
      // fontFamily: "Cairo-ExtraBold",
      fontFamily: "Poppins-SemiBold",
      color: "#080D46",
    },

    //total payroll
    h6: {
      fontFamily: "Poppins-Regular",
      fontSize: "12px",
      color: "#080D46",
    },
    //paper labels
    h6Bold: {
      fontFamily: "Poppins-SemiBold",
      fontSize: "14px",
      color: "#080D46",
    },

    //sidebar
    h6Sidebar: {
      fontFamily: "Poppins-Regular",
      fontSize: "13px",
      fontWeight: "bold",
      color: "#080D46",
    },

    //small body
    paragraph: {
      fontSize: "11px",
      //fontFamily: "OpenSans",
      fontFamily: "Poppins-Medium",
    },

    //grey subtexts
    subText: {
      color: "#c1c1c1",
      fontSize: "12px",
      fontFamily: "Poppins-Regular",
    },

    //input Labels
    input: {
      fontFamily: "Poppins-Medium",
      fontSize: "12px",
      color: "#8386A2",
    },
  },

  components: {
    MuiToolbar: {
      styleOverrides: {
        root: {
          minHeight: "46px !important",
          height: "46px !important",
          paddingLeft: "44px !important",
          paddingRight: "60px !important",
        },
      },
    },

    MuiPaper: {
      styleOverrides: {
        root: {
          borderRadius: "5px !important",
          // border: "0.1px solid #CEDAD9",
        },
        elevation1: {
          boxShadow: "rgba(0, 0, 0, 0.02) 0px 3px 4px !important",
        },
        elevation3: {
          boxShadow:
            "rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 56px, rgba(17, 17, 26, 0.1) 0px 24px 80px",
          //boxShadow: "rgba(17, 17, 26, 0.06) 0px 5px 24px !important",
        },
      },
      //elevation:0
    },

    MuiCollapse: {
      styleOverrides: {
        wrapperInner: {
          backgroundColor: "white",
          zIndex: "1000",
        },
      },
    },
    MuiAccordionSummary: {
      styleOverrides: {
        root: {
          "&.Mui-expanded": {
            minHeight: 0,
          },
          minHeight: 0,
        },
      },
    },

    MuiInputBase: {
      styleOverrides: {
        root: {
          fontSize: 10,
          height: 42,
        },
      },
    },
    // MuiAutocomplete: {
    //     styleOverrides: {
    //         input: {
    //             width: 0,
    //             backgroundColor: "red",
    //         },

    //     },
    // },

    MuiCheckbox: {
      styleOverrides: {
        root: {
          color: "black",
        },
      },
    },
    // MUIDataTableFilter-checkbox

    //scroll bar css, caution editing scrollbar causes scrollbar to always show on mac devices
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          scrollbarColor: "#1161D5 #fffff",
          "&::-webkit-scrollbar, & *::-webkit-scrollbar": {
            backgroundColor: "rgba(0, 0,0, 0.0)",
            width: "10px",
            height: "0",
          },
          "&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb": {
            borderRadius: 2,
            //width:10,
            backgroundColor: "#F4F4F4",
            minHeight: 24,
            maxWidth: 3,
          },
          "&::-webkit-scrollbar-thumb:focus, & *::-webkit-scrollbar-thumb:focus":
            {
              backgroundColor: "#959595",
            },
          "&::-webkit-scrollbar-thumb:active, & *::-webkit-scrollbar-thumb:active":
            {
              backgroundColor: "#959595",
            },
          "&::-webkit-scrollbar-thumb:hover, & *::-webkit-scrollbar-thumb:hover":
            {
              backgroundColor: "#959595",
            },
          "&::-webkit-scrollbar-corner, & *::-webkit-scrollbar-corner": {
            backgroundColor: "#2b2b2b",
          },
        },
      },
    },
  },
});
