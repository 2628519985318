//employee constants
import {
    RESET_ADD_EMPLOYEE_SALARY_DATA,
    ADD_EMPLOYEE_SALARY_DATA,
} from "../../../constants/Workforce/AddEmployee/AddEmployeeSalaryData";

const INIT_STATE = {
    addEmployeeSalaryData: {},
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case RESET_ADD_EMPLOYEE_SALARY_DATA:
            return INIT_STATE;

        case ADD_EMPLOYEE_SALARY_DATA:
            return {
                ...state,
                addEmployeeSalaryData: action.payload,
            };

        default:
            return state;
    }
};
