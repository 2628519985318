import {
  RESET_CHANGE_USER_PASSWORD,
  CHANGE_USER_PASSWORD,
  CHANGE_USER_PASSWORD_SUCCESS,
  CHANGE_USER_PASSWORD_FAILURE,
} from "../../constants/Settings/ChangeUserPassword";

// for add employee in admin workforce table

export const ResetChangeUserPassword = (data) => {
  return {
    type: RESET_CHANGE_USER_PASSWORD,
    payload: data,
  };
};

export const changeUserPasswordInfo = (data) => {
  return {
    type: CHANGE_USER_PASSWORD,
    payload: data,
  };
};

export const changeUserPasswordSuccess = (payload) => {
  return {
    type: CHANGE_USER_PASSWORD_SUCCESS,
    payload,
  };
};

export const changeUserPasswordFailure = (payload) => {
  return {
    type: CHANGE_USER_PASSWORD_FAILURE,
    payload,
  };
};
