//employee constants
import {
  DISABLE_CUSTOMER,
  DISABLE_CUSTOMER_SUCCESS,
  DISABLE_CUSTOMER_FAILURE,
} from "../../constants/Customers/DisableCustomer";

const INIT_STATE = {
  disableCustomer: {},
  loadingDisableCustomer: false,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case DISABLE_CUSTOMER:
      return {
        ...state,
        loadingDisableCustomer: true,
      };
    case DISABLE_CUSTOMER_SUCCESS:
      return {
        ...state,
        loadingDisableCustomer: false,
        disableCustomer: action.payload,
      };
    // eslint-disable-next-line no-fallthrough
    case DISABLE_CUSTOMER_FAILURE:
      return {
        ...state,
        loadingDisableCustomer: false,
        disableCustomer: "Error in  getting Club Data",
      };
    default:
      return state;
  }
};
