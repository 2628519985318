import {
    PAYONEER_REVOKE_ACCESS_TOKEN_FETCH,
    PAYONEER_REVOKE_ACCESS_TOKEN_SUCCESS,
    PAYONEER_PAYONEER_REVOKE_ACCESS_TOKEN_FAILED,
  } from "store/constants/Payoneer/PayoneerRevokeAccessToken";

  export const revokeAccessTokenFetch = () => {
    return {
      type: PAYONEER_REVOKE_ACCESS_TOKEN_FETCH,
    };
  };

  export const revokeAccessTokenSuccess = () => {
    return {
      type: PAYONEER_REVOKE_ACCESS_TOKEN_SUCCESS,
    };
  };
  
  export const revokeAccessTokenFailed = (error) => {
    return { type: PAYONEER_PAYONEER_REVOKE_ACCESS_TOKEN_FAILED, payload: error };
  };
  