import React from "react";

const WorkforceIcon = () => {
  return (
    <svg
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M5.02959 16.1464C6.35291 17.3184 8.09344 18.0299 10.0002 18.0299C11.9069 18.0299 13.6474 17.3184 14.9707 16.1464C14.7268 15.2694 12.9746 14.6966 10.0002 14.6966C7.02568 14.6966 5.2735 15.2694 5.02959 16.1464ZM3.83392 14.8005C4.77179 13.5617 6.97566 13.0299 10.0002 13.0299C13.0247 13.0299 15.2285 13.5617 16.1664 14.8005C17.0073 13.5885 17.5002 12.1168 17.5002 10.5299C17.5002 6.38781 14.1423 3.02995 10.0002 3.02995C5.85803 3.02995 2.50016 6.38781 2.50016 10.5299C2.50016 12.1168 2.99298 13.5885 3.83392 14.8005ZM10.0002 19.6966C4.93755 19.6966 0.833496 15.5926 0.833496 10.5299C0.833496 5.46734 4.93755 1.36328 10.0002 1.36328C15.0628 1.36328 19.1668 5.46734 19.1668 10.5299C19.1668 15.5926 15.0628 19.6966 10.0002 19.6966ZM6.66683 8.86328C6.66683 6.99308 7.98275 5.52995 10.0002 5.52995C12.012 5.52995 13.3335 7.13126 13.3335 9.02995C13.3335 11.763 11.8486 13.0299 10.0002 13.0299C8.13422 13.0299 6.66683 11.7194 6.66683 8.86328ZM8.3335 8.86328C8.3335 10.7543 9.01534 11.3633 10.0002 11.3633C10.9816 11.3633 11.6668 10.7786 11.6668 9.02995C11.6668 7.98863 11.0132 7.19661 10.0002 7.19661C8.94494 7.19661 8.3335 7.87647 8.3335 8.86328Z"
        fill="#FBFAFF"
      />
    </svg>
  );
};

export default WorkforceIcon;
