import React from "react";

const FailureIcon = () => {
  return (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M1.83595 16.4988L1.83595 7.50118L8.19824 1.13889L17.1959 1.13889L23.5582 7.50118L23.5582 16.4988L17.1959 22.8611L8.19824 22.8611L1.83595 16.4988ZM9.0162 20.8864L16.3779 20.8864L21.5834 15.6809L21.5834 8.31915L16.3779 3.11364L9.0162 3.11364L3.81069 8.31915L3.81069 15.6809L9.0162 20.8864ZM12.6967 6.07739C13.2422 6.07739 13.6844 6.51946 13.6844 7.06477C13.6844 7.61008 13.2422 8.05214 12.6967 8.05214C12.1512 8.05214 11.709 7.61008 11.709 7.06477C11.709 6.51946 12.1512 6.07739 12.6967 6.07739ZM13.6815 17.9259L11.7061 17.9259L11.7061 10.0269L13.6815 10.0269L13.6815 17.9259Z"
        fill="#BA1A1A"
      />
    </svg>
  );
};

export default FailureIcon;
