import React, { useMemo } from "react";
import UserInfo from "./UserInfo";
import { IconButton } from "@mui/material";
import ModeEditOutlineOutlinedIcon from "@mui/icons-material/ModeEditOutlineOutlined";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";

function UserItem(props) {
  const { user, loggedInUser, setEditAbleUser, setAddorEditUser, handleRemoveUser } = props;

  const { hasRestrictedAccess, isCurrentUser } = useMemo(() => {
    const restrictedRoles = ["CustomerAdmin", "CustomerEmployee"];
    return {
      hasRestrictedAccess:
        loggedInUser?.permissions?.some((permission) => restrictedRoles.includes(permission)) && user?.mainUser,
      isCurrentUser: loggedInUser?.email === user?.email,
    };
  }, [loggedInUser?.permissions, loggedInUser?.email, user?.mainUser, user?.email]);

  return (
    <div className="flex justify-between items-center p-1">
      <UserInfo user={user} />
      {(!hasRestrictedAccess || isCurrentUser) && (
        <div className="flex gap-05 items-center">
          <IconButton
            onClick={() => {
              setEditAbleUser(user);
              setAddorEditUser({
                open: true,
                mode: "editUser",
              });
            }}
            size="small"
            className="edit-delete-icon-size"
          >
            <ModeEditOutlineOutlinedIcon />
          </IconButton>

          <IconButton disabled={user?.mainUser || isCurrentUser} size="small" className="edit-delete-icon-size">
            <DeleteOutlineIcon onClick={() => handleRemoveUser(user)} />
          </IconButton>
        </div>
      )}
    </div>
  );
}

export default UserItem;
