import { call, put, takeEvery } from "redux-saga/effects";
//actions
import {
  GetUsersInfoSuccess,
  GetUsersInfoFailure,
} from "../../actions/GetUsersInfo";
//constant
import { GET_USERS_INFO } from "../../constants/GetUsers";
//request type
import getRequest from "../../../util/APIHelperGet";
import { baseUrl } from "../../../util/APIBaseUrl";

export const fetchGetUsersRequest = async (actions) => {
  // eslint-disable-next-line no-return-await
  // return await getRequest(`${baseUrl}/users/list`)

  return await getRequest(
    `${baseUrl}/users/list?` +
      new URLSearchParams({
        limit: actions?.payload.limit,
        filter: JSON.stringify({
          Role: actions.payload.permissions,
          // filter: ["EdgeAdmin", "EdgeEmployee"],
        }),
      })
  )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

function* fetchGetUsers(params) {
  try {
    let response = yield call(fetchGetUsersRequest, params);
    yield put(GetUsersInfoSuccess(response));
  } catch (error) {
    console.error(error);
    yield put(GetUsersInfoFailure(error));
  }
}

export default function* rootSaga() {
  yield takeEvery(GET_USERS_INFO, fetchGetUsers);
}
