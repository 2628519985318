//employee constants
import {
  GET_CUSTOMER_Details,
  GET_CUSTOMER_Details_SUCCESS,
  GET_CUSTOMER_Details_FAILURE,
} from "../../constants/Customers/GetCustomerDetails";

const INIT_STATE = {
  customerDetails: {},
  loadingCustomerDetails: false,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_CUSTOMER_Details:
      return {
        ...state,
        loadingCustomerDetails: true,
      };
    case GET_CUSTOMER_Details_SUCCESS:
      return {
        ...state,
        loadingCustomerDetails: false,
        customerDetails: action.payload,
      };
    // eslint-disable-next-line no-fallthrough
    case GET_CUSTOMER_Details_FAILURE:
      return {
        ...state,
        loadingCustomerDetails: false,
        customerDetails: "Error in  getting Club Data",
      };
    default:
      return state;
  }
};
