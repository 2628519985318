import React, { useState } from "react";
//material ui
import {
  Button,
  Grid,
  Box,
  Typography,
  FormControl,
  TextField,
  Autocomplete,
  InputAdornment,
  IconButton,
  Checkbox,
  FormControlLabel,
  FormHelperText,
  FormGroup,
  Tooltip,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import Switch from "@mui/material/Switch";

//widgets
import AvatarIcon from "./../Icons/AvatarIcon";
//Icons
import CameraAltRoundedIcon from "@mui/icons-material/CameraAltRounded";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import ImageCropperModal from "../common/ImageCropperModal";

//constants
const textFiledStyle = {
  "& .MuiInputBase-root": {
    borderRadius: "5px",
    fontSize: "12px",
    fontFamily: "Poppins-Medium",
    fontWeight: "500",
    color: "primary",
  },
  "& .MuiOutlinedInput-input": {
    "&::-webkit-outer-spin-button, &::-webkit-inner-spin-button": {
      WebkitAppearance: "none",
      // "-webkit-appearance": "none",
    },
  },
};
const avatarStyle = {
  height: "170px",
  maxWidth: "170px",
  width: "calc(100% - 20px)",
  borderRadius: "50%",

  mt: 2,
};

function FormWidget(props) {
  const {
    data,
    nextButtonClick,
    handleDataChange,
    callbackFromMultiSelect,
    formData,
    selectProfilePicture,
    justifyContent,
    callbackFromDatePicker,
  } = props;

  const theme = useTheme();
  let photoInput = React.createRef();
  const matches = useMediaQuery(theme.breakpoints.down("lg"));
  //component state
  const [showPassword, setShowPassword] = React.useState(false);
  const [showImageCropperModal, setShowImageCropperModal] = React.useState(false);
  const [imageSrc, setImageSrc] = React.useState({});
  const [isFileUploadError, setIsFileUploadError] = React.useState(false);
  const [profilePicReader, setProfilePicReader] = React.useState(false);

  const [values, setValues] = useState({
    imagePreviewUrl: "",
    picFile: null,
  });

  //funcations
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };
  const handleMouseDownPassword = () => {
    setShowPassword(!showPassword);
  };

  //methods
  React.useEffect(() => {}, [showPassword]);
  React.useEffect(() => {}, [formData]);

  const uploadProfilePic = () => {
    photoInput.current.click();
  };

  function readFile(file) {
    return new Promise((resolve) => {
      const reader = new FileReader();

      reader.addEventListener(
        "load",
        () => {
          setValues({
            ...values,
            picFile: file,
            imagePreviewUrl: reader.result,
          });

          setProfilePicReader(reader?.result?.split(",")[1]);

          resolve(reader.result);
        },
        false
      );
      reader.readAsDataURL(file);
    });
  }

  const handleImageChange = async (e) => {
    e.preventDefault();
    const file = e.target.files[0];
    e.target.value = "";

    if (!file?.type.startsWith("image/")) {
      alert("Invalid Image File");
      return false;
    }

    if (file.size / 1024 / 1024 > 2) setIsFileUploadError(true);
    else setIsFileUploadError(false);

    let imageDataUrl = await readFile(file);
    setImageSrc(imageDataUrl);

    setShowImageCropperModal(true);
    return;
  };

  const checkEmptyValue = (field) => {
    return nextButtonClick && field.required && formData[field.name] == 0;
  };

  const handleSave = (param) => {
    const form_data = new FormData();
    form_data?.append("file", param);

    selectProfilePicture(profilePicReader, form_data);
    setValues({
      ...values,
      picFile: param,
    });
    setShowImageCropperModal(false);
  };

  const gridFunction = (data, index) => {
    return (
      <Grid item xs={12} lg={data.lg} key={index}>
        <FormControl variant="outlined" fullWidth>
          <label htmlFor={data?.name}>
            <Box
              display="flex"
              flexDirection="row"
              alignItems="center"
              height={data.type !== "cascaded" ? "10px" : "0px"}
            >
              {data.type !== "cascaded" ? (
                <Typography
                  variant="input"
                  color={
                    nextButtonClick == true &&
                    data.required &&
                    (data.type == "text" ? formData[data.name] == "" : formData[data.name]?.key == null)
                      ? "warning.main"
                      : "secondary.alt"
                  }
                >
                  {data.label}
                </Typography>
              ) : null}

              {data?.type == "password" && (
                <Tooltip
                  title="Password Length should be at least 8 Characters, containing at least 1 Uppercase, Lowercase, Number and a Special Character"
                  placement="right-start"
                >
                  <IconButton>
                    <InfoOutlinedIcon sx={{ fontSize: "13px" }} />
                  </IconButton>
                </Tooltip>
              )}
            </Box>
          </label>

          {data.type == "text" || data.type == "number" ? (
            <TextField
              // autoComplete="ViewCrunch"
              id={data.name}
              size="small"
              name={data.name}
              // autoComplete="new-password"
              margin="dense"
              fullWidth={true}
              sx={textFiledStyle}
              onChange={(event) => {
                handleDataChange(event, data.name, data.type);
              }}
              value={formData[data.name]}
              error={nextButtonClick && data.required ? (formData[data.name] != "" ? false : true) : null}
              InputProps={{
                readOnly: data?.readOnly ? data.readOnly : false,
              }}
              inputProps={{
                autoComplete: "none",
                // type: data?.type,
                maxLength: data?.maxLength ? data.maxLength : false,
              }}
            />
          ) : data.type == "datePicker" ? (
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                views={["year", "month", "day"]}
                value={formData[data.name]}
                disabled={data?.disabled}
                id={data.name}
                maxDate={data.maxDate ? data.maxDate : null}
                onChange={(newValue) => {
                  callbackFromDatePicker(data.name, newValue);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        borderRadius: "5px",
                        fontSize: "12px",
                        fontFamily: "Poppins-Medium",
                        fontWeight: "500",
                        color: "primary",
                        marginTop: "8px",
                      },

                      "& .MuiSvgIcon-root": {
                        fill: "#1161D5",
                      },
                    }}
                  />
                )}
              />
            </LocalizationProvider>
          ) : data.type == "radioButton" ? (
            <>
              {data?.options?.map((Permission, index) => {
                return (
                  <FormControl component="fieldset" variant="standard">
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={formData[data.name]}
                            onChange={(event) => {
                              handleDataChange(event, data.name);
                            }}
                            name={data.name}
                          />
                        }
                        label={Permission?.key}
                      />
                    </FormGroup>
                    <FormHelperText>{Permission?.value}</FormHelperText>
                  </FormControl>
                );
              })}
            </>
          ) : data.type == "dropdown" ? (
            <Autocomplete
              size="small"
              // limitTags={0}
              multiple={data.multiple ? true : false}
              id={data.name}
              disabled={data?.disabled}
              sx={{
                "& .MuiOutlinedInput-root": {
                  borderRadius: "5px",
                  fontSize: "12px",
                  fontFamily: "Poppins-Medium",
                  fontWeight: "500",
                  color: "primary",
                },

                "& .MuiSvgIcon-root": {
                  fill: "#1161D5",
                },
              }}
              options={data?.options ? data.options : []}
              renderOption={(props, option, { selected }) => (
                <li {...props} data-testid={`option-${option.key}`}>
                  <Box
                    sx={{
                      "& > img": {
                        mr: 2,
                        flexShrink: 0,
                      },
                    }}
                    {...props}
                    data-testid={`box-${option.key}`}
                  >
                    {data.name == "country" ? (
                      <img
                        loading="lazy"
                        width="20"
                        src={`https://flagcdn.com/w20/${option?.key.toLowerCase()}.png`}
                        srcSet={`https://flagcdn.com/w40/${option?.key.toLowerCase()}.png 2x`}
                        alt=""
                      />
                    ) : null}
                    {data?.valueDescription == true ? option?.valueDescription : option?.value}
                    {/* {option?.value} */}
                  </Box>
                </li>
              )}
              getOptionLabel={
                (option) =>
                  data?.valueDescription == true
                    ? option?.valueDescription
                      ? option.valueDescription
                      : ""
                    : option?.value
                    ? option.value
                    : ""
                // option?.value
              }
              onChange={(event, option) => {
                callbackFromMultiSelect(option, data.name);
              }}
              value={formData[data.name]}
              renderInput={(params) => {
                return (
                  <TextField
                    {...params}
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: "new-password", // disable autocomplete and autofill
                    }}
                    margin="dense"
                    variant="outlined"
                    sx={textFiledStyle}
                    fullWidth
                    error={nextButtonClick && data.required ? (formData[data.name]?.key != null ? false : true) : null}
                  />
                );
              }}
            />
          ) : data.type == "password" ? (
            <TextField
              id={data.name}
              size="small"
              name={data.name}
              type={showPassword ? "text" : "password"}
              margin="dense"
              fullWidth={true}
              sx={textFiledStyle}
              onChange={(event) => {
                handleDataChange(event, data.name);
              }}
              value={formData[data.name]}
              error={nextButtonClick && data.required ? (formData[data.name] != "" ? false : true) : null}
              inputProps={{
                // type: "password",
                autoComplete: "new-password",
              }}
              InputProps={{
                readOnly: data?.readOnly ? data.readOnly : false,
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                    >
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          ) : data.type === "switch" ? (
            <Switch
              checked={formData[data.name]}
              onChange={(event) => handleDataChange(event, data.name, data.type)}
              name={data.name}
            />
          ) : data.type == "checkBox" ? (
            <Grid item mt={1}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={data.discount}
                    onChange={(event) => {
                      handleDataChange(event, data.name, data.type);
                    }}
                    name={data.name}
                    color="primary"
                  />
                }
                label={<Typography variant="input">{data.checkboxLabel}</Typography>}
              />
            </Grid>
          ) : data.type == "cascaded" ? (
            <Grid
              item
              container
              direction="row"
              columnSpacing={2}
              justifyContent="flex-start"
              alignItems={"center"}
              // xs={data.lg}
              sx={{
                mt: -1,
                padding: 0,
              }}
            >
              {data.fields.map((field, index) => {
                return (
                  <Grid item xs={field.lg} key={field.name}>
                    {field.type == "text" || field.type == "number" ? (
                      <>
                        <Typography
                          variant="input"
                          disabled={field.disabled}
                          sx={{ lineHeight: "25px" }}
                          color={checkEmptyValue(field) ? "warning.main" : "secondary.alt"}
                        >
                          {field.label}
                        </Typography>
                        <TextField
                          id={field.name}
                          disabled={field.disabled}
                          size="small"
                          name={field.name}
                          // margin="dense"
                          fullWidth={true}
                          sx={textFiledStyle}
                          onChange={(event) => {
                            handleDataChange(event, field.name, field.type);
                          }}
                          value={formData[field.name]}
                          // error={
                          //     nextButtonClick && data.required
                          //         ? formData[data.name] != ""
                          //             ? false
                          //             : true
                          //         : null
                          // }

                          InputProps={{
                            readOnly: data?.readOnly ? data.readOnly : false,
                            startAdornment: <InputAdornment position="start">{field.icon}</InputAdornment>,
                          }}
                          inputProps={{
                            autoComplete: "none",
                            // type: data?.type,
                            maxLength: data?.maxLength ? data.maxLength : false,
                          }}
                        />
                      </>
                    ) : null}
                  </Grid>
                );
              })}
            </Grid>
          ) : null}
        </FormControl>
      </Grid>
    );
  };

  return (
    <>
      <ImageCropperModal
        open={showImageCropperModal}
        onClose={() => setShowImageCropperModal(false)}
        imageSrc={imageSrc}
        onSave={handleSave}
        isFileUploadError={isFileUploadError}
        errorMessage="The file you've selected is too large. Please upload an image file file that is 2MB or smaller."
      />
      <>
        <Grid item xs={12} md={4} sm={12} lg={2} sx={matches ? { textAlign: "center" } : null}>
          <Typography variant="input" fontSize="14px">
            Profile
          </Typography>
          <AvatarIcon
            shape={"rounded"}
            size={
              matches
                ? {
                    ...avatarStyle,
                    marginRight: "auto",
                    marginLeft: "auto",
                  }
                : avatarStyle
            }
            source={formData?.loadFromUrl ? formData.imageBlob : values.picFile}
          />
          <Button
            variant="outlined"
            id="changePhoto"
            startIcon={<CameraAltRoundedIcon />}
            sx={{
              mt: 2,
              borderRadius: "5px",
              maxWidth: "195px",
              width: "calc(100% - 20px)",
            }}
            onClick={() => {
              uploadProfilePic();
            }}
          >
            <input
              type="file"
              accept="image/*"
              onChange={handleImageChange}
              ref={photoInput}
              style={{ display: "none" }}
            />
            <Typography variant="h3">CHANGE PHOTO</Typography>
          </Button>
        </Grid>
        <Grid
          item
          container
          direction="column"
          justifyContent={justifyContent}
          xs={12}
          sm={12}
          md={8}
          lg={10}
          rowSpacing={3}
        >
          <Grid
            item
            container
            direction="row"
            // justifyContent="space-between"
            columnSpacing={5}
            rowSpacing={3}
          >
            {data.length > 0 ? data.map((data, index) => gridFunction(data, index)) : null}
          </Grid>
        </Grid>
      </>
    </>
  );
}

export default FormWidget;
