import {
  GET_RECENT_PAYMENTS,
  GET_RECENT_PAYMENTS_SUCCESS,
  GET_RECENT_PAYMENTS_FAILURE,
} from "../../constants/DashBoard/RecentPayments";

// for user authentication
export const GetRecentPayments = (data) => {
  return {
    type: GET_RECENT_PAYMENTS,
    payload: data,
  };
};
export const GetRecentPaymentsSuccess = (payload) => {
  return {
    type: GET_RECENT_PAYMENTS_SUCCESS,
    payload,
  };
};
export const GetRecentPaymentsFailure = (payload) => {
  return {
    type: GET_RECENT_PAYMENTS_FAILURE,
    payload,
  };
};
