import { LEAVE_REQUESTS, GET_LEAVE_REQUESTS_SUCCESS, GET_LEAVE_REQUESTS_FAILURE } from "store/constants/Timeoff";

export const FetchLeaveRequests = (data) => {
  return {
    type: LEAVE_REQUESTS,
    payload: data,
  };
};

export const LeaveRequestsSucess = (payload) => {
  return {
    type: GET_LEAVE_REQUESTS_SUCCESS,
    payload,
  };
};

export const LeaveRequestsFailure = (payload) => {
  return {
    type: GET_LEAVE_REQUESTS_FAILURE,
    payload,
  };
};
