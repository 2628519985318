import React from "react";
import { getValue } from "components/Screens/CommonUtils";

function ProfileInformation({ contactInfo }) {
  return (
    <div className="flex flex-wrap">
      {contactInfo.map((info) => (
        <div key={info.label} className="w-50p mt-1">
          <p className="text-headings-secondary heading-h5-regular">{info?.label}</p>
          <p className="color-text-body-secondary para-body-m-medium">{getValue(info?.value)}</p>
        </div>
      ))}
    </div>
  );
}

export default ProfileInformation;
