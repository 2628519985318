import {
  EMPLOYEE_PEERS,
  EMPLOYEE_PEERS_SUCCESS,
  EMPLOYEE_PEERS_FAILURE,
} from "../constants/EmployeePeers";

const INIT_STATE = {
  peers: [],
  isLoadingData: false,
  error: null,
};

export default (state = INIT_STATE, action) => {
  //
  switch (action.type) {
    case EMPLOYEE_PEERS:
      return {
        ...state,
        isLoadingData: true,
      };
    case EMPLOYEE_PEERS_SUCCESS:
      return {
        ...state,
        isLoadingData: false,
        peers: action?.payload?.payload,
        error: null,
      };
    case EMPLOYEE_PEERS_FAILURE:
      return {
        ...state,
        isLoadingData: false,
        peers: "Error in Fetching Employee Peers",
      };
    default:
      return state;
  }
};
