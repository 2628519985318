import { format, endOfYear } from "date-fns";

import { baseUrl } from "util/APIBaseUrl";
import postRequest from "util/APIHelperPost";
import { makeRequest } from "util/APIHelper";
import getRequest from "util/APIHelperGet";
import { USER_TYPES } from "constants/index";

const getLoggedInUser = () => JSON.parse(localStorage.getItem("loggedInUser"));

export const createLeaveRequest = async (payload) => {
  const loggedInUser = getLoggedInUser();
  const formatDateAsLocal = (date) => {
    const d = new Date(date);
    const year = d.getFullYear();
    const month = `${d.getMonth() + 1}`.padStart(2, "0"); // Months are zero-indexed
    const day = `${d.getDate()}`.padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  const startDate = formatDateAsLocal(payload?.selectedDateRange?.startDate);
  const endDate = formatDateAsLocal(payload?.selectedDateRange?.endDate);

  const timeDifferenceInMilliseconds = new Date(endDate) - new Date(startDate);
  const daysDifference = Math.ceil(timeDifferenceInMilliseconds / (1000 * 60 * 60 * 24));

  let body = {
    leaveTypeId: payload?.selectedLeaveType,
    employeeId: loggedInUser?.userId,
    dateFrom: startDate,
    dateTo: endDate,
    noOfLeaves: daysDifference,
    description: payload?.description,
  };

  body = JSON.stringify(body);

  const request = `${baseUrl}/employee/leave`;

  const res = await postRequest(request, body);

  return res;
};

export const approveOrRejectLeaveRequest = async ({
  customerId,
  requestId,
  action,
  replacementData,
  reason = "",
  loggedInUserType,
  requestReplacement = false,
}) => {
  let url;

  if (loggedInUserType === USER_TYPES.EDGE) {
    url = `${baseUrl}/admin/leave/${requestId}/action`;
  } else if (requestReplacement) {
    url = `${baseUrl}/customer/${customerId}/leave/${requestId}/replacement`;
  } else {
    url = `${baseUrl}/customer/${customerId}/leave/${requestId}`;
  }
  return await makeRequest(
    {
      path: url,
      method: "PUT",
      body: requestReplacement
        ? JSON.stringify({
            reportsTo: replacementData.reportsTo,
            credentials: replacementData.credentials,
            tasks: replacementData.tasks,
          })
        : JSON.stringify({
            action,
            reason,
            ...replacementData,
          }),
      contentType: "application/json",
    },
    false
  );
};

export const getCustomerEmployeeLeaves = async (employeeId) => {
  const loggedInUser = getLoggedInUser();
  return await getRequest(
    `${baseUrl}/customer/${loggedInUser?.customerId}/employee/${employeeId}/upcoming_leaves`,
    true
  );
};

export const getCustomerLeavesCalender = async (earliestStartDate) => {
  const loggedInUser = getLoggedInUser();
  const currentDate = new Date();
  const endOfCurrentYear = endOfYear(currentDate);
  const res = await getRequest(
    `${baseUrl}/customer/${loggedInUser?.customerId}/calendar?startDate=${earliestStartDate}&endDate=${endOfCurrentYear}`,
    true
  );
  return res;
};

export const getEmployeesTimeout = async (dateRange) => {
  const loggedInUser = getLoggedInUser();
  const res = await getRequest(
    `${baseUrl}/customer/${loggedInUser?.customerId}/leave_employees?startDate=${dateRange.startDate}&endDate=${dateRange?.endDate}`,
    true
  );
  return res;
};

export const getEmployeeLeavesDataForCustomer = async (employeeId) => {
  const loggedInUser = getLoggedInUser();
  const res = await getRequest(
    `${baseUrl}/customer/${loggedInUser?.customerId}/employee/${employeeId}/leave_types`,
    true
  );
  return res;
};

export const getEmployeePendingLeaves = async (employeeId) => {
  const loggedInUser = getLoggedInUser();
  const res = await getRequest(`${baseUrl}/customer/${loggedInUser?.customerId}/employee/${employeeId}/leave`, true);
  return res;
};

export const uploadMedicalDocs = async (leaveId, files) => {
  const formData = new FormData();
  files.forEach((file) => {
    formData.append("files", file);
  });

  const res = await postRequest(`${baseUrl}/employee/leave/document/${leaveId}`, formData, true, "POST", true);
  return res;
};

export const getMedicalDocsListing = async (leaveId) => {
  const res = await getRequest(`${baseUrl}/employee/leave/document/${leaveId}`, true);
  return res;
};

export const downloadMedicalDoc = async (leaveRequestId, documentId, name = "medical-doc") => {
  const res = await getRequest(`${baseUrl}/employee/leave/document/${leaveRequestId}/${documentId}`, true, "export");

  if (!res?.ok) {
    return null;
  }
  const url = window.URL.createObjectURL(res?.payload);
  const link = document?.createElement("a");
  link.style.display = "none";
  link.href = url;

  // Check if the filename is present in the response object and use the correct key
  link.download = name; // Use a default filename if filename is undefined
  document.body.appendChild(link);
  link.click();
  window.URL.revokeObjectURL(url);
  return res;
};

export const getLeaveCount = async (startDate, endDate, leaveTypeId) => {
  const loggedInUser = getLoggedInUser();
  const res = await getRequest(
    `${baseUrl}/employee/${loggedInUser.userId}/count_leaves/${leaveTypeId}?dateFrom=${format(
      startDate,
      "yyyy-MM-dd'T'HH:mm:ss.SSS"
    )}&dateTo=${format(endDate, "yyyy-MM-dd'T'HH:mm:ss.SSS")}`,
    true
  );
  return res;
};
