//employee constants
import {
    RESET_IMPORT_EMPLOYEES,
    IMPORT_EMPLOYEES,
    IMPORT_EMPLOYEES_SUCCESS,
    IMPORT_EMPLOYEES_FAILURE,
} from "../../../constants/Workforce/ImportEmployees";

const INIT_STATE = {
    importEmployeesInfo: {},
    loadingImportEmployees: true,
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case RESET_IMPORT_EMPLOYEES:
            return INIT_STATE;

        case IMPORT_EMPLOYEES:
            return {
                ...state,
                loadingImportEmployees: true,
            };

        case IMPORT_EMPLOYEES_SUCCESS:
            return {
                ...state,
                loadingImportEmployees: false,
                importEmployeesInfo: action.payload,
            };

        // eslint-disable-next-line no-fallthrough
        case IMPORT_EMPLOYEES_FAILURE:
            return {
                ...state,
                loadingImportEmployees: false,
                importEmployeesInfo: "Error in  getting Club Data",
            };
        default:
            return state;
    }
};
