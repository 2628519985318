import {
  RESET_ADD_USER_PERMISSIONS,
  ADD_USER_PERMISSIONS,
  ADD_USER_PERMISSIONS_SUCCESS,
  ADD_USER_PERMISSIONS_FAILURE,
} from "../../constants/Settings/AddUserPermissions";

// for add employee in admin workforce table

export const ResetAddUserPermissions = (data) => {
  return {
    type: RESET_ADD_USER_PERMISSIONS,
    payload: data,
  };
};

export const AddUserPermissions = (data) => {
  return {
    type: ADD_USER_PERMISSIONS,
    payload: data,
  };
};
export const AddUserPermissionsSuccess = (payload) => {
  return {
    type: ADD_USER_PERMISSIONS_SUCCESS,
    payload,
  };
};
export const AddUserPermissionsFailure = (payload) => {
  return {
    type: ADD_USER_PERMISSIONS_FAILURE,
    payload,
  };
};
