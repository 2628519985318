import {
  RESET_GET_DOCUMENTS_LIST,
  GET_DOCUMENTS_LIST,
  GET_DOCUMENTS_LIST_SUCCESS,
  GET_DOCUMENTS_LIST_FAILURE,
} from "../../constants/Doucments/GetDocumentsList";

// for user authentication
export const ResetGetDocumentsList = (data) => {
  return {
    type: RESET_GET_DOCUMENTS_LIST,
    payload: data,
  };
};

export const GetDocumentsList = (data) => {
  return {
    type: GET_DOCUMENTS_LIST,
    payload: data,
  };
};
export const GetDocumentsListSuccess = (payload) => {
  return {
    type: GET_DOCUMENTS_LIST_SUCCESS,
    payload,
  };
};
export const GetDocumentsListFailure = (payload) => {
  return {
    type: GET_DOCUMENTS_LIST_FAILURE,
    payload,
  };
};
