import { makeRequest } from "./APIHelper";
// import { useDispatch } from "react-redux";
// import { RegisterUser } from "../store/actions/RegisterUser";
// FOR POST REQUEST
const deleteRequest = async (path) => {
    // const dispatch = useDispatch();
    const options = {
        path: path,
        method: "DELETE",

        // contentType: "application/json",
    };
    // Return the fetch response or throw the error.
    // dispatch(RegisterUser());
    // eslint-disable-next-line no-return-await
    return await makeRequest(options);
};
export default deleteRequest;
