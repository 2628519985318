import React from "react";

import { getValue } from "components/Screens/CommonUtils";

export default function EmployeeEmergencyContact({ employeeDetails }) {
  const fullAddress = [
    employeeDetails?.emergencyContact?.addressLine1,
    employeeDetails?.emergencyContact?.zip,
    employeeDetails?.emergencyContact?.city,
    employeeDetails?.emergencyContact?.state,
    employeeDetails?.emergencyContact?.country,
  ]
    .filter(Boolean)
    .join(", ");
  const contactInfo = [
    { label: "Name", value: employeeDetails?.emergencyContact?.name },
    { label: "Relationship", value: employeeDetails?.emergencyContact?.relationship },
    { label: "Phone number", value: employeeDetails?.emergencyContact?.phoneNumber },
    { label: "Email address", value: employeeDetails?.emergencyContact?.email },
  ];

  return (
    <>
      <div className="flex flex-wrap">
        {contactInfo.map((info) => (
          <div key={info.label} className="w-50p mt-1">
            <p className="text-headings-secondary heading-h5-regular">{info.label}</p>
            <p className="color-text-body-secondary para-body-m-medium">{getValue(info.value)}</p>
          </div>
        ))}
      </div>
      <div className="mt-1">
        <p className="text-headings-secondary heading-h5-regular block">Residential address</p>
        <p className="color-text-body-secondary para-body-m-medium block">{getValue(fullAddress)}</p>
      </div>
    </>
  );
}
