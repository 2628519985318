import {
    CUSTOMER_INFO,
    CUSTOMER_INFO_SUCCESS,
    CUSTOMER_INFO_FAILURE,
    RESET_CUSTOMER_INFO
} from "../constants/CustomerInfo";

// for customer information
export const CustomerInfo = (data) => {
    return {
        type: CUSTOMER_INFO,
        payload: data,
    };
};

export const ResetCustomerInfo = (data) => {
    return {
        type: RESET_CUSTOMER_INFO,
        payload: data,
    };
};

export const CustomerInfoSuccess = (payload) => {
    return {
        type: CUSTOMER_INFO_SUCCESS,
        payload,
    };
};
export const CustomerInfoFailure = (payload) => {
    return {
        type: CUSTOMER_INFO_FAILURE,
        payload,
    };
};
