import React from "react";

const EdgeLogo = () => {
  return (
    <svg
      width="50"
      height="50"
      viewBox="0 0 50 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M24.3269 38.9004C24.2743 38.868 24.2236 38.8339 24.1701 38.8033C24.0416 38.7352 23.9345 38.6349 23.86 38.5129C23.7855 38.3909 23.7465 38.2518 23.747 38.1102C23.747 38.0499 23.747 37.9895 23.747 37.9291C23.747 29.9369 23.7446 21.9447 23.7397 13.9524C23.7397 13.3399 24.1302 12.9759 24.7219 13.0012C25.3135 13.0266 25.8879 13.0012 26.4705 13.0065C26.6243 12.9995 26.7768 13.0352 26.9103 13.1094C27.0437 13.1835 27.1523 13.2929 27.2234 13.4248C27.2851 13.5378 27.3192 13.6629 27.3231 13.7906C27.3231 13.8641 27.3231 13.9384 27.3231 14.0119V37.9081C27.3231 37.9816 27.3231 38.056 27.3231 38.1295C27.2959 38.4288 27.1654 38.6677 26.88 38.8112C26.8278 38.8288 26.7811 38.8589 26.7441 38.8987L24.3269 38.9004Z"
        fill="#109146"
      />
      <path
        d="M30.0323 38.9006C29.9852 38.8735 29.9417 38.842 29.8901 38.8192C29.757 38.7597 29.6429 38.6666 29.56 38.5497C29.4771 38.4328 29.4284 38.2965 29.419 38.1551C29.4117 38.075 29.409 37.9947 29.4108 37.9144V19.0735C29.4086 18.9859 29.4119 18.8982 29.4208 18.811C29.4357 18.6072 29.532 18.4169 29.6894 18.2804C29.8468 18.1439 30.0529 18.0719 30.2643 18.0794C30.7173 18.0724 31.1794 18.0794 31.6369 18.0794H32.0328C32.5312 18.0794 32.7694 18.2378 32.947 18.6814V38.2977C32.9158 38.421 32.8542 38.5353 32.7675 38.6306C32.6807 38.726 32.5713 38.7997 32.4487 38.8455C32.4186 38.8613 32.3901 38.8797 32.3635 38.9006H30.0323Z"
        fill="#131599"
      />
      <path
        d="M18.001 22.6415V14.2409C17.9974 14.1201 18.0037 13.9993 18.02 13.8795C18.0898 13.4612 18.3824 13.2074 18.8164 13.2048C19.4678 13.2048 20.1211 13.2144 20.7725 13.2004C20.957 13.2045 21.1345 13.2688 21.2762 13.3829C21.4179 13.4971 21.5153 13.6542 21.5526 13.8287C21.5681 13.9213 21.576 14.015 21.5762 14.1088V31.1724C21.5762 31.2127 21.5762 31.2529 21.5762 31.2923C21.5481 31.8051 21.2255 32.107 20.6928 32.1105C20.0888 32.114 19.4848 32.114 18.8807 32.1105C18.3634 32.1105 18.0363 31.8025 18.0046 31.3037C17.9992 31.2241 18.0046 31.1436 18.0046 31.0631L18.001 22.6415Z"
        fill="#F0525F"
      />
    </svg>
  );
};

export default EdgeLogo;
