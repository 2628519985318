import { Chip } from "@mui/material";

import PendingLeaveIcon from "assets/images/pending-leave-chip-icon.svg";
import CheckMarksIcon from "assets/images/taken-leave-icon.svg";

import "../LeaveType/LeaveType.css";

function EditByHrStatus({ type, style, hrEdit, hrApproved, leaveStatus }) {
  type = type?.toLowerCase?.();
  let chipClassName = "cancelled-chip";
  let chipIcon = "";
  let chipLabel = "N/A";
  let pendingIcon = "";

  if (hrEdit && !hrApproved && leaveStatus === "Approved") {
    chipClassName = "pending-chip";
    chipIcon = "pending-icon";
    chipLabel = "Pending";
    pendingIcon = PendingLeaveIcon;
  } else if (hrApproved && leaveStatus === "Approved") {
    chipClassName = "taken-chip";
    chipIcon = "scheduled-icon";
    chipLabel = "Complete";
    pendingIcon = CheckMarksIcon;
  } else {
    chipClassName = "cancelled-chip";
    chipIcon = "";
    chipLabel = "N/A";
    pendingIcon = "";
  }

  return (
    <Chip
      icon={pendingIcon && <img className={chipIcon} src={pendingIcon} alt={type} />}
      className={chipClassName + " label-sm custom-chip"}
      size="small"
      id="leave-status"
      label={chipLabel}
      sx={{
        ...style,
      }}
    />
  );
}

export default EditByHrStatus;
