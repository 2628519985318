//employee constants
import {
  RESET_GET_CUSTOMER_LIST,
  GET_CUSTOMER_LIST,
  GET_CUSTOMER_LIST_SUCCESS,
  GET_CUSTOMER_LIST_FAILURE,
} from "../../constants/Customers/GetCustomerList";

const INIT_STATE = {
  getCustomerList: {},
  loadingGetCustomerList: false,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case RESET_GET_CUSTOMER_LIST:
      return INIT_STATE;

    case GET_CUSTOMER_LIST:
      return {
        ...state,
        loadingGetCustomerList: true,
      };
    case GET_CUSTOMER_LIST_SUCCESS:
      return {
        ...state,
        loadingGetCustomerList: false,
        getCustomerList: action.payload,
      };
    // eslint-disable-next-line no-fallthrough
    case GET_CUSTOMER_LIST_FAILURE:
      return {
        ...state,
        loadingGetCustomerList: false,
        getCustomerList: "Error in  getting Club Data",
      };
    default:
      return state;
  }
};
