import {
    PAYONEER_GET_EMPLOYEE_STATUS,
    PAYONEER_SET_EMPLOYEE_STATUS,
    PAYONEER_GET_EMPLOYEE_STATUS_FAILED,
  } from "store/constants/Payoneer/PayoneerGetEmployeeStatus";
  
  export const fetchStatus = () => {
    return {
      type: PAYONEER_GET_EMPLOYEE_STATUS,
    };
  };
  
  export const setStatus = (balanceInfo) => {
    return { type: PAYONEER_SET_EMPLOYEE_STATUS, payload: balanceInfo };
  };
  
  export const fetchStatusFailed = (error) => {
    return { type: PAYONEER_GET_EMPLOYEE_STATUS_FAILED, payload: error };
  };
  