import {
    PAYROLL_SUMMARY,
    PAYROLL_SUMMARY_SUCCESS,
    PAYROLL_SUMMARY_FAILURE,
} from "../constants/PayrollSummary";

// for customer information
export const PayrollSummary = (data) => {
    return {
        type: PAYROLL_SUMMARY,
        payload: data,
    };
};
export const PayrollSummarySuccess = (payload) => {
    return {
        type: PAYROLL_SUMMARY_SUCCESS,
        payload,
    };
};
export const PayrollSummaryFailure = (payload) => {
    return {
        type: PAYROLL_SUMMARY_FAILURE,
        payload,
    };
};
