import { Box, Grid, Typography } from "@mui/material";
import React from "react";

function CurrentSalary(props) {
  const { costToCompany, employeeSalaryDetails } = props;
  //component state
  // const [costCompany, SetCostCompany] = React.useState("");

  //methods
  // React.useEffect(() => {
  //     let costCompany = "";
  //     if (employeeSalaryDetails?.incrementDetails?.incrementAmount) {
  //         costCompany =
  //             employeeSalaryDetails?.costToCompany -
  //             employeeSalaryDetails?.incrementDetails?.incrementAmount;
  //     } else {
  //         costCompany = employeeSalaryDetails?.costToCompany;
  //     }
  //     SetCostCompany(costCompany);
  // }, [employeeSalaryDetails]);

  return (
    <Grid
      item
      container
      direction="column"
      alignItems="center"
      justifyContent="center"
      sx={{ minWidth: "145px" }}
    >
      <Typography
        variant="h3"
        fontSize="16px"
        fontFamily="Poppins-Medium"
        textAlign="start"
        color="#000000"
        sx={{ height: "62px", display: "flex", alignItems: "center" }}
      >
        $ {costToCompany}
      </Typography>
    </Grid>
  );
}

export default CurrentSalary;
