import React, { useEffect, useState } from "react";
import UpcomingLeavesAndHolidaysBar from "../../ManageTimeoff/UpcomingLeavesAndHolidaysBar";
import { Stack } from "@mui/material";
import CalendarIcon from "./Icons/CalendarIcon";
import { useSelector } from "react-redux";

function EmployeeUpcommingHolidays(props) {
  const { portal } = props;

  const upcomingLeaves = useSelector((state) => state?.TimeoffUpcomingLeaves?.upcomingLeaves);
  const allHolidays = useSelector((state) => state?.Holidays?.allHolidays);

  const [totalHolidays, setTotalHolidays] = useState(0);

  useEffect(() => {
    let leavesHolidays = [];
    let holidays = [];

    if (portal === "admin") {
      holidays = allHolidays?.result?.holidays || [];
      leavesHolidays = allHolidays?.result?.leaves || [];
    } else {
      holidays = allHolidays?.data || [];
    }

    setTotalHolidays(holidays?.length + leavesHolidays?.length);
  }, [upcomingLeaves, allHolidays]);

  return (
    <div style={{ padding: "19px 60px" }}>
      <Stack direction="row" spacing="12px" alignItems="center" sx={{ mb: "18px" }}>
        <CalendarIcon />
        <div className="timeoff-subheading upcoming-leaves-margin">Upcoming holidays ({totalHolidays || 0})</div>
      </Stack>
      <UpcomingLeavesAndHolidaysBar portal={portal} />
    </div>
  );
}

export default EmployeeUpcommingHolidays;
