import {
  RESET_SEARCH_EMPLOYEE_AND_CUSTOMER,
  SEARCH_EMPLOYEE_AND_CUSTOMER,
  SEARCH_EMPLOYEE_AND_CUSTOMER_SUCCESS,
  SEARCH_EMPLOYEE_AND_CUSTOMER_FAILURE,
} from "../../constants/SearchEmployee&Customer";

// for add employee in admin workforce table

export const ResetSearchEmployeeAndCustomer = (data) => {
  return {
    type: RESET_SEARCH_EMPLOYEE_AND_CUSTOMER,
    payload: data,
  };
};

export const SearchEmployeeAndCustomer = (data) => {
  return {
    type: SEARCH_EMPLOYEE_AND_CUSTOMER,
    payload: data,
  };
};
export const SearchEmployeeAndCustomerSuccess = (payload) => {
  return {
    type: SEARCH_EMPLOYEE_AND_CUSTOMER_SUCCESS,
    payload,
  };
};
export const SearchEmployeeAndCustomerFailure = (payload) => {
  return {
    type: SEARCH_EMPLOYEE_AND_CUSTOMER_FAILURE,
    payload,
  };
};
