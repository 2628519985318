import { useState } from "react";

import { Popper, Paper, ClickAwayListener } from "@mui/material";
import MoreVertOutlinedIcon from "@mui/icons-material/MoreVertOutlined";

import { MenuItem, MenuList } from "components/common/Menu";

function EditDeleteMenu({ setOpenModal, menuItem, isSourceEdge = false }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const source = isSourceEdge ? "Edge" : "";

  const handleClick = (event) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <div className="icon-wrapper">
      <MoreVertOutlinedIcon className="timeline-more-icon" onClick={handleClick} />
      <Popper
        open={open}
        anchorEl={anchorEl}
        placement="bottom-end"
        disablePortal={false}
        modifiers={[
          {
            name: "flip",
            enabled: true,
            options: {
              altBoundary: true,
              rootBoundary: "viewport",
              padding: 8,
            },
          },
        ]}
      >
        <ClickAwayListener onClickAway={handleClose}>
          <Paper
            sx={{
              boxShadow: "0px 4px 8px 0px var(--color-elevation-default, rgba(0, 0, 0, 0.16)) !important",
            }}
            className="paper-style"
          >
            <MenuList autoWidth>
              <MenuItem
                key="edit"
                value="Edit"
                isDense
                className="para-body-m-regular"
                onClick={() => {
                  setOpenModal({ show: true, mode: "Edit", data: menuItem, source });
                  handleClose();
                }}
              />
              {source !== "Edge" && (
                <MenuItem
                  key="delete"
                  value="Delete"
                  variant="danger"
                  className="para-body-m-regular"
                  isDense
                  onClick={() => {
                    setOpenModal({ show: true, mode: "Delete", data: menuItem, source });
                    handleClose();
                  }}
                />
              )}
            </MenuList>
          </Paper>
        </ClickAwayListener>
      </Popper>
    </div>
  );
}

export default EditDeleteMenu;
