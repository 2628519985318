//employee constants
import { RESET_ADD_EMPLOYEE, ADD_EMPLOYEE, ADD_EMPLOYEE_SUCCESS, ADD_EMPLOYEE_FAILURE } from "../constants/AddEmployee";

const INIT_STATE = {
  addEmployeeInfo: {},
  loadingAddEmployeeInfo: false,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case RESET_ADD_EMPLOYEE:
      return INIT_STATE;

    case ADD_EMPLOYEE:
      return {
        ...state,
        loadingAddEmployeeInfo: true,
      };
    case ADD_EMPLOYEE_SUCCESS:
      return {
        ...state,
        loadingAddEmployeeInfo: false,
        addEmployeeInfo: action.payload,
      };
    // eslint-disable-next-line no-fallthrough
    case ADD_EMPLOYEE_FAILURE:
      return {
        ...state,
        loadingAddEmployeeInfo: false,
        addEmployeeInfo: "Error in  getting Club Data",
      };
    default:
      return state;
  }
};
