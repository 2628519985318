import { IconButton, Stack, Tooltip } from "@mui/material";
import React from "react";
import PeruIcon from "./Icons/PeruIcon";
import PakistanIcon from "./Icons/PakistanIcon";
import AvatarIcon from "components/Icons/AvatarIcon";

function EmployeeAvatar(props) {
  const { img, name, jobTitle, country, size, subtitleColor } = props;
  const avatarSize = size || {
    width: "38.5px",
    height: "38.5px",
    borderRadius: "50%",
  };
  return (
    <Stack direction="row" alignItems="center" spacing="10px">
      <div style={{ position: "relative" }}>
        {/* <img className="list-leaves-icon w-5 h-5" style={{ clipPath: "circle(40%)" }} src={img} alt="Profile" /> */}
        <AvatarIcon shape={"circle"} size={avatarSize} source={img} />
        {country && (
          <IconButton
            sx={{ position: "absolute", right: "0px", bottom: "-2px", width: "17px", height: "17px", padding: "0px" }}
          >
            {country === "Peru" ? <PeruIcon /> : <PakistanIcon />}
          </IconButton>
        )}
      </div>

      {(name || jobTitle) && (
        <div>
          {name && (
            <Tooltip title={name}>
              <div
                style={{
                  lineHeight: "18px",
                  fontSize: "13px",
                  weight: 600,
                  letterSpacing: "0.15px",
                  fontFamily: "Poppins-SemiBold",
                }}
                className="ellipsis-two-line"
              >
                {name}
              </div>
            </Tooltip>
          )}

          {jobTitle && (
            <div
              className="label-sm neutral-30"
              style={{
                fontFamily: "Poppins-Regular",
                fontSize: "11px",
                lineHeight: "16px",
                color: "#666899",
              }}
            >
              {jobTitle}
            </div>
          )}
        </div>
      )}
    </Stack>
  );
}

export default EmployeeAvatar;
