import React from "react";
import DisplayCompanyInfo from "./DisplayCompanyInfo";
//redux dispatch
import { useSelector, useDispatch } from "react-redux";
import { getValue } from "../CommonUtils";
import {
  EditCompanyProfileInfo,
  ResetEditCompanyProfile,
} from "../../../store/actions/Settings/EditCompanyInfo";
import { GetCustomerUserandCompanyProfileData } from "../../../store/actions/Settings/CustomerUser&CompanyProfileData";
import { convertTimezonesToDropdownOptions } from "../../../util/utils";

const countries =
  convertTimezonesToDropdownOptions(
    JSON.parse(sessionStorage?.getItem("dropdownData"))?.payload?.country
  ) || [];

const initialGeneralInfo = {
  country: "",
  state: "",
  addressLine1: "",
  zip: "",
  city: "",
};

const initailModal = {
  modalName: null,
  edit: false,
};

function GeneralInfo(props) {
  const { title } = props;
  const dispatch = useDispatch();

  const countries = React.useMemo(() => {
    return (
      convertTimezonesToDropdownOptions(
        JSON.parse(sessionStorage?.getItem("dropdownData"))?.payload?.country
      ) || []
    );
  }, []);

  let generalInitialvalues = [
    {
      title: "Country",
      value: null,
      name: "country",
      dropdownValues: countries,
      type: "select",
    },
    {
      title: "State",
      value: null,
      name: "state",
      type: "text",
    },
    {
      title: "Address",
      value: null,
      name: "addressLine1",
      type: "text",
    },
    {
      title: "Code",
      value: null,
      name: "zip",
      type: "number",
    },
    {
      title: "City",
      value: null,
      name: "city",
      type: "text",
    },
  ];

  // component state
  const [generalInfo, setGeneralInfo] = React.useState(initialGeneralInfo);
  const [editAbleModal, setEditAbleModal] = React.useState(initailModal);
  const [displayGeneralInfo, setDisplayGeneralInfo] =
    React.useState(generalInitialvalues);
  const [openAlert, setOpenAlert] = React.useState(false);
  const [saveInfo, setSaveInfo] = React.useState(false);

  //redux state
  const CompanyProfileData = useSelector(
    (state) =>
      state.CustomerUserandCompanyProfileData.customerUserandCompanyProfileData
  );

  const editGeneralInfo = useSelector(
    (state) => state.EditCompanyInfo.editCompanyInfo
  );

  //functions

  const handleInputChange = (event, fieldName) => {
    setGeneralInfo({
      ...generalInfo,
      [event.target.name]: event.target.value,
    });
  };

  const hanldeEdit = (editableModal) => {
    setEditAbleModal({
      modalName: editableModal,
      edit: true,
    });
  };

  const handleSave = (modalName, editable) => {
    handleSaveInfo();
  };

  const handleCancel = (modalName, editable) => {
    // setGeneralInfo(initialGeneralInfo);

    setEditAbleModal({
      modalName: modalName,
      edit: false,
    });
  };

  const handleSaveInfo = (event) => {
    let loggedInUser = {};
    let logoRequest = "";
    loggedInUser = JSON.parse(localStorage.getItem("loggedInUser"));
    if (loggedInUser?.type == "customer") {
      logoRequest = `customer/${loggedInUser?.customerId}/logo`;
    }
    setSaveInfo(true);
    if (generalInfo.country != "") {
      setOpenAlert(false);
      dispatch(
        EditCompanyProfileInfo({
          companyProfileData: generalInfo,
          customerId: loggedInUser?.userId,
        })
      );
    } else {
      setOpenAlert(true);
    }
  };

  //methods

  React.useEffect(() => {
    let loggedInUser = {};
    loggedInUser = JSON.parse(localStorage.getItem("loggedInUser"));

    let getProfileDataRequest = `customerUser/${loggedInUser?.userId}`;
    if (editGeneralInfo?.ok) {
      setEditAbleModal({
        modalName: null,
        edit: false,
      });
      dispatch(
        GetCustomerUserandCompanyProfileData({
          request: getProfileDataRequest,
        })
      );
      dispatch(ResetEditCompanyProfile({}));
    }
  }, [editGeneralInfo]);

  React.useEffect(() => {
    if (CompanyProfileData?.ok) {
      setGeneralInfo({
        country: getValue(CompanyProfileData?.payload?.customer?.country),
        state: getValue(CompanyProfileData?.payload?.customer?.state),
        addressLine1: getValue(
          CompanyProfileData?.payload?.customer?.addressLine1
        ),
        zip: getValue(CompanyProfileData?.payload?.customer?.zip),
        city: getValue(CompanyProfileData?.payload?.customer?.city),
      });

      generalInitialvalues[0].value = getValue(
        CompanyProfileData?.payload?.customer?.country
      );
      generalInitialvalues[1].value = getValue(
        CompanyProfileData?.payload?.customer?.state
      );
      generalInitialvalues[2].value = getValue(
        CompanyProfileData?.payload?.customer?.addressLine1
      );
      generalInitialvalues[3].value = getValue(
        CompanyProfileData?.payload?.customer?.zip
      );
      generalInitialvalues[4].value = getValue(
        CompanyProfileData?.payload?.customer?.city
      );
      setDisplayGeneralInfo(generalInitialvalues);
    }
  }, [CompanyProfileData]);

  return (
    <DisplayCompanyInfo
      data={displayGeneralInfo}
      title={title}
      fieldsData={generalInfo}
      hanldeEdit={hanldeEdit}
      editAbleModal={editAbleModal}
      handleInputChange={handleInputChange}
      handleCancel={handleCancel}
      handleSave={handleSave}
      icon={true}
      bgColor="#FFEFD3"
      iconColor="#F9BD00"
      open={openAlert}
      setOpenAlert={setOpenAlert}
      editCompanyInfo={editGeneralInfo}
      saveInfo={saveInfo}
    />
  );
}

export default GeneralInfo;
