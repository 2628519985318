import {
  PAYONEER_SAVE_ACCESS_TOKEN_FETCH,
  PAYONEER_SAVE_ACCESS_TOKEN_SUCCESS,
  PAYONEER_PAYONEER_SAVE_ACCESS_TOKEN_FAILED,
} from "store/constants/Payoneer/PayoneerSaveAccessToken";

const initialState = {
  saveInfo: {
    saveAccessToken: false,
    error: null,
    isLoading: false,
  },
};

const saveAccessTokenReducer = (state = initialState, action) => {
  switch (action.type) {
    case PAYONEER_SAVE_ACCESS_TOKEN_FETCH:
      return {
        ...state,
        saveInfo: {
          saveAccessToken: false,
          error: null,
          isLoading: true,
        },
      };
    case PAYONEER_SAVE_ACCESS_TOKEN_SUCCESS:
      return {
        ...state,
        saveInfo: {
          saveAccessToken: true,
          error: null,
          isLoading: false,
        },
      };
    case PAYONEER_PAYONEER_SAVE_ACCESS_TOKEN_FAILED:
      return {
        ...state,
        saveInfo: {
          saveAccessToken: false,
          error: action.payload,
          isLoading: false,
        },
      };
    default:
      return state;
  }
};

export default saveAccessTokenReducer;
