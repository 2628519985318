import { call, put, takeEvery } from "redux-saga/effects";
import {
  AddEmployeeEducationSuccess,
  AddEmployeeEducationFailure,
} from "../../../actions/Workforce/AddEmployee/AddEmployeeEducation";
import { ADD_EMPLOYEE_Education } from "../../../constants/Workforce/AddEmployee/AddEmployeeEducation";
import postRequest from "../../../../util/APIHelperPost";
import { baseUrl } from "../../../../util/APIBaseUrl";

export const fetchAddEmployeeEducationRequest = async (actions) => {
  let body = JSON.stringify({
    degreeName: actions.payload?.educationData?.degree,
    schoolName: actions.payload?.educationData?.schoolName,
    fieldOfStudy: actions.payload?.educationData?.fieldOfStudy,
    startDate: actions.payload?.educationData?.dateAttendedFrom,
    endDate: actions.payload?.educationData?.dateAttendedTo,
    degreeDetail: actions.payload?.educationData?.description,
  });

  return await postRequest(
    `${baseUrl}/employee/${actions.payload.employeeId}/education`,
    body
  )
    .then((response) => response)
    .catch((error) => {
      throw error;
    });
};

function* fetchAddEmployeeEducation(params) {
  try {
    let response = yield call(fetchAddEmployeeEducationRequest, params);
    yield put(AddEmployeeEducationSuccess(response));
  } catch (error) {
    console.error(error);
    yield put(AddEmployeeEducationFailure(error));
  }
}

export default function* rootSaga() {
  yield takeEvery(ADD_EMPLOYEE_Education, fetchAddEmployeeEducation);
}
