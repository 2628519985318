/* eslint-disable react/jsx-key */
import React from "react";
//material ui
import { Avatar, Grid, Typography } from "@mui/material";
import { capitalizeFirstLetter } from "../Screens/CommonUtils";

function gridFunction(
  data,
  shape,
  icon,
  height,
  width,
  type,
  source,
  textColor
) {
  //constants
  return (
    <Grid
      item
      container
      direction="column"
      textAlign="start"
      gap="6px"
      sx={{ minWidth: "220px" }}
    >
      <Typography
        sx={{
          fontFamily: "Poppins-Medium",
          fontSize: "14px",
          lineHeight: "20px",
          color: "#212231",
        }}
      >
        {capitalizeFirstLetter(data?.name)}
      </Typography>
      <Typography
        sx={{
          fontFamily: "Poppins-Regular",
          fontSize: "12px",
          color: "#7C7EB6",
        }}
      >
        {capitalizeFirstLetter(data?.role)}
      </Typography>
    </Grid>
  );
}

function ProfleWithIcon(props) {
  const { data, shape, icon, height, width, type, source, textColor } = props;

  return (
    <>
      {data
        ? gridFunction(
            data,
            shape,
            icon,
            height,
            width,
            type,
            source,
            textColor
          )
        : null}
    </>
  );
}

export default ProfleWithIcon;
