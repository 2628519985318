import {
  Button,
  IconButton,
  Typography,
  Snackbar,
  Alert,
  List,
  ListItem,
  Grid,
  RadioGroup,
  FormControl,
  FormLabel,
  FormControlLabel,
  Radio,
  Autocomplete,
  TextField,
} from "@mui/material";
import React from "react";
//redux dispatch
import { useDispatch, useSelector } from "react-redux";
//dialog
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
// Icons
import SaveRoundedIcon from "@mui/icons-material/SaveRounded";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import RemoveRoundedIcon from "@mui/icons-material/RemoveRounded";
import { DropdownData } from "../../store/actions/DropdownData";
import { useCallback } from "react";

function EditPermissions(props) {
  const { selectedId, handleEditPermissions, title, defaultPermissions, mode } =
    props;

  //component state
  const [openDialog, setOpenDialog] = React.useState(false);
  const [openAlert, setOpenAlert] = React.useState(false);
  const [action, setAction] = React.useState(null);

  const [dropdownData_, setDropdownData_] = React.useState({});
  const [selectedPermission, setSelectedPermission] = React.useState({});
  const [confirmButtonClicked, setConfirmButtonClicked] = React.useState(false);

  //redux state

  const removePermissions = useSelector(
    (state) => state.RemovePermissions.removePermissions
  );

  //function

  const selectPermissions = (event, option) => {
    if (option) {
      setSelectedPermission(option);
    } else {
      setSelectedPermission({
        key: null,
        value: "None",
        valueDescription: "None",
      });
    }
  };

  const handleAddPermissions = () => {
    setOpenDialog(true);
    setAction("addPermission");
  };

  const handleRemovePermissions = () => {
    setOpenDialog(true);
    setAction("removePermission");
  };

  const handleClose = () => {
    setOpenDialog(false);
  };

  const handleDialogClose = () => {
    setOpenDialog(false);
  };

  const handleAlertClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenAlert(false);
  };

  const getError = (errors) => {
    if (errors != null && errors != undefined) {
      return errors.map((error, index) => {
        return (
          <List
            sx={{
              listStyleType: "disc",
              paddingRight: "0px !important",
              pl: 3,
              "& .MuiListItem-root": {
                paddingRight: "0px",
                paddingLeft: "0px",
                paddingTop: "0px",
                paddingBottom: "0px",
              },
            }}
          >
            <ListItem
              sx={{
                display: "list-item",
              }}
            >
              <Typography variant="h6" color="secondary" fontSize="12px">
                {(error?.msg
                  ? error.msg
                  : error?.description
                  ? error.description
                  : "") +
                  " " +
                  (error?.param ? error?.param : "")}
              </Typography>
            </ListItem>
          </List>
        );
      });
    } else {
      return null;
    }
  };

  //methods
  React.useEffect(() => {
    if (Object.keys(removePermissions).length && removePermissions?.ok) {
      setOpenDialog(false);
    } else if (removePermissions?.ok == false) {
      setOpenAlert(true);
    }
  }, [removePermissions]);

  const searchPermissionByPriority = () => {
    let foundObject = null;
    const priorityList = ["EdgeAdmin", "EdgeHREmployee", "EdgeEmployee"];

    for (let priority of priorityList) {
      // foundObject = arrayOfObjects.find((obj) => obj.priority === priority);
      if (defaultPermissions.includes(priority)) {
        foundObject = dropdownData_?.Permissions?.find(
          (permission) => permission.permissionName === priority
        );
      }

      if (foundObject) {
        break;
      }
    }

    return foundObject;
  };

  const getSelectedPermission = useCallback(() => {
    const permission = searchPermissionByPriority();

    return {
      key: permission?.key,
      value: permission?.value,
      valueDescription: permission?.valueDescription,
      permissionName: permission?.permissionName,
    };
  }, [defaultPermissions, dropdownData_]);

  React.useEffect(() => {
    const permission = getSelectedPermission();

    setSelectedPermission(permission);
  }, [defaultPermissions, dropdownData_]);

  React.useEffect(() => {
    let loggedInUser = {};
    let dropdownData = {};

    loggedInUser = JSON.parse(localStorage.getItem("loggedInUser"));
    dropdownData = JSON.parse(sessionStorage.getItem("dropdownData"));

    if (true) {
      let edgePermissions = [];
      let customerPermissions = [];
      let Permissions = [];

      if (
        dropdownData != undefined &&
        Object.keys(dropdownData).length &&
        dropdownData.ok
      ) {
        dropdownData?.payload?.edgePermission?.map((permission, index) => {
          edgePermissions.push({
            key: permission.permissionName,
            permissionName: permission.permissionName,
            value: permission.permissionName,
            valueDescription:
              permission.permissionName == "EdgeAdmin"
                ? "Admin"
                : permission.permissionName == "EdgeEmployee"
                ? "Employee"
                : permission.permissionName == "EdgeHREmployee"
                ? "HR Employee"
                : null,
          });
        });

        dropdownData?.payload?.customerPermission?.map((permission, index) => {
          customerPermissions.push({
            key: permission.permissionName,
            permissionName: permission.permissionName,
            value: permission.permissionName,

            valueDescription:
              permission.permissionName == "CustomerAdmin"
                ? "Admin"
                : permission.permissionName == "CustomerEmployee"
                ? "Employee"
                : permission.permissionName == "CustomerHREmployee"
                ? "HR Employee"
                : null,
          });
        });

        if (loggedInUser?.type == "customer") {
          Permissions = customerPermissions;
        } else if (loggedInUser?.type == "edge") {
          Permissions = edgePermissions;
        }

        setDropdownData_({
          ...dropdownData_,

          Permissions: Permissions,
        });
      }
    }
  }, []);

  return (
    <>
      {mode == "remove" ? (
        <IconButton
          size="small"
          sx={{ "&:hover": { backgroundColor: "white" } }}
          onClick={handleRemovePermissions}
        >
          <RemoveRoundedIcon
            fontSize="small"
            color="muted"
            sx={{ "&:hover": { color: "warning.main" } }}
          />
        </IconButton>
      ) : (
        <IconButton
          size="small"
          sx={{ "&:hover": { backgroundColor: "white" } }}
          onClick={handleAddPermissions}
        >
          <AddRoundedIcon
            fontSize="small"
            color="muted"
            sx={{ "&:hover": { color: "#116D15" } }}
          />
        </IconButton>
      )}

      <Dialog
        open={openDialog}
        onClose={handleDialogClose}
        sx={{
          "& .MuiPaper-root": {
            width: "400px",
          },
        }}
      >
        <DialogTitle>{`Manage ${title}`} </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <FormControl variant="outlined" sx={{ width: "350px" }}>
              <label>
                <Typography
                  variant="h6"
                  fontWeight={500}
                  color={"#000000"}
                  sx={{
                    fontFamily: "Poppins-Regular !important",
                    color:
                      !Object.keys(selectedPermission).length &&
                      confirmButtonClicked
                        ? "red"
                        : "080D46",
                    fontSize: "15px",
                  }}
                >
                  {mode == "remove"
                    ? "Remove Permissions *"
                    : "Add Permissions *"}
                </Typography>
              </label>
              <Autocomplete
                size="small"
                id="standard"
                sx={{
                  "& .MuiOutlinedInput-root": {
                    padding: "2px",
                    borderRadius: "9px",
                    fontSize: "11px",
                  },

                  "& .MuiSvgIcon-root": {
                    fill: "#1161D5",
                  },
                }}
                options={
                  dropdownData_?.Permissions ? dropdownData_.Permissions : []
                }
                getOptionLabel={(option) => option.valueDescription}
                onChange={selectPermissions}
                value={selectedPermission}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: "new-password", // disable autocomplete and autofill
                    }}
                    margin="dense"
                    name="countryLocation"
                    fullWidth={true}
                    // sx={textFiledStyle}
                    variant="outlined"
                  />
                )}
              />
            </FormControl>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Snackbar
            open={openAlert}
            autoHideDuration={2500}
            onClose={handleAlertClose}
            sx={{
              "&.MuiSnackbar-root": {
                position: "relative",
                bottom: 9,
                left: 0,
              },
            }}
          >
            <Alert onClose={handleAlertClose} severity="error">
              {removePermissions?.ok == false
                ? getError(removePermissions?.payload?.error)
                : null}
            </Alert>
          </Snackbar>
          <Button onClick={handleClose}>
            <Typography
              variant="h6Sidebar"
              onClick={() => {
                setConfirmButtonClicked(false);
                // setSelectedPermission([]);
              }}
            >
              Cancel
            </Typography>
          </Button>
          <Button
            variant="contained"
            startIcon={<SaveRoundedIcon />}
            fullWidth
            sx={{
              width: "140px",
              borderRadius: 1,
            }}
            onClick={() => {
              setConfirmButtonClicked(true);
              handleEditPermissions(selectedId, selectedPermission, action);
            }}
          >
            <Typography variant="h6Sidebar" color="white">
              Confirm
            </Typography>
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default EditPermissions;
