//employee constants
import { EMPLOYEE_INFO, EMPLOYEE_INFO_SUCCESS, EMPLOYEE_INFO_FAILURE } from "../constants/EmployeeInfo";

const INIT_STATE = {
  employeesInfo: [],
  loadingEmployeesInfo: false,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case EMPLOYEE_INFO:
      return {
        ...state,
        loadingEmployeesInfo: true,
      };
    case EMPLOYEE_INFO_SUCCESS:
      return {
        ...state,
        loadingEmployeesInfo: false,
        employeesInfo: action?.payload?.payload,
      };
    // eslint-disable-next-line no-fallthrough
    case EMPLOYEE_INFO_FAILURE:
      return {
        ...state,
        loadingEmployeesInfo: false,
        employeesInfo: "Error in  getting Club Data",
      };
    default:
      return state;
  }
};
