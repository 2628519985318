import React from "react";
import Skeleton from "react-loading-skeleton";
import { Card, CardContent, Grid, Typography, Chip, Divider } from "@mui/material";
import "react-loading-skeleton/dist/skeleton.css";

const PayoneerStatusCardSkeleton = () => {
  return (
    <>
      <Grid container className="justify-center items-center w-full">
        <div className="peers-wrapper">
          <Card>
            <CardContent className="mt-05">
              <Skeleton width={150} />
              <Grid className="flex flex-col items-center">
                <Typography className="label-lg">
                  <Skeleton width={200} />
                </Typography>
              </Grid>
              <Grid className="flex flex-row">
                <Typography></Typography>
                <Typography>
                  <Skeleton width={100} />
                </Typography>
              </Grid>
            </CardContent>
            <Divider />
                <Grid className="flex justify-between ml-1 mt-1">
                  <Grid className="mt-025">
                    <Typography>
                      <Skeleton width={100} />
                    </Typography>
                    <Grid className="flex flex-row">
                      <Skeleton width={250} />
                    </Grid>
                  </Grid>
                  <Grid>
                    <Chip
                      className="align-self-center m-1 text-center items-center justify-center"
                      label={
                        <div className="payoneer-label-go-to-payoneer">
                          <Skeleton width={100} h/>
                        </div>
                      }
                    />
                  </Grid>
              </Grid>
          </Card>
        </div>
      </Grid>
    </>
  );
};

export default PayoneerStatusCardSkeleton;
