import {
  GET_EMPLOYEE_BY_ID,
  GET_EMPLOYEE_BY_ID_SUCCESS,
  GET_EMPLOYEE_BY_ID_FAILURE,
} from "../../constants/EmployeePortal/GetEmployeeDetials";

export const GetEmployeeById = (data) => {
  return {
    type: GET_EMPLOYEE_BY_ID,
    payload: data,
  };
};
export const GetEmployeeByIdSuccess = (payload) => {
  return {
    type: GET_EMPLOYEE_BY_ID_SUCCESS,
    payload,
  };
};
export const GetEmployeeByIdFailure = (payload) => {
  return {
    type: GET_EMPLOYEE_BY_ID_FAILURE,
    payload,
  };
};
