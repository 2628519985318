//employee constants
import {
  RESET_ADD_ACH_BANK_INFO,
  ADD_ACH_BANK_INFO,
  ADD_ACH_BANK_INFO_SUCCESS,
  ADD_ACH_BANK_INFO_FAILURE,
} from "../../constants/Payment/AddAchInfo";

const INIT_STATE = {
  addAchBankInfo: {},
  loadingAddAchBankInfo: false,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case RESET_ADD_ACH_BANK_INFO:
      return INIT_STATE;
    case ADD_ACH_BANK_INFO:
      return {
        ...state,
        loadingAddAchBankInfo: true,
      };
    case ADD_ACH_BANK_INFO_SUCCESS:
      // if (action.payload.ok) {
      //   localStorage.setItem("bankAccountDetails", action?.payload?.payload);
      return {
        ...state,
        loadingAddAchBankInfo: false,
        addAchBankInfo: action.payload,
      };
    // }
    // eslint-disable-next-line no-fallthrough
    case ADD_ACH_BANK_INFO_FAILURE:
      return {
        ...state,
        loadingAddAchBankInfo: false,
        addAchBankInfo: "Error in  getting Club Data",
      };
    default:
      return state;
  }
};
