import React from "react";
import { useSelector } from "react-redux";

import { Badge, Divider, Grid, Typography } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";

import EmploymentInformation from "components/Screens/Customers/CustomerDetials/CustomerProfile/EmploymentInformation";
import PersonalInfo from "components/Screens/Customers/CustomerDetials/CustomerProfile/PersonalInfo";
import LastActive from "components/common/LastActive";
import BankInfo from "components/Screens/Customers/CustomerDetials/CustomerProfile/BankInfo.js";

import { baseUrl } from "util/APIBaseUrl";

import AvatarIcon from "components/Icons/AvatarIcon.js";

import { getValue } from "components/Screens/CommonUtils";

function NewProfile(props) {
  let photoInput = React.createRef();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const customerDetails = useSelector((state) => state.GetCustomerDetails.customerDetails);
  const { lastActive, lastActiveEmail } = customerDetails?.payload || {};

  const postLogo = async (actions) => {
    const response = await fetch(`${baseUrl}/${actions.request}`, {
      method: "POST",
      headers: {},
      body: actions.formData,
    });
    if (!response.ok) {
      return;
    }
    window.location.reload();
    const data = await response.json();
  };

  const uploadProfilePic = () => {
    photoInput.current.click();
  };

  const handleImageChange = (e) => {
    let logoRequest = "";
    let loggedInUser = JSON.parse(localStorage.getItem("loggedInUser"));
    e.preventDefault();
    let inFile = e.target.files[0];
    var form_data = new FormData();
    form_data.append("file", inFile);
    if (loggedInUser?.type == "edge") {
      logoRequest = `customer/${customerDetails?.payload?.customerId}/logo`;
    }

    postLogo({
      request: logoRequest,
      formData: form_data,
    });
  };

  return (
    <Grid
      item
      container
      direction="row"
      xl={12}
      lg={12}
      md={12}
      sm={12}
      xs={12}
      sx={{ px: 10, py: 5 }}
      columnSpacing={7}
    >
      <Grid item container direction="column" xl={5} lg={5} md={6} sm={12} xs={12} rowSpacing={10}>
        <Grid item container direction="row">
          <PersonalInfo />
        </Grid>
        <Grid item container direction="row">
          <EmploymentInformation />
        </Grid>
        <Grid item container direction="row">
          <BankInfo />
        </Grid>
      </Grid>
      {!isMobile ? (
        <Grid item xl={2} lg={2} alignItems="center">
          <Divider orientation="vertical" variant="middle" flexItem sx={{ color: "#9C9EB5", height: "270px" }} />
        </Grid>
      ) : null}
      <Grid
        item
        container
        direction="column"
        //justifyContent="center"
        alignItems="center"
        rowSpacing={2}
        xl={5}
        lg={5}
        md={4}
        sm={12}
        xs={12}
      >
        <Grid item>
          <Badge
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            // badgeContent={100}
            variant="standard"
            badgeContent={
              <EditOutlinedIcon
                // color="primary.main"
                fontSize="large"
              />
            }
            // color="secondary"
            overlap="circular"
            sx={{
              "& .MuiBadge-badge": {
                backgroundColor: "#F7F7F7",
                minWidth: "45px",
                height: "45px",
                borderRadius: "25px",
                boxShadow: "0px 4px 10px 0px #080D461F",
              },
            }}
            onClick={() => {
              uploadProfilePic();
            }}
          >
            <input
              type="file"
              accept="image/*"
              onChange={handleImageChange}
              ref={photoInput}
              style={{ display: "none" }}
            />
            <AvatarIcon
              shape={"rounded"}
              size={{
                width: "25vh",
                height: "25vh",
                borderRadius: "50%",
              }}
              source={`${baseUrl}/customer/${customerDetails?.payload?.customerId}/logo?id=${customerDetails?.payload?.pictureVersion}`}
            />
          </Badge>
        </Grid>
        <Grid item>
          <Typography
            variant="h2"
            // color="secondary.main"
            sx={{ letterSpacing: "3px" }}
          >
            {getValue(customerDetails?.payload?.companyName)}
          </Typography>
        </Grid>
        <LastActive {...{ lastActive, lastActiveEmail }} />
      </Grid>
    </Grid>
  );
}

export default NewProfile;
