import React, { useEffect, useState } from "react";

import { getValue } from "components/Screens/CommonUtils";
import AvatarIcon from "components/Icons/AvatarIcon";

import { baseUrl } from "util/APIBaseUrl";
import { getUserRole } from "util/utils";

function UserInfo(props) {
  const { user } = props;

  const [profileUrl, setProfileUrl] = useState(null);

  const avatarSize = {
    width: "40px",
    height: "40px",
    borderRadius: "100px",
  };

  useEffect(() => {
    const loggedInUser = JSON.parse(localStorage.getItem("loggedInUser"));
    let userProfileUrl = null;
    if (user && Object.keys(user).length && Object.keys(loggedInUser).length && loggedInUser.userId != undefined) {
      if (loggedInUser?.type == "customer") {
        userProfileUrl = `${baseUrl}/customerUser/${user?.userId}/logo?id=${user?.pictureVersion}`;
      } else if (loggedInUser?.type == "edge") {
        userProfileUrl = `${baseUrl}/user/${user?.userId}/logo?id=${user?.pictureVersion}`;
      }

      setProfileUrl(userProfileUrl);
    }
  }, [user]);

  return (
    <div className="flex gap-1">
      <AvatarIcon shape="circle" size={avatarSize} source={profileUrl} />
      <div>
        <div className="heading-h4-semibold user-name-role-text-color">
          {`${getValue(user?.firstName)} ${getValue(user?.lastName)}`}
        </div>
        <div className="para-body-s-regular user-name-role-text-color">{getUserRole(user)}</div>
        <div className="para-body-s-regular user-mail-text-color">{getValue(user?.email)}</div>
      </div>
    </div>
  );
}

export default UserInfo;
