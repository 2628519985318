import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { Divider } from "@mui/material";
import Barchart from "../../../../Graphs/Barchart";

import "./PayrollBar.css";
import { getFieldValue, getValue } from "../../../CommonUtils";

const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

const PAYROLL_SUMMARY_MOCK = [
  {
    month: "Jan",
    amount: 10,
    bonus: "0",
  },
  {
    month: "Feb",
    amount: 20,
    bonus: "0",
  },
  {
    month: "Mar",
    amount: 30,
    bonus: "0",
  },
  {
    month: "Apr",
    amount: 40,
    bonus: "0",
  },
  {
    month: "May",
    amount: 50,
    bonus: "0",
  },
];

function PayrollBar(props) {
  const { payrollData, salaryInfo } = props;
  //
  //component state
  const [payrollVisibility, setPayrollVisibility] = React.useState(false);
  const [hiddenFigure, setHiddenFigure] = React.useState("******");

  //functions
  const handlePayrollVisibilityChange = () => {
    setHiddenFigure("*".repeat(salaryInfo?.payload?.grossSalary ? salaryInfo?.payload?.grossSalary.length : 4));
    setPayrollVisibility(!payrollVisibility);
  };

  return (
    <div className="ep-payroll-bar">
      <div className="ep-payroll-bar-meta">
        <div>
          <>
            <div className="ep-payroll-last-month">
              <div className="ep-payroll-dollar">$</div>

              <div className="ep-payroll-amount" id="payroll-amount">
                {!payrollVisibility
                  ? `${
                      payrollData?.payload?.rows[payrollData?.payload?.rows?.length - 1]?.payrollAmount ||
                      getValue(salaryInfo?.payload?.grossSalary)
                    }`
                  : `${hiddenFigure}`}
              </div>
            </div>
            <div className="ep-payroll-gross">{`${
              monthNames[
                new Date(
                  payrollData?.payload?.rows[payrollData?.payload?.rows?.length - 1]?.paymentDate ||
                    salaryInfo?.payload?.salaryDate
                ).getMonth()
              ]
            }’s gross salary`}</div>
            <div className="ep-payroll-bonus-raise" id="bonus-raise-employee">
              {getFieldValue(payrollData?.payload?.rows[payrollData?.payload?.rows?.length - 1]?.bonus)} Bonus |{" "}
              {getFieldValue(payrollData?.payload?.rows[payrollData?.payload?.rows?.length - 1]?.raise)} Raise
            </div>
          </>
        </div>
        <div>
          {!payrollVisibility ? (
            <img
              src="../assets/Images/eye-icon.svg"
              style={{ cursor: "pointer" }}
              onClick={handlePayrollVisibilityChange}
            />
          ) : (
            <img src="../assets/Images/NotVisibileIcon.png" onClick={handlePayrollVisibilityChange} />
          )}
        </div>
      </div>

      <div style={{ border: "0.1px solid #eff0fe" }} />

      <div style={{ padding: "5px 5px" }}>
        <Barchart
          payrollData={
            payrollData.ok
              ? payrollData.payload.rows
                  .map((r, i) => ({
                    month: monthNames[new Date(r.paymentDate).getMonth()],
                    amount: Number(r.payrollAmount),
                  }))
                  .slice(-6)
              : []
          }
        />
      </div>
    </div>
  );
}

export default PayrollBar;
