import React from "react";

import InfoSection from "components/common/InfoSection";
import ProfileInformation from "./ProfileInformation";
import ChangePassword from "./ChangePassword";
import { getValue } from "components/Screens/CommonUtils";
import ManagedEmployeeList from "components/Screens/SettingV2/AddUser/ManagedEmployeeList";

function GeneralInformation(props) {
  const { customerUserandCompanyProfileData, managersList } = props;
  const { payload: data } = customerUserandCompanyProfileData;

  const contactInfo = [
    { label: "First Name", value: getValue(data?.firstName) },
    { label: "Last Name", value: getValue(data?.lastName) },
    { label: "Email", value: getValue(data?.email) },
    { label: "Phone number", value: getValue(data?.phoneNumber) || "N/A" },
    { label: "Country / Region", value: getValue(data?.country) },
    { label: "Time Zone", value: getValue(data?.timezone) },
  ];

  const passwordInfo = [
    { label: "Password", value: "********" },
    { label: "Email", value: getValue(data?.email) },
  ];

  return (
    <div className="pb-5">
      <InfoSection header="General Information" position="top" editIcon onEdit={() => {}} noBottomBorder>
        <ProfileInformation contactInfo={contactInfo} />
      </InfoSection>
      <InfoSection header="Change Password" position="middle" editIcon onEdit={() => {}} noBottomBorder>
        <ChangePassword contactInfo={passwordInfo} />
      </InfoSection>
      <InfoSection header="" position="bottom" onEdit={() => {}}>
        <ManagedEmployeeList customerUserId={data?.userId} managersList={managersList} />
      </InfoSection>
    </div>
  );
}

export default GeneralInformation;
