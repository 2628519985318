import React from "react";
//redux dispatch
import { useSelector, useDispatch } from "react-redux";
import { EmployeeInfo } from "../../../../store/actions/EmployeeInfo";
import PaymentBreakDown from "./PaymentBreakDown";
//router
import { useNavigate, useParams } from "react-router-dom";
import { UserAdditionalInfo } from "../../../../store/actions/UserAdditionalInfo";
import { ResetAddEmployeeSalaryData } from "../../../../store/actions/Workforce/AddEmployee/AddEmployeeSalaryData";
import { ResetAddEmployeeSalary } from "../../../../store/actions/Workforce/AddEmployee/AddEmployeeSalary";
import { ResetCalculateEmployeeSalary } from "../../../../store/actions/Workforce/AddEmployee/CalculateEmployeeSalary";
import { GetEmployeeDetails } from "../../../../store/actions/GetEmployeeDetails";

//constants

function Overview(props) {
  const dispatch = useDispatch();
  const params = useParams();
  const navigate = useNavigate();

  //component state
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [page, setPage] = React.useState(0);
  const [navigateToPage, setNavigateToPage] = React.useState(null);
  const [selectedRowID, setSelectedRowID] = React.useState(null);

  //redux state
  const customerDetails = useSelector((state) => state.GetCustomerDetails.customerDetails);

  const userAdditionalInfo = useSelector((state) => state.UserAdditionalInfo.userAdditionalInfo);

  const employeesInfo = useSelector((state) => state.EmployeeInfo.employeesInfo);

  const getEmployeeDetails = useSelector((state) => state.GetEmployeeDetails.getEmployeeDetails);

  //functions
  const handlePagination = (pagination) => {
    setRowsPerPage(pagination.rowsPerPage);
    setPage(pagination.page);
  };

  const handleSelectedRow = (selectedEmployeeID, navigateTo) => {
    let loggedInUser = {};
    loggedInUser = JSON.parse(localStorage.getItem("loggedInUser"));
    dispatch(ResetAddEmployeeSalaryData({}));
    dispatch(ResetAddEmployeeSalary({}));
    dispatch(ResetCalculateEmployeeSalary({}));
    if (
      Object.keys(loggedInUser).length &&
      loggedInUser.userId != undefined &&
      selectedEmployeeID != null &&
      selectedEmployeeID != undefined
    ) {
      dispatch(
        GetEmployeeDetails({
          customerID: loggedInUser?.userId,
          employeeID: selectedEmployeeID,
          request: `employee/${selectedEmployeeID}`,
        })
      );
      setSelectedRowID(selectedEmployeeID);
      setNavigateToPage(navigateTo);
    }
  };

  //methods

  React.useEffect(() => {
    if (getEmployeeDetails.ok) {
      if (navigateToPage != null) {
        if (selectedRowID != null && navigateToPage == "details") {
          navigate(`/main/details/${selectedRowID}`);
        } else if (navigateToPage == "addEmployee") {
          navigate(`/main/addEmployee`, {
            state: {
              navigatedFor: "editEmployee",
              redirectTo: "workforce",
              editEmployeeId: selectedRowID,
            },
          });
        }
      }
    }
  }, [getEmployeeDetails]);

  React.useEffect(() => {
    if (customerDetails?.payload?.customerId != undefined) {
      dispatch(
        EmployeeInfo({
          customerID: customerDetails.payload.customerId,
          rowsPerPage: rowsPerPage,
          page: page,
          dist: {},
          sortBy: {},
        })
      );
    }
  }, [rowsPerPage, page]);

  React.useState(() => {
    if (params.id != undefined && params.id != null) {
      let request = `customer/${params.id}/info`;
      dispatch(UserAdditionalInfo(request));
    }
  }, []);

  return (
    <PaymentBreakDown
      paymentDetail={userAdditionalInfo}
      customerPayment={employeesInfo}
      handlePagination={handlePagination}
      handleSelectedRow={handleSelectedRow}
      openDetailsPage={"employeeName"}
    />
  );
}

export default Overview;
