import React from "react";
import "./Grid.css";

function DefaultHeader(props) {
  return <div></div>;
}
function DefaultCell(props) {
  return <div>{props.default}</div>;
}

/**
 * Reusable Grid Component
 * Renders default components if not provided in the props.
 */
function Grid(props) {
  const Header = props.header;
  const EmployeePaySlip = props.employeePaySlip

  return (
    <div className={`common-grid-container ${props.className || ""}`}>
      <div className={EmployeePaySlip ? "" : "common-grid-header"}>
        {Header ? <Header /> : <DefaultHeader />}
      </div>
      <div className={EmployeePaySlip ? "grid-content-payslip" : "common-grid-content"}>
        {props.rows.map((row) => {
          return (
            <div className={EmployeePaySlip ? "grid-row-payslip" : "common-grid-row"}>
              {props.columns.map((item) => {
                const Component_ = item.component;
                if (Component_) {
                  return <Component_ row={row} />;
                } else {
                  return <DefaultCell default={row[item.key]} />;
                }
              })}
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default Grid;
