import React, { useEffect, useState } from "react";
import { Avatar } from "@mui/material";

function AvatarIcon(props) {
  const { shape, size, source } = props;
  const [avatarUrl, setAvatarUrl] = useState("");

  useEffect(() => {
    if (source instanceof File) {
      const reader = new FileReader();
      reader.onload = (e) => {
        setAvatarUrl(e.target.result);
      };
      reader.readAsDataURL(source);
    } else {
      setAvatarUrl(source);
    }
  }, [source]);

  return (
    <div>
      <Avatar
        variant={shape == "rounded" ? "rounded" : "circle"}
        src={avatarUrl}
        imgProps={{ crossOrigin: "anonymous" }}
        sx={
          size
            ? {
                ...size,
              }
            : {}
        }
        fullwidth="true"
      />
    </div>
  );
}

export default AvatarIcon;
