import { LEAVE_TYPES, GET_LEAVE_TYPES_SUCCESS, GET_LEAVE_TYPES_FAILURE } from "store/constants/Timeoff";

export const FetchLeaveTypes = (data) => {
  return {
    type: LEAVE_TYPES,
    payload: data,
  };
};

export const LeaveTypeSucess = (payload) => {
  return {
    type: GET_LEAVE_TYPES_SUCCESS,
    payload,
  };
};
export const LeaveTypeFailure = (payload) => {
  return {
    type: GET_LEAVE_TYPES_FAILURE,
    payload,
  };
};
