import { GET_LEAVE_REQUESTS_FAILURE, GET_LEAVE_REQUESTS_SUCCESS, LEAVE_REQUESTS } from "store/constants/Timeoff";

const INIT_STATE = {
  leaveRequests: [],
  loadingLeaveRequests: true,
  leaveRequestsError: "",
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case LEAVE_REQUESTS:
      return {
        ...state,
        loadingLeaveRequests: true,
      };
    case GET_LEAVE_REQUESTS_SUCCESS:
      return {
        ...state,
        loadingLeaveRequests: false,
        leaveRequests: action.payload?.payload?.data,
      };
    case GET_LEAVE_REQUESTS_FAILURE:
      return {
        ...state,
        loadingLeaveRequests: false,
        leaveRequestsError: "Error in  getting Leave requests",
      };
    default:
      return state;
  }
};
