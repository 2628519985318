import { call, put, takeEvery } from "redux-saga/effects";
//actions
import {
    GetRecentPaymentsSuccess,
    GetRecentPaymentsFailure,
} from "../../actions/DashBoard/RecentPayments";
//constant
import { GET_RECENT_PAYMENTS } from "../../constants/DashBoard/RecentPayments";
//request type
import getRequest from "../../../util/APIHelperGet";
import { baseUrl } from "../../../util/APIBaseUrl";

export const fetchGetRecentPaymentsRequest = async (actions) => {
    // eslint-disable-next-line no-return-await
    return await getRequest(
        `${baseUrl}/customer/${actions.payload.customerID}/payments?` +
            new URLSearchParams({
                limit: actions.payload.rowsPerPage,
                page: actions.payload.page,
                orderBy: JSON.stringify({
                    field: actions.payload?.sortBy?.field,
                    direction: actions.payload?.sortBy?.direction,
                }),
            })
    )
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return error;
        });
};

function* fetchGetRecentPayments(params) {
    try {
        let response = yield call(fetchGetRecentPaymentsRequest, params);
        yield put(GetRecentPaymentsSuccess(response));
    } catch (error) {
        console.error(error);
        yield put(GetRecentPaymentsFailure(error));
    }
}

export default function* rootSaga() {
    yield takeEvery(GET_RECENT_PAYMENTS, fetchGetRecentPayments);
}
