import { IconButton, Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import UpcommingHolidayLeftIcon from "../EmployeeTimeOffDetails/Icons/UpcommingHolidayLeftIcon";
import UpcommingHolidayRightIcon from "../EmployeeTimeOffDetails/Icons/UpcommingHolidayRightIcon";
import LeaveCard from "../../ManageTimeoff/Customer/LeaveCard";
import { getEmployeesLeaveRequestById } from "../TimeOffAdminServices/EmployeeNewAvtivity";

function EmployeeTimeOffDetails(props) {
  const {
    employeesAllRequests,
    employeeSelectedActivity,
    backToAllHolidays,
    navigateToEmployeeProfile,
    style,
    // backToAllEmployeeReqScreen,
  } = props;

  const [currentRequestIndex, setCurrentRequestIndex] = useState(0);
  const [leaveRequestDetails, setLeaveRequestDetials] = useState(null);
  const [leaveDetailsLoader, setLeaveDetailsLoader] = useState(false);

  const onSuccessApproveLeave = async (requestId) => {
    try {
      setLeaveDetailsLoader(true);
      const res = await getEmployeesLeaveRequestById(requestId);
      if (res?.success) {
        setLeaveRequestDetials({
          ...leaveRequestDetails,
          [res?.data?.requestId]: res?.data,
        });
        setLeaveDetailsLoader(false);
      }
    } catch (error) {
      console.log(error);
      setLeaveDetailsLoader(false);
    }
  };

  useEffect(() => {
    if (employeeSelectedActivity && employeesAllRequests) {
      const requestIndex = employeesAllRequests.findIndex(
        (request) => request.requestId === employeeSelectedActivity?.requestId
      );

      if (requestIndex !== -1) {
        setCurrentRequestIndex(requestIndex);
      } else {
        setCurrentRequestIndex(currentRequestIndex > 0 ? currentRequestIndex - 1 : 0);
      }
    }
  }, [employeesAllRequests, employeeSelectedActivity]);

  const getLeavesData = () => {
    const requestId = employeesAllRequests?.[currentRequestIndex]?.requestId;

    if (leaveRequestDetails && leaveRequestDetails?.[requestId]) {
      return leaveRequestDetails?.[requestId];
    }
    return employeesAllRequests[currentRequestIndex];
  };

  return (
    <div>
      <div className="employee-details-toolbar" style={{ ...style }}>
        <Stack direction="row" alignItems="center" spacing="9px">
          {/* <IconButton
            onClick={() => {
              setCurrentRequestIndex(0);
              backToAllHolidays();
            }}
          >
            <UpcommingHolidayLeftIcon />
          </IconButton> */}
          <IconButton
            onClick={() => {
              setCurrentRequestIndex(0);
              backToAllHolidays();
            }}
            sx={{ p: "0px" }}
          >
            <svg width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M3.81045 7.74998L8.5302 12.4697L7.4697 13.5302L0.939453 6.99998L7.4697 0.469727L8.5302 1.53023L3.81045 6.24998H14.75V7.74998H3.81045Z"
                fill="#131599"
              />
            </svg>
          </IconButton>

          <div className="tollbar-text" style={{ textDecorationLine: "underline" }}>
            Go back to all holidays
          </div>
        </Stack>

        <Stack direction="row" alignItems="center" spacing="6px">
          <div className="tollbar-text" style={{ marginRight: "14px" }}>
            {currentRequestIndex + 1}/{employeesAllRequests?.length} total requests
          </div>
          <IconButton
            disabled={!currentRequestIndex}
            onClick={() => setCurrentRequestIndex(currentRequestIndex - 1)}
            sx={{ p: "0px" }}
          >
            <UpcommingHolidayLeftIcon />
          </IconButton>

          <IconButton
            disabled={currentRequestIndex === employeesAllRequests?.length - 1}
            onClick={() => setCurrentRequestIndex(currentRequestIndex + 1)}
            sx={{ p: "0px" }}
          >
            <UpcommingHolidayRightIcon />
          </IconButton>
        </Stack>
      </div>

      <Stack
        justifyContent="center"
        alignItems="center"
        sx={{
          borderRadius: "12px",
          mt: "11px",
          // height: "747px",
          py: "24px",
          backgroundColor: "var(--Primary-99, #FBFAFF)",
          margin: " 0px auto",
        }}
      >
        <div style={{ width: "890px", margin: "0px auto" }}>
          {(leaveRequestDetails || employeesAllRequests[currentRequestIndex]) && (
            <LeaveCard
              data={getLeavesData()}
              admin
              showProfileSection={true}
              navigateToEmployeeProfile={(employeeId) => navigateToEmployeeProfile(employeeId)}
              onSuccessApproveLeave={(requestId) => {
                onSuccessApproveLeave(requestId);
              }}
              leaveDetailsLoader={leaveDetailsLoader}
            />
          )}
        </div>
      </Stack>
    </div>
  );
}

export default EmployeeTimeOffDetails;
