import { call, put, takeEvery } from "redux-saga/effects";
//actions
import {
    SearchEmployeeAndCustomerSuccess,
    SearchEmployeeAndCustomerFailure,
} from "../../actions/SearchEmployee&Customer";
//constant
import { SEARCH_EMPLOYEE_AND_CUSTOMER } from "../../constants/SearchEmployee&Customer";
//request type
import getRequest from "../../../util/APIHelperGet";
import { baseUrl } from "../../../util/APIBaseUrl";

export const fetchSearchAndCustomerRequest = async (actions) => {
    // eslint-disable-next-line no-return-await

    return await getRequest(
        `${baseUrl}/${actions.payload.request}`,
        actions?.payload?.loaderFlag
    )
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return error;
        });
};

function* fetchSearchAndCustomer(params) {
    try {
        let response = yield call(fetchSearchAndCustomerRequest, params);
        yield put(SearchEmployeeAndCustomerSuccess(response));
    } catch (error) {
        console.error(error);
        yield put(SearchEmployeeAndCustomerFailure(error));
    }
}

export default function* rootSaga() {
    yield takeEvery(SEARCH_EMPLOYEE_AND_CUSTOMER, fetchSearchAndCustomer);
}
