//employee constants
import {
  RESET_EDUCATION_INFO,
  EDUCATION_INFO,
  EDUCATION_INFO_SUCCESS,
  EDUCATION_INFO_FAILURE,
} from "../../../constants/Workforce/EmployeeDetils/EducationInfo";

const INIT_STATE = {
  educationInfo: {},
  loadingEducationInfo: false,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case RESET_EDUCATION_INFO:
      return INIT_STATE;

    case EDUCATION_INFO:
      return {
        ...state,
        loadingEducationInfo: true,
      };

    case EDUCATION_INFO_SUCCESS:
      return {
        ...state,
        loadingEducationInfo: false,
        educationInfo: action.payload,
      };

    // eslint-disable-next-line no-fallthrough
    case EDUCATION_INFO_FAILURE:
      return {
        ...state,
        loadingEducationInfo: false,
        educationInfo: "Error in  getting Club Data",
      };
    default:
      return state;
  }
};
