import React from "react";

import { convertUTCTimeIntoLocalUserTime } from "components/Screens/CommonUtils";

import LoginIcon from "@mui/icons-material/Login";

import "./LastActive.css";

function LastActive({ lastActiveEmail: email, lastActive: lastActiveTime = "" }) {
  return (
    <div className={`last-login-container ${email ? "" : "employee-last-active"} `}>
      <div className="flex gap-05 items-center">
        <LoginIcon className="align-self-start last-login-icon" />

        <span className="last-login-title para-body-s-regular">Last Active:</span>
      </div>

      <div className="flex flex-col">
        {email && <span className="para-body-s-medium align-self-start">{email}</span>}
        <span className="para-body-s-medium align-self-start">
          {`${email ? "on" : ""} ${convertUTCTimeIntoLocalUserTime(lastActiveTime)}`}
        </span>
      </div>
    </div>
  );
}

export default LastActive;
