function NothingToSeeIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="325.707"
      height="273.305"
      viewBox="0 0 325.707 273.305"
    >
      <defs>
        <clipPath id="clip-path">
          <ellipse
            id="Ellipse_605"
            data-name="Ellipse 605"
            cx="130.918"
            cy="130.25"
            rx="130.918"
            ry="130.25"
            transform="translate(0 0)"
            fill="#dedede"
          />
        </clipPath>
        <clipPath id="clip-path-2">
          <path
            id="Path_14051"
            data-name="Path 14051"
            d="M-14,0A87.606,87.606,0,0,1,73.606,87.606,87.606,87.606,0,0,1-14,175.213c-24.869,0-58.356,14.578-83.785,0-24.018-13.769-40.7-56.024-40.7-79.538C-138.482,47.291-62.384,0-14,0Z"
            transform="translate(138.482 0)"
            fill="#bababa"
          />
        </clipPath>
        <filter
          id="Path_14063"
          x="122.527"
          y="114.301"
          width="126.852"
          height="46.89"
          filterUnits="userSpaceOnUse"
        >
          <feOffset dy="3" />
          <feGaussianBlur stdDeviation="3" result="blur" />
          <feFlood floodOpacity="0.161" />
          <feComposite operator="in" in2="blur" />
          <feComposite in="SourceGraphic" />
        </filter>
      </defs>
      <g id="no-show-img" transform="translate(-520.146 -785.348)">
        <ellipse
          id="Ellipse_548"
          data-name="Ellipse 548"
          cx="130.918"
          cy="130.25"
          rx="130.918"
          ry="130.25"
          transform="translate(520.146 785.348)"
          fill="#fce9e5"
        />
        <g
          id="Mask_Group_59"
          data-name="Mask Group 59"
          transform="translate(584.018 798.152)"
          clipPath="url(#clip-path)"
        >
          <g id="Path_10390" data-name="Path 10390" transform="translate(-22.298 -3.106)">
            <path
              id="Path_13978"
              data-name="Path 13978"
              d="M284.5,167.225H.5V.5h284Z"
              transform="translate(-0.301 -0.302)"
              fill="#ecfafc"
            />
            <path
              id="Path_13979"
              data-name="Path 13979"
              d="M.4.4V166.725H284V.4H.4M0,0H284.4V167.122H0Z"
              transform="translate(0 0)"
              fill="#ffac98"
            />
          </g>
          <g id="Group_11518" data-name="Group 11518" transform="translate(141.173 21.538)">
            <path
              id="Path_10387"
              data-name="Path 10387"
              d="M1322.137,3680.957c-.338,9.274.453,21.1-.847,30.845-1.714.322-23.412.5-26.308.562l-9.969.208a23.71,23.71,0,0,1-6.942-.541l.074-20.765c.012-3.24-.93-7.085-.406-10.282,14.757-.728,29.448-.66,44.4-.024"
              transform="translate(-1277.579 -3680.46)"
              fill="#545a6e"
            />
            <rect
              id="Rectangle_4491"
              data-name="Rectangle 4491"
              width="37.228"
              height="24.902"
              transform="translate(3.934 3.298)"
              fill="#99acc7"
            />
            <path
              id="Path_10388"
              data-name="Path 10388"
              d="M1319.987,3714.205a21.53,21.53,0,0,0-3.111-9.671c-.6-.917-1.488-1.881-2.573-1.737-2.661.353-1.485,5.937-4.157,6.2-1.472.146-2.273-1.622-2.72-3.028a32.98,32.98,0,0,0-3.929-8.2c-.405-.611-.982-1.281-1.71-1.19a1.8,1.8,0,0,0-1.172.918c-1.855,2.846-1.276,6.771-3.12,9.625a2.2,2.2,0,0,1-1.106.985c-1.093.343-2.068-.776-2.579-1.8s-1.123-2.256-2.263-2.372c-1.038-.105-1.892.828-2.325,1.775a10.318,10.318,0,0,1-1.358,2.8c-.7.774-2.143,1.016-2.694.132-.186-.3-.266-.7-.584-.852-.5-.235-.969.387-1.153.91a18.036,18.036,0,0,0-.672,5.515c5.477.1,33.008-.227,37.229,0"
              transform="translate(-1278.824 -3686.008)"
              fill="#6c896d"
            />
            <path
              id="Path_10389"
              data-name="Path 10389"
              d="M1288.061,3691.743a3.06,3.06,0,1,0,3.06-3.057,3.058,3.058,0,0,0-3.06,3.057Z"
              transform="translate(-1256.506 -3683.292)"
              fill="#ffbd7d"
            />
          </g>
        </g>
        <path
          id="Path_13980"
          data-name="Path 13980"
          d="M963.376,872.2c-2.576-9.083-6.374-29.546-4.635-44.436.3-2.529.726-5.144,2.284-7.272,3.216-4.385,9.905-5.236,15.765-5.63a1355.814,1355.814,0,0,1,146.061-1.934c3.184.128,6.583.334,9.13,2.043,2.4,1.61,3.592,4.257,4.523,6.8a73.688,73.688,0,0,1,3.5,37.577c-.6,3.547.044,10.855-2.414,13.7-4.484,5.181-14.2,2.623-21.507,3.249a990.986,990.986,0,0,1-139.773,2.059c-6.162-.344-13.014-.658-12.933-6.16"
          transform="translate(-335.977 48.762)"
          fill="#bfbfbf"
        />
        <g
          id="Mask_Group_93"
          data-name="Mask Group 93"
          transform="translate(531.234 818.316)"
          clipPath="url(#clip-path-2)"
        >
          <g id="Group_17544" data-name="Group 17544" transform="translate(75.543 42.252)">
            <path
              id="Path_13981"
              data-name="Path 13981"
              d="M963.376,872.2c-2.576-9.083-6.374-29.546-4.635-44.436.3-2.529.726-5.144,2.284-7.272,3.216-4.385,9.905-5.236,15.765-5.63a1355.814,1355.814,0,0,1,146.061-1.934c3.184.128,6.583.334,9.13,2.043,2.4,1.61,3.592,4.257,4.523,6.8a73.688,73.688,0,0,1,3.5,37.577c-.6,3.547.044,10.855-2.414,13.7-4.484,5.181-14.2,2.623-21.507,3.249a990.986,990.986,0,0,1-139.773,2.059c-6.162-.344-13.014-.658-12.933-6.16"
              transform="translate(-942.756 -811.807)"
              fill="#838b89"
            />
            <path
              id="Path_13982"
              data-name="Path 13982"
              d="M961.43,877.366c-.18-.762-.726-27.181-.726-27.181s177.786-3.9,176.552-3.7-3.195,28.674-3.195,28.674l-2.971-.037s-1.187-20.34-2.148-19.437-162.8,1.715-162.8,1.715l-1.911,21.006Z"
              transform="translate(-938.886 -755.985)"
              fill="#838b89"
            />
            <path
              id="Path_13983"
              data-name="Path 13983"
              d="M982.152,861.422c-4.565.112-8.083-7.741-8.232-12.437s-1.762-8.668-2.474-13.306-14.36-6.974-17.234-1.449c-3.179,6.115-1.331,14.128-1.347,21.063-.018,8.381,3.043,16.814,9.326,22.151,5.087,4.323,12.471,6.692,19.056,7.1,26.526,1.657,126.18,3.307,165.367-3.456,12.061-2.083,20.635-14.139,22.5-21.8,1.817-7.438,1.757-18.377-.932-25.529-1.8-4.771-7.42-7.206-12.366-6.661-4.223.467-6.583,5.306-7.248,9.621s-.533,9.034-2.989,12.6c-1.955,2.845-5.434,5.99-8.632,7.136-7.094,2.54-10.865,2.957-18.32,3.918-44.961,5.8-94.9,6.241-139.88.632"
              transform="translate(-952.344 -787.293)"
              fill="#838b89"
            />
            <path
              id="Path_13984"
              data-name="Path 13984"
              d="M960.531,836.9a18.881,18.881,0,0,0,1.482,11.26,6.778,6.778,0,0,0,2.464,3.106,10.8,10.8,0,0,0,4.62,1.143,542.2,542.2,0,0,0,61.762,1.816c22.4-.459,45.718-.112,68.07-1.438,6.65-.4,17.4-.592,23.994-1.386,3.98-.48,4.037-.031,7.125-2.174s3.685-9.193,1.4-11.949c-2.464-2.983-7.642-2.89-11.986-2.717-48.092,1.9-98.318,1.958-148.781.767-2.707-.063-10.445-.882-10.156,1.571"
              transform="translate(-939.632 -775.289)"
              fill="#838b89"
            />
            <path
              id="Path_13985"
              data-name="Path 13985"
              d="M1009.394,843.748c.7,2.232,3.195,3.461,5.5,3.424a16.143,16.143,0,0,0,6.549-2.062l9.968-4.8a5.94,5.94,0,0,0,2.169-1.48,2.088,2.088,0,0,0,.212-2.474c-.632-.825-1.866-.759-2.874-.543-3.508.757-6.935,2.391-10.505,2.059a7.055,7.055,0,0,0-1.673-.071,6.146,6.146,0,0,0-2.615,1.438,19.1,19.1,0,0,1-6.46,3.142"
              transform="translate(-862.091 -773.378)"
              fill="#838b89"
            />
            <path
              id="Path_13986"
              data-name="Path 13986"
              d="M1007.708,843.5c.5,2.247,2.832,3.675,5.092,3.845a15.87,15.87,0,0,0,6.59-1.436q5.09-1.906,10.177-3.808a5.788,5.788,0,0,0,2.253-1.255,2.052,2.052,0,0,0,.423-2.4c-.548-.867-1.762-.911-2.769-.788-3.5.428-7,1.72-10.469,1.078a6.97,6.97,0,0,0-1.631-.219,6.007,6.007,0,0,0-2.686,1.177,18.711,18.711,0,0,1-6.6,2.5"
              transform="translate(-864.805 -770.277)"
              fill="#838b89"
            />
            <path
              id="Path_13987"
              data-name="Path 13987"
              d="M967.506,833.933c-.893-2.145-3.317-3.142-5.56-3.609a8.877,8.877,0,0,0-2.673-.23,6.28,6.28,0,0,0-4.507,2.879,8.8,8.8,0,0,0-1.287,5.34,9.531,9.531,0,0,0,2.884,6.337,7.592,7.592,0,0,0,6.5,1.971,10.169,10.169,0,0,0,5.857-3.993,7.134,7.134,0,0,0-1.911-9.425"
              transform="translate(-950.551 -782.408)"
              fill="#838b89"
            />
            <path
              id="Path_13988"
              data-name="Path 13988"
              d="M1042.394,832.825c-.893-2.146-3.315-3.145-5.56-3.612a8.875,8.875,0,0,0-2.673-.23,6.28,6.28,0,0,0-4.5,2.881,8.786,8.786,0,0,0-1.289,5.338,9.544,9.544,0,0,0,2.884,6.337,7.593,7.593,0,0,0,6.5,1.97,9.135,9.135,0,0,0,3.618-1.7,9.029,9.029,0,0,0,2.242-2.289,7.133,7.133,0,0,0-1.913-9.425"
              transform="translate(-829.977 -784.196)"
              fill="#838b89"
            />
            <path
              id="Path_13989"
              data-name="Path 13989"
              d="M1011.5,829.4c-.323,5.353-.619,10.542.342,15.838a5.586,5.586,0,0,0,1.968,3.889,7.41,7.41,0,0,0,2.926.976,58.573,58.573,0,0,0,14.851.676c1.905-.157,4.032-.527,5.087-1.9a5.1,5.1,0,0,0,.835-2.647,93.035,93.035,0,0,0,.613-11.688c-.021-1.958-.164-4.066-1.6-5.588-1.506-1.59-4.046-2.125-6.416-2.417a58.544,58.544,0,0,0-13.366-.112c-2.25.24-4.983,1.042-5.236,2.968"
              transform="translate(-857.598 -788.79)"
              fill="#838b89"
            />
            <path
              id="Path_13990"
              data-name="Path 13990"
              d="M1041.9,826.437c-6.115-.642-12.048-1.242-18.163-.574-1.652.18-3.448.486-4.555,1.772a6.926,6.926,0,0,0-1.261,2.915,52.888,52.888,0,0,0-1.519,15.044c.086,1.947.4,4.129,1.916,5.278a6.442,6.442,0,0,0,2.994,1,120.757,120.757,0,0,0,13.361,1.3c2.242.094,4.667.068,6.481-1.305,1.9-1.438,2.639-3.986,3.093-6.374a52.81,52.81,0,0,0,.794-13.572c-.162-2.3-.945-5.124-3.14-5.492"
              transform="translate(-849.289 -789.645)"
              fill="#838b89"
            />
            <path
              id="Path_13991"
              data-name="Path 13991"
              d="M1010.064,839.132a37.435,37.435,0,0,1,5.656-4.2.762.762,0,0,1,.308-.13.794.794,0,0,1,.444.138,36.113,36.113,0,0,1,3.7,2.367q-2.4,2.451-4.8,4.9a.671.671,0,0,1-.339.227.645.645,0,0,1-.365-.073,21.166,21.166,0,0,1-4.675-2.678l-.01-.008"
              transform="translate(-861.142 -774.791)"
              fill="#838b89"
            />
            <path
              id="Path_13992"
              data-name="Path 13992"
              d="M1006.249,846.047l3.62,1.911a.418.418,0,0,0,.564-.044l2.122-1.511a59.663,59.663,0,0,0,8.908-7.253,28.353,28.353,0,0,1-3.946-2.678,43.222,43.222,0,0,0-10.97,9.188"
              transform="translate(-867.154 -772.096)"
              fill="#838b89"
            />
            <path
              id="Path_13993"
              data-name="Path 13993"
              d="M1068.838,860.158c0,2.192-14.207,3.967-31.73,3.967s-31.728-1.775-31.728-3.967,14.206-3.967,31.728-3.967S1068.838,857.968,1068.838,860.158Z"
              transform="translate(-866.955 -740.346)"
              fill="#838b89"
            />
            <g id="Group_17543" data-name="Group 17543" transform="translate(156.628 95.586)">
              <g id="Group_17524" data-name="Group 17524">
                <path
                  id="Path_13994"
                  data-name="Path 13994"
                  d="M1012.907,857.889a5.124,5.124,0,0,1,2.558-4.641c.086-.091.172-.18.264-.266a1.1,1.1,0,0,0-.665-.23,3.532,3.532,0,0,1,2.328-.95c.211-.112.436-.217.668-.313a.793.793,0,0,0-.2-.026,4.935,4.935,0,0,1,3.174-.684c2.028-.172,2.853.619,4.059,1.185,4.354,2.041,7.449,3.443,11.782,5.528l.065-.024c-.444.272-.527,1.347-.478,2.586a3.893,3.893,0,0,1,.8,1.979l-.7-.514q.027.282.055.556a7.371,7.371,0,0,1,.582,3.168l-.384-.551a4.664,4.664,0,0,1-1.736,3.25c-2.918,2.286-11.623,1.691-15.144,1.185-2.477-.355-4.732.031-5.813-2.229a14.03,14.03,0,0,1-.616-2.336.891.891,0,0,0-.042.4,9.466,9.466,0,0,1-.363-5.04c.011-.331.029-.663.063-1a.42.42,0,0,0-.185.256l.279-1.018c.039-.261.086-.522.141-.78A1.3,1.3,0,0,0,1012.907,857.889Z"
                  transform="translate(-1011.476 -844.703)"
                  fill="#838b89"
                />
                <path
                  id="Path_13995"
                  data-name="Path 13995"
                  d="M1024.752,854.853c.566-2.109-.076-4.333-.715-6.421q-1.64,1.335-3.156,2.821"
                  transform="translate(-998.626 -848.422)"
                  fill="#838b89"
                />
                <path
                  id="Path_13996"
                  data-name="Path 13996"
                  d="M1016.583,854.87c-.64-2.085-.076-4.333.488-6.441q1.688,1.272,3.252,2.7"
                  transform="translate(-1006.003 -848.429)"
                  fill="#838b89"
                />
                <path
                  id="Path_13997"
                  data-name="Path 13997"
                  d="M1021.553,850.175a5.76,5.76,0,0,0,1.007-.992c.031-.039.073-.084.12-.065s.052.055.06.094a4.631,4.631,0,0,1,.023,2.772,20.206,20.206,0,0,0-1.305-1.736"
                  transform="translate(-997.696 -847.327)"
                  fill="#838b89"
                />
                <path
                  id="Path_13998"
                  data-name="Path 13998"
                  d="M1018.3,850.358a1.543,1.543,0,0,0-.5-.762l-.478-.485c-.034-.036-.084-.076-.128-.052a.108.108,0,0,0-.05.057,4.545,4.545,0,0,0-.4.911,4.613,4.613,0,0,0-.018,1.765,9.853,9.853,0,0,1,1.53-1.344"
                  transform="translate(-1005.428 -847.427)"
                  fill="#838b89"
                />
                <path
                  id="Path_13999"
                  data-name="Path 13999"
                  d="M1023.848,855.87a2.918,2.918,0,0,1-1.8,1.673,4.733,4.733,0,0,0-2.07,1.227,2.916,2.916,0,0,0-.6,1.527,1.834,1.834,0,0,0,.175,1.067.606.606,0,0,0,.788.313,1.64,1.64,0,0,0,.36-.373,3.872,3.872,0,0,1,2.443-1.026,2.721,2.721,0,0,0,2.208-1.506"
                  transform="translate(-1001.059 -836.449)"
                  fill="#838b89"
                />
                <path
                  id="Path_14000"
                  data-name="Path 14000"
                  d="M1018.5,855.87a2.918,2.918,0,0,0,1.8,1.673,4.734,4.734,0,0,1,2.07,1.227,2.891,2.891,0,0,1,.6,1.527,1.81,1.81,0,0,1-.172,1.067.606.606,0,0,1-.788.313,1.536,1.536,0,0,1-.36-.373,3.878,3.878,0,0,0-2.443-1.026,2.729,2.729,0,0,1-2.211-1.506"
                  transform="translate(-1004.887 -836.449)"
                  fill="#838b89"
                />
                <path
                  id="Path_14001"
                  data-name="Path 14001"
                  d="M1026.744,855.914a1.913,1.913,0,0,1-.869,1.41c-.462.24-.971.368-1.446.582a3.218,3.218,0,0,0-2,3.414c.086.136-.357.133-.446.141a2.4,2.4,0,0,0-.18-2.224,3.355,3.355,0,0,0-1.352-.845,13.176,13.176,0,0,1-2.046-1.135c-.512-.345-1.06-.914-.846-1.5l2.892,1.211a3.794,3.794,0,0,0,3.946.005l2.344-1.057"
                  transform="translate(-1004.046 -836.634)"
                  fill="#838b89"
                />
                <path
                  id="Path_14002"
                  data-name="Path 14002"
                  d="M1015.756,857.719a5.275,5.275,0,0,1,.953-2.44c1.01-4.4,5.512-6.6,8.726-6.457,4.513.206,7.444,2.934,8.018,7.572.135.238.3.465.449.692a2.218,2.218,0,0,1,.48,1.522,1.227,1.227,0,0,0-.726-.653l.013.107a4.056,4.056,0,0,1-.042,3.111l-.224-.46a2.764,2.764,0,0,1-.217.376,1.993,1.993,0,0,1-.906,1.255,1.279,1.279,0,0,0-.065-.344,4.8,4.8,0,0,1-.428.232c-3.43,1.631-11.978,1.467-14.674-.89a.212.212,0,0,0-.021.091l-.057.647a5.737,5.737,0,0,1-.882-2.093,1.542,1.542,0,0,0-.248.676,6.946,6.946,0,0,1,.491-3.43A1.646,1.646,0,0,0,1015.756,857.719Z"
                  transform="translate(-1006.878 -847.808)"
                  fill="#838b89"
                />
                <path
                  id="Path_14003"
                  data-name="Path 14003"
                  d="M1012.643,858.965a13.059,13.059,0,0,1,1.95-3.511l1.3,2.668c-.645,0-1.388,1.352-.556,2.581A5.2,5.2,0,0,0,1018,862.84a16.87,16.87,0,0,0,3.315.24,1.444,1.444,0,0,1-.4.459c1.3.426,2.655-.371,4.012-.538a2.107,2.107,0,0,1-.8.564,14.265,14.265,0,0,0,3.524-.509.578.578,0,0,1-.318.358l.183.071a9.242,9.242,0,0,1,3.68-.295,2.4,2.4,0,0,1,1.185.548,1.3,1.3,0,0,1,.417,1.209,1.56,1.56,0,0,1-.681.869,5.251,5.251,0,0,1-1.924.712,2.106,2.106,0,0,1,.52.339l-2.02.141a1.234,1.234,0,0,0,.728.632,16.651,16.651,0,0,1-3.048-.347,15.618,15.618,0,0,1-1.676-.029,31.2,31.2,0,0,1-4.661-.611,2.811,2.811,0,0,0,.472.551,21.571,21.571,0,0,1-3.818-1.334,1.067,1.067,0,0,0,.462.848,10.39,10.39,0,0,1-3.223-2.55,8.63,8.63,0,0,1-1.039-1.284A4.939,4.939,0,0,1,1012.643,858.965Z"
                  transform="translate(-1012.355 -837.119)"
                  fill="#838b89"
                />
                <path
                  id="Path_14004"
                  data-name="Path 14004"
                  d="M1021.96,854.127c-.076-.026-.16-.07-.167-.149a.2.2,0,0,1,.094-.161,1.154,1.154,0,0,1,.543-.214c-.037.2-.063.41-.081.616A2.282,2.282,0,0,1,1021.96,854.127Z"
                  transform="translate(-997.161 -840.1)"
                  fill="#838b89"
                />
                <path
                  id="Path_14005"
                  data-name="Path 14005"
                  d="M1022.313,853.271c-.081-.013-.162-.026-.24-.042a1.487,1.487,0,0,1,.332-.191C1022.373,853.117,1022.341,853.193,1022.313,853.271Z"
                  transform="translate(-996.707 -841.007)"
                  fill="#838b89"
                />
                <g id="Group_17519" data-name="Group 17519" transform="translate(8.978 7.44)">
                  <path
                    id="Path_14006"
                    data-name="Path 14006"
                    d="M1025.541,852.688c.086.13.17.269.245.412a5.985,5.985,0,0,0-.718-.031,16.032,16.032,0,0,1-2.78-.285,2.537,2.537,0,0,1-1.164-.457.418.418,0,0,1-.18-.3c0-.206.23-.324.428-.371a7.846,7.846,0,0,1,2.211-.026,5.233,5.233,0,0,0,1.443-.083c.024.146.045.295.063.449C1025.228,852.234,1025.39,852.458,1025.541,852.688Z"
                    transform="translate(-1007.502 -850.849)"
                    fill="#838b89"
                  />
                  <path
                    id="Path_14007"
                    data-name="Path 14007"
                    d="M1024.5,853.633a6.512,6.512,0,0,1-3.036-.692c-.107-.063-.227-.178-.175-.292a.255.255,0,0,1,.123-.1,3.88,3.88,0,0,1,1.976-.063,5.337,5.337,0,0,0,1.425-.076,3.978,3.978,0,0,1,.253,1.281A3.4,3.4,0,0,0,1024.5,853.633Z"
                    transform="translate(-1006.97 -849.503)"
                    fill="#838b89"
                  />
                  <path
                    id="Path_14008"
                    data-name="Path 14008"
                    d="M1020.647,853.927a.581.581,0,0,1,.295-.567,1.529,1.529,0,0,1,.645-.177,15.422,15.422,0,0,1,2.947-.05,6.947,6.947,0,0,0,1.527.029,3.889,3.889,0,0,1-.305,1.133l-.222-.459a2.759,2.759,0,0,1-.217.378,2.161,2.161,0,0,1-.765,1.172l-.151-.031a1.36,1.36,0,0,0-.055-.232c-.089.055-.18.1-.274.156a26.426,26.426,0,0,1-2.787-.82C1021.01,854.363,1020.7,854.214,1020.647,853.927Z"
                    transform="translate(-1007.993 -848.371)"
                    fill="#838b89"
                  />
                  <path
                    id="Path_14009"
                    data-name="Path 14009"
                    d="M1017.77,851.346a7.943,7.943,0,0,1,2.211.026c.2.047.431.164.428.371a.428.428,0,0,1-.18.3,2.561,2.561,0,0,1-1.164.454,15.774,15.774,0,0,1-2.78.285c-.151,0-.3,0-.451.008a5.3,5.3,0,0,1,.746-1.48v0A5.846,5.846,0,0,0,1017.77,851.346Z"
                    transform="translate(-1015.732 -851.279)"
                    fill="#838b89"
                  />
                  <path
                    id="Path_14010"
                    data-name="Path 14010"
                    d="M1015.835,854.227a6.978,6.978,0,0,1-.016-1.342,6.886,6.886,0,0,0,1.553-.026,15.443,15.443,0,0,1,2.947.05,1.582,1.582,0,0,1,.645.177.58.58,0,0,1,.295.566c-.052.287-.363.436-.637.533a25.958,25.958,0,0,1-3.307.934c-.094-.068-.185-.141-.269-.214a.215.215,0,0,0-.021.091l-.018.188c-.1.018-.2.036-.3.047a5.826,5.826,0,0,1-.624-1.681A1.541,1.541,0,0,0,1015.835,854.227Z"
                    transform="translate(-1015.795 -848.813)"
                    fill="#838b89"
                  />
                  <path
                    id="Path_14011"
                    data-name="Path 14011"
                    d="M1017.432,852.208a3.853,3.853,0,0,1,1.976.065.238.238,0,0,1,.123.1c.052.115-.068.23-.175.292a6.546,6.546,0,0,1-3.036.694,4.279,4.279,0,0,0-.485.036,6.827,6.827,0,0,1,.337-1.237A5.229,5.229,0,0,0,1017.432,852.208Z"
                    transform="translate(-1015.729 -849.944)"
                    fill="#838b89"
                  />
                </g>
                <g id="Group_17520" data-name="Group 17520" transform="translate(14.85 1.024)">
                  <path
                    id="Path_14012"
                    data-name="Path 14012"
                    d="M1020.156,848.828a10.259,10.259,0,0,1,1.075.1c-.438,1.222-.376,2.6-.7,3.866a.9.9,0,0,1-.412.637c-.4.172-.8-.285-.932-.7-.4-1.232.016-2.574-.063-3.868A6.985,6.985,0,0,1,1020.156,848.828Z"
                    transform="translate(-1016.494 -848.821)"
                    fill="#838b89"
                  />
                  <path
                    id="Path_14013"
                    data-name="Path 14013"
                    d="M1019.519,850.912a.858.858,0,0,1-.089.579.524.524,0,0,1-.744,0,1.475,1.475,0,0,1-.365-.754c-.11-.433-.2-.872-.277-1.313a9.4,9.4,0,0,1,1.665-.53A6.405,6.405,0,0,0,1019.519,850.912Z"
                    transform="translate(-1018.044 -848.704)"
                    fill="#838b89"
                  />
                  <path
                    id="Path_14014"
                    data-name="Path 14014"
                    d="M1020.983,851.953a.425.425,0,0,1-.287-.075,1.753,1.753,0,0,1-.645-1.65c.029-.444.13-.88.175-1.321a8.716,8.716,0,0,1,1.472.454c-.219.674-.2,1.475-.392,2.177C1021.257,851.719,1021.163,851.92,1020.983,851.953Z"
                    transform="translate(-1014.823 -848.681)"
                    fill="#838b89"
                  />
                </g>
                <g id="Group_17522" data-name="Group 17522" transform="translate(1.695 6.199)">
                  <g id="Group_17521" data-name="Group 17521" transform="translate(17.715 9.594)">
                    <path
                      id="Path_14015"
                      data-name="Path 14015"
                      d="M1021.424,859.2a.655.655,0,0,1,.4-.355c.9-.366,1.931.057,2.879-.149a.575.575,0,0,0,.4-.384.759.759,0,0,0-.209-.973c-.755-.48-1.689-.332-4.4-.681a.943.943,0,0,1-.514-.3.581.581,0,0,1-.183-.546.777.777,0,0,1,.585-.465c.5-.151,4.445.439,5.5.519a5.826,5.826,0,0,1,.219,2.044l-.384-.548a4.674,4.674,0,0,1-1.736,3.25,4.341,4.341,0,0,1-.447.3,8.459,8.459,0,0,1-1.67-.914C1021.6,859.815,1021.317,859.515,1021.424,859.2Z"
                      transform="translate(-1019.791 -853.12)"
                      fill="#838b89"
                    />
                    <path
                      id="Path_14016"
                      data-name="Path 14016"
                      d="M1024.1,855.55c.018.185.036.373.055.553.047.128.091.258.136.386-1.5-.136-3.583-.324-3.649-1.235a.594.594,0,0,1,.324-.569,2.048,2.048,0,0,1,.791-.188c.843-.063,1.715.052,2.566.07a3.912,3.912,0,0,1,.475,1.493Z"
                      transform="translate(-1018.421 -854.48)"
                      fill="#838b89"
                    />
                  </g>
                  <path
                    id="Path_14017"
                    data-name="Path 14017"
                    d="M1016.584,853.2a3.768,3.768,0,0,1,1.028,1.908.58.58,0,0,1-.06.313.45.45,0,0,1-.464.175,1.239,1.239,0,0,1-.462-.238,8.729,8.729,0,0,1-1.866-1.866c-.217-.295-.417-.608-.65-.89l.021-.013c.086-.091.172-.177.264-.264a1.091,1.091,0,0,0-.665-.232,3.5,3.5,0,0,1,1.858-.906,3.72,3.72,0,0,0,.193.605A6.766,6.766,0,0,0,1016.584,853.2Z"
                    transform="translate(-1011.837 -850.194)"
                    fill="#838b89"
                  />
                  <path
                    id="Path_14018"
                    data-name="Path 14018"
                    d="M1016.335,853.991c.125.162.232.394.115.559a.5.5,0,0,1-.441.133,7.873,7.873,0,0,1-3-.765,5.258,5.258,0,0,1,1.08-1.827A19.393,19.393,0,0,1,1016.335,853.991Z"
                    transform="translate(-1013.004 -848.732)"
                    fill="#838b89"
                  />
                  <path
                    id="Path_14019"
                    data-name="Path 14019"
                    d="M1016.684,853.143a.572.572,0,0,1-.451.48.72.72,0,0,1-.577-.3,2.671,2.671,0,0,1-.619-1.185c-.065-.277-.089-.574-.151-.851a4.873,4.873,0,0,1,1.292-.48c.109.551.292,1.1.436,1.639A1.519,1.519,0,0,1,1016.684,853.143Z"
                    transform="translate(-1009.974 -850.804)"
                    fill="#838b89"
                  />
                </g>
                <path
                  id="Path_14020"
                  data-name="Path 14020"
                  d="M1016.66,854.508c1.211.423,2.54.125,3.821.167.264.01.577.065.692.308.146.321-.175.663-.494.809-1.039.47-2.237.12-3.372.052-.24-.013-.548.036-.6.274s.2.42.417.514a5.182,5.182,0,0,0,1.532.389,1.84,1.84,0,0,1,.7.141.54.54,0,0,1,.3.6.686.686,0,0,1-.525.363,3.253,3.253,0,0,1-1.814-.081,1.335,1.335,0,0,0-.465-.11.369.369,0,0,0-.365.261.437.437,0,0,0,.049.266,2.108,2.108,0,0,0,1.219.958,8.547,8.547,0,0,0,1.866.511,3.425,3.425,0,0,1,1.608.475.652.652,0,0,1,.253.326.569.569,0,0,1-.316.611,1.444,1.444,0,0,1-.715.123,14.13,14.13,0,0,1-4.852-.879,13.364,13.364,0,0,0,1.177-5.985"
                  transform="translate(-1007.124 -838.641)"
                  fill="#838b89"
                />
                <path
                  id="Path_14021"
                  data-name="Path 14021"
                  d="M1014.382,854.625a1.262,1.262,0,0,1,.885-1,2.045,2.045,0,0,1,1.368.1,3.913,3.913,0,0,1,2.245,2.957,6.551,6.551,0,0,1-.585,3.8,4.42,4.42,0,0,0-.587,1.879.275.275,0,0,0,.044.165.3.3,0,0,0,.175.086,4.6,4.6,0,0,1,1.339.58,1.294,1.294,0,0,1,.532.553.779.779,0,0,1-.167.833.747.747,0,0,1-.822.138,1.3,1.3,0,0,0,.593-.277.314.314,0,0,0,.141-.253c-.013-.1-.115-.172-.2-.222a17.915,17.915,0,0,0-2.393-1.138c.358-1.068.72-2.135.984-3.231a5.513,5.513,0,0,0-.081-3.633,3.148,3.148,0,0,0-3.223-1.728"
                  transform="translate(-1009.09 -840.183)"
                  fill="#838b89"
                />
                <g id="Group_17523" data-name="Group 17523" transform="translate(1.861 23.717)">
                  <path
                    id="Path_14022"
                    data-name="Path 14022"
                    d="M1017.982,858.519c.2-.047.4-.1.606-.154a.571.571,0,0,1-.319.358l.183.07c.318-.065.637-.133.958-.193a13.853,13.853,0,0,0,.522,3.7l-.3.021a1.241,1.241,0,0,0,.363.441,1.39,1.39,0,0,1,.005.18c-.428-.018-.856-.055-1.282-.1a31.073,31.073,0,0,1-.723-4.213Z"
                    transform="translate(-1005.155 -856.149)"
                    fill="#838b89"
                  />
                  <path
                    id="Path_14023"
                    data-name="Path 14023"
                    d="M1017.3,859.72a9.856,9.856,0,0,0-.436-1.125c.347-.005.7-.026,1.044-.057a4.306,4.306,0,0,0,0,.88c.036.337.112.665.154,1a10.039,10.039,0,0,1,.057,1.01,2,2,0,0,1-.12.892c-.2,0-.389-.016-.582-.029l-.175-.01C1017.052,861.453,1017.488,860.561,1017.3,859.72Z"
                    transform="translate(-1006.947 -855.87)"
                    fill="#838b89"
                  />
                  <path
                    id="Path_14024"
                    data-name="Path 14024"
                    d="M1016.172,859.336a1.991,1.991,0,0,1,.008-.647,8.114,8.114,0,0,0,.8-.175,4.083,4.083,0,0,0,.157.9,8,8,0,0,1,.4,1.349,2.033,2.033,0,0,1-.368,1.4l-.036.047c-.162-.015-.326-.034-.488-.052a.939.939,0,0,1-.084-.24A22.1,22.1,0,0,1,1016.172,859.336Z"
                    transform="translate(-1008.105 -855.909)"
                    fill="#838b89"
                  />
                  <path
                    id="Path_14025"
                    data-name="Path 14025"
                    d="M1015.489,860.767a9.8,9.8,0,0,1,.3-1.451.41.41,0,0,1,.167-.271.262.262,0,0,1,.258.021.593.593,0,0,1,.185.2,1.933,1.933,0,0,1,.271,1.422,4.923,4.923,0,0,1-.144.5c-.352-.055-.7-.115-1.054-.185A1.758,1.758,0,0,1,1015.489,860.767Z"
                    transform="translate(-1009.19 -855.095)"
                    fill="#838b89"
                  />
                  <path
                    id="Path_14026"
                    data-name="Path 14026"
                    d="M1015.9,860.79a.8.8,0,0,1-.284.339.546.546,0,0,1-.574.094.689.689,0,0,1-.258-.569,10.657,10.657,0,0,1,.086-2.119,1.242,1.242,0,0,1,.034-.2c.342.036.746.068,1.138.089-.026.65-.049,1.3-.076,1.95A1.265,1.265,0,0,1,1015.9,860.79Z"
                    transform="translate(-1010.348 -856.204)"
                    fill="#838b89"
                  />
                  <path
                    id="Path_14027"
                    data-name="Path 14027"
                    d="M1014.55,861.368c-.17.188-.418.371-.647.271a.508.508,0,0,1-.248-.308,1.9,1.9,0,0,1,.264-1.394l.376-.786a3,3,0,0,1,.512-.822,1.266,1.266,0,0,1,.282-.211,5.407,5.407,0,0,0,.809.389l-.047.157A7.285,7.285,0,0,1,1014.55,861.368Z"
                    transform="translate(-1012.21 -856.547)"
                    fill="#838b89"
                  />
                  <path
                    id="Path_14028"
                    data-name="Path 14028"
                    d="M1014.225,858.586a1.22,1.22,0,0,1-.937.146.325.325,0,0,1-.13-.055.288.288,0,0,1-.06-.342.739.739,0,0,1,.251-.271,4.78,4.78,0,0,1,.94-.548c.133.188.269.363.41.527A1.256,1.256,0,0,1,1014.225,858.586Z"
                    transform="translate(-1013.067 -857.516)"
                    fill="#838b89"
                  />
                </g>
                <path
                  id="Path_14029"
                  data-name="Path 14029"
                  d="M1031.472,855.231a3.429,3.429,0,0,1-1.738.6c-.82.125-1.639.25-2.461.368a14.863,14.863,0,0,1-3.432.222,15.134,15.134,0,0,1-1.561-.243,13.284,13.284,0,0,1-5.2-1.762l.086.626A1.821,1.821,0,0,1,1016,853.43a26.715,26.715,0,0,0,11.977,1.95c.739-.076,1.469-.193,2.2-.311a3.47,3.47,0,0,0,1.334-.41l-.055.493"
                  transform="translate(-1006.482 -840.377)"
                  fill="#838b89"
                />
              </g>
              <g id="Group_17527" data-name="Group 17527" transform="translate(20.113 7.562)">
                <path
                  id="Path_14030"
                  data-name="Path 14030"
                  d="M1020.127,852.254a2.363,2.363,0,0,1,.762-.629,3.077,3.077,0,0,1,2.13-.149,2.553,2.553,0,0,1,1.595,1.41.324.324,0,0,1,.034.156.309.309,0,0,1-.071.154,1.657,1.657,0,0,1-.78.509,3.312,3.312,0,0,1-1.838.125,2.789,2.789,0,0,1-1.563-.968,1.059,1.059,0,0,1-.269-.608"
                  transform="translate(-1019.953 -851.287)"
                  fill="#838b89"
                />
                <path
                  id="Path_14031"
                  data-name="Path 14031"
                  d="M1020.386,852.863a1.157,1.157,0,0,1-.256-.5c.005-.05.011-.1.018-.144a2.413,2.413,0,0,1,.731-.592,3.126,3.126,0,0,1,1.984-.185,1.613,1.613,0,0,1-.4,2.461,3.111,3.111,0,0,1-.511-.068A2.811,2.811,0,0,1,1020.386,852.863Z"
                  transform="translate(-1019.948 -851.282)"
                  fill="#838b89"
                />
                <g id="Group_17525" data-name="Group 17525" transform="translate(0.007)">
                  <path
                    id="Path_14032"
                    data-name="Path 14032"
                    d="M1020.308,852.43a2.712,2.712,0,0,1,4.408.8c.076.17.321.024.245-.146a2.973,2.973,0,0,0-4.855-.853c-.133.128.068.331.2.2Z"
                    transform="translate(-1020.063 -851.326)"
                    fill="#838b89"
                  />
                </g>
                <g id="Group_17526" data-name="Group 17526" transform="translate(0 0.914)">
                  <path
                    id="Path_14033"
                    data-name="Path 14033"
                    d="M1024.617,852.453c-1.357,1.326-3.291.739-4.286-.713-.1-.151-.35-.005-.245.146,1.1,1.611,3.234,2.232,4.734.77a.144.144,0,0,0-.2-.2Z"
                    transform="translate(-1020.061 -851.676)"
                    fill="#838b89"
                  />
                </g>
              </g>
              <g id="Group_17530" data-name="Group 17530" transform="translate(11.711 7.564)">
                <path
                  id="Path_14034"
                  data-name="Path 14034"
                  d="M1021.422,852.181a2.383,2.383,0,0,0-.783-.6,3.1,3.1,0,0,0-2.135-.07,2.569,2.569,0,0,0-1.542,1.469.324.324,0,0,0-.029.157.313.313,0,0,0,.078.151,1.619,1.619,0,0,0,.794.48,3.329,3.329,0,0,0,1.843.057,2.8,2.8,0,0,0,1.529-1.023,1.065,1.065,0,0,0,.245-.619"
                  transform="translate(-1016.699 -851.288)"
                  fill="#838b89"
                />
                <path
                  id="Path_14035"
                  data-name="Path 14035"
                  d="M1020.343,852.8a1.157,1.157,0,0,0,.237-.514l-.023-.141a2.458,2.458,0,0,0-.752-.564,3.133,3.133,0,0,0-1.989-.115,1.612,1.612,0,0,0,.488,2.446,3.074,3.074,0,0,0,.509-.086A2.818,2.818,0,0,0,1020.343,852.8Z"
                  transform="translate(-1015.861 -851.284)"
                  fill="#838b89"
                />
                <g id="Group_17528" data-name="Group 17528">
                  <path
                    id="Path_14036"
                    data-name="Path 14036"
                    d="M1021.69,852.149a2.978,2.978,0,0,0-4.837,1.06c-.071.172.2.248.274.076a2.7,2.7,0,0,1,4.359-.929c.139.123.339-.083.2-.206Z"
                    transform="translate(-1016.842 -851.326)"
                    fill="#838b89"
                  />
                </g>
                <g id="Group_17529" data-name="Group 17529" transform="translate(0.126 0.836)">
                  <path
                    id="Path_14037"
                    data-name="Path 14037"
                    d="M1016.935,852.8c1.545,1.4,3.657.718,4.7-.934.1-.159-.146-.3-.245-.146-.939,1.485-2.845,2.156-4.257.874-.136-.123-.337.081-.2.206Z"
                    transform="translate(-1016.89 -851.647)"
                    fill="#838b89"
                  />
                </g>
              </g>
              <g id="Group_17531" data-name="Group 17531" transform="translate(18.263 9.8)">
                <path
                  id="Path_14038"
                  data-name="Path 14038"
                  d="M1019.472,852.323l-.12,1.647c-.013.186.272.186.285,0l.12-1.647c.016-.185-.269-.185-.285,0Z"
                  transform="translate(-1019.352 -852.184)"
                  fill="#838b89"
                />
              </g>
              <path
                id="Path_14039"
                data-name="Path 14039"
                d="M1019.384,852.087a2.628,2.628,0,0,1,1.2-.011.954.954,0,0,1,.548.29.223.223,0,0,1,.063.146c0,.094-.089.159-.172.2a2.525,2.525,0,0,1-1.579.052.578.578,0,0,1-.381-.18.329.329,0,0,1,.024-.386.393.393,0,0,1,.371-.128"
                transform="translate(-1001.637 -842.659)"
                fill="#838b89"
              />
              <g id="Group_17532" data-name="Group 17532" transform="translate(17.311 11.524)">
                <path
                  id="Path_14040"
                  data-name="Path 14040"
                  d="M1020.732,853.055c-.18.292-.464-.029-.579-.167a.144.144,0,0,0-.224.029c-.17.224-.491.334-.673.039-.1-.157-.345-.011-.246.146a.679.679,0,0,0,1.164-.039l-.222.029c.264.318.752.553,1.026.109a.143.143,0,0,0-.245-.146Z"
                  transform="translate(-1018.987 -852.844)"
                  fill="#838b89"
                />
              </g>
              <g id="Group_17533" data-name="Group 17533" transform="translate(21.812 10.995)">
                <path
                  id="Path_14041"
                  data-name="Path 14041"
                  d="M1020.848,852.943a17.122,17.122,0,0,1,1.832.029c.183.013.18-.277,0-.29a17.889,17.889,0,0,0-1.832-.029c-.183.008-.183.295,0,.29Z"
                  transform="translate(-1020.711 -852.642)"
                  fill="#838b89"
                />
              </g>
              <g id="Group_17534" data-name="Group 17534" transform="translate(21.926 11.386)">
                <path
                  id="Path_14042"
                  data-name="Path 14042"
                  d="M1020.854,853.075l1.42.431c.178.052.251-.227.076-.279l-1.42-.428c-.175-.055-.251.225-.076.277Z"
                  transform="translate(-1020.755 -852.791)"
                  fill="#838b89"
                />
              </g>
              <g id="Group_17535" data-name="Group 17535" transform="translate(12.562 10.827)">
                <path
                  id="Path_14043"
                  data-name="Path 14043"
                  d="M1019.025,852.676c-.572-.047-1.146-.1-1.72-.1a.145.145,0,0,0,0,.29c.574,0,1.148.052,1.72.1.182.013.182-.277,0-.29Z"
                  transform="translate(-1017.168 -852.577)"
                  fill="#838b89"
                />
              </g>
              <g id="Group_17536" data-name="Group 17536" transform="translate(13.056 11.385)">
                <path
                  id="Path_14044"
                  data-name="Path 14044"
                  d="M1018.647,852.791a8.6,8.6,0,0,0-1.188.086c-.18.026-.1.305.076.28a7.882,7.882,0,0,1,1.112-.076.145.145,0,0,0,0-.29Z"
                  transform="translate(-1017.357 -852.791)"
                  fill="#838b89"
                />
              </g>
              <g id="Group_17537" data-name="Group 17537" transform="translate(16.391 10.586)">
                <path
                  id="Path_14045"
                  data-name="Path 14045"
                  d="M1018.772,852.774a.145.145,0,0,0,0-.29.145.145,0,0,0,0,.29Z"
                  transform="translate(-1018.635 -852.485)"
                  fill="#838b89"
                />
              </g>
              <g id="Group_17538" data-name="Group 17538" transform="translate(15.78 10.586)">
                <path
                  id="Path_14046"
                  data-name="Path 14046"
                  d="M1018.537,852.774a.145.145,0,0,0,0-.29.145.145,0,0,0,0,.29Z"
                  transform="translate(-1018.4 -852.485)"
                  fill="#838b89"
                />
              </g>
              <g id="Group_17539" data-name="Group 17539" transform="translate(16.094 11.341)">
                <path
                  id="Path_14047"
                  data-name="Path 14047"
                  d="M1018.66,853.064a.145.145,0,0,0,0-.29.145.145,0,0,0,0,.29Z"
                  transform="translate(-1018.521 -852.774)"
                  fill="#838b89"
                />
              </g>
              <g id="Group_17540" data-name="Group 17540" transform="translate(20.609 10.795)">
                <path
                  id="Path_14048"
                  data-name="Path 14048"
                  d="M1020.424,852.565h-.036a.145.145,0,0,0,0,.29h.036a.145.145,0,0,0,0-.29Z"
                  transform="translate(-1020.25 -852.565)"
                  fill="#838b89"
                />
              </g>
              <g id="Group_17541" data-name="Group 17541" transform="translate(19.998 10.719)">
                <path
                  id="Path_14049"
                  data-name="Path 14049"
                  d="M1020.153,852.826a.145.145,0,0,0,0-.29.145.145,0,0,0,0,.29Z"
                  transform="translate(-1020.016 -852.536)"
                  fill="#838b89"
                />
              </g>
              <g id="Group_17542" data-name="Group 17542" transform="translate(20.289 11.439)">
                <path
                  id="Path_14050"
                  data-name="Path 14050"
                  d="M1020.412,852.971v-.021a.142.142,0,0,0-.285,0v.021a.142.142,0,0,0,.285,0Z"
                  transform="translate(-1020.128 -852.811)"
                  fill="#838b89"
                />
              </g>
            </g>
          </g>
        </g>
        <path
          id="Path_14052"
          data-name="Path 14052"
          d="M960.531,836.9a18.881,18.881,0,0,0,1.482,11.26,6.778,6.778,0,0,0,2.464,3.106,10.8,10.8,0,0,0,4.62,1.143,542.2,542.2,0,0,0,61.762,1.816c22.4-.459,45.718-.112,68.07-1.438,6.65-.4,17.4-.592,23.994-1.386,3.98-.48,4.037-.031,7.125-2.174s3.685-9.193,1.4-11.949c-2.464-2.983-7.642-2.89-11.986-2.717-48.092,1.9-98.318,1.958-148.781.767-2.707-.063-10.445-.882-10.156,1.571"
          transform="translate(-332.854 85.281)"
          fill="#838b89"
        />
        <path
          id="Path_14057"
          data-name="Path 14057"
          d="M982.152,861.422c-4.565.112-8.083-7.741-8.232-12.437s-1.762-8.668-2.474-13.306-14.36-6.974-17.234-1.449c-3.179,6.115-1.331,14.128-1.347,21.063-.018,8.381,3.043,16.814,9.326,22.151,5.087,4.323,12.471,6.692,19.056,7.1,26.526,1.657,126.18,3.307,165.367-3.456,12.061-2.083,20.635-14.139,22.5-21.8,1.817-7.438,1.757-18.377-.932-25.529-1.8-4.771-7.42-7.206-12.366-6.661-4.223.467-6.583,5.306-7.248,9.621s-.533,9.034-2.989,12.6c-1.955,2.845-5.434,5.99-8.632,7.136-7.094,2.54-10.865,2.957-18.32,3.918-44.961,5.8-94.9,6.241-139.88.632"
          transform="translate(-348.928 71.367)"
          fill="#667673"
        />
        <g id="Group_17545" data-name="Group 17545" transform="translate(597.75 930.688)">
          <path
            id="Path_10064"
            data-name="Path 10064"
            d="M962.182,874.8a20.215,20.215,0,0,0-4.928-9.07c-1.386-1.472-3.137-3.142-2.722-5.1a.886.886,0,0,1,.316-.572,1.145,1.145,0,0,1,1.054.068q1.55.634,3.1,1.274c1.579-4.082-3.973-10.921-2.665-12.455s2.508,1.261,2.508,1.261q-.4-4.706-.8-9.407a4.006,4.006,0,0,1,.394-2.67c.971-1.386,3.356-1.023,4.46.264s1.287,3.077,1.435,4.745q.317,3.551.7,7.092a.85.85,0,0,0,.673.746c.715.138,1-.786,1.448-1.313a2.007,2.007,0,0,1,2.895.214,3.215,3.215,0,0,1,.368,3.067,14.425,14.425,0,0,1-1.563,2.832,19.35,19.35,0,0,0-2.584,6.757c1.185.485,2.563-.919,3.748-.175a1.575,1.575,0,0,1,.707,1.334,4.1,4.1,0,0,1-1.334,2.631c-2.513,2.913-4.706,5.565-7.219,8.475"
            transform="translate(-939.895 -837.244)"
            fill="#deebdb"
          />
          <path
            id="Path_10065"
            data-name="Path 10065"
            d="M956.106,871.933l-1.522-16.882H969.56s-.8,16.6-1.031,16.594C967.3,871.636,956.106,871.933,956.106,871.933Z"
            transform="translate(-939.712 -808.573)"
            fill="#674d4d"
          />
          <g id="Group_11211" data-name="Group 11211" transform="translate(20.542 2.449)">
            <path
              id="Path_10066"
              data-name="Path 10066"
              d="M959.277,881.591c-1.044-5.471.123-11.02.292-16.511a69.056,69.056,0,0,0-.4-8.535q-.4-4.228-.809-8.459-.462-4.8-.919-9.589c-.042-.417-.72-.42-.679,0q.818,8.57,1.639,17.135a80.74,80.74,0,0,1,.511,8.551c-.029,2.788-.344,5.562-.553,8.342a35.121,35.121,0,0,0,.266,9.242c.078.418.734.24.655-.175Z"
              transform="translate(-956.756 -838.182)"
              fill="#1a1718"
            />
          </g>
          <path
            id="Path_10067"
            data-name="Path 10067"
            d="M966.882,864.129a6.293,6.293,0,0,0-3.477-2.1,24.231,24.231,0,0,0-3.85-.23,5.3,5.3,0,0,1-3.539-1.261c-.389-.391-.616-1.13-.133-1.407.446-.253,1.141.146,1.485-.227s-.141-.921-.585-1.182a46.144,46.144,0,0,1-4.7-2.853,7.9,7.9,0,0,1-3.106-4.327,2.462,2.462,0,0,1,.3-2.085c.806-1.013,2.451-.747,3.638-.193a12.1,12.1,0,0,1,4.408,3.5,3.949,3.949,0,0,0,1.245,1.24,1.156,1.156,0,0,0,1.553-.378c.292-.679-.407-1.645.177-2.109a.97.97,0,0,1,.7-.151,4.183,4.183,0,0,1,2.727,1.95,20.127,20.127,0,0,1,4,11.894"
            transform="translate(-948.886 -820.287)"
            fill="#b0cea9"
          />
          <path
            id="Path_10068"
            data-name="Path 10068"
            d="M959.1,866.456a14.361,14.361,0,0,1,3.871-7.809,1.063,1.063,0,0,1,.389-.292.772.772,0,0,1,.921.605,2.112,2.112,0,0,1-.19,1.19c.908.162,1.506-.875,1.8-1.725l2.177-6.261c.264-.76,1.052-1.7,1.7-1.2.345.269.324.767.371,1.2s.357.935.786.83a.855.855,0,0,0,.485-.486c1.193-2.263,1.245-5.069,2.9-7.039a3.152,3.152,0,0,1,1.665-1.117,2.835,2.835,0,0,1,3.111,2.237,5.868,5.868,0,0,1-.841,4.007,24.554,24.554,0,0,0-1.934,3.722c.668.47,1.42-.48,2.195-.754a1.687,1.687,0,0,1,2.057,1.289,2.618,2.618,0,0,1-.989,2.393,8.161,8.161,0,0,1-2.425,1.287l-4.847,1.908c-.786.311-1.72.848-1.61,1.665l2.414.384c.439.527-.313,1.208-.958,1.47a31.225,31.225,0,0,1-12.633,2.26"
            transform="translate(-932.438 -825.886)"
            fill="#c0d3bc"
          />
          <g id="Group_11212" data-name="Group 11212" transform="translate(7.179 34.168)">
            <path
              id="Path_10069"
              data-name="Path 10069"
              d="M964.291,862.108a24.57,24.57,0,0,0-12.137-11.74c-.394-.175-.739.391-.342.569a24.125,24.125,0,0,1,10.051,8.308,23.545,23.545,0,0,1,1.84,3.195c.183.384.767.05.587-.331Z"
              transform="translate(-951.637 -850.335)"
              fill="#1a1718"
            />
          </g>
          <g id="Group_11213" data-name="Group 11213" transform="translate(24.991 26.913)">
            <path
              id="Path_10070"
              data-name="Path 10070"
              d="M959.139,866.61a9.329,9.329,0,0,1,1.243-4.536,10.025,10.025,0,0,1,3.448-3.505c1.48-.929,3.075-1.665,4.5-2.678a21.523,21.523,0,0,0,3.67-3.279,20.956,20.956,0,0,0,3.083-4.557c.19-.379-.4-.713-.585-.332a20.559,20.559,0,0,1-6.374,7.5c-1.441,1.039-3.054,1.793-4.562,2.727a10.905,10.905,0,0,0-3.581,3.474,10.055,10.055,0,0,0-1.522,5.184.34.34,0,0,0,.679,0Z"
              transform="translate(-958.461 -847.555)"
              fill="#1a1718"
            />
          </g>
          <path
            id="Path_10071"
            data-name="Path 10071"
            d="M971.473,854.5l-.107,4.318h-17.3v-4.2Z"
            transform="translate(-940.538 -809.469)"
            fill="#6273a9"
          />
        </g>
        <g id="Group_17546" data-name="Group 17546" transform="translate(618.15 849.496)">
          <path
            id="Path_9977"
            data-name="Path 9977"
            d="M999.484,838.517c.6,0,.785-.8.676-1.381-.378-2.01-2.038-3.545-3.628-4.889a40.788,40.788,0,0,0-7.436-5.288c-1.227-.637-2.505-1.161-3.764-1.733-4.239-1.924-8.229-4.377-12.492-6.246a39.563,39.563,0,0,1-3.177,7.485.952.952,0,0,0,.112,1.48c2.994,2.72,6.724,4.474,10.388,6.186l4.325,2.02c.833.389,1.668.778,2.526,1.109a27.528,27.528,0,0,0,13.069,1.245"
            transform="translate(-937.877 -789.185)"
            fill="#ffd1a8"
          />
          <path
            id="Path_9978"
            data-name="Path 9978"
            d="M967.028,828.824a61.256,61.256,0,0,0-6.079,8.078c-1.483,2.349-2.848,4.949-2.837,7.773a14.167,14.167,0,0,0,1.725,6.063,116.941,116.941,0,0,0,8.757,13.645c1.783-.765,3.8-1.4,5.079-2.824,1.66-1.84,3.529-5.588,2.462-8.728a9.3,9.3,0,0,1-2.955,9.461c3.4,5.622,10.785,15.942,17.391,15.942,0,0,23.459-22.919,24.159-23.869-6.293-3.417-15.219-13.745-18.145-20.439-2.231-5.1-3.881-10.5-6.77-15.253-1.023-1.678-2.4-3.4-4.3-3.665a5.529,5.529,0,0,0-3.19.713c-4.422,2.205-7.245,6.666-10.7,10.244a6.477,6.477,0,0,1-1.7,1.376c-1.057.522-2.573.318-2.892,1.483"
            transform="translate(-956.03 -795.638)"
            fill="#ed6c6c"
          />
          <path
            id="Path_9980"
            data-name="Path 9980"
            d="M1011.11,820.316c-1.284,7.582-1.973,14.927-3.6,22.23-4.2.825-27.46,5.909-29.9,6.556q1.022-11.451,2.038-22.908c2.9-.068,31.462-5.878,31.462-5.878"
            transform="translate(-924.637 -787.035)"
            fill="#1a1718"
          />
          <g id="Group_11173" data-name="Group 11173" transform="translate(16.142 50.479)">
            <path
              id="Path_9981"
              data-name="Path 9981"
              d="M967.511,833.485a49.491,49.491,0,0,0-3.691-6.5c-.123-.18-.415-.016-.292.167a49.371,49.371,0,0,1,3.691,6.5c.094.2.386.029.292-.167Z"
              transform="translate(-963.498 -826.905)"
              fill="#1a1818"
            />
          </g>
          <path
            id="Path_9982"
            data-name="Path 9982"
            d="M968.41,838.03q-2.835-5.058-5.664-10.114a3.1,3.1,0,0,1-2.863.483,2.537,2.537,0,0,1-1.657-2.373,1.941,1.941,0,0,1,1.97-1.877,9.938,9.938,0,0,1-.41-7.934,9.308,9.308,0,0,1,5.57-5.405,2.412,2.412,0,0,1,1.352-.151,2.959,2.959,0,0,1,1.517,1.331,104.1,104.1,0,0,1,7.115,11.675,4.048,4.048,0,0,1,.572,1.642,4.008,4.008,0,0,1-1.49,2.775q1.19,1.836,2.378,3.675a2.6,2.6,0,0,1,.488,1.107,2.3,2.3,0,0,1-.611,1.54,12.753,12.753,0,0,1-4.2,3.552c-1.269.666-2.89,1.081-4.08.238"
            transform="translate(-955.849 -802.657)"
            fill="#ffd1a8"
          />
          <path
            id="Path_9983"
            data-name="Path 9983"
            d="M962.114,847.365c1.209,1.044,2.952,1.208,4.581,1.25,14.723.381,24.78-6.186,38.569-11.779.921-.376,2.049-1.19,1.65-2.07a3.676,3.676,0,0,0,1.37-1.052,1.19,1.19,0,0,0-.073-1.555c2.477-1.624.331-2.341.331-2.341,1.06-2.062-2.145-1.19-3.795-.911a18.877,18.877,0,0,1-5.053.53c1.53-.77,5.082-2.769,3.236-3.97-1.412.652-5.58,3.158-7.212,4.072-6.113,3.422-16.49,6.256-18.4,6.256l-7.687-.008a41.542,41.542,0,0,1-1.89,7.768C966.984,845.864,963.544,846.916,962.114,847.365Z"
            transform="translate(-949.586 -778.744)"
            fill="#ffd1a8"
          />
          <g id="Group_11179" data-name="Group 11179" transform="translate(12.224 29.043)">
            <path
              id="Path_9989"
              data-name="Path 9989"
              d="M962.113,825.37a5.206,5.206,0,0,0,2.96.227,7.727,7.727,0,0,0,2.555-1.19,13.109,13.109,0,0,0,2.8-2.506,3.369,3.369,0,0,0,.875-3.088c-.065-.209-.391-.125-.326.083a3.035,3.035,0,0,1-.812,2.8,13.074,13.074,0,0,1-2.61,2.357,6.068,6.068,0,0,1-4.173,1.307,6.308,6.308,0,0,1-1.177-.316c-.2-.07-.292.258-.089.329Z"
              transform="translate(-961.998 -818.693)"
              fill="#1a1818"
            />
          </g>
          <path
            id="Path_9990"
            data-name="Path 9990"
            d="M960.257,817.759l2.573,4.638s5.484-1.018,8.232-3.477Z"
            transform="translate(-952.576 -791.153)"
            fill="#ffd1a8"
          />
          <path
            id="Path_9991"
            data-name="Path 9991"
            d="M960.829,828.644q1.566,2.118,3.163,4.218a13.756,13.756,0,0,0,1.616,1.876,8.324,8.324,0,0,0,7.188,1.785,7.414,7.414,0,0,0,5.032-3.26,5.078,5.078,0,0,0-.428-5.883,2.86,2.86,0,0,0-1.944-.966,3.474,3.474,0,0,0-1.2.24,9.934,9.934,0,0,0-4.766,3.615,3.937,3.937,0,0,1-1.24,1.357,2.1,2.1,0,0,1-1.146.193c-1.66-.149-2.913-1.639-3.751-3.129a6.5,6.5,0,0,1-.911-2.477,2.777,2.777,0,0,1,.812-2.391,3.962,3.962,0,0,1,1.657-.71c2.855-.715,5.946-1.412,7.875-3.672a13.682,13.682,0,0,0,1.9-3.325,5.388,5.388,0,0,1-2.466,1.263c1.172-1.556.953-3.782.509-5.721a7.523,7.523,0,0,1-2.738,2.649c.546-1.806-.8-5.079-1.725-6.742-2.31,3.8-9.174,10.518-10.662,12.641-.8,1.143.274,3.86.908,5.136a14.419,14.419,0,0,0,2.32,3.3"
            transform="translate(-957.314 -807.565)"
            fill="#868686"
          />
          <g id="Group_11180" data-name="Group 11180" transform="translate(28.115 22.278)">
            <path
              id="Path_9993"
              data-name="Path 9993"
              d="M968.316,816.36a3.321,3.321,0,0,1,.162,1.122c.021.548,0,1.1-.034,1.647-.065,1.094-.183,2.187-.355,3.268-.034.214.29.308.324.091a34.185,34.185,0,0,0,.373-3.458,6.8,6.8,0,0,0-.178-2.84c-.083-.2-.376-.031-.292.17Z"
              transform="translate(-968.086 -816.1)"
              fill="#1a1818"
            />
          </g>
          <g id="Group_11181" data-name="Group 11181" transform="translate(30.213 23.511)">
            <path
              id="Path_9994"
              data-name="Path 9994"
              d="M969.217,816.735a26.3,26.3,0,0,1,.071,2.936,24.829,24.829,0,0,1-.394,2.944c-.036.217.284.308.324.091a25.913,25.913,0,0,0,.4-2.944,25.4,25.4,0,0,0-.063-3.022c-.013-.219-.35-.225-.337-.005Z"
              transform="translate(-968.89 -816.573)"
              fill="#1a1818"
            />
          </g>
          <g id="Group_11182" data-name="Group 11182" transform="translate(32.122 25.517)">
            <path
              id="Path_9995"
              data-name="Path 9995"
              d="M969.991,817.5a13.378,13.378,0,0,1-.112,2.263,18.383,18.383,0,0,1-.253,1.96c-.044.214.277.306.324.091a18.891,18.891,0,0,0,.256-1.952,13.451,13.451,0,0,0,.12-2.357c-.016-.217-.35-.225-.334-.005Z"
              transform="translate(-969.621 -817.341)"
              fill="#1a1818"
            />
          </g>
          <path
            id="Path_9997"
            data-name="Path 9997"
            d="M993.889,822.327a30.027,30.027,0,0,0-3.445-.619c-.36-.042-.791-.049-1.008.235a.673.673,0,0,0,.034.77,1.739,1.739,0,0,0,.65.5,8.579,8.579,0,0,0,3.205.937.96.96,0,0,0,.551-.068.932.932,0,0,0,.519-.8c-.047-.305.123-.853-.506-.955"
            transform="translate(-905.767 -784.829)"
            fill="#ffd1a8"
          />
          <path
            id="Path_9998"
            data-name="Path 9998"
            d="M994.945,823.687l-3.69-1.229a4.28,4.28,0,0,0-1.094-.259,3.033,3.033,0,0,0-.838.094c-.258.063-.561.2-.577.459a.563.563,0,0,0,.227.423,5.186,5.186,0,0,0,2.023,1q1.461.47,2.926.942a1.236,1.236,0,0,0,.564.086.839.839,0,0,0,.6-.658c.188-.718.016-.681-.141-.859"
            transform="translate(-906.708 -784.007)"
            fill="#ffd1a8"
          />
          <path
            id="Path_9999"
            data-name="Path 9999"
            d="M994.153,824.065a14.245,14.245,0,0,0-4.594-1.133.712.712,0,0,0-.436.091.5.5,0,0,0-.188.324c-.1.582.472,1.041,1,1.318a10.893,10.893,0,0,0,2.928,1.031,2.131,2.131,0,0,0,.517.023c.485-.021.877.151,1.018-.566.157-.8.044-.859-.245-1.088"
            transform="translate(-906.422 -782.824)"
            fill="#ffd1a8"
          />
          <path
            id="Path_10000"
            data-name="Path 10000"
            d="M992.686,824.3a.55.55,0,0,1,.436.031q-1.155-.219-2.307-.433c-.459-.086-1.036-.125-1.295.258a.68.68,0,0,0-.094.488c.1.619.827.9,1.443,1.068l1.308.357a1.988,1.988,0,0,0,1.235.065.979.979,0,0,0,.577-1.143.992.992,0,0,0-1.1-.655"
            transform="translate(-905.629 -781.362)"
            fill="#ffd1a8"
          />
        </g>
        <path
          id="Path_14062"
          data-name="Path 14062"
          d="M974.168,850.523a52.326,52.326,0,0,0,14.366,1.929,23.193,23.193,0,0,0,6.175-.718c2.5-.71,35.977-12.3,35.977-12.3s29.321,26.834,30.5,26.4c2.981-1.094,9.775-10.083,9.775-10.083s-29.259-23.08-30.7-24.148c-4.053-3-7.76-5.614-15.564-5.489-5.011.078-37.089,11.356-37.561,11.5a2.577,2.577,0,0,0-1.013.733q-5.971,6.026-11.938,12.053"
          transform="translate(-312.027 71.789)"
          fill="#2b2b2b"
        />
        <g transform="matrix(1, 0, 0, 1, 520.15, 785.35)" filter="url(#Path_14063)">
          <path
            id="Path_14063-2"
            data-name="Path 14063"
            d="M970.158,854.347c5.051,3.678,14.828,4.142,19.68,3.231,2.041-.384,6.249-1.592,8.279-2.041,7.7-1.7,24.814-10.719,31.253-10.714,6.583.008,43.933,10.26,44.6,10.03,2.54-.874,5.808-7.67,4.884-10.03-6.588-3.74-34.217-13.159-34.217-13.159s-11.852-3.258-16.8-2.444-33.884,8.243-38,7.131c-.477-.13-4.445,5.16-4.914,5.3-.4.123-.316.31-.611.611-3.98,4.02-10.177,8.065-14.154,12.082"
            transform="translate(-838.63 -708.79)"
            fill="#080808"
          />
        </g>
      </g>
    </svg>
  );
}

export default NothingToSeeIcon;
