import React, { useCallback, useState } from "react";

import { useDropzone } from "react-dropzone";

import "./FileUploader.css";
import { formatFileSize } from "util/formatFileSize";
import Button from "components/Button/Button";
import CrossIcon from "assets/svgs/CrossIcon";

const FileUploader = ({ onFileUpload }) => {
  const [filesToUpload, setFilesToUpload] = useState([]);

  const onDrop = useCallback(
    (acceptedFiles) => {
      // Do something with the files
      onFileUpload(acceptedFiles);
      setFilesToUpload(acceptedFiles);
    },
    [onFileUpload]
  );

  const removeFile = (fileToRemove) => {
    const updatedFiles = filesToUpload.filter((file) => file !== fileToRemove);
    setFilesToUpload(updatedFiles);
    onFileUpload(updatedFiles);
  };
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  return (
    <div>
      <div
        {...getRootProps()}
        className={` request-timeoff-textarea request-timeoff-files dropzone ${isDragActive ? "dropzone-active" : ""}`}
      >
        <input {...getInputProps()} />
        <div className="flex droparea gap-2">
          <div className="flex flex-col">
            <p>Drag your files here OR just select files you want to add</p>
            <p className="label-sm">File size should not exceed 2 mbs</p>
          </div>
          <div className=" flex ml-1 items-center">
            <Button variant="primary" size="sm">
              Select files
            </Button>
          </div>
        </div>
      </div>

      {filesToUpload?.length ? (
        <div>
          <p className="ml-1">{filesToUpload?.length} document(s) to upload</p>

          {filesToUpload?.map((file) => (
            <div className="flex uploaded-file-list-card justify-between ">
              <div className="flex flex-col ">
                <p>{file.name}</p>
                <p className="label-sm">{formatFileSize(file.size)}</p>
              </div>
              <CrossIcon onClick={() => removeFile(file)} className="align-self-center cursor-pointer mr-05" />
            </div>
          ))}
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};

export default FileUploader;
