import React from "react";

const UserAvatarIcon = ({ color = "#02006E", size = "18" }) => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ width: size, height: size }}
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M6.03532 19.2398C7.6233 20.6462 9.71194 21.5 12 21.5C14.2881 21.5 16.3767 20.6462 17.9647 19.2398C17.672 18.1873 15.5694 17.5 12 17.5C8.43062 17.5 6.328 18.1873 6.03532 19.2398ZM4.6005 17.6246C5.72595 16.1381 8.3706 15.5 12 15.5C15.6294 15.5 18.274 16.1381 19.3995 17.6246C20.4086 16.1703 21 14.4042 21 12.5C21 7.52944 16.9706 3.5 12 3.5C7.02944 3.5 3 7.52944 3 12.5C3 14.4042 3.59138 16.1703 4.6005 17.6246ZM12 23.5C5.92487 23.5 1 18.5751 1 12.5C1 6.42487 5.92487 1.5 12 1.5C18.0751 1.5 23 6.42487 23 12.5C23 18.5751 18.0751 23.5 12 23.5ZM8 10.5C8 8.25576 9.5791 6.5 12 6.5C14.4142 6.5 16 8.42158 16 10.7C16 13.9796 14.2181 15.5 12 15.5C9.76086 15.5 8 13.9274 8 10.5ZM10 10.5C10 12.7693 10.8182 13.5 12 13.5C13.1777 13.5 14 12.7984 14 10.7C14 9.45042 13.2157 8.5 12 8.5C10.7337 8.5 10 9.31582 10 10.5Z"
        fill={color}
      />
    </svg>
  );
};

export default UserAvatarIcon;
