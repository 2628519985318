import {
  RESET_GET_ALL_HOLIDAYS,
  GET_ALL_HOLIDAYS,
  GET_ALL_HOLIDAYS_SUCCESS,
  GET_ALL_HOLIDAYS_FAILURE,
} from "../../constants/Holidays";

// for user authentication
export const ResetGetAllHolidays = (data) => {
  return {
    type: RESET_GET_ALL_HOLIDAYS,
    payload: data,
  };
};

export const GetAllHolidays = (data) => {
  return {
    type: GET_ALL_HOLIDAYS,
    payload: data,
  };
};
export const GetAllHolidaysSuccess = (payload) => {
  return {
    type: GET_ALL_HOLIDAYS_SUCCESS,
    payload,
  };
};
export const GetAllHolidaysFailure = (payload) => {
  return {
    type: GET_ALL_HOLIDAYS_FAILURE,
    payload,
  };
};
