import { call, put, takeEvery } from 'redux-saga/effects';
import {
  AddEmployeeSuccess,
  AddEmployeeFailure,
} from '../../actions/AddEmployee';
import { ADD_EMPLOYEE } from '../../constants/AddEmployee';
import postRequest from '../../../util/APIHelperPost';
import { baseUrl } from '../../../util/APIBaseUrl';
import { getUtcFormatDate } from '../../../components/Screens/CommonUtils';

//function

export const fetchAddEmployeeRequest = async (actions) => {
  let body = {
    // picture: actions.payload?.employeeProfileData?.imageBlob,
    firstName: actions?.payload?.employeeProfileData?.firstName,
    lastName: actions?.payload?.employeeProfileData?.lastName,
    email: actions.payload?.employeeProfileData?.email,
    gender: actions.payload?.employeeProfileData?.gender?.key,
    addressLine1: actions.payload?.employeeProfileData?.residentialAddress,

    country: actions.payload?.employeeProfileData?.country?.value,
    dateOfBirth: getUtcFormatDate(
      actions?.payload?.employeeProfileData?.birthday
    ),
    phoneNumber: actions.payload?.employeeProfileData?.contactNumber,
    jobTitle: actions.payload?.employeeProfileData?.jobTitle,
    state: actions.payload?.employeeProfileData?.state,
    city: actions.payload?.employeeProfileData?.city,
    zip: actions.payload?.employeeProfileData?.code,
    Linkedin_Url: actions.payload?.employeeProfileData?.linkedUrl,
    // discount: actions.payload?.employeeProfileData?.discount,
    // discountPercentage:
    //     actions.payload?.employeeProfileData?.discountPercentage,
    // flatDiscount: actions.payload?.employeeProfileData?.flatDiscount,
    edgeJoiningDate: getUtcFormatDate(
      actions?.payload?.employeeProfileData?.edgeJoiningDate
    ),
    allowedForPayoneer: actions.payload?.employeeProfileData?.allowedForPayoneer,
    // discountDuration:
    //     actions.payload?.employeeProfileData?.discountDuration?.key,
  };
  // if (actions?.payload?.employeeProfileData?.loadFromUrl == false) {
  //     body["picture"] = actions.payload?.employeeProfileData?.imageBlob;
  // }

  if (actions.payload.method == 'POST') {
    body['password'] = 'password';
  }
  body = JSON.stringify(body);

  return await postRequest(
    `${baseUrl}/${actions.payload.request}`,
    body,
    true,
    actions.payload.method
  )
    .then((response) => response)
    .catch((error) => {
      throw error;
    });
};

function* fetchAddEmployee(params) {
  try {
    let response = yield call(fetchAddEmployeeRequest, params);
    yield put(AddEmployeeSuccess(response));
  } catch (error) {
    console.error(error);
    yield put(AddEmployeeFailure(error));
  }
}

export default function* rootSaga() {
  yield takeEvery(ADD_EMPLOYEE, fetchAddEmployee);
}
