//employee constants
import { ADD_COMPANY_PROFILE_DATA } from "../../constants/Settings/AddCompanyProfileData";

const INIT_STATE = {
    addCompanyProfileData: {},
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case ADD_COMPANY_PROFILE_DATA:
            return {
                ...state,
                addCompanyProfileData: action.payload,
            };

        default:
            return state;
    }
};
