import React from "react";
//material ui
import { Grid, Paper, Typography, Button, Badge, Box } from "@mui/material";
import { settingPages } from "../SettingPages.enumn";
//icon
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import { AiOutlineEdit } from "react-icons/ai";
import { HiOutlineOfficeBuilding } from "react-icons/hi";
//redux dispatch
import { useSelector, useDispatch } from "react-redux";
import { baseUrl } from "../../../../util/APIBaseUrl";
import { GetCustomerUserandCompanyProfileData } from "../../../../store/actions/Settings/CustomerUser&CompanyProfileData";
import AvatarIcon from "../../../Icons/AvatarIcon";
import { getValue } from "../../CommonUtils";

function CompanyProfilee(props) {
  const { handleEditChange } = props;
  const dispatch = useDispatch();
  let photoInput = React.createRef();

  //component state
  const [companyProfileUrl, setCompanyProfileUrl] = React.useState(null);

  //redux state
  const customerUserandCompanyProfileData = useSelector(
    (state) => state.CustomerUserandCompanyProfileData.customerUserandCompanyProfileData
  );

  const userAdditionalInfo = useSelector((state) => state.UserAdditionalInfo.userAdditionalInfo);

  //functions
  const postLogo = async (actions) => {
    const response = await fetch(`${baseUrl}/${actions.request}`, {
      method: "POST",
      headers: {},
      body: actions.formData,
    });
    if (!response.ok) {
      return;
    }
    window.location.reload();
    const data = await response.json();
  };

  const uploadProfilePic = () => {
    photoInput.current.click();
  };

  const handleImageChange = (e) => {
    let logoRequest = "";
    let loggedInUser = JSON.parse(localStorage.getItem("loggedInUser"));
    e.preventDefault();
    let inFile = e.target.files[0];
    var form_data = new FormData();
    form_data.append("file", inFile);
    if (loggedInUser?.type == "customer") {
      logoRequest = `customer/${loggedInUser?.customerId}/logo`;
    }

    postLogo({
      request: logoRequest,
      formData: form_data,
    });
  };

  //methods
  React.useEffect(() => {
    let companyProfileUrl = "";
    let getProfileDataRequest = "";
    let loggedInUser = {};
    let userAdditionalInfo = JSON.parse(sessionStorage.getItem("userAdditionalInfo"));

    loggedInUser = JSON.parse(localStorage.getItem("loggedInUser"));

    if (Object.keys(loggedInUser).length) {
      if (loggedInUser?.type == "customer") {
        getProfileDataRequest = `customerUser/${loggedInUser?.userId}`;
        companyProfileUrl = `${baseUrl}/customer/${userAdditionalInfo?.customerId}/logo?id=${userAdditionalInfo?.customerPictureVersion}`;
      } else if (loggedInUser?.type == "edge") {
        getProfileDataRequest = `user/${loggedInUser?.userId}`;
        companyProfileUrl = `${baseUrl}/edge/logo}`;
      }
    }
    dispatch(
      GetCustomerUserandCompanyProfileData({
        request: getProfileDataRequest,
      })
    );
    setCompanyProfileUrl(companyProfileUrl);
  }, []);

  return (
    <Grid container direction="row" columnSpacing={3} alignItems="flex-start" justifyContent="flex-start">
      <Grid item xl={8} lg={8} md={8} sm={8} xs={12}>
        <Paper
          sx={{
            p: 3,
            pl: 4,
            height: "60vh",
            overflowX: "hidden",
            overflowY: "auto",
          }}
        >
          <Grid item container direction="row" justifyContent="space-between">
            <Grid item>
              <Button
                variant="text"
                color="secondary"
                disabled
                startIcon={<HiOutlineOfficeBuilding color="#000000" />}
                onClick={() => navigate("/main/dashboard")}
                sx={{ pl: "0px !important" }}
              >
                <Typography variant="h1" fontSize="16px">
                  Company Information
                </Typography>
              </Button>
            </Grid>
            <Grid item>
              {JSON.parse(localStorage.getItem("loggedInUser"))?.permissions?.includes("CustomerAdmin") ? (
                <Button
                  size="small"
                  variant="contained"
                  disableElevation
                  color="primaryAlt"
                  endIcon={<AiOutlineEdit />}
                  sx={{
                    borderRadius: "5px",
                    color: "#FFFFFF",
                    backgroundColor: "#3D43BB",
                  }}
                  onClick={() => {
                    handleEditChange(settingPages.EDIT_COMPANY_PROFILE);
                  }}
                >
                  <Typography variant="h5" sx={{ color: "#FFFFFF" }}>
                    Edit
                  </Typography>
                </Button>
              ) : null}
            </Grid>
          </Grid>

          <Grid
            item
            container
            direction="column"
            // justifyContent="flex-end"
            alignItems="flex-start"
            rowSpacing={1.5}
            sx={{ pl: 3, pt: 3 }}
            xs={12}
          >
            <Grid item container>
              <Typography variant="h6" color="#8386A2" fontSize="13px" width="40%">
                Company Name
              </Typography>
              <Typography variant="h5" width="60%">
                {getValue(customerUserandCompanyProfileData?.payload?.customer?.companyName)}
              </Typography>
            </Grid>
            <Grid item container>
              <Typography variant="h6" color="#8386A2" fontSize="13px" width="40%">
                Email Address
              </Typography>
              <Typography variant="h5" width="60%">
                {getValue(customerUserandCompanyProfileData?.payload?.customer?.contactEmail)}
              </Typography>
            </Grid>
            <Grid item container>
              <Typography variant="h6" color="#8386A2" fontSize="13px" width="40%">
                Industry
              </Typography>
              <Typography variant="h5" width="60%">
                {getValue(customerUserandCompanyProfileData?.payload?.customer?.industry)}
              </Typography>
            </Grid>
            <Grid item container>
              <Typography variant="h6" color="#8386A2" fontSize="13px" width="40%">
                Contact Person
              </Typography>
              <Typography variant="h5" width="60%">
                {getValue(customerUserandCompanyProfileData?.payload?.customer?.contactPerson)}
              </Typography>
            </Grid>
            <Grid item container>
              <Typography variant="h6" color="#8386A2" fontSize="13px" width="40%">
                Contact Number
              </Typography>
              <Typography variant="h5" width="60%">
                {getValue(customerUserandCompanyProfileData?.payload?.customer?.contactNumber)}
              </Typography>
            </Grid>
            <Grid item container>
              <Typography variant="h6" color="#8386A2" fontSize="13px" width="40%">
                Country
              </Typography>
              <Typography variant="h5" width="60%">
                {getValue(customerUserandCompanyProfileData?.payload?.customer?.country)}
              </Typography>
            </Grid>
            <Grid item container>
              <Typography variant="h6" color="#8386A2" fontSize="13px" width="40%">
                State
              </Typography>
              <Typography variant="h5" width="60%">
                {getValue(
                  customerUserandCompanyProfileData?.payload?.customer?.state
                    ? customerUserandCompanyProfileData?.payload?.customer?.state
                    : "-"
                )}
              </Typography>
            </Grid>
            <Grid item container>
              <Typography variant="h6" color="#8386A2" fontSize="13px" width="40%">
                Address
              </Typography>
              <Typography variant="h5" width="60%">
                {getValue(
                  customerUserandCompanyProfileData?.payload?.customer?.addressLine1
                    ? customerUserandCompanyProfileData?.payload?.customer?.addressLine1
                    : "-"
                )}
              </Typography>
            </Grid>
            <Grid item container>
              <Typography variant="h6" color="#8386A2" fontSize="13px" width="40%">
                Code
              </Typography>
              <Typography variant="h5" width="60%">
                {getValue(
                  customerUserandCompanyProfileData?.payload?.customer?.zip
                    ? customerUserandCompanyProfileData?.payload?.customer?.zip
                    : "-"
                )}
              </Typography>
            </Grid>
            <Grid item container>
              <Typography variant="h6" color="#8386A2" fontSize="13px" width="40%">
                City
              </Typography>
              <Typography variant="h5" width="60%">
                {getValue(
                  customerUserandCompanyProfileData?.payload?.customer?.city
                    ? customerUserandCompanyProfileData?.payload?.customer?.city
                    : "-"
                )}
              </Typography>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
      <Grid item xl={4} lg={4} md={4} sm={4} xs={12}>
        <Paper
          sx={{
            p: 3,
            pt: 5,
            height: "60vh",
            overflowX: "hidden",
            overflowY: "auto",
          }}
        >
          <Grid item container direction="column" alignItems="center" rowSpacing={2}>
            <Grid item pb={2}>
              <Badge
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "right",
                }}
                variant="standard"
                badgeContent={<EditOutlinedIcon color="primary" fontSize="large" />}
                // color="secondary"
                overlap="circular"
                sx={{
                  "& .MuiBadge-badge": {
                    backgroundColor: "#F7F7F7",
                    minWidth: "45px",
                    height: "45px",
                    borderRadius: "25px",
                    boxShadow: "0px 4px 10px 0px #080D461F",
                  },
                }}
                onClick={() => {
                  uploadProfilePic();
                }}
              >
                <input
                  type="file"
                  accept="image/*"
                  onChange={handleImageChange}
                  ref={photoInput}
                  style={{ display: "none" }}
                />
                <AvatarIcon
                  shape={"rounded"}
                  size={{
                    width: "25vh",
                    height: "25vh",
                    borderRadius: "50%",
                  }}
                  source={companyProfileUrl}
                />
              </Badge>
            </Grid>
            <Grid item>
              <Typography variant="h5" fontSize="17px">
                {getValue(customerUserandCompanyProfileData?.payload?.customer?.companyName)}
              </Typography>
            </Grid>
            <Grid item>
              <Typography
                variant="h3"
                fontSize="15px"
                lineHeight="20px"
                sx={{
                  lineHeight: "4px",
                }}
              >
                {getValue(customerUserandCompanyProfileData?.payload?.customer?.industry)}
              </Typography>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  );
}

export default CompanyProfilee;
