import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import moment from "moment";

import PageLoader from "components/common/PageLoader";
import LeaveItem from "components/common/LeaveItem";
import { getCustomerEmployeeLeaves } from "services/useTimeoffService";

import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";

function EmploymentInfo() {
  const [upcomingLeaves, setUpcomingLeaves] = useState([]);

  const loadingGetEmployeeDetails = useSelector((state) => state.GetEmployeeDetails.loadingGET_EMPLOYEE_Details);
  const loadingUnAssignEmployee = useSelector((state) => state.UnAssignEmployee.loadingUnAssignInfo);

  const fetchUpcomingLeavesForEmployee = async () => {
    let res = await getCustomerEmployeeLeaves(window.location.pathname?.split("/")?.[3]);
    res = res?.payload?.data?.slice(0, 2);
    setUpcomingLeaves(res);
  };

  useEffect(() => {
    fetchUpcomingLeavesForEmployee();
  }, []);

  if (upcomingLeaves?.length)
    return (
      <>
        {(loadingGetEmployeeDetails || loadingUnAssignEmployee) && <PageLoader />}
        <div className="mt-15">
          <p className="heading-h4-semibold mb-1">Upcoming Leaves</p>
          <div
            className={`flex items-center justify-between space-x-2 ${
              upcomingLeaves.length === 1 ? "w-50p pr-05" : "w-full"
            }`}
          >
            {upcomingLeaves?.map((leave) => (
              <LeaveItem
                key={leave?.id}
                icon={<CalendarMonthOutlinedIcon className="color-icon-information" />}
                type={leave?.leaveType}
                noOfLeaves={leave?.noOfLeaves}
                dateFrom={moment(leave?.dateFrom).format("MMM DD, YYYY")}
                dateTo={moment(leave?.dateTo).format("MMM DD, YYYY")}
              />
            ))}
          </div>
        </div>
      </>
    );
}

export default EmploymentInfo;
