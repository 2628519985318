import React from "react";

import SearchIcon from "assets/images/search.svg";

const NoDataForTimeoffReports = () => {
  return (
    <div className="no-data-reports-container">
      <img src={SearchIcon} alt="search photo" />

      <div className="no-data-reports-heading mt-1">No Data Found</div>
      <div className="no-data-reports-subheading">Try adjusting filters to find what you’re looking for.</div>
    </div>
  );
};

export default NoDataForTimeoffReports;
