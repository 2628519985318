import {
  RESET_CALCULATE_EMPLOYEE_SALARY,
  CALCULATE_EMPLOYEE_SALARY,
  CALCULATE_EMPLOYEE_SALARY_SUCCESS,
  CALCULATE_EMPLOYEE_SALARY_FAILURE,
} from "../../../constants/Workforce/AddEmployee/CalculateEmployeeSalary";

// for user authentication
export const ResetCalculateEmployeeSalary = (data) => {
  return {
    type: RESET_CALCULATE_EMPLOYEE_SALARY,
    payload: data,
  };
};

export const CalculateEmployeeSalary = (data) => {
  return {
    type: CALCULATE_EMPLOYEE_SALARY,
    payload: data,
  };
};

export const CalculateEmployeeSalarySuccess = (payload) => {
  return {
    type: CALCULATE_EMPLOYEE_SALARY_SUCCESS,
    payload,
  };
};

export const CalculateEmployeeSalaryFailure = (payload) => {
  return {
    type: CALCULATE_EMPLOYEE_SALARY_FAILURE,
    payload,
  };
};
