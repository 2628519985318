export const leavesTypesDropdown = [
  {
    label: "Annual",
    key: 1,
  },
  {
    label: "Sick",
    key: 2,
  },
  {
    label: "Paternity",
    key: 4,
  },
  {
    label: "Unpaid",
    key: 5,
  },
  {
    label: "Maternity",
    key: 3,
  },
];

export const countryDropdown = [
  { label: "Peru", key: "Peru" },
  { label: "Pakistan", key: "Pakistan" },
];

export const countryDropdownPeruDisabledForTimeoff = [
  { label: "Peru", key: "Peru", disabled: true, disabledDesc: "Data not available" },
  { label: "Pakistan", key: "Pakistan" },
];

export const yearDropdown = [
  { label: "This Week", key: "thisWeek" },
  { label: "This Year", key: "thisYear" },
  { label: "This Month", key: "thisMonth" },
  { label: "Last 6 Months", key: "last6Months" },
];

export const timeOffFlagDropdown = [
  { label: "Short Notice", key: "shortNotice" },
  { label: "Overlaps", key: "overlaps" },
];

export const sortByDropdown = [
  { label: "Last Activity", key: "updatedAtDESC", direction: "DESC", fieldName: "updatedAt" },
  { label: "Leaves Dates", key: "DateFormDESC", direction: "ASC", fieldName: "Date_From" },
];

export const sortByDropdownKeys = {
  updatedAtAsc: "updatedAtAsc",
  updatedAtDESC: "updatedAtDESC",
  DateFormAsc: "DateFormAsc",
  DateFormDESC: "DateFormDESC",
};
