import React from "react";
//material ui
import { Grid, Typography, Button, Box, IconButton } from "@mui/material";
//widget
import AvatarIcons from "../../../Icons/AvatarIcon";
//icons
import totalEmployeesIcon from "../../../Icons/TotalEmployeesIcon.png";
import EditRoundedIcon from "@mui/icons-material/EditRounded";

//router
import { useNavigate, useLocation } from "react-router-dom";

//redux dispatch
import { useSelector, useDispatch } from "react-redux";
import { ManageUser } from "../../../../store/actions/Settings/ManageUser";

//enum
import { DeleteUser, ResetDeleteUser } from "../../../../store/actions/Settings/DeleteUser";
import { baseUrl } from "../../../../util/APIBaseUrl";
//
import DeleteUsers from "./../../../Widgets/Delete";
import EditPermissions from "../../../Widgets/EditPermissions";
import { AddPermissions, ResetAddPermissions } from "../../../../store/actions/Permissions/AddPermissions";
import { RemovePermissions, ResetRemovePermissions } from "../../../../store/actions/Permissions/RemovePermissions";
import DataTable from "../../../Widgets/DataTable";
import { GetUserDetails } from "../../../../store/actions/Settings/GetUserDetails";
import { checkUserAdditionalInfo, getValue } from "../../CommonUtils";
import { usePermissions } from "../../../Hook";
import PageLoader from "../../../common/PageLoader";

//constants

const tableHeader = [
  {
    id: "Profile_Picture",
    sort: false,
    numeric: true,
    disablePadding: true,
    label: "",
    align: "right",
    width: "20px,",
  },
  {
    id: "First_Name",
    label: "User Name",
    align: "left",
    width: "220px",
    wrapCell: true,
    mobileView: true,
  },
  {
    id: "Email",
    label: "Email",
    width: "270px",
    wrapCell: true,
    align: "left",
    mobileView: false,
  },

  {
    id: "Department",
    label: "Department",
    align: "left",
    mobileView: false,
  },
  {
    id: "permissions",
    label: "Permissions",
    align: "left",
    mobileView: false,
    sort: false,
  },
  {
    id: "action",
    label: "Action",
    sort: false,
    align: "left",
    mobileView: false,
  },
  {
    id: "editPermissions",
    label: "Edit Permissions",
    disablePadding: true,
    sort: false,
    align: "left",
    mobileView: false,
  },

  {
    id: "editUser",
    label: "Edit User",
    disablePadding: true,
    sort: false,
    align: "left",
    mobileView: false,
  },
];

const customerTableHeader = [
  {
    id: "Profile_Picture",
    sort: false,
    numeric: true,
    disablePadding: true,
    label: "",
    align: "right",
    width: "20px,",
  },
  {
    id: "First_Name",
    label: "User Name",
    align: "left",
    width: "220px",
    mobileView: true,
  },
  {
    id: "Email",
    label: "Email",
    align: "left",
    mobileView: false,
  },
];

function MangeUsers(props) {
  //constants
  const navigate = useNavigate();

  //constants
  const dispatch = useDispatch();

  //component state
  const [selectedRowID, setSelectedRowID] = React.useState(null);
  const [navigateToPage, setNavigateToPage] = React.useState(null);
  const [tableRows, setTableRows] = React.useState([]);
  const [buttonText, setButtonText] = React.useState("");
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [userId, setUserId] = React.useState(null);
  const [page, setPage] = React.useState(0);
  const [sortBy, setSortBy] = React.useState({});
  const [permission, setPermission] = React.useState(null);

  // custom hook
  const { loggedInUser } = usePermissions();

  //redux state
  const manageUser = useSelector((state) => state.ManageUser.manageUser);
  const loadingManageUser = useSelector((state) => state.ManageUser.loadingManageUser);

  const deleteUser = useSelector((state) => state.DeleteUser.deleteUser);
  const loadingDeleteUser = useSelector((state) => state.DeleteUser.loadingDeleteUser);

  const getUserDetails = useSelector((state) => state.GetUserDetails.getUserDetails);
  const loadingGetUserDetails = useSelector((state) => state.GetUserDetails.loadingGetUserDetails);

  const userAdditionalInfo = useSelector((state) => state.UserAdditionalInfo.userAdditionalInfo);
  const loadingAdditionalInfo = useSelector((state) => state.UserAdditionalInfo.loadingAdditionalInfo);

  const addPermissions = useSelector((state) => state.AddPermissions.addPermissions);
  const loadingAddPermissions = useSelector((state) => state.AddPermissions.loadingAddPermissions);

  const removePermissions = useSelector((state) => state.RemovePermissions.removePermissions);

  const loadingRemovePermission = useSelector((state) => state.RemovePermissions.loadingRemovePermission);

  //functions

  const handleDeleteRow = (userId) => {
    let deleteUserRequest = "";

    if (userId != undefined && userId != null) {
      if (loggedInUser?.type == "edge") {
        deleteUserRequest = `user/${userId}`;
      } else if (loggedInUser?.type == "customer") {
        deleteUserRequest = `customerUser/${userId}`;
      }
      dispatch(
        DeleteUser({
          request: deleteUserRequest,
        })
      );
    }
  };

  const handleEditPermissions = (userId, permissionNames, action) => {
    let editPermissionsRequest = "";
    let permissions = [];

    if (userId != undefined && userId != null && Object.keys(permissionNames).length && permissionNames.key != null) {
      if (loggedInUser?.type == "edge") {
        if (action == "addPermission") {
          editPermissionsRequest = `user/${userId}/addUserPermission`;
        } else if (action == "removePermission") {
          editPermissionsRequest = `user/${userId}/removeUserPermission`;
        }
      } else if (loggedInUser?.type == "customer") {
        if (action == "addPermission") {
          editPermissionsRequest = `customerUser/${userId}/addUserPermission`;
        } else if (action == "removePermission") {
          editPermissionsRequest = `customerUser/${userId}/removeUserPermission`;
        }
      }

      dispatch(
        RemovePermissions({
          request: editPermissionsRequest,
          permissionName: [permissionNames?.key],
        })
      );
    }
  };

  //table pagination
  const handleRowsPerPage = (pagination) => {
    let manageUserRequest = "";

    setRowsPerPage(pagination.rowsPerPage);
    setPage(pagination.page);

    if (
      Object.keys(loggedInUser).length &&
      loggedInUser.userId != undefined &&
      checkUserAdditionalInfo(userAdditionalInfo)
    ) {
      if (loggedInUser?.type == "customer") {
        manageUserRequest = `customer/${userAdditionalInfo?.customerId}/customerUsers`;
      } else if (loggedInUser?.type == "edge") {
        manageUserRequest = `users`;
      }
    }

    // if (sortBy?.orderBy != undefined && sortBy?.order != undefined) {
    dispatch(
      ManageUser({
        request: manageUserRequest,
        rowsPerPage: pagination.rowsPerPage,
        page: pagination.page,
        sortBy: {
          field: sortBy?.orderBy,
          direction: sortBy?.order,
        },
      })
    );
    // }
    //  else {
    //   dispatch(
    //     ManageUser({
    //       request: manageUserRequest,
    //       rowsPerPage: pagination.rowsPerPage,
    //       page: pagination.page,
    //       sortBy: null,
    //     })
    //   );
    // }
  };

  const handleSelectedRow = (selectedUserID, navigateTo) => {
    let userDetailsReuqest = "";

    setUserId(selectedUserID);
    if (loggedInUser?.type == "customer") {
      userDetailsReuqest = `customerUser/${selectedUserID}`;
    } else if (loggedInUser?.type == "edge") {
      userDetailsReuqest = `user/${selectedUserID}`;
    }
    dispatch(
      GetUserDetails({
        request: userDetailsReuqest,
      })
    );
    setSelectedRowID(selectedUserID);
    setNavigateToPage(navigateTo);
  };

  const handleRequestSortBy = (request) => {
    let manageUserRequest = "";

    setSortBy(request);
    if (
      Object.keys(loggedInUser).length &&
      loggedInUser.userId != undefined &&
      checkUserAdditionalInfo(userAdditionalInfo)
    ) {
      if (loggedInUser?.type == "customer") {
        manageUserRequest = `customer/${userAdditionalInfo?.customerId}/customerUsers`;
        setButtonText("Add User");
      } else if (loggedInUser?.type == "edge") {
        setButtonText("Add Edge Employee");
        manageUserRequest = `users`;
      }

      dispatch(
        ManageUser({
          request: manageUserRequest,
          rowsPerPage: rowsPerPage,
          page: page,
          sortBy: {
            field: sortBy.orderBy,
            direction: sortBy.order,
          },
        })
      );
    }
  };

  //methods

  React.useEffect(() => {
    if (getUserDetails.ok) {
      if (navigateToPage != null) {
        if (selectedRowID != null && navigateToPage == "details") {
          navigate(`/main/user/details/${selectedRowID}`);
        } else if (navigateToPage == "editUser") {
          navigate(`/main/adduser`, {
            state: {
              navigatedFor: "editUser",
              paramId: selectedRowID,
            },
          });
        }
      }
    }
  }, [getUserDetails]);

  React.useEffect(() => {
    let manageUserRequest = "";

    if (Object.keys(deleteUser).length && deleteUser?.ok && deleteUser?.payload) {
      if (
        Object.keys(loggedInUser).length &&
        loggedInUser.userId != undefined &&
        checkUserAdditionalInfo(userAdditionalInfo)
      ) {
        if (loggedInUser?.type == "customer") {
          setButtonText("Add User");
          manageUserRequest = `customer/${userAdditionalInfo?.customerId}/customerUsers`;
        } else if (loggedInUser?.type == "edge") {
          setButtonText("Add Edge Employee");
          manageUserRequest = `users`;
        }

        dispatch(
          ManageUser({
            request: manageUserRequest,
            rowsPerPage: rowsPerPage,
            page: page,
            sortBy: {
              field: sortBy.orderBy,
              direction: sortBy.order,
            },
          })
        );
      }
      dispatch(ResetDeleteUser({}));
    }
  }, [deleteUser]);

  React.useEffect(() => {
    let manageUserRequest = "";

    if (
      Object.keys(addPermissions).length &&
      addPermissions?.ok &&
      addPermissions?.payload &&
      checkUserAdditionalInfo(userAdditionalInfo)
    ) {
      if (Object.keys(loggedInUser).length && loggedInUser.userId != undefined) {
        if (loggedInUser?.type == "customer") {
          manageUserRequest = `customer/${userAdditionalInfo?.customerId}/customerUsers`;
        } else if (loggedInUser?.type == "edge") {
          manageUserRequest = `users`;
        }

        dispatch(
          ManageUser({
            request: manageUserRequest,
            rowsPerPage: rowsPerPage,
            page: page,
            sortBy: {},
          })
        );

        // dispatch(
        //     ManageUser({
        //         request: manageUserRequest,
        //     })
        // );
      }
      dispatch(ResetAddPermissions({}));
    }
  }, [addPermissions]);

  React.useEffect(() => {
    let manageUserRequest = "";

    if (Object.keys(removePermissions).length && removePermissions?.ok && removePermissions?.payload) {
      if (
        Object.keys(loggedInUser).length &&
        loggedInUser.userId != undefined &&
        checkUserAdditionalInfo(userAdditionalInfo)
      ) {
        if (loggedInUser?.type == "customer") {
          manageUserRequest = `customer/${userAdditionalInfo?.customerId}/customerUsers`;
        } else if (loggedInUser?.type == "edge") {
          manageUserRequest = `users`;
        }

        dispatch(
          ManageUser({
            request: manageUserRequest,
            rowsPerPage: rowsPerPage,
            page: page,
            sortBy: {},
          })
        );
      }
      dispatch(ResetRemovePermissions({}));
    }
  }, [removePermissions]);

  React.useEffect(() => {
    let Data = [];
    let userProfileUrl = null;

    if (manageUser != null && manageUser != undefined && Object.keys(manageUser).length && manageUser?.ok) {
      manageUser?.payload?.rows?.map((user, index) => {
        if (Object.keys(loggedInUser).length && loggedInUser.userId != undefined) {
          if (loggedInUser?.type == "customer") {
            userProfileUrl = `${baseUrl}/customerUser/${user?.userId}/logo?id=${user?.pictureVersion}&variant=thumbnail`;
          } else if (loggedInUser?.type == "edge") {
            userProfileUrl = `${baseUrl}/user/${user?.userId}/logo?id=${user?.pictureVersion}&variant=thumbnail`;
          }
        }
        if (!user?.mainUser) {
          Data.push({
            id: user?.userId,
            Profile_Picture: (
              <Box display="flex" alignItems="flex-end" justifyContent="flex-end" ml={0} pl={0}>
                <AvatarIcons
                  shape={"rounded"}
                  size={{
                    width: "8vh",
                    height: "8vh",
                    borderRadius: "50%",
                  }}
                  source={userProfileUrl}
                />
              </Box>
            ),
            First_Name: (
              <Typography variant="h3">{getValue(user?.firstName) + " " + getValue(user?.lastName)}</Typography>
            ),

            Email: (
              <Typography variant="h3" pb="4px">
                {getValue(user?.email)}
              </Typography>
            ),

            Department: (
              <Typography variant="h3" pb="4px">
                {getValue(user?.departmentName)}
              </Typography>
            ),

            permissions: user?.permissions?.map((permission, index) => {
              return (
                <Typography variant="h3" pb="4px">
                  {permission == "EdgeAdmin" || permission == "CustomerAdmin"
                    ? "Admin"
                    : permission == "EdgeEmployee" || permission == "CustomerEmployee"
                    ? "Employee"
                    : permission}
                </Typography>
              );
            }),
            action: <DeleteUsers selectedId={user?.userId} handleDeleteRow={handleDeleteRow} title={"User"} />,
            editPermissions: (
              <>
                <EditPermissions
                  selectedId={user?.userId}
                  handleEditPermissions={handleEditPermissions}
                  title={"User"}
                  mode="add"
                  defaultPermissions={user?.permissions?.length ? user?.permissions : []}
                />
                <EditPermissions
                  selectedId={user?.userId}
                  handleEditPermissions={handleEditPermissions}
                  title={"User"}
                  mode="remove"
                  defaultPermissions={user?.permissions?.length ? user?.permissions : []}
                />
              </>
            ),
            editUser: (
              <IconButton
                size="small"
                sx={{ "&:hover": { backgroundColor: "white" } }}
                onClick={() => handleSelectedRow(user?.userId, "editUser")}
              >
                <EditRoundedIcon fontSize="small" color="muted" sx={{ "&:hover": { color: "#1161D5" } }} />
              </IconButton>
            ),
          });
        }
      });
    }
    setTableRows(Data);
  }, [manageUser]);

  React.useEffect(() => {
    let manageUserRequest = "";
    let userPermission = "";

    dispatch(ResetDeleteUser({}));

    if (Object.keys(loggedInUser).length && loggedInUser.userId != undefined) {
      if (loggedInUser?.type == "customer") {
        manageUserRequest = `customer/${userAdditionalInfo?.customerId}/customerUsers`;
        setButtonText("Add User");
        userPermission = loggedInUser?.permissions.includes("CustomerAdmin");
      } else if (loggedInUser?.type == "edge") {
        manageUserRequest = `users`;
        setButtonText("Add Edge Employee");
        userPermission = loggedInUser?.permissions.includes("EdgeAdmin");
      }
      dispatch(
        ManageUser({
          request: manageUserRequest,
          rowsPerPage: rowsPerPage,
          page: page,
          sortBy: sortBy,
        })
      );

      setPermission(userPermission);
    }
  }, []);

  return (
    <>
      {loadingAddPermissions == true ||
      loadingDeleteUser == true ||
      loadingAdditionalInfo == true ||
      loadingGetUserDetails == true ||
      loadingRemovePermission == true ||
      loadingManageUser == true ? (
        <PageLoader />
      ) : (
        <></>
      )}
      <Grid
        item
        container
        direction="row"
        justifyContent="space-between"
        alignItems="flex-end"
        columnSpacing={3}
        rowSpacing={1}
      >
        <Grid item xs={12} textAlign="end"></Grid>
        <Grid item xs={12}>
          <DataTable
            rowsData={tableRows}
            totalCount={manageUser?.payload?.count ? manageUser.payload.count - 1 : 0}
            titleIcon={totalEmployeesIcon}
            tableheadCells={permission != null && permission ? tableHeader : customerTableHeader}
            title={`Account Users (${manageUser?.payload?.count ? manageUser.payload.count - 1 : 0})`}
            subTitle={null}
            toolBar={true}
            slectedRowNavigation={"/main/user/details"}
            sortByName={"First_Name"}
            openDetailsPage={"First_Name"}
            handleRowsPerPage={handleRowsPerPage}
            handleSelectedRow={handleSelectedRow}
            handleRequestSortBy={handleRequestSortBy}
            AddNewRowButton={
              <Button
                disableElevation
                variant="contained"
                id="addUser"
                fullWidth
                sx={{
                  width: "auto",
                  minWidth: "150px",
                  height: "42px",
                  borderRadius: "5px",
                }}
                onClick={() => {
                  navigate("/main/adduser", {
                    state: {
                      navigatedFor: "addUser",
                    },
                  });
                }}
              >
                <Typography variant="h6" color="white">
                  {buttonText}
                </Typography>
              </Button>
            }
          />
        </Grid>
      </Grid>
    </>
  );
}

export default MangeUsers;
