import React from "react";

const MoonIcon = () => {
  return (
    <svg
      width="46"
      height="46"
      viewBox="0 0 46 46"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M42.9588 26.6494C42.8358 26.5357 42.6842 26.4575 42.5201 26.4234C42.356 26.3894 42.1858 26.4007 42.0278 26.4562C39.9994 27.167 37.8652 27.5288 35.716 27.5263C30.5942 27.4931 25.6948 25.4296 22.0924 21.7887C18.49 18.1476 16.479 13.2265 16.5005 8.10464C16.5018 6.37976 16.7266 4.66234 17.1692 2.99522C17.2117 2.83351 17.2095 2.66326 17.1627 2.50273C17.1159 2.3422 17.0262 2.19745 16.9033 2.08404C16.7805 1.97062 16.629 1.89282 16.4652 1.85897C16.3014 1.82513 16.1316 1.83653 15.9738 1.89196C12.8453 3.01687 10.0226 4.85647 7.73023 7.26437C5.43784 9.67227 3.73914 12.582 2.76928 15.7619C1.79942 18.9419 1.58517 22.3044 2.14359 25.5817C2.70201 28.8591 4.01768 31.9608 5.9859 34.6402C6.11461 34.8151 6.301 34.9389 6.51214 34.9897L31.7374 41.0434C31.8107 41.0602 31.8859 41.0687 31.9611 41.0685C32.1067 41.0687 32.2503 41.0342 32.38 40.9679C35.0223 39.6137 37.352 37.7214 39.2192 35.4128C41.0864 33.1041 42.4498 30.4303 43.2216 27.5632C43.2651 27.4014 43.2636 27.2307 43.2173 27.0697C43.1709 26.9087 43.0816 26.7634 42.9588 26.6494Z"
        fill="#F9BD00"
      />
      <path
        d="M34.9706 38.6401C34.968 36.7551 34.2432 34.9427 32.9454 33.5756C31.6476 32.2085 29.8753 31.3905 27.993 31.29C27.4376 29.9332 26.4929 28.7713 25.2779 27.9509C24.063 27.1305 22.6323 26.6883 21.1663 26.68C19.4945 26.6895 17.8764 27.2706 16.5805 28.3268C15.2765 27.2634 13.646 26.6819 11.9634 26.68C10.4281 26.6897 8.93362 27.175 7.68549 28.0691C6.43732 28.9632 5.49697 30.2221 4.99381 31.6727C4.76237 31.7594 4.53553 31.8579 4.3142 31.9679C4.80498 32.8986 5.36377 33.7918 5.98603 34.6402C6.11474 34.8152 6.30113 34.939 6.51227 34.9898L31.7375 41.0434C31.8109 41.0603 31.886 41.0687 31.9613 41.0685C32.1069 41.0687 32.2505 41.0342 32.3801 40.968C33.2647 40.5136 34.116 39.9972 34.9277 39.4227C34.9559 39.1627 34.9702 38.9016 34.9706 38.6401Z"
        fill="#02006E"
      />
      <path
        d="M7.10254 27.6C6.90321 27.5999 6.70932 27.5351 6.55 27.4154C6.39068 27.2956 6.27453 27.1275 6.21903 26.936C5.57093 24.6818 5.3732 22.3219 5.63711 19.9913C5.90103 17.6606 6.62143 15.4048 7.7572 13.3525C7.87855 13.1443 8.07663 12.992 8.30899 12.9283C8.54143 12.8645 8.78949 12.8944 9.00011 13.0116C9.21066 13.1287 9.36692 13.3238 9.4353 13.5548C9.50367 13.7859 9.47879 14.0345 9.36588 14.2474C8.34649 16.0866 7.69998 18.1088 7.46329 20.1983C7.22667 22.2878 7.40454 24.4034 7.98679 26.4239C8.02575 26.561 8.03244 26.7052 8.0063 26.8453C7.98023 26.9854 7.92211 27.1176 7.83652 27.2314C7.75086 27.3453 7.64004 27.4378 7.51271 27.5017C7.38537 27.5657 7.245 27.5993 7.10254 27.6Z"
        fill="#FFEFD3"
      />
      <path
        d="M17.4842 33.9447C17.4598 32.5631 16.9184 31.2409 15.9667 30.239C15.0151 29.2371 13.7224 28.6285 12.344 28.5331C10.9654 28.4378 9.60131 28.8627 8.5208 29.724C7.44029 30.5853 6.72197 31.8204 6.50764 33.1855C5.13416 33.4003 3.89227 34.1254 3.03007 35.2159C2.16788 36.3063 1.74887 37.682 1.85675 39.068C1.96463 40.454 2.59146 41.7483 3.61201 42.6923C4.63256 43.6362 5.97168 44.1604 7.36188 44.16H18.4054C18.5415 44.16 18.6759 44.1298 18.799 44.0716C18.922 44.0134 19.0306 43.9285 19.117 43.8232C19.2033 43.718 19.2651 43.5948 19.2981 43.4628C19.3311 43.3307 19.3343 43.1929 19.3076 43.0594L17.4842 33.9447Z"
        fill="#3035AE"
      />
      <path
        d="M19.1172 43.8231C19.2035 43.7179 19.2654 43.5948 19.2984 43.4627C19.3314 43.3306 19.3346 43.1929 19.3077 43.0594L17.4843 33.9447C17.4691 33.1068 17.2628 32.2834 16.8814 31.5373C16.4999 30.7911 15.9532 30.1418 15.283 29.6387C14.8157 30.255 14.449 30.9415 14.1967 31.6726C12.7462 32.1752 11.487 33.1151 10.5927 34.3628C9.69826 35.6106 9.21261 37.1049 9.20251 38.64C9.20314 39.6858 9.42735 40.7193 9.86003 41.6713C10.2928 42.6234 10.924 43.4719 11.7115 44.16H18.4054C18.5416 44.1599 18.6761 44.1297 18.7992 44.0714C18.9222 44.0132 19.0308 43.9284 19.1172 43.8231Z"
        fill="#02006E"
      />
      <path
        d="M27.6082 33.1199C27.2786 33.1201 26.9497 33.1502 26.6255 33.2098C26.4267 31.9066 25.7684 30.7175 24.7693 29.8574C23.7704 28.9973 22.4966 28.5229 21.1784 28.5199C19.8602 28.517 18.5843 28.9857 17.5815 29.8414C16.5787 30.6971 15.915 31.8832 15.7105 33.1855C14.337 33.4003 13.0952 34.1254 12.2329 35.2159C11.3707 36.3064 10.9518 37.682 11.0596 39.068C11.1675 40.454 11.7943 41.7483 12.8149 42.6923C13.8354 43.6362 15.1746 44.1603 16.5647 44.16H27.6082C29.0722 44.16 30.4763 43.5784 31.5115 42.5432C32.5467 41.508 33.1283 40.104 33.1283 38.64C33.1283 37.1759 32.5467 35.7719 31.5115 34.7367C30.4763 33.7015 29.0722 33.1199 27.6082 33.1199Z"
        fill="#F1EFFF"
      />
    </svg>
  );
};

export default MoonIcon;
