//employee constants
import { jsPDF } from "jspdf";
import {
  RESER_SALARY_INFO,
  SALARY_INFO,
  SALARY_INFO_SUCCESS,
  SALARY_INFO_FAILURE,
} from "../../../constants/Workforce/EmployeeDetils/SalaryInfo";

const INIT_STATE = {
  employeeSalaryInfo: {},
  payslipTemplate: "",
  loadingEmployeeSalaryInfo: false,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case RESER_SALARY_INFO:
      return INIT_STATE;

    case SALARY_INFO:
      return {
        ...state,
        loadingEmployeeSalaryInfo: true,
      };

    case SALARY_INFO_SUCCESS:
      if (action.payload.payload.template) {
        //template version
        var doc = new jsPDF();
        doc.html(action.payload.payload.template, {
          callback: function (doc) {
            // Save the PDF
            doc.save("salary-slip.pdf");
          },
          x: 15,
          y: 15,
          windowWidth: 650, //window width in CSS pixels
        });
        return {
          ...state,
          loadingEmployeeSalaryInfo: false,
          payslipTemplate: action.payload.payload.template ? action.payload.payload.template : "",
        };
      } else {
        // standard salary fetching
        return {
          ...state,
          loadingEmployeeSalaryInfo: false,
          employeeSalaryInfo: action.payload,
        };
      }

    // eslint-disable-next-line no-fallthrough
    case SALARY_INFO_FAILURE:
      return {
        ...state,
        loadingEmployeeSalaryInfo: false,
        employeeSalaryInfo: "Error in  getting Club Data",
      };
    default:
      return state;
  }
};
