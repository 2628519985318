import { call, put, takeEvery } from "redux-saga/effects";
//actions
import {
    AddPermissionsSuccess,
    AddPermissionsFailure,
} from "../../actions/Permissions/AddPermissions";
//constant
import { ADD_PERMISSIONS } from "../../constants/Permissions/AddPermissions";
//request type
import postRequest from "../../../util/APIHelperPost";
import { baseUrl } from "../../../util/APIBaseUrl";

export const fetchAddPermissionsRequest = async (actions) => {
    // eslint-disable-next-line no-return-await
    let body = JSON.stringify({
        permission: actions?.payload?.permissionName,
    });

    return await postRequest(`${baseUrl}/${actions.payload.request}`, body)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return error;
        });
};

function* fetchAddPermissions(params) {
    try {
        let response = yield call(fetchAddPermissionsRequest, params);
        yield put(AddPermissionsSuccess(response));
    } catch (error) {
        console.error(error);
        yield put(AddPermissionsFailure(error));
    }
}

export default function* rootSaga() {
    yield takeEvery(ADD_PERMISSIONS, fetchAddPermissions);
}
