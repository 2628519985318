import postRequest from "util/APIHelperPost";
import getRequest from "util/APIHelperGet";
import { baseUrl } from "util/APIBaseUrl";
import { formatDate } from "util/utils";

export const updateEmployeeSalary = async (employeeId, data) => {
  try {
    const response = await postRequest(
      `${baseUrl}/v2/employee/${employeeId}/salary`,
      JSON.stringify({
        costToCustomer: data.costToCustomer,
        grossSalary: data.grossSalary,
        netSalary: data.netSalary,
        platformFee: data.platformFee,
        additionalDeduction: data.additionalDeduction,
        discounted: data.discounted,
        percentage: data.percentage,
        flatAmount: data.flatAmount,
        startDate: formatDate(data.startDate),
        duration: data.duration,
      }),
      true,
      "POST"
    );
    return response;
  } catch (error) {
    console.error("Failed to update employee salary:", error);
    throw error;
  }
};

export const getEmployeeSalary = async (employeeId) => {
  try {
    const response = await getRequest(`${baseUrl}/employee/${employeeId}/salary`, true);
    return response;
  } catch (error) {
    console.error("Failed to fetch employee salary:", error);
    throw error;
  }
};

export const getEmployeePaySlips = async (employeeId, year, page = 0, limit = 10) => {
  try {
    const filter = year ? JSON.stringify({ year }) : "";
    const queryParams = new URLSearchParams({
      limit: limit.toString(),
      page: page.toString(),
      filter,
    });

    const url = `${baseUrl}/v2/employee/${employeeId}/payroll?${queryParams}`;

    const response = await getRequest(url, true);
    return response;
  } catch (error) {
    console.error("Failed to fetch employee pay slips:", error);
    throw error;
  }
};
