import React from "react";
import { useNavigate } from "react-router-dom";

import { Grid, Typography } from "@mui/material";

import Button from "components/Button/Button";

import "./PageToolBarV2.css";

function ToolBar({ setCurrentTab, currentTab, tabs, styles, toolBarWrapperStyles, updateHash = true }) {
  const navigate = useNavigate();

  return (
    <Grid className="toolbar-container" sx={{ ...styles }}>
      <Grid className="toolbar-wrapper" sx={{ ...toolBarWrapperStyles }}>
        {tabs?.map(({ title, link, count }) => (
          <Button
            className="toolbar-tab"
            type="button"
            id={`tab-${title}`}
            variant={title === currentTab ? "primary" : "ghost"}
            // size="sm"
            onClick={() => {
              if (updateHash) {
                window.location.hash = title;
              }
              setCurrentTab(title);
              if (link) navigate(link);
            }}
          >
            <Typography className="toolbar-title">{`${title} (${count || 0})`}</Typography>
          </Button>
        ))}
      </Grid>
    </Grid>
  );
}

export default ToolBar;
