import React from "react";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import { LinearProgress } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { getFileSizeToDisplay } from "util/utils";

import "./drag-drop.scss";

function getFileStatus({ error, size, loading }) {
  if (error) {
    return (
      <>
        <span className="file-info-error">{error}</span>
        <span className="dot-span file-info-error"> • </span>
        <span className="file-info-error">Failed</span>
      </>
    );
  }
  return (
    <>
      <span className="file-size">{getFileSizeToDisplay(size)}</span>
      <span className="dot-span"> • </span>
      <span className="file-info-complete">{loading ? "Uploading" : "Complete"}</span>
    </>
  );
}

function SelectedFile({ uploadProgress, files, loading, showGreenTick, onFileDelete, showButtons = true }) {
  return (
    <div className="uploader-files">
      {files.map(({ id, fileName, error, size }) => (
        <div className="file-item" key={id}>
          <div className={error ? "file-icon-red" : "file-icon"}>
            <UploadFileIcon />
          </div>
          <div className="file-details">
            <div className={error ? "file-name-error" : "file-name-success"}>{error ? "Upload failed" : fileName}</div>
            <div className="file-size-status">{getFileStatus({ error, size, loading })}</div>
            {(error || loading) && (
              <LinearProgress
                classes={{
                  root: `${error && "progress-root-error"} custom-linear-progress`,
                  bar: error ? "progress-bar-error" : "progress-bar-complete",
                }}
                variant="determinate"
                value={uploadProgress}
              />
            )}
          </div>

          {showButtons && (
            <div className="file-remove">
              <DeleteIcon onClick={() => onFileDelete(id)} />
              {showGreenTick && !error && <CheckCircleIcon className="check-circle ml-075" />}
            </div>
          )}
        </div>
      ))}
    </div>
  );
}

export default SelectedFile;
