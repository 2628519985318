import React from "react";
import SignUp from "./SignUp";
import SignUpV2 from "./SignUpV2";
import AddCustomerInfo from "./AddCustomerInfo";
import AddCustomerInfoV2 from "./AddCustomerInfoV2";
import AddPaymentMethod from "../AddPaymentMethod";
import AddPaymentMethodV2 from "../AddPaymentMethodV2";
//enum
import { SignInScreenPermissions } from "../SignIn.enum";
import { useSelector } from "react-redux";

function index(props) {
  //component state
  const [screen, setScreen] = React.useState(SignInScreenPermissions.SIGN_UP);
  const userAdditionalInfo = useSelector((state) => state.UserAdditionalInfo.userAdditionalInfo);

  //functions
  const changeScreen = (screen) => {
    setScreen(screen);
  };

  return (
    <>
      {screen == SignInScreenPermissions.SIGN_UP ? (
        <SignUpV2 changeScreen={changeScreen} />
      ) : screen == SignInScreenPermissions.ADD_CUSTOMER_INFO ? (
        <AddCustomerInfoV2 changeScreen={changeScreen} navigatedFrom={"signUpScreen"} />
      ) : screen == SignInScreenPermissions.ADD_PAYMENT_METHOD && userAdditionalInfo.status === "enabled" ? (
        <AddPaymentMethodV2 changeScreen={changeScreen} navigatedFrom={"signUpScreen"} />
      ) : null}
    </>
  );
}

export default index;
