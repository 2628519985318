//employee constants
import {
  RESET_GET_ALL_HOLIDAYS,
  GET_ALL_HOLIDAYS,
  GET_ALL_HOLIDAYS_SUCCESS,
  GET_ALL_HOLIDAYS_FAILURE,
} from "../../constants/Holidays";

const INIT_STATE = {
  allHolidays: {},
  loadingHolidaysList: false,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case RESET_GET_ALL_HOLIDAYS:
      return INIT_STATE;
    case GET_ALL_HOLIDAYS:
      return {
        ...state,
        loadingHolidaysList: true,
      };
    case GET_ALL_HOLIDAYS_SUCCESS:
      if (action.payload.ok) {
        return {
          ...state,
          loadingHolidaysList: false,
          allHolidays: action?.payload?.payload,
        };
      }
    // eslint-disable-next-line no-fallthrough
    case GET_ALL_HOLIDAYS_FAILURE:
      return {
        ...state,
        loadingHolidaysList: false,
        allHolidays: "Error in  getting Club Data",
      };
    default:
      return state;
  }
};
