import React from "react";

function PakistanIcon() {
  return (
    <svg width="25" height="25" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M15.4705 7.74989C15.4705 3.48595 12.0138 0.0292969 7.74991 0.0292969C6.33366 0.0292969 5.00741 0.412431 3.86621 1.07809V14.4217C5.00741 15.0873 6.33366 15.4705 7.74991 15.4705C12.0138 15.4705 15.4705 12.0138 15.4705 7.74989Z"
        fill="#1D6B17"
      />
      <path
        d="M3.86619 1.07809V14.4217C1.57221 13.0834 0.0292969 10.5976 0.0292969 7.74989C0.0292969 4.9022 1.57221 2.41641 3.86619 1.07809ZM10.4866 6.58239L10.5891 7.5354L11.0837 6.71195L12.0247 6.911H12.0278L11.3945 6.18719L11.8739 5.35482L10.9904 5.72878L10.345 5.01704L10.4263 5.97295L9.55168 6.36501L10.4866 6.58239ZM7.81261 11.0017C7.80634 10.9988 7.80007 10.9959 7.7938 10.993C5.94833 10.1384 5.14491 7.9499 5.99924 6.10444C6.41133 5.18328 7.19328 4.47901 8.15256 4.16512C8.02831 4.26863 7.90961 4.37816 7.79669 4.49373C6.54306 5.88199 6.65236 8.02373 8.04061 9.27712C9.35963 10.4683 11.3747 10.4367 12.6561 9.20595C11.8133 11.0379 9.64625 11.8418 7.81261 11.0017Z"
        fill="white"
      />
    </svg>
  );
}

export default PakistanIcon;
