//employee constants
import {
    RESER_CURRENT_PAYROLL,
    CURRENT_PAYROLL,
    CURRENT_PAYROLL_SUCCESS,
    CURRENT_PAYROLL_FAILURE,
} from "../../constants/Payroll/CurrentPayroll";

const INIT_STATE = {
    currentPayroll: {},
    loadingCurrentPayroll: true,
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case RESER_CURRENT_PAYROLL:
            return INIT_STATE;
        case CURRENT_PAYROLL:
            return {
                ...state,
                loadingCurrentPayroll: true,
            };
        case CURRENT_PAYROLL_SUCCESS:
            return {
                ...state,
                loadingCurrentPayroll: false,
                currentPayroll: action.payload,
            };
        // eslint-disable-next-line no-fallthrough
        case CURRENT_PAYROLL_FAILURE:
            return {
                ...state,
                loadingCurrentPayroll: false,
                currentPayroll: "Error in  getting Club Data",
            };
        default:
            return state;
    }
};
