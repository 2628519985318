import {
  Alert,
  IconButton,
  Slide,
  Snackbar,
  Tooltip,
  Typography,
} from "@mui/material";
import React from "react";
//Icons
import EmailIcon from "@mui/icons-material/Email";
//redux dispatch
import { useSelector, useDispatch } from "react-redux";
import { ResetCustomerActivationEmail } from "./../../store/actions/Customers/ActivationEmail";
import { getError } from "../Screens/CommonUtils";

function TransitionUp(props) {
  return <Slide {...props} direction="left" />;
}

function SendActivationEmail(props) {
  const { selectedId, SendCustomerActivationEmail, navigateTo, status } = props;
  const dispatch = useDispatch();

  //component state
  const [open, setOpen] = React.useState({ alert: false, error: null });
  const [transition, setTransition] = React.useState("TransitionUp");

  //redux state
  const customerActivationEmail = useSelector(
    (state) => state.ActivationEmail.customerActivationEmail
  );

  //functions
  const navigateToEditPage = () => {
    if (selectedId != undefined && navigateTo != undefined)
      SendCustomerActivationEmail(selectedId, navigateTo);
  };

  const handleClose = () => {
    setOpen(false);
  };

  //methods
  React.useEffect(() => {
    if (Object.keys(customerActivationEmail).length) {
      if (customerActivationEmail?.ok) {
        setOpen({
          alert: true,
          error: "Activation Email Send Successfully to Customer !",
        });
      } else if (customerActivationEmail?.ok == false) {
        setOpen({ alert: true, error: "Something Went Wrong !" });
      }
    } else {
      setOpen({
        alert: false,
        error: null,
      });
    }
  }, [customerActivationEmail]);

  React.useEffect(() => {
    dispatch(ResetCustomerActivationEmail({}));
  }, []);

  return (
    <>
      <Tooltip title="Send Activation Email">
        <IconButton
          size="small"
          sx={{ "&:hover": { backgroundColor: "white" } }}
          onClick={navigateToEditPage}
          disabled={status !== "enabled"}
        >
          <EmailIcon
            fontSize="small"
            color="muted"
            sx={{ "&:hover": { color: "#1161D5" } }}
          />
        </IconButton>
      </Tooltip>

      <Snackbar
        open={open.alert}
        autoHideDuration={1500}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        onClose={handleClose}
        TransitionComponent={TransitionUp}
        // sx={{
        //     "&.MuiSnackbar-root": {
        //         position: "relative",
        //         bottom: 9,
        //         left: 0,
        //     },
        // }}
      >
        <Alert
          onClose={handleClose}
          severity={customerActivationEmail?.ok ? "success" : "error"}
        >
          {customerActivationEmail?.ok
            ? open.error
            : getError(customerActivationEmail?.payload?.error)}
        </Alert>
      </Snackbar>

      {/* <Snackbar
                anchorOrigin={{ vertical: "top", horizontal: "right" }}
                open={true}
                onClose={handleClose}
                message="I love snacks"
                key={"vertical"}
            /> */}
    </>
  );
}

export default SendActivationEmail;
