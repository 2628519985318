import React from "react";
import { HiOutlineOfficeBuilding } from "react-icons/hi";
import { AiOutlineEdit } from "react-icons/ai";
import { useParams, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import Header from "components/Header/Header.js";
import PageLoader from "components/common/PageLoader";
import LastActive from "components/common/LastActive";

import { Badge, Button, Grid, Paper, Typography } from "@mui/material";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";

import { baseUrl } from "util/APIBaseUrl";
import { getValue } from "components/Screens/CommonUtils";

import AvatarIcon from "components/Icons/AvatarIcon.js";

import { GetUserDetails } from "store/actions/Settings/GetUserDetails";

function index(props) {
  let photoInput = React.createRef();
  const params = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [userProfileUrl, setUserProfileUrl] = React.useState("");

  const getUserDetails = useSelector((state) => state.GetUserDetails.getUserDetails);
  const loadingGetUserDetails = useSelector((state) => state.GetUserDetails.loadingGetUserDetails);

  const { lastActive, lastActiveEmail } = getUserDetails?.payload || {};

  const postLogo = async (actions) => {
    const response = await fetch(`${baseUrl}/${actions.request}`, {
      method: "POST",
      headers: {},
      body: actions.formData,
    });
    if (!response.ok) {
      return;
    }
    window.location.reload();
    const data = await response.json();
  };

  const uploadProfilePic = () => {
    photoInput.current.click();
  };

  const handleImageChange = (e) => {
    let logoRequest = "";
    let loggedInUser = JSON.parse(localStorage.getItem("loggedInUser"));
    e.preventDefault();
    let inFile = e.target.files[0];
    var form_data = new FormData();
    form_data.append("file", inFile);
    if (loggedInUser?.type == "customer") {
      logoRequest = `customerUser/${getUserDetails?.payload?.userId}/logo`;
    } else if (loggedInUser?.type == "edge") {
      logoRequest = `user/${getUserDetails?.payload?.userId}/logo`;
    }

    postLogo({
      request: logoRequest,
      formData: form_data,
    });
  };

  //
  React.useEffect(() => {
    let userProfile = "";
    let loggedInUser = JSON.parse(localStorage.getItem("loggedInUser"));

    if (getUserDetails?.ok && Object.keys(loggedInUser).length) {
      if (loggedInUser?.type == "customer") {
        userProfile = `${baseUrl}/customerUser/${getUserDetails?.payload?.userId}/logo?id=${getUserDetails?.payload?.pictureVersion}`;
      } else if (loggedInUser?.type == "edge") {
        userProfile = `${baseUrl}/user/${getUserDetails?.payload?.userId}/logo?id=${getUserDetails?.payload?.pictureVersion}`;
      }
      setUserProfileUrl(userProfile);
    }
  }, [getUserDetails]);

  React.useEffect(() => {
    let loggedInUser = JSON.parse(localStorage.getItem("loggedInUser"));
    let userDetailsReuqest = "";

    if (!Object.keys(getUserDetails).length && params.id != undefined && params.id != null) {
      if (loggedInUser?.type == "customer") {
        userDetailsReuqest = `customerUser/${params.id}`;
      } else if (loggedInUser?.type == "edge") {
        userDetailsReuqest = `user/${params.id}`;
      }

      dispatch(
        GetUserDetails({
          request: userDetailsReuqest,
        })
      );
    }
  }, []);

  return (
    <>
      {loadingGetUserDetails && <PageLoader />}
      <Grid container direction="column" rowSpacing={3}>
        <Header
          data={{
            heading: getValue(getUserDetails?.payload?.firstName) + " " + getValue(getUserDetails?.payload?.lastName),
            subHeader: null,
            letterSpacing: "15px",
            profileWithIcon: null,
          }}
        />

        <Grid container direction="row" columnSpacing={3} alignItems="flex-start" justifyContent="flex-start">
          <Grid item xs={6}>
            <Paper
              sx={{
                p: 3,
                pl: 4,
                height: "60vh",
                overflowX: "hidden",
                overflowY: "auto",
              }}
            >
              <Grid item container direction="row" justifyContent="space-between">
                <Grid item>
                  <Button
                    variant="text"
                    color="secondary"
                    disabled
                    startIcon={<HiOutlineOfficeBuilding color="secondary" />}
                    sx={{ pl: "0px !important" }}
                  >
                    <Typography variant="h1" fontSize="16px">
                      User Information
                    </Typography>
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    size="small"
                    variant="contained"
                    disableElevation
                    color="primary1Color"
                    endIcon={<AiOutlineEdit />}
                    sx={{
                      borderRadius: "5px",
                    }}
                    onClick={() => {
                      navigate("/main/adduser", {
                        state: {
                          navigatedFor: "editUser",
                          paramId: getUserDetails?.payload?.userId,
                        },
                      });
                    }}
                  >
                    <Typography variant="h5">Edit</Typography>
                  </Button>
                </Grid>
              </Grid>

              <Grid item container direction="column" rowSpacing={1.75} xs={12} sx={{ pl: 3, pt: 3 }}>
                {getUserDetails?.payload?.customer?.companyName ? (
                  <Grid item container>
                    <Typography
                      variant="h6"
                      // color="secondary.alt"
                      fontSize="13px"
                      width="35%"
                    >
                      Company Name
                    </Typography>
                    <Typography variant="h5" width="60%">
                      {getValue(getUserDetails?.payload?.customer?.companyName)}
                    </Typography>
                  </Grid>
                ) : null}
                <Grid item container>
                  <Typography variant="h6" color="secondary.alt" fontSize="13px" width="35%">
                    Email Address
                  </Typography>
                  <Typography variant="h5" width="60%">
                    {getValue(getUserDetails?.payload?.email)}
                  </Typography>
                </Grid>
                <Grid item container>
                  <Typography variant="h6" color="secondary.alt" fontSize="13px" width="35%">
                    Phone Number
                  </Typography>
                  <Typography variant="h5" width="60%">
                    {" "}
                    {getValue(getUserDetails?.payload?.phoneNumber)}
                  </Typography>
                </Grid>

                <Grid item container>
                  <Typography variant="h6" color="secondary.alt" fontSize="13px" width="35%">
                    Country
                  </Typography>
                  <Typography variant="h5" width="60%">
                    {getValue(getUserDetails?.payload?.country)}
                  </Typography>
                </Grid>

                <Grid item container>
                  <Typography variant="h6" color="secondary.alt" fontSize="13px" width="35%">
                    Department
                  </Typography>
                  <Typography variant="h5" width="60%">
                    {getValue(getUserDetails?.payload?.departmentName)}
                  </Typography>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
          <Grid item xs={6}>
            <Paper
              sx={{
                p: 3,
                pt: 5,
                height: "60vh",
                overflowX: "hidden",
                overflowY: "auto",
              }}
            >
              <Grid item container direction="column" alignItems="center" rowSpacing={2}>
                <Grid item pb={2}>
                  <Badge
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "right",
                    }}
                    variant="standard"
                    badgeContent={<EditOutlinedIcon color="primary" fontSize="large" />}
                    overlap="circular"
                    sx={{
                      "& .MuiBadge-badge": {
                        backgroundColor: "#F7F7F7",
                        minWidth: "45px",
                        height: "45px",
                        borderRadius: "25px",
                        boxShadow: "0px 4px 10px 0px #080D461F",
                      },
                    }}
                    onClick={() => {
                      uploadProfilePic();
                    }}
                  >
                    <input
                      type="file"
                      accept="image/*"
                      onChange={handleImageChange}
                      ref={photoInput}
                      style={{ display: "none" }}
                    />
                    <AvatarIcon
                      shape={"rounded"}
                      size={{
                        width: "25vh",
                        height: "25vh",
                        borderRadius: "50%",
                      }}
                      source={userProfileUrl}
                    />
                  </Badge>
                </Grid>
                <Grid item>
                  <Typography variant="h5" fontSize="17px">
                    {getValue(getUserDetails?.payload?.firstName) + " " + getValue(getUserDetails?.payload?.lastName)}
                  </Typography>
                </Grid>
                <LastActive {...{ lastActive, lastActiveEmail }} />
              </Grid>
            </Paper>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}

export default index;
