import React from "react";
import { Modal, Box, Typography, Link } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  modalContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  modalContent: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    maxWidth: 400,
    width: "100%",
    textAlign: "center",
    width: "20em",
    borderRadius: "12px",
    paddingTop: "3rem",
    paddingBottom: "3rem",
  },
  image: {
    width: 100,
    height: 100,
    marginBottom: theme.spacing(2),
  },
  title: {
    marginBottom: "0.5rem !important",
    fontFamily: "Poppins-Medium !important",
    fontSize: "20px !important",
    fontStyle: "normal",
    fontWeight: "600 !important",
    lineHeight: "24px !important",
    letterSpacing: "0.15px !important",
    paddingLeft: "1.5rem !important",
    paddingRight: "1.5rem !important",
  },
  subtitle: {
    marginTop: "1rem !important",
    marginBottom: "2rem !important",
    fontFamily: "Poppins-Regular !important",
    fontSize: "14px !important",
    fontStyle: "normal",
    fontWeight: "400 !important",
    lineHeight: "16px !important",
    letterSpacing: "0.4px !important",
  },
}));

const GetInTouchModal = ({ open, imageSrc, title, subtitle }) => {
  const classes = useStyles();

  return (
    <Modal open={open} className={classes.modalContainer}>
      <Box className={classes.modalContent}>
        {imageSrc && (
          <img src={imageSrc} alt="Modal Icon" className={classes.image} />
        )}
        <Typography variant="h2" className={classes.title}>
          {title}
        </Typography>
        <Typography variant="h3" className={classes.subtitle}>
          {subtitle}
        </Typography>
        <Link href="/main/dashboard" color="#4950C7" underline="none">
          Go To Home
        </Link>
      </Box>
    </Modal>
  );
};

export default GetInTouchModal;
