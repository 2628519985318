import { Box, Button, Grid, Typography } from "@mui/material";
import React from 'react'
import { useNavigate } from "react-router";

function FormSubmissionMessage() {
  const navigate = useNavigate();

  const handleHome = () => {
    navigate(`/`);
  }

  return (
    <Grid
      container
      direction="row"
      justifyContent="space-evenly"
      alignItems="center"
      sx={{ backgroundColor: "#FFFFFF", overflowY: "auto" }}
    >
      <Grid item container sx={{ maxWidth: "360px" }}>
        <Grid item container direction="column" rowSpacing={4}>
          <Grid className="flex items-center justify-center" item>
            <Box
              component="img"
              sx={{
                height: "auto",
                maxWidth: "100%",
              }}
              alt="edge"
              src="assets/Images/edge-logo.svg"
            />
          </Grid>
          <Grid className="flex item-center justify-center">
            <Typography
              sx={{
                fontFamily: "Poppins !important",
                color: "#000000",
                fontSize: "28px",
                fontWeight: "600",
                lineHeight: "42px",
                maxWidth: "360px",
                textAlign: "center",
                marginTop: "24px",
              }}
            >
              Request submitted
            </Typography>
          </Grid>

          <Grid className="flex item-center justify-center">
            <Typography
              sx={{
                fontFamily: "Poppins !important",
                color: "#52537A",
                fontSize: "14px",
                fontWeight: "400",
                lineHeight: "20px",
                maxWidth: "360px",
                textAlign: "center",
                marginTop: "14px",
              }}
            >
              The leave request has been accepted and the request for replacement is submitted successfully.
            </Typography>
          </Grid>
          <Grid className="flex item-center justify-center">
            <Typography
              sx={{
                fontFamily: "Poppins !important",
                color: "#52537A",
                fontSize: "14px",
                fontWeight: "400",
                lineHeight: "20px",
                maxWidth: "360px",
                textAlign: "center",
                marginTop: "24px",
              }}
            >
              Our team will respond to your request within 1-2 business days.
            </Typography>
          </Grid>

          <Grid item textAlign="center">
            <Button
              variant="contained"
              fullWidth={false}
              onClick={handleHome}
              sx={{
                backgroundColor: "#131599",
                height: "40px",
                fontFamily: "Poppins-Regular !important",
                fontWeight: "500",
                lineHeight: "20px",
                borderRadius: "99px",
                width: "140px",
                fontSize: "16px",
                color: "white",
                boxShadow: "0px 1px 14px rgba(2, 0, 110, 0.12)",
              }}
            >
              Sign in
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default FormSubmissionMessage