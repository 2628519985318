import {
  PAYONEER_GET_EMPLOYEE_STATUS_FAILED,
  PAYONEER_SET_EMPLOYEE_STATUS,
} from "store/constants/Payoneer/PayoneerGetEmployeeStatus";

const initialState = {
  statusInfo: {
    status: null,
    error: null,
    isLoading: true,
  },
};

const statusReducer = (state = initialState, action) => {
  switch (action.type) {
    case PAYONEER_SET_EMPLOYEE_STATUS:
      return {
        ...state,
        statusInfo: {
          status: action.payload,
          error: null,
          isLoading: false,
        },
      };
    case PAYONEER_GET_EMPLOYEE_STATUS_FAILED:
      return {
        ...state,
        statusInfo: {
          status: null,
          error: action.payload,
          isLoading: false,
        },
      };
    default:
      return state;
  }
};

export default statusReducer;
