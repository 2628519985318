import {
  RESET_DELETE_CUSTOMER_BANK_INFO,
  DELETE_CUSTOMER_BANK_INFO,
  DELETE_CUSTOMER_BANK_INFO_SUCCESS,
  DELETE_CUSTOMER_BANK_INFO_FAILURE,
} from "../../constants/Payment/DeleteBankAcc";

// for user authentication
export const ResetDeleteCustomerBankInfo = (data) => {
  return {
    type: RESET_DELETE_CUSTOMER_BANK_INFO,
    payload: data,
  };
};

export const DeleteCustomerBankInfo = (data) => {
  return {
    type: DELETE_CUSTOMER_BANK_INFO,
    payload: data,
  };
};
export const DeleteCustomerBankInfoSuccess = (payload) => {
  return {
    type: DELETE_CUSTOMER_BANK_INFO_SUCCESS,
    payload,
  };
};
export const DeleteCustomerBankInfoFailure = (payload) => {
  return {
    type: DELETE_CUSTOMER_BANK_INFO_FAILURE,
    payload,
  };
};
