import React from "react";
import Skeleton from "react-loading-skeleton";
import { Card, CardContent, Grid, Typography, Chip, Divider } from "@mui/material";
import "react-loading-skeleton/dist/skeleton.css";
import "./EmployeeStubsGrid.css";

const EmployeeStubsGridSkeleton = () => {
  return (
    <>
      <Grid container className="flex mt-1 rounded-xl justify-center items-center w-full">
        <div className="peers-wrapper">
          <Card>
            <CardContent className="flex items-center justify-between neutral-60a button-md px-05">
              <div className="flex items-center">
                <Skeleton className="ml-3 header-one" width={150} />
              </div>
              <Skeleton className="pl-05" width={150} />
              <Skeleton className="mr-3" width={150} />
            </CardContent>
            {
              [1, 2, 3, 4, 5].map(_ => {
                return (
                  <div>
                    <Divider />
                    <CardContent className="flex items-center justify-between neutral-60a button-md">
                      <div className="flex items-center space-x-2">
                        <Skeleton circle width={30} height={30} />
                        <Skeleton width={150} />
                      </div>
                      <Skeleton className="pl-05" width={150} />
                      <div className="flex items-center space-x-2">
                        <Skeleton width={150} />
                        <Skeleton circle width={30} height={30} />
                      </div>
                    </CardContent>
                  </div>
                )
              })
            }
          </Card>
        </div>
      </Grid>
    </>
  );
};

export default EmployeeStubsGridSkeleton;
