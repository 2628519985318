import React from "react";

const AccountSettingsIcon = () => {
  return (
    <svg
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_3309_7221)">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M17.3946 15.7089L15.547 17.5566L13.1821 16.8978L12.5058 17.1767L11.2917 19.3082H8.67955L7.47219 17.1694L6.79656 16.8861L4.4319 17.5368L2.58514 15.69L3.24393 13.3251L2.96494 12.6487L0.833496 11.4337V8.82166L2.97314 7.61433L3.25639 6.93877L2.60575 4.57411L4.45181 2.72805L6.8167 3.38776L7.49243 3.10908L8.70469 0.975708H11.3175L12.5248 3.11535L13.2004 3.39861L15.5647 2.74807L17.4136 4.59434L16.7547 6.9596L17.0335 7.63548L19.1668 8.84775V11.4606L17.0274 12.6678L16.7439 13.3449L17.3946 15.7089ZM14.9842 13.2322L15.7107 11.497L17.5002 10.4873V9.81764L15.7114 8.80117L14.9953 7.06484L15.5467 5.08542L15.0726 4.61206L13.0872 5.15837L11.3539 4.43162L10.3443 2.64237H9.67458L8.65812 4.43117L6.92163 5.14733L4.94184 4.59505L4.46996 5.06693L5.01616 7.052L4.28941 8.78529L2.50016 9.79491V10.4653L4.28751 11.4842L5.00337 13.2199L4.45185 15.1997L4.92472 15.6726L6.90979 15.1264L8.643 15.8531L9.65259 17.6415H10.3229L11.341 15.8542L13.0769 15.1383L15.0567 15.6898L15.5303 15.2162L14.9842 13.2322ZM10.0002 13.4757C8.15921 13.4757 6.66683 11.9833 6.66683 10.1424C6.66683 8.30143 8.15921 6.80904 10.0002 6.80904C11.8411 6.80904 13.3335 8.30143 13.3335 10.1424C13.3335 11.9833 11.8411 13.4757 10.0002 13.4757ZM10.0002 11.809C10.9206 11.809 11.6668 11.0628 11.6668 10.1424C11.6668 9.2219 10.9206 8.47571 10.0002 8.47571C9.07969 8.47571 8.3335 9.2219 8.3335 10.1424C8.3335 11.0628 9.07969 11.809 10.0002 11.809Z"
          fill="#FBFAFF"
        />
      </g>
      <defs>
        <clipPath id="clip0_3309_7221">
          <rect
            width="20"
            height="20"
            fill="white"
            transform="translate(0 0.142334)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default AccountSettingsIcon;
