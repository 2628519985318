import {
  RESER_PAYROLL_PAYMENT_HISTORY,
  PAYROLL_PAYMENT_HISTORY,
  PAYROLL_PAYMENT_HISTORY_SUCCESS,
  PAYROLL_PAYMENT_HISTORY_FAILURE,
} from "../../constants/Payroll/PaymentHistory";

// for user authentication
export const ResetPayrollPaymentHistory = (data) => {
  return {
    type: RESER_PAYROLL_PAYMENT_HISTORY,
    payload: data,
  };
};

export const PayrollPaymentHistory = (data) => {
  return {
    type: PAYROLL_PAYMENT_HISTORY,
    payload: data,
  };
};
export const PayrollPaymentHistorySuccess = (payload) => {
  return {
    type: PAYROLL_PAYMENT_HISTORY_SUCCESS,
    payload,
  };
};
export const PayrollPaymentHistoryFailure = (payload) => {
  return {
    type: PAYROLL_PAYMENT_HISTORY_FAILURE,
    payload,
  };
};
