import React from "react";

function PeruIcon() {
  return (
    <svg width="25" height="25" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M14.2553 7.74989C14.2553 6.95627 14.1048 6.19998 13.8423 5.49805H1.80075C1.53825 6.19998 1.3877 6.95627 1.3877 7.74989C1.3877 8.5435 1.53825 9.29979 1.80075 10.0017H13.842C14.1048 9.29979 14.2553 8.5435 14.2553 7.74989Z"
        fill="url(#paint0_linear_1666_50729)"
      />
      <path
        d="M7.82155 14.1837C5.06176 14.1837 2.71471 12.4427 1.80078 10.0017H13.8423C12.9284 12.4427 10.5813 14.1837 7.82155 14.1837ZM7.82155 1.31604C5.06176 1.31604 2.71471 3.05703 1.80078 5.49803H13.842C12.9284 3.05703 10.5813 1.31604 7.82155 1.31604Z"
        fill="url(#paint1_linear_1666_50729)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_1666_50729"
          x1="4.4206"
          y1="2.54814"
          x2="14.5204"
          y2="17.9951"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#DFE9F2" />
          <stop offset="0.998" stopColor="#9EA7B0" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_1666_50729"
          x1="5.32555"
          y1="0.539799"
          x2="11.2337"
          y2="17.6065"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.008" stopColor="#F44F5A" />
          <stop offset="0.447" stopColor="#EE3D4A" />
          <stop offset="1" stopColor="#E52030" />
        </linearGradient>
      </defs>
    </svg>
  );
}

export default PeruIcon;
