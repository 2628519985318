import React from "react";
import { useFormContext, Controller } from "react-hook-form";
import PropTypes from "prop-types";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import Autocomplete from "@mui/material/Autocomplete";
import Paper from "@mui/material/Paper";
import CustomChip from "components/common/Chip";
import { Divider } from "@mui/material";

function CustomPaper({ children }) {
  return (
    <Paper elevation={8} className="auto-complete-paper">
      {children}
    </Paper>
  );
}

function SearchableAutoComplete({
  name,
  label,
  helperText,
  options = [],
  topChoiceOptions = [],
  isOptional,
  placeholder,
  value: propValue,
  onChange: propOnChange,
  error: propError,
  size = "small",
  variant = "outlined",
  disabled = false,
  multiple = false,
  noPaddingInList = false,
  ...other
}) {
  const formContext = useFormContext();

  const allOptions = [...topChoiceOptions, ...options];
  const uniqueOptions = Array.from(new Set(allOptions.map((option) => option.value))).map((optionValue) =>
    allOptions.find((option) => option.value === optionValue)
  );

  const renderSearchableAutoComplete = (field = {}, fieldError) => {
    const handleChange = (event, newValue) => {
      let selectedValue;
      if (multiple) {
        selectedValue = newValue.map((option) => option.value);
      } else {
        selectedValue = newValue ? newValue.value : null;
      }
      field.onChange(selectedValue);
      if (propOnChange) {
        propOnChange(selectedValue);
      }
    };

    const selectedOption = multiple
      ? [...topChoiceOptions, ...options].filter((option) => field.value && field.value.includes(option.value))
      : [...topChoiceOptions, ...options].find((option) => option.value === field.value) || null;

    const isOptionSelected = (option) => {
      if (multiple) {
        return selectedOption.some((selected) => selected.value === option.value);
      }
      return selectedOption?.value === option.value;
    };

    const renderOptionWithDivider = (props, option) => {
      const isLastTopChoice = topChoiceOptions.length > 0 && option === topChoiceOptions[topChoiceOptions.length - 1];

      const style = { backgroundColor: isOptionSelected(option) ? "var(--color-surface-primary-pressed)" : "" };
      if (noPaddingInList) {
        style.padding = 0;
      }

      return (
        <React.Fragment key={option.value}>
          <li {...props} style={style}>
            {option.render ? (
              option.render(option, isOptionSelected(option))
            ) : (
              <span className="menu-item">{option.label}</span>
            )}
          </li>
          {isLastTopChoice && options.length > 0 && <Divider className="select-divider" />}
        </React.Fragment>
      );
    };

    return (
      <FormControl fullWidth data-testid="form-control">
        <InputLabel
          shrink
          htmlFor={name}
          className="paragraph text-left text-md font-medium line-height-lg mb-025 pos-relative non-transform"
        >
          {`${label} ${isOptional ? "(optional)" : ""}`}
        </InputLabel>
        <Autocomplete
          id={name}
          options={uniqueOptions}
          disabled={disabled}
          value={selectedOption}
          getOptionDisabled={(option) => option.disabled}
          onChange={handleChange}
          isOptionEqualToValue={(option, value) => option.value === value.value}
          getOptionLabel={(option) => option.label || ""}
          ListboxProps={{
            onScroll: () => {
              if (!multiple) {
                const selectedElement = document.querySelector(".MuiAutocomplete-option[aria-selected='true']");
                if (selectedElement) {
                  selectedElement.scrollIntoView({
                    block: "nearest",
                  });
                }
              }
            },
          }}
          multiple={multiple}
          renderTags={(tagValue, getTagProps) =>
            tagValue.map((option, index) => (
              <CustomChip label={option.label} showDeleteIcon {...getTagProps({ index })} key={option.value} />
            ))
          }
          renderInput={(params) => (
            <div className="ant-text-component">
              <TextField
                {...params}
                error={!!fieldError}
                helperText={fieldError ? fieldError.message : helperText}
                placeholder={selectedOption?.length > 0 ? "" : placeholder}
                size={size}
                variant={variant}
                disabled={disabled}
                InputProps={{
                  ...params.InputProps,
                  classes: {
                    root: `custom-text-input-root text-left text-field-padding text-field-input-${size} ${
                      disabled ? "disabled-input" : ""
                    }`,
                    input: "paragraph font-regular text-lg line-height-xl flex mr-15",
                    focused: "custom-text-input-focused",
                    error: "custom-text-input-error",
                  },
                }}
                InputLabelProps={{
                  shrink: true,
                }}
                FormHelperTextProps={{
                  classes: {
                    root: "paragraph text-sm font-regular line-height-sm error-text-color ml-0",
                  },
                }}
              />
            </div>
          )}
          PaperComponent={CustomPaper}
          renderOption={renderOptionWithDivider}
          {...other}
        />
      </FormControl>
    );
  };

  return formContext && !propValue && !propOnChange ? (
    <Controller
      name={name}
      control={formContext.control}
      defaultValue={null}
      render={({ field, fieldState: { error } }) => renderSearchableAutoComplete(field, error)}
    />
  ) : (
    renderSearchableAutoComplete(
      { value: propValue, onChange: propOnChange },
      propError ? { message: helperText } : null
    )
  );
}

SearchableAutoComplete.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  helperText: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      label: PropTypes.string.isRequired,
      render: PropTypes.func,
    })
  ).isRequired,
  topChoiceOptions: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      label: PropTypes.string.isRequired,
      render: PropTypes.func,
    })
  ),
  placeholder: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func,
  error: PropTypes.bool,
  size: PropTypes.oneOf(["small", "medium"]),
  variant: PropTypes.oneOf(["standard", "outlined", "filled"]),
  disabled: PropTypes.bool,
  isOptional: PropTypes.bool,
  multiple: PropTypes.bool,
  noPaddingInList: PropTypes.bool,
};

SearchableAutoComplete.defaultProps = {
  helperText: "",
  topChoiceOptions: [],
  placeholder: "Search and select",
  value: null,
  onChange: undefined,
  error: false,
  size: "small",
  variant: "outlined",
  disabled: false,
  isOptional: false,
  multiple: false,
  noPaddingInList: false,
};

export default SearchableAutoComplete;
