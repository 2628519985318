//employee constants
import {
  RESET_REMOVE_PERMISSIONS,
  REMOVE_PERMISSIONS,
  REMOVE_PERMISSIONS_SUCCESS,
  REMOVE_PERMISSIONS_FAILURE,
} from "../../constants/Permissions/RemovePermissions";

const INIT_STATE = {
  removePermissions: {},
  loadingRemovePermissions: false,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case RESET_REMOVE_PERMISSIONS:
      return INIT_STATE;

    case REMOVE_PERMISSIONS:
      return {
        ...state,
        loadingRemovePermissions: true,
      };
    case REMOVE_PERMISSIONS_SUCCESS:
      return {
        ...state,
        loadingRemovePermissions: false,
        removePermissions: action.payload,
      };
    // eslint-disable-next-line no-fallthrough
    case REMOVE_PERMISSIONS_FAILURE:
      return {
        ...state,
        loadingRemovePermissions: false,
        removePermissions: "Error in  getting Club Data",
      };
    default:
      return state;
  }
};
