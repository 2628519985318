import {
  RESET_RETRY_PAYMENT,
  RETRY_PAYMENT,
  RETRY_PAYMENT_SUCCESS,
  RETRY_PAYMENT_FAILURE,
} from "../../constants/Payment/RetryPayment";

// for user authentication
export const ResetRetryPayment = (data) => {
  return {
    type: RESET_RETRY_PAYMENT,
    payload: data,
  };
};

export const RetryPayment = (data) => {
  return {
    type: RETRY_PAYMENT,
    payload: data,
  };
};
export const RetryPaymentSuccess = (payload) => {
  return {
    type: RETRY_PAYMENT_SUCCESS,
    payload,
  };
};
export const RetryPaymentFailure = (payload) => {
  return {
    type: RETRY_PAYMENT_FAILURE,
    payload,
  };
};
