import React from "react";

const HamburgerMenuIcon = ({ onClick }) => {
  return (
    <svg
      width="21"
      height="21"
      viewBox="0 0 21 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick}
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M16.182 13.6366C16.5334 13.6366 16.8183 13.9215 16.8183 14.273C16.8183 14.6244 16.5334 14.9093 16.182 14.9093H4.72743C4.37597 14.9093 4.09106 14.6244 4.09106 14.273C4.09106 13.9215 4.37597 13.6366 4.72743 13.6366H16.182ZM16.182 9.81843C16.5334 9.81843 16.8183 10.1033 16.8183 10.4548C16.8183 10.8062 16.5334 11.0912 16.182 11.0912H4.72743C4.37597 11.0912 4.09106 10.8062 4.09106 10.4548C4.09106 10.1033 4.37597 9.81843 4.72743 9.81843H16.182ZM16.182 6.00024C16.5334 6.00024 16.8183 6.28515 16.8183 6.63661C16.8183 6.98806 16.5334 7.27297 16.182 7.27297H4.72743C4.37597 7.27297 4.09106 6.98806 4.09106 6.63661C4.09106 6.28515 4.37597 6.00024 4.72743 6.00024H16.182Z"
        fill="#FBFAFF"
      />
    </svg>
  );
};

export default HamburgerMenuIcon;
