import { call, put, takeEvery } from "redux-saga/effects";

import { UpcomingLeavesSucess, UpcomingLeavesFailure } from "store/actions/Timeoff/UpcomingLeaves";

import { UPCOMING_LEAVES } from "store/constants/Timeoff";

import getRequest from "util/APIHelperGet";
import { baseUrl } from "util/APIBaseUrl";

export const fetchUpcomingLeavesRequest = async () => {
  const loggedInUserID = JSON.parse(localStorage.getItem("loggedInUser"))?.userId;
  return await getRequest(`${baseUrl}/employee/${loggedInUserID}/upcoming_leaves`)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

function* fetchUpcomingLeaves(params) {
  try {
    let response = yield call(fetchUpcomingLeavesRequest, params);
    yield put(UpcomingLeavesSucess(response));
  } catch (error) {
    console.error(error);
    yield put(UpcomingLeavesFailure(error));
  }
}

export default function* rootSaga() {
  yield takeEvery(UPCOMING_LEAVES, fetchUpcomingLeaves);
}
