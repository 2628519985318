import {
  RESET_CUSTOMER_USER_AND_COMPANY_PROFILE,
  CUSTOMER_USER_AND_COMPANY_PROFILE,
  CUSTOMER_USER_AND_COMPANY_PROFILE_SUCCESS,
  CUSTOMER_USER_AND_COMPANY_PROFILE_FAILURE,
} from "../../constants/Settings/CustomerUser&CompanyProfileData";

// for add employee in admin workforce table

export const ResetGetCustomerUserandCompanyProfileData = (data) => {
  return {
    type: RESET_CUSTOMER_USER_AND_COMPANY_PROFILE,
    payload: data,
  };
};

export const GetCustomerUserandCompanyProfileData = (data) => {
  return {
    type: CUSTOMER_USER_AND_COMPANY_PROFILE,
    payload: data,
  };
};
export const GetCustomerUserandCompanyProfileDataSuccess = (payload) => {
  return {
    type: CUSTOMER_USER_AND_COMPANY_PROFILE_SUCCESS,
    payload,
  };
};
export const GetCustomerUserandCompanyProfileDataFailure = (payload) => {
  return {
    type: CUSTOMER_USER_AND_COMPANY_PROFILE_FAILURE,
    payload,
  };
};
