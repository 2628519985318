import {
    GET_CUSTOMER_Details,
    GET_CUSTOMER_Details_SUCCESS,
    GET_CUSTOMER_Details_FAILURE,
} from "../../constants/Customers/GetCustomerDetails";

// for employee information
export const GetCustomerDetails = (data) => {
    return {
        type: GET_CUSTOMER_Details,
        payload: data,
    };
};
export const GetCustomerDetailsSuccess = (payload) => {
    return {
        type: GET_CUSTOMER_Details_SUCCESS,
        payload,
    };
};
export const GetCustomerDetailsFailure = (payload) => {
    return {
        type: GET_CUSTOMER_Details_FAILURE,
        payload,
    };
};
