import React from "react";
import DisplayCompanyInfo from "./DisplayCompanyInfo";
import { getValue } from "../CommonUtils";
//redux dispatch
import { useSelector, useDispatch } from "react-redux";
import {
  EditCompanyProfileInfo,
  ResetEditCompanyProfile,
} from "../../../store/actions/Settings/EditCompanyInfo";
import { GetCustomerUserandCompanyProfileData } from "../../../store/actions/Settings/CustomerUser&CompanyProfileData";
import { usePermissions } from "../../Hook";
import { UserAdditionalInfo } from "../../../store/actions/UserAdditionalInfo";

const initialCompanyInfo = {
  companyName: "",
  email: "",
  contactPerson: "",
  industry: "",
  contactNumber: "",
};

let compInfo = [
  {
    title: "Company Name",
    value: null,
    name: "companyName",
    type: "text",
    isRequired: true,
  },
  {
    title: "Email Address",
    value: null,
    name: "email",
    type: "text",
    isRequired: true,
  },
  {
    title: "Contact Number",
    value: null,
    name: "contactNumber",
    type: "number",
  },
  {
    title: "Industry",
    value: null,
    name: "industry",
    type: "text",
  },
  {
    title: "Contact Person",
    value: null,
    name: "contactPerson",
    type: "text",
  },
];

const initailModal = {
  modalName: null,
  edit: false,
};

function CompanyInfo(props) {
  const { title } = props;
  const dispatch = useDispatch();
  const { loggedInUser } = usePermissions();

  // component state
  const [companyInfo, setCompanyInfo] = React.useState(initialCompanyInfo);
  const [editAbleModal, setEditAbleModal] = React.useState(initailModal);
  const [openAlert, setOpenAlert] = React.useState(false);
  const [saveInfo, setSaveInfo] = React.useState(false);
  const [displaycompanyInfo, setDisplayCompanyInfo] = React.useState(compInfo);

  //redux state
  const CompanyProfileData = useSelector(
    (state) =>
      state.CustomerUserandCompanyProfileData.customerUserandCompanyProfileData
  );

  const editCompanyInfo = useSelector(
    (state) => state.EditCompanyInfo.editCompanyInfo
  );

  //methods
  const handleInputChange = (event, fieldName) => {
    setCompanyInfo({
      ...companyInfo,
      [event.target.name]: event.target.value,
    });
  };

  const hanldeEdit = (editableModal) => {
    setEditAbleModal({
      modalName: editableModal,
      edit: true,
    });
  };

  const handleSave = (modalName, editable) => {
    handleSaveInfo();
    // setEditAbleModal({
    //     modalName: modalName,
    //     edit: false,
    // });
  };

  const handleCancel = (modalName, editable) => {
    // setCompanyInfo(initialCompanyInfo);
    setEditAbleModal({
      modalName: modalName,
      edit: false,
    });
  };

  const handleSaveInfo = (event) => {
    let logoRequest
    if (loggedInUser?.type == "customer") {
      logoRequest = `customer/${loggedInUser?.customerId}/logo`;
    }
    setSaveInfo(true);

    if (companyInfo.companyName != "" && companyInfo.email) {
      setOpenAlert(false);
      dispatch(
        EditCompanyProfileInfo({
          companyProfileData: companyInfo,
          customerId: loggedInUser?.userId,
        })
      );
    } else {
      setOpenAlert(true);
    }
  };

  //method

  React.useEffect(() => {
    let loggedInUser = {};
    loggedInUser = JSON.parse(localStorage.getItem("loggedInUser"));

    let getProfileDataRequest = `customerUser/${loggedInUser?.userId}`;
    if (editCompanyInfo?.ok) {
      setEditAbleModal({
        modalName: null,
        edit: false,
      });
      dispatch(UserAdditionalInfo(`customer/${loggedInUser?.customerId}/info`));
      dispatch(
        GetCustomerUserandCompanyProfileData({
          request: getProfileDataRequest,
        })
      );
      dispatch(ResetEditCompanyProfile({}));
    }
  }, [editCompanyInfo]);

  React.useEffect(() => {
    if (CompanyProfileData?.ok) {
      setCompanyInfo({
        companyName: getValue(
          CompanyProfileData?.payload?.customer?.companyName
        ),
        email: getValue(CompanyProfileData?.payload?.customer?.contactEmail),
        contactNumber: getValue(
          CompanyProfileData?.payload?.customer?.contactNumber
        ),
        industry: getValue(CompanyProfileData?.payload?.customer?.industry),
        contactPerson: getValue(
          CompanyProfileData?.payload?.customer?.contactPerson
        ),
      });

      compInfo[0].value = getValue(
        CompanyProfileData?.payload?.customer?.companyName
      );
      compInfo[1].value = getValue(
        CompanyProfileData?.payload?.customer?.contactEmail
      );
      compInfo[2].value = getValue(
        CompanyProfileData?.payload?.customer?.contactNumber
      );
      compInfo[3].value = getValue(
        CompanyProfileData?.payload?.customer?.industry
      );
      compInfo[4].value = getValue(
        CompanyProfileData?.payload?.customer?.contactPerson
      );
      setDisplayCompanyInfo(compInfo);
    }
  }, [CompanyProfileData]);

  return (
    <DisplayCompanyInfo
      data={displaycompanyInfo}
      title={title}
      fieldsData={companyInfo}
      hanldeEdit={hanldeEdit}
      editAbleModal={editAbleModal}
      handleInputChange={handleInputChange}
      handleCancel={handleCancel}
      handleSave={handleSave}
      icon={true}
      bgColor="#F2FFFB"
      iconColor="#00C0AF"
      open={openAlert}
      setOpenAlert={setOpenAlert}
      editCompanyInfo={editCompanyInfo}
      saveInfo={saveInfo}
    />
  );
}

export default CompanyInfo;
