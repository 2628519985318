import { call, put, takeEvery } from "redux-saga/effects";
//actions
import {
  CustomerBankInfoSuccess,
  CustomerBankInfoFailure,
} from "../../actions/Payment/GetCustomerBankInfo";
//constant
import { CUSTOMER_BANK_INFO } from "../../constants/Payment/GetCustomerBankInfo";
//request type
import getRequest from "../../../util/APIHelperGet";
import { baseUrl } from "../../../util/APIBaseUrl";

export const fetchCustomerBankAccountInfoRequest = async (actions) => {
  // eslint-disable-next-line no-return-await

  return await getRequest(`${actions.payload.request}`)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

function* fetchCustomerBankAccountInfo(params) {
  try {
    let response = yield call(fetchCustomerBankAccountInfoRequest, params);
    yield put(CustomerBankInfoSuccess(response));
  } catch (error) {
    console.error(error);
    yield put(CustomerBankInfoFailure(error));
  }
}

export default function* rootSaga() {
  yield takeEvery(CUSTOMER_BANK_INFO, fetchCustomerBankAccountInfo);
}
