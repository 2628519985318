import React from "react";

const CrossButtonIcon = () => {
  return (
    <svg
      width="30"
      height="30"
      viewBox="0 0 23 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="11.4126" cy="11.8638" r="10.8755" fill="#F1F2FF" />
      <g clip-path="url(#clip0_3489_48090)">
        <path
          d="M14.2005 14.6524L8.62329 9.0752M14.2005 9.0752L8.62329 14.6524"
          stroke="#3D43BB"
          stroke-width="1.67315"
          stroke-linecap="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_3489_48090">
          <rect
            width="8.36576"
            height="8.36576"
            fill="white"
            transform="translate(7.22925 7.68091)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default CrossButtonIcon;
