import { call, put, takeEvery } from 'redux-saga/effects';
//actions
import { AuthUserSuccess, AuthUserFailure } from '../../actions/AuthUser';
//constant
import { AUTH_USER } from '../../constants/AuthUser';
// import { REGISTER_USER } from "../../constants/RegisterUser";
//request type
import postRequest from '../../../util/APIHelperPost';
import { baseUrl } from '../../../util/APIBaseUrl';

export const fetchAuthUserRequest = async (actions) => {
  let body = {};
  let request = '';

  if (actions.payload.loginPortal != 'admin') {
    body = JSON.stringify({
      email: actions.payload.userCredential.email,
      password: actions.payload.userCredential.password,
      newPassword: actions?.payload?.userCredential?.newPassword,
      confirmPassword: actions?.payload?.userCredential?.confirmPassword,
      type: actions?.payload?.userCredential?.type,
      token: actions?.payload?.userCredential?.token,
    });
    // request = `${baseUrl}/auth/${actions.payload.loginPortal}`;
    request = actions.payload.request;
  } else {
    body = JSON.stringify({
      email: actions.payload.userCredential.email,
      password: actions.payload.userCredential.password,
      newPassword: actions?.payload?.userCredential?.newPassword,
      confirmPassword: actions?.payload?.userCredential?.confirmPassword,
      type: actions?.payload?.userCredential?.type,
      token: actions?.payload?.userCredential?.token,
    });
    // request = `${baseUrl}/auth`;
  }
  // eslint-disable-next-line no-return-await
  return await postRequest(actions.payload.request, body)
    .then((response) => {
      //
      return response;
    })
    .catch((error) => {
      // console.error("error in auth user", error);
    });
};

function* fetchAuthUser(params) {
  try {
    let response = yield call(fetchAuthUserRequest, params);

    yield put(AuthUserSuccess(response));
    // } else {
    //         // console.error("error in auth user a", response);
    //         throw Error(response.status);
  } catch (error) {
    // console.error("error in auth user", error);
    // yield put(AuthUserFailure(error));
  }
}

export default function* rootSaga() {
  yield takeEvery(AUTH_USER, fetchAuthUser);
}
