import React from 'react';

function NoCommentView(props) {
    return (
        <div className="pending-timeoff-no-comment-wrapper" style={{marginLeft:"9px"}}>
            No comment available
        </div>
    );
}

export default NoCommentView;