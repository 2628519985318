import {
  RESET_LOGO,
  LOGO,
  LOGO_SUCCESS,
  LOGO_FAILURE,
} from "../../constants/Logo";

// for user authentication
export const ResetLogo = (data) => {
  return {
    type: RESET_LOGO,
    payload: data,
  };
};

export const Logo = (data) => {
  return {
    type: LOGO,
    payload: data,
  };
};
export const LogoSuccess = (payload) => {
  return {
    type: LOGO_SUCCESS,
    payload,
  };
};
export const LogoFailure = (payload) => {
  return {
    type: LOGO_FAILURE,
    payload,
  };
};
