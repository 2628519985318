import React from "react";
//redux dispatch
import { useSelector, useDispatch } from "react-redux";
//files
import PaymentBreakDown from "./PaymentBreakDown";
//redux actions
import { GetCustomerPaymentById } from "../../../../store/actions/Customers/GetCustomerPaymentById";

function PaymentDetails(props) {
    const { selectedPaymentId } = props;
    const dispatch = useDispatch();
    //component state
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [page, setPage] = React.useState(0);
    const [paginationFlag, setPaginationFlag] = React.useState(false);

    //redux state

    const customerPaymentById = useSelector(
        (state) => state.GetCustomerPaymentById.customerPaymentById
    );

    //functions

    const handlePagination = (pagination) => {
        setPaginationFlag(true);
        setRowsPerPage(pagination.rowsPerPage);
        setPage(pagination.page);
    };

    //methods

    React.useEffect(() => {
        if (selectedPaymentId != undefined && paginationFlag) {
            dispatch(
                GetCustomerPaymentById({
                    request: `customerPayment/${selectedPaymentId}`,
                    rowsPerPage: rowsPerPage,
                    page: page,
                    dist: {},
                    sortBy: {},
                })
            );
        }
    }, [rowsPerPage, page]);

    return (
        <PaymentBreakDown
            paymentDetail={customerPaymentById?.payload}
            customerPayment={customerPaymentById?.payload?.details}
            handlePagination={handlePagination}
            navigatedFrom="paymentDetails"
        />
    );
}

export default PaymentDetails;
