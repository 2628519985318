import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { IconButton } from "@mui/material";

import Dialog, { DialogContent, DialogActions } from "components/common/Dialog";
import Button from "components/common/Button";
import AvatarIcon from "components/Icons/AvatarIcon";

import { baseUrl } from "util/APIBaseUrl";
import postRequest from "util/APIHelperPost";
import { showSnackbar } from "store/actions/Utility";

import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import "./RemoveManager.scss";

function RemoveManager({
  employeeData,
  openRemoveManagerModal,
  setOpenRemoveManagerModal,
  getCustomerAdminEmployeeList,
}) {
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();

  const customerUserId = employeeData?.managedBy;
  const employeeId = employeeData?.employeeId;

  const handleUnassignManager = async () => {
    setIsLoading(true);
    try {
      const response = await postRequest(
        `${baseUrl}/customerUsers/${customerUserId}/unassignEmployee`,
        JSON.stringify({ employeeId })
      );

      if (response.ok) {
        dispatch(
          showSnackbar({
            type: "success",
            message: `${employeeData?.firstName} ${employeeData?.lastName} has been removed successfully.`,
            dispatch,
          })
        );
        setIsLoading(false);
        setOpenRemoveManagerModal(false);
        getCustomerAdminEmployeeList();
      } else {
        dispatch(
          showSnackbar({
            type: "error",
            message: "Error unassigning manager",
            dispatch,
          })
        );
        setIsLoading(false);
        setOpenRemoveManagerModal(false);
      }
    } catch (error) {
      dispatch(
        showSnackbar({
          type: "error",
          message: "Error Unassigning manager",
          dispatch,
        })
      );
      setIsLoading(false);
      setOpenRemoveManagerModal(false);
    }
  };

  const handleClose = () => {
    setOpenRemoveManagerModal(false);
  };

  return (
    <Dialog
      open={openRemoveManagerModal}
      onClose={handleClose}
      size="xs"
      content={
        <DialogContent>
          <div className="mb-075">
            <IconButton className="warning-icon-button">
              <WarningAmberIcon className="warning-icon" />
            </IconButton>
          </div>

          <div className="heading-h2-semibold ">Are you sure you want to remove the following employee?</div>

          <div className="para-body-m-regular remove-manager-text my-05">
            Once removed, all users will start receiving their time-off request notifications. Consider reassigning them
            before proceeding.
          </div>
          <div className="remove-manager-employee-card p-075 flex items-center justify-start gap-1 mb-1">
            <AvatarIcon
              shape="circle"
              size={{
                width: "32px",
                height: "32px",
                borderRadius: "100px",
              }}
              source={`${baseUrl}/employees/${employeeData?.employeeId}/logo?id=${employeeData?.pictureVersion}`}
            />
            <div>
              <div className="heading-h5-regular">{`${employeeData?.firstName} ${employeeData?.lastName}`}</div>
              <div className="heading-h5-regular remove-manager-text">{`${employeeData?.email}`}</div>
            </div>
          </div>
        </DialogContent>
      }
      actions={
        <DialogActions
          action1={<Button label="Cancel" size="large" variant="text" onClick={handleClose} />}
          action2={
            <Button
              label="Yes, remove"
              size="large"
              color="error"
              onClick={handleUnassignManager}
              isLoading={isLoading}
              variant="contained"
            />
          }
        />
      }
    />
  );
}

export default RemoveManager;
