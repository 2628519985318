import { call, put, takeEvery } from "redux-saga/effects";
//actions
import {
  UnAssignEmployeeSuccess,
  UnAssignEmployeeFailure,
} from "../../../actions/Workforce/UnAssignEmployee";
//constant
import { UNASSIGN_EMPLOYEE } from "../../../constants/Workforce/UnAssignEmployee";
//request type
import getRequest from "../../../../util/APIHelperGet";
import { baseUrl } from "../../../../util/APIBaseUrl";

export const fetchUnAssignEmployeeRequest = async (actions) => {
  // eslint-disable-next-line no-return-await

  return await getRequest(
    `${baseUrl}/employee/${actions.payload.employeeId}/${actions.payload.request}`
  )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

function* fetchUnAssignEmployee(params) {
  try {
    let response = yield call(fetchUnAssignEmployeeRequest, params);
    yield put(UnAssignEmployeeSuccess(response));
  } catch (error) {
    console.error(error);
    yield put(UnAssignEmployeeFailure(error));
  }
}

export default function* rootSaga() {
  yield takeEvery(UNASSIGN_EMPLOYEE, fetchUnAssignEmployee);
}
