import {
  USER_DETAILS,
  USER_DETAILS_SUCCESS,
  USER_DETAILS_FAILURE,
} from "../constants/UserDetails";

// for user authentication
export const UserDetails = (data) => {
  return {
    type: USER_DETAILS,
    payload: data,
  };
};
export const UserDetailsSuccess = (payload) => {
  return {
    type: USER_DETAILS_SUCCESS,
    payload,
  };
};
export const UserDetailsFailure = (payload) => {
  return {
    type: USER_DETAILS_FAILURE,
    payload,
  };
};
