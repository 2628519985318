import {
  RESET_ADD_CUSTOMER_BANK_INFO,
  ADD_CUSTOMER_BANK_INFO,
  ADD_CUSTOMER_BANK_INFO_SUCCESS,
  ADD_CUSTOMER_BANK_INFO_FAILURE,
} from "../../constants/Payment/AddBankAccountInfo";

// for user authentication
export const ResetAddCustomerBankInfo = (data) => {
  return {
    type: RESET_ADD_CUSTOMER_BANK_INFO,
    payload: data,
  };
};

export const AddCustomerBankInfo = (data) => {
  return {
    type: ADD_CUSTOMER_BANK_INFO,
    payload: data,
  };
};
export const AddCustomerBankInfoSuccess = (payload) => {
  return {
    type: ADD_CUSTOMER_BANK_INFO_SUCCESS,
    payload,
  };
};
export const AddCustomerBankInfoFailure = (payload) => {
  return {
    type: ADD_CUSTOMER_BANK_INFO_FAILURE,
    payload,
  };
};
