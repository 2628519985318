import React from "react";

const NdaIcon = () => {
  return (
    <svg
      width="51"
      height="50"
      viewBox="0 0 51 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="0.744629"
        y="0.218994"
        width="49.5107"
        height="49.5107"
        rx="24.7553"
        fill="#FBFAFF"
      />
      <g clip-path="url(#clip0_1122_18098)">
        <path
          d="M16.3047 36.8719C6.78854 34.0287 10.5596 16.1045 21.5276 18.7477C32.1785 21.3144 39.9223 12.0182 43.4903 19.1965C44.8813 21.9949 44.1894 26.7895 36.5035 35.2808C29.8008 42.6857 27.7199 40.2824 16.3047 36.8719Z"
          fill="#79A1E8"
        />
        <path
          d="M35.6729 41.9893C36.9709 41.9893 38.0232 41.6771 38.0232 41.2919C38.0232 40.9067 36.9709 40.5945 35.6729 40.5945C34.3749 40.5945 33.3226 40.9067 33.3226 41.2919C33.3226 41.6771 34.3749 41.9893 35.6729 41.9893Z"
          fill="#473CBA"
        />
        <path
          d="M20.7921 42.1656C26.7049 42.1656 31.4982 41.8533 31.4982 41.4682C31.4982 41.083 26.7049 40.7708 20.7921 40.7708C14.8792 40.7708 10.0859 41.083 10.0859 41.4682C10.0859 41.8533 14.8792 42.1656 20.7921 42.1656Z"
          fill="#473CBA"
        />
        <path
          d="M36.147 15.7395L34.6024 18.8559H37.6914L36.147 15.7395Z"
          fill="#D7ECF9"
        />
        <path
          d="M34.6024 18.856V34.5536L37.6914 34.5535V18.856H34.6024Z"
          fill="#F9D015"
        />
        <path
          d="M34.6024 34.5535V36.0613C34.6024 36.9246 35.2939 37.6245 36.1469 37.6245C36.9999 37.6245 37.6914 36.9246 37.6914 36.0613V34.5535H34.6024Z"
          fill="#7693DD"
        />
        <path
          d="M37.6914 33.9771H34.6024V35.0192H37.6914V33.9771Z"
          fill="#D7ECF9"
        />
        <path
          d="M36.1991 27.8827C36.0343 27.8827 35.9006 27.7474 35.9006 27.5806V24.5599C35.9006 24.3931 36.0343 24.2578 36.1991 24.2578C36.3639 24.2578 36.4975 24.3931 36.4975 24.5599V27.5806C36.4975 27.7474 36.3639 27.8827 36.1991 27.8827Z"
          fill="#4B31AA"
        />
        <path
          d="M37.9899 18.8559C37.9899 18.8536 37.9893 18.8515 37.9892 18.8493C37.9888 18.8323 37.9873 18.8153 37.9841 18.7985C37.9838 18.7971 37.9838 18.7955 37.9835 18.7941C37.9801 18.7775 37.9748 18.7612 37.9685 18.7451C37.9668 18.7406 37.965 18.7363 37.963 18.732C37.9613 18.7282 37.96 18.7242 37.9582 18.7205L36.4138 15.6041C36.3631 15.5019 36.2599 15.4375 36.147 15.4375C36.0341 15.4375 35.9309 15.502 35.8803 15.6041L34.3358 18.7205C34.3339 18.7243 34.3327 18.7282 34.331 18.732C34.329 18.7363 34.3272 18.7407 34.3255 18.7451C34.3193 18.7612 34.314 18.7775 34.3104 18.7941C34.3101 18.7955 34.3101 18.7971 34.3099 18.7985C34.3066 18.8153 34.3051 18.8323 34.3048 18.8493C34.3047 18.8516 34.3041 18.8536 34.3041 18.8559L34.304 33.8874C34.3088 33.8879 34.3136 33.8886 34.3184 33.8891C34.3099 33.9171 34.304 33.9462 34.304 33.977V34.7076V35.0191V36.0612C34.304 37.0898 35.1307 37.9265 36.1469 37.9265C37.163 37.9265 37.9898 37.0898 37.9899 36.0612V35.0191V34.6726V33.977C37.9899 33.9743 37.9891 33.9717 37.9891 33.969C37.9894 33.969 37.9896 33.969 37.9899 33.969V18.8559ZM36.147 16.4132L37.2079 18.5538H35.086L36.147 16.4132ZM37.393 36.0613C37.3929 36.7567 36.8339 37.3224 36.1469 37.3224C35.4598 37.3224 34.9009 36.7567 34.9009 36.0613V35.3212H37.393V36.0613ZM37.393 34.7171H34.9009V34.2791H37.393V34.7171ZM37.393 33.6749H36.4923C36.4952 33.6577 36.4976 33.6402 36.4976 33.6221V29.091C36.4976 28.9242 36.3639 28.7889 36.1991 28.7889C36.0343 28.7889 35.9007 28.9242 35.9007 29.091V33.6221C35.9007 33.6402 35.9029 33.6577 35.906 33.6749H34.9009L34.9009 19.158H37.393V33.6749Z"
          fill="#4B31AA"
        />
        <path
          d="M28.1681 13.1723V8.08528L36.0057 8.08521C37.8348 8.08521 39.3176 9.58604 39.3176 11.4374V13.1723H28.1681Z"
          fill="#473CBA"
        />
        <path
          d="M13.6317 10.9873V38.7419H29.0729C30.902 38.7419 32.3848 37.2411 32.3848 35.3898V11.4516C32.3848 9.59261 33.8738 8.08521 35.7105 8.08521H16.4991C14.9155 8.08521 13.6317 9.38456 13.6317 10.9873Z"
          fill="white"
        />
        <path
          d="M13.6317 38.7419H29.0729C30.902 38.7419 32.3848 37.2412 32.3848 35.3899V14.6619L13.6317 33.6425V38.7419Z"
          fill="#E1F0FA"
        />
        <path
          d="M28.0094 38.7419H12.4724C10.786 38.7419 9.41907 37.3583 9.41907 35.6516V33.3665H24.6838V35.376C24.6837 37.2349 26.1727 38.7419 28.0094 38.7419Z"
          fill="#7693DD"
        />
        <path
          d="M36.0057 7.7832H35.7106H16.4991C14.7987 7.7832 13.4078 9.14713 13.3368 10.8507C13.5359 10.8504 13.7351 10.8491 13.9341 10.8465C14.0067 9.47812 15.1294 8.38734 16.4991 8.38734H33.7201C32.7366 9.04405 32.0864 10.1725 32.0864 11.4516V35.3898C32.0864 37.0716 30.7346 38.4398 29.0729 38.4398H28.0093C27.2007 38.4398 26.4405 38.1211 25.8687 37.5424C25.297 36.9637 24.9821 36.1943 24.9821 35.376V33.3665C24.9821 33.1996 24.8485 33.0644 24.6837 33.0644H13.9303V30.5385C13.7307 30.4942 13.5319 30.4455 13.3334 30.3941V33.0645H9.41905C9.25424 33.0645 9.12061 33.1997 9.12061 33.3665V35.6516C9.12061 37.5223 10.6242 39.0441 12.4723 39.0441H28.0094H29.073C31.0638 39.0441 32.6834 37.4048 32.6834 35.39V13.4744H39.3177C39.4825 13.4744 39.6161 13.3391 39.6161 13.1723V11.4374C39.6161 9.42254 37.9965 7.78328 36.0057 7.7832ZM12.4723 38.4398C10.9533 38.4398 9.7175 37.189 9.7175 35.6515V33.6685H24.3853V35.3759C24.3853 36.3557 24.7622 37.2768 25.4467 37.9696C25.6222 38.1472 25.8125 38.3043 26.0147 38.4398H12.4723ZM39.0192 12.8702H32.6834V11.4516C32.6834 9.76199 34.0413 8.38734 35.7106 8.38734H36.0057C37.6674 8.38742 39.0192 9.75565 39.0192 11.4374V12.8702Z"
          fill="#4B31AA"
        />
        <path
          d="M21.8709 30.3815H8.48413V11.0122H21.8709C23.007 11.0122 23.9281 11.9444 23.9281 13.0944V28.2994C23.9281 29.4493 23.0071 30.3815 21.8709 30.3815Z"
          fill="#E1F0FA"
        />
        <path
          d="M19.9311 30.3815H6.54431V11.0122H19.9311C21.0672 11.0122 21.9883 11.9444 21.9883 13.0944V28.2994C21.9883 29.4493 21.0673 30.3815 19.9311 30.3815Z"
          fill="#7693DD"
        />
        <path
          d="M7.2041 30.3814H19.9312C21.0673 30.3814 21.9884 29.4492 21.9884 28.2992V15.4177L7.2041 30.3814Z"
          fill="#473CBA"
        />
        <path
          d="M21.9074 16.1296H6.52698V22.4731H21.9074V16.1296Z"
          fill="#F9D015"
        />
        <path
          d="M15.0175 22.4731H21.9075V16.1296H21.2849L15.0175 22.4731Z"
          fill="#E5B900"
        />
        <path
          d="M17.7785 11.0449H16.2365V30.3271H17.7785V11.0449Z"
          fill="#E1F0FA"
        />
        <path
          d="M28.3684 16.4822C29.7901 16.4822 30.9426 15.3157 30.9426 13.8768C30.9426 12.4379 29.7901 11.2715 28.3684 11.2715C26.9468 11.2715 25.7943 12.4379 25.7943 13.8768C25.7943 15.3157 26.9468 16.4822 28.3684 16.4822Z"
          fill="#13CECE"
        />
        <path
          d="M28.3685 16.7841C26.7846 16.7841 25.496 15.4798 25.496 13.8767C25.496 12.2735 26.7846 10.9692 28.3685 10.9692C29.9525 10.9692 31.2411 12.2735 31.2411 13.8767C31.2411 15.4798 29.9524 16.7841 28.3685 16.7841ZM28.3685 11.5734C27.1138 11.5734 26.0929 12.6067 26.0929 13.8767C26.0929 15.1466 27.1138 16.18 28.3685 16.18C29.6233 16.18 30.6442 15.1466 30.6442 13.8767C30.6442 12.6067 29.6233 11.5734 28.3685 11.5734Z"
          fill="#4B31AA"
        />
        <path
          d="M10.5161 32.3912L9.77002 31.9875L8.92444 32.3912V30.4277H10.5161V32.3912Z"
          fill="#E1F0FA"
        />
        <path
          d="M21.871 10.71H6.5443C6.37948 10.71 6.24585 10.8452 6.24585 11.012V30.3813C6.24585 30.5481 6.37948 30.6834 6.5443 30.6834H8.62598V32.3911C8.62598 32.4945 8.67828 32.5908 8.76461 32.6462C8.81318 32.6774 8.8687 32.6931 8.92443 32.6931C8.96778 32.6931 9.01128 32.6836 9.05172 32.6642L9.76158 32.3253L10.3753 32.6574C10.4679 32.7074 10.5795 32.7047 10.6695 32.6501C10.7594 32.5955 10.8145 32.4973 10.8145 32.391V30.6833H21.8709C23.1699 30.6833 24.2266 29.6138 24.2266 28.2991V13.0941C24.2267 11.7795 23.1699 10.71 21.871 10.71ZM10.2177 31.8871L9.91073 31.721C9.82746 31.6759 9.72808 31.6734 9.64265 31.7142L9.22288 31.9146V30.7298H10.2177V31.8871ZM18.0768 11.3141H19.9311C20.9009 11.3141 21.6898 12.1126 21.6898 13.0941V15.8275H18.0769V11.3141H18.0768ZM18.0768 16.4316H21.609V22.171H18.0768V16.4316ZM15.938 30.0793H8.89981H6.84275V22.7751H15.938V30.0793ZM15.938 22.171H6.84275V16.4316H15.938V22.171ZM15.938 15.8275H6.84275V11.3141H9.35479H15.938V15.8275ZM17.4799 30.0248H16.5349V11.3468H17.48V30.0248H17.4799ZM19.9311 30.0793H18.0768V22.7751H21.6898V28.2991C21.6898 29.2807 20.9009 30.0793 19.9311 30.0793ZM23.6298 28.2992C23.6298 29.2807 22.8408 30.0793 21.871 30.0793H21.4959C21.9807 29.6422 22.2867 29.0063 22.2867 28.2992V13.0941C22.2867 12.387 21.9807 11.7511 21.4959 11.3141H21.871C22.8408 11.3141 23.6298 12.1126 23.6298 13.0941V28.2992Z"
          fill="#4B31AA"
        />
        <path
          d="M28.3684 23.802C29.7901 23.802 30.9426 22.6356 30.9426 21.1967C30.9426 19.7578 29.7901 18.5913 28.3684 18.5913C26.9468 18.5913 25.7943 19.7578 25.7943 21.1967C25.7943 22.6356 26.9468 23.802 28.3684 23.802Z"
          fill="#13CECE"
        />
        <path
          d="M28.3685 24.1042C26.7846 24.1042 25.496 22.7999 25.496 21.1967C25.496 19.5936 26.7846 18.2893 28.3685 18.2893C29.9525 18.2893 31.2411 19.5936 31.2411 21.1967C31.2411 22.7999 29.9524 24.1042 28.3685 24.1042ZM28.3685 18.8934C27.1138 18.8934 26.0929 19.9268 26.0929 21.1967C26.0929 22.4667 27.1138 23.5 28.3685 23.5C29.6233 23.5 30.6442 22.4667 30.6442 21.1967C30.6442 19.9268 29.6233 18.8934 28.3685 18.8934Z"
          fill="#4B31AA"
        />
        <path
          d="M27.9051 15.1742C27.8287 15.1742 27.7523 15.1446 27.6941 15.0857L26.9234 14.3056C26.8068 14.1877 26.8068 13.9964 26.9234 13.8785C27.0399 13.7605 27.229 13.7605 27.3454 13.8785L27.9051 14.4449L29.4556 12.8756C29.5721 12.7576 29.7612 12.7576 29.8776 12.8756C29.9942 12.9936 29.9942 13.1849 29.8776 13.3028L28.1161 15.0857C28.0579 15.1446 27.9814 15.1742 27.9051 15.1742Z"
          fill="#4B31AA"
        />
        <path
          d="M28.3684 30.9385C29.7901 30.9385 30.9426 29.772 30.9426 28.3331C30.9426 26.8942 29.7901 25.7278 28.3684 25.7278C26.9468 25.7278 25.7943 26.8942 25.7943 28.3331C25.7943 29.772 26.9468 30.9385 28.3684 30.9385Z"
          fill="#13CECE"
        />
        <path
          d="M28.3685 31.2404C26.7846 31.2404 25.496 29.9361 25.496 28.333C25.496 26.7298 26.7846 25.4255 28.3685 25.4255C29.9525 25.4255 31.2411 26.7298 31.2411 28.333C31.2411 29.9361 29.9524 31.2404 28.3685 31.2404ZM28.3685 26.0297C27.1138 26.0297 26.0929 27.063 26.0929 28.333C26.0929 29.6029 27.1138 30.6363 28.3685 30.6363C29.6233 30.6363 30.6442 29.6029 30.6442 28.333C30.6442 27.063 29.6233 26.0297 28.3685 26.0297Z"
          fill="#4B31AA"
        />
        <path
          d="M27.9051 22.494C27.8287 22.494 27.7523 22.4645 27.6941 22.4055L26.9234 21.6255C26.8068 21.5075 26.8068 21.3162 26.9234 21.1983C27.0399 21.0803 27.229 21.0803 27.3454 21.1983L27.9051 21.7647L29.4556 20.1955C29.5721 20.0774 29.7612 20.0774 29.8776 20.1955C29.9942 20.3134 29.9942 20.5047 29.8776 20.6226L28.1161 22.4055C28.0579 22.4645 27.9814 22.494 27.9051 22.494Z"
          fill="#4B31AA"
        />
        <path
          d="M27.9051 29.6305C27.8287 29.6305 27.7523 29.6009 27.6941 29.542L26.9234 28.7619C26.8068 28.644 26.8068 28.4527 26.9234 28.3348C27.0399 28.2168 27.229 28.2168 27.3454 28.3348L27.9051 28.9012L29.4556 27.3319C29.5721 27.2139 29.7612 27.2139 29.8776 27.3319C29.9942 27.4499 29.9942 27.6412 29.8776 27.7591L28.1161 29.542C28.0579 29.6009 27.9814 29.6305 27.9051 29.6305Z"
          fill="#4B31AA"
        />
      </g>
      <defs>
        <clipPath id="clip0_1122_18098">
          <rect
            width="37.8207"
            height="34.3824"
            fill="white"
            transform="translate(6.24573 7.7832)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default NdaIcon;
