import { call, put, takeEvery } from "redux-saga/effects";
//actions
import {
  CustomerActivationEmailSuccess,
  CustomerActivationEmailFailure,
} from "../../actions/Customers/ActivationEmail";
//constant
import { CUSTOMER_ACTIVATION_EMAIL } from "../../constants/Customers/ActivationEmail";
//request type
import getRequest from "../../../util/APIHelperGet";
//base url
import { baseUrl } from "../../../util/APIBaseUrl";

export const fetchCustomerActivationEmailRequest = async (actions) => {
  let body = JSON.stringify({
    edgeEmployeeId: actions.payload.edgeEmployeeId,
  });

  // return await postRequest(`${baseUrl}/${actions.payload.request}`, body)
  return await getRequest(`${baseUrl}/${actions.payload.request}`)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

function* fetchCustomerActivationEmail(params) {
  try {
    let response = yield call(fetchCustomerActivationEmailRequest, params);

    yield put(CustomerActivationEmailSuccess(response));
  } catch (error) {
    console.error(error);
    yield put(CustomerActivationEmailFailure(error));
  }
}

export default function* rootSaga() {
  yield takeEvery(CUSTOMER_ACTIVATION_EMAIL, fetchCustomerActivationEmail);
}
