import React from "react";
import { Dialog, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

import SectionLoader from "components/common/SectionLoader";
import EmployeeAvatar from "components/Screens/Timeoff/TimeoffAdmin/EmployeeTimeOffDetails/EmployeeAvatar";

import { baseUrl } from "util/APIBaseUrl";

import "./ChurnedConfirmationModal.css";

const ConfirmationDialog = ({
  open,
  handleClose,
  handleConfirm,
  title,
  companyName,
  employeeCount,
  employees,
  loadingEmployeesInfo,
}) => {
  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="sm"
        fullWidth
        PaperProps={{
          sx: {
            padding: "20px",
            borderRadius: "10px",
            boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.1)",
          },
        }}
      >
        <div className="churned-modal">
          <div className="title-section">
            <h1 className="churned-modal-title">{title}</h1>
            <IconButton sx={{ width: "20px", height: "20px" }} onClick={() => handleClose()}>
              <CloseIcon />
            </IconButton>
          </div>
          <div className="churned-modal-content">
            <p>
              You are about to mark <span style={{ fontWeight: "700" }}>{companyName}</span> as churned. This will
              result in the following <span style={{ fontWeight: "700" }}>{employeeCount}</span> Employee(s) being
              unassigned from this account:
            </p>
            <div className="churned-modal-employee-list">
              {loadingEmployeesInfo ? (
                <SectionLoader />
              ) : (
                <>
                  {employees?.map((employee, index) => (
                    <div key={index} className="churned-modal-employee-item">
                      <EmployeeAvatar
                        img={`${baseUrl}/employee/${employee?.employeeId}/logo?id=${employee?.pictureVersion}&variant=thumbnail`}
                        size={{ width: "40px", height: "40px", borderRadius: "50%" }}
                        country={employee?.country}
                      />
                      <div className="churned-modal-employee-info">
                        <p className="churned-modal-employee-name">{`${employee.firstName} ${employee.lastName}`}</p>
                        <p className="churned-modal-employee-email">{employee.email}</p>
                      </div>
                    </div>
                  ))}
                </>
              )}
            </div>
            <p className="churned-modal-warning">This action is not reversible.</p>
          </div>
          <div className="churned-modal-actions">
            <button onClick={handleClose} className="churned-modal-cancel">
              Cancel
            </button>
            <button onClick={handleConfirm} className="churned-modal-confirm">
              Mark as Churned
            </button>
          </div>
        </div>
      </Dialog>
    </>
  );
};

export default ConfirmationDialog;
