import {
    RESET_GET_CUSTOMER_PAYMENT_SUMMARY,
    GET_CUSTOMER_PAYMENT_SUMMARY,
    GET_CUSTOMER_PAYMENT_SUMMARY_SUCCESS,
    GET_CUSTOMER_PAYMENT_SUMMARY_FAILURE,
} from "../../constants/Customers/GetCustomerPaymentSummary";

// for employee information
export const ResetGetCustomerPaymentSummary = (data) => {
    return {
        type: RESET_GET_CUSTOMER_PAYMENT_SUMMARY,
        payload: data,
    };
};

export const GetCustomerPaymentSummary = (data) => {
    return {
        type: GET_CUSTOMER_PAYMENT_SUMMARY,
        payload: data,
    };
};
export const GetCustomerPaymentSummarySuccess = (payload) => {
    return {
        type: GET_CUSTOMER_PAYMENT_SUMMARY_SUCCESS,
        payload,
    };
};
export const GetCustomerPaymentSummaryFailure = (payload) => {
    return {
        type: GET_CUSTOMER_PAYMENT_SUMMARY_FAILURE,
        payload,
    };
};
