//employee constants
import {
  RESET_ADD_PERMISSIONS,
  ADD_PERMISSIONS,
  ADD_PERMISSIONS_SUCCESS,
  ADD_PERMISSIONS_FAILURE,
} from "../../constants/Permissions/AddPermissions";

const INIT_STATE = {
  addPermissions: {},
  loadingAddPermissions: false,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case RESET_ADD_PERMISSIONS:
      return INIT_STATE;

    case ADD_PERMISSIONS:
      return {
        ...state,
        loadingAddPermissions: true,
      };
    case ADD_PERMISSIONS_SUCCESS:
      return {
        ...state,
        loadingAddPermissions: false,
        addPermissions: action.payload,
      };
    // eslint-disable-next-line no-fallthrough
    case ADD_PERMISSIONS_FAILURE:
      return {
        ...state,
        loadingAddPermissions: false,
        addPermissions: "Error in  getting Club Data",
      };
    default:
      return state;
  }
};
