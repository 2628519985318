import React from "react";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";

import EmployeeStubsGrid from "./EmployeeStubsGrid";
import PageLoader from "components/common/PageLoader";
import { usePermissions } from "components/Hook";

import { EmployeeDocumentsRequest } from "store/actions/EmployeePortal/EmployeeDocuments";

import "./EmployeePayStubs.css";

const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

function EmployeePayStub(props) {
  const userAdditionalInfo = useSelector((state) => state.UserAdditionalInfo.userAdditionalInfo);
  const loadingAdditionalInfo = useSelector((state) => state.UserAdditionalInfo.loadingAdditionalInfo);
  const dispatch = useDispatch();
  const { loggedInUser } = usePermissions();

  // const empDocs = useSelector((state) => state.EmployeeDocuments);

  const loadingEmpDocs = useSelector((state) => state.EmployeeDocuments.isLoading);

  // const handleDownloadSlip = async () => {
  //   const empDocument = empDocs?.docs.find((doc) => doc?.documentType === "Contract");
  //   if (empDocument) {
  //     const response = await getRequest(
  //       `${baseUrl}/employees/${loggedInUser?.userId}/document/${empDocument?.documentId}`,
  //       true,
  //       "export"
  //     );

  //     if (!response?.ok) {
  //       return;
  //     }
  //     const url = window.URL.createObjectURL(response?.payload);
  //     const link = document?.createElement("a");
  //     link.style.display = "none";
  //     link.href = url;

  //     link.download = empDocument?.documentType;
  //     document.body.appendChild(link);
  //     link.click();
  //     window.URL.revokeObjectURL(url);
  //     // alert("your file has downloaded!");
  //   }
  // };

  React.useEffect(() => {
    dispatch(EmployeeDocumentsRequest(`employees/${loggedInUser.userId}/document`));
  }, []);

  return (
    <>
      <Helmet>
        <title>Edge | Pay Slips</title>
      </Helmet>
      {(loadingAdditionalInfo || loadingEmpDocs) && <PageLoader />}
      <div
        style={{
          display: "flex",
          background: "#FAFAFF",
          height: "calc(100vh)",
        }}
      >
        <div className="employee-paystubs-dA">
          <div className="employee-paystubs-dA1">
            <div className="employee-paystubs-dA1-Menu mt-05">Your Pay Slips</div>
          </div>
          <EmployeeStubsGrid />
          <br />
        </div>
      </div>
    </>
  );
}

export default EmployeePayStub;
