import React from "react";
import { Helmet } from "react-helmet";
import { Grid } from "@mui/material";

import Header from "components/Header/Header";
import CustomerWorkforceV2 from "./CustomerWorkforceV2";
import AdminWorkforce from "./AdminWorkforce";

import { usePermissions } from "components/Hook";

function index(props) {
  const { loggedInUserType } = usePermissions();

  return (
    <>
      <Helmet>
        <title>Edge | Workforce</title>
      </Helmet>
      <Grid container direction="row" rowSpacing={3}>
        {loggedInUserType == "edge" && (
          <Grid item xs={12}>
            <Header
              data={{
                heading: "Workforce",
                profileWithIcon: null,
              }}
            />
          </Grid>
        )}

        <Grid item xs={12}>
          {loggedInUserType == "edge" ? (
            <AdminWorkforce />
          ) : loggedInUserType == "customer" ? (
            <CustomerWorkforceV2 />
          ) : null}
        </Grid>
      </Grid>
    </>
  );
}

export default index;
