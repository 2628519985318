import React from "react";

const ArrowIcons = () => {
  return (
    <svg width="17" height="18" viewBox="0 0 17 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M12.4568 9.70831H2.125V8.29164H12.4568L7.99921 3.8341L9.00079 2.83252L15.1683 8.99998L9.00079 15.1674L7.99921 14.1659L12.4568 9.70831Z"
        fill="#212231"
      />
    </svg>
  );
};

export default ArrowIcons;
