import {
  RESET_ADD_EMPLOYEE,
  ADD_EMPLOYEE,
  ADD_EMPLOYEE_SUCCESS,
  ADD_EMPLOYEE_FAILURE,
} from "../constants/AddEmployee";

// for add employee in admin workforce table

export const ResetAddEmployee = (data) => {
  return {
    type: RESET_ADD_EMPLOYEE,
    payload: data,
  };
};

export const AddEmployee = (data) => {
  return {
    type: ADD_EMPLOYEE,
    payload: data,
  };
};
export const AddEmployeeSuccess = (payload) => {
  return {
    type: ADD_EMPLOYEE_SUCCESS,
    payload,
  };
};
export const AddEmployeeFailure = (payload) => {
  return {
    type: ADD_EMPLOYEE_FAILURE,
    payload,
  };
};
