import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";

import LeaveMessage from "components/Screens/Timeoff/LeaveStatus/LeaveMessage";
import PageLoader from "components/common/PageLoader";
import LeaveReplacement from "components/common/LeaveReplacement";
import FormSubmissionMessage from "components/Screens/Timeoff/LeaveStatus/FormSubmissionMessage";

import { GetPlatformConfig } from "store/actions/PlatformConfig";

import { baseUrl } from "util/APIBaseUrl";

function LeaveStatus() {
  const location = useLocation();
  const dispatch = useDispatch();

  const queryParams = new URLSearchParams(location.search);
  const tokenParam = queryParams.get("token");

  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [formSubmitted, setFormSubmitted] = useState(false);

  const approveOrRejectLeaveRequest = async (queryParamsURL, formData = null) => {
    const actionParam = queryParamsURL.get("action");

    if (!actionParam || !tokenParam) {
      console.error("Missing required query parameters.");
      return;
    }

    const apiUrl = `${baseUrl}/leave_request/action`;

    let requestData = {
      action: actionParam,
      token: tokenParam,
    };

    if (formData) {
      requestData = { ...requestData, ...formData, action: "Approved" };
    }

    const requestOptions = {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(requestData),
    };

    try {
      const response = await fetch(apiUrl, requestOptions);
      if (!response.ok) {
        throw new Error("API Error");
      }
      const data = await response.json();
      setMessage(data?.message);
      // eslint-disable-next-line consistent-return
      return await data;
    } catch (error) {
      console.error("API Error:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleReplacementData = async (formData) => {
    const data = await approveOrRejectLeaveRequest(queryParams, formData);
    if (data.message) setFormSubmitted(true);
  };

  useEffect(() => {
    const fetchData = async () => {
      const actionParam = queryParams.get("action");
      if (actionParam === "Approved" || actionParam === "Rejected") {
        await approveOrRejectLeaveRequest(queryParams);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    dispatch(GetPlatformConfig({}));
  }, []);

  useEffect(() => {
    const decodedString = atob(tokenParam);
    const tokenObject = JSON.parse(decodedString);

    window.heap?.identify(tokenObject.userId);
  }, [window.heap]);

  return (
    <>
      {loading && <PageLoader />}
      {!loading && queryParams.get("action") === "Request" && (
        <div className="flex items-center justify-center w-full bg-white">
          <div style={{ width: "530px" }}>
            {formSubmitted ? (
              <FormSubmissionMessage />
            ) : (
              <LeaveReplacement onDataReceived={handleReplacementData} fromEmail />
            )}
          </div>
        </div>
      )}
      {!loading && queryParams.get("action") !== "Request" && <LeaveMessage message={message} />}
    </>
  );
}

export default LeaveStatus;
