//employee constants
import {
  RESET_EDIT_USER_PROFILE,
  EDIT_USER_PROFILE,
  EDIT_USER_PROFILE_SUCCESS,
  EDIT_USER_PROFILE_FAILURE,
} from "../../constants/Settings/EditUserProfile";

const INIT_STATE = {
  editUserProfile: {},
  loadingEditUserProfile: false,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case RESET_EDIT_USER_PROFILE:
      return INIT_STATE;

    case EDIT_USER_PROFILE:
      return {
        ...state,
        loadingEditUserProfile: true,
      };
    case EDIT_USER_PROFILE_SUCCESS:
      return {
        ...state,
        loadingEditUserProfile: false,
        editUserProfile: action.payload,
      };
    // eslint-disable-next-line no-fallthrough
    case EDIT_USER_PROFILE_FAILURE:
      return {
        ...state,
        loadingEditUserProfile: false,
        editUserProfile: "Error in  getting Club Data",
      };
    default:
      return state;
  }
};
