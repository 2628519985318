import { call, put, takeEvery } from "redux-saga/effects";
import { setBalance, fetchBalanceFailed } from "../../actions/Payoneer/PayoneerGetEmployeeBalance";
import { PAYONEER_GET_EMPLOYEE_BALANCE } from "store/constants/Payoneer/PayoneerGetEmployeeBalance";
import { baseUrl } from "util/APIBaseUrl";
import getRequest from "util/APIHelperGet";

const fetchBalancesFromAPI = async () => {
  return getRequest(`${baseUrl}/payoneer/payee-balance`);
};

function* fetchBalancesSaga() {
  const balanceInfo = yield call(fetchBalancesFromAPI);
  if (balanceInfo?.ok) {
    yield put(setBalance(balanceInfo?.payload));
  } else {
    yield put(fetchBalanceFailed(balanceInfo?.payload?.error[0]));
  }
}

export default function* rootSaga() {
  yield takeEvery(PAYONEER_GET_EMPLOYEE_BALANCE, fetchBalancesSaga);
}
