//employee constants
import {
  RESER_GET_PAYMENT_DETAILS,
  GET_PAYMENT_DETAILS,
  GET_PAYMENT_DETAILS_SUCCESS,
  GET_PAYMENT_DETAILS_FAILURE,
} from "../../constants/Payroll/GetPaymentDetails";

const INIT_STATE = {
  paymentDetails: {},
  loadingPaymentDetails: false,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case RESER_GET_PAYMENT_DETAILS:
      return INIT_STATE;
    case GET_PAYMENT_DETAILS:
      return {
        ...state,
        loadingPaymentDetails: true,
      };
    case GET_PAYMENT_DETAILS_SUCCESS:
      return {
        ...state,
        loadingPaymentDetails: false,
        paymentDetails: action.payload,
      };
    // eslint-disable-next-line no-fallthrough
    case GET_PAYMENT_DETAILS_FAILURE:
      return {
        ...state,
        loadingPaymentDetails: false,
        paymentDetails: "Error in  getting Club Data",
      };
    default:
      return state;
  }
};
