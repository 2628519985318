//employee constants
import {
  RESET_ASSIGN_CUSTOMER_TO_EMPLOYEE,
  ASSIGN_CUSTOMER_TO_EMPLOYEE,
  ASSIGN_CUSTOMER_TO_EMPLOYEE_SUCCESS,
  ASSIGN_CUSTOMER_TO_EMPLOYEE_FAILURE,
} from "../../constants/Customers/AssignCustomertoEmployee";

const INIT_STATE = {
  assignCustomerToEmployee: {},
  loadingAssignCustomerToEmployee: false,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case RESET_ASSIGN_CUSTOMER_TO_EMPLOYEE:
      return INIT_STATE;

    case ASSIGN_CUSTOMER_TO_EMPLOYEE:
      return {
        ...state,
        loadingAssignCustomerToEmployee: true,
      };
    case ASSIGN_CUSTOMER_TO_EMPLOYEE_SUCCESS:
      return {
        ...state,
        loadingAssignCustomerToEmployee: false,
        assignCustomerToEmployee: action.payload,
      };
    // eslint-disable-next-line no-fallthrough
    case ASSIGN_CUSTOMER_TO_EMPLOYEE_FAILURE:
      return {
        ...state,
        loadingAssignCustomerToEmployee: false,
        assignCustomerToEmployee: "Error in  getting Club Data",
      };
    default:
      return state;
  }
};
