//employee constants
import {
  RESET_GET_USER_DETAILS,
  GET_USER_DETAILS,
  GET_USER_DETAILS_SUCCESS,
  GET_USER_DETAILS_FAILURE,
} from "../../constants/Settings/GetUserDetails";

const INIT_STATE = {
  getUserDetails: {},
  loadingGetUserDetails: false,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case RESET_GET_USER_DETAILS:
      return INIT_STATE;

    case GET_USER_DETAILS:
      return {
        ...state,
        loadingGetUserDetails: true,
      };
    case GET_USER_DETAILS_SUCCESS:
      return {
        ...state,
        loadingGetUserDetails: false,
        getUserDetails: action.payload,
      };
    // eslint-disable-next-line no-fallthrough
    case GET_USER_DETAILS_FAILURE:
      return {
        ...state,
        loadingGetUserDetails: false,
        getUserDetails: "Error in  getting Club Data",
      };
    default:
      return state;
  }
};
