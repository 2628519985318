//employee constants
import {
  RESET_CUSTOMER_BANK_INFO,
  CUSTOMER_BANK_INFO,
  CUSTOMER_BANK_INFO_SUCCESS,
  CUSTOMER_BANK_INFO_FAILURE,
} from "../../constants/Payment/GetCustomerBankInfo";

const INIT_STATE = {
  bankAccountInfo: {},
  loadingBankAccountInfo: false,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case RESET_CUSTOMER_BANK_INFO:
      return INIT_STATE;
    case CUSTOMER_BANK_INFO:
      return {
        ...state,
        loadingBankAccountInfo: true,
      };
    case CUSTOMER_BANK_INFO_SUCCESS:
      if (action.payload.ok) {
        localStorage.setItem("bankAccountDetails", action?.payload?.payload);
        return {
          ...state,
          loadingBankAccountInfo: false,
          bankAccountInfo: action?.payload?.payload,
        };
      }
    // eslint-disable-next-line no-fallthrough
    case CUSTOMER_BANK_INFO_FAILURE:
      localStorage.removeItem("bankAccountDetails");
      return {
        ...state,
        loadingBankAccountInfo: false,
        bankAccountInfo: "Error in  getting Club Data",
      };
    default:
      return state;
  }
};
