//employee constants
import {
  RESET_CUSTOMER_USER_AND_COMPANY_PROFILE,
  CUSTOMER_USER_AND_COMPANY_PROFILE,
  CUSTOMER_USER_AND_COMPANY_PROFILE_SUCCESS,
  CUSTOMER_USER_AND_COMPANY_PROFILE_FAILURE,
} from "../../constants/Settings/CustomerUser&CompanyProfileData";

const INIT_STATE = {
  customerUserandCompanyProfileData: {},
  loadingCustomeruserandCompanyProfileData: false,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case RESET_CUSTOMER_USER_AND_COMPANY_PROFILE:
      return INIT_STATE;

    case CUSTOMER_USER_AND_COMPANY_PROFILE:
      return {
        ...state,
        loadingCustomeruserandCompanyProfileData: true,
      };
    case CUSTOMER_USER_AND_COMPANY_PROFILE_SUCCESS:
      return {
        ...state,
        loadingCustomeruserandCompanyProfileData: false,
        customerUserandCompanyProfileData: action.payload,
      };
    // eslint-disable-next-line no-fallthrough
    case CUSTOMER_USER_AND_COMPANY_PROFILE_FAILURE:
      return {
        ...state,
        loadingCustomeruserandCompanyProfileData: false,
        customerUserandCompanyProfileData: "Error in  getting Club Data",
      };
    default:
      return state;
  }
};
