import React from "react";
import { Helmet } from "react-helmet";

import CustomerSetting from "./Setting";
import AdminSetting from "components/Screens/Setting";

import { usePermissions } from "components/Hook";

function index(props) {
  const { loggedInUserType } = usePermissions();
  return (
    <>
      <Helmet>
        <title>Edge | Settings</title>
      </Helmet>
      {loggedInUserType && loggedInUserType == "edge" ? <AdminSetting /> : <CustomerSetting />}
    </>
  );
}

export default index;
