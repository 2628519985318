import React, { useState, useEffect, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";

import InfoSection from "components/common/InfoSection";
import EmployeePersonalInfo from "./PersonalDetails/EmployeePersonalInfo";
import EmployeeContactInfo from "./ContactDetails/EmployeeContactInfo";
import EmployeeEmergencyContact from "./EmergencyDetails/EmployeeEmergencyContact";
import PageLoader from "components/common/PageLoader";
import EmployeeAboutModal from "./AboutModal";

import "./About.scss";

import { GetEmployeeById } from "store/actions/EmployeePortal/GetEmployeeDetials";

function EmployeeAbout() {
  const dispatch = useDispatch();

  const [openModal, setModalOpen] = useState(false);
  const [modalType, setModalType] = useState("");
  const [countryStates, setCountryStates] = useState([]);
  const countriesList = useMemo(() => JSON.parse(sessionStorage.getItem("dropdownData"))?.payload?.country || [], []);
  const employeeDetails = useSelector((state) => state.GetEmployeeDetials.employeeDetails);
  const loadingGetEmployeeDetails = useSelector((state) => state.GetEmployeeDetails.loadingGET_EMPLOYEE_Details);

  const employeeCountry = employeeDetails?.country;
  const employeeID = employeeDetails?.employeeId;

  const refreshEmployeeInfo = async () => {
    dispatch(
      GetEmployeeById({
        employeeId: employeeID,
      })
    );
  };

  useEffect(() => {
    const country = countriesList.find((c) => c.countryCode?.toLowerCase() === employeeCountry?.toLowerCase());

    if (country && country.states) {
      setCountryStates(
        country.states.map((state) => ({
          value: state.name,
          label: state.name,
        }))
      );
    }
  }, [countriesList, employeeCountry]);

  return (
    <div className="mb-3 about-container">
      {loadingGetEmployeeDetails && <PageLoader />}

      <div>
        <InfoSection
          header="Personal"
          position="top"
          editIcon
          onEdit={() => {
            setModalType("Personal");
            setModalOpen(true);
          }}
          noBottomBorder
        >
          <EmployeePersonalInfo employeeDetails={employeeDetails} />
        </InfoSection>
        <InfoSection
          header="Contact"
          position="middle"
          editIcon
          onEdit={() => {
            setModalType("Contact");
            setModalOpen(true);
          }}
          noBottomBorder
        >
          <EmployeeContactInfo employeeDetails={employeeDetails} />
        </InfoSection>
        <InfoSection
          header="Emergency Contact"
          position="bottom"
          editIcon
          onEdit={() => {
            setModalType("Emergency Contact");
            setModalOpen(true);
          }}
        >
          <EmployeeEmergencyContact employeeDetails={employeeDetails} />
        </InfoSection>
      </div>
      <EmployeeAboutModal
        data={employeeDetails}
        onUpdate={refreshEmployeeInfo}
        countryStates={countryStates}
        openModal={openModal}
        setOpenModal={setModalOpen}
        type={modalType}
      />
    </div>
  );
}

export default EmployeeAbout;
