import { useCallback, useEffect, useRef, useState } from "react";
import { TextField, InputAdornment } from "@mui/material";

import LeaveCard from "components/Screens/Timeoff/ManageTimeoff/Customer/LeaveCard";
import { useSelector } from "react-redux";

const TakenLeaves = () => {
  const ref = useRef(null);

  const takenLeaveRequests = useSelector((state) => state?.TimeoffLeaveRequests?.leaveRequests?.taken || []);

  const [filteredLeaveRequests, setFilteredLeaveRequests] = useState(takenLeaveRequests);
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    setFilteredLeaveRequests(takenLeaveRequests);
  }, [takenLeaveRequests]);

  const handleSearchEmployee = useCallback((event) => {
    const newSearchTerm = event.target.value;
    setSearchTerm(newSearchTerm);

    setFilteredLeaveRequests(() => {
      const tempLeaveReq = takenLeaveRequests.filter((req) =>
        req.employeeName?.toLowerCase()?.includes(newSearchTerm?.toLowerCase())
      );
      return tempLeaveReq;
    });
  }, []);

  useEffect(() => {
    if (ref.current) {
      ref.current.focus();
    }
  }, [filteredLeaveRequests]);

  const SearchComponent = () => {
    return (
      <TextField
        inputRef={ref}
        type="search"
        id="search"
        value={searchTerm}
        onChange={handleSearchEmployee}
        placeholder="Search by employees"
        sx={{
          ".MuiInputBase-root": {
            borderRadius: "99px",
            borderColor: "#BFC2FF",
            outlineColor: "#BFC2FF",
            fontFamily: "Poppins-Regular",
            fontSize: "14px",
            lineHeight: "20px",
            paddingRight: "6px",
            marginTop: "9px",
            border: "0.4px solid #BFC2FF",
          },
        }}
        InputProps={{
          disableUnderline: true,
          endAdornment: (
            <InputAdornment position="start">
              <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M14.9066 13.6148L19.8274 18.5355L18.5356 19.8272L13.6149 14.9065C12.3775 15.8684 10.8227 16.4412 9.13409 16.4412C5.09844 16.4412 1.8269 13.1696 1.8269 9.13397C1.8269 5.09832 5.09844 1.82678 9.13409 1.82678C13.1697 1.82678 16.4413 5.09832 16.4413 9.13397C16.4413 10.8226 15.8685 12.3774 14.9066 13.6148ZM9.13409 14.6144C12.1608 14.6144 14.6145 12.1607 14.6145 9.13397C14.6145 6.10724 12.1608 3.65358 9.13409 3.65358C6.10736 3.65358 3.6537 6.10724 3.6537 9.13397C3.6537 12.1607 6.10736 14.6144 9.13409 14.6144Z"
                  fill="#00201C"
                />
              </svg>
            </InputAdornment>
          ),
        }}
      />
    );
  };

  const NoDataCard = (_) => {
    return (
      <div className="no-card-container">
        <p className="no-card-text">No Taken time-off</p>
      </div>
    );
  };

  return (
    <div>
      {takenLeaveRequests?.length ? <p>Total taken leaves : {takenLeaveRequests?.length}</p> : <p>No taken time-off</p>}
      {<SearchComponent />}
      <div className="mt-1">
        {filteredLeaveRequests?.length ? (
          <div className="flex flex-col gap-075">
            {filteredLeaveRequests.map((leave) => (
              <LeaveCard type={"cancelled"} data={leave} showProfileSection={true} />
            ))}
          </div>
        ) : (
          <NoDataCard />
        )}
      </div>
    </div>
  );
};

export default TakenLeaves;
