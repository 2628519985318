import { useState, useEffect, useLayoutEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { BsCreditCard2Back } from "react-icons/bs";
//material ui
import { Box, Grid, Typography } from "@mui/material";
//widget
import DataTable from "components/Widgets/DataTable";
import AmountDetails from "components/Screens/AmountDetails.js";
import ProfleWithIcon from "components/Widgets/ProfileWithIcon";
import {
  convertDate,
  getCurrencySymbol,
  currencyFormat,
  getValue,
  getPaymentStatus,
  checkUserAdditionalInfo,
} from "components/Screens/CommonUtils";
import PageHeader from "components/Screens/PageHeader";
//icons
import AvatarIcon from "components/Icons/AvatarIcon";
//redux dispatch
import { baseUrl } from "util/APIBaseUrl";
import { GetNoticeBoard } from "store/actions/DashBoard/GetNoticeBoard";
import { usePermissions } from "components/Hook";

//constants
function PaymentBreakDown(props) {
  const {
    paymentDetail,
    selectedOptions,
    selectedFiltersList,
    resetPage,
    customerPayment,
    openDetailsPage,
    handlePagination,
    handleSelectedRow,
    navigatedFrom,
    salariesPage,
    toolBar,
    filterButton,
    SearchEmployee,
    title,
    handleRequestSortBy,
    ExportButton,
    datePicker,
  } = props;

  const dispatch = useDispatch();
  const navigate = useNavigate();

  //component state
  const [tableRows, setTableRows] = useState([]);
  const [tableHeader, setTableHeader] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [sortBy, setSortBy] = useState({});
  const [page, setPage] = useState(0);

  //Notices Table
  const [noticesTableRows, setnoticesTableRows] = useState([]);

  const noticeBoard = useSelector((state) => state.GetNoticeBoard.noticeBoard);

  useEffect(() => {
    if (noticeBoard?.ok) {
      const formattedNotices = noticeBoard?.payload?.rows.map((notice) => ({
        id: notice.id,
        customerName: (
          <Typography
            variant="h3"
            onClick={() => navigate(`/main/customer/details/${notice?.customer?.id}`)}
            style={{ cursor: "pointer" }}
          >
            {notice?.customer?.name}
          </Typography>
        ),

        noticeText: notice?.notice,
        date: convertDate(notice.date),
      }));

      setnoticesTableRows(formattedNotices || []);
    }
  }, [noticeBoard]);

  // Define the table headers for the notices
  const noticeTableHeaders = [
    {
      id: "customerName",
      numeric: false,
      disablePadding: true,
      sort: false,
      label: (
        <Typography variant="h5" fontSize="14px">
          Customer Name
        </Typography>
      ),
    },
    {
      id: "noticeText",
      numeric: false,
      disablePadding: true,
      sort: false,
      label: (
        <Typography variant="h5" fontSize="14px">
          Notice
        </Typography>
      ),
    },
    {
      id: "date",
      numeric: false,
      disablePadding: true,
      sort: false,
      label: (
        <Typography variant="h5" fontSize="14px">
          Date
        </Typography>
      ),
    },
  ];

  //functions

  const handleRowsPerPage = (pagination) => {
    handlePagination(pagination);
  };

  const handleRequestNoticesSortBy = (request) => {
    setSortBy(request);
  };

  const handleNoticesRowsPerPage = (pagination) => {
    setRowsPerPage(pagination.rowsPerPage);
    setPage(pagination.page);
  };

  const userAdditionalInfo = useSelector((state) => state.UserAdditionalInfo.userAdditionalInfo);
  const { loggedInUserType } = usePermissions();

  //methods
  useEffect(() => {
    let Data = [];

    if (customerPayment != undefined && customerPayment?.rows?.length) {
      customerPayment.rows.map((employee, index) => {
        Data.push({
          id: employee.employeeId,
          Profile_Picture: (
            <Box display="flex" alignItems="flex-end" justifyContent="flex-end" ml={0} pl={0} sx={{ ml: 0, pl: 0 }}>
              <AvatarIcon
                shape={"circle"}
                size={{
                  width: "8vh",
                  height: "8vh",
                  borderRadius: "50%",
                }}
                source={`${baseUrl}/employee/${employee?.employeeId}/logo?id=${employee?.pictureVersion}&variant=thumbnail`}
              />
            </Box>
          ),
          employeeName: (
            <ProfleWithIcon
              type="textOnly"
              data={{
                name: getValue(employee.firstName) + " " + getValue(employee.lastName),
                role: getValue(employee.jobTitle),
              }}
            />
          ),
          companyName: <Typography variant="h3">{getValue(employee.companyName)}</Typography>,

          costToCompany: (
            <Typography variant="h3">
              {getCurrencySymbol("en-US", "USD", currencyFormat(getValue(employee?.costToCompany)))}
            </Typography>
          ),
          platformFee: (
            <Typography variant="h3">
              {getCurrencySymbol("en-US", "USD", currencyFormat(getValue(employee?.platformFee)))}
            </Typography>
          ),
          grossPay: (
            <Typography variant="h3">
              {getCurrencySymbol("en-US", "USD", currencyFormat(getValue(employee?.grossSalary)))}
            </Typography>
          ),
          taxes: (
            <Typography variant="h3">
              {getCurrencySymbol("en-US", "USD", currencyFormat(getValue(employee?.tax)))}
            </Typography>
          ),
          netPay: (
            <Typography variant="h3">
              {getCurrencySymbol("en-US", "USD", currencyFormat(getValue(employee?.netSalary)))}
            </Typography>
          ),
          bonus: (
            <Typography variant="h3">
              {getCurrencySymbol(
                "en-US",
                "USD",
                currencyFormat(
                  getValue(
                    employee?.incrementDetails?.bonus
                      ? employee?.incrementDetails?.bonus
                      : employee?.bonus
                        ? employee?.bonus
                        : "-"
                  )
                )
              )}
            </Typography>
          ),

          status: (
            <Typography variant="h3">{getValue(getPaymentStatus(employee?.status, employee?.failure))}</Typography>
          ),

          date: <Typography variant="h3">{convertDate(employee.startDate)}</Typography>,
          country: <Typography variant="h3">{getValue(employee.country)}</Typography>,
        });
      });
    }

    setTableRows(Data);
  }, [customerPayment]);
  const [selectedtab, setSelectedtab] = useState("Overview");

  //function
  const selectPageHeading = (opt) => {
    setSelectedtab(opt);
  };

  const tabHeadings = ["Overview", "Important Notices"];

  useEffect(() => {
    if (selectedtab === "Important Notices") {
      if (checkUserAdditionalInfo(userAdditionalInfo)) {
        let request;
        if (loggedInUserType == "edge") {
          request = `customers/noticeboard`;
        } else if (loggedInUserType == "customer") {
          request = `customer/${userAdditionalInfo?.customerId}/noticeboard`;
        }
        dispatch(
          GetNoticeBoard({
            request: request,
            rowsPerPage: rowsPerPage,
            page: page,
            sortBy: {
              field: sortBy.orderBy,
              direction: sortBy.order,
            },
          })
        );
      }
    }
  }, [selectedtab, userAdditionalInfo, loggedInUserType, rowsPerPage, page, sortBy]);

  useLayoutEffect(() => {
    let tableheadCells = [
      {
        id: "Profile_Picture",
        mobileView: true,
        label: "",
        align: "right",
        width: "20px,",
        sort: false,
      },
      {
        id: "employeeName",
        numeric: false,
        wrapCell: true,
        width: "230px",
        disablePadding: false,
        label: (
          <Typography variant="h5" fontSize="14px">
            Employee Name
          </Typography>
        ),
      },
      {
        id: "costToCompany",
        numeric: false,
        disablePadding: false,
        label: (
          <Typography variant="h5" fontSize="14px">
            Cost To Company
          </Typography>
        ),
      },
      {
        id: "platformFee",
        numeric: false,
        disablePadding: false,
        label: (
          <Typography variant="h5" fontSize="14px">
            Platform Fee
          </Typography>
        ),
      },
      {
        id: "grossPay",
        numeric: false,
        disablePadding: false,
        sort: false,
        label: (
          <Typography variant="h5" fontSize="14px">
            Gross Pay
          </Typography>
        ),
      },

      {
        id: "taxes",
        numeric: false,
        disablePadding: false,
        sort: false,
        label: (
          <Typography variant="h5" fontSize="14px">
            Taxes
          </Typography>
        ),
      },
      {
        id: "netPay",
        numeric: false,
        disablePadding: false,
        sort: false,
        // width: "250px",
        label: (
          <Typography variant="h5" fontSize="14px">
            Net Pay
          </Typography>
        ),
      },
      {
        id: "bonus",
        numeric: false,
        disablePadding: false,
        label: (
          <Typography variant="h5" fontSize="14px">
            Bonus
          </Typography>
        ),
      },
      // {
      //     id: "date",
      //     numeric: true,
      //     disablePadding: false,
      //     label: (
      //         <Typography variant="h5" fontSize="14px" pl="1.6rem">
      //             Start Date
      //         </Typography>
      //     ),
      // },
    ];
    const companyName = {
      id: "companyName",
      numeric: false,
      disablePadding: false,
      wrapCell: true,
      width: "230px",
      label: (
        <Typography variant="h5" fontSize="14px">
          Company Name
        </Typography>
      ),
    };

    const status = {
      id: "status",
      numeric: false,
      disablePadding: false,
      label: (
        <Typography variant="h5" fontSize="14px">
          Status
        </Typography>
      ),
    };

    const startDate = {
      id: "date",
      numeric: true,
      disablePadding: false,
      label: (
        <Typography variant="h5" fontSize="14px">
          Start Date
        </Typography>
      ),
    };

    const country = {
      id: "country",
      numeric: false,
      disablePadding: false,
      label: (
        <Typography variant="h5" fontSize="14px">
          Employee Country
        </Typography>
      ),
    };

    if (salariesPage) {
      tableheadCells.splice(2, 0, companyName);
      tableheadCells.splice(3, 0, country);
      tableheadCells.splice(10, 0, status);
    } else {
      tableheadCells.splice(8, 0, startDate);
    }
    setTableHeader(tableheadCells);
  }, []);

  return (
    <Grid item container direction="row" rowSpacing={3} columnSpacing={3}>
      {salariesPage && (
        <PageHeader pageHeadings={tabHeadings} selectPageHeading={selectPageHeading} selectedOption={selectedtab} />
      )}
      {selectedtab == "Overview" && (
        <Grid item container direction="row" lg={12} md={12} sm={12} xs={12} rowSpacing={3} columnSpacing={3}>
          {salariesPage && (
            <Grid item lg={salariesPage ? 6 : 12} md={12} sm={12} xs={12}>
              <AmountDetails
                details={[
                  {
                    title: "Payment Recieved",
                    amount: getCurrencySymbol(
                      "en-US",
                      "USD",
                      currencyFormat(getValue(userAdditionalInfo?.totalPayrollReceived))
                    ),
                  },
                  {
                    title: "Total Payroll(Active Customers)",
                    amount: getCurrencySymbol(
                      "en-US",
                      "USD",
                      currencyFormat(getValue(userAdditionalInfo?.totalPayroll))
                    ),

                    icon: <BsCreditCard2Back color="#080D46" fontSize="30px" />,
                  },
                ]}
              />
            </Grid>
          )}
          <Grid item lg={salariesPage ? 6 : 12} md={12} sm={12} xs={12}>
            <AmountDetails
              details={[
                {
                  title: salariesPage ? "Total Payroll(All Customers)" : "Total Payroll",
                  amount: getCurrencySymbol(
                    "en-US",
                    "USD",
                    currencyFormat(getValue(salariesPage ? customerPayment.totalPayroll : paymentDetail?.totalPayroll))
                  ),
                },
                {
                  title: "Total Bonus",
                  amount: getCurrencySymbol(
                    "en-US",
                    "USD",
                    currencyFormat(getValue(salariesPage ? customerPayment.totalBonus : paymentDetail?.totalBonus))
                  ),
                },
                {
                  title: "Employees",
                  amount: currencyFormat(
                    getValue(salariesPage ? customerPayment.totalEmployees : paymentDetail?.totalEmployees)
                  ),
                },
                !salariesPage && {
                  title:
                    navigatedFrom == "paymentDetails"
                      ? paymentDetail?.status == "paid"
                        ? "Paid On"
                        : "Payment Date"
                      : "Payment Date",

                  amount:
                    navigatedFrom == "paymentDetails"
                      ? paymentDetail?.paymentStatus?.status == "paid"
                        ? convertDate(paymentDetail?.paidOn)
                        : convertDate(paymentDetail?.paymentDate)
                      : convertDate(paymentDetail?.paymentDate),
                },
              ]}
            />
          </Grid>
        </Grid>
      )}
      {selectedtab == "Important Notices" && salariesPage && (
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <DataTable
            rowsData={noticesTableRows}
            tableheadCells={noticeTableHeaders}
            title={`Important Notices (${noticeBoard?.payload?.count || 0})`}
            totalCount={noticeBoard?.payload?.count || 0}
            handleRowsPerPage={handleNoticesRowsPerPage}
            handleSelectedRow={() => console.log("row selected")}
            handleRequestSortBy={handleRequestNoticesSortBy}
          />
        </Grid>
      )}

      {selectedFiltersList ? selectedFiltersList : null}
      {selectedtab == "Overview" && (
        <Grid item xs={12}>
          <DataTable
            rowsData={tableRows}
            resetPage={resetPage}
            totalCount={customerPayment?.count || 0}
            tableheadCells={tableHeader}
            title={title ? title : null}
            toolBar={toolBar ? toolBar : false}
            filterButton={filterButton ? filterButton : null}
            datePicker={datePicker ? datePicker : null}
            SearchEmployee={SearchEmployee ? SearchEmployee : null}
            subTitle={null}
            selectedOptions={selectedOptions}
            openDetailsPage={openDetailsPage}
            // toolBar={false}
            slectedRowNavigation={"/main/details"}
            handleRowsPerPage={handleRowsPerPage}
            handleSelectedRow={handleSelectedRow}
            handleRequestSortBy={handleRequestSortBy ? handleRequestSortBy : null}
            sortByName={"employeeName"}
            AddNewRowButton={ExportButton ? ExportButton : null}
          />
        </Grid>
      )}
    </Grid>
  );
}

export default PaymentBreakDown;
