import React from "react";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";

import "./sectionLoader.css";

export default function SectionLoader({ size }) {
  return (
    <Box className="sectionLoader">
      <CircularProgress size={size} />
    </Box>
  );
}
