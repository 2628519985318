import {
  RESER_EMPLOYEE_SALARY_DETAILS,
  EMPLOYEE_SALARY_DETAILS,
  EMPLOYEE_SALARY_DETAILS_SUCCESS,
  EMPLOYEE_SALARY_DETAILS_FAILURE,
} from "../../../constants/Workforce/EmployeeDetils/SalaryDetails";

// for user authentication
export const ResetemployeeSalaryDetails = (data) => {
  return {
    type: RESER_EMPLOYEE_SALARY_DETAILS,
    payload: data,
  };
};

export const employeeSalaryDetails = (data) => {
  return {
    type: EMPLOYEE_SALARY_DETAILS,
    payload: data,
  };
};
export const employeeSalaryDetailsSuccess = (payload) => {
  return {
    type: EMPLOYEE_SALARY_DETAILS_SUCCESS,
    payload,
  };
};
export const employeeSalaryDetailsFailure = (payload) => {
  return {
    type: EMPLOYEE_SALARY_DETAILS_FAILURE,
    payload,
  };
};
