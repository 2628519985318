import React, { useState, useEffect } from "react";

import PropTypes from "prop-types";

import { Snackbar, IconButton } from "@mui/material";

import "./Snackbar.scss";

import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

export const AlertType = {
  success: "success",
  error: "error",
};

export default function CustomSnackbar({ message, label, duration, onClose, type, open }) {
  const [isVisible, setIsVisible] = useState(open);

  useEffect(() => {
    setIsVisible(open);
  }, [open]);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setIsVisible(false);
    if (typeof onClose === "function") {
      onClose();
    }
  };

  const snackbarClass = type === AlertType.error ? "snackbar-error" : "snackbar-success";

  const snackbarContent = (
    <div className={`snackbar box-shadow-lg flex justify-between items-center gap-1 px-1 ${snackbarClass}`}>
      <div className="snackbar-content gap-05">
        {type === AlertType.error ? (
          <InfoOutlinedIcon className="snackbar-alert-icon" />
        ) : (
          <CheckIcon className="snackbar-icon" />
        )}
        <span className={`${type == AlertType.error ? "alert-description" : "para-body-m-regular"}`}>{message}</span>
      </div>
      <div className="snackbar-actions gap-05">
        <span className="button-small">{label}</span>
        <IconButton onClick={handleClose} className="snackbar-close-button" aria-label="close" size="small">
          <CloseIcon className={`${type == AlertType.error ? "snackbar-alert-close-icon" : "snackbar-close-icon"}`} />
        </IconButton>
      </div>
    </div>
  );

  return (
    <Snackbar
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
      open={isVisible}
      autoHideDuration={duration}
      onClose={handleClose}
      className="snackbar-container"
    >
      {isVisible && snackbarContent}
    </Snackbar>
  );
}

CustomSnackbar.propTypes = {
  message: PropTypes.string.isRequired,
  label: PropTypes.string,
  duration: PropTypes.number,
  onClose: PropTypes.func,
  type: PropTypes.oneOf(Object.values(AlertType)),
  open: PropTypes.bool.isRequired,
};

CustomSnackbar.defaultProps = {
  label: "",
  duration: 3000,
  type: AlertType.success,
  onClose: () => {},
};
