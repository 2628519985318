//employee constants
import {
    RESET_UPLOAD_LOGO,
    UPLOAD_LOGO,
    UPLOAD_LOGO_SUCCESS,
    UPLOAD_LOGO_FAILURE,
} from "../../constants/Logo/uploadLogo";

const INIT_STATE = {
    uploadLogo: {},
    uploadingLogo: true,
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case RESET_UPLOAD_LOGO:
            return INIT_STATE;
        case UPLOAD_LOGO:
            return {
                ...state,
                uploadingLogo: true,
            };
        case UPLOAD_LOGO_SUCCESS:
            return {
                ...state,
                uploadingLogo: false,
                uploadLogo: action.payload,
            };
        // eslint-disable-next-line no-fallthrough
        case UPLOAD_LOGO_FAILURE:
            return {
                ...state,
                uploadingLogo: false,
                uploadLogo: "Error in  getting Club Data",
            };
        default:
            return state;
    }
};
