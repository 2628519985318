import React from "react";

function NegitiveIcon(props) {
  return (
    <svg width="31" height="31" viewBox="0 0 31 31" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="31" height="31" rx="15.5" fill="#F1EFFF" />
      <rect width="13" height="13" transform="translate(9 9)" fill="#F1EFFF" />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M20.9173 14.9583V16.0416H10.084V14.9583H20.9173Z"
        fill="#131599"
      />
    </svg>
  );
}

export default NegitiveIcon;
