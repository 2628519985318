import { call, put, takeEvery } from "redux-saga/effects";
import { revokeAccessTokenSuccess, revokeAccessTokenFailed } from "../../actions/Payoneer/PayoneerRevokeAccessToken";
import { baseUrl } from "util/APIBaseUrl";
import { PAYONEER_REVOKE_ACCESS_TOKEN_FETCH } from "store/constants/Payoneer/PayoneerRevokeAccessToken";
import getRequest from "util/APIHelperGet";
import { fetchConsent } from "store/actions/Payoneer/PayoneerGetEmployeeConsent";
import { resetBalance } from "store/actions/Payoneer/PayoneerGetEmployeeBalance";

const revokeAccessTokenFromAPI = async () => {
  return await getRequest(`${baseUrl}/payoneer/revoke-access-token`)
    .then((response) => response)
    .catch((error) => error);
};

function* revokeAccessTokenSaga() {
  const revokeInfo = yield call(revokeAccessTokenFromAPI);
  if (revokeInfo?.ok) {
    yield put(revokeAccessTokenSuccess());
    yield put(fetchConsent());
    yield put(resetBalance());
  } else {
    yield put(revokeAccessTokenFailed(revokeInfo?.payload?.error[0]));
  }
}

export default function* rootSaga() {
  yield takeEvery(PAYONEER_REVOKE_ACCESS_TOKEN_FETCH, revokeAccessTokenSaga);
}
