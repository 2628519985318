import { call, put, takeEvery } from "redux-saga/effects";
//actions
import {
  AssignCustomertoEmployeeSuccess,
  AssignCustomertoEmployeeFailure,
} from "../../actions/Customers/AssignCustomertoEmployee";
//constant
import { ASSIGN_CUSTOMER_TO_EMPLOYEE } from "../../constants/Customers/AssignCustomertoEmployee";
//request type
import postRequest from "../../../util/APIHelperPost";
//base url
import { baseUrl } from "../../../util/APIBaseUrl";

export const fetchAssignCustomerToEmployeeRequest = async (actions) => {
  let body = JSON.stringify({
    edgeEmployeeId: actions.payload.edgeEmployeeId,
  });

  return await postRequest(
    `${baseUrl}/customer/${actions.payload.customerId}/assign`,
    body
  )
    // return await getRequest(
    //     `${baseUrl}/customer/${actions.payload.customerId}/assign`
    // )

    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

function* fetchAssignCustomerToEmployee(params) {
  try {
    let response = yield call(fetchAssignCustomerToEmployeeRequest, params);

    yield put(AssignCustomertoEmployeeSuccess(response));
  } catch (error) {
    console.error(error);
    yield put(AssignCustomertoEmployeeFailure(error));
  }
}

export default function* rootSaga() {
  yield takeEvery(ASSIGN_CUSTOMER_TO_EMPLOYEE, fetchAssignCustomerToEmployee);
}
