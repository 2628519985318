import {
  RESER_GET_PAYMENT_DETAILS,
  GET_PAYMENT_DETAILS,
  GET_PAYMENT_DETAILS_SUCCESS,
  GET_PAYMENT_DETAILS_FAILURE,
} from "../../constants/Payroll/GetPaymentDetails";

// for user authentication
export const ResetGetPaymentDetails = (data) => {
  return {
    type: RESER_GET_PAYMENT_DETAILS,
    payload: data,
  };
};

export const GetPaymentDetails = (data) => {
  return {
    type: GET_PAYMENT_DETAILS,
    payload: data,
  };
};

export const GetPaymentDetailsSuccess = (payload) => {
  return {
    type: GET_PAYMENT_DETAILS_SUCCESS,
    payload,
  };
};

export const GetPaymentDetailsFailure = (payload) => {
  return {
    type: GET_PAYMENT_DETAILS_FAILURE,
    payload,
  };
};
