import {
  PAYONEER_GET_EMPLOYEE_REGISTRATION_LINK_FAILED,
  PAYONEER_SET_EMPLOYEE_REGISTRATION_LINK,
} from "store/constants/Payoneer/PayoneerGetEmployeeRegistrationLink";

const initialState = {
  registrationInfo: {
    registrationLink: null,
    error: null,
    isLoading: true,
  },
};

const registrationLinkReducer = (state = initialState, action) => {
  switch (action.type) {
    case PAYONEER_SET_EMPLOYEE_REGISTRATION_LINK:
      return {
        ...state,
        registrationInfo: {
          registrationLink: action.payload,
          error: null,
          isLoading: false,
        },
      };
    case PAYONEER_GET_EMPLOYEE_REGISTRATION_LINK_FAILED:
      return {
        ...state,
        registrationInfo: {
          registrationLink: null,
          error: action.payload,
          isLoading: false,
        },
      };
    default:
      return state;
  }
};

export default registrationLinkReducer;
