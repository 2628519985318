import * as Yup from "yup";

export default Yup.object().shape({
  schoolName: Yup.string().required("School is required"),
  degreeName: Yup.string().required("Degree is required"),
  fieldOfStudy: Yup.string().required("Field Of Study is required"),
  startDate: Yup.date().required("Start Date is required").typeError("Invalid date format"),
  endDate: Yup.date()
    .required("End Date is required")
    .typeError("Invalid date format")
    .test("is-greater", "End date cannot be earlier than start date", function (value) {
      const { startDate } = this.parent;
      return !startDate || !value || value >= startDate;
    }),
});
