import React, { useEffect, useRef, useState } from "react";
import { DateRangePicker } from "react-date-range";
import { addDays } from "date-fns";

import { convertDateForDateRange } from "components/Screens/CommonUtils";

import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import "./DropDownDateRange.css";

const DropDownDateRange = ({ style, dropdownStyles, onSelect, dateRange, handlePageChange }) => {
  const dropdownRef = useRef(null);
  const datePickerRef = useRef(null);

  const [isOpen, setIsOpen] = useState(false);
  const [state, setState] = useState([
    {
      startDate: dateRange?.startDate,
      endDate: dateRange?.endDate,
      key: "selection",
    },
  ]);

  const handleSelect = (ranges) => {
    setState([ranges.selection]);
    onSelect(ranges.selection);
    handlePageChange();
  };

  const staticTimeOffDateRange = [
    {
      label: "Today",
      range: () => ({
        startDate: new Date(),
        endDate: new Date(),
      }),
      isSelected: (ranges) => {
        if (!ranges) return false;
        const { startDate, endDate } = ranges;
        const today = new Date();
        return startDate.getTime() === today.getTime() && endDate.getTime() === today.getTime();
      },
    },
    {
      label: "Yesterday",
      range: () => {
        const yesterday = addDays(new Date(), -1);
        return { startDate: yesterday, endDate: yesterday };
      },
      isSelected: (ranges) => {
        if (!ranges) return false;
        const { startDate, endDate } = ranges;
        const yesterday = addDays(new Date(), -1);
        return startDate.getTime() === yesterday.getTime() && endDate.getTime() === yesterday.getTime();
      },
    },
    {
      label: "Last week",
      range: () => {
        const lastWeekStart = addDays(new Date(), -7);
        const lastWeekEnd = addDays(new Date(), -1);
        return { startDate: lastWeekStart, endDate: lastWeekEnd };
      },
      isSelected: (ranges) => {
        if (!ranges) return false;
        const { startDate, endDate } = ranges;
        const lastWeekStart = addDays(new Date(), -7);
        const lastWeekEnd = addDays(new Date(), -1);
        return startDate.getTime() >= lastWeekStart.getTime() && endDate.getTime() <= lastWeekEnd.getTime();
      },
    },
    {
      label: "Last month",
      range: () => {
        const lastMonthStart = addDays(new Date(), -30);
        const lastMonthEnd = addDays(new Date(), -1);
        return { startDate: lastMonthStart, endDate: lastMonthEnd };
      },
      isSelected: (ranges) => {
        if (!ranges) return false;
        const { startDate, endDate } = ranges;
        const lastMonthStart = addDays(new Date(), -30);
        const lastMonthEnd = addDays(new Date(), -1);
        return startDate.getTime() >= lastMonthStart.getTime() && endDate.getTime() <= lastMonthEnd.getTime();
      },
    },
    {
      label: "Last quarter",
      range: () => {
        const lastQuarterStart = addDays(new Date(), -90);
        const lastQuarterEnd = addDays(new Date(), -1);
        return { startDate: lastQuarterStart, endDate: lastQuarterEnd };
      },
      isSelected: (ranges) => {
        if (!ranges) return false;
        const { startDate, endDate } = ranges;
        const lastQuarterStart = addDays(new Date(), -90);
        const lastQuarterEnd = addDays(new Date(), -1);
        return startDate.getTime() >= lastQuarterStart.getTime() && endDate.getTime() <= lastQuarterEnd.getTime();
      },
    },
  ];

  const handleClickOutside = (event) => {
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target) &&
      datePickerRef.current &&
      !datePickerRef.current.contains(event.target)
    ) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  return (
    <div className="custom-dropdown" style={style}>
      <button
        ref={dropdownRef}
        className="date-range-button"
        style={{ ...dropdownStyles, alignItems: "center" }}
        onClick={() => setIsOpen(!isOpen)}
      >
        <span>
          {convertDateForDateRange(dateRange?.startDate)} <span style={{ color: "rgba(61, 62, 92, 0.70)" }}>to</span>{" "}
          {convertDateForDateRange(dateRange?.endDate)}
        </span>
      </button>
      {isOpen && (
        <div className="dropdown-list">
          <div className="date-range-picker-wrapper" ref={datePickerRef}>
            <DateRangePicker
              onChange={(item) => handleSelect(item)}
              showSelectionPreview={true}
              moveRangeOnFirstSelection={false}
              months={1}
              ranges={state}
              direction="horizontal"
              staticRanges={staticTimeOffDateRange}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default DropDownDateRange;
