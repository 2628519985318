import { RESET_SIGNUP, SIGNUP, SIGNUP_SUCCESS, SIGNUP_FAILURE } from "../../constants/SignUp/SignUp";

// for user authentication

export const ResetSigunp = (data) => {
  return {
    type: RESET_SIGNUP,
    payload: data,
  };
};

export const Sigunp = (data) => {
  return {
    type: SIGNUP,
    payload: data,
  };
};
export const SigunpSuccess = (payload) => {
  return {
    type: SIGNUP_SUCCESS,
    payload,
  };
};
export const SigunpFailure = (payload) => {
  return {
    type: SIGNUP_FAILURE,
    payload,
  };
};
