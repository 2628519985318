import React from "react";

const GmailIcon = () => {
  return (
    <svg
      width="14"
      height="10"
      viewBox="0 0 14 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_2797_48225)">
        <path
          d="M3.18183 9.95123V4.82636L1.50429 3.37232L0 2.56543V9.04683C0 9.54724 0.42793 9.95123 0.95457 9.95123H3.18183Z"
          fill="#4285F4"
        />
        <path
          d="M10.8181 9.95118H13.0454C13.5736 9.95118 13.9999 9.54569 13.9999 9.04678V2.56543L12.2961 3.48963L10.8181 4.82631V9.95118Z"
          fill="#34A853"
        />
        <path
          d="M3.18188 4.82619L2.95361 2.82376L3.18188 0.907227L7.00005 3.62039L10.8182 0.907227L11.0736 2.72028L10.8182 4.82619L7.00005 7.53935L3.18188 4.82619Z"
          fill="#EA4335"
        />
        <path
          d="M10.8181 0.906927V4.82589L13.9999 2.56496V1.3591C13.9999 0.240709 12.6524 -0.396855 11.7091 0.27387L10.8181 0.906927Z"
          fill="#FBBC04"
        />
        <path
          d="M0 2.56498L1.46338 3.60488L3.18183 4.82591V0.906951L2.29086 0.273894C1.34586 -0.396884 0 0.240733 0 1.35908V2.56493V2.56498Z"
          fill="#C5221F"
        />
      </g>
      <defs>
        <clipPath id="clip0_2797_48225">
          <rect width="14" height="10" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default GmailIcon;
