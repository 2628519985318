import { Link } from "@mui/material";
import "./MissingPaymentMethodBanner.css";

const MissingPaymentMethodBanner = () => {
  return (
    <div className="banner-box">
      <h3 className="missing-payment-method-text">
        It seems you haven't added a payment method yet. To proceed with transactions smoothly,{" "}
        <Link href="/main/payment" className="missing-payment-method-text-blue">
          add your payment method now.
        </Link>
      </h3>
    </div>
  );
};

export default MissingPaymentMethodBanner;
