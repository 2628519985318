import React from "react";

// mui
import { Divider, Grid, IconButton, Tooltip, Typography } from "@mui/material";
import EyeIcon from "../../../../assets/svgs/EyeIcon";
import EditIcon from "../../../../assets/svgs/EditIcon";
import { useParams } from "react-router-dom";
// images and icons
import ContractIcon from "../../../../assets/svgs/ContractIcon";
import BackgroundDocumentsIcon from "../../../../assets/svgs/BackgroundDocumentsIcon";
import getRequest from "../../../../util/APIHelperGet";
import { baseUrl } from "../../../../util/APIBaseUrl";
import PersonalIdIcon from "../../../../assets/svgs/PersonalIdIcon";

const DocumentCard = ({ data, setIsLoading, isEditable = false }) => {
  const params = useParams();
  //function
  const handleExport = async (data) => {
    if (setIsLoading) setIsLoading(true);
    const response = await getRequest(
      `${baseUrl}/employees/${params?.id}/document/${data?.documentId}`,

      true,
      "export"
    );

    if (!response?.ok) {
      if (setIsLoading) setIsLoading(false);
      return;
    }

    const url = window.URL.createObjectURL(response?.payload);
    const link = document.createElement("a");
    link.style.display = "none";
    link.href = url;

    const documentName =
      data?.documentExtension && data?.documentExtension !== "null"
        ? `${data?.documentName}${data?.documentExtension}`
        : data?.documentName;

    link.download = documentName;
    if (setIsLoading) setIsLoading(false);
    document.body.appendChild(link);
    link.click();
    window.URL.revokeObjectURL(url);
  };
  return (
    <Grid
      item
      sx={{
        borderRadius: "20px",
        boxShadow: !isEditable ? "inset 0px 0.853633px 3.41453px rgba(2, 0, 110, 0.08)" : "none",
        backgroundColor: "#FFFFFF",
      }}
      style={{}}
    >
      <Grid
        sx={{
          display: "flex",
          justifyContent: "center",
          padding: "16px 0px",
        }}
      >
        <IconButton onClick={() => data?.documentAdded !== false && handleExport(data)}>
          {data?.type === "application/pdf" ? <BackgroundDocumentsIcon /> : <PersonalIdIcon />}
        </IconButton>
      </Grid>
      <Grid
        sx={{
          display: "felx",
          justifyContent: "center",
          padding: "6px 16px",
        }}
      >
        <Tooltip title={data?.documentName}>
          <Typography
            sx={{
              fontFamily: "Poppins-Medium",
              fontSize: "12px",
              fontWeight: 500,
              lineHeight: "16px",
              color: "#212231",
              textAlign: "center",
              height: "38px",
              cursor: "pointer",
            }}
            className="customer-employee-docs"
            onClick={() => data?.documentAdded !== false && handleExport(data)}
          >
            {data?.documentAdded === false ? "No Document Found" : data?.documentName}
          </Typography>
        </Tooltip>
      </Grid>
      {/* {isEditable && ( */}
      {/* <Grid sx={{ backgroundColor: "#FBFAFF", padding: "6px 12px" }}>
        <Typography
          sx={{
            fontFamily: "Poppins-Regular",
            fontSize: "11px",
            fontWeight: 400,
            lineHeight: "14px",
            color: "#212231",
            textAlign: "center",
            whiteSpace: "nowrap",
          }}
        >
          {data?.type}
        </Typography>
      </Grid> */}
      {/* )} */}

      {/* {data?.documentName && (
        <Divider sx={{ margin: "12px 0px 0px", backgroundColor: "#D9DAF2" }} />
      )} */}
      <Grid sx={{ display: "flex", justifyContent: "center" }}>
        <Grid sx={{ padding: "8px 12px", width: "100%" }}>
          {/* {isEditable && data.documentAdded ? (
            <Grid
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-around",
              }}
            >
              <Typography
                sx={{
                  fontFamily: "Poppins-Regular",
                  fontSize: "12px",
                  lineHeight: "16px",
                  color: "#212231",
                  display: "flex",
                  alignItems: "center",
                  gap: "6px",
                }}
              >
                <EyeIcon />
                View
              </Typography>
              <Typography
                sx={{
                  fontFamily: "Poppins-Regular",
                  fontSize: "12px",
                  lineHeight: "16px",
                  color: "#212231",
                  display: "flex",
                  alignItems: "center",
                  gap: "6px",
                }}
              >
                <EditIcon />
                Edit
              </Typography>
            </Grid>
          ) : !data?.documentName ? (
            <></>
          ) : (
            <Typography
              sx={{
                fontFamily: "Poppins-Regular",
                fontSize: "11px",
                fontWeight: 400,
                lineHeight: "14px",
                color: "#212231",
                textAlign: "center",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: "100%",
                cursor: "pointer",
              }}
              onClick={() => handleExport(data)}
            >
              <IconButton sx={{ mr: "5px" }}>
                <EyeIcon />
              </IconButton>
              Download
            </Typography>
          )} */}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default DocumentCard;
