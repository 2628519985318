import { IconButton, Typography, Tooltip, Button, Dialog, DialogContent } from "@mui/material";
import WestIcon from "@mui/icons-material/West";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import vivoBlackLogo from "../../../assets/images/vivoBlackLogo.svg";
import CreateOutlinedIcon from "@mui/icons-material/CreateOutlined";
import moment from "moment";

export const VivoSideBar = ({
  activeThreadId,
  conversations,
  onNewChat,
  onConversationClick,
  handleOpenConfirmationModal,
  isSidebarExpanded,
  toggleSidebar,
  threadToDelete,
  openConfirmationModal,
  handleCloseConfirmationModal,
  deactivateThread,
}) => {
  const categorizeConversations = (conversations) => {
    const categorized = {
      today: [],
      yesterday: [],
      last7Days: [],
      previous: [],
    };

    const today = moment().startOf("day");
    const yesterday = moment().subtract(1, "days").startOf("day");
    const last7Days = moment().subtract(7, "days").startOf("day");

    conversations.forEach((conversation) => {
      const lastMessageDate = moment(conversation.messages[conversation.messages.length - 1]?.timestamp);
      if (lastMessageDate.isSame(today, "d")) {
        categorized.today.push(conversation);
      } else if (lastMessageDate.isBetween(yesterday, today, "d")) {
        categorized.yesterday.push(conversation);
      } else if (lastMessageDate.isBetween(last7Days, yesterday, "d")) {
        categorized.last7Days.push(conversation);
      } else {
        categorized.previous.push(conversation);
      }
    });

    return categorized;
  };

  const renderConversation = (conversation) => {
    return (
      <li
        key={conversation.thread_id}
        className={`conversationItem ${activeThreadId === conversation.thread_id ? "active" : ""}`}
        onClick={() => onConversationClick(conversation.thread_id)}
        id={`conversationItem`}
      >
        <Typography
          sx={{
            fontFamily: activeThreadId === conversation.thread_id ? "Poppins-Semibold" : "Poppins-Medium",
            fontSize: "14px",
            lineHeight: "20px",
            color: "#14151F",
          }}
        >
          {conversation.title ? (
            conversation.title?.length > 23 ? (
              <Tooltip title={conversation.title} placement="bottom">
                <p>{conversation.title.slice(0, 23) + "..."}</p>
              </Tooltip>
            ) : (
              <p>{conversation.title}</p>
            )
          ) : (
            <>New chat</>
          )}
        </Typography>
        <IconButton
          className="deleteConversationButton"
          onClick={(e) => {
            e.stopPropagation();
            handleOpenConfirmationModal(conversation.thread_id);
          }}
          sx={{ padding: "0px", marginLeft: "auto" }}
        >
          <DeleteOutlineIcon fontSize="small" sx={{ width: "15px", height: "15px" }} />
        </IconButton>
      </li>
    );
  };

  const { today, yesterday, last7Days, previous } = categorizeConversations(conversations);

  return (
    <>
      <aside className={`coversations-sidebar ${isSidebarExpanded ? "expanded" : "collapsed"}`}>
        {isSidebarExpanded && (
          <div className="flex justify-between items-center" style={{ marginInline: "10px" }}>
            <div className="flex items-center">
              <img src={vivoBlackLogo} className="vivoLogo" />
              <h1 className="edgeVivoTitle">Edge Vivo</h1>
            </div>
            <IconButton
              id="toggleSidebarBtn"
              edge="arrow"
              color="inherit"
              aria-label="menu"
              sx={{ display: "flex", alignItems: "center" }}
              onClick={toggleSidebar}
            >
              <WestIcon />
            </IconButton>
          </div>
        )}
        <div className="flex">
          <Button
            variant="outlined"
            startIcon={<CreateOutlinedIcon sx={{ color: "#000000" }} />}
            sx={{
              width: "100%",
              padding: "15px",
              margin: "10px",
              borderRadius: "12px",
              backgroundColor: "#FFFFFF",
              border: "1px solid #E0E0FF",
              borderColor: "#E0E0FF",
              fontWeight: "500",
            }}
            onClick={onNewChat}
            id="new_chat_click"
          >
            <Typography
              sx={{
                fontFamily: "Poppins-Medium",
                fontSize: "13px",
                fontWeight: "500",
                lineHeight: "20px",
                color: "#131599",
              }}
            >
              Start a new chat
            </Typography>
          </Button>
        </div>

        <ul className="conversationsList">
          {today.length > 0 && (
            <>
              <li className="categoryTitle">TODAY</li>
              {today.map(renderConversation)}
            </>
          )}

          {yesterday.length > 0 && (
            <>
              <li className="categoryTitle">YESTERDAY</li>
              {yesterday.map(renderConversation)}
            </>
          )}

          {last7Days.length > 0 && (
            <>
              <li className="categoryTitle">LAST 7 DAYS</li>
              {last7Days.map(renderConversation)}
            </>
          )}

          {previous.length > 0 && (
            <>
              <li className="categoryTitle">Previous</li>
              {previous.map(renderConversation)}
            </>
          )}
        </ul>
      </aside>
      <Dialog
        open={openConfirmationModal}
        onClose={handleCloseConfirmationModal}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <div
          style={{
            padding: "20px",
            paddingBlock: "50px",
            display: "flex-block",
            alignItems: "center",
          }}
        >
          <DialogContent>
            <Typography
              sx={{
                fontFamily: "Poppins",
                fontSize: "16px",
                textAlign: "center",
                fontWeight: "500",
              }}
            >
              Are you sure you want to delete this conversation?<br></br> This action cannot be undone.
            </Typography>
          </DialogContent>
          <div style={{ display: "flex", justifyContent: "space-around", margin: "20px" }}>
            <Button className="secondaryBtn" onClick={handleCloseConfirmationModal}>
              Cancel
            </Button>
            <Button className="primaryBtn" onClick={() => deactivateThread(threadToDelete)} autoFocus>
              Confirm
            </Button>
          </div>
        </div>
      </Dialog>
    </>
  );
};
