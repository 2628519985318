//employee constants
import {
  RESER_ADD_EMPLOYEE_SALARY,
  ADD_EMPLOYEE_SALARY,
  ADD_EMPLOYEE_SALARY_SUCCESS,
  ADD_EMPLOYEE_SALARY_FAILURE,
} from "../../../constants/Workforce/AddEmployee/AddEmployeeSalary";

const INIT_STATE = {
  addEmployeeSalary: {},
  loadingAddEmployeeSalary: false,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case RESER_ADD_EMPLOYEE_SALARY:
      return INIT_STATE;
    case ADD_EMPLOYEE_SALARY:
      return {
        ...state,
        loadingAddEmployeeSalary: true,
      };
    case ADD_EMPLOYEE_SALARY_SUCCESS:
      return {
        ...state,
        loadingAddEmployeeSalary: false,
        addEmployeeSalary: action.payload,
      };
    // eslint-disable-next-line no-fallthrough
    case ADD_EMPLOYEE_SALARY_FAILURE:
      return {
        ...state,
        loadingAddEmployeeSalary: false,
        addEmployeeSalary: "Error in  getting Club Data",
      };
    default:
      return state;
  }
};
