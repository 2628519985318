import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { VscArrowLeft, VscArrowRight } from "react-icons/vsc";
import { Fade } from "@mui/material";

import LeaveCard from "components/Screens/Timeoff/ManageTimeoff/Customer/LeaveCard";

import { getEmployeePendingLeaves } from "services/useTimeoffService";

const PendingRequests = ({ pendingLeaveRequests, fetchEmployeePendingLeaves }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [cardShownOnScreenFlag, setCardShownOnScreenFlag] = useState(true);

  const handlePrevClick = () => {
    if (currentIndex > 0) {
      setCurrentIndex(currentIndex - 1);
    }
  };

  const handleNextClick = () => {
    if (currentIndex < pendingLeaveRequests.length - 1) {
      setCurrentIndex(currentIndex + 1);
    }
  };

  const runAnimationForLeaveCard = () => {
    setCardShownOnScreenFlag(false);
    setTimeout(() => setCardShownOnScreenFlag(true), 300);
  };

  useEffect(() => {
    runAnimationForLeaveCard();
  }, [currentIndex]);

  return (
    <div className="pending-leaves-container-customer p-1">
      <div className="flex w-full items-center justify-between">
        <div>
          <p className="">
            Pending {pendingLeaveRequests?.length ? `  (${currentIndex + 1}/${pendingLeaveRequests?.length})` : null}
          </p>
        </div>
        {pendingLeaveRequests?.length ? (
          <div>
            <VscArrowLeft
              onClick={handlePrevClick}
              className={`text-2xl cursor-pointer arrow-size mr-gap bg-primary-95 primary-30 ${
                currentIndex === 0 ? "blurred-arrow" : ""
              }`}
            />
            <VscArrowRight
              onClick={handleNextClick}
              className={`text-2xl cursor-pointer arrow-size bg-primary-95 primary-30 ${
                currentIndex === pendingLeaveRequests?.length - 1 ? "blurred-arrow" : ""
              }`}
            />
          </div>
        ) : null}
      </div>
      <Fade className="w-100p" in={cardShownOnScreenFlag}>
        {pendingLeaveRequests?.length ? (
          <div>
            <LeaveCard
              data={pendingLeaveRequests?.[currentIndex]}
              fetchEmployeePendingLeaves={fetchEmployeePendingLeaves}
              showProfileSection={false}
              runAnimationForLeaveCard={runAnimationForLeaveCard}
            />
          </div>
        ) : (
          <p className="text-center" style={{ display: "flex", justifyContent: "center" }}>
            No data available
          </p>
        )}
      </Fade>
      <div className="dots-container">
        {pendingLeaveRequests?.map((_, index) => (
          <span key={index} className={`dot ${index === currentIndex ? "active-dot" : "inactive-dot"}`}></span>
        ))}
      </div>
    </div>
  );
};

export default PendingRequests;
