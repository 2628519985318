import {
  FETCH_ADMIN_SUMMARY_REQUEST,
  FETCH_ADMIN_SUMMARY_SUCCESS,
  FETCH_ADMIN_SUMMARY_FAILURE,
} from "store/constants/AdminSummary";

export const fetchAdminSummaryRequest = () => {
  return {
    type: FETCH_ADMIN_SUMMARY_REQUEST,
  };
};

export const fetchAdminSummarySuccess = (payload) => {
  return {
    type: FETCH_ADMIN_SUMMARY_SUCCESS,
    payload,
  };
};

export const fetchAdminSummaryFailure = (error) => {
  return {
    type: FETCH_ADMIN_SUMMARY_FAILURE,
    error,
  };
};
