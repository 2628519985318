import { call, put, takeEvery } from "redux-saga/effects";

import {
  EmployeeDocumentsRequestSuccess,
  EmployeeDocumentsRequestFailure,
} from "../../actions/EmployeePortal/EmployeeDocuments";

import { EMPLOYEE_DOCUMENTS_REQUEST } from "../../constants/EmployeePortal/EmployeeDocuments";

import getRequest from "../../../util/APIHelperGet";
import { baseUrl } from "../../../util/APIBaseUrl";

export const fetchRequest = async (actions) => {
  return await getRequest(`${baseUrl}/${actions.payload}`)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

function* fetchEmployeeDocumentsList(params) {
  try {
    let response = yield call(fetchRequest, params);
    yield put(EmployeeDocumentsRequestSuccess(response));
  } catch (error) {
    console.error(error);
    yield put(EmployeeDocumentsRequestFailure(error));
  }
}

export default function* rootSaga() {
  yield takeEvery(EMPLOYEE_DOCUMENTS_REQUEST, fetchEmployeeDocumentsList);
}
