//employee constants
import {
  RESET_DELETE_CUSTOMER_BANK_INFO,
  DELETE_CUSTOMER_BANK_INFO,
  DELETE_CUSTOMER_BANK_INFO_SUCCESS,
  DELETE_CUSTOMER_BANK_INFO_FAILURE,
} from "../../constants/Payment/DeleteBankAcc";

const INIT_STATE = {
  deleteBankAccountInfo: {},
  loadingDeleteAccountInfo: true,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case RESET_DELETE_CUSTOMER_BANK_INFO:
      return INIT_STATE;
    case DELETE_CUSTOMER_BANK_INFO:
      return {
        ...state,
        loadingDeleteAccountInfo: true,
      };
    case DELETE_CUSTOMER_BANK_INFO_SUCCESS:
      return {
        ...state,
        loadingDeleteAccountInfo: false,
        deleteBankAccountInfo: action.payload,
      };
    // eslint-disable-next-line no-fallthrough
    case DELETE_CUSTOMER_BANK_INFO_FAILURE:
      localStorage.removeItem("deleteBankAccountInfo");
      return {
        ...state,
        loadingDeleteAccountInfo: false,
        deleteBankAccountInfo: "Error in  getting Club Data",
      };
    default:
      return state;
  }
};
