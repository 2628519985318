import React from "react";
import { Chip } from "@mui/material";
import LeaveType from "components/Screens/Timeoff/ManageTimeoff/Common/LeaveType";
import { convertDate } from "components/Screens/CommonUtils";

import LeftArrow from "assets/images/left-arrow.svg";

import "./HistoryCard.css";

function HistoryCard({ data = {}, onClick }) {
  const { status, leaveStatus, leaveType, dateTo, dateFrom, noOfLeaves, description } = data;

  const pending = status === "pending";

  return (
    <div
      onClick={onClick}
      className={
        pending
          ? "history-pending-leaves-card-container flex flex-col cursor-pointer"
          : "history-pending-leaves-card-container flex flex-row cursor-pointer"
      }
    >
      <div className="w-100p">
        <div className="pending-leaves-top-section mt-05 mb-05">
          <div className={pending && "flex py-1"}>
            <div className={!pending && "pl-1"}>
              <div className="pending-leave-reason">{leaveType || "Sick"} leaves</div>

              <div className="pending-leave-duration">
                <div className="pending-leave-date">{convertDate(dateFrom || "2024-12-12")}</div>
                <img className="pending-leave-arrow" src={LeftArrow} alt="left arrow" />
                <div className="pending-leave-date">{convertDate(dateTo || "2024-12-12")}</div>
              </div>
            </div>
          </div>
        </div>

        <div className="pl-1 label-md history-description neutral-20 text-italic opacity-75">
          <p>{description || ""}</p>
        </div>

        <div
          className={
            pending ? "pending-timeoff-details-wrapper" : "flex justify-between items-center space-y-1 pt-025 pb-1"
          }
        >
          <div className="pending-timeoff-details">
            <div className="pr-1 text-sm font-base">Total leaves taken</div>
            <Chip
              className="secondary-20"
              size="small"
              variant="outlined"
              label={`${noOfLeaves || 2}  ${noOfLeaves == 1 ? "leave" : "leaves"}`}
            />
          </div>
          <div className="pending-timeoff-details pr-1">
            <LeaveType type={pending ? "Pending" : leaveStatus} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default HistoryCard;
