import React from "react";

function ErrorIcon() {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 23C5.92487 23 1 18.0751 1 12C1 5.92487 5.92487 1 12 1C18.0751 1 23 5.92487 23 12C23 18.0751 18.0751 23 12 23ZM12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21ZM12.0003 17.9983C11.4479 17.9983 11 17.5506 11 16.9983C11 16.4461 11.4479 15.9983 12.0003 15.9983C12.5528 15.9983 13.0007 16.4461 13.0007 16.9983C13.0007 17.5506 12.5528 17.9983 12.0003 17.9983ZM11.003 5.99835H13.0036V13.9983H11.003V5.99835Z"
        fill="#BA1A1A"
      />
    </svg>
  );
}

export default ErrorIcon;
