import { call, put, takeEvery } from "redux-saga/effects";
//actions
import {
  AddCustomerBankInfoSuccess,
  AddCustomerBankInfoFailure,
} from "../../actions/Payment/AddBankAccountInfo";
//constant
import { ADD_CUSTOMER_BANK_INFO } from "../../constants/Payment/AddBankAccountInfo";
//request type
import postRequest from "../../../util/APIHelperPost";
import { baseUrl } from "../../../util/APIBaseUrl";

export const fetchAddCustomerBankAccountInfoRequest = async (actions) => {
  return await postRequest(`${actions.payload.request}`, actions.payload.body)
    .then((response) => response)
    .catch((error) => {
      throw error;
    });
};

function* fetchAddCustomerBankAccountInfo(params) {
  try {
    let response = yield call(fetchAddCustomerBankAccountInfoRequest, params);
    yield put(AddCustomerBankInfoSuccess(response));
  } catch (error) {
    console.error(error);
    yield put(AddCustomerBankInfoFailure(error));
  }
}

export default function* rootSaga() {
  yield takeEvery(ADD_CUSTOMER_BANK_INFO, fetchAddCustomerBankAccountInfo);
}
