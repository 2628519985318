import { call, put, takeEvery } from 'redux-saga/effects';
//actions
import {
  GetEmployeePayrollByIdSuccess,
  GetEmployeePayrollByIdFailure,
} from '../../actions/EmployeeSalaries/EmployeePayrollById';
//constant
import { GET_EMPLOYEE_PAYROLL_BY_ID } from '../../constants/EmployeeSalaries/EmployeePayrollById';
//request type
import getRequest from '../../../util/APIHelperGet';
import { baseUrl } from '../../../util/APIBaseUrl';

export const fetchEmployeePayrollByIdRequest = async (actions) => {
  // eslint-disable-next-line no-return-await

  return await getRequest(
    `${baseUrl}/customer/${actions.payload.customerId}/employee/${actions.payload.employeeId}/payroll`
  )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

function* fetchEmployeePayrollById(params) {
  try {
    let response = yield call(fetchEmployeePayrollByIdRequest, params);
    yield put(GetEmployeePayrollByIdSuccess(response));
  } catch (error) {
    console.error(error);
    yield put(GetEmployeePayrollByIdFailure(error));
  }
}

export default function* rootSaga() {
  yield takeEvery(GET_EMPLOYEE_PAYROLL_BY_ID, fetchEmployeePayrollById);
}
