import { call, put, takeEvery } from "redux-saga/effects";

import { LeaveTypeSucess, LeaveTypeFailure } from "store/actions/Timeoff/LeaveTypes";

import { LEAVE_TYPES } from "store/constants/Timeoff";

import getRequest from "util/APIHelperGet";
import { baseUrl } from "util/APIBaseUrl";

export const fetcheLeavesTypesRequest = async (actions) => {
  const loggedInUserID = JSON.parse(localStorage.getItem("loggedInUser"))?.userId;
  return await getRequest(`${baseUrl}/employee/${loggedInUserID}/leave_types`)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

function* fetchLeaveTypes(params) {
  try {
    let response = yield call(fetcheLeavesTypesRequest, params);
    yield put(LeaveTypeSucess(response));
  } catch (error) {
    console.error(error);
    yield put(LeaveTypeFailure(error));
  }
}

export default function* rootSaga() {
  yield takeEvery(LEAVE_TYPES, fetchLeaveTypes);
}
