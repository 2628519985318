import React, { useEffect, useState } from "react";
import { useNavigate, Link as RouterLink } from "react-router-dom";
import { useDispatch } from "react-redux";
import moment from "moment";
import { subYears } from "date-fns";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Box,
  Typography,
  Pagination,
  Breadcrumbs,
  Link,
} from "@mui/material";
import DownloadIcon from "@mui/icons-material/Download";

import Button from "components/Button/Button";
import CustomDropdown from "components/common/Dropdown";
import EmployeeAvatar from "./EmployeeAvatar";
import DropDownDateRange from "components/common/DropDownDateRange";
import PageLoader from "components/common/PageLoader";
import NoDataForTimeoffReports from "components/Screens/Timeoff/TimeoffAdmin/UtilisationReport/NoDataForTimeoffReports";

import { countryDropdownPeruDisabledForTimeoff } from "../Constants";
import getRequest from "util/APIHelperGet";
import { baseUrl } from "util/APIBaseUrl";

import { showSnackbar } from "store/actions/Utility";

import LeftArrow from "assets/images/left-arrow.svg";

import "./UtilizationReport.css";

const TimeOffUtilizationReport = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [selectedFilter, setSelectedFilter] = useState({
    country: null,
  });
  const [timeoffReports, setTimeoffReports] = useState([]);
  const [loadingTimeoffReports, setLoadingTimeoffReports] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalReportsCount, setTotalReportsCount] = useState(0);

  const itemsPerPage = 10;

  const [dateRange, setDateRange] = useState({
    startDate: subYears(new Date(), 1),
    endDate: new Date(),
  });

  const dropdownStyles = {
    borderRadius: "33px",
    background: "#FBFAFF",
    minWidth: "150px",
    display: "flex",
  };
  const customDropdownStyles = { display: "flex", flex: 1, height: "32px" };

  const handlePageChange = (_, value = 1) => {
    setCurrentPage(value);
  };

  const downloadTimeoffReports = async () => {
    dispatch(
      showSnackbar({
        type: "info", //info
        message: "Downloading CSV...",
        dispatch,
      })
    );

    const filter = {
      countries: ["Pakistan"],
      fromDate: moment(dateRange.startDate).format("YYYY-MM-DD"),
      toDate: moment(dateRange.endDate).format("YYYY-MM-DD"),
    };
    const response = await getRequest(
      `${baseUrl}/admin/timeoff/report/export?page=${currentPage}&limit=${itemsPerPage}&filter=${encodeURIComponent(
        JSON.stringify(filter)
      )}`,
      true,
      "export"
    );

    if (!response?.ok) {
      return;
    }

    const url = window.URL.createObjectURL(response?.payload);
    const link = document.createElement("a");
    link.style.display = "none";
    link.href = url;

    link.download = "Timeoff Utilization Report";
    document.body.appendChild(link);
    link.click();
    window.URL.revokeObjectURL(url);
  };

  useEffect(() => {
    const fetchTimeoffReports = async () => {
      try {
        setLoadingTimeoffReports(true);
        const filter = {
          countries: ["Pakistan"],
          fromDate: moment(dateRange.startDate).format("YYYY-MM-DD"),
          toDate: moment(dateRange.endDate).format("YYYY-MM-DD"),
        };
        const response = await getRequest(
          `${baseUrl}/admin/timeoff/report?page=${currentPage}&limit=${itemsPerPage}&filter=${encodeURIComponent(
            JSON.stringify(filter)
          )}`,
          true
        );
        if (response?.ok) {
          setTimeoffReports(response?.payload?.rows || []);
          setTotalReportsCount(response?.payload?.count);
          setLoadingTimeoffReports(false);
        }
      } catch (error) {
        setLoadingTimeoffReports(false);
      }
    };
    fetchTimeoffReports();
  }, [dateRange, currentPage]);

  return (
    <div className="outer_container_report">
      {loadingTimeoffReports && <PageLoader />}
      <div>
        <Breadcrumbs sx={{ fontSize: "12px" }}>
          <Link
            component={RouterLink}
            to="/main/timeoff/admin"
            sx={{
              fontFamily: "Poppins-Regular",
              fontSize: "12px",
              fontWeight: 400,
              color: "#212231",
              textDecoration: "none",
            }}
          >
            Time-off
          </Link>
          <Link
            sx={{
              fontFamily: "Poppins-Regular",
              fontSize: "12px",
              fontWeight: 400,
              color: "#212231",
              textDecoration: "none",
            }}
            aria-current="page"
          >
            Report
          </Link>
        </Breadcrumbs>
      </div>
      <Box display="flex" alignItems="center" mb={2}>
        <img onClick={() => navigate(-1)} className="pr-025 cursor-pointer" src={LeftArrow} alt="left arrow" />
        <Typography
          variant="h1"
          sx={{
            fontSize: "20px",
            color: "#292a3d",
            fontFamily: "Poppins",
            fontStyle: "normal",
            fontWeight: 600,
            lineHeight: "32px",
          }}
        >
          Time-off Utilization Report
        </Typography>
      </Box>
      <div className="time_off_report_container">
        <div className="time_off_report_top_section">
          <Box display="flex" justifyContent="space-between" alignItems="center" gap={1}>
            <Typography
              sx={{
                color: "#292A3D",
                fontFamily: "Inter",
                fontSize: "14px",
                fontStyle: "normal",
                fontWeight: "500",
                lineHeight: "normal",
              }}
            >
              Select duration
            </Typography>

            <DropDownDateRange
              handlePageChange={handlePageChange}
              dateRange={dateRange}
              style={dropdownStyles}
              placeholder="Select Date Range"
              onSelect={(range) => setDateRange(range)}
            />
            <CustomDropdown
              options={countryDropdownPeruDisabledForTimeoff}
              placeholder="Country"
              onSelect={(key) => {
                setSelectedFilter({
                  ...selectedFilter,
                  country: key,
                });
              }}
              onClear={() =>
                setSelectedFilter({
                  ...selectedFilter,
                  country: null,
                })
              }
              customDropdownStyles={{ ...customDropdownStyles }}
              buttonLabel={
                countryDropdownPeruDisabledForTimeoff?.find((country) => country?.key === selectedFilter?.country)
                  ?.label
              }
              style={{ ...dropdownStyles, flex: 1 }}
            />
          </Box>
          <Button
            variant="primary"
            size="sm"
            onClick={downloadTimeoffReports}
            style={{
              backgroundColor: "transparent",
              borderRadius: "35.875px",
              fontFamily: "Inter",
              fontSize: "14px",
              color: "#131599",
              lineHeight: "27px",
              fontWeight: "600",
              border: "2px solid #131599",
              height: "32px",
              paddingInline: "25px",
            }}
            startIcon={<DownloadIcon />}
          >
            Download CSV
          </Button>
        </div>

        {timeoffReports.length ? (
          <>
            {" "}
            <TableContainer
              sx={{
                overflow: "auto",
                borderRadius: "14px !important",
                border: "1px solid #dbd9f2",
                borderBlockEnd: "none",
                marginBlock: "16px",
                boxShadow: "none",
              }}
              component={Paper}
              className="table-container"
            >
              <Table className="report-table">
                <TableHead>
                  <TableRow>
                    <TableCell
                      sx={{
                        position: "sticky",
                        left: 0,
                        backgroundColor: "white",
                        zIndex: 2,
                      }}
                    >
                      {`Name (${timeoffReports.length})`}
                    </TableCell>
                    <TableCell
                      sx={{
                        position: "sticky",
                        left: "150px",
                        backgroundColor: "white",
                        zIndex: 2,
                      }}
                    >
                      Renewal Date
                    </TableCell>
                    <TableCell colSpan={3}>Annual Leaves</TableCell>
                    <TableCell colSpan={3}>Sick Leaves</TableCell>
                    <TableCell colSpan={3}>Maternity Leaves</TableCell>
                    <TableCell colSpan={3}>Paternity Leaves</TableCell>
                    <TableCell colSpan={3}>Swapped</TableCell>
                    <TableCell colSpan={2} style={{ borderRight: "none" }}>
                      Unpaid Leaves
                    </TableCell>
                  </TableRow>
                  <TableRow className="sub_columns_cells">
                    <TableCell
                      sx={{
                        position: "sticky",
                        left: 0,
                        backgroundColor: "white",
                        zIndex: 1,
                      }}
                    ></TableCell>
                    <TableCell
                      sx={{
                        position: "sticky",
                        left: 150,
                        backgroundColor: "white",
                        zIndex: 1,
                      }}
                    ></TableCell>
                    <TableCell>Total</TableCell>
                    <TableCell>Used</TableCell>
                    <TableCell>Remaining</TableCell>
                    <TableCell>Total</TableCell>
                    <TableCell>Used</TableCell>
                    <TableCell>Remaining</TableCell>
                    <TableCell>Total</TableCell>
                    <TableCell>Used</TableCell>
                    <TableCell>Remaining</TableCell>
                    <TableCell>Total</TableCell>
                    <TableCell>Used</TableCell>
                    <TableCell>Remaining</TableCell>
                    <TableCell>Total</TableCell>
                    <TableCell>Used</TableCell>
                    <TableCell>Remaining</TableCell>
                    <TableCell style={{ borderRight: "none" }}>Used</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {timeoffReports.map((row, index) => (
                    <TableRow key={index}>
                      <TableCell
                        className="left_text"
                        sx={{
                          position: "sticky",
                          left: 0,
                          backgroundColor: "white",
                          zIndex: 1,
                        }}
                      >
                        <EmployeeAvatar
                          img={`${baseUrl}/employee/${row?.employeeId}/logo?id=${row?.pictureVersion}&variant=thumbnail`}
                          name={row?.name || ""}
                          jobTitle={row?.customerName || ""}
                          country={row?.country}
                        />
                      </TableCell>
                      <TableCell
                        sx={{
                          position: "sticky",
                          left: 150,
                          backgroundColor: "white",
                          zIndex: 100,
                        }}
                      >
                        {row.renewalDate}
                      </TableCell>
                      <TableCell>{row.annual.total}</TableCell>
                      <TableCell>{row.annual.used}</TableCell>
                      <TableCell>{row.annual.remaining}</TableCell>
                      <TableCell>{row.sick.total}</TableCell>
                      <TableCell>{row.sick.used}</TableCell>
                      <TableCell>{row.sick.remaining}</TableCell>
                      <TableCell>{row.maternity.total}</TableCell>
                      <TableCell>{row.maternity.used}</TableCell>
                      <TableCell>{row.maternity.remaining}</TableCell>
                      <TableCell>{row.paternity.total}</TableCell>
                      <TableCell>{row.paternity.used}</TableCell>
                      <TableCell>{row.paternity.remaining}</TableCell>
                      <TableCell>{row.swapped.total}</TableCell>
                      <TableCell>{row.swapped.used}</TableCell>
                      <TableCell>{row.swapped.remaining}</TableCell>
                      <TableCell>{row.unpaid.used}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <div className="time_off_report_pagination">
              <Pagination
                count={Math.ceil(totalReportsCount / itemsPerPage)}
                page={currentPage}
                onChange={handlePageChange}
                variant="outlined"
                shape="rounded"
                data-testid="pagination-component"
              />
            </div>
          </>
        ) : (
          <NoDataForTimeoffReports />
        )}
      </div>
    </div>
  );
};

export default TimeOffUtilizationReport;
