import { call, put, takeEvery } from 'redux-saga/effects';
import {
  AddCustomerSuccess,
  AddCustomerFailure,
  AddGoogleCustomerSuccess,
  AddGoogleCustomerFailure,
} from '../../../actions/Customers/AddCustomer/AddCustomer';
import {
  ADD_CUSTOMER,
  ADD_GOOGLE_CUSTOMER,
} from '../../../constants/Customers/AddCustomer/AddCustomer';
import postRequest from '../../../../util/APIHelperPost';
import { baseUrl } from '../../../../util/APIBaseUrl';
import { getUtcFormatDate } from '../../../../components/Screens/CommonUtils';

export const fetchAddCustomerRequest = async (actions) => {

  let body = {
    // logo: actions.payload.imageBlob,
    companyName: actions?.payload?.customerData?.companyName,
    contactEmail: actions?.payload?.customerData?.email,
    industry: actions?.payload?.customerData?.industry,
    contactPerson: actions?.payload?.customerData?.contactPerson,
    contactNumber: actions?.payload?.customerData?.contactNumber,
    country: actions?.payload?.customerData?.country?.value,
    // logo: actions?.payload?.customerData?.imageBlob,
    // gender: "Male",
    // password: "123456",
    addressLine1: actions?.payload?.customerData?.companyAddress,
    state: actions?.payload?.customerData?.state,
    city: actions?.payload?.customerData?.city,
    zip: actions?.payload?.customerData?.code,
    google: actions?.payload?.customerData?.google,
    onboardingDate: getUtcFormatDate(
      actions?.payload?.customerData?.customerJoiningDate
    ),
  };

  // if (actions?.payload?.customerData?.loadFromUrl == false) {
  //     body["logo"] = actions.payload?.customerData?.imageBlob;
  // }

  body = JSON.stringify(body);

  return await postRequest(
    `${baseUrl}/${actions.payload.request}`,
    body,
    true,
    actions.payload.method
  )
    .then((response) => response)
    .catch((error) => {
      throw error;
    });
};

function* fetchAddCustomer(params) {
  try {
    let response = yield call(fetchAddCustomerRequest, params);
    yield put(AddCustomerSuccess(response));
  } catch (error) {
    console.error(error);
    yield put(AddCustomerFailure(error));
  }
}

function* fetchAddGoogleCustomer(params) {
  try {
    let response = yield call(fetchAddCustomerRequest, params);
    yield put(AddGoogleCustomerSuccess(response));
  } catch (error) {
    console.error(error);
    yield put(AddGoogleCustomerFailure(error));
  }
}
export default function* rootSaga() {
  yield takeEvery(ADD_CUSTOMER, fetchAddCustomer);
  yield takeEvery(ADD_GOOGLE_CUSTOMER, fetchAddGoogleCustomer);
}
