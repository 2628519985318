import { call, put, takeEvery } from "redux-saga/effects";
import { setConsent, fetchConsentFailed } from "../../actions/Payoneer/PayoneerGetEmployeeConsent";
import { PAYONEER_GET_EMPLOYEE_CONSENT } from "store/constants/Payoneer/PayoneerGetEmployeeConsent";
import { baseUrl } from "util/APIBaseUrl";
import getRequest from "util/APIHelperGet";

const fetchConsentsFromAPI = async () => {
  return getRequest(`${baseUrl}/payoneer/authorization-link`);
};

function* fetchConsentsSaga() {
  const consentInfo = yield call(fetchConsentsFromAPI);
  if (consentInfo?.ok) {
    yield put(setConsent(consentInfo?.payload?.authorizationLink));
  } else {
    yield put(fetchConsentFailed(consentInfo?.payload?.error[0]));
  }
}

export default function* rootSaga() {
  yield takeEvery(PAYONEER_GET_EMPLOYEE_CONSENT, fetchConsentsSaga);
}
