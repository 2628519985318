//employee constants
import {
  EMPLOYEE_PORTAL_EDIT_PROFILE,
  EMPLOYEE_PORTAL_EDIT_PROFILE_SUCCESS,
  EMPLOYEE_PORTAL_EDIT_PROFILE_FAILURE,
  RESET_EMPLOYEE_PORTAL_EDIT_PROFILE,
} from "../../constants/EmployeePortal/EditEmployeeProfile";

const INIT_STATE = {
  editedEmployee: {},
  isLoading: false,
  error: null,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case RESET_EMPLOYEE_PORTAL_EDIT_PROFILE:
      return INIT_STATE;

    case EMPLOYEE_PORTAL_EDIT_PROFILE:
      return {
        ...state,
        isLoading: true,
      };
    case EMPLOYEE_PORTAL_EDIT_PROFILE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        editedEmployee: action.payload,
        error: null,
      };
    case EMPLOYEE_PORTAL_EDIT_PROFILE_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: "Error in Updating user",
      };
    default:
      return state;
  }
};
