import { call, put, takeEvery } from "redux-saga/effects";
//actions
import {
  CurrentPayrollSuccess,
  CurrentPayrollFailure,
} from "../../actions/Payroll/CurrentPayroll";
//constant
import { CURRENT_PAYROLL } from "../../constants/Payroll/CurrentPayroll";
//request type
import getRequest from "../../../util/APIHelperGet";
import { baseUrl } from "../../../util/APIBaseUrl";

export const fetchCurrentPayrollRequest = async (actions) => {
  // eslint-disable-next-line no-return-await

  return await getRequest(
    `${baseUrl}/customer/${actions.payload.customerId}/summary`
  )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

function* fetchCurrentPayroll(params) {
  try {
    let response = yield call(fetchCurrentPayrollRequest, params);
    yield put(CurrentPayrollSuccess(response));
  } catch (error) {
    console.error(error);
    yield put(CurrentPayrollFailure(error));
  }
}

export default function* rootSaga() {
  yield takeEvery(CURRENT_PAYROLL, fetchCurrentPayroll);
}
