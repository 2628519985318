import { useSelector } from "react-redux";
import LeaveCard from "../Common/LeaveCards";
import PageLoader from "components/common/PageLoader";
import { useEffect, useState } from "react";

const TakenLeaves = () => {
  const [takenLeaveRequests, setTakenLeaveRequests] = useState([]);
  const leaveRequests = useSelector((state) => state?.TimeoffLeaveRequests?.leaveRequests || []);
  const loadingLeaveRequests = useSelector((state) => state?.TimeoffLeaveRequests?.loadingLeaveRequests);

  useEffect(() => {
    setTakenLeaveRequests(leaveRequests?.taken || []);
  }, [leaveRequests]);

  const NoDataCard = (_) => {
    return (
      <div className="no-card-container">
        <p className="no-card-text">No taken time-off</p>
      </div>
    );
  };

  return (
    <>
      {loadingLeaveRequests && <PageLoader />}
      <div className="pending-leaves-container-emp flex-col">
        <div className="mt-1">
          {takenLeaveRequests?.length ? (
            <div className="flex flex-col gap-075">
              {takenLeaveRequests.map((leave) => (
                <LeaveCard type={"taken"} data={leave} />
              ))}
            </div>
          ) : (
            <NoDataCard />
          )}
        </div>
      </div>
    </>
  );
};
export default TakenLeaves;
