import React from "react";

function ArrowRight(props) {
  const { isDisabled } = props;
  return (
    <svg
      width="18"
      height="19"
      viewBox="0 0 18 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M13.1895 10.25H2.25V8.74998H13.1895L8.46975 4.03023L9.53025 2.96973L16.0605 9.49998L9.53025 16.0302L8.46975 14.9697L13.1895 10.25Z"
        fill={isDisabled ? "grey" : "#02006E"}
      />
    </svg>
  );
}

export default ArrowRight;
