import React from "react";

const EdgeIcon = ({ color = "black", style }) => {
  return (
    <svg
      width="105"
      height="54"
      viewBox="0 0 105 54"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M89.2199 41.9094C89.1526 41.867 89.0876 41.8223 89.0191 41.7822C88.8545 41.6929 88.7172 41.5615 88.6218 41.4016C88.5264 41.2418 88.4764 41.0596 88.4771 40.8741C88.4771 40.7949 88.4771 40.7158 88.4771 40.6367C88.4771 30.1645 88.474 19.6923 88.4678 9.22003C88.4678 8.41741 88.968 7.94043 89.7259 7.97368C90.4838 8.00693 91.2197 7.97368 91.966 7.98056C92.163 7.97145 92.3584 8.01822 92.5293 8.11534C92.7002 8.21246 92.8394 8.35587 92.9305 8.52863C93.0095 8.67666 93.0532 8.84061 93.0582 9.00791C93.0582 9.10422 93.0582 9.20168 93.0582 9.29799V40.6092C93.0582 40.7055 93.0582 40.803 93.0582 40.8993C93.0234 41.2914 92.8562 41.6044 92.4906 41.7925C92.4237 41.8156 92.3638 41.855 92.3165 41.9071L89.2199 41.9094Z"
        fill="#109146"
      />
      <path
        d="M96.5295 41.9097C96.4691 41.8741 96.4134 41.8328 96.3472 41.803C96.1767 41.725 96.0306 41.603 95.9244 41.4499C95.8182 41.2967 95.7558 41.1181 95.7437 40.9328C95.7344 40.8279 95.7309 40.7227 95.7333 40.6175V15.9301C95.7304 15.8153 95.7347 15.7005 95.746 15.5862C95.7651 15.3191 95.8885 15.0699 96.0901 14.891C96.2917 14.7122 96.5558 14.6177 96.8266 14.6276C97.4069 14.6184 97.9989 14.6276 98.585 14.6276H99.0922C99.7306 14.6276 100.036 14.8351 100.263 15.4165V41.1197C100.223 41.2813 100.144 41.431 100.033 41.5559C99.9221 41.6809 99.782 41.7775 99.6249 41.8374C99.5864 41.8582 99.5499 41.8823 99.5158 41.9097H96.5295Z"
        fill="#3166B0"
      />
      <path
        d="M55.8447 31.6448C55.8247 31.6376 55.8031 31.6364 55.7824 31.6413C55.7618 31.6462 55.7431 31.657 55.7286 31.6723C55.0113 32.2227 54.1768 32.4692 53.2912 32.5896C52.6558 32.6708 52.0136 32.6861 51.375 32.6355C49.9519 32.5564 48.5921 32.0278 47.4961 31.1277C46.3993 30.2482 45.5732 29.0834 45.1109 27.7647C44.8445 27.0093 44.6958 26.2183 44.6699 25.4188C44.6273 24.5428 44.7181 23.6655 44.9391 22.816C45.2709 21.6054 45.9019 20.4952 46.7753 19.5849C47.604 18.6826 48.6595 18.0129 49.8348 17.6438C50.7794 17.3544 51.7727 17.2527 52.7573 17.3445C53.1914 17.3758 53.6222 17.4413 54.0456 17.5406C54.7069 17.6904 55.3219 17.9952 55.8389 18.4292C55.8971 18.4921 55.9706 18.5395 56.0524 18.5668C56.1279 18.4521 56.0907 18.3168 56.093 18.1941C56.1 17.6999 56.042 17.6816 56.5991 17.6827C57.6483 17.6827 58.6972 17.6827 59.7456 17.6827H59.8524C60.2006 17.6827 60.2122 17.6942 60.2122 18.0382C60.2122 22.2152 60.2227 26.3923 60.2122 30.5693C60.2053 32.3557 59.582 33.9323 58.4097 35.2967C57.7961 36.0169 57.0586 36.6243 56.2312 37.0911C55.4084 37.5492 54.504 37.8462 53.5674 37.966C53.25 38.0064 52.93 38.0252 52.6099 38.0222H47.0074C46.6035 38.0222 46.6023 38.0222 46.6023 37.6346V34.3439C46.6023 34.2923 46.6023 34.2384 46.6023 34.1868C46.6151 34.0034 46.643 33.9713 46.8217 33.9575C46.9099 33.9518 46.9993 33.9575 47.0887 33.9575C48.9097 33.9575 50.7331 33.9816 52.5542 33.9472C54.041 33.9185 55.0891 33.1526 55.782 31.8833C55.8287 31.8127 55.8508 31.729 55.8447 31.6448ZM52.4439 21.4183C50.5068 21.3037 48.7774 23.0603 48.7728 24.9728C48.7681 26.8853 50.4163 28.5788 52.4045 28.5937C54.4391 28.6075 56.093 26.9254 56.1023 25.0118C56.1116 22.9571 54.3103 21.3106 52.4439 21.4161V21.4183Z"
        fill="#3166B0"
      />
      <path
        d="M38.4478 18.5738C38.4847 18.4777 38.4959 18.3739 38.4803 18.2723C38.4803 16.6311 38.4803 14.9899 38.4803 13.3488C38.4803 12.9337 38.4803 12.9337 38.9097 12.9337H42.3232C42.5553 12.9417 42.5832 12.9635 42.5948 13.1894C42.5948 13.2513 42.5948 13.3132 42.5948 13.374V31.8342C42.5948 31.9305 42.5948 32.0268 42.5867 32.1231C42.5874 32.1453 42.5834 32.1674 42.5748 32.1879C42.5663 32.2084 42.5534 32.2269 42.537 32.2421C42.5206 32.2573 42.5012 32.2688 42.4799 32.276C42.4586 32.2832 42.4361 32.2858 42.4137 32.2836H42.2536H38.8412C38.8052 32.2836 38.7693 32.2836 38.7344 32.2836C38.5139 32.2733 38.4884 32.2515 38.4803 32.0383C38.4745 31.8812 38.4803 31.723 38.4803 31.5647C38.4836 31.5427 38.482 31.5201 38.4756 31.4987C38.4692 31.4773 38.4581 31.4576 38.4431 31.4409C38.3944 31.4122 38.3595 31.4466 38.3271 31.4753C37.7142 32.0555 36.9656 32.365 36.1473 32.5072C34.6443 32.7721 33.1575 32.7365 31.7125 32.1919C30.9301 31.8961 30.2123 31.4551 29.5977 30.8928C28.7482 30.1391 28.079 29.2081 27.6386 28.1674C27.3238 27.3925 27.1282 26.5755 27.0582 25.7435C26.9881 24.9565 27.0272 24.1637 27.1743 23.3872C27.4168 22.0752 28.0135 20.8523 28.9014 19.8477C29.5422 19.0922 30.332 18.4734 31.2227 18.0292C31.9975 17.6516 32.8374 17.4217 33.6983 17.3515C34.5577 17.2718 35.4244 17.3256 36.2669 17.5109C37.0248 17.6749 37.7189 17.9753 38.2922 18.5096C38.3182 18.5331 38.3466 18.5539 38.377 18.5715C38.3955 18.5738 38.4187 18.5738 38.4478 18.5738ZM34.8335 21.4174C32.9381 21.3027 31.1495 22.924 31.1426 24.9833C31.1356 27.0426 32.9056 28.5733 34.7569 28.5985C36.6627 28.6237 38.464 27.1079 38.471 25.0062C38.4837 22.8609 36.622 21.3176 34.8335 21.4162V21.4174Z"
        fill="#3166B0"
      />
      <path
        d="M19.7851 27.0162C18.1494 27.0162 16.5144 27.0162 14.8802 27.0162C14.7873 27.0162 14.6538 26.968 14.6086 27.0632C14.5633 27.1584 14.6805 27.2466 14.7444 27.3246C16.0559 28.9367 18.6198 28.9814 20.0219 27.6239C20.218 27.4347 20.2378 27.437 20.4316 27.6239C21.2827 28.4647 22.1308 29.3025 22.9758 30.1372C23.1568 30.3149 23.158 30.3356 22.9641 30.5133C21.728 31.653 20.2946 32.3971 18.6001 32.5921C17.4687 32.7396 16.3186 32.6517 15.2237 32.3341C14.3294 32.067 13.4955 31.6321 12.7678 31.0533C11.5352 30.0718 10.6171 28.8748 10.1354 27.3659C9.8088 26.3051 9.70532 25.1895 9.83131 24.0878C9.92884 22.9812 10.2995 21.9151 10.9107 20.9828C12.1654 19.0921 13.8692 17.8251 16.165 17.4364C17.5965 17.1808 19.0716 17.32 20.4281 17.8388C21.2333 18.1497 21.9761 18.5997 22.6218 19.1677C23.3991 19.8278 24.0412 20.6288 24.5136 21.5274C24.932 22.3435 25.1914 23.2299 25.2785 24.1405C25.3696 24.9707 25.3257 25.8099 25.1485 26.6263C25.0603 27.0162 25.0556 27.0162 24.661 27.0162H19.7851ZM20.4931 22.9205C20.4933 22.8869 20.486 22.8537 20.4718 22.8232C20.4575 22.7927 20.4367 22.7656 20.4107 22.744C20.1064 22.3529 19.724 22.0278 19.2872 21.7888C18.6778 21.4712 18.0267 21.3956 17.3489 21.4151C16.8494 21.4168 16.3566 21.5295 15.9072 21.7448C15.4577 21.9602 15.0631 22.2726 14.7525 22.6591C14.6933 22.7313 14.5726 22.7967 14.6179 22.8965C14.6631 22.9962 14.7943 22.9469 14.886 22.9469C16.6656 22.9469 18.4434 22.9469 20.2192 22.9469C20.3113 22.9599 20.4053 22.9509 20.4931 22.9205Z"
        fill="#3166B0"
      />
      <path
        d="M67.076 27.0591C67.2418 27.3464 67.463 27.5988 67.7271 27.8021C68.3428 28.3035 69.1136 28.5818 69.9115 28.5909C70.3925 28.6098 70.8725 28.5348 71.3242 28.3703C71.7758 28.2058 72.1903 27.9551 72.5438 27.6324C72.7504 27.4535 72.7562 27.4558 72.9489 27.6462C73.8 28.487 74.6485 29.3248 75.4942 30.1595C75.6753 30.3384 75.6753 30.3578 75.5035 30.5126C74.6133 31.3153 73.6244 31.9528 72.4637 32.3094C71.7594 32.5256 71.0274 32.6415 70.2898 32.6533C69.3733 32.6864 68.4579 32.5623 67.5843 32.2864C66.5697 31.9514 65.6391 31.4065 64.8545 30.6881C64.2238 30.1215 63.6855 29.4621 63.2586 28.7331C62.7361 27.8114 62.4195 26.7898 62.33 25.7371C62.2505 24.9063 62.3026 24.0684 62.4844 23.2535C62.7497 22.0555 63.3202 20.9441 64.1418 20.0247C64.7863 19.2539 65.5818 18.6195 66.4804 18.1596C67.3791 17.6998 68.3625 17.4239 69.3718 17.3486C70.3918 17.2554 71.4204 17.3577 72.4011 17.6501C73.4167 17.9547 74.3532 18.4734 75.146 19.1705C75.9567 19.8589 76.6197 20.7006 77.0959 21.646C77.5275 22.5247 77.7765 23.4799 77.8283 24.4552C77.8764 25.1906 77.8221 25.929 77.667 26.6498C77.6519 26.7186 77.6298 26.7851 77.6136 26.8539C77.6058 26.902 77.5798 26.9454 77.5407 26.9752C77.5017 27.0051 77.4526 27.0191 77.4035 27.0144C77.3141 27.0144 77.2248 27.0144 77.1366 27.0144H67.4544C67.3264 27.0016 67.1972 27.0169 67.076 27.0591ZM67.0818 22.905C67.1905 22.9545 67.3118 22.9709 67.43 22.952C69.1717 22.952 70.9127 22.952 72.6529 22.952C72.7156 22.952 72.7783 22.952 72.8398 22.952C72.9013 22.952 72.9559 22.9635 72.986 22.9119C73.0162 22.8603 72.964 22.7972 72.9292 22.7502C72.6028 22.2959 72.1563 21.9389 71.6385 21.7183C71.1439 21.5069 70.6106 21.3978 70.0716 21.3978C69.5327 21.3978 68.9994 21.5069 68.5047 21.7183C67.914 21.9545 67.4613 22.3707 67.0818 22.9004V22.905Z"
        fill="#3166B0"
      />
      <path
        d="M81.1165 20.6052V9.59792C81.1119 9.43968 81.12 9.28133 81.1409 9.12438C81.2302 8.5763 81.6051 8.24379 82.1611 8.24035C82.9956 8.24035 83.8324 8.25296 84.6669 8.23462C84.9032 8.23993 85.1307 8.32423 85.3122 8.47376C85.4937 8.62329 85.6185 8.82921 85.6663 9.05787C85.6862 9.17919 85.6963 9.30188 85.6964 9.42478V31.7834C85.6964 31.8361 85.6964 31.8889 85.6964 31.9405C85.6605 32.6124 85.2473 33.0079 84.5648 33.0125C83.791 33.0171 83.0173 33.0171 82.2435 33.0125C81.5808 33.0125 81.1618 32.6089 81.1211 31.9554C81.1142 31.851 81.1211 31.7455 81.1211 31.64L81.1165 20.6052Z"
        fill="#F0525F"
      />
    </svg>
  );
};

export default EdgeIcon;
