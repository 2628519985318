import React from "react";

function PakistanFlag({ height, width }) {
  return (
    <svg
      width={width || "24"}
      height={height || "24"}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_40_1550)">
        <path
          d="M23.9999 12C23.9999 5.37263 18.6274 0 11.9999 0C9.79875 0 7.73737 0.595503 5.96362 1.63012V22.3699C7.73737 23.4045 9.79875 24 11.9999 24C18.6274 24 23.9999 18.6273 23.9999 12Z"
          fill="#1D6B17"
        />
        <path
          d="M5.96362 1.62988V22.3696C2.39812 20.2896 0 16.4259 0 11.9998C0 7.57364 2.39812 3.71 5.96362 1.62988ZM16.2536 10.1851L16.413 11.6664L17.1817 10.3865L18.6442 10.6959H18.6492L17.6647 9.57089L18.4099 8.27713L17.0367 8.85838L16.0335 7.75214L16.1598 9.23788L14.8005 9.84726L16.2536 10.1851ZM12.0975 17.054C12.0878 17.0496 12.0779 17.045 12.0682 17.0405C9.19988 15.7122 7.95113 12.3106 9.279 9.44226C9.9195 8.01051 11.1349 6.91588 12.6259 6.42802C12.4327 6.58889 12.2482 6.75913 12.0727 6.93876C10.1243 9.09651 10.2941 12.4254 12.4518 14.3736C14.5021 16.2249 17.6341 16.1758 19.6256 14.2628C18.3158 17.1103 14.9475 18.3598 12.0975 17.054Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_40_1550">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default PakistanFlag;
