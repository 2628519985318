import React from "react";

function AddTimeOffRequest(props) {
  return (
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M7.58366 6.41675H12.8337V7.58341H7.58366V12.8334H6.41699V7.58341H1.16699V6.41675H6.41699V1.16675H7.58366V6.41675Z"
        fill="#131599"
      />
    </svg>
  );
}

export default AddTimeOffRequest;
