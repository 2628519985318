//employee constants
import {
  RESET_ADD_EMPLOYEE_EXPERIENCE,
  ADD_EMPLOYEE_EXPERIENCE,
  ADD_EMPLOYEE_EXPERIENCE_SUCCESS,
  ADD_EMPLOYEE_EXPERIENCE_FAILURE,
} from "../../../constants/Workforce/AddEmployee/AddEmployeeExperience";

const INIT_STATE = {
  addEmployeeExperience: {},
  loadingAddEmployeeExperience: false,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case RESET_ADD_EMPLOYEE_EXPERIENCE:
      return INIT_STATE;
    case ADD_EMPLOYEE_EXPERIENCE:
      return {
        ...state,
        loadingAddEmployeeExperience: true,
      };

    case ADD_EMPLOYEE_EXPERIENCE_SUCCESS:
      return {
        ...state,
        loadingAddEmployeeExperience: false,
        addEmployeeExperience: action.payload,
      };

    // eslint-disable-next-line no-fallthrough
    case ADD_EMPLOYEE_EXPERIENCE_FAILURE:
      return {
        ...state,
        loadingAddEmployeeExperience: false,
        addEmployeeExperience: "Error in  getting Club Data",
      };
    default:
      return state;
  }
};
