import { call, put, takeEvery } from "redux-saga/effects";
//actions
import {
    GetCustomerUserandCompanyProfileDataSuccess,
    GetCustomerUserandCompanyProfileDataFailure,
} from "../../actions/Settings/CustomerUser&CompanyProfileData";
//constant
import { CUSTOMER_USER_AND_COMPANY_PROFILE } from "../../constants/Settings/CustomerUser&CompanyProfileData";
//request type
import getRequest from "../../../util/APIHelperGet";
import { baseUrl } from "../../../util/APIBaseUrl";

export const fetchCustomerUserandCompanyProfileDateRequest = async (
    actions
) => {
    // eslint-disable-next-line no-return-await

    return await getRequest(`${baseUrl}/${actions.payload.request}`)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return error;
        });
};

function* fetchCustomerUserandCompanyProfileDate(params) {
    try {
        let response = yield call(
            fetchCustomerUserandCompanyProfileDateRequest,
            params
        );
        yield put(GetCustomerUserandCompanyProfileDataSuccess(response));
    } catch (error) {
        console.error(error);
        yield put(GetCustomerUserandCompanyProfileDataFailure(error));
    }
}

export default function* rootSaga() {
    yield takeEvery(
        CUSTOMER_USER_AND_COMPANY_PROFILE,
        fetchCustomerUserandCompanyProfileDate
    );
}
