import React, { useState } from "react";
import { useDispatch } from "react-redux";

import Dialog, { DialogContent, DialogActions } from "components/common/Dialog";
import CustomButton from "components/common/Button";
import Text from "components/common/Text";
import SearchableAutoComplete from "components/common/AutoComplete";

import { baseUrl } from "util/APIBaseUrl";
import postRequest from "util/APIHelperPost";
import { showSnackbar } from "store/actions/Utility";

import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";

function ReAssignManager({
  employeeData,
  managersList,
  getCustomerAdminEmployeeList,
  setOpenReAssignManagerModal,
  openReAssignManagerModal,
}) {
  const [isLoading, setIsLoading] = useState(false);
  const [selectedManager, setSelectedManager] = useState(null);

  const dispatch = useDispatch();

  const employeeId = employeeData?.employeeId;

  const handleReAssignManager = async () => {
    setIsLoading(true);
    try {
      const response = await postRequest(
        `${baseUrl}/customerUsers/${selectedManager?.userId}/reassignEmployee`,
        JSON.stringify({ employeeId })
      );

      if (response.ok) {
        setIsLoading(false);
        setOpenReAssignManagerModal(false);
        getCustomerAdminEmployeeList();
        dispatch(
          showSnackbar({
            type: "success",
            message: `${employeeData?.firstName} ${employeeData?.lastName} has been assigned to ${selectedManager?.firstName} ${selectedManager?.lastName}`,
            dispatch,
          })
        );
      } else {
        dispatch(
          showSnackbar({
            type: "error",
            message: `Error Assigning ${employeeData?.firstName} ${employeeData?.lastName} to ${selectedManager?.firstName} ${selectedManager?.lastName}`,
            dispatch,
          })
        );
        setIsLoading(false);
        setOpenReAssignManagerModal(false);
      }
    } catch (error) {
      dispatch(
        showSnackbar({
          type: "error",
          message: "Error Unassigning manager",
          dispatch,
        })
      );
      setIsLoading(false);
      setOpenReAssignManagerModal(false);
    }
  };

  const handleClose = () => {
    setOpenReAssignManagerModal(false);
  };

  const handleManagerChange = (newValue) => {
    setSelectedManager(newValue);
  };

  const filteredManagers = managersList.filter((manager) => manager.userId !== employeeData?.managedBy);

  const dialogContent = (
    <DialogContent>
      <div className="heading-h2-semibold">Reassign Employee</div>
      <div className="flex items-end justify-between gap-075 mt-1">
        <Text
          name="employee-name"
          label="Employee"
          size="medium"
          disabled
          value={`${employeeData?.firstName} ${employeeData?.lastName}`}
        />
        <div className="mb-05" style={{ color: "#ACAAB4" }}>
          <KeyboardDoubleArrowRightIcon />
        </div>
        <SearchableAutoComplete
          name="manager"
          size="medium"
          label="Manager"
          placeholder="Select Manager"
          options={filteredManagers.map((user) => ({
            label: `${user.firstName} ${user.lastName}`,
            value: user,
            key: user.userId,
          }))}
          onChange={handleManagerChange}
        />
      </div>
    </DialogContent>
  );

  const dialogActions = (
    <DialogActions
      action1={<CustomButton label="Cancel" onClick={handleClose} size="large" color="primary" variant="text" />}
      action2={
        <CustomButton
          label="Assign Now"
          onClick={handleReAssignManager}
          size="large"
          color="primary"
          isLoading={isLoading}
          variant="contained"
          isDisabled={!selectedManager}
        />
      }
    />
  );

  return (
    <Dialog
      open={openReAssignManagerModal}
      onClose={handleClose}
      content={dialogContent}
      actions={dialogActions}
      size="sm"
    />
  );
}

export default ReAssignManager;
