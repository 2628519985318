/* eslint-disable camelcase */
import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import {
  Autocomplete,
  Divider,
  IconButton,
  Stack,
  TextField,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  CircularProgress,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import moment from "moment";

import ToolBar from "components/Widgets/PageToolBarV2";
import CustomDropdown from "components/common/Dropdown";
import EmployeeAvatar from "components/Screens/Timeoff/TimeoffAdmin/EmployeeTimeOffDetails/EmployeeAvatar";
import ArrowIcons from "components/Screens/Timeoff/TimeoffAdmin/EmployeeTimeOffDetails/Icons/ArrowIcons";
import NoteTextIcon from "components/Screens/Timeoff/TimeoffAdmin/EmployeeTimeOffDetails/Icons/NoteTextIcon";
import PersonIcon from "components/Screens/Timeoff/TimeoffAdmin/EmployeeTimeOffDetails/Icons/PersonIcon";
import ExpandIcon from "./ExpandIcon";
import NothingToSeeIcon from "components/Screens/Timeoff/TimeoffAdmin/NothingToSeeIcon";
import LeaveType from "components/Screens/Timeoff/ManageTimeoff/Common/LeaveType";
import BackIcon from "components/Screens/Timeoff/TimeoffAdmin/EmployeeTimeOffDetails/Icons/BackIcon";
import EditByHrStatus from "components/Screens/Timeoff/ManageTimeoff/Common/EditByHrStatus";
import EmployeeTimeOffDetails from "./EmployeeTimeOffDetails";
import { NEW_ACTIVITY, LEAVE_STATUS } from "./Enum";
import { usePermissions } from "components/Hook";

import { FetchLeaveTypes } from "store/actions/Timeoff/LeaveTypes";
import { GetCustomersList, ResetGetCustomersList } from "store/actions/Customers/GetCustomerList";
import { GetEmployeeDetails, ResetGetEmployeeDetails } from "store/actions/GetEmployeeDetails";
import { ResetAddEmployeeSalaryData } from "store/actions/Workforce/AddEmployee/AddEmployeeSalaryData";
import { ResetAddEmployeeSalary } from "store/actions/Workforce/AddEmployee/AddEmployeeSalary";
import { ResetCalculateEmployeeSalary } from "store/actions/Workforce/AddEmployee/CalculateEmployeeSalary";
import { getEmployeesNewActivity } from "components/Screens/Timeoff/TimeoffAdmin/TimeOffAdminServices/EmployeeNewAvtivity";

import {
  countryDropdown,
  leavesTypesDropdown,
  sortByDropdown,
  timeOffFlagDropdown,
  yearDropdown,
} from "components/Screens/Timeoff/TimeoffAdmin/Constants";
import { getStartDateAndEndDate, Employee_TIMEOFF_REQUESTS } from "constants";
import { baseUrl } from "util/APIBaseUrl";

import DropdownIcon from "assets/images/chevron-bottom.svg";
import "./EmployeeTimeoffNewActivity.css";

const dropdownStyles = {
  borderRadius: "33px",
  border: "1px solid var(--Primary-90, #E0E0FF)",
  background: "var(--Primary-99, #FBFAFF)",
  minWidth: "auto",
  display: "flex",
  padding: "4px 12px",
};

const customDropdownStyles = { display: "flex" };

function applyFilter({ inputData, query }) {
  let filteredData = inputData;
  if (query) {
    filteredData = filteredData?.filter(
      (activity) => activity?.employeeName?.toLowerCase().indexOf(query.toLowerCase()) !== -1
    );
  }

  return filteredData;
}

function getTimeOffFlagLabel(timeOffFlag) {
  if (timeOffFlag === "shortNotice") {
    return "short notice period";
  }
  if (timeOffFlag === "overlaps") {
    return "Overlap";
  }
  return "";
}

function EmployeeTimeoffNewActivity() {
  const location = useLocation();
  const dispatch = useDispatch();

  const queryParams = new URLSearchParams(location.search);
  const leaveType = queryParams.get("leaveType");
  const country = queryParams.get("country");
  const flags = queryParams.get("flags");
  const navigate = useNavigate();

  const { loggedInUser } = usePermissions();

  const [currentTab, setCurrentTab] = useState("All");
  const [searchQuery, setSearchQuery] = useState("");
  const [timeOffTypes, setTimeOffTypes] = useState([]);
  const [tabs, setTabs] = useState(Employee_TIMEOFF_REQUESTS);
  const [screenView, setScreenView] = useState(NEW_ACTIVITY.ALL_EMPLOYEES_REQUESTS);
  const [companyDropdownOptions, setCompanyDropdownOptions] = useState([]);
  const [employeeSelectedActivity, setEmployeeSelectedActivity] = useState(null);
  const [searchCustomerTimer, setSearchCustomerTimer] = useState(null);
  const [selectedFilter, setSelectedFilter] = useState({
    selectedLeaveType: 1,
    country: null,
    year: "thisWeek",
    customerId: null,
    timeOffFlag: null,
    sortBy: "DateFormDESC",
  });

  const [employeesNewActivityList, setEmployeesNewActivityList] = useState([]);
  const [newActivityListLoading, setNewActivityListLoading] = useState(false);
  const [selectedEmployeeID, setSelectedEmployeeID] = useState(null);

  const leaveTypes = useSelector((state) => state?.TimeoffLeaveTypes?.leaveTypes);
  const getCustomerList = useSelector((state) => state?.GetCustomerList?.getCustomerList);
  const getEmployeeDetails = useSelector((state) => state?.GetEmployeeDetails?.getEmployeeDetails);

  const getSortByFilterValue = () => {
    const selectedOption = sortByDropdown?.find((leave) => leave?.key === selectedFilter?.sortBy);
    if (selectedOption) {
      return { fieldName: selectedOption?.fieldName, direction: selectedOption?.direction };
    }
    return null;
  };

  const fetchEmployeesNewActivity = async () => {
    try {
      setNewActivityListLoading(true);
      const dateRange = getStartDateAndEndDate(selectedFilter?.year);
      let payload = {
        leaveTypeId: selectedFilter?.selectedLeaveType || "",
        country: selectedFilter?.country || "",
        year: selectedFilter?.year || "",
        customerId: selectedFilter?.customerId?.value || "",
        flag: selectedFilter?.timeOffFlag || "",
        employeeId: "",
        startDate: dateRange?.startDate,
        endDate: dateRange?.endDate,
      };
      if (selectedFilter?.sortBy) {
        const sortByPayload = getSortByFilterValue();
        if (sortByPayload) {
          payload = { ...payload, sortBy: sortByPayload };
        }
      }
      const response = await getEmployeesNewActivity(payload);
      if (response?.success) {
        setNewActivityListLoading(false);
        const allTabsCount = Employee_TIMEOFF_REQUESTS.map((activity) => ({
          ...activity,
          count: (response?.data[activity?.title?.toLowerCase()] || [])?.length,
        }));
        setTabs(allTabsCount || Employee_TIMEOFF_REQUESTS);
        setEmployeesNewActivityList(response?.data);
      } else setNewActivityListLoading(false);
    } catch (error) {
      setNewActivityListLoading(false);
    }
  };

  const ResetActivityList = () => {
    setEmployeesNewActivityList([]);
  };

  const searchCustomer = (customerName) => {
    clearTimeout(searchCustomerTimer);
    const newTimer = setTimeout(() => {
      if (customerName.length >= 3) {
        dispatch(
          GetCustomersList({
            customerName,
            loaderFlag: false,
          })
        );
      } else {
        dispatch(ResetGetCustomersList({}));
      }
    }, 500);

    setSearchCustomerTimer(newTimer);
  };

  const renderToolBar = () => {
    return (
      <ToolBar
        tabs={tabs}
        currentTab={currentTab}
        setCurrentTab={setCurrentTab}
        styles={{
          marginLeft: "14px",
          padding: "0px",
          paddingBottom: "12px",
          maxWidth: "fit-content !important",
        }}
        toolBarWrapperStyles={{
          borderRadius: "19.738px",
          border: "1px solid var(--Primary-90, #E0E0FF)",
          background: "#F8F6FF",
          padding: "0px 1px",
          overflowX: "scroll",
        }}
      />
    );
  };

  const getLeaveDate = (employeeActivity) => {
    switch (employeeActivity?.status) {
      case LEAVE_STATUS.APPROVED:
        return employeeActivity?.approvedDate
          ? `Approved Date: ${moment(employeeActivity?.approvedDate).format("MMM DD, YYYY")}`
          : null;

      case LEAVE_STATUS.REJECTED:
        return employeeActivity?.rejectedDate
          ? `Rejected date: ${moment(employeeActivity?.rejectedDate).format("MMM DD, YYYY")}`
          : null;

      case LEAVE_STATUS.CANCELLED:
        return employeeActivity?.cancelledDate
          ? `Cancelled date: ${moment(employeeActivity?.cancelledDate).format("MMM DD, YYYY")}`
          : null;

      case LEAVE_STATUS.Pending:
        return employeeActivity?.createdAt
          ? `Requested date: ${moment(employeeActivity?.createdAt).format("MMM DD, YYYY")}`
          : null;

      case LEAVE_STATUS.Taken:
        return employeeActivity?.approvedDate
          ? `Scheduled date: ${moment(employeeActivity?.approvedDate).format("MMM DD, YYYY")}`
          : null;
      default:
        return null;
    }
  };

  const navigateToEmployeeProfile = (employeeId) => {
    dispatch(ResetAddEmployeeSalaryData({}));
    dispatch(ResetGetEmployeeDetails({}));
    dispatch(ResetAddEmployeeSalary({}));
    dispatch(ResetCalculateEmployeeSalary({}));
    if (Object.keys(loggedInUser).length && loggedInUser?.userId && employeeId) {
      setSelectedEmployeeID(employeeId);
      dispatch(
        GetEmployeeDetails({
          customerID: loggedInUser?.userId,
          employeeID: employeeId,
          request: `employee/${employeeId}`,
        })
      );
    }
  };

  const getTableRow = (employeeActivity, index, employeeActivityLength) => {
    return (
      <div style={{ position: "relative", overflowX: "scroll", cursor: "pointer" }}>
        <div className="employee-details-on-notice-period">
          <div style={{ mt: "12px", width: "25%" }}>
            <EmployeeAvatar
              img={`${baseUrl}/employee/${employeeActivity?.employeeId}/logo?id=${employeeActivity?.pictureVersion}&variant=thumbnail`}
              name={employeeActivity?.employeeName || ""}
              jobTitle={employeeActivity?.companyName || ""}
              country={employeeActivity?.country}
            />
          </div>

          <Stack spacing="1px" sx={{ width: "25%" }}>
            <div className="employee-sick-leaves">
              {employeeActivity?.originalLeaveType &&
              employeeActivity?.originalLeaveType !== employeeActivity?.leaveType ? (
                <>
                  <s>{`${employeeActivity?.originalLeaveType}`}</s>

                  <span style={{ paddingLeft: "8px" }}>{`${employeeActivity?.leaveType}`}</span>
                </>
              ) : (
                `${employeeActivity?.leaveType}`
              )}
            </div>

            <Stack gap="6px" direction="row" alignItems="center">
              <div className="employee-sick-leave-date" id="start-date">
                {moment(employeeActivity?.dateFrom).format("MMM DD, YYYY")}
              </div>
              {employeeActivity?.dateTo && (
                <>
                  <ArrowIcons />
                  <div className="employee-sick-leave-date" id="end-date">
                    {moment(employeeActivity?.dateTo).format("MMM DD, YYYY")}
                  </div>
                </>
              )}
            </Stack>
          </Stack>

          <Stack spacing="5px" sx={{ width: "25%" }}>
            <Stack direction="row" alignItems="center" sx={{ height: "22px" }}>
              <div className="time-off-used">Duration</div>
              <div className="time-off-days" id="timeoff-used-admin">
                {employeeActivity?.noOfLeaves || 0} days
              </div>
            </Stack>

            {/* TODO: Unit Test */}
            <Stack direction="row" alignItems="center" sx={{ height: "22px" }}>
              <div className="time-off-used">Notice period</div>
              <div className="time-off-days" id="notice-period-admin">
                {Math.abs(
                  moment(employeeActivity?.dateFrom, "YYYY-MM-DD").diff(
                    moment(employeeActivity?.createdAt, "YYYY-MM-DD"),
                    "days"
                  ) || 0
                )}{" "}
                days
              </div>
            </Stack>

            <Stack direction="row" alignItems="center" sx={{ height: "22px" }}>
              <div className="time-off-used">Status</div>
              <div style={{ marginLeft: "2px" }}>
                <LeaveType type={employeeActivity?.leaveStatus || ""} />
              </div>
            </Stack>

            {employeeActivity?.country === "Peru" &&
              (employeeActivity?.leaveType === "Sick" || employeeActivity?.hrEdit || employeeActivity?.hrApproved) && (
                <Stack direction="row" alignItems="center" sx={{ height: "22px" }}>
                  <div className="time-off-used">HR doc review</div>
                  <div style={{ marginLeft: "2px" }}>
                    <EditByHrStatus
                      style={{ width: "fit-content !important" }}
                      hrEdit={employeeActivity?.hrEdit}
                      hrApproved={employeeActivity?.hrApproved}
                      leaveStatus={employeeActivity?.status}
                    />
                  </div>
                </Stack>
              )}
          </Stack>

          <Stack
            direction="row"
            spacing={4}
            alignItems="center"
            sx={{
              width: "25%",
              display: "flex",
              alignItems: "center",
              paddingTop: "7px",
            }}
          >
            <IconButton
              onClick={() => {
                setEmployeeSelectedActivity(employeeActivity);
                setScreenView(NEW_ACTIVITY.EMPLOYEE_TIME_OFF_DETAILS);
              }}
              id="notes-icon"
            >
              <NoteTextIcon />
            </IconButton>

            <IconButton id="person-icon" onClick={() => navigateToEmployeeProfile(employeeActivity?.employeeId)}>
              <PersonIcon />
            </IconButton>
          </Stack>
        </div>

        <div className="leave-state-date">{getLeaveDate(employeeActivity)}</div>

        {index !== employeeActivityLength - 1 && (
          <Divider sx={{ border: "1px solid var(--Neutral-90, #D9DAF2)", opacity: 0.3 }} />
        )}
      </div>
    );
  };

  const getOverLapTable = (employeeActivity, index, employeeActivityTotalLength) => {
    return (
      <Accordion
        sx={{
          width: "100%",
          background: "var(--Primary-99, #FBFAFF)",
          "&.MuiAccordion-root": {
            borderTopLeftRadius: index === 0 ? "25px !important" : null,
            borderTopRightRadius: index === 0 ? "25px !important" : null,
            borderBottomLeftRadius: index === employeeActivityTotalLength - 1 ? "25px !important" : null,
            borderBottomRightRadius: index === employeeActivityTotalLength - 1 ? "25px !important" : null,
          },
        }}
      >
        <AccordionSummary expandIcon={<ExpandIcon />} aria-controls="panel1a-content" id="panel1a-header">
          <Typography className="overlap-timeoff-comapny-name">{`${employeeActivity?.companyName}  (${employeeActivity?.list?.length})`}</Typography>
        </AccordionSummary>
        <AccordionDetails
          sx={{
            background: "white",
            "&.MuiAccordionDetails-root": {
              padding: "0px !important",
            },
          }}
        >
          <Stack direction="row" sx={{ flexFlow: "wrap", gap: "15px", display: "contents" }}>
            {employeeActivity?.list?.map((activity, activityIndex) => {
              return getTableRow(activity, activityIndex, employeeActivity?.list?.length);
            })}
          </Stack>
        </AccordionDetails>
      </Accordion>
    );
  };

  const handleSearch = (e) => {
    const searchValue = e.target.value.toLowerCase();
    setSearchQuery(searchValue);
  };

  const dataFiltered = applyFilter({
    inputData: employeesNewActivityList[currentTab?.toLowerCase()] || [],
    query: searchQuery,
  });

  const getAllEmployeeTimeOffs = () => {
    return (
      <>
        {renderToolBar()}
        <Stack direction="row" gap="8px" sx={{ pr: "14px", pl: "14px", margin: "0px 0px 16px" }} alignItems="center">
          <div style={{ flex: 1, position: "relative" }}>
            <input
              className="search-employee-input"
              placeholder="Search employee"
              value={searchQuery}
              onChange={handleSearch}
            />
            <svg
              width="21"
              height="21"
              viewBox="0 0 21 21"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              style={{
                position: "absolute",
                top: "50%",
                right: "15px",
                transform: "translateY(-50%)",
                cursor: "pointer",
              }}
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M14.067 12.9214L18.5564 17.4108L17.3779 18.5893L12.8885 14.0999C11.7596 14.9775 10.341 15.5 8.80046 15.5C5.11856 15.5 2.13379 12.5153 2.13379 8.83335C2.13379 5.15146 5.11856 2.16669 8.80046 2.16669C12.4824 2.16669 15.4671 5.15146 15.4671 8.83335C15.4671 10.3739 14.9446 11.7925 14.067 12.9214ZM8.80046 13.8334C11.5619 13.8334 13.8005 11.5948 13.8005 8.83335C13.8005 6.07193 11.5619 3.83335 8.80046 3.83335C6.03903 3.83335 3.80046 6.07193 3.80046 8.83335C3.80046 11.5948 6.03903 13.8334 8.80046 13.8334Z"
                fill="black"
              />
            </svg>
          </div>

          <Stack direction="row" spacing="8px" alignItems="center">
            <div className="time-off-used" style={{ width: "fit-content" }}>
              Sort by:
            </div>
            <CustomDropdown
              placeholder="Sort by"
              options={sortByDropdown}
              onSelect={(key) => {
                setSelectedFilter({
                  ...selectedFilter,
                  sortBy: key,
                });
              }}
              customDropdownStyles={{ ...customDropdownStyles }}
              buttonLabel={sortByDropdown?.find((leave) => leave?.key === selectedFilter?.sortBy)?.label}
              style={{ ...dropdownStyles, width: "195px", height: "30px" }}
            />
          </Stack>
        </Stack>
        <div className="results-for-short-notice-periods">
          Results {selectedFilter?.timeOffFlag && "for"}{" "}
          <span style={{ color: "var(--Primary-20, #131599)" }}>
            {getTimeOffFlagLabel(selectedFilter?.timeOffFlag)} (
            {`${(employeesNewActivityList[currentTab?.toLowerCase()] || []).length || 0}/${
              employeesNewActivityList?.all?.length || 0
            }`}
            )
          </span>
        </div>
        <div className="container-employees-results-for-notice-periods">
          {newActivityListLoading && (
            <Stack sx={{ height: "50vh", justifyContent: "center", alignItems: "center" }}>
              <CircularProgress />
            </Stack>
          )}

          {!newActivityListLoading &&
            dataFiltered?.length > 0 &&
            dataFiltered.map((employeeActivity, index) => {
              const employeeActivityLength = dataFiltered.length;
              if (selectedFilter?.timeOffFlag === timeOffFlagDropdown[1].key) {
                return getOverLapTable(employeeActivity, index, employeeActivityLength);
              }
              return getTableRow(employeeActivity, index, employeeActivityLength);
            })}

          {!newActivityListLoading && dataFiltered?.length === 0 && (
            <Stack sx={{ m: "auto", alignItems: "center", justifyContent: "center", height: "50vh" }}>
              <NothingToSeeIcon />
              <Stack sx={{ textAlign: "center" }}>
                No Results found. <br />
              </Stack>
            </Stack>
          )}
        </div>
      </>
    );
  };

  const getAllEmployeeRequests = () => {
    if (selectedFilter?.timeOffFlag == timeOffFlagDropdown[1]?.key) {
      const overLapRequests = employeesNewActivityList?.all?.map((company) => company.list) || [];
      const allOverLapRequests = [].concat(...(overLapRequests || []));

      return allOverLapRequests;
    }

    return employeesNewActivityList?.all || [];
  };

  const handleBackToPrevScreen = () => {
    if (screenView === NEW_ACTIVITY.ALL_EMPLOYEES_REQUESTS) {
      return navigate("/main/timeoff/admin");
    }
    return setScreenView(NEW_ACTIVITY.ALL_EMPLOYEES_REQUESTS);
  };

  useEffect(() => {
    if (timeOffTypes?.length) fetchEmployeesNewActivity();
  }, [selectedFilter]);

  useEffect(() => {
    const leavesTypesWithSwappedDropdown = [
      ...leavesTypesDropdown,
      {
        label: "Swapped",
        key: 6,
      },
    ];

    setTimeOffTypes(leavesTypesWithSwappedDropdown);
  }, [leaveTypes]);

  useEffect(() => {
    if (queryParams && timeOffTypes?.length) {
      const paramsLeaveType = timeOffTypes?.find((leave) => leave?.label === leaveType)?.key || "";
      const paramsTimeOffFlags = timeOffFlagDropdown?.find((flag) => flag?.label === flags)?.key;

      setSelectedFilter({
        ...selectedFilter,
        selectedLeaveType: paramsLeaveType,
        country,
        timeOffFlag: paramsTimeOffFlags,
      });
    }
  }, [timeOffTypes]);

  useEffect(() => {
    const customers = [];
    if (getCustomerList?.ok) {
      getCustomerList?.payload?.forEach((data) => {
        customers.push({
          label: data.companyName,
          value: data.customerId,
        });
      });
    }
    setCompanyDropdownOptions(customers);
  }, [getCustomerList]);

  useEffect(() => {
    if (getEmployeeDetails?.ok) {
      if (selectedEmployeeID) {
        navigate(`/main/details/${selectedEmployeeID}?tab=Time-off&navigateFrom=admin/timeoff/new-activity`);
      }
    }
  }, [getEmployeeDetails]);

  useEffect(() => {
    dispatch(FetchLeaveTypes({}));
    dispatch(ResetGetEmployeeDetails({}));
    setSelectedEmployeeID(null);
  }, []);

  return (
    <>
      <Helmet>
        <title>Edge | Timeoff - New Activity</title>
      </Helmet>
      <div className="manage-timeoff-wrapper-adminy" style={{ mb: "20px", padding: "16px 60px" }}>
        <Stack direction="row" alignItems="flex-start" gap="5px">
          <IconButton onClick={() => handleBackToPrevScreen()}>
            <BackIcon />
          </IconButton>
          <Stack sx={{ mb: "17px" }}>
            <div className="page-header">New Activity | Time-off</div>
            <div className="page-subheader">Manage time-off requests and view upcoming holidays here</div>
          </Stack>
        </Stack>

        <div className="new-activity-time-off-page-container">
          {screenView === NEW_ACTIVITY.ALL_EMPLOYEES_REQUESTS && (
            <Stack
              direction="row"
              spacing={1}
              sx={{
                flexFlow: "wrap",
                padding: "12px 14px",
                height: "52px",
                borderBottom: "1px solid #D9DAF2",
                rowGap: "10px",
                marginBottom: "14px",
              }}
            >
              <CustomDropdown
                options={yearDropdown}
                onSelect={(key) => {
                  ResetActivityList();
                  setSelectedFilter({
                    ...selectedFilter,
                    year: key,
                  });
                }}
                customDropdownStyles={{ ...customDropdownStyles }}
                buttonLabel={yearDropdown?.find((leave) => leave?.key === selectedFilter?.year)?.label}
                style={{ ...dropdownStyles, width: "137px" }}
              />

              <CustomDropdown
                options={timeOffTypes}
                placeholder="Type"
                onSelect={(key) => {
                  ResetActivityList();
                  setSelectedFilter({
                    ...selectedFilter,
                    selectedLeaveType: key,
                  });
                }}
                customDropdownStyles={{ ...customDropdownStyles }}
                buttonLabel={
                  timeOffTypes?.find((leave) => leave?.key === selectedFilter?.selectedLeaveType)?.label || ""
                }
                style={{ ...dropdownStyles, width: "137px" }}
                onClear={() =>
                  setSelectedFilter({
                    ...selectedFilter,
                    selectedLeaveType: null,
                  })
                }
              />

              <CustomDropdown
                options={countryDropdown}
                onSelect={(key) => {
                  ResetActivityList();
                  setSelectedFilter({
                    ...selectedFilter,
                    country: key,
                  });
                }}
                customDropdownStyles={{ ...customDropdownStyles }}
                placeholder="Country"
                buttonLabel={
                  countryDropdown?.find((countryItem) => countryItem?.key === selectedFilter?.country)?.label
                }
                style={{ ...dropdownStyles, width: "158px" }}
                onClear={() =>
                  setSelectedFilter({
                    ...selectedFilter,
                    country: null,
                  })
                }
              />

              <Autocomplete
                clearOnBlur={false}
                size="small"
                limitTags={0}
                id="standard"
                popupIcon={<img className="dropdown-icon" src={DropdownIcon} alt="chevron-bottom" />}
                sx={{
                  ...dropdownStyles,
                  flex: 1,

                  mb: 1,
                  "& .MuiOutlinedInput-root": {
                    padding: "2px",
                    borderRadius: "5px",
                    fontSize: "12px",
                    height: "fit-content !important",
                    "& fieldset": {
                      display: "none",
                    },
                  },
                  "& .MuiAutocomplete-inputRoot": {
                    height: "fit-content",
                    fontFamily: "Poppins-Medium",
                    fontSize: "14px",
                  },
                  "&.MuiAutocomplete-root": {
                    marginBottom: "0px",
                    height: "30px",
                    padding: "0px",
                  },
                  "& .MuiInputBase-sizeSmall": {
                    pt: "2px !important",
                    pb: "2px !important",
                  },
                  "& input#standard::placeholder": {
                    color: "black",
                    opacity: 1,
                    fontFamily: "Poppins-Medium",
                    fontSize: "14px",
                  },
                  "& .MuiAutocomplete-popupIndicator": {
                    padding: "5px 5px",
                  },
                }}
                options={companyDropdownOptions}
                value={selectedFilter?.customerId}
                onChange={(event, option) => {
                  ResetActivityList();
                  setSelectedFilter({
                    ...selectedFilter,
                    customerId: option,
                  });
                }}
                getOptionLabel={(option) => option.label}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: "new-password",
                    }}
                    maxRows={1}
                    minRows={1}
                    variant="outlined"
                    onChange={(event) => searchCustomer(event.target.value)}
                    placeholder="Company"
                  />
                )}
              />

              <CustomDropdown
                options={timeOffFlagDropdown}
                id="flags"
                onSelect={(key) => {
                  ResetActivityList();

                  setSelectedFilter({
                    ...selectedFilter,
                    timeOffFlag: key,
                  });
                }}
                buttonLabel={timeOffFlagDropdown?.find((flag) => flag?.key === selectedFilter?.timeOffFlag)?.label}
                placeholder="Flags"
                style={{
                  ...dropdownStyles,
                  width: "225px",
                  border: "1px solid var(--error-90, #FFDAD6)",
                  background: selectedFilter?.timeOffFlag ? "var(--error-90, #FFDAD6)" : "white",
                  height: "30px",
                }}
                onClear={() =>
                  setSelectedFilter({
                    ...selectedFilter,
                    timeOffFlag: null,
                  })
                }
              />
            </Stack>
          )}
          {screenView === NEW_ACTIVITY.ALL_EMPLOYEES_REQUESTS ? (
            getAllEmployeeTimeOffs()
          ) : (
            <EmployeeTimeOffDetails
              employeesAllRequests={getAllEmployeeRequests()}
              employeeSelectedActivity={employeeSelectedActivity}
              backToAllHolidays={() => {
                setScreenView(NEW_ACTIVITY.ALL_EMPLOYEES_REQUESTS);
                setEmployeeSelectedActivity(null);
                fetchEmployeesNewActivity();
              }}
              navigateToEmployeeProfile={(employeeId) => navigateToEmployeeProfile(employeeId)}
              style={{ marginTop: "12px" }}
            />
          )}
        </div>
      </div>
    </>
  );
}

export default EmployeeTimeoffNewActivity;
