// reducers/summaryReducer.js
import {
  FETCH_ADMIN_SUMMARY_REQUEST,
  FETCH_ADMIN_SUMMARY_SUCCESS,
  FETCH_ADMIN_SUMMARY_FAILURE,
} from "store/constants/AdminSummary";

const INIT_STATE = {
  summary: null,
  loadingSummary: false,
  summaryError: "",
};

const summaryReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case FETCH_ADMIN_SUMMARY_REQUEST:
      return {
        ...state,
        loadingSummary: true,
      };
    case FETCH_ADMIN_SUMMARY_SUCCESS:
      return {
        ...state,
        loadingSummary: false,
        summary: action.payload.payload,
      };
    case FETCH_ADMIN_SUMMARY_FAILURE:
      return {
        ...state,
        loadingSummary: false,
        summaryError: action.error,
      };
    default:
      return state;
  }
};

export default summaryReducer;
