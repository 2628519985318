import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Helmet } from "react-helmet";

import TimeoffLayout from "components/Screens/Timeoff/Layout";
import ToolBar from "components/Widgets/PageToolBarV2";
import PendingLeaves from "components/Screens/Timeoff/ManageTimeoff/Customer/PendingRequest";
import ScheduledLeaves from "components/Screens/Timeoff/ManageTimeoff/Customer/ScheduledLeaves";
import RejectedLeaves from "components/Screens/Timeoff/ManageTimeoff/Customer/RejectedLeaves";
import CancelledLeaves from "components/Screens/Timeoff/ManageTimeoff/Customer/CancelledLeaves";
import TakenLeaves from "components/Screens/Timeoff/ManageTimeoff/Customer/TakenLeaves";
import LeaveList from "components/Screens/Timeoff/ManageTimeoff/Customer/LeavesList";
import Daterange from "components/common/Daterange";
import PageLoader from "components/common/PageLoader";

import { FetchLeaveRequests } from "store/actions/Timeoff/LeaveRequests";
import { EmployeeInfo } from "store/actions/EmployeeInfo";

import { getCustomerLeavesCalender, getEmployeesTimeout } from "services/useTimeoffService";
import { checkUserAdditionalInfo } from "components/Screens/CommonUtils";

import { TIMEOFF_CUSTOMER } from "constants";

import "./LeaveTimeOff.css";

function ManageTimeoff() {
  const dispatch = useDispatch();
  const [currentTab, setCurrentTab] = useState(TIMEOFF_CUSTOMER[0]?.title);
  const [loading, setLoading] = useState(false);
  const [tabs, setTabs] = useState(TIMEOFF_CUSTOMER);
  const [leavesCalender, setLeavesCalender] = useState([]);
  const [pendingLeavesDateRange, setPendingLeavesDateRange] = useState([]);
  const [selectedDateRange, setSelectedDateRange] = useState({
    startDate: new Date(),
    endDate: new Date(),
  });
  const [employeesTimeout, setEmployeesTimeout] = useState([]);

  let earliestStartDate;

  const userAdditionalInfo = useSelector((state) => state.UserAdditionalInfo?.userAdditionalInfo);
  const employeesInfo = useSelector((state) => state.EmployeeInfo.employeesInfo);

  const leaveRequests = useSelector((state) => state?.TimeoffLeaveRequests?.leaveRequests);
  const loadingLeaveRequests = useSelector((state) => state?.TimeoffLeaveRequests?.loadingLeaveRequests);

  const fetchLeavesCount = () => {
    const allTabsCount = TIMEOFF_CUSTOMER.map((activity) => ({
      ...activity,
      count: leaveRequests?.[activity?.title?.toLowerCase()]?.length,
    }));
    setTabs(allTabsCount || TIMEOFF_CUSTOMER);
  };

  const renderToolBar = () => {
    return <ToolBar tabs={tabs} currentTab={currentTab} setCurrentTab={setCurrentTab} />;
  };

  const renderContent = () => {
    switch (currentTab) {
      case "Pending":
        return <PendingLeaves updateCalendarWithPendingLeaves={(params) => setPendingLeavesDateRange(params)} />;
      case "Scheduled":
        return <ScheduledLeaves />;
      case "Rejected":
        return <RejectedLeaves />;
      case "Cancelled":
        return <CancelledLeaves />;
      case "Taken":
        return <TakenLeaves />;
      default:
        return null;
    }
  };

  const fetchCustomerLeavesCalender = async () => {
    if (earliestStartDate) {
      const tempCustomerLeaves = await getCustomerLeavesCalender(earliestStartDate);
      setLeavesCalender(tempCustomerLeaves?.payload?.data);
    }
  };

  const fetchEmployeesTimeout = async () => {
    const tempEmployeesTimeout = await getEmployeesTimeout(selectedDateRange);
    const filteredData = tempEmployeesTimeout?.payload?.data?.leaves
      ?.filter((item) => item?.status !== "Cancelled" && item?.status !== "Rejected" && item?.status !== "Pending")
      .sort((a, b) => new Date(a?.dateFrom) - new Date(b?.dateFrom));
    setEmployeesTimeout(filteredData);
  };

  const getEarliestStartDateOfEmployees = (employeeData) => {
    const rows = employeeData?.rows ?? [];
    return rows.reduce((earliest, { startDate }) => {
      const startDateObj = new Date(startDate);
      return !earliest || startDateObj < earliest ? startDateObj : earliest;
    }, null);
  };

  const getInitialLeavesData = async () => {
    setLoading(true);
    const earliestDate = getEarliestStartDateOfEmployees(employeesInfo);
    earliestStartDate = earliestDate;

    if (earliestStartDate) {
      await fetchCustomerLeavesCalender();
    }
    setLoading(false);
  };

  useEffect(() => {
    const fetchInitialData = async () => {
      try {
        setLoading(true);
        await dispatch(FetchLeaveRequests({}));
        await fetchCustomerLeavesCalender();
      } catch (error) {
        console.error("Error fetching initial data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchInitialData();
  }, [dispatch, earliestStartDate]);

  useEffect(() => {
    setCurrentTab(window?.location.hash.replace(/[^a-zA-Z ]/g, ""));
  }, [window?.location.hash]);

  useEffect(() => {
    const fetchEmployeesData = async () => {
      try {
        setLoading(true);
        await fetchEmployeesTimeout();
      } catch (error) {
        console.error("Error fetching employees timeout:", error);
      } finally {
        setLoading(false);
      }
    };

    if (selectedDateRange.startDate && selectedDateRange.endDate) {
      fetchEmployeesData();
    }
  }, [selectedDateRange]);

  useEffect(() => {
    const updateLeavesCount = async () => {
      try {
        setLoading(true);
        await fetchLeavesCount();
      } catch (error) {
        console.error("Error updating leaves count:", error);
      } finally {
        setLoading(false);
      }
    };

    updateLeavesCount();
  }, [leaveRequests]);

  useEffect(() => {
    if (checkUserAdditionalInfo(userAdditionalInfo) && userAdditionalInfo?.customerId) {
      dispatch(
        EmployeeInfo({
          customerID: userAdditionalInfo?.customerId,
          rowsPerPage: 999,
          page: 0,
          dist: null,
        })
      );
    }
  }, [userAdditionalInfo]);

  useEffect(() => {
    getInitialLeavesData();
  }, [employeesInfo]);

  return (
    <>
      <Helmet>
        <title>Edge | Timeoff</title>
      </Helmet>
      <div className="manage-timeoff-wrapper">
        {(loadingLeaveRequests || loading) && <PageLoader />}
        <TimeoffLayout />
        <div className="flex flex-row manage-timeoff-container">
          <div className="pr-3 w-70p">
            {renderToolBar()}
            <div className="pt-1">{renderContent()}</div>
          </div>
          <div className="sidebar">
            <div>
              <p className="title">Upcoming Leaves and holidays</p>
              <p className="body-sm">Click on the calendar dates to see who is off on that day</p>
              <Daterange
                pendingLeavesDateRange={pendingLeavesDateRange}
                setSelectedDateRange={setSelectedDateRange}
                leavesCalender={leavesCalender}
                months={1}
              />
            </div>
            <div>
              <LeaveList selectedDateRange={selectedDateRange} employeesTimeout={employeesTimeout} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ManageTimeoff;
