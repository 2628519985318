import React from "react";
import { Alert, List, ListItem, Snackbar, Typography } from "@mui/material";
import { ErrorSharp } from "@mui/icons-material";

function AlertError(props) {
  const {
    openAlert,
    setOpenAlert,
    errors,
    passwordMatch,
    negitiveIncrement,
    displayError = "",
  } = props;

  const getError = (errors) => {
    if (errors != null && errors != undefined) {
      return errors.map((error, index) => {
        return (
          <List
            sx={{
              listStyleType: "disc",
              paddingRight: "0px !important",
              pl: 3,
              "& .MuiListItem-root": {
                paddingRight: "0px",
                paddingLeft: "0px",
                paddingTop: "0px",
                paddingBottom: "0px",
              },
            }}
          >
            <ListItem
              sx={{
                display: "list-item",
              }}
            >
              <Typography fontSize="12px">
                {(error?.msg
                  ? error.msg
                  : error?.description
                  ? error.description
                  : "") +
                  " " +
                  (error?.param ? error?.param : "")}
              </Typography>
            </ListItem>
          </List>
        );
      });
    } else {
      return null;
    }
  };

  return (
    <Snackbar
      open={openAlert}
      autoHideDuration={2500}
      onClose={() => setOpenAlert(false)}
      sx={{
        "&.MuiSnackbar-root": {
          position: "relative",
          bottom: 0,
          left: 0,
        },
      }}
    >
      <Alert
        // onClose={() => setOpenAlert(false)}
        severity="error"
        variant="filled"
        icon={false}
        sx={{
          backgroundColor: "#313033",
          padding: "initial",
          px: "15px",
          py: "8px",
          fontSize: "14px",
          textAlign: "left",
          minWidth: "347px",

          borderRadius: "12px !important",
          color: "#ffffff !important",
          "&.MuiPaper-root": {
            color: "#ffffff !important",
            boxShadow: "0px 4px 8px rgba(2, 0, 110, 0.18)",
          },
        }}
      >
        {!(negitiveIncrement < 0) ? (
          !passwordMatch ? (
            errors.map((error, index) => {
              return error?.ok == false ? (
                getError(error?.payload?.error)
              ) : (
                <Typography fontSize="12px">
                  {displayError ?? "Enter all information before proceeding"}
                </Typography>
              );
            })
          ) : (
            <Typography fontSize="12px">Password Mismatch</Typography>
          )
        ) : (
          <Typography fontSize="12px">
            Salary cannot be lower then current salary
          </Typography>
        )}
      </Alert>
    </Snackbar>
  );
}

export default AlertError;
