import './Button.css';

const Button = () => {
  return (
    <div className="button-container p-1">
      <button className="button button-md cursor-pointer accept">Accept</button>
      <button className="button button-md cursor-pointer reject">Reject</button>
    </div>
  );
}

export default Button;
