//employee constants
import {
  RESET_ADD_CUSTOMER_BANK_INFO,
  ADD_CUSTOMER_BANK_INFO,
  ADD_CUSTOMER_BANK_INFO_SUCCESS,
  ADD_CUSTOMER_BANK_INFO_FAILURE,
} from "../../constants/Payment/AddBankAccountInfo";

const INIT_STATE = {
  addBankAccountInfo: {},
  loadingAddBankAccountInfo: false,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case RESET_ADD_CUSTOMER_BANK_INFO:
      return INIT_STATE;
    case ADD_CUSTOMER_BANK_INFO:
      return {
        ...state,
        loadingAddBankAccountInfo: true,
      };
    case ADD_CUSTOMER_BANK_INFO_SUCCESS:
      if (action.payload.ok) {
        localStorage.setItem("bankAccountDetails", action?.payload?.payload);
        return {
          ...state,
          loadingAddBankAccountInfo: false,
          addBankAccountInfo: action.payload,
        };
      }
    // eslint-disable-next-line no-fallthrough
    case ADD_CUSTOMER_BANK_INFO_FAILURE:
      localStorage.removeItem("addBankAccountInfo");
      return {
        ...state,
        loadingAddBankAccountInfo: false,
        addBankAccountInfo: "Error in  getting Club Data",
      };
    default:
      return state;
  }
};
