//employee constants
import { TABLE_FILTER_SELECTED_OPTIONS } from "../constants/TableFilterSelectedOptions";

const INIT_STATE = {
    tableFilterSelectedOptions: {
   
    },
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case TABLE_FILTER_SELECTED_OPTIONS:
            return {
                ...state,
                tableFilterSelectedOptions: action.payload,
            };

        default:
            return state;
    }
};
