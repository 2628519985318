//employee constants
import { PAYROLL_SUMMARY, PAYROLL_SUMMARY_SUCCESS, PAYROLL_SUMMARY_FAILURE } from "../constants/PayrollSummary";

const INIT_STATE = {
  payrollSummary: {},
  loadingPayrollSummary: false,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case PAYROLL_SUMMARY:
      return {
        ...state,
        loadingPayrollSummary: true,
      };
    case PAYROLL_SUMMARY_SUCCESS:
      // const newState = Object.assign(
      //     {},
      //     state.PayrollSummary,
      //     action.payload
      // );

      return {
        ...state,
        loadingPayrollSummary: false,
        payrollSummary: action.payload,
      };
    // eslint-disable-next-line no-fallthrough
    case PAYROLL_SUMMARY_FAILURE:
      return {
        ...state,
        loadingPayrollSummary: false,
        payrollSummary: "Error in  getting Club Data",
      };
    default:
      return state;
  }
};
