import { Avatar, Grid, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import { getValue } from "../../CommonUtils";
import { baseUrl } from "../../../../util/APIBaseUrl";

function UserProfileWithInfo(props) {
  const { user } = props;

  const [profileUrl, setProfileUrl] = React.useState(null);

  React.useEffect(() => {
    let loggedInUser = JSON.parse(localStorage.getItem("loggedInUser"));
    let userProfileUrl = null;
    if (user && Object.keys(user).length && Object.keys(loggedInUser).length && loggedInUser.userId != undefined) {
      if (loggedInUser?.type == "customer") {
        userProfileUrl = `${baseUrl}/customerUser/${user?.userId}/logo?id=${user?.pictureVersion}`;
      } else if (loggedInUser?.type == "edge") {
        userProfileUrl = `${baseUrl}/user/${user?.userId}/logo?id=${user?.pictureVersion}`;
      }

      setProfileUrl(userProfileUrl);
    }
  }, [user]);
  return (
    <Grid item container spacing={2}>
      <Grid item>
        <Avatar
          sx={{
            width: "71px",
            height: "71px",
            borderRadius: "12px",
          }}
          variant="square"
          src={profileUrl}
        />
      </Grid>
      <Grid item>
        <Box
          sx={{
            minHeight: "71px",
            maxWidth: "250px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-evenly",
          }}
        >
          <Typography
            sx={{
              fontFamily: "Poppins-Medium",
              fontSize: "14px",
              fontWeight: 500,
              lineHeight: "20px",
              color: "#212231",
            }}
          >
            {getValue(user?.firstName) + " " + getValue(user?.lastName)}
          </Typography>

          {user?.permissions?.map((permission, index) => {
            return (
              <Typography
                sx={{
                  fontFamily: "Poppins-Regular",
                  fontSize: "14px",
                  fontWeight: 400,
                  lineHeight: "20px",
                  color: "#212231",
                }}
              >
                {permission == "EdgeAdmin" || permission == "CustomerAdmin"
                  ? "Admin"
                  : permission == "EdgeEmployee" || permission == "CustomerEmployee"
                  ? "Employee"
                  : permission}
              </Typography>
            );
          })}

          {/* <Typography
                        sx={{
                            fontFamily: "Poppins-Regular",
                            fontSize: "14px",
                            fontWeight: 400,
                            lineHeight: "20px",
                            color: "#212231",
                        }}
                    >
                        Admin
                    </Typography> */}

          <Typography
            sx={{
              fontFamily: "Poppins-Regular",
              fontSize: "14px",
              fontWeight: 400,
              lineHeight: "20px",
              color: "#ACAAB4",
            }}
          >
            {getValue(user?.email)}
          </Typography>
        </Box>
      </Grid>
    </Grid>
  );
}

export default UserProfileWithInfo;
