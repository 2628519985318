//employee constants
import {
  RESET_GOOGLE_SIGNUP,
  GOOGLE_SIGNUP,
  GOOGLE_SIGNUP_SUCCESS,
  GOOGLE_SIGNUP_FAILURE,
} from "../../constants/Google/Google_signup";
import jwt from "jwt-decode"; // import dependency

const INIT_STATE = {
  googleSignupInfo: {},
  loadingGoogleSignupInfo: false,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case RESET_GOOGLE_SIGNUP:
      return INIT_STATE;

    case GOOGLE_SIGNUP:
      return {
        ...state,
        loadingGoogleSignupInfo: true,
      };
    case GOOGLE_SIGNUP_SUCCESS:
      if (action.payload.ok) {
        const user = jwt(action.payload?.payload?.accessToken); // decode your token here

        localStorage.setItem("accessToken", action.payload?.payload?.accessToken);
        localStorage.setItem("loggedInUser", JSON.stringify(user));
        localStorage.setItem("refreshToken", action.payload?.payload?.refreshToken);
        return {
          ...state,
          loadingGoogleSignupInfo: false,
          googleSignupInfo: action.payload,
        };
      } else {
        return {
          ...state,
          loadingGoogleSignupInfo: false,
          googleSignupInfo: action.payload,
        };
      }
    // eslint-disable-next-line no-fallthrough
    case GOOGLE_SIGNUP_FAILURE:
      localStorage.removeItem("accessToken");
      return {
        ...state,
        loadingGoogleSignupInfo: false,
        googleSignupInfo: "Error in  getting Customer Data",
      };
    default:
      return state;
  }
};
