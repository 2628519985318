import {
    ADD_EMPLOYEE_SALARY_DATA,
    RESET_ADD_EMPLOYEE_SALARY_DATA,
} from "../../../constants/Workforce/AddEmployee/AddEmployeeSalaryData";

// for user authentication
export const ResetAddEmployeeSalaryData = (data) => {
    return {
        type: RESET_ADD_EMPLOYEE_SALARY_DATA,
        payload: data,
    };
};

export const AddEmployeeSalaryData = (data) => {
    return {
        type: ADD_EMPLOYEE_SALARY_DATA,
        payload: data,
    };
};
