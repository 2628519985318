import React from "react";
import { Box } from "@mui/material";
import TextFieldV2 from "./TextFieldV2";

import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import PhoneAndroidOutlinedIcon from "@mui/icons-material/PhoneAndroidOutlined";

function TextFieldWithIcon(props) {
  const { name, ...rest } = props;

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        flex: 1,
        border: "1px solid #C4C4C4",
        borderRadius: "4px",
      }}
    >
      {name === "email" && <EmailOutlinedIcon style={{ marginLeft: "8px", color: "#787B97" }} />}
      {name === "phoneNumber" && <PhoneAndroidOutlinedIcon style={{ marginLeft: "8px", color: "#787B97" }} />}
      <TextFieldV2 fontSize={12} fontWeight={400} {...rest} hiddenLabel />
    </Box>
  );
}

export default TextFieldWithIcon;
