import React from "react";

const GiftIcon = ({ color = "#484649", style }) => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={style}
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M4.3518 6.04134C4.09197 5.61651 3.95831 5.0936 3.95831 4.45801C3.95831 3.20487 4.90067 2.08301 6.33331 2.08301C7.76781 2.08301 8.92465 3.0438 9.49998 4.54812C10.0753 3.0438 11.2322 2.08301 12.6666 2.08301C14.0993 2.08301 15.0416 3.20487 15.0416 4.45801C15.0416 5.0936 14.908 5.61651 14.6482 6.04134H15.8333C16.7078 6.04134 17.4166 6.75022 17.4166 7.62467V9.20801C17.4166 10.0825 16.7078 10.7913 15.8333 10.7913V16.333C15.8333 17.2075 15.1244 17.9163 14.25 17.9163H4.74998C3.87553 17.9163 3.16665 17.2075 3.16665 16.333V10.7913C2.2922 10.7913 1.58331 10.0825 1.58331 9.20801V7.62467C1.58331 6.75022 2.2922 6.04134 3.16665 6.04134H4.3518ZM8.70831 7.62467H3.16665V9.20801H8.70831V7.62467ZM10.2916 9.20801H15.8333V7.62467H10.2916V9.20801ZM8.70831 16.333V10.7913H4.74998V16.333H8.70831ZM10.2916 16.333H14.25V10.7913H10.2916V16.333ZM6.33331 3.66634C5.8422 3.66634 5.54165 4.02414 5.54165 4.45801C5.54165 5.45015 6.34677 5.92189 8.2566 6.02114C8.04434 4.54636 7.25337 3.66634 6.33331 3.66634ZM12.6108 3.66634C11.6907 3.66634 10.8997 4.54636 10.6875 6.02114C12.5973 5.92189 13.4024 5.45015 13.4024 4.45801C13.4024 4.02414 13.1019 3.66634 12.6108 3.66634Z"
        fill={color}
      />
    </svg>
  );
};

export default GiftIcon;
