//employee constants
import {
  RESER_ASSIGN_EMPLOYEE,
  ASSIGN_EMPLOYEE,
  ASSIGN_EMPLOYEE_SUCCESS,
  ASSIGN_EMPLOYEE_FAILURE,
} from "../../../constants/Workforce/AssignEmployee";

const INIT_STATE = {
  assignEmployee: {},
  loadingAssignInfo: false,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case RESER_ASSIGN_EMPLOYEE:
      return INIT_STATE;
    case ASSIGN_EMPLOYEE:
      return {
        ...state,
        loadingAssignInfo: true,
      };
    case ASSIGN_EMPLOYEE_SUCCESS:
      return {
        ...state,
        loadingAssignInfo: false,
        assignEmployee: action.payload,
      };
    // eslint-disable-next-line no-fallthrough
    case ASSIGN_EMPLOYEE_FAILURE:
      return {
        ...state,
        loadingAssignInfo: false,
        assignEmployee: "Error in  getting Club Data",
      };
    default:
      return state;
  }
};
