import React from "react";
import PropTypes from "prop-types";
import { Chip, IconButton } from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";

import "./Chip.scss";

function CustomChip({
  label,
  onClick,
  onDelete,
  variant = "filled",
  color = "default",
  size = "medium",
  startIcon = null,
  isDisabled = false,
  showDeleteIcon = false,
  className = "",
  ...other
}) {
  const getColorClass = () => {
    return `chip-${variant}-${color}`;
  };

  const getSizeClass = () => {
    return `chip-${size}`;
  };

  const getDisabledClass = () => {
    return isDisabled ? "chip-disabled" : "";
  };

  const handleDelete = (event) => {
    event.stopPropagation();
    if (onDelete && !isDisabled) {
      onDelete(event);
    }
  };

  return (
    <Chip
      className={`${getColorClass()} ${getSizeClass()} ${getDisabledClass()} ${className} chip`}
      label={<span className="para-body-s-medium">{label}</span>}
      onClick={!isDisabled ? onClick : null}
      onDelete={showDeleteIcon && onDelete ? handleDelete : null}
      deleteIcon={
        showDeleteIcon && onDelete ? (
          <IconButton size="small" className="chip-delete-icon" onClick={handleDelete} disabled={isDisabled}>
            <CancelIcon fontSize="small" />
          </IconButton>
        ) : null
      }
      variant={variant}
      icon={startIcon}
      disabled={isDisabled}
      {...other}
    />
  );
}

CustomChip.propTypes = {
  label: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  onDelete: PropTypes.func,
  variant: PropTypes.oneOf(["filled", "outlined"]),
  color: PropTypes.oneOf(["default", "primary", "secondary", "error", "success", "warning", "info"]),
  size: PropTypes.oneOf(["small", "medium"]),
  startIcon: PropTypes.node,
  isDisabled: PropTypes.bool,
  showDeleteIcon: PropTypes.bool,
};

CustomChip.defaultProps = {
  variant: "filled",
  color: "default",
  size: "medium",
  startIcon: null,
  isDisabled: false,
  onClick: null,
  onDelete: null,
  showDeleteIcon: false,
};

export default CustomChip;
