import React from "react";

const PaymentMethodIcon = () => {
  return (
    <svg
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M17.5002 6.809V4.309H2.50016V6.809H17.5002ZM17.5002 8.47567H2.50016V14.309H17.5002V8.47567ZM2.34865 2.64233H17.6517C18.4885 2.64233 19.1668 3.38853 19.1668 4.309V14.309C19.1668 15.2295 18.4885 15.9757 17.6517 15.9757H2.34865C1.51185 15.9757 0.833496 15.2295 0.833496 14.309V4.309C0.833496 3.38853 1.51185 2.64233 2.34865 2.64233Z"
        fill="#FBFAFF"
      />
    </svg>
  );
};

export default PaymentMethodIcon;
