import { combineReducers } from "redux";
import LoggedInUserInfo from "./loggedInUserInfo";
import AuthUser from "./AuthUser";
import RegisterUser from "./RegisterUser";
import GetUsers from "./GetUsers";
import Logout from "./Logout";
import EmployeeInfo from "./EmployeeInfo";
import CustomerInfo from "./CustomerInfo";
import PayrollSummary from "./PayrollSummary";
import EmployeesCountInfo from "./EmployeesCountInfo";
import UserDetails from "./UserDetails";
import UserAdditionalInfo from "./UserAdditionalInfo";
import EmployeeExperience from "./EmployeeExperience";
import EmployeePeers from "./EmployeePeers";
import GetEmployeeList from "./GetEmployeeList";
import AddEmployeeData from "./AddEmployeeData";
import AddEmployee from "./AddEmployee";
import GetEmployeeDetails from "./GetEmployeeDetails";
import AddCompanyProfileData from "./Settings/AddCompanyProfileData";
import AddProfileData from "./Settings/AddProfileData";
import RecentPayments from "./DashBoard/RecentPayments";
import GetNoticeBoard from "./DashBoard/GetNoticeBoard";
import AddEmployeeEducation from "./Workforce/AddEmployee/AddEmployeeEducation";
import AddEmployeeExperience from "./Workforce/AddEmployee/AddEmployeeExperience";
import AddEmployeeSalary from "./Workforce/AddEmployee/AddEmployeeSalary";
import AddEmployeeEducationData from "./Workforce/AddEmployee/AddEmployeeEducationData";
import AddEmployeeExperienceData from "./Workforce/AddEmployee/AddEmployeeExperienceData";
import AddemployeeSalaryData from "./Workforce/AddEmployee/AddemployeeSalaryData";
import GetCustomerDetails from "./Customers/GetCustomerDetails";
import AddCustomer from "./Customers/AddCustomer/AddCustomer";
import AddCustomerData from "./Customers/AddCustomer/AddCustomerData";
import GetCustomerPaymentSummary from "./Customers/GetCustomerPaymentSummary";
import TableFilterSelectedOptions from "./TableFilterSelectedOptions";
import DropdownData from "./DropdownData";
import AssignEmployee from "./Workforce/AssignEmployee";
import UnAssignEmployee from "./Workforce/UnAssignEmployee";
import EmployeeSalaryDetails from "./Workforce/EmployeeDetils/SalaryDetails";
import CurrentPayroll from "./Payroll/CurrentPayroll";
import PaymentHistory from "./Payroll/PaymentHistory";
import CustomerUserandCompanyProfileData from "./Settings/CustomerUser&CompanyProfileData";
import AssignCustomertoEmployee from "./Customers/AssignCustomertoEmployee";
import SalaryInfo from "./Workforce/EmployeeDetils/SalaryInfo";
import EducationInfo from "./Workforce/EmployeeDetils/EducationInfo";
import WorkforceDocs from "./Workforce/EmployeeDetils/Documents";
import ExperienceInfo from "./Workforce/EmployeeDetils/ExperienceInfo";
import EditUserProfile from "./Settings/EditUserProfile";
import EditEmployeeProfile from "./EmployeePortal/EditEmployeeProfile";
import EmployeePaySlipDetails from "./EmployeePortal/EmployeePaySlipDetails";
import EmployeeDocuments from "./EmployeePortal/EmployeeDocuments";
import EditCompanyInfo from "./Settings/EditCompanyInfo";
import ChangeUserPassword from "./Settings/ChangeUserPassword";
import ManageUser from "./Settings/ManageUser";
import AddUserPermissions from "./Settings/AddUserPermissions";
import RemoveUserPermissions from "./Settings/RemoveUserPermissions";
import CalculateEmployeeSalary from "./Workforce/AddEmployee/CalculateEmployeeSalary";
import Logo from "./Logo";
import AddUser from "./Settings/AddUser";
import SearchEmployeeAndCustomer from "./SearchEmployee&Customer";
import DeleteUser from "./Settings/DeleteUser";
import GoogleSignup from "./Google";
import UploadLogo from "./Logo/uploadLogo";
import GetPaymentDetails from "./Payroll/GetPaymentDetails";
import GetCustomerBankInfo from "./Payment/GetCustomerBankInfo";
import AddBankAccountInfo from "./Payment/AddBankAccountInfo";
import AddPermissions from "./Permissions/AddPermissions";
import RemovePermissions from "./Permissions/RemovePermissions";
import DisableCustomer from "./Customers/DisableCustomer";
import ActivationEmail from "./Customers/ActivationEmail";
import SignUp from "./SignUp/SignUp";
import GetUserDetails from "./Settings/GetUserDetails";
import GetCustomerPaymentById from "./Customers/GetCustomerPaymentById";
import ForgotPassword from "./ForgotPassword";
import EditUserDetails from "./Settings/EditUserDetails";
import GiveRaise from "./Raises/GiveRaise";
import RetryPayment from "./Payment/RetryPayment";
import GetCustomerList from "./Customers/GetCustomerList";
import PlatformConfig from "./PlatformConfig";
import EmployeeSalaries from "./EmployeeSalaries";
import GetDocumentsList from "./Doucments/GetDocumentsList";
import Holidays from "./Holidays";
import AddAchInfo from "./Payment/AddAchInfo";
import TaskList from "./TaskList";
import ImportEmployees from "./Workforce/ImportEmployees";
import DeleteBankAcc from "./Payment/DeleteBankAcc";
import GetEmployeeDetials from "./EmployeePortal/GetEmployeeDetials";
import EmployeePayrollById from "./EmployeeSalaries/EmployeePayrollById";
import Utility from "./Utility/index";
import TimeoffLeaveTypes from "./Timeoff/LeaveTypes";
import TimeoffLeaveRequests from "./Timeoff/LeaveRequests";
import TimeoffUpcomingLeaves from "./Timeoff/UpcomingLeaves";
import EmployeeBalance from "./Payoneer/EmployeePayoneerBalance";
import EmployeeStatus from "./Payoneer/EmployeePayoneerStatus";
import EmployeeConsent from "./Payoneer/EmployeePayoneerConsnet";
import EmployeeRegistrationLink from "./Payoneer/EmployeePayoneerRegistrationLink";
import EmployeeRevokeAccessToken from "./Payoneer/EmployeePayoneerRevokeAccessToken";
import EmployeeSaveAccessToken from "./Payoneer/EmployeePayoneerSaveAccessToken";
import AdminSummary from "./AdminSummary";

const reducers = combineReducers({
  EmployeePayrollById,
  GetEmployeeDetials,
  DeleteBankAcc,
  TaskList,
  AddAchInfo,
  Holidays,
  GetDocumentsList,
  ActivationEmail,
  ImportEmployees,
  EmployeeSalaries,
  PlatformConfig,
  GetCustomerList,
  RetryPayment,
  EditUserDetails,
  ForgotPassword,
  GetCustomerPaymentById,
  GetUserDetails,
  LoggedInUserInfo,
  AuthUser,
  RegisterUser,
  GetUsers,
  Logout,
  CustomerInfo,
  EmployeeInfo,
  PayrollSummary,
  EmployeesCountInfo,
  UserDetails,
  UserAdditionalInfo,
  EmployeeExperience,
  EmployeePeers,
  GetEmployeeList,
  AddEmployeeData,
  AddEmployee,
  GetEmployeeDetails,
  AddCompanyProfileData,
  AddProfileData,
  RecentPayments,
  GetNoticeBoard,
  AddEmployeeEducation,
  AddEmployeeEducationData,
  AddEmployeeExperienceData,
  AddEmployeeExperience,
  AddEmployeeSalary,
  AddemployeeSalaryData,
  GetCustomerDetails,
  GetCustomerPaymentSummary,
  AddCustomerData,
  AddCustomer,
  TableFilterSelectedOptions,
  DropdownData,
  AssignEmployee,
  UnAssignEmployee,
  EmployeeSalaryDetails,
  CurrentPayroll,
  PaymentHistory,
  CustomerUserandCompanyProfileData,
  AssignCustomertoEmployee,
  SalaryInfo,
  EducationInfo,
  WorkforceDocs,
  ExperienceInfo,
  EditUserProfile,
  EditEmployeeProfile,
  EmployeePaySlipDetails,
  EmployeeDocuments,
  EditCompanyInfo,
  ChangeUserPassword,
  ManageUser,
  RemoveUserPermissions,
  AddUserPermissions,
  CalculateEmployeeSalary,
  Logo,
  AddUser,
  SearchEmployeeAndCustomer,
  DeleteUser,
  GoogleSignup,
  UploadLogo,
  GetPaymentDetails,
  GetCustomerBankInfo,
  AddBankAccountInfo,
  AddPermissions,
  RemovePermissions,
  DisableCustomer,
  SignUp,
  GiveRaise,
  Utility,
  TimeoffLeaveTypes,
  TimeoffLeaveRequests,
  TimeoffUpcomingLeaves,
  EmployeeBalance,
  EmployeeStatus,
  EmployeeConsent,
  EmployeeRegistrationLink,
  EmployeeRevokeAccessToken,
  EmployeeSaveAccessToken,
  AdminSummary,
});

export default reducers;
