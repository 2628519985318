import React from "react";
//material ui
import { Grid, Paper, Typography, ListItem, List } from "@mui/material";
//redux
import { useSelector } from "react-redux";
//icons
import pendingTasksIcon from "../../../Icons/PendingTasksIcon.png";
import Notices from "../../../Widgets/Notices";

function TaskGrid(props) {
  //redux state
  const noticeBoard = useSelector((state) => state.GetNoticeBoard.noticeBoard);

  // const test = {
  //     actionLink: "/main/payment",
  //     customer: { name: "UziQuzi", id: "h3_x0vpSO" },
  //     id: "h3_x0vpSO",
  //     name: "UziQuzi",
  //     date: "2022-12-13T11:06:04.529Z",
  //     notice: "Missing Bank Account Information",
  //     noticeBoardcol: "Bank_Info_Missing",
  //     noticeId: "QVeoqMlUxE",
  //     roles: "CustomerAdmin",
  // };

  return (
    <Paper
      sx={{
        pt: 2,
        pb: 1,
        pl: 3,
        minHeight: "110px",
        height: "100%",
        overflowY: "auto",
      }}
    >
      <Grid item container direction="column">
        <Grid
          item
          container
          direction="row"
          alignContent="center"
          columnSpacing={2}
          mb={2}
        >
          <Grid item pt={0.5}>
            <img src={pendingTasksIcon} height={18} width={"auto"} />
          </Grid>
          <Grid item>
            <Typography variant="h6Bold">Pending Tasks</Typography>
          </Grid>
        </Grid>

        {noticeBoard?.ok && noticeBoard?.payload.length ? (
          noticeBoard?.payload.map((notice, index) => {
            return notice?.notice != null ? (
              // <Grid item container direction="row">
              //     <Grid item>
              //         <List
              //             sx={{
              //                 listStyleType: "disc",
              //                 paddingRight: "0px !important",
              //                 pl: 3,
              //                 "& .MuiListItem-root": {
              //                     paddingRight: "0px",
              //                     paddingLeft: "0px",
              //                     paddingTop: "0px",
              //                     paddingBottom: "0px",
              //                 },
              //             }}
              //         >
              //             <ListItem sx={{ display: "list-item" }}>
              //                 <Typography
              //                     variant="h6"
              //                     color="secondary"
              //                     fontSize="12px"
              //                 >
              //                     {notice?.notice}
              //                 </Typography>
              //             </ListItem>
              //         </List>
              //     </Grid>
              // </Grid>
              <Notices notice={notice} />
            ) : null;
          })
        ) : (
          <List
            sx={{
              listStyleType: "disc",
              paddingRight: "0px !important",
              pl: 3,
              "& .MuiListItem-root": {
                paddingRight: "0px",
                paddingLeft: "0px",
                paddingTop: "0px",
                paddingBottom: "0px",
              },
            }}
          >
            <ListItem sx={{ display: "list-item" }}>
              <Typography variant="h6" fontSize="12px" color="#3D43BB">
                No Notices Found
              </Typography>
            </ListItem>
          </List>
        )}
      </Grid>
    </Paper>
  );
}

export default TaskGrid;
