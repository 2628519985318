import { GET_LEAVE_TYPES_FAILURE, GET_LEAVE_TYPES_SUCCESS, LEAVE_TYPES } from "store/constants/Timeoff";

const INIT_STATE = {
  leaveTypes: [],
  loadingLeaveTypes: true,
  leaveTypesError: "",
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case LEAVE_TYPES:
      return {
        ...state,
        loadingLeaveTypes: true,
      };
    case GET_LEAVE_TYPES_SUCCESS:
      return {
        ...state,
        loadingLeaveTypes: false,
        leaveTypes: action.payload?.payload?.data,
      };
    case GET_LEAVE_TYPES_FAILURE:
      return {
        ...state,
        loadingLeaveTypes: false,
        leaveTypesError: "Error in  getting Leave types",
      };
    default:
      return state;
  }
};
