import React from "react";
import PropTypes from "prop-types";
import { Alert } from "@mui/material";

import "./Alert.css";

function CustomAlert({ severity, message }) {
  return (
    <Alert
      severity={severity}
      className="custom-alert"
      sx={{
        background: severity === "error" ? "#FFB4AB" : "",
      }}
    >
      {message}
    </Alert>
  );
}

CustomAlert.propTypes = {
  severity: PropTypes.oneOf(["success", "info", "warning", "error"]).isRequired,
  message: PropTypes.string.isRequired,
};

export default CustomAlert;
