//employee constants
import {
  RESET_FORGOT_PASSWORD,
  FORGOT_PASSWORD,
  FORGOT_PASSWORD_SUCCESS,
  FORGOT_PASSWORD_FAILURE,
} from "../constants/ForgotPassword";

const INIT_STATE = {
  forgotPasswordInfo: {},
  loadingForgotPasswordInfo: false,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case RESET_FORGOT_PASSWORD:
      return INIT_STATE;

    case FORGOT_PASSWORD:
      return {
        ...state,
        loadingForgotPasswordInfo: true,
      };
    case FORGOT_PASSWORD_SUCCESS:
      return {
        ...state,
        loadingForgotPasswordInfo: false,
        forgotPasswordInfo: action.payload,
      };
    // eslint-disable-next-line no-fallthrough
    case FORGOT_PASSWORD_FAILURE:
      return {
        ...state,
        loadingForgotPasswordInfo: false,
        forgotPasswordInfo: "Error in  getting Club Data",
      };
    default:
      return state;
  }
};
