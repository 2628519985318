import { call, put, takeEvery } from "redux-saga/effects";
//actions
import {
    RemovePermissionsSuccess,
    RemovePermissionsFailure,
} from "../../actions/Permissions/RemovePermissions";
//constant
import { REMOVE_PERMISSIONS } from "../../constants/Permissions/RemovePermissions";
//request type
import postRequest from "../../../util/APIHelperPost";
import { baseUrl } from "../../../util/APIBaseUrl";

export const fetchRemovePermissionsRequest = async (actions) => {
    // eslint-disable-next-line no-return-await
    let body = JSON.stringify({
        permission: actions?.payload?.permissionName,
    });

    return await postRequest(`${baseUrl}/${actions.payload.request}`, body)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return error;
        });
};

function* fetchRemovePermissions(params) {
    try {
        let response = yield call(fetchRemovePermissionsRequest, params);
        yield put(RemovePermissionsSuccess(response));
    } catch (error) {
        console.error(error);
        yield put(RemovePermissionsFailure(error));
    }
}

export default function* rootSaga() {
    yield takeEvery(REMOVE_PERMISSIONS, fetchRemovePermissions);
}
