import React from "react";

function DocumentIcon(props) {
  return (
    <svg
      width="44"
      height="41"
      viewBox="0 0 44 41"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_3249_9499)">
        <path
          d="M8.5865 34.1017C-3.01431 30.6838 1.58282 9.13712 14.9534 12.3144C27.9375 15.3999 37.3776 4.22487 41.7272 12.8539C43.4229 16.218 42.5794 21.9816 33.2099 32.1891C25.0391 41.0904 22.5023 38.2014 8.5865 34.1017Z"
          fill="#79A1E8"
        />
        <path
          d="M13.2219 40.5278C19.4334 40.5278 24.4689 40.2202 24.4689 39.8407C24.4689 39.4613 19.4334 39.1537 13.2219 39.1537C7.01029 39.1537 1.97482 39.4613 1.97482 39.8407C1.97482 40.2202 7.01029 40.5278 13.2219 40.5278Z"
          fill="#473CBA"
        />
        <path
          d="M24.2828 6.17511C25.4836 6.17511 26.4569 7.14661 26.4569 8.34494V12.7442H20.6594V6.17511H24.2828Z"
          fill="#473CBA"
        />
        <path
          d="M22.1087 26.4844V8.34494C22.1087 7.14661 23.0821 6.17511 24.2827 6.17511H10.202C8.93673 6.17511 7.91093 7.19892 7.91093 8.46177V26.4844H22.1087Z"
          fill="#F9D015"
        />
        <path
          d="M7.91093 26.4844H22.1087V10.5867L7.91093 24.7569V26.4844Z"
          fill="#E5B900"
        />
        <path
          d="M24.2828 5.83819H10.202C8.76802 5.83819 7.59935 6.99037 7.57454 8.41586C7.65573 8.36878 7.75455 8.34081 7.87152 8.34081C8.03043 8.34081 8.15575 8.39236 8.24859 8.47339V8.46185C8.24859 7.38675 9.12492 6.51211 10.202 6.51211H22.5711C22.0791 6.97007 21.7711 7.62234 21.7711 8.34511V26.1476H14.96C15.1363 26.3148 15.1826 26.5944 15.0998 26.8214H22.1087C22.2951 26.8214 22.4463 26.6705 22.4463 26.4845V13.0811H26.4568C26.6432 13.0811 26.7943 12.9302 26.7943 12.7442V8.34494C26.7944 6.96265 25.6677 5.83819 24.2828 5.83819ZM26.1193 12.4072H22.4463V8.34494C22.4463 7.33427 23.2703 6.51194 24.2829 6.51194C25.2955 6.51194 26.1194 7.33427 26.1194 8.34494V12.4072H26.1193Z"
          fill="#4B31AA"
        />
        <path
          d="M24.0592 3.25715C23.8728 3.25715 23.7216 3.1063 23.7216 2.92024V0.586917C23.7216 0.400855 23.8728 0.25 24.0592 0.25C24.2456 0.25 24.3967 0.400855 24.3967 0.586917V2.92024C24.3967 3.1063 24.2456 3.25715 24.0592 3.25715Z"
          fill="#4B31AA"
        />
        <path
          d="M27.4115 4.19757C27.3488 4.19757 27.2854 4.18022 27.2288 4.14375C27.0722 4.04285 27.0271 3.83429 27.1282 3.67796L28.3233 1.82972C28.4245 1.67339 28.6334 1.62841 28.79 1.72932C28.9466 1.83023 28.9917 2.03878 28.8906 2.19511L27.6955 4.04335C27.6309 4.14325 27.5224 4.19757 27.4115 4.19757Z"
          fill="#4B31AA"
        />
        <path
          d="M29.7773 6.88836C29.6535 6.88836 29.5342 6.82005 29.4751 6.70205C29.3918 6.53553 29.4593 6.33321 29.6261 6.24999L31.1846 5.4723C31.3512 5.38908 31.5541 5.45647 31.6376 5.62299C31.7209 5.78951 31.6533 5.99183 31.4866 6.07505L29.928 6.85274C29.8796 6.87699 29.8281 6.88836 29.7773 6.88836Z"
          fill="#4B31AA"
        />
        <path
          d="M17.3482 28.9562C18.549 28.9562 19.5223 27.9847 19.5223 26.7864V22.3871H13.7248V28.9562H17.3482Z"
          fill="#473CBA"
        />
        <path
          d="M15.1742 8.6469V26.7864C15.1742 27.9847 16.1476 28.9562 17.3483 28.9562H3.26764C2.00225 28.9562 0.976456 27.9324 0.976456 26.6695V8.6469H15.1742Z"
          fill="white"
        />
        <path
          d="M15.1742 26.7864V11.4432L0.976456 25.6135V26.6695C0.976456 27.9324 2.00225 28.9562 3.26755 28.9562H17.3483C16.1476 28.9562 15.1742 27.9847 15.1742 26.7864Z"
          fill="#E1F0FA"
        />
        <path
          d="M19.5224 22.0502H15.5119V8.6469C15.5119 8.46084 15.3607 8.30999 15.1743 8.30999H0.976457C0.790033 8.30999 0.638885 8.46084 0.638885 8.6469V26.6695C0.638885 28.1163 1.81811 29.2931 3.26755 29.2931H17.3483C18.7332 29.2931 19.86 28.1686 19.86 26.7863V22.3871C19.86 22.2011 19.7088 22.0502 19.5224 22.0502ZM3.26755 28.6194C2.19045 28.6194 1.31403 27.7446 1.31403 26.6695V8.98382H14.8366V26.7863C14.8366 27.509 15.1447 28.1613 15.6366 28.6193H3.26755V28.6194ZM19.1848 26.7864C19.1848 27.797 18.3609 28.6194 17.3483 28.6194C16.3356 28.6194 15.5118 27.797 15.5118 26.7864V22.7241H19.1847V26.7864H19.1848Z"
          fill="#4B31AA"
        />
        <path
          d="M31.7375 30.3879C30.5822 30.3879 29.5456 29.8832 28.8351 29.0833V21.0049C28.8351 20.2613 28.4455 19.609 27.8593 19.2389V19.1676C27.8593 18.0761 27.009 17.1466 25.9161 17.1077C24.7688 17.0671 23.8257 17.9833 23.8257 19.1193V29.6665L23.0823 28.9245C22.2733 28.1171 20.9615 28.1171 20.1524 28.9245C19.3432 29.7319 19.3431 31.0411 20.1522 31.8485L24.918 36.6051C25.076 36.7899 25.2484 36.962 25.4336 37.1196L25.5016 37.1875L25.5024 37.178C26.2908 37.8226 27.2987 38.2097 28.3974 38.2097H39.3969V30.3878H31.7375V30.3879Z"
          fill="white"
        />
        <path
          d="M36.5681 30.0054H31.8384V38.6598H36.5681V30.0054Z"
          fill="#FF931E"
        />
        <path
          d="M42.9958 30.0054H35.6283V39.5676H42.9958V30.0054Z"
          fill="#473CBA"
        />
        <path
          d="M42.9958 29.6685H31.8384C31.652 29.6685 31.5009 29.8194 31.5009 30.0054V30.0422C30.587 29.9817 29.7672 29.5737 29.1726 28.9495V21.0049C29.1726 20.2107 28.7871 19.506 28.1942 19.0634C28.1406 17.8327 27.1448 16.8144 25.9281 16.7712C25.2813 16.7465 24.6715 16.9816 24.2077 17.4284C23.7437 17.8754 23.4882 18.476 23.4882 19.1194V28.8533L23.3211 28.6865C22.3819 27.7488 20.8533 27.749 19.9137 28.6864C19.4586 29.1406 19.208 29.7444 19.2079 30.3867C19.2079 31.0289 19.4585 31.6327 19.9135 32.0869L24.6987 36.8628C25.599 37.8938 26.9227 38.547 28.3974 38.547H31.5009V38.66C31.5009 38.8461 31.652 38.9969 31.8384 38.9969H35.2907V39.5677C35.2907 39.7538 35.4418 39.9047 35.6283 39.9047H42.9958C43.1822 39.9047 43.3333 39.7538 43.3333 39.5677V30.0054C43.3333 29.8194 43.1822 29.6685 42.9958 29.6685ZM28.1968 20.027C28.3862 20.3064 28.4975 20.6428 28.4975 21.0049V27.9399C28.3046 27.5042 28.1968 27.0231 28.1968 26.5171V20.027ZM28.3974 37.873C27.3399 37.873 26.3732 37.4825 25.6307 36.8401L20.3909 31.6104C20.0633 31.2835 19.883 30.8489 19.883 30.3867C19.883 29.9245 20.0635 29.4899 20.391 29.163C21.0674 28.4881 22.1676 28.4881 22.8437 29.163L25.6722 31.9859C25.8039 32.1176 26.0178 32.1176 26.1495 31.9859C26.2814 31.8544 26.2814 31.641 26.1495 31.5095L24.1634 29.5273V19.1193C24.1634 18.6603 24.3457 18.232 24.6767 17.9132C24.9926 17.6089 25.4044 17.4434 25.8422 17.4434C25.8628 17.4434 25.8835 17.4438 25.9041 17.4445C26.796 17.4762 27.5217 18.2492 27.5217 19.1676V26.5172C27.5217 28.758 29.286 30.5949 31.5009 30.7178V37.873H28.3974ZM35.2908 38.3229H32.1761V30.3424H35.2908V38.3229ZM42.6582 39.2306H35.9658V30.3424H42.6582V39.2306Z"
          fill="#4B31AA"
        />
        <path
          d="M33.7789 36.9047C34.0468 36.9047 34.264 36.688 34.264 36.4206C34.264 36.1532 34.0468 35.9364 33.7789 35.9364C33.511 35.9364 33.2938 36.1532 33.2938 36.4206C33.2938 36.688 33.511 36.9047 33.7789 36.9047Z"
          fill="white"
        />
        <path
          d="M33.779 37.2417C33.3253 37.2417 32.9562 36.8733 32.9562 36.4206C32.9562 35.9679 33.3253 35.5995 33.779 35.5995C34.2326 35.5995 34.6016 35.9679 34.6016 36.4206C34.6016 36.8733 34.2326 37.2417 33.779 37.2417ZM33.779 36.2734C33.6975 36.2734 33.6314 36.3394 33.6314 36.4206C33.6314 36.5018 33.6975 36.5678 33.779 36.5678C33.8603 36.5678 33.9265 36.5018 33.9265 36.4206C33.9265 36.3394 33.8602 36.2734 33.779 36.2734Z"
          fill="#4B31AA"
        />
      </g>
      <defs>
        <clipPath id="clip0_3249_9499">
          <rect
            width="42.6944"
            height="40.2778"
            fill="white"
            transform="translate(0.638885 0.25)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}

export default DocumentIcon;
