import React from "react";

function PersonIcon(props) {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M5.09943 15.3948C6.3707 16.5208 8.04278 17.2043 9.87451 17.2043C11.7062 17.2043 13.3783 16.5208 14.6496 15.3948C14.4153 14.5523 12.732 14.0021 9.87451 14.0021C7.01701 14.0021 5.33374 14.5523 5.09943 15.3948ZM3.95077 14.1018C4.85176 12.9117 6.96896 12.4009 9.87451 12.4009C12.7801 12.4009 14.8973 12.9117 15.7982 14.1018C16.6061 12.9376 17.0795 11.5237 17.0795 9.99926C17.0795 6.02003 13.8537 2.79423 9.87451 2.79423C5.89528 2.79423 2.66948 6.02003 2.66948 9.99926C2.66948 11.5237 3.14291 12.9376 3.95077 14.1018ZM9.87451 18.8054C5.01101 18.8054 1.06836 14.8628 1.06836 9.99926C1.06836 5.13576 5.01101 1.19312 9.87451 1.19312C14.738 1.19312 18.6807 5.13576 18.6807 9.99926C18.6807 14.8628 14.738 18.8054 9.87451 18.8054ZM6.67227 8.39815C6.67227 6.6015 7.93643 5.19591 9.87451 5.19591C11.8072 5.19591 13.0767 6.73425 13.0767 8.55826C13.0767 11.1838 11.6502 12.4009 9.87451 12.4009C8.08195 12.4009 6.67227 11.142 6.67227 8.39815ZM8.27339 8.39815C8.27339 10.2148 8.92842 10.7998 9.87451 10.7998C10.8173 10.7998 11.4756 10.2381 11.4756 8.55826C11.4756 7.55789 10.8477 6.79703 9.87451 6.79703C8.86079 6.79703 8.27339 7.45014 8.27339 8.39815Z"
        fill="black"
      />
    </svg>
  );
}

export default PersonIcon;
