import { call, put, takeEvery } from "redux-saga/effects";

import { AddAchInfoSuccess, AddAchInfoFailure } from "store/actions/Payment/AddAchInfo";
import { ADD_ACH_BANK_INFO } from "store/constants/Payment/AddAchInfo";

import postRequest from "util/APIHelperPost";

export const fetchAddAchInfoRequest = async (actions) => {
  const body = JSON.stringify(actions.payload.body);
  return await postRequest(`${actions.payload.request}`, body)
    .then((response) => {
      actions.payload?.hidePlaidBanner?.();
      return response;
    })
    .catch((error) => {
      throw error;
    });
};

function* fetchAddAchInfo(params) {
  try {
    const response = yield call(fetchAddAchInfoRequest, params);
    yield put(AddAchInfoSuccess(response));
  } catch (error) {
    yield put(AddAchInfoFailure(error));
  }
}

export default function* rootSaga() {
  yield takeEvery(ADD_ACH_BANK_INFO, fetchAddAchInfo);
}
