import { Grid } from "@mui/material";
import NotesItem from "./NotesItem";

import "./Notes.css";

function NotesList({ notes }) {
  return (
    <div>
      <Grid
        sx={{
          marginTop: "20px",
          backgroundColor: "white",
        }}
      >
        <Grid sx={{ width: "555px", margin: "0 auto", padding: "20px 0" }}>
          {!notes?.length ? (
            <div className="notes-empty-card">
              <p>No notes available</p>
              <p className="notes-empty-card-font">Once notes are available for this user they</p>
              <p className="notes-empty-card-font"> will start to show up here</p>
            </div>
          ) : (
            notes.map((item) => <NotesItem data={item} />)
          )}
        </Grid>
      </Grid>
    </div>
  )
}

export default NotesList