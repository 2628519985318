import { call, put, takeEvery } from "redux-saga/effects";
import {
  GiveRaiseSuccess,
  GiveRaiseFailure,
} from "../../actions/Raises/GiveRaise";
import { GIVE_RAISE } from "../../constants/Raises/GiveRaise";
import postRequest from "../../../util/APIHelperPost";
import { baseUrl } from "../../../util/APIBaseUrl";

export const fetchGiveRaiseRequest = async (actions) => {
  let body = {
    // employeeId: actions?.payload?.customerData?.employeeId,
    // amount: actions?.payload?.customerData?.amount,
    // description: actions?.payload?.customerData?.description,
    // bonusMonth: actions?.payload?.customerData?.bonusMonth,
  };

  body = JSON.stringify(actions.payload.employeesIncreamentedSalary);

  return await postRequest(
    `${baseUrl}/${actions.payload.request}`,
    body,
    true,
    actions.payload.method
  )
    .then((response) => response)
    .catch((error) => {
      throw error;
    });
};

function* fetchGiveRaise(params) {
  try {
    let response = yield call(fetchGiveRaiseRequest, params);
    yield put(GiveRaiseSuccess(response));
  } catch (error) {
    console.error(error);
    yield put(GiveRaiseFailure(error));
  }
}

export default function* rootSaga() {
  yield takeEvery(GIVE_RAISE, fetchGiveRaise);
}
