import {
  RESET_USER_ADDITIONAL_INFO,
  USER_ADDITIONAL_INFO,
  USER_ADDITIONAL_INFO_SUCCESS,
  USER_ADDITIONAL_INFO_FAILURE,
} from "../constants/UserAdditionalInfo";

// for user authentication
export const ResetUserAdditionalInfo = (data) => {
  return {
    type: RESET_USER_ADDITIONAL_INFO,
    payload: data,
  };
};

export const UserAdditionalInfo = (data) => {
  return {
    type: USER_ADDITIONAL_INFO,
    payload: data,
  };
};
export const UserAdditionalInfoSuccess = (payload) => {
  return {
    type: USER_ADDITIONAL_INFO_SUCCESS,
    payload,
  };
};
export const UserAdditionalInfoFailure = (payload) => {
  return {
    type: USER_ADDITIONAL_INFO_FAILURE,
    payload,
  };
};
