import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  section: {
    flex: 1,
  },

  iconsContainer: {
    marginTop: theme.spacing(1),
  },

  icon: {
    marginRight: theme.spacing(1),
  },

  container: {
    background: '#FBFAFF',
    minHeight: 'calc(100vh - 70px)',
    marginTop: '5px',
  },

  headerContainer: {
    background: '#FBFAFF',
    width: 'calc(100% - 328px)',
    display: 'flex',
    flexDirection: 'column',
    gap: '0px',
  },

  headerDirection: {
    width: '752px',
    marginLeft: 'auto',
    marginRight: 'auto',
    padding: '16px 0px',
    display: 'flex',
    flexDirection: 'column',
  },

  headerHeading: {
    color: '#14151F',
    fontFamily: 'Poppins-Semibold',
    fontSize: '20px',
    fontWeight: 600,
    lineHeight: '32px',
  },

  headerSubHeading: {
    fontFamily: 'Poppins-Regular',
    fontSize: '12px',
    fontWeight: 400,
    color: '#212231',
    textDecoration: 'none',
  },

  header: {
    display: 'block !important',
    height: '80px',
    backgroundColor: '#FFFFFF',
  },

  getInTouchContainer: {
    width: '752px',
    marginLeft: 'auto',
    marginRight: 'auto',
    paddingTop: '20px',
  },

  notificationsContainer: {
    width: '328px',
    height: 'calc(100vh - 46px)',
    position: 'fixed',
    background: '#F5F5FF',
    right: 0,
  },

  nameAndEmailContainer: {
    marginTop: '24px',
  },

  form: {
    marginTop: '48px',
  },

  contactTextarea: {
    borderRadius: '10px',
    width: '100%',
    height: '10em',
    background: 'white',
    fontFamily: 'Poppins-Regular',
    fontSize: '16px',
    padding: '10px',
    marginTop: '1rem',

    '&:focus-visible': {
      border: '1px solid black',
    },
  },

  actionButtons: {
    marginTop: '1rem',
  },

  actionButton: {
    borderRadius: '99px',
    width: '99px',
    height: '40px',
    fontFamily: 'Poppins-Medium',
    fontSize: '13px',
    lineHeight: '20px',
  },
}));

export default useStyles;
