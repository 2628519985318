import React from "react";

const PendingIcon = () => {
  return (
    <svg
      width="21"
      height="20"
      viewBox="0 0 21 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M4.96348 14.9368C6.30678 16.8339 8.33369 17.8989 10.6971 17.8989C15.0596 17.8989 18.5961 14.3624 18.5961 9.99995L20.5708 9.99995C20.5708 15.4531 16.1502 19.8737 10.6971 19.8737C7.92827 19.8737 5.4888 18.7061 3.78548 16.6446L3.78548 17.8989L1.81073 17.8989L1.81073 12.9621L6.7476 12.9621L6.7476 14.9368L4.96348 14.9368ZM16.4307 5.06308C15.0874 3.16604 13.0605 2.10096 10.6971 2.10096C6.3346 2.10096 2.79811 5.63746 2.79811 9.99995L0.823359 9.99995C0.82336 4.54684 5.24398 0.126214 10.6971 0.126214C13.4659 0.126214 15.9054 1.2938 17.6087 3.35527L17.6087 2.10096L19.5835 2.10096L19.5835 7.03783L14.6466 7.03783L14.6466 5.06308L16.4307 5.06308ZM10.6968 6.05209C11.2423 6.05209 11.6845 6.49415 11.6845 7.03946C11.6845 7.58477 11.2423 8.02684 10.6968 8.02684C10.1513 8.02684 9.70907 7.58477 9.70907 7.03946C9.70907 6.49415 10.1513 6.05209 10.6968 6.05209ZM11.6816 14.9385L9.70616 14.9385L9.70616 9.01421L11.6816 9.01421L11.6816 14.9385Z"
        fill="#977100"
      />
    </svg>
  );
};

export default PendingIcon;
