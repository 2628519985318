import {
  RESET_REMOVE_USER_PERMISSIONS,
  REMOVE_USER_PERMISSIONS,
  REMOVE_USER_PERMISSIONS_SUCCESS,
  REMOVE_USER_PERMISSIONS_FAILURE,
} from "../../constants/Settings/RemoveUserPermissions";

// for add employee in admin workforce table

export const ResetRemoveUserPermissions = (data) => {
  return {
    type: RESET_REMOVE_USER_PERMISSIONS,
    payload: data,
  };
};

export const RemoveUserPermissions = (data) => {
  return {
    type: REMOVE_USER_PERMISSIONS,
    payload: data,
  };
};

export const RemoveUserPermissionsSuccess = (payload) => {
  return {
    type: REMOVE_USER_PERMISSIONS_SUCCESS,
    payload,
  };
};

export const RemoveUserPermissionsFailure = (payload) => {
  return {
    type: REMOVE_USER_PERMISSIONS_FAILURE,
    payload,
  };
};
