import {
    EMPLOYEE_INFO,
    EMPLOYEE_INFO_SUCCESS,
    EMPLOYEE_INFO_FAILURE,
} from "../constants/EmployeeInfo";

// for employee information
export const EmployeeInfo = (data) => {
    return {
        type: EMPLOYEE_INFO,
        payload: data,
    };
};
export const EmployeeInfoSuccess = (payload) => {
    return {
        type: EMPLOYEE_INFO_SUCCESS,
        payload,
    };
};
export const EmployeeInfoFailure = (payload) => {
    return {
        type: EMPLOYEE_INFO_FAILURE,
        payload,
    };
};
