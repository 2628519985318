import React from "react";

const CelebrationIcon = () => {
  return (
    <svg
      width="50"
      height="50"
      viewBox="0 0 50 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.1 16.2C15.9 14.4 21.8 17.3 27.2 22.8C32.6 28.3 35.6 34.1 33.8 35.9C32 37.7 8.40001 46.7 5.90001 44.1C3.40001 41.7 12.3 18 14.1 16.2Z"
        fill="#FFB94A"
      />
      <g opacity="0.2">
        <path
          d="M33.8001 35.9001C32.0001 37.7001 8.4001 46.7 5.9001 44.1C5.8001 44 5.7001 43.8 5.6001 43.5C5.6001 43.5 27.7001 34.5 31.4001 30.8C33.8001 35.9 33.8001 35.9001 33.8001 35.9001Z"
          fill="black"
        />
      </g>
      <g opacity="0.2">
        <path
          d="M14.1001 16.2C12.3001 18 3.3001 41.5999 5.9001 44.0999C6.0001 44.1999 6.2001 44.3 6.5001 44.4C6.5001 44.4 15.5001 22.3 19.2001 18.6C14.1001 16.2 14.1001 16.2 14.1001 16.2Z"
          fill="white"
        />
      </g>
      <path
        d="M33.8 35.9001C32.1 37.6001 26.7 35.1001 21.5 30.1001C21.2 29.8001 21 29.6001 20.7 29.3001C20.5 29.1001 20.4 29.0001 20.2 28.8001C20.1 28.7001 20 28.6001 19.9 28.5001C19.6 28.2001 19.3 27.9001 19.1 27.6001C19 27.5001 18.9 27.4001 18.9 27.4001C18.5 26.9001 18.1 26.5001 17.8 26.0001C14.3 21.7001 12.8 17.7001 14.2 16.2001C15.3 15.1001 18 15.8001 21.2 17.8001C21.8 18.2001 22.4 18.6001 23 19.0001C23.6 19.4001 24.2 19.9001 24.8 20.5001C25.3 20.9001 25.8 21.4001 26.3 21.9001C26.6 22.2001 26.9 22.5001 27.2 22.8001C28.3 23.9001 29.3 25.0001 30.2 26.1001C30.7 26.7001 31.1 27.3001 31.6 27.9001C34 31.5001 35 34.7001 33.8 35.9001Z"
        fill="#F79911"
      />
      <path
        d="M33 22C32.7 22 32.3 21.8 32.1 21.5C31.1 19.9 30 16.7 32.4 15.1C34.1 14 35.7 15.4 36.8 16.3C37.2 16.6 37.9 17.3 38.2 17.3C38.2 17.3 38.3 17.3 38.3 17.2C38.7 16.8 38.6 16.5 38.1 15.3C37.6 14.2 36.9 12.6 38.3 11.2C39.7 9.79999 41.2 10.6 42.3 11C43.5 11.6 43.8 11.6 44.3 11.2C44.5 11 44.5 10.9 44.5 10.8C44.4 9.89999 42.7 8.49999 41.5 7.79999C41 7.59999 40.9 6.99999 41.1 6.49999C41.4 5.99999 42 5.89999 42.5 6.09999C43.1 6.49999 46.2 8.39999 46.4 10.6C46.5 11.4 46.2 12.1 45.7 12.7C44.2 14.2 42.5 13.4 41.4 12.9C40.3 12.4 40 12.3 39.7 12.7C39.3 13.1 39.4 13.4 39.9 14.6C40.4 15.7 41.1 17.3 39.7 18.7C38.1 20.3 36.5 18.8 35.5 17.9C34.5 17 34 16.6 33.6 16.9C32.3 17.8 33.9 20.5 33.9 20.5C34.2 21 34 21.6 33.6 21.9C33.4 22 33.2 22 33 22Z"
        fill="#F45145"
      />
      <path
        d="M9.7999 18.5001C9.4999 18.5001 9.0999 18.3001 8.9999 18.0001C8.6999 17.5001 8.7999 16.9001 9.2999 16.6001C9.6999 16.4001 10.3999 15.7001 10.4999 15.2001C10.4999 15.1001 10.4999 15.0001 10.3999 14.8001C10.2999 14.6001 9.5999 14.8001 9.0999 15.0001C8.1999 15.3001 6.3999 15.8001 5.6999 14.1001C5.0999 12.6001 6.1999 11.6001 6.7999 11.1001C7.4999 10.5001 7.3999 10.5001 7.3999 10.3001C7.2999 10.1001 7.1999 10.1001 6.3999 10.1001C5.4999 10.1001 3.9999 10.1001 3.3999 8.50008C3.2999 7.90008 3.2999 7.20008 3.5999 6.70008C4.4999 5.30008 6.9999 5.00008 7.7999 4.90008C8.3999 4.80008 8.7999 5.30008 8.8999 5.80008C8.8999 6.30008 8.4999 6.80008 7.9999 6.90008C6.6999 7.00008 5.4999 7.40008 5.2999 7.70008C5.4999 8.00008 5.5999 8.10008 6.4999 8.10008C7.2999 8.10008 8.6999 8.10008 9.2999 9.60008C9.8999 11.1001 8.7999 12.1001 8.1999 12.6001C7.5999 13.1001 7.5999 13.2001 7.5999 13.3001C7.7999 13.3001 8.2999 13.2001 8.5999 13.1001C9.5999 12.8001 11.1999 12.4001 12.0999 13.8001C12.4999 14.4001 12.5999 15.0001 12.4999 15.7001C12.1999 17.2001 10.5999 18.2001 10.3999 18.4001C10.1999 18.4001 9.9999 18.5001 9.7999 18.5001Z"
        fill="#5FA3E0"
      />
      <path
        d="M35.5999 45.0001C33.9999 45.0001 32.7 43.8001 32.5 43.7001C32.1 43.3001 32.1 42.8001 32.5 42.4001C32.9 42.0001 33.5 42.1001 33.9 42.5001C34.2 42.8001 35 43.5001 35.5 43.5001C35.6 43.5001 35.7 43.5001 35.8 43.4001C35.9 43.3001 35.6 42.6001 35.4 42.2001C35 41.3001 34.2 39.7001 35.7 38.7001C37.1 37.8001 38.2 38.8001 38.9 39.3001C39.6 39.8001 39.6 39.8001 39.8 39.7001C39.9 39.6001 40 39.5001 39.8 38.7001C39.7 37.8001 39.4 36.4001 40.8 35.5001C41.4 35.1001 41.9999 35.1001 42.5999 35.3001C44.0999 35.9001 44.9 38.3001 45.0999 39.1001C45.2 39.6001 44.9 40.2001 44.4 40.3001C43.9 40.4001 43.3 40.1001 43.2 39.6001C42.9 38.4001 42.2 37.3001 41.9 37.2001C41.6 37.4001 41.6 37.5001 41.7 38.4001C41.8 39.2001 42.1 40.6001 40.8 41.4001C39.4 42.3001 38.2999 41.3001 37.5999 40.8001C36.9999 40.3001 36.9 40.3001 36.8 40.3001C36.8 40.5001 37.1 40.9001 37.2 41.2001C37.7 42.1001 38.4 43.4001 37.2 44.5001C36.9 45.0001 36.2999 45.0001 35.5999 45.0001Z"
        fill="#5FA3E0"
      />
      <path
        d="M42.8 26.0999C42.8 26.6999 42.4 27.0999 41.8 27.0999C38.2 27.0999 34.7 27.4999 31.5 27.9999C27 28.6999 23.3 29.6999 21.4 30.1999C21.1 29.8999 20.9 29.6999 20.6 29.3999C20.3 29.0999 20.1 28.8999 19.8 28.5999C20.9 28.2999 24.8 27.0999 30.1 26.1999C33.6 25.5999 37.6 25.1999 41.7001 25.1999C42.3001 25.0999 42.8 25.4999 42.8 26.0999Z"
        fill="#F45145"
      />
      <path
        d="M30.9999 8.19998C31.0999 13.5 28.7999 18.2 26.2999 21.9C23.9999 25.2 21.4999 27.7 20.1999 28.8C20.0999 28.7 19.9999 28.6 19.8999 28.5C19.5999 28.2 19.2999 27.9 19.0999 27.6C18.9999 27.5 18.8999 27.4 18.8999 27.4C20.0999 26.3 22.6999 23.9 24.8999 20.6C27.1999 17.2 29.1999 12.9 29.0999 8.29998C29.0999 7.79998 29.4999 7.29998 30.0999 7.29998C30.4999 7.19998 30.9999 7.59998 30.9999 8.19998Z"
        fill="#5FA3E0"
      />
      <g opacity="0.2">
        <path
          d="M30.7001 36.2001C28.0001 35.5001 24.2001 33.0001 20.6001 29.3001C15.2001 23.9001 12.2001 18.0001 14.0001 16.2001C15.2001 15.0001 18.2001 15.9001 21.7001 18.2001C20.3001 17.8001 19.3001 17.9001 18.7001 18.5001C16.9001 20.3001 19.8001 26.2001 25.3001 31.6001C27.1001 33.5001 29.0001 35.0001 30.7001 36.2001Z"
          fill="black"
        />
      </g>
      <path
        d="M22.9001 19.0999C22.1001 22.9999 19.5001 26.7999 19.0001 27.5999C18.5001 27.0999 18.1001 26.4999 17.7001 25.9999C18.6001 24.5999 20.8001 20.9999 21.2001 17.7999C21.3001 16.8999 21.2001 16.0999 21.0001 15.2999C20.6001 13.8999 19.5001 12.9999 17.8001 12.3999C17.3001 12.1999 17.0001 11.5999 17.2001 11.0999C17.4001 10.5999 17.9001 10.2999 18.5001 10.4999C20.8001 11.2999 22.3001 12.6999 22.9001 14.6999C23.3001 16.0999 23.2001 17.5999 22.9001 19.0999Z"
        fill="#F45145"
      />
      <path
        d="M15 9C16.1046 9 17 8.10457 17 7C17 5.89543 16.1046 5 15 5C13.8954 5 13 5.89543 13 7C13 8.10457 13.8954 9 15 9Z"
        fill="#FDC457"
      />
      <path
        d="M25 12C26.1046 12 27 11.1046 27 10C27 8.89543 26.1046 8 25 8C23.8954 8 23 8.89543 23 10C23 11.1046 23.8954 12 25 12Z"
        fill="#5FA3E0"
      />
      <path
        d="M37 10C38.1046 10 39 9.10457 39 8C39 6.89543 38.1046 6 37 6C35.8954 6 35 6.89543 35 8C35 9.10457 35.8954 10 37 10Z"
        fill="#FDC457"
      />
      <path
        d="M44 23C45.1046 23 46 22.1046 46 21C46 19.8954 45.1046 19 44 19C42.8954 19 42 19.8954 42 21C42 22.1046 42.8954 23 44 23Z"
        fill="#FDC457"
      />
      <path
        d="M39 34C40.1046 34 41 33.1046 41 32C41 30.8954 40.1046 30 39 30C37.8954 30 37 30.8954 37 32C37 33.1046 37.8954 34 39 34Z"
        fill="#5FA3E0"
      />
    </svg>
  );
};

export default CelebrationIcon;
