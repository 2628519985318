//employee constants
import {
    RESET_EDIT_USER_DETAILS,
    EDIT_USER_DETAILS,
    EDIT_USER_DETAILS_SUCCESS,
    EDIT_USER_DETAILS_FAILURE,
} from "../../constants/Settings/EditUserDetails";

const INIT_STATE = {
    getUserDetails: {},
    loadingEditUserDetails: true,
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case RESET_EDIT_USER_DETAILS:
            return INIT_STATE;

        case EDIT_USER_DETAILS:
            return {
                ...state,
                loadingEditUserDetails: true,
            };
        case EDIT_USER_DETAILS_SUCCESS:
            return {
                ...state,
                loadingEditUserDetails: false,
                getUserDetails: action.payload,
            };
        // eslint-disable-next-line no-fallthrough
        case EDIT_USER_DETAILS_FAILURE:
            return {
                ...state,
                loadingEditUserDetails: false,
                getUserDetails: "Error in  getting Club Data",
            };
        default:
            return state;
    }
};
