import {
  EMPLOYEE_DOCUMENTS_REQUEST,
  EMPLOYEE_DOCUMENTS_REQUEST_SUCCESS,
  EMPLOYEE_DOCUMENTS_REQUEST_FAILURE,
} from "../../constants/EmployeePortal/EmployeeDocuments";

const INIT_STATE = {
  docs: [],
  isLoading: false,
  error: null,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case EMPLOYEE_DOCUMENTS_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case EMPLOYEE_DOCUMENTS_REQUEST_SUCCESS:
      return {
        ...state,
        isLoading: false,
        docs: action.payload.payload.documents,
        error: null,
      };
    case EMPLOYEE_DOCUMENTS_REQUEST_FAILURE:
      return {
        ...state,
        isLoading: false,
        docs: [],
        error: "Error in getting Documents",
      };
    default:
      return state;
  }
};
