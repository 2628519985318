import {
  RESET_ADD_PERMISSIONS,
  ADD_PERMISSIONS,
  ADD_PERMISSIONS_SUCCESS,
  ADD_PERMISSIONS_FAILURE,
} from "../../constants/Permissions/AddPermissions";

// for add employee in admin workforce table

export const ResetAddPermissions = (data) => {
  return {
    type: RESET_ADD_PERMISSIONS,
    payload: data,
  };
};

export const AddPermissions = (data) => {
  return {
    type: ADD_PERMISSIONS,
    payload: data,
  };
};
export const AddPermissionsSuccess = (payload) => {
  return {
    type: ADD_PERMISSIONS_SUCCESS,
    payload,
  };
};
export const AddPermissionsFailure = (payload) => {
  return {
    type: ADD_PERMISSIONS_FAILURE,
    payload,
  };
};
