import React from "react";
import { Helmet } from "react-helmet";
import { Grid } from "@mui/material";

import Header from "components/Header/Header";
import UploadFile from "./UploadFile";

function index(props) {
  return (
    <>
      <Helmet>
        <title>Edge | Payroll Upload</title>
      </Helmet>
      <Grid container direction="column" rowSpacing={3}>
        <Grid item>
          <Header
            data={{
              heading: "Payroll Upload",
              profileWithIcon: null,
            }}
          />
        </Grid>

        <Grid item>
          <UploadFile />
        </Grid>
      </Grid>
    </>
  );
}

export default index;
