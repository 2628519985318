import { IconButton, Stack, Tooltip } from "@mui/material";
import React from "react";
import PeruIcon from "components/Screens/Timeoff/TimeoffAdmin/EmployeeTimeOffDetails/Icons/PeruIcon";
import PakistanIcon from "components/Screens/Timeoff/TimeoffAdmin/EmployeeTimeOffDetails/Icons/PakistanIcon";
import AvatarIcon from "components/Icons/AvatarIcon";

function EmployeeAvatar(props) {
  const { img, name, jobTitle, country, size, subtitleColor } = props;
  const avatarSize = size || {
    width: "40.452px",
    height: "40.452px",
    borderRadius: "50%",
  };
  return (
    <Stack direction="row" alignItems="center" spacing="10px">
      <div style={{ position: "relative" }}>
        {/* <img className="list-leaves-icon w-5 h-5" style={{ clipPath: "circle(40%)" }} src={img} alt="Profile" /> */}
        <AvatarIcon shape={"circle"} size={avatarSize} source={img} />
        {country && (
          <IconButton
            sx={{ position: "absolute", right: "0px", bottom: "-2px", width: "17px", height: "17px", padding: "0px" }}
          >
            {country === "Peru" ? <PeruIcon /> : <PakistanIcon />}
          </IconButton>
        )}
      </div>

      <div>
        {name && (
          <Tooltip title={name}>
            <div
              style={{
                lineHeight: "16px",
                fontSize: "13px",
                weight: 500,
                fontFamily: "Inter",
                textTransform: "none",
                color: "#292A3D",
              }}
            >
              {name}
            </div>
          </Tooltip>
        )}

        {jobTitle && (
          <div
            style={{
              fontFamily: "Inter",
              fontSize: "11px",
              lineHeight: "normal",
              color: "#666899",
              fontWeight: 400,
              textTransform: "none",
            }}
          >
            {jobTitle}
          </div>
        )}
        {/* <div style={{ fontFamily: "Poppins-Regular" }} className="holiday-label label-sm neutral-20">
          Independance day
        </div> */}
      </div>
    </Stack>
  );
}

export default EmployeeAvatar;
