import { call, put, takeEvery } from "redux-saga/effects";
import {
  EditEmployeeProfileSuccess,
  EditEmployeeProfileFailure,
} from "../../actions/EmployeePortal/EditEmployeeProfile";
import { EMPLOYEE_PORTAL_EDIT_PROFILE } from "../../constants/EmployeePortal/EditEmployeeProfile";
import postRequest from "../../../util/APIHelperPost";
import { baseUrl } from "../../../util/APIBaseUrl";

export const editEmployeeProfileRequest = async (actions) => {
  return await postRequest(
    `${baseUrl}/${actions.payload.request}`,
    JSON.stringify(actions.payload.data),
    undefined,
    "PATCH"
  )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

function* EditEmployeeProfile(params) {
  try {
    let response = yield call(editEmployeeProfileRequest, params);
    yield put(EditEmployeeProfileSuccess(response));
  } catch (error) {
    console.error(error);
    yield put(EditEmployeeProfileFailure(error));
  }
}

export default function* rootSaga() {
  yield takeEvery(EMPLOYEE_PORTAL_EDIT_PROFILE, EditEmployeeProfile);
}
