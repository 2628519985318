//employee constants
import {
    RESET_GET_CUSTOMER_PAYMENT_SUMMARY,
    GET_CUSTOMER_PAYMENT_SUMMARY,
    GET_CUSTOMER_PAYMENT_SUMMARY_SUCCESS,
    GET_CUSTOMER_PAYMENT_SUMMARY_FAILURE,
} from "../../constants/Customers/GetCustomerPaymentSummary";

const INIT_STATE = {
    customerPaymentSummary: {},
    loadingCustomerPaymentSummary: true,
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case RESET_GET_CUSTOMER_PAYMENT_SUMMARY:
            return INIT_STATE;

        case GET_CUSTOMER_PAYMENT_SUMMARY:
            return {
                ...state,
                loadingCustomerPaymentSummary: true,
            };
        case GET_CUSTOMER_PAYMENT_SUMMARY_SUCCESS:
            return {
                ...state,
                loadingCustomerPaymentSummary: false,
                customerPaymentSummary: action.payload,
            };
        // eslint-disable-next-line no-fallthrough
        case GET_CUSTOMER_PAYMENT_SUMMARY_FAILURE:
            return {
                ...state,
                loadingCustomerPaymentSummary: false,
                customerPaymentSummary: "Error in  getting Club Data",
            };
        default:
            return state;
    }
};
