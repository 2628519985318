import React, { useState } from "react";
import { useDispatch } from "react-redux";
import moment from "moment";

import Modal from "components/common/Modal";
import Button from "components/common/Button";
import { AlertType } from "components/common/Snackbar";

import { deleteEmployeeDocument } from "services/BambooHrTabs/documents";
import { showSnackbar } from "store/actions/Utility";

import "../Document.scss";

function DocumentDeleteModal({ open, onClose, document, employeeId, onPostDelete }) {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);

  const getShareText = () => {
    if (document?.shareWithEmployee && document?.shareWithCustomer) {
      return "Shared with Employee and Customer";
    }
    if (document?.shareWithEmployee) {
      return "Shared with Employee";
    }
    if (document?.shareWithCustomer) {
      return "Shared with Customer";
    }
    return "";
  };

  const onDelete = async () => {
    try {
      setIsLoading(true);
      await deleteEmployeeDocument(employeeId, document?.documentId);
      dispatch(showSnackbar({ type: AlertType.success, message: "Document Deleted Successfully", dispatch }));

      onPostDelete();
    } catch (error) {
      dispatch(showSnackbar({ type: AlertType.error, message: error?.message, dispatch }));
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Modal open={open} onClose={onClose} title="Delete Document?" width="452px">
      <div className="mt-15 pb-15">
        <p className="heading-h4-medium">{document?.documentName}</p>
        <p className="para-body-m-medium delete-modal-share-with-text">{getShareText()}</p>
        <p className="para-body-m-medium delete-modal-created-at-text">
          Added on {moment(document?.createdAt).format("DD MMM, YYYY")}
        </p>
      </div>
      <div className="flex justify-end mt-05 mb-05">
        <Button label="Cancel" variant="outlined" size="large" color="inherit-text" onClick={onClose} />
        <div className="ml-05">
          <Button isLoading={isLoading} label="Delete" size="large" color="error" onClick={onDelete} />
        </div>
      </div>
    </Modal>
  );
}

export default DocumentDeleteModal;
