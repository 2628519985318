import * as yup from "yup";

export const editContactInfoSchema = yup.object().shape({
  email: yup.string().required("Email is required").email("Invalid email"),
  phoneNumber: yup.string().nullable(),
  personalEmail: yup.string().email("Invalid email").nullable(),
  state: yup.string().required("State is required"),
  city: yup.string().required("City is required"),
  zip: yup.string().required("ZIP code is required"),
  addressLine1: yup.string().required("Address is required"),
});

export const editEmergencyContactSchema = yup.object().shape({
  name: yup.string().required("Name is required"),
  relationship: yup.string().required("Relationship is required"),
  phoneNumber: yup.string().nullable(),
  email: yup.string().email("Invalid email").nullable(),
  state: yup.string().required("State is required"),
  city: yup.string().required("City is required"),
  zipCode: yup.string().required("ZIP code is required"),
  addressLine1: yup.string().required("Address is required"),
});
