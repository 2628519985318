import { call, put, takeEvery } from "redux-saga/effects";
//actions
import {
  EditUserDetailsSuccess,
  EditUserDetailsFailure,
} from "../../actions/Settings/EditUserDetails";
//constant
import { EDIT_USER_DETAILS } from "../../constants/Settings/EditUserDetails";
//request type
import postRequest from "../../../util/APIHelperPost";
import { baseUrl } from "../../../util/APIBaseUrl";

export const fetchEditUserDetailsRequest = async (actions) => {
  // eslint-disable-next-line no-return-await
  let body = JSON.stringify({
    customerId: actions?.payload?.customerId,
    email: actions.payload?.userData?.email,
  });

  return await postRequest(`${baseUrl}/${actions.payload.request}`, body)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

function* fetchEditUserDetails(params) {
  try {
    let response = yield call(fetchEditUserDetailsRequest, params);
    yield put(EditUserDetailsSuccess(response));
  } catch (error) {
    console.error(error);
    yield put(EditUserDetailsFailure(error));
  }
}

export default function* rootSaga() {
  yield takeEvery(EDIT_USER_DETAILS, fetchEditUserDetails);
}
