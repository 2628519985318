import React from "react";
//material ui
import UserDetailsV2 from "./UserDetails";
import UserDetailsV1 from "./../UserDetails";
import { usePermissions } from "../../../Hook";

function index(props) {
  const { loggedInUserType, loggedInUser } = usePermissions();

  return loggedInUserType === "edge" ? <UserDetailsV1 /> : <UserDetailsV2 />;
}

export default index;
