import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { VscArrowLeft, VscArrowRight } from "react-icons/vsc";
import { Fade } from "@mui/material";

import LeaveReplacementSuccessModal from "components/Screens/Timeoff/ManageTimeoff/Customer/LeaveCard/LeaveReplacementSuccessModal";
import LeaveCard from "components/Screens/Timeoff/ManageTimeoff/Customer/LeaveCard";
import { FetchLeaveRequests } from "store/actions/Timeoff/LeaveRequests";
import "./PendingRequest.css";

const PendingRequests = ({ updateCalendarWithPendingLeaves }) => {
  const dispatch = useDispatch();
  const [currentIndex, setCurrentIndex] = useState(0);
  const [showReplacementSuccessModal, setShowReplacementSuccessModal] = useState(false);
  const [cardShownOnScreenFlag, setCardShownOnScreenFlag] = useState(true);

  const pendingLeaveRequests = useSelector((state) => state?.TimeoffLeaveRequests?.leaveRequests?.pending || []);

  const handlePrevClick = () => {
    if (currentIndex > 0) {
      setCurrentIndex(currentIndex - 1);
    }
  };

  const handleNextClick = () => {
    if (currentIndex < pendingLeaveRequests.length - 1) {
      setCurrentIndex(currentIndex + 1);
    }
  };

  const runAnimationForLeaveCard = () => {
    setCardShownOnScreenFlag(false);
    setTimeout(() => setCardShownOnScreenFlag(true), 300);
  };

  useEffect(() => {
    runAnimationForLeaveCard();
  }, [currentIndex]);

  useEffect(() => {
    dispatch(FetchLeaveRequests({}));
  }, []);

  useEffect(() => {
    dispatch(FetchLeaveRequests({}));
  }, []);

  return (
    <div className="pending-leaves-container-customer">
      <LeaveReplacementSuccessModal
        open={showReplacementSuccessModal}
        closeModal={() => setShowReplacementSuccessModal(false)}
      />
      {pendingLeaveRequests.length > 0 ? (
        <>
          <div className="flex w-full items-center justify-between">
            <div>
              <p className="count-text">
                {currentIndex + 1}/{pendingLeaveRequests.length}
              </p>
            </div>
            <div>
              <VscArrowLeft
                onClick={handlePrevClick}
                id="prev-leave"
                className={`text-2xl cursor-pointer arrow-size mr-gap bg-primary-95 primary-30 ${
                  currentIndex === 0 ? "blurred-arrow" : ""
                }`}
              />
              <VscArrowRight
                id="next-leave"
                onClick={handleNextClick}
                className={`text-2xl cursor-pointer arrow-size bg-primary-95 primary-30 ${
                  currentIndex === pendingLeaveRequests.length - 1 ? "blurred-arrow" : ""
                }`}
              />
            </div>
          </div>
          <Fade className="w-100p" in={cardShownOnScreenFlag}>
            <div>
              <LeaveCard
                setLeaveCardIndexToZero={() => setCurrentIndex(0)}
                setShowReplacementSuccessModal={setShowReplacementSuccessModal}
                updateCalendarWithPendingLeaves={updateCalendarWithPendingLeaves}
                data={pendingLeaveRequests[currentIndex]}
                showProfileSection={true}
                runAnimationForLeaveCard={runAnimationForLeaveCard}
              />
            </div>
          </Fade>
          <div className="dots-container">
            {pendingLeaveRequests.map((_, index) => (
              <span key={index} className={`dot ${index === currentIndex ? "active-dot" : "inactive-dot"}`}></span>
            ))}
          </div>
        </>
      ) : (
        <p className="m-auto text-center">No pending requests</p>
      )}
    </div>
  );
};

export default PendingRequests;
