import { call, put, takeEvery } from "redux-saga/effects";
import {
  EmployeePaySlipDetailsSuccess,
  EmployeePaySlipDetailsFailure,
} from "../../actions/EmployeePortal/EmployeePaySlipDetails";
import { EMPLOYEE_PAY_SLIP_DETAILS } from "../../constants/EmployeePortal/EmployeePaySlipDetails";
import getRequest from "../../../util/APIHelperGet";
import { baseUrl } from "../../../util/APIBaseUrl";

export const fetchRequest = async (actions) => {
  return await getRequest(`${baseUrl}/${actions.payload}`)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

function* fetchEmployeePayslipDetails(params) {
  try {
    let response = yield call(fetchRequest, params);
    yield put(EmployeePaySlipDetailsSuccess(response));
  } catch (error) {
    console.error(error);
    yield put(EmployeePaySlipDetailsFailure(error));
  }
}

export default function* rootSaga() {
  yield takeEvery(EMPLOYEE_PAY_SLIP_DETAILS, fetchEmployeePayslipDetails);
}
