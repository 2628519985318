import SampleCustomerComponent from "components/Screens/Marketplace/Customer/SampleCustomerComponent";

const marketplacePublicRoutes = [
  {
    path: "public",
    element: <SampleCustomerComponent />,
  },
];

const marketplacePrivateRoutes = [
  {
    path: "private",
    element: <SampleCustomerComponent />,
  },
];

export { marketplacePrivateRoutes, marketplacePublicRoutes };
