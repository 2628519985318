import './Layout.css';

const TimeoffLayout = () => {
  return (
    <div className="layout-container">
      <div className="layout-heading">Time-off </div>
      <div className="layout-subheading"> Manage time-off requests and view upcoming holidays here</div>
    </div>
  );
};

export default TimeoffLayout;
