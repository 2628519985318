import React from "react";

import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

import Button from "components/common/Button";

function EmployeeAssignmentBanner({
  bannerText = "",
  handleActionButtonClick = () => {},
  actionButtonLabel = "",
  isOverviewTab,
}) {
  return (
    <div className={`${isOverviewTab ? "pb-175" : "pt-175 px-2"}`}>
      <div className="assignment-banner-bg flex items-center justify-between p-075 gap-2">
        <div className="flex items-center space-x-025">
          <InfoOutlinedIcon className="info-icon" />
          <p className="para-body-m-regular pl-025">{bannerText}</p>
        </div>
        <Button
          label={actionButtonLabel}
          variant="outlined"
          color="warning"
          size="medium"
          onClick={handleActionButtonClick}
        />
      </div>
    </div>
  );
}

export default EmployeeAssignmentBanner;
