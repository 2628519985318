import React from 'react';
import { Card, Grid, Typography } from '@mui/material';

import {
  BarChart,
  Bar,
  XAxis,
  Tooltip,
  Cell,
  ResponsiveContainer,
} from 'recharts';

const payrollContainerWidth = {
  1: 30,
  2: 50,
  3: 73,
  4: 95,
};

const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    return (
      <Card elevation={3}>
        <Grid px={1.5} py={1}>
          <Typography variant="h6">{label}</Typography>
          <Typography
            variant="h5"
            color="#006980"
          >{`Payroll : $ ${payload[0].value}`}</Typography>
        </Grid>
      </Card>
    );
  }

  return null;
};

export default function Barchart(props) {
  const { payrollData } = props;
  const payrollLength = payrollData.length;

  return (
    <ResponsiveContainer
      width={
        payrollLength < 5 ? `${payrollContainerWidth[payrollLength]}%` : '100%'
      }
      height={140}
    >
      {payrollLength ? (
        <BarChart
          data={payrollData}
          barSize={payrollLength === 6 ? 53 : payrollLength <= 5 && 63}
        >
          <XAxis
            dataKey="month"
            axisLine={false}
            tickMargin={15}
            tickLine={false}
            tick={{
              fontFamily: 'Poppins-Medium !important',
              fontWeight: 500,
              fontSize: 11,
              lineHeight: '16px',
              color: '#212231',
            }}
          />
          <Tooltip cursor={false} content={<CustomTooltip />} />
          <Bar dataKey="amount" stackId="amount" fill="#131599" radius={4}>
            {payrollData.map((entry, index) => (
              <Cell
                key={index}
                radius={4}
                fill={index === payrollLength - 1 ? "#131599" : "#F1EFFF"}
              />
            ))}
          </Bar>
        </BarChart>
      ) : (
        <Typography
          variant="h6"
          color="secondary"
          fontSize="12px"
          sx={{ color: '#3D43BB' }}
        >
          {' '}
          No Payroll Summary Found
        </Typography>
      )}
    </ResponsiveContainer>
  );
}
