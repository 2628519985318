import {
  RESET_ADD_ACH_BANK_INFO,
  ADD_ACH_BANK_INFO,
  ADD_ACH_BANK_INFO_SUCCESS,
  ADD_ACH_BANK_INFO_FAILURE,
} from "../../constants/Payment/AddAchInfo";

// for user authentication
export const ResetAddAchInfo = (data) => {
  return {
    type: RESET_ADD_ACH_BANK_INFO,
    payload: data,
  };
};

export const AddAchInfo = (data) => {
  return {
    type: ADD_ACH_BANK_INFO,
    payload: data,
  };
};
export const AddAchInfoSuccess = (payload) => {
  return {
    type: ADD_ACH_BANK_INFO_SUCCESS,
    payload,
  };
};
export const AddAchInfoFailure = (payload) => {
  return {
    type: ADD_ACH_BANK_INFO_FAILURE,
    payload,
  };
};
