/* eslint-disable react/require-default-props */
import React, { useState, useRef } from "react";
import AvatarEditor from "react-avatar-editor";
import PropTypes from "prop-types";
import { Modal, Slider, Typography } from "@mui/material";

import Button from "components/Button/Button";
import CustomAlert from "components/common/Alert";

const modalStyle = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
};

const contentStyle = {
  backgroundColor: "white",
  padding: "30px 0",
  borderRadius: "8px",
  width: "560px",
  textAlign: "center",
};

function ImageCropperModal({ open, onClose, imageSrc, onSave, isFileUploadError, errorMessage }) {
  const focusPoint = useRef(null);
  const [zoom, setZoom] = useState(1.2);

  const handleSave = () => {
    const canvas = focusPoint.current.getImage();

    const optimizedCanvas = document.createElement("canvas");
    const ctx = optimizedCanvas.getContext("2d");
    optimizedCanvas.width = canvas.width;
    optimizedCanvas.height = canvas.height;
    ctx.drawImage(canvas, 0, 0, canvas.width, canvas.height);

    optimizedCanvas.toBlob(
      (blob) => {
        if (blob) {
          const file = new File([blob], "my_image.jpg", {
            type: "image/jpeg",
            lastModified: new Date().getTime(),
          });

          onSave(file);
        }
      },
      "image/jpeg",
      0.7
    );
  };

  return (
    <Modal open={open} onClose={onClose} style={modalStyle}>
      <div
        style={{
          ...contentStyle,
          height: isFileUploadError ? "245px" : "665px",
        }}
      >
        <Typography
          sx={{
            fontFamily: "Poppins-Medium",
            fontSize: "17px",
            lineHeight: "24px",
            fontWeight: "500",
          }}
        >
          {isFileUploadError ? "Error uploading file" : "Crop and Preview Your Photo"}
        </Typography>
        {!isFileUploadError ? (
          <>
            <Typography
              sx={{
                fontFamily: "Poppins-Regular",
                fontSize: "12px",
                lineHeight: "16px",
                fontWeight: "400",
                margin: "10px 40px 30px 40px",
                color: "#666899",
              }}
            >
              Please use a professionally appropriate photo and ensure your face is centered and clearly visible in the
              frame
            </Typography>

            <div style={{ position: "relative", background: "black" }}>
              <AvatarEditor
                ref={focusPoint}
                image={imageSrc}
                width={300}
                height={300}
                border={50}
                color={[0, 0, 0, 0.6]}
                scale={zoom}
                rotate={0}
              />
            </div>
            <Slider
              value={zoom}
              min={1}
              max={3}
              step={0.1}
              onChange={(e, value) => setZoom(value)}
              style={{ width: "80%", marginTop: "15px" }}
            />
          </>
        ) : (
          <div style={{ margin: "30px" }}>
            <CustomAlert message={errorMessage} severity="error" color="#FFB4AB" />
          </div>
        )}
        <div
          style={{
            marginTop: "10px",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Button
            variant="outlined"
            size="lg"
            style={{
              color: "#4950C7",
              fontSize: "14px",
              fontWeight: 500,
              lineHeight: "20px",
            }}
            onClick={onClose}
          >
            {isFileUploadError ? "Close" : "Cancel"}
          </Button>
          {!isFileUploadError && (
            <Button onClick={handleSave} style={{ marginLeft: "10px" }} variant="primary" size="lg">
              Save
            </Button>
          )}
        </div>
      </div>
    </Modal>
  );
}

ImageCropperModal.propTypes = {
  open: PropTypes.bool,
  isFileUploadError: PropTypes.bool,
  onClose: PropTypes.func,
  onSave: PropTypes.func,
  imageSrc: PropTypes.string,
  errorMessage: PropTypes.string,
};

export default ImageCropperModal;
