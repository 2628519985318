import React from "react";

const PayrollInformationIcon = () => {
  return (
    <svg
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M17.5002 7.58415V5.08415H2.50016V7.58415H17.5002ZM17.5002 9.25081H2.50016V15.0841H17.5002V9.25081ZM2.34865 3.41748H17.6517C18.4885 3.41748 19.1668 4.16367 19.1668 5.08415V15.0841C19.1668 16.0046 18.4885 16.7508 17.6517 16.7508H2.34865C1.51185 16.7508 0.833496 16.0046 0.833496 15.0841V5.08415C0.833496 4.16367 1.51185 3.41748 2.34865 3.41748Z"
        fill="#FBFAFF"
      />
    </svg>
  );
};

export default PayrollInformationIcon;
