import {
  RESET_CUSTOMER_BANK_INFO,
  CUSTOMER_BANK_INFO,
  CUSTOMER_BANK_INFO_SUCCESS,
  CUSTOMER_BANK_INFO_FAILURE,
} from "../../constants/Payment/GetCustomerBankInfo";

// for user authentication
export const ResetCustomerBankInfo = (data) => {
  return {
    type: RESET_CUSTOMER_BANK_INFO,
    payload: data,
  };
};

export const CustomerBankInfo = (data) => {
  return {
    type: CUSTOMER_BANK_INFO,
    payload: data,
  };
};
export const CustomerBankInfoSuccess = (payload) => {
  return {
    type: CUSTOMER_BANK_INFO_SUCCESS,
    payload,
  };
};
export const CustomerBankInfoFailure = (payload) => {
  return {
    type: CUSTOMER_BANK_INFO_FAILURE,
    payload,
  };
};
