import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

// mui
import { Avatar, Box, Card, CardContent, CardMedia, Grid, Typography } from "@mui/material";
//commmon function
import {
  getCountryFlag,
  getFieldValue,
  getProfileImage,
  getSalaryWithoutBouns,
  getValue,
  getWorkingDuration,
} from "../Screens/CommonUtils";
// styles
import "./WorkforceCard.css";
// images
import avatarImage from "assets/images/fallback-image.svg";
import CreditCardIcon from "assets/svgs/CreditCardIcon";
import CalendarIcon from "assets/svgs/CalendarIcon";
import { usePermissions } from "../Hook";
import RedDotIcon from "assets/svgs/RedDotIcon";
import YellowDotIcon from "assets/svgs/YellowDotIcon";

function WorkforceCard({ data, isEmployee }) {
  const navigate = useNavigate();

  const { loggedInUserType } = usePermissions();
  const [imageSrc, setImageSrc] = useState(avatarImage);

  let today = new Date();
  today.setHours(0, 0, 0, 0);

  let lastLeaveDate = new Date(data?.leaveStatus?.dateTo);
  lastLeaveDate.setHours(0, 0, 0, 0);

  const truncateString = (str, num) => {
    if (str.length <= num) {
      return str;
    }
    return str.slice(0, num) + "...";
  };

  //function
  const handleNaviagte = () => {
    if (loggedInUserType === "customer") navigate(`/main/details/${data?.employeeId}`);
    else if (loggedInUserType === "employee")
      if (data?.Community_Proifle_Url) {
        window.open(data?.Community_Proifle_Url, "_blank");
      }
  };

  //method
  useEffect(() => {
    if (data?.picturePath) {
      getProfileImage(data?.employeeId, data?.pictureVersion).then((img) => {
        setImageSrc(img);
      });
    } else {
      setImageSrc(avatarImage);
    }
  }, [data?.employeeId, data?.pictureVersion]);

  return (
    <Grid
      onClick={handleNaviagte}
      sx={{
        "&:hover": {
          boxShadow: "0px 1px 14px rgba(2, 0, 110, 0.12)",
          borderRadius: "20px",
        },
      }}
    >
      <Card
        sx={{
          borderRadius: "20px !important",
          background: "#FFFFFF",
          cursor: "pointer",
        }}
      >
        <div style={{ position: "relative" }}>
          <Box
            sx={{
              backgroundColor: "#E0E0FF",
              minHeight: "136px",
              maxHeight: "136px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <CardMedia
              component="img"
              minHeight="100"
              image={imageSrc[data?.employeeId] ? imageSrc[data?.employeeId] : avatarImage}
              sx={{
                height: "136px",
                objectFit: imageSrc[data?.employeeId] ? "cover" : "none",
                objectPosition: imageSrc[data?.employeeId] ? "top" : "center",
              }}
            />
          </Box>
          <Avatar
            sx={{
              width: "26px",
              height: "26px",
              background: "#FFFFFF",
              borderRadius: "99px",
              position: "absolute",
              bottom: "-14px",
              left: "15px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
            id="country-flag"
          >
            {getCountryFlag(data?.country)}
          </Avatar>
        </div>
        <CardContent sx={{ paddingTop: "26px", paddingX: "0px" }}>
          <Grid
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "12px",
            }}
          >
            <Grid
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "6px",
              }}
            >
              <Typography
                sx={{
                  fontFamily: "Poppins-Medium",
                  fontSize: "14px",
                  lineHeight: "20px",
                  color: "#000000",
                  height: "44px",
                  paddingX: "10px",
                }}
                id="full-name-emp"
              >
                {`${getValue(data?.firstName)} ${getValue(data?.lastName)}`}
              </Typography>
              <Typography
                sx={{
                  fontFamily: "Poppins-Regular",
                  fontSize: "12px",
                  lineHeight: "12px",
                  color: "#3D43BB",
                  paddingX: "10px",
                  lineHeight: "140%",
                }}
                id="emp-job-title"
              >
                {getValue(data?.jobTitle)}
              </Typography>
            </Grid>
            {!isEmployee ? (
              <Grid>
                <Grid
                  sx={{
                    background: "#FBFAFF",
                    padding: "6px 10px",
                    display: "flex",
                    alignItems: "center",
                    gap: "8px",
                  }}
                >
                  <CreditCardIcon style={{ width: "16px", height: "16px" }} />
                  <Typography
                    sx={{
                      fontFamily: "Poppins-Regular",
                      fontWeight: 400,
                      fontSize: "12px",
                      lineHeight: "12px",
                      color: "#14151F",
                    }}
                    id="salary-without-bonus"
                  >
                    $ {getSalaryWithoutBouns(data?.costToCompany, data?.incrementDetails?.bonus)}
                  </Typography>
                </Grid>
                <Grid
                  sx={{
                    background: "#F1EFFF",
                    padding: "6px 10px",
                    display: "flex",
                    alignItems: "center",
                    gap: "8px",
                  }}
                >
                  <CalendarIcon style={{ width: "16px", height: "16px" }} />
                  <Typography
                    sx={{
                      fontFamily: "Poppins-Regular",
                      fontWeight: 400,
                      fontSize: "12px",
                      lineHeight: "12px",
                      color: "#14151F",
                    }}
                    id="work-duration"
                  >
                    {getWorkingDuration(data?.startDate)}
                  </Typography>
                </Grid>
                <div style={{ height: "20px" }}>
                  {data?.leaveStatus?.status && lastLeaveDate >= today && (
                    <Grid
                      sx={{
                        background: "#FFFBF5",
                        padding: "6px 10px",
                        display: "flex",
                        alignItems: "center",
                        gap: "8px",
                      }}
                    >
                      {data?.leaveStatus?.statusId === "Out till" || data?.leaveStatus?.statusId === "Out today" ? (
                        <RedDotIcon />
                      ) : (
                        data?.leaveStatus?.statusId === "Out from" && <YellowDotIcon />
                      )}

                      <Typography
                        sx={{
                          fontFamily: "Poppins-Regular",
                          fontWeight: 400,
                          fontSize: "12px",
                          lineHeight: "12px",
                          color: "#14151F",
                        }}
                        id="emp-status"
                      >
                        {truncateString(
                          data?.leaveStatus?.statusId === "Out today"
                            ? data?.leaveStatus?.statusId
                            : data?.leaveStatus?.status,
                          15
                        )}
                      </Typography>
                    </Grid>
                  )}
                </div>
              </Grid>
            ) : (
              <></>
            )}
          </Grid>
        </CardContent>
      </Card>
    </Grid>
  );
}

export default WorkforceCard;
