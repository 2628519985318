import { call, put, takeEvery } from "redux-saga/effects";
//actions
import {
  GetCustomerPaymentByIdSuccess,
  GetCustomerPaymentByIdFailure,
} from "../../actions/Customers/GetCustomerPaymentById";
//constant
import { GET_CUSTOMER_PAYMENT_BY_ID } from "../../constants/Customers/GetCustomerPaymentById";
//request type
import getRequest from "../../../util/APIHelperGet";
//base url
import { baseUrl } from "../../../util/APIBaseUrl";

export const fetchGetCustomerPaymentByIdRequest = async (actions) => {
  return await getRequest(
    `${baseUrl}/${actions.payload.request}?` +
      new URLSearchParams({
        limit: actions.payload.rowsPerPage,
        page: actions.payload.page,
      })
  )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

function* fetchGetCustomerPaymentById(params) {
  try {
    let response = yield call(fetchGetCustomerPaymentByIdRequest, params);

    yield put(GetCustomerPaymentByIdSuccess(response));
  } catch (error) {
    console.error(error);
    yield put(GetCustomerPaymentByIdFailure(error));
  }
}

export default function* rootSaga() {
  yield takeEvery(GET_CUSTOMER_PAYMENT_BY_ID, fetchGetCustomerPaymentById);
}
