import {
  RESET_POST_DOC_FOR_WORKFORCE,
  POST_DOC_FOR_WORKFORCE,
  POST_DOC_FOR_WORKFORCE_SUCCESS,
  POST_DOC_FOR_WORKFORCE_FAILURE,
} from "../../../constants/Workforce/EmployeeDetils/Documents";

export const ResetPostADocForWorkforce = (data) => {
  return {
    type: RESET_POST_DOC_FOR_WORKFORCE,
    payload: data,
  };
};

export const postADocForWorkforce = (data) => {
  return {
    type: POST_DOC_FOR_WORKFORCE,
    payload: data,
  };
};
export const postADocForWorkforceSuccess = (payload) => {
  return {
    type: POST_DOC_FOR_WORKFORCE_SUCCESS,
    payload,
  };
};
export const postADocForWorkforceFailure = (payload) => {
  return {
    type: POST_DOC_FOR_WORKFORCE_FAILURE,
    payload,
  };
};
