/* eslint-disable camelcase */
import {
  GET_EMPLOYEE_Details,
  GET_EMPLOYEE_Details_SUCCESS,
  GET_EMPLOYEE_Details_FAILURE,
  RESET_GET_EMPLOYEE_Details,
} from "../constants/GetEmployeeDetails";

const INIT_STATE = {
  getEmployeeDetails: {},
  loadingGET_EMPLOYEE_Details: false,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case RESET_GET_EMPLOYEE_Details:
      return INIT_STATE;

    case GET_EMPLOYEE_Details:
      return {
        ...state,
        loadingGET_EMPLOYEE_Details: true,
      };
    case GET_EMPLOYEE_Details_SUCCESS:
      return {
        ...state,
        loadingGET_EMPLOYEE_Details: false,
        getEmployeeDetails: action.payload,
      };
    // eslint-disable-next-line no-fallthrough
    case GET_EMPLOYEE_Details_FAILURE:
      return {
        ...state,
        loadingGET_EMPLOYEE_Details: false,
        getEmployeeDetails: "Error in  getting Club Data",
      };
    default:
      return state;
  }
};
