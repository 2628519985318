import {
  RESER_EXPERIENCE_INFO,
  EXPERIENCE_INFO,
  EXPERIENCE_INFO_SUCCESS,
  EXPERIENCE_INFO_FAILURE,
} from "../../../constants/Workforce/EmployeeDetils/ExperienceInfo";

// for user authentication
export const ResetexperienceInfo = (data) => {
  return {
    type: RESER_EXPERIENCE_INFO,
    payload: data,
  };
};

export const experienceInfo = (data) => {
  return {
    type: EXPERIENCE_INFO,
    payload: data,
  };
};
export const experienceInfoSuccess = (payload) => {
  return {
    type: EXPERIENCE_INFO_SUCCESS,
    payload,
  };
};
export const experienceInfoFailure = (payload) => {
  return {
    type: EXPERIENCE_INFO_FAILURE,
    payload,
  };
};
