export const NEW_ACTIVITY = {
  ALL_EMPLOYEES_REQUESTS: "allEmployeeLeaveRequests",
  EMPLOYEE_TIME_OFF_DETAILS: "employeeTimeOffDetails",
};

export const LEAVE_STATUS = {
  APPROVED: "Approved",
  REJECTED: "Rejected",
  CANCELLED: "Cancelled",
  Pending: "Pending",
  Taken: "Taken",
};
