//employee constants
import {
    RESET_POST_DOC_FOR_WORKFORCE,
    POST_DOC_FOR_WORKFORCE,
    POST_DOC_FOR_WORKFORCE_SUCCESS,
    POST_DOC_FOR_WORKFORCE_FAILURE,
} from "../../../constants/Workforce/EmployeeDetils/Documents";

const INIT_STATE = {
    documents: [],
    isLoading: false,
    error: null,
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case RESET_POST_DOC_FOR_WORKFORCE:
            return INIT_STATE;

        case POST_DOC_FOR_WORKFORCE:
            return {
                ...state,
                isLoading: true,
                error: null,
            };

        case POST_DOC_FOR_WORKFORCE_SUCCESS:
            return {
                ...state,
                isLoading: false,
                // documents: [...state.documents, action.payload],
                documents: action.payload,
                error: null,
            };
        case POST_DOC_FOR_WORKFORCE_FAILURE:
            return {
                ...state,
                isLoading: false,
                error: "Error in Saving the Document",
            };
        default:
            return state;
    }
};
