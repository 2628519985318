export const LEAVE_TYPES = "LEAVE_TYPES";
export const GET_LEAVE_TYPES_SUCCESS = "GET_LEAVE_TYPES_SUCCESS";
export const GET_LEAVE_TYPES_FAILURE = "GET_LEAVE_TYPES_FAILURE";

export const LEAVE_REQUESTS = "LEAVE_REQUESTS";
export const GET_LEAVE_REQUESTS_SUCCESS = "GET_LEAVE_REQUESTS_SUCCESS";
export const GET_LEAVE_REQUESTS_FAILURE = "GET_LEAVE_REQUESTS_FAILURE";

export const UPCOMING_LEAVES = "UPCOMING_LEAVES";
export const GET_UPCOMING_LEAVES_SUCCESS = "GET_UPCOMING_LEAVES_SUCCESS";
export const GET_UPCOMING_LEAVES_FAILURE = "GET_UPCOMING_LEAVES_FAILURE";
