import React from "react";
import { Grid, Typography } from "@mui/material";

import SunIcon from "../../assets/svgs/SunIcon";
import MoonIcon from "../../assets/svgs/MoonIcon";

import { getValue } from "../Screens/CommonUtils";
import { usePermissions } from "../Hook";

const GreetingHeader = ({ isEmployee }) => {
  const getDayStatus = () => {
    const currentTime = new Date();
    const currentHour = currentTime.getHours();

    if (currentHour >= 5 && currentHour < 12) {
      return "Morning";
    } else if (currentHour >= 12 && currentHour < 17) {
      return "Afternoon";
    } else if (currentHour >= 17 && currentHour < 20) {
      return "Evening";
    } else {
      return "Evening";
    }
  };

  const { loggedInUser } = usePermissions();

  return (
    <Grid
      container
      sx={{
        height: "80px",
        backgroundColor: "#FFFFFF",
      }}
    >
      <Grid
        sx={{
          width: "752px",
          marginX: "auto",
          padding: "16px 0px",
          display: "flex",
          alignItems: "center",
          gap: "14px",
        }}
      >
        {getDayStatus() === "Morning" || getDayStatus() === "Afternoon" ? <SunIcon /> : <MoonIcon />}

        <Grid>
          <Typography
            variant="h1"
            id="greetings"
            sx={{
              fontFamily: "Poppins-Semibold",
              fontStyle: "normal",
              fontSize: "20px",
              lineHeight: "29px",
              color: "#14151F",
            }}
          >
            Good {getDayStatus()}, {getValue(loggedInUser?.name)}!
          </Typography>
          <Typography
            variant="body1"
            sx={{
              fontFamily: "Poppins-Regular",
              fontSize: "14px",
              lineHeight: "19px",
              color: "#2C2D2E",
            }}
          >
            {isEmployee ? "Keep up the good work!" : "We hope you have a great day!"}
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default GreetingHeader;
