import React from "react";

function CalendarIcon(props) {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M13.3335 5.33325V3.33325H12.0002V3.99992H10.6668V3.33325H5.3335V3.99992H4.00016V3.33325H2.66683V5.33325H13.3335ZM13.3335 6.66659H2.66683V13.3333H13.3335V6.66659ZM12.0002 1.99992H13.3335C14.0699 1.99992 14.6668 2.59687 14.6668 3.33325V13.3333C14.6668 14.0696 14.0699 14.6666 13.3335 14.6666H2.66683C1.93045 14.6666 1.3335 14.0696 1.3335 13.3333V3.33325C1.3335 2.59687 1.93045 1.99992 2.66683 1.99992H4.00016V1.33325H5.3335V1.99992H10.6668V1.33325H12.0002V1.99992Z"
        fill="black"
      />
    </svg>
  );
}

export default CalendarIcon;
