import { call, put, takeEvery } from "redux-saga/effects";
//actions
import { LogoutUserSuccess, LogoutUserFailure } from "../../actions/Logout";
//constant
import { LOGOUT } from "../../constants/Logout";
//request type
import postRequest from "../../../util/APIHelperPost";
import { baseUrl } from "../../../util/APIBaseUrl";

export const fetchLogoutUserRequest = async (actions) => {
  // eslint-disable-next-line no-return-await
  let refreshToken = localStorage.getItem("refreshToken");
  let body = JSON.stringify({ refreshToken: refreshToken });
  return await postRequest(`${baseUrl}/logout/`, body)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

function* fetchLogoutUser(params) {
  try {
    let response = yield call(fetchLogoutUserRequest, params);

    yield put(LogoutUserSuccess(response));
  } catch (error) {
    console.error(error);
    yield put(LogoutUserFailure(error));
  }
}

export default function* rootSaga() {
  yield takeEvery(LOGOUT, fetchLogoutUser);
}
