import { useEffect } from "react";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";

import PersonalInfoV2 from "./PersonalInfo/NewPersonalProfileV2";
import PageLoader from "components/common/PageLoader";
import TopHeader from "components/common/TopHeader";

import { ManageUser } from "store/actions/Settings/ManageUser";
import { GetCustomerUserandCompanyProfileData } from "store/actions/Settings/CustomerUser&CompanyProfileData";

function Profile() {
  const dispatch = useDispatch();

  const manageUser = useSelector((state) => state.ManageUser.manageUser);
  const loadingChangeUserPassword = useSelector((state) => state.ChangeUserPassword.loadingChangeUserPassword);
  const loadingCustomeruserandCompanyProfileData = useSelector(
    (state) => state.CustomerUserandCompanyProfileData.loadingCustomeruserandCompanyProfileData
  );
  const customerUserandCompanyProfileData = useSelector(
    (state) => state.CustomerUserandCompanyProfileData.customerUserandCompanyProfileData
  );
  const loadingEditUserProfile = useSelector((state) => state.EditUserProfile.loadingEditUserProfile);

  useEffect(() => {
    let loggedInUser = {};
    let userAdditionalInfo = {};
    let manageUserRequest = "";
    let getProfileDataRequest = "";

    loggedInUser = JSON.parse(localStorage.getItem("loggedInUser"));
    userAdditionalInfo = JSON.parse(sessionStorage.getItem("userAdditionalInfo"));

    if (loggedInUser && Object.keys(loggedInUser).length && loggedInUser.userId) {
      if (loggedInUser?.type === "customer") {
        getProfileDataRequest = `customerUser/${loggedInUser?.userId}`;
        manageUserRequest = `customer/${userAdditionalInfo?.customerId}/customerUsers`;
      } else if (loggedInUser?.type === "edge") {
        getProfileDataRequest = `user/${loggedInUser?.userId}`;
        manageUserRequest = "users";
      }
    }

    dispatch(
      ManageUser({
        request: manageUserRequest,
        rowsPerPage: 50,
        page: 0,
        sortBy: {
          field: "First_Name",
          direction: "asc",
        },
      })
    );

    dispatch(
      GetCustomerUserandCompanyProfileData({
        request: getProfileDataRequest,
      })
    );
  }, []);

  return (
    <>
      <Helmet>
        <title>Edge | Profile</title>
      </Helmet>

      {loadingChangeUserPassword || loadingCustomeruserandCompanyProfileData || loadingEditUserProfile ? (
        <PageLoader />
      ) : null}

      <div className="bg-white">
        <TopHeader title="Profile Details" linkName="Account" link="Account" breadcrumbs backIcon="/main/dashboard" />
        <div className="profile-info-container">
          <PersonalInfoV2
            customerUserandCompanyProfileData={customerUserandCompanyProfileData}
            managersList={manageUser?.ok ? manageUser.payload.rows : []}
          />
        </div>
      </div>
    </>
  );
}

export default Profile;
