/* eslint-disable no-restricted-globals */

import * as yup from "yup";

const SalaryDetailsSchema = yup
  .object()
  .shape({
    costToCustomer: yup
      .number()
      .transform((value) => (isNaN(value) ? undefined : value))
      .typeError("Cost to customer must be a number")
      .min(0, "Cost to customer cannot be negative"),
    grossSalary: yup
      .number()
      .transform((value) => (isNaN(value) ? undefined : value))
      .typeError("Gross pay must be a number")
      .min(0, "Gross pay cannot be negative"),
    additionalDeduction: yup
      .number()
      .nullable()
      .transform((value) => (isNaN(value) || value === "" ? null : value))
      .typeError("Deduction must be a number")
      .min(0, "Deduction cannot be negative"),
    discounted: yup.boolean(),
    percentage: yup
      .number()
      .nullable()
      .transform((value) => (isNaN(value) || value === "" ? null : value))
      .typeError("Percentage discount must be a number")
      .min(0, "Percentage discount cannot be negative")
      .max(100, "Percentage discount cannot exceed 100%"),
    flatAmount: yup
      .number()
      .nullable()
      .transform((value) => (isNaN(value) || value === "" ? null : value))
      .typeError("Flat discount must be a number")
      .min(0, "Flat discount cannot be negative"),
    netSalary: yup
      .number()
      .transform((value) => (isNaN(value) ? undefined : parseFloat(value)))
      .typeError("Net salary must be a number"),
    platformFee: yup
      .number()
      .transform((value) => (isNaN(value) ? undefined : parseFloat(value)))
      .typeError("Platform fee must be a number"),
    startDate: yup.date().nullable().typeError("Invalid Date Format, must be MM/YYYY"),
    duration: yup
      .number()
      .nullable()
      .transform((value) => (Number.isInteger(value) ? value : undefined))
      .typeError("Duration must be an Number")
      .integer("Duration must be an Integer Number")
      .min(1, "Duration must be at least 1"),
  })
  .test("cost-to-customer-required", function (values) {
    if (
      (this.options.context.employeeStatus === "assigned" || this.options.context.employeeStatus === "pending") &&
      !values.costToCustomer
    ) {
      return this.createError({
        path: "costToCustomer",
        message: "Cost to Customer cannot be empty since this employee is assigned to a customer",
      });
    }
    return true;
  })
  .test("gross-pay-required", function (values) {
    if (
      (this.options.context.employeeStatus === "assigned" || this.options.context.employeeStatus === "pending") &&
      !values.grossSalary
    ) {
      return this.createError({
        path: "grossSalary",
        message: "Gross pay cannot be empty since this employee is assigned to a customer",
      });
    }
    return true;
  })
  .test("at-least-one-salary", "Either Cost to customer or Gross pay is required", function (values) {
    if (!values.costToCustomer && !values.grossSalary) {
      return new yup.ValidationError("Either Cost to customer or Gross pay is required", null, [
        "costToCustomer",
        "grossSalary",
      ]);
    }
    return true;
  })
  .test("cost-to-customer-greater-than-pay", "Cost to customer must be greater than gross pay", function (values) {
    if (values.costToCustomer && values.grossSalary && values.costToCustomer <= values.grossSalary) {
      return this.createError({ path: "costToCustomer", message: "Cost to customer must be greater than gross pay" });
    }
    return true;
  })
  .test("gross-pay-less-than-cost", "Gross pay must be less than Cost to customer", function (values) {
    if (values.costToCustomer && values.grossSalary && values.grossSalary >= values.costToCustomer) {
      return this.createError({ path: "grossSalary", message: "Gross pay must be less than cost to customer" });
    }
    return true;
  })
  .test("discount-validation", null, function (values) {
    if (values.discounted) {
      if (values.percentage && values.flatAmount) {
        return this.createError({
          path: "flatAmount",
          message: "Only one type of discount (percentage or flat) can be applied",
        });
      }
      if (!values.percentage && !values.flatAmount) {
        return this.createError({
          path: "percentage",
          message: "Either percentage or flat discount must be provided when applying a discount",
        });
      }
      if (!values.duration) {
        return this.createError({
          path: "duration",
          message: "Duration is required when applying a discount",
        });
      }
      if (!values.startDate) {
        return this.createError({
          path: "startDate",
          message: "Start Date is required when applying a discount",
        });
      }
    }
    return true;
  });

export default SalaryDetailsSchema;
