import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import { Alert, Grid, Snackbar, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import ACHImg from "../../Icons/ACH.jpg";
//redux dispatch
import { useSelector, useDispatch } from "react-redux";
import { usePermissions } from "../../Hook";
import { baseUrl } from "../../../util/APIBaseUrl";
//common
import { getError, isValidResponse } from "../CommonUtils";
import { DeleteCustomerBankInfo } from "../../../store/actions/Payment/DeleteBankAcc";
import { CustomerBankInfo } from "../../../store/actions/Payment/GetCustomerBankInfo";

export default function DeleteBankAcc(props) {
  //props
  const { open, handleClose, setOpenDeleteAcc } = props;

  //constant
  const dispatch = useDispatch();

  //component state
  const [openAlert, setOpenAlert] = React.useState(false);

  //custom hook
  const { loggedInUser } = usePermissions();

  //redux state
  const deleteBankAccountInfo = useSelector((state) => state.DeleteBankAcc.deleteBankAccountInfo);

  //function
  const handleAlertClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenAlert(false);
  };

  const handleDeleteBankInfo = () => {
    dispatch(
      DeleteCustomerBankInfo({
        request: `${baseUrl}/customer/${loggedInUser?.customerId}/account`,
      })
    );
  };

  //methods

  React.useEffect(() => {
    if (isValidResponse(deleteBankAccountInfo)) {
      dispatch(
        CustomerBankInfo({
          request: `${baseUrl}/customer/${loggedInUser?.customerId}/bankInfo`,
        })
      );
      setOpenDeleteAcc(false);
      setOpenAlert(false);
    } else if (deleteBankAccountInfo?.ok == false) {
      setOpenAlert(true);
    }
  }, [deleteBankAccountInfo]);

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{
          "& .MuiDialog-paper": {
            py: "35px",
            px: "24px",
            width: "375px",
            boxShadow: "0px 1px 14px rgba(2, 0, 110, 0.12)",
            borderRadius: "12px !important",
          },
        }}
      >
        <Grid container direction="column" spacing={2}>
          <Grid item>
            <img src={ACHImg} height={138} width={"auto"} />
          </Grid>

          <Grid item>
            <Typography
              sx={{
                fontFamily: "Poppins-Medium !important",
                fontSize: "20px",
                fontWeight: 600,
                lineHeight: "32px",
                color: "#1C1B1F",
              }}
            >
              Remove Bank Account
            </Typography>
          </Grid>

          <Grid item>
            <Typography
              sx={{
                fontFamily: "Poppins-Medium !important",
                fontSize: "15px",
                // fontWeight: 600,
                // lineHeight: "32px",
                color: "#1C1B1F",
              }}
            >
              Are you sure you want to delete you bank Info ?
            </Typography>
          </Grid>

          <Grid item xs>
            <Stack direction="row" spacing={1} justifyContent={"flex-start"}>
              <Button
                variant="text"
                id="cancel-delete-account"
                sx={{
                  borderRadius: "99px",
                  height: "40px",
                }}
                onClick={() => {
                  setOpenDeleteAcc(false);
                  setOpenAlert(false);
                  //   dispatch(ResetAddAchInfo({}));
                }}
              >
                <Typography variant="h5" color="#3D43BB">
                  Cancel
                </Typography>
              </Button>

              <Button
                variant="contained"
                disableElevation
                id="delete-account"
                sx={{
                  borderRadius: "99px",
                  width: "99px",
                  height: "40px",
                }}
                onClick={(e) => {
                  e.preventDefault();
                  handleDeleteBankInfo();
                }}
              >
                <Typography variant="h6" color="white">
                  Delete
                </Typography>
              </Button>
            </Stack>
          </Grid>

          <Grid item>
            <Snackbar
              fullWidth
              open={openAlert}
              autoHideDuration={2500}
              onClose={handleAlertClose}
              sx={{
                "&.MuiSnackbar-root": {
                  position: "relative",
                  bottom: 0,
                  left: 0,
                },
              }}
            >
              <Alert
                open={openAlert}
                fullWidth
                // autoHideDuration={3500}
                variant="filled"
                icon={true}
                sx={{
                  backgroundColor: "#313033",
                  padding: "initial",
                  px: "15px",
                  py: "8px",
                  fontSize: "14px",
                  textAlign: "left",

                  minWidth: "320px",

                  borderRadius: "12px !important",
                  color: "#ffffff !important",
                  "&.MuiPaper-root": {
                    color: "#ffffff !important",
                    boxShadow: "0px 4px 8px rgba(2, 0, 110, 0.18)",
                  },
                }}
              >
                <Typography
                  sx={{
                    fontFamily: "Poppins-Regular !important",
                    fontWeight: "400",
                    fontSize: "14px",
                  }}
                >
                  {deleteBankAccountInfo?.ok == false
                    ? getError(deleteBankAccountInfo?.payload?.error, "white")
                    : "Enter Required Fields"}
                </Typography>
              </Alert>
            </Snackbar>
          </Grid>
        </Grid>
      </Dialog>
    </div>
  );
}
