import { call, put, takeEvery } from "redux-saga/effects";
//actions
import {
  EmployeeInfoSuccess,
  EmployeeInfoFailure,
} from "../../actions/EmployeeInfo";
//constant
import { EMPLOYEE_INFO } from "../../constants/EmployeeInfo";
//request type
import getRequest from "../../../util/APIHelperGet";
//base url
import { baseUrl } from "../../../util/APIBaseUrl";

export const fetchEmployeeInfoRequest = async (actions) => {
  // return await getRequest(
  //     `${baseUrl}/customer/${actions.payload.customerID}/employees?limit=${actions.payload.rowsPerPage}`
  // )
  return await getRequest(
    `${baseUrl}/customer/${actions.payload.customerID}/employees?` +
      new URLSearchParams({
        limit: actions.payload.rowsPerPage,
        page: actions.payload.page,
        orderBy: JSON.stringify({
          field: actions.payload?.sort?.field,
          direction: actions.payload?.sort?.direction,
        }),
        filter: JSON.stringify({
          country: actions.payload?.dist?.countries,
          First_Name: actions.payload?.dist?.employeeName,
          employeeId: actions.payload?.dist?.employeeId,
          updated: actions.payload?.dist?.updated,
        }),
      })
  )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

function* fetchEmployeeInfo(params) {
  try {
    let response = yield call(fetchEmployeeInfoRequest, params);
    yield put(EmployeeInfoSuccess(response));
  } catch (error) {
    console.error(error);
    yield put(EmployeeInfoFailure(error));
  }
}

export default function* rootSaga() {
  yield takeEvery(EMPLOYEE_INFO, fetchEmployeeInfo);
}
