import { baseUrl } from "util/APIBaseUrl";
import getRequest from "util/APIHelperGet";

export const getTimeOffStats = async (payload) => {
  try {
    const { startDate, endDate, selectedLeaveType, country, customerId } = payload;
    // const response = await getRequest(`${baseUrl}/admin/timeoff/stats?filter=${JSON.stringify(payload)}`, true);
    const response = await getRequest(
      `${baseUrl}/admin/timeoff/stats?startDate=${startDate}&endDate=${endDate}&leaveType=${selectedLeaveType}&country=${
        country || ""
      }&customerId=${customerId}`,
      true
    );
    return response.payload;
  } catch (error) {
    console.log(error);
  }
};

export const getEmployeesTimeOff = async (payload) => {
  try {
    const response = await getRequest(
      `${baseUrl}/admin/leaves?startDate=${payload?.startDate}&endDate=${payload?.endDate}`,
      true
    );
    return response.payload;
  } catch (error) {
    console.log(error);
  }
};

export const getEmployeesTimeOffDates = async (payload) => {
  try {
    const response = await getRequest(
      `${baseUrl}/admin/leave_holidays?startDate=${payload?.startDate}&endDate=${payload?.endDate}`,
      true
    );
    return response.payload;
  } catch (error) {
    console.log(error);
  }
};

export const getTimeOffFlags = async () => {
  try {
    const response = await getRequest(`${baseUrl}/admin/timeoff/flags`, true);
    return response.payload;
  } catch (error) {
    console.log(error);
  }
};
