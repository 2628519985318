import React, { useState, useEffect } from "react";

import { useSelector } from "react-redux";

import CustomModal from "components/common/Modal";
import PageLoader from "components/common/PageLoader";

import { getEmployeeSalary, updateEmployeeSalary } from "services/BambooHrTabs/salarydetails";

import EmployeeAssignmentBanner from "./EmployeeAssignmentBanner";
import AddEditSalaryDetails from "./AddEditSalaryDetails";
import NoSalaryDetails from "./NoSalaryDetails";
import SalarySummary from "./SalarySummary";
import PaySlips from "./Payslips";
import AssignToCustomerModal from "components/Screens/Workforce/UserDetails/NewPersonalInfo/EmploymentInfo/AssignToCustomer";

import "./SalaryDetails.scss";

function SalaryDetails({ fetchEmployeeDetailsByIDV2 }) {
  const [isLoading, setIsLoading] = useState(true);
  const [salaryData, setSalaryData] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [error, setError] = useState(null);
  const [showAssignToCustomerModal, setShowAssignToCustomerModal] = useState(false);

  const getEmployeeDetails = useSelector((state) => state.GetEmployeeDetails.getEmployeeDetails);
  const LoadingGetEmployeeDetails = useSelector((state) => state.GetEmployeeDetails.loadingGET_EMPLOYEE_Details);

  const employeeID = getEmployeeDetails?.payload?.employeeId;

  const employeeStatus = getEmployeeDetails?.payload?.status;

  const fetchSalaryData = async () => {
    setIsLoading(true);
    try {
      const data = await getEmployeeSalary(employeeID);
      setSalaryData(data?.payload && Object.keys(data.payload).length > 0 ? data.payload : null);
    } catch (err) {
      setError("Failed to fetch salary data");
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchSalaryData();
  }, [employeeID]);

  const handleModalOpen = () => {
    setIsModalOpen(true);
  };

  const handleEdit = () => {
    setIsModalOpen(true);
  };

  const handleSave = async (updatedData) => {
    setIsLoading(true);
    try {
      await updateEmployeeSalary(employeeID, updatedData);
      await fetchSalaryData();
      fetchEmployeeDetailsByIDV2();
    } catch (err) {
      setError("Failed to update salary data");
    } finally {
      setIsLoading(false);
      setIsModalOpen(false);
    }
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  if (isLoading) {
    return <PageLoader />;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <>
      {(LoadingGetEmployeeDetails || isLoading) && <PageLoader />}
      <CustomModal
        open={isModalOpen}
        title={salaryData ? "Edit Salary" : "Add Salary"}
        sx={{ maxWidth: "652px !important", width: "652px !important", padding: "24px !important" }}
      >
        <AddEditSalaryDetails
          onSave={handleSave}
          onCancel={handleCancel}
          initialData={salaryData || {}}
          employeeStatus={employeeStatus}
        />
      </CustomModal>

      <AssignToCustomerModal
        employeeId={employeeID}
        open={showAssignToCustomerModal}
        initialEmploymentDetails={{}}
        fetchEmployeeDetailsByIDV2={fetchEmployeeDetailsByIDV2}
        closeModal={() => {
          setShowAssignToCustomerModal(false);
        }}
      />

      <div className="bg-white">
        {!salaryData || Object.keys(salaryData).length === 0 ? (
          <NoSalaryDetails handleModalOpen={handleModalOpen} />
        ) : (
          <>
            {employeeStatus === "unassigned" && (
              <EmployeeAssignmentBanner
                bannerText="This employee has not yet been assigned to a customer. Please assign them now to begin customer billing."
                actionButtonLabel="Assign to Customer"
                handleActionButtonClick={() => setShowAssignToCustomerModal(true)}
              />
            )}
            <SalarySummary
              costToCustomer={salaryData?.costToCustomer}
              grossSalary={salaryData?.grossSalary}
              additionalDeduction={salaryData?.additionalDeduction}
              netSalary={salaryData?.netSalary}
              discounted={salaryData?.discounted}
              discountedCost={salaryData?.discountedCost}
              percentage={salaryData?.percentage}
              flatAmount={salaryData?.flatAmount}
              startDate={salaryData?.startDate}
              duration={salaryData?.duration}
              onEditSalary={handleEdit}
            />
            <PaySlips employeeID={employeeID} />
          </>
        )}
      </div>
    </>
  );
}

export default SalaryDetails;
