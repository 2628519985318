import {
    GET_PLATFORM_CONFIG,
    GET_PLATFORM_CONFIG_SUCCESS,
    GET_PLATFORM_CONFIG_FAILURE,
} from "../../constants/PlatformConfig";

// for employee information
export const GetPlatformConfig = (data) => {
    return {
        type: GET_PLATFORM_CONFIG,
        payload: data,
    };
};
export const GetPlatformConfigSuccess = (payload) => {
    return {
        type: GET_PLATFORM_CONFIG_SUCCESS,
        payload,
    };
};
export const GetPlatformConfigFailure = (payload) => {
    return {
        type: GET_PLATFORM_CONFIG_FAILURE,
        payload,
    };
};
