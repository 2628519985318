import React from "react";

const EditIcon = ({ color = "#02006E", style }) => {
  return (
    <svg
      width="15"
      height="14"
      viewBox="0 0 15 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={style}
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M7.92352 4.59153L3.57343 8.94058C3.34172 9.14552 3.19837 9.43222 3.17532 9.6945V11.4875L4.92765 11.4886C5.23318 11.467 5.51758 11.3248 5.74594 11.0635L10.0707 6.73879L7.92352 4.59153ZM8.73242 3.78283L10.8795 5.92999L12.2486 4.5609C12.2981 4.51137 12.3259 4.44419 12.3259 4.37414C12.3259 4.3041 12.2981 4.23692 12.2486 4.18739L10.4736 2.41236C10.4246 2.36299 10.3579 2.33523 10.2884 2.33523C10.2189 2.33523 10.1522 2.36299 10.1032 2.41236L8.73242 3.78283ZM10.2884 1.19141C10.6628 1.19141 11.0218 1.34092 11.2839 1.60514L13.0574 3.37858C13.3214 3.64262 13.4698 4.00073 13.4698 4.37414C13.4698 4.74755 13.3214 5.10566 13.0574 5.3697L6.58249 11.8426C6.18302 12.3034 5.61675 12.5865 4.96803 12.631H2.03149V12.0591L2.03335 9.64844C2.08207 9.04538 2.36244 8.48462 2.79013 8.10778L9.29214 1.60583C9.55579 1.34058 9.91436 1.19141 10.2884 1.19141Z"
        fill={color}
      />
    </svg>
  );
};

export default EditIcon;
