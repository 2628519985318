import React from "react";
import { Card, CardContent, Grid, Typography } from "@mui/material";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

const PayoneerCreateAccountSkeleton = () => {
  return (
    <>
      <Grid container className="justify-center items-center w-100p">
        <div className="peers-wrapper">
          <Card className="create-payoneer-account-card">
            <CardContent>
              <Grid
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  textAlign: "center",
                }}
              >
                <Skeleton circle height={153} width={153} />
                <Typography className="mt-3">
                  <Skeleton width={350} height={50} className="mt-125" />
                </Typography>
                <Skeleton width={150} height={25} className="mt-125" />
                <Grid className="flex flex-row items-center justify-center text-center mt-1">
                  <Skeleton
                    height={19}
                    width={50}
                    style={{ marginRight: "5px" }}
                    containerClassName="avatar-skeleton"
                  />
                  <Skeleton height={19} width={50} containerClassName="avatar-skeleton" />
                </Grid>
                <Grid className="mt-125">
                  <Typography>
                    <Skeleton width={150} />
                    <Skeleton width={200} />
                    <Skeleton width={150} />
                    <Skeleton width={250} />
                    <Skeleton width={150} />
                  </Typography>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </div>
      </Grid>
    </>
  );
};

export default PayoneerCreateAccountSkeleton;
