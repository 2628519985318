import { getFormattedDateAndTime } from "components/Screens/CommonUtils";
import { Grid } from "@mui/material";

import AvatarIcon from "components/Icons/AvatarIcon";

import "./Notes.css";

const NotesItem = ({ data }) => (
  <Grid key={data?.createdAt} sx={{ padding: "18px 0", display: "flex", width: "555px" }}>
    <div style={{ width: "65px", padding: "5px 0" }}>
      <AvatarIcon
        shape={"rounded"}
        size={{
          width: "55px",
          height: "55px",
          borderRadius: "4px",
          borderColor: "yellow",
        }}
        source={data?.image}
      />
    </div>
    <Grid sx={{ flex: 1 }}>
      <Grid
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          flex: 1,
          paddingBottom: "20px",
        }}
      >
        <p id="emp-name">
          {data?.edgeEmployee?.firstName} {data?.edgeEmployee?.lastName}
        </p>
        <CategoryLabel category={data?.title} />
      </Grid>
      <Grid sx={{ fontSize: "14px", fontWeight: 400 }}>
        <p style={{ color: "dimgray", paddingBottom: 20 }} id="desc-notes">
          {data?.description}
        </p>
        <p style={{ fontSize: "12px", color: "darkgray" }} id="date-created">
          {getFormattedDateAndTime(data?.createdAt)}
        </p>
      </Grid>
    </Grid>
  </Grid>
);

const CategoryLabel = ({ category }) => {
  const getBackgroundColor = (category) => {
    switch (category) {
      case "medium":
        return "#E5F3FF";
      case "high":
        return "#FFF6E5";
      case "critical":
        return "#FFE7E5";
      default:
        return "#F5FFFB";
    }
  };

  const backgroundColor = getBackgroundColor(category);

  return (
    <div
      id="category-level"
      style={{
        backgroundColor,
        padding: "5px 10px",
        color: "black",
        fontSize: 12,
        fontWeight: 400,
        height: 30,
        textTransform: "capitalize",
        borderRadius: "14px",
      }}
    >
      {category}
    </div>
  );
};

export default NotesItem;
