import { call, put, takeEvery } from "redux-saga/effects";

//actions
import {
    UploadLogoSuccess,
    UploadLogoFailure,
} from "../../actions/Logo/uploadLogo";

//constant
import { UPLOAD_LOGO } from "../../constants/Logo/uploadLogo";

//request type
import postRequest from "../../../util/APIHelperPost";
import { baseUrl } from "../../../util/APIBaseUrl";

export const fetchUploadLogoRequest = async (actions) => {
    // eslint-disable-next-line no-return-await
    let body = {
        formData: actions.payload.formData,
    };

    return await postRequest(
        `${baseUrl}/${actions.payload.request}`,
        body,
        true,
        "POST",
        "multipart/form-data"
    )
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return error;
        });
};

function* fetchUploadLogo(params) {
    try {
        let response = yield call(fetchUploadLogoRequest, params);
        yield put(UploadLogoSuccess(response));
    } catch (error) {
        console.error(error);
        yield put(UploadLogoFailure(error));
    }
}

export default function* rootSaga() {
    yield takeEvery(UPLOAD_LOGO, fetchUploadLogo);
}
