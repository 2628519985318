import React from "react";
//materail ui
import { Button, Grid } from "@mui/material";
//widgets
import Header from "../../Header/Header";
import PageHeader from "../PageHeader";
//components
import Profile from "./Profile";
import MangeRoles from "./MangeRoles";
import CompanyProfile from "./CompanyProfile";
import AddForm from "../../Widgets/AddForm";

let loggedInUser = {};
loggedInUser = JSON.parse(localStorage.getItem("loggedInUser"));

function index(props) {
  //component state
  const [option, selectedOption] = React.useState("");
  const [pageHeader, setPageHeader] = React.useState(["Company Profile"]);

  //function
  const selectPageHeading = (opt) => {
    selectedOption(opt);
  };

  //methods

  React.useEffect(() => {
    let loggedInUser = {};
    loggedInUser = JSON.parse(localStorage.getItem("loggedInUser"));
    if (loggedInUser?.type == "customer") {
      selectedOption("Company Profile");
      if (loggedInUser?.permissions?.includes("CustomerAdmin")) {
        setPageHeader(["Company Profile", "Manage Users"]);
      } else {
        setPageHeader(["Company Profile"]);
      }
    } else if (loggedInUser?.type == "edge") {
      setPageHeader(["Manage Users"]);
      selectedOption("Manage Users");
    }
  }, []);

  return (
    <Grid container direction="column" rowSpacing={3}>
      <Header
        data={{
          heading: "Account Settings",
        }}
      />
      <PageHeader pageHeadings={pageHeader} selectPageHeading={selectPageHeading} selectedOption={option} />
      <Grid item container direction="row">
        <Grid item xs={12}>
          {option == "Manage Users" ? (
            <MangeRoles />
          ) : // <AddForm />
          option == "Company Profile" ? (
            <CompanyProfile />
          ) : // <AddForm />
          null}
        </Grid>
      </Grid>
    </Grid>
  );
}

export default index;
