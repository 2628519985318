import {
  RESER_ADD_EMPLOYEE_SALARY,
  ADD_EMPLOYEE_SALARY,
  ADD_EMPLOYEE_SALARY_SUCCESS,
  ADD_EMPLOYEE_SALARY_FAILURE,
} from "../../../constants/Workforce/AddEmployee/AddEmployeeSalary";

// for user authentication
export const ResetAddEmployeeSalary = (data) => {
  return {
    type: RESER_ADD_EMPLOYEE_SALARY,
    payload: data,
  };
};

export const AddEmployeeSalary = (data) => {
  return {
    type: ADD_EMPLOYEE_SALARY,
    payload: data,
  };
};
export const AddEmployeeSalarySuccess = (payload) => {
  return {
    type: ADD_EMPLOYEE_SALARY_SUCCESS,
    payload,
  };
};
export const AddEmployeeSalaryFailure = (payload) => {
  return {
    type: ADD_EMPLOYEE_SALARY_FAILURE,
    payload,
  };
};
