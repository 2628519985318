import {
    RESET_GET_CUSTOMER_LIST,
    GET_CUSTOMER_LIST,
    GET_CUSTOMER_LIST_SUCCESS,
    GET_CUSTOMER_LIST_FAILURE,
} from "../../constants/Customers/GetCustomerList";

// for employee information

export const ResetGetCustomersList = (data) => {
    return {
        type: RESET_GET_CUSTOMER_LIST,
        payload: data,
    };
};

export const GetCustomersList = (data) => {
    return {
        type: GET_CUSTOMER_LIST,
        payload: data,
    };
};
export const GetCustomersListSuccess = (payload) => {
    return {
        type: GET_CUSTOMER_LIST_SUCCESS,
        payload,
    };
};
export const GetCustomersListFailure = (payload) => {
    return {
        type: GET_CUSTOMER_LIST_FAILURE,
        payload,
    };
};
