import React from "react";
//mui
import { FormControl, Grid, InputAdornment, Paper, TextField, Typography } from "@mui/material";
import { Box } from "@mui/system";
//redux dispatch
import { useSelector } from "react-redux";
//widgtes
import DataTable from "../../../Widgets/DataTable";
import BonusDetails from "../../../Widgets/BonusDetails";
import ProfleWithIcon from "../../../Widgets/ProfileWithIcon";
//icons
import AvatarIcon from "../../../Icons/AvatarIcon";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
//api base urls
import { baseUrl } from "../../../../util/APIBaseUrl";
//common functions
import { getValue } from "../../CommonUtils";
import PageLoader from "../../../common/PageLoader";

//constants
const tableheadCells = [
  {
    id: "Profile_Picture",

    mobileView: true,
    label: "",
    align: "right",
    width: "20px",
    sort: false,
  },
  {
    id: "Company_Name",
    wrapCell: true,
    numeric: false,
    disablePadding: false,
    // label: <Typography variant="h5">Customer Name</Typography>,
  },
];

function AddBonus(props) {
  //component state
  const [tableRows, setTableRows] = React.useState([]);

  //redux state
  const customerInfo = useSelector((state) => state.CustomerInfo.customerInfo);
  const loadingCustomerInfo = useSelector((state) => state.CustomerInfo.loadingCustomerInfo);

  //function

  const handleRowsPerPage = (pagination) => {};

  const handleSelectedRow = (selectedEmployeeId, navigateTo) => {};

  //methods
  React.useEffect(() => {
    let Data = [];

    if (
      customerInfo != null &&
      Object.keys(customerInfo).length &&
      customerInfo.ok &&
      customerInfo?.payload?.rows?.length
    ) {
      customerInfo?.payload?.rows?.map((customer, index) => {
        Data.push({
          id: customer.customerId,
          Profile_Picture: (
            <Box display="flex" alignItems="flex-end" justifyContent="flex-end" ml={0} pl={0} sx={{ ml: 0, pl: 0 }}>
              <AvatarIcon
                shape={"circle"}
                size={{
                  width: "8vh",
                  height: "8vh",
                  borderRadius: "50%",
                }}
                source={`${baseUrl}/customer/${customer.customerId}/logo?id=${customer?.pictureVersion}`}
              />
            </Box>
          ),
          Company_Name: (
            <ProfleWithIcon
              type="textOnly"
              data={{
                name: getValue(customer.companyName),
                role: getValue(customer.industry),
              }}
            />
          ),
        });
      });
    }

    setTableRows(Data);
  }, [customerInfo]);

  return (
    <>
      {loadingCustomerInfo && <PageLoader />}
      <Grid item container direction="row" xs={7} columnSpacing={5} rowSpacing={5}>
        <Grid item xs={12}>
          <FormControl variant="outlined" fullWidth error>
            <label>
              <Typography
                fontWeight={600}
                sx={{
                  fontFamily: "Poppins-Medium !important",

                  fontSize: "13px",
                  lineHeight: "8px",
                  paddingTop: 1,
                }}
              >
                Enter Amount
              </Typography>
            </label>
            <TextField
              sx={{
                "& .MuiInputBase-root": {
                  backgroundColor: "white",
                  borderRadius: "6px",
                  fontFamily: "Poppins-Medium !important",
                  height: "60px",
                },
                "& .MuiInputBase-input": {
                  paddingTop: 1,
                  paddingLeft: 2,
                  paddingBottom: 1,

                  fontSize: "18px",
                },
              }}
              id="userName"
              variant={"outlined"}
              placeholder="enter amount"
              size="small"
              name="email"
              fullWidth={true}
              margin="normal"
              InputProps={{
                disableUnderline: true,
                startAdornment: (
                  <InputAdornment position="start">
                    <AttachMoneyIcon fontSize="20px" color="secondary.alt" />
                  </InputAdornment>
                ),
              }}
            />
          </FormControl>
          <Typography
            fontWeight={600}
            sx={{
              fontFamily: "Poppins-Medium !important",

              fontSize: "13px",
              lineHeight: "8px",
              paddingTop: 1,
            }}
          >
            Or , Here are some presets to get you started
          </Typography>

          <Grid item xs={12}>
            {/* <Paper elevation={1} sx={{ mt: 2, p: 1, height: "60vh" }}> */}
            <Grid item container direction="column" rowSpacing={2} sx={{ mt: 1 }}>
              <Grid item container direction="row" columnSpacing={3} sx={{ p: 3 }} xs={12}>
                <Grid item xs={4}>
                  <BonusDetails number={"123"} country={"pakistan"} bonusAmount={"20"} bonusFor={"Lunch Money"} />
                </Grid>
                <Grid item xs={4}>
                  <BonusDetails number={"123"} country={"pakistan"} bonusAmount={"20"} bonusFor={"Lunch Money"} />
                </Grid>
                <Grid item xs={4}>
                  <BonusDetails number={"123"} country={"pakistan"} bonusAmount={"20"} bonusFor={"Lunch Money"} />
                </Grid>
              </Grid>

              <Grid item>
                <Typography
                  fontWeight={600}
                  sx={{
                    fontFamily: "Poppins-Medium !important",

                    fontSize: "13px",
                    lineHeight: "8px",
                    paddingTop: 1,
                  }}
                >
                  Select the Employee you want to give the <b>$ 30</b> bonus too
                </Typography>
              </Grid>

              <Grid item>
                <DataTable
                  rowsData={tableRows}
                  totalCount={customerInfo?.payload?.count ? customerInfo.payload.count : 0}
                  tableheadCells={tableheadCells}
                  // title={`Your Customers ()`}
                  subTitle={null}
                  toolBar={true}
                  minimumHeight={false}
                  handleRowsPerPage={handleRowsPerPage}
                  handleSelectedRow={handleSelectedRow}
                  openDetailsPage={"Company_Name"}
                  sortByName={"Company_Name"}
                />
              </Grid>
            </Grid>
            {/* </Paper> */}
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}

export default AddBonus;
