import { call, put, takeEvery } from "redux-saga/effects";
//actions
import {
  DeleteCustomerBankInfoSuccess,
  DeleteCustomerBankInfoFailure,
} from "../../actions/Payment/DeleteBankAcc";
//constant
import { DELETE_CUSTOMER_BANK_INFO } from "../../constants/Payment/DeleteBankAcc";
//request type
import postRequest from "../../../util/APIHelperPost";
import deleteRequest from "../../../util/ApiHelperDelete";

export const fetchDeleteAccountInfoReq = async (actions) => {
  // eslint-disable-next-line no-return-await

  return await deleteRequest(`${actions.payload.request}`, {})
    .then((response) => response)
    .catch((error) => {
      throw error;
    });
};

function* fetchDeleteAccountInfo(params) {
  try {
    let response = yield call(fetchDeleteAccountInfoReq, params);
    yield put(DeleteCustomerBankInfoSuccess(response));
  } catch (error) {
    console.error(error);
    yield put(DeleteCustomerBankInfoFailure(error));
  }
}

export default function* rootSaga() {
  yield takeEvery(DELETE_CUSTOMER_BANK_INFO, fetchDeleteAccountInfo);
}
