import React from "react";
import { Grid, Typography } from "@mui/material";
import "./PageHeader.css";
import logo from "../../../assets/images/edge-logo-v4.svg";

const PolicyHeader = ({ title, subTitle }) => {
  return (
    <>
      <Grid xl={12} sm={12} md={12} lg={12}>
        <Grid className="service-header-body pt-5" container justifyContent="center" alignItems="center">
          <Grid item lg={8} md={8} sm={10} xs={10}>
            <img src={logo} className="service-edge-logo" alt="logo" />
            <Typography
              variant="h6"
              fontWeight={600}
              color="#181a9d"
              fontFamily={"Poppins"}
              marginTop="1.5rem"
              sx={{
                fontSize: { xs: "2.5rem", sm: "3rem", md: "4.5rem", lg: "85px" },
                width: { xs: "100%", sm: "100%", md: "60%", lg: "60%" },
                lineHeight: { sm: "95px" },
              }}
            >
              {title}
            </Typography>
            <Typography className="service-sub-heading">{subTitle}</Typography>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default PolicyHeader;
