import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { MsalProvider, useMsal } from "@azure/msal-react";
import { PublicClientApplication } from "@azure/msal-browser";
import { Button, Typography } from "@mui/material";
import MicrosoftIcon from "assets/svgs/AccountSettingsIcon";
import { SignInScreenPermissions } from "../SignIn.enum";
import { DropdownData } from "store/actions/DropdownData";
import { AuthUserSuccess, ResetAuthUser } from "store/actions/AuthUser";
import { GetPlatformConfig } from "store/actions/PlatformConfig";
import { UserAdditionalInfo } from "store/actions/UserAdditionalInfo";
import { MicrosoftSignup } from "store/actions/Microsoft/Microsoft_signup";
import postRequest from "util/APIHelperPost";
import { baseUrl } from "util/APIBaseUrl";
import MicrosoftLogo from "assets/images/microsoft-icon.png";

const MicrosoftAuth = (props) => {
  const { changeScreen, loginPortal } = props;
  let history = useNavigate();
  const dispatch = useDispatch();
  const [msalInstance, setMsalInstance] = React.useState(null);

  const userAdditionalInfo = useSelector((state) => state.UserAdditionalInfo.userAdditionalInfo);
  const platformConfig = useSelector((state) => state.PlatformConfig.platformConfig);
  const dropdownData = useSelector((state) => state.DropdownData.dropdownData);

  const handleLogin = async () => {
    try {
      // Call the initialization function before using other MSAL API methods
      await msalInstance.handleRedirectPromise();
      const loginResponse = await msalInstance.loginPopup({ scopes: ["User.Read"] });

      const resp = await postRequest(`${baseUrl}/customer/microsoft/auth`, JSON.stringify(loginResponse), true, "POST");

      dispatch(AuthUserSuccess(resp));
    } catch (error) {
      console.error("Login error:", error);
    }
  };

  React.useEffect(() => {
    if (platformConfig?.ok && platformConfig?.payload?.MICROSOFT_CLIENT_ID) {
      const msalConfig = {
        auth: {
          clientId: platformConfig?.payload?.MICROSOFT_CLIENT_ID,
          authority: platformConfig.payload.MICROSOFT_AUTHORITY,
          redirectUri: window.location.origin,
        },
      };
      const instance = new PublicClientApplication(msalConfig);
      setMsalInstance(instance);
    }
  }, [platformConfig.payload.MICROSOFT_CLIENT_ID]);

  React.useEffect(() => {
    let loggedInUser = JSON.parse(localStorage.getItem("loggedInUser"));
    let accessToken = localStorage.getItem("accessToken");

    if (accessToken && loggedInUser) {
      dispatch(DropdownData());
    }
  }, []);

  React.useEffect(() => {
    let loggedInUser = {};
    let accessToken = localStorage.getItem("accessToken");

    if (dropdownData?.ok) {
      if (accessToken !== null && accessToken !== "undefined") {
        loggedInUser = JSON.parse(localStorage.getItem("loggedInUser"));
        if (loggedInUser !== null && loggedInUser !== undefined) {
          if (loggedInUser.type === "customer") {
            if (loggedInUser.setupCompany) {
              changeScreen(SignInScreenPermissions.ADD_CUSTOMER_INFO);
            } else if (!loggedInUser.bankAttached && userAdditionalInfo.status === "enabled") {
              changeScreen(SignInScreenPermissions.ADD_PAYMENT_METHOD);
            } else {
              history("/main/dashboard");
            }
          } else if (loggedInUser.type === "employee") {
            dispatch(UserAdditionalInfo(`employee/${loggedInUser.userId}`));
            history("/main/dashboard");
          } else {
            history("/main/dashboard");
          }
        }
      }
    }
  }, [dropdownData, userAdditionalInfo]);

  return (
    <div>
      {msalInstance && (
        <MsalProvider instance={msalInstance}>
          <Button
            type="submit"
            variant="contained"
            fullWidth={true}
            onClick={handleLogin}
            sx={{
              backgroundColor: "#FFFFFF",
              height: "54px",
              fontFamily: "Poppins-Regular !important",
              fontWeight: "500",
              lineHeight: "20",
              borderRadius: "99px",
              fontSize: "16px",
              color: "#3D43BB",
              boxShadow: "2px 5px 7px rgba(2, 0, 110, 0.12)",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              gap: "12px",
              ":hover": {
                bgcolor: "primary.main",
                color: "white",
              },
            }}
          >
            <div className="w-3">
              <img src={MicrosoftLogo} alt="microsoft icon" width="26px" />
            </div>
            <Typography>Continue with Microsoft</Typography>
          </Button>
        </MsalProvider>
      )}
    </div>
  );
};

export default MicrosoftAuth;
