import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import moment from "moment";
import { useForm, FormProvider } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import Text from "components/common/Text";
import Button from "components/common/Button";
import Modal from "components/common/Modal";
import Select from "components/common/Select";
import CustomDatePicker from "components/common/DatePicker";
import { getYearFromDate } from "components/Screens/CommonUtils";

import EducationSchema from "validations/EducationSchema";
import { EDUCATION_LEVELS } from "constants";
import { deleteEmployeeEducation, editEmployeeEducation, postEmployeeEducation } from "services/BambooHrTabs/education";

function RenderDeleteComp({ data, closeModal, isLoading }) {
  const { degreeName, schoolName, startDate, endDate } = data;
  return (
    <div className="mt-15">
      <p className="heading-h4-medium">{degreeName}</p>
      <p className="para-body-m-medium color-text-action capitalize">{schoolName}</p>
      <p className="para-body-m-medium color-text-body-primary">{`${getYearFromDate(startDate)} - ${getYearFromDate(endDate)}`}</p>
      <div className="flex w-full justify-end pt-05 gap-05">
        <Button type="submit" variant="outlined" color="inherit-text" onClick={closeModal} label="Cancel" />
        <Button color="error" type="submit" isLoading={isLoading} name="submitButton" label="Delete" />
      </div>
    </div>
  );
}

function EducationModal({ open, mode, closeModal, employeeId, data, onSuccess }) {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const methods = useForm({
    resolver: yupResolver(EducationSchema),
    mode: "onChange",
    defaultValues: {
      degreeName: data?.degreeName || "",
      startDate: data?.startDate ? moment(data.startDate).toDate() : null,
      endDate: data?.endDate ? moment(data.endDate).toDate() : null,
      fieldOfStudy: data?.fieldOfStudy || "",
      schoolName: data?.schoolName || "",
    },
  });

  const {
    handleSubmit,
    reset,
    formState: { isValid },
  } = methods;

  useEffect(() => {
    reset({
      degreeName: data?.degreeName || "",
      startDate: data?.startDate ? moment(data.startDate).toDate() : null,
      endDate: data?.endDate ? moment(data.endDate).toDate() : null,
      fieldOfStudy: data?.fieldOfStudy || "",
      schoolName: data?.schoolName || "",
    });
  }, [data, reset]);

  const onSubmit = async (formData) => {
    setIsLoading(true);
    const payload = {
      ...formData,
      startDate: moment(formData.startDate).format("YYYY-MM-DD"),
      endDate: moment(formData.endDate).format("YYYY-MM-DD"),
    };

    if (mode === "Add") await postEmployeeEducation(employeeId, payload, dispatch);
    else if (mode === "Edit") await editEmployeeEducation(employeeId, payload, data.educationId, dispatch);
    else if (mode === "Delete") await deleteEmployeeEducation(employeeId, data.educationId, dispatch);

    setIsLoading(false);
    closeModal();
    onSuccess();
  };

  return (
    <Modal
      open={open}
      title={`${mode} Education ${mode === "Delete" ? "?" : ""}`}
      width={mode === "Delete" ? "500px" : "700px"}
    >
      <FormProvider {...methods}>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            handleSubmit((formData) => onSubmit(formData, e))(e);
          }}
        >
          {mode === "Delete" ? (
            <RenderDeleteComp data={data} closeModal={closeModal} isLoading={isLoading} />
          ) : (
            <div className="space-y-2 mt-15">
              <div className="flex">
                <Text label="School" name="schoolName" placeholder="e.g. Massachusetts Institute of Technology" />
              </div>

              <div className="flex gap-1">
                <Select label="Degree" name="degreeName" placeholder="Select" options={EDUCATION_LEVELS} />
                <Text label="Field of study" name="fieldOfStudy" placeholder="e.g. Environmental science" />
              </div>

              <div className="flex gap-1">
                <CustomDatePicker name="startDate" label="Start date" />
                <CustomDatePicker name="endDate" label="End date (or expected)" />
              </div>
              <div className="flex w-full justify-end pt-1 gap-05">
                <Button type="submit" variant="outlined" size="large" onClick={closeModal} label="Cancel" />
                <Button
                  isDisabled={!isValid}
                  size="large"
                  isLoading={isLoading}
                  type="submit"
                  name="submitButton"
                  label="Save"
                />
              </div>
            </div>
          )}
        </form>
      </FormProvider>
    </Modal>
  );
}

export default EducationModal;
