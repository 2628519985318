import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useSelector, useDispatch } from "react-redux";
import { Box, Button, Grid, Stack, Tab, Tabs, Typography } from "@mui/material";
import { format, addMonths, startOfMonth, parseISO, lastDayOfMonth, startOfDay, endOfMonth } from "date-fns";

import UpcomingHolidayCard from "./UpcomingHolidayCard";
import PageTitle from "components/Widgets/PageTitle";
import PageLoader from "components/common/PageLoader";
import { usePermissions } from "components/Hook";
import { getMonthFromDate } from "components/Screens/CommonUtils";

import { GetAllHolidays } from "store/actions/Holidays";
import { baseUrl } from "util/APIBaseUrl";
import { makeRequest } from "util/APIHelper";

import "./UpcomingHolidays.css";

const todayDate = new Date();

function UpcomingHolidays() {
  const getFormattedDates = (date) => {
    const defaultStartDate = format(startOfMonth(date), "yyyy-MM-dd");
    const defaultMidDate = format(startOfMonth(addMonths(date, 1)), "yyyy-MM-dd");
    const defaultEndDate = format(endOfMonth(addMonths(date, 2)), "yyyy-MM-dd");
    return { defaultStartDate, defaultMidDate, defaultEndDate };
  };

  const { defaultStartDate, defaultMidDate, defaultEndDate } = getFormattedDates(todayDate);

  const dispatch = useDispatch();

  const platformConfig = useSelector((state) => state.PlatformConfig.platformConfig);
  const loadingPlatformConfig = useSelector((state) => state.PlatformConfig.loadingPlatformConfig);

  const [holidaysTab, setHolidaysTab] = React.useState("All");
  const [employeeDetails, setEmployeeDetails] = useState(null);
  const [showInfoTooltip, setShowInfoTooltip] = React.useState(false);

  const [startDate, setStartDate] = React.useState(defaultStartDate);
  const [midDate, setMidDate] = React.useState(defaultMidDate);
  const [endDate, setEndDate] = React.useState(defaultEndDate);

  const { loggedInUserType, loggedInUser } = usePermissions();

  const handleChange = (event, newValue) => {
    window.location.hash = newValue;
    setHolidaysTab(newValue);
  };

  const handleInfoTooltip = () => {
    setShowInfoTooltip(!showInfoTooltip);
  };

  const allHolidays = useSelector((state) => state.Holidays.allHolidays);
  const loadingHolidaysList = useSelector((state) => state.Holidays.loadingHolidaysList);

  const getPrevMonth = () => {
    const parsedStartDate = startOfDay(parseISO(startDate));

    const previousEndDate = lastDayOfMonth(addMonths(parsedStartDate, -1));
    const previousMidDate = startOfMonth(addMonths(previousEndDate, -1));
    const previousStartDate = startOfMonth(addMonths(previousMidDate, -1));

    const formattedStartDate = format(previousStartDate, "yyyy-MM-dd");
    const formattedMidDate = format(previousMidDate, "yyyy-MM-dd");
    const formattedEndDate = format(previousEndDate, "yyyy-MM-dd");

    setStartDate(formattedStartDate);
    setMidDate(formattedMidDate);
    setEndDate(formattedEndDate);
  };

  const getNextMonth = () => {
    const parsedEndDate = startOfDay(parseISO(endDate));
    const nextStartDate = startOfMonth(addMonths(parsedEndDate, 1));

    const newMidDate = startOfMonth(addMonths(nextStartDate, 1));

    const nextMonth = addMonths(newMidDate, 1);
    const newEndDate = lastDayOfMonth(nextMonth);

    const formattedStartDate = format(nextStartDate, "yyyy-MM-dd");
    const formattedMidDate = format(newMidDate, "yyyy-MM-dd");
    const formattedEndDate = format(newEndDate, "yyyy-MM-dd");

    setStartDate(formattedStartDate);
    setMidDate(formattedMidDate);
    setEndDate(formattedEndDate);
  };

  const getCOuntryCalender = () => {
    const countryHoliday = platformConfig?.payload?.CALENDAR && JSON.parse(platformConfig?.payload?.CALENDAR);
    const countryCalendarUrl = countryHoliday?.find((region) => region.region === holidaysTab);
    return countryCalendarUrl?.link;
  };

  const showDownloadButton = () => {
    if (holidaysTab !== "All" && getCOuntryCalender()) {
      return true;
    }
    return false;
  };

  const downloadCalendar = () => {
    window.open(getCOuntryCalender(), "_blank");
  };

  useEffect(() => {
    let holidaysRequest = "";

    holidaysRequest = {
      customer: `${baseUrl}/customer/${loggedInUser?.customerId}/publicHolidays`,
      employee: `${baseUrl}/employee/publicHolidays`,
    };

    let region;

    if (loggedInUserType === "employee") {
      region = employeeDetails?.country || "";
    } else if (holidaysTab !== "All") {
      region = holidaysTab;
    } else {
      region = "";
    }

    dispatch(
      GetAllHolidays({
        request: holidaysRequest[loggedInUserType],
        region,
        startDate,
        endDate,
      })
    );
  }, [loggedInUserType, holidaysTab, startDate, endDate, loggedInUser]);

  const employeeId = loggedInUser?.userId;
  const requestOptions = {
    path: `${baseUrl}/employee/${employeeId}`,
    method: "GET",
  };

  const fetchEmployeeDetails = async () => {
    try {
      const response = await makeRequest(requestOptions, true /* loaderFlag */, false /* apiType */);
      if (response.ok) {
        setEmployeeDetails(response.payload);
      } else {
        console.error("Failed to fetch employee details");
      }
    } catch (error) {
      console.error("Error fetching employee details:", error);
    }
  };

  useEffect(() => {
    if (loggedInUserType === "employee") {
      fetchEmployeeDetails();
    }
  }, [loggedInUser, loggedInUserType]);

  useEffect(() => {
    if (loggedInUserType === "employee") {
      const employeeCountry = employeeDetails?.country;

      setHolidaysTab(employeeCountry);
    }
  }, [allHolidays, loggedInUserType, employeeDetails]);

  return (
    <>
      <Helmet>
        <title>Edge | Upcoming Holidays</title>
      </Helmet>
      {(loadingHolidaysList || loadingPlatformConfig) && <PageLoader />}
      <Grid
        sx={{
          background: "#FAFAFF",
          paddingBottom: "32px",
          height: "calc(100vh - 46px)",
        }}
      >
        <PageTitle
          page={{
            title: "Upcoming Holidays",
            linkName: "Upcoming Holidays",
            link: "",
          }}
        />

        <Grid className="holidays-tabs-container">
          <Tabs
            value={holidaysTab}
            onChange={handleChange}
            sx={{
              ".MuiTabs-indicator": {
                height: "4px !important",
                zIndex: 2,
              },
              ".MuiTabs-flexContainer": {
                gap: "16px",
              },
              ".Mui-selected": {
                color: "#212231",
              },
            }}
          >
            {loggedInUserType === "customer" ? (
              <Tab label="All" value="All" disableRipple className="holidays-tab" />
            ) : (
              <Tab
                label={employeeDetails?.country}
                value={employeeDetails?.country}
                key={employeeDetails?.country}
                disableRipple
                className="holidays-tab"
              />
            )}
            {loggedInUserType === "customer" &&
              allHolidays?.countryList?.map((country) => {
                return <Tab label={country} value={country} key={country} disableRipple className="holidays-tab" />;
              })}
          </Tabs>
          <span
            style={{
              display: "block",
              width: "100%",
              height: "1px",
              backgroundColor: "#B2B4E2",
              position: "absolute",
              bottom: "2px",
            }}
          />
        </Grid>
        <Grid
          sx={{
            maxWidth: "1076px",
            width: "100%",
            margin: "0px auto",
          }}
        />
        <Grid sx={{ maxWidth: "1076px", width: "100%", margin: "0px auto" }}>
          <Grid
            sx={{
              marginTop: "16px",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Grid sx={{ display: "flex", alignItems: "center" }}>
              <Box className="holidays-calendar-prev">
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  onClick={() => {
                    getPrevMonth();
                  }}
                >
                  <path d="M14 7L9 12L14 17L14 7Z" fill="#4950C7" />
                </svg>
              </Box>
              <Box className="holidays-calendar-date">
                {`${getMonthFromDate(startDate)} , ${getMonthFromDate(midDate)} , ${getMonthFromDate(endDate)} `}
              </Box>
              <Box className="holidays-calendar-next">
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  onClick={() => {
                    getNextMonth();
                  }}
                >
                  <path d="M10 17L15 12L10 7L10 17Z" fill="#4950C7" />
                </svg>
              </Box>
            </Grid>
            {showDownloadButton() && (
              <Grid
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: "12px",
                  position: "relative",
                }}
              >
                <Button variant="primary" size="lg" className="holidays-calendar-download" onClick={downloadCalendar}>
                  Download Calendar
                </Button>
                <Button variant="primary" size="lg" className="holidays-info-button" onClick={handleInfoTooltip}>
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M12.0001 22.2282C6.35124 22.2282 1.77197 17.6489 1.77197 12.0001C1.77197 6.35136 6.35124 1.77209 12.0001 1.77209C17.6489 1.77209 22.2281 6.35136 22.2281 12.0001C22.2281 17.6489 17.6489 22.2282 12.0001 22.2282ZM12.0001 20.3686C16.6218 20.3686 20.3685 16.6219 20.3685 12.0001C20.3685 7.37841 16.6218 3.63175 12.0001 3.63175C7.37829 3.63175 3.63162 7.37841 3.63162 12.0001C3.63162 16.6219 7.37829 20.3686 12.0001 20.3686ZM12.9332 13.8583H13.8625V15.7179H10.1432V13.8583H11.0729V11.9986H10.1432V10.139H12.9332V13.8583ZM12.0003 9.20912C11.4866 9.20912 11.0702 8.79286 11.0702 8.27933C11.0702 7.7658 11.4866 7.34951 12.0003 7.34951C12.514 7.34951 12.9305 7.7658 12.9305 8.27933C12.9305 8.79286 12.514 9.20912 12.0003 9.20912Z"
                      fill="#02006E"
                    />
                  </svg>
                </Button>
                {showInfoTooltip && (
                  <Box className="holidays-info-tooltip">
                    <svg
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      style={{
                        position: "absolute",
                        right: "15px",
                        cursor: "pointer",
                      }}
                      onClick={handleInfoTooltip}
                    >
                      <path
                        d="M4.50537 11.4955L8.0007 8.00022L11.496 11.4955M11.496 4.50488L8.00004 8.00022L4.50537 4.50488"
                        stroke="#02006E"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>

                    <Typography className="holidays-info-tooltip-content">
                      If an Associate is needed to work on any of the above mentioned Public holidays, they can be given
                      the option to swap and take time off on any other day of their choice as agreed upon with their
                      Client.
                      <br />
                      <br />
                      OR
                      <br />
                      <br />
                      An amount of $60/day will be charged from the Client for Associate working that day.
                    </Typography>
                  </Box>
                )}
              </Grid>
            )}
          </Grid>
        </Grid>
        {allHolidays?.success ? (
          <>
            <Grid
              sx={{
                maxWidth: "1076px",
                width: "100%",
                margin: "0px auto",
              }}
            >
              <Typography
                sx={{
                  marginTop: "25px",
                  fontFamily: "Poppins-Medium",
                  fontSize: "14px",
                  fontWeight: 500,
                  lineHeight: "20px",
                  color: "#212231",
                }}
                id="upcoming holidays"
              >
                Upcoming holidays in next 3 months ({`${allHolidays?.data?.length ? allHolidays?.data?.length : 0}`})
              </Typography>
            </Grid>
            <Grid className="holidays-listing">
              <Grid container sx={{ gap: "16px" }}>
                {allHolidays?.data?.map((holiday) => (
                  <UpcomingHolidayCard key={holiday.id} holiday={holiday} />
                ))}
              </Grid>
            </Grid>
          </>
        ) : (
          <Stack direction="row" justifyContent="center">
            No Data Found
          </Stack>
        )}
      </Grid>
    </>
  );
}

export default UpcomingHolidays;
