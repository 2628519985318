import { call, put, takeEvery } from "redux-saga/effects";
//actions
import {
  RetryPaymentSuccess,
  RetryPaymentFailure,
} from "../../actions/Payment/RetryPayment";
//constant
import { RETRY_PAYMENT } from "../../constants/Payment/RetryPayment";
//request type
import getRequest from "../../../util/APIHelperGet";

export const fetchRetryPaymentRequest = async (actions) => {
  // eslint-disable-next-line no-return-await

  return await getRequest(`${actions.payload.request}`)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

function* fetchRetryPayment(params) {
  try {
    let response = yield call(fetchRetryPaymentRequest, params);
    yield put(RetryPaymentSuccess(response));
  } catch (error) {
    console.error(error);
    yield put(RetryPaymentFailure(error));
  }
}

export default function* rootSaga() {
  yield takeEvery(RETRY_PAYMENT, fetchRetryPayment);
}
