import { useEffect, useState } from "react";
import { Fade } from "@mui/material";

import LeftArror from "assets/images/left-Arrow-filled.svg";
import RightArror from "assets/images/right-Arrow-filled.svg";
import PageLoader from "components/common/PageLoader";

import "./HistoryLeaves.css";
import HistoryLeavesCard from "./HisotyLeavesCard";

const HistoryLeaves = ({ data }) => {
  const [leaveRequests, setleaveRequests] = useState([]);
  const [cardShownOnScreenFlag, setCardShownOnScreenFlag] = useState(true);
  const [currentLeave, setCurrentLeave] = useState({});
  const [currentShownLeaveCard, setCurrentShownLeaveCard] = useState(data?.leaveData.findIndex(leave => leave.requestId === data?.currentLeave?.requestId));

  useEffect(() => {
    setleaveRequests(data?.leaveData || []);
    setCurrentLeave(data?.currentLeave || {});
  }, [data?.leaveData]);

  useEffect(() => {
    setCardShownOnScreenFlag(false);
    setTimeout(() => setCardShownOnScreenFlag(true), 300);
  }, [currentShownLeaveCard]);

  return (
    <div className="pending-leaves-container-history">
      <div className="mb-2">
        {leaveRequests?.length ? (
          <div className="flex items-center justify-around w-full">
            {" "}
            <img
              src={LeftArror}
              style={{
                filter: currentShownLeaveCard === 0 ? "grayscale(1)" : "",
                cursor: currentShownLeaveCard === 0 ? "not-allowed" : "pointer",
              }}
              onClick={() => {
                if (currentShownLeaveCard !== 0) setCurrentShownLeaveCard(currentShownLeaveCard - 1);
              }}
              alt="left arrow"
            />
            <Fade in={cardShownOnScreenFlag}>
              <div>
                <p className="pt-05 pb-1">Request history</p>
                <HistoryLeavesCard leave={leaveRequests[currentShownLeaveCard] || {}} />
              </div>
            </Fade>
            <img
              style={{
                filter: currentShownLeaveCard === leaveRequests.length - 1 ? "grayscale(1)" : "",
                cursor: currentShownLeaveCard === leaveRequests.length - 1 ? "not-allowed" : "pointer",
              }}
              src={RightArror}
              onClick={() => {
                if (currentShownLeaveCard !== leaveRequests.length - 1)
                  setCurrentShownLeaveCard(currentShownLeaveCard + 1);
              }}
              alt="left arrow"
            />
          </div>
        ) : (
          <p className="m-auto text-center">No History leaves</p>
        )}
      </div>
    </div>
  );
};

export default HistoryLeaves;
