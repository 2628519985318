import { call, put, takeEvery } from "redux-saga/effects";
import {
  CalculateEmployeeSalarySuccess,
  CalculateEmployeeSalaryFailure,
} from "../../../actions/Workforce/AddEmployee/CalculateEmployeeSalary";
import { CALCULATE_EMPLOYEE_SALARY } from "../../../constants/Workforce/AddEmployee/CalculateEmployeeSalary";
import postRequest from "../../../../util/APIHelperPost";
import { baseUrl } from "../../../../util/APIBaseUrl";

export const fetchCalculateEmployeeSalaryRequest = async (actions) => {
  let body = JSON.stringify({
    grossSalary: actions.payload.salaryData.grossPay,
    costToCustomer: actions.payload.salaryData.costToCustomer,
    platformFee: actions.payload?.salaryData?.edgeFee,
    // platformFee:actions.payload?.salaryData?.edgefee,
    additionalDeduction: actions.payload?.salaryData?.deduction,
  });

  return await postRequest(
    `${baseUrl}/employee/${actions.payload.employeeId}/calculateSalary`,
    body,
    actions?.payload?.loaderFlag
  )
    .then((response) => response)
    .catch((error) => {
      throw error;
    });
};

function* fetchCalculateEmployeeSalary(params) {
  try {
    let response = yield call(fetchCalculateEmployeeSalaryRequest, params);
    yield put(CalculateEmployeeSalarySuccess(response));
  } catch (error) {
    console.error(error);
    yield put(CalculateEmployeeSalaryFailure(error));
  }
}

export default function* rootSaga() {
  yield takeEvery(CALCULATE_EMPLOYEE_SALARY, fetchCalculateEmployeeSalary);
}
