import {
  PAYONEER_GET_EMPLOYEE_BALANCE,
  PAYONEER_SET_EMPLOYEE_BALANCE,
  PAYONEER_GET_EMPLOYEE_BALANCE_FAILED,
  PAYONEER_RESET_EMPLOYEE_BALANCE,
} from "store/constants/Payoneer/PayoneerGetEmployeeBalance";

export const fetchBalance = () => {
  return {
    type: PAYONEER_GET_EMPLOYEE_BALANCE,
  };
};

export const setBalance = (balanceInfo) => {
  return { type: PAYONEER_SET_EMPLOYEE_BALANCE, payload: balanceInfo };
};

export const resetBalance = () => {
  return { type: PAYONEER_RESET_EMPLOYEE_BALANCE };
};

export const fetchBalanceFailed = (error) => {
  return { type: PAYONEER_GET_EMPLOYEE_BALANCE_FAILED, payload: error };
};
