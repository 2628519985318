import React, { useState, useEffect, useMemo } from "react";

function useRequirePermission(Permission, type) {
  let loggedInUser = JSON.parse(localStorage.getItem("loggedInUser"));

  let isAccessible = useMemo(() => {
    if (type) {
      return type.includes(loggedInUser.type);
    } else {
      return loggedInUser?.permissions.some((userPermission) =>
        Permission.includes(userPermission)
      );
    }
  }, []);

  return isAccessible;
}

export default useRequirePermission;
