import React from "react";
//material ui
import {
  Grid,
  Paper,
  Typography,
  Button,
  Box,
  Badge,
  Avatar,
} from "@mui/material";
import { settingPages } from "../../SettingPages.enumn";
//icon
import { AiOutlineEdit } from "react-icons/ai";
import { HiOutlineOfficeBuilding } from "react-icons/hi";
//redux dispatch
import { useSelector } from "react-redux";
import { baseUrl } from "../../../../../util/APIBaseUrl";
import Password from "../Password/Password";
import { getValue } from "../../../CommonUtils";
import PersonalInfoV2 from "./PersonalInfoV2";

// icons
import EditIconLarge from "../../../../../assets/images/EditIconLarge.png";
import ProfilePicture from "../ProfilePicture";

function PersonalInfo(props) {
  let photoInput = React.createRef();
  const { handleSettingPagechange } = props;

  //component states
  const [userProfileUrl, setUserProfileUrl] = React.useState(null);

  //redux state
  const customerUserandCompanyProfileData = useSelector(
    (state) =>
      state.CustomerUserandCompanyProfileData.customerUserandCompanyProfileData
  );

  //   functions
  const uploadProfilePic = () => {
    photoInput.current.click();
  };

  //methods
  React.useEffect(() => {
    let loggedInUser = {};
    let userProfileUrl = "";

    loggedInUser = JSON.parse(localStorage.getItem("loggedInUser"));

    let userAdditionalInfo = JSON.parse(
      sessionStorage.getItem("userAdditionalInfo")
    );

    if (Object.keys(loggedInUser).length && loggedInUser.userId != undefined) {
      if (loggedInUser?.type == "customer") {
        userProfileUrl = `${baseUrl}/customerUser/${loggedInUser?.userId}/logo?id=${userAdditionalInfo?.pictureVersion}`;
      } else if (loggedInUser?.type == "edge") {
        userProfileUrl = `${baseUrl}/user/${loggedInUser?.userId}/logo?id=${userAdditionalInfo?.pictureVersion}`;
      }
    }
    setUserProfileUrl(userProfileUrl);
  }, []);

  return (
    <Grid
      sx={{
        maxWidth: "1076px",
        width: "100%",
        margin: "0px auto",
        marginTop: "24px",
        paddingTop: "0px",
      }}
    >
      <Grid
        sx={{
          display: "flex",
          alignItems: "start",
          gap: "16px",
        }}
      >
        <Grid sx={{ minWidth: "712px" }}>
          <PersonalInfoV2 title={"Personal Information"} />
        </Grid>
        <Grid sx={{ maxWidth: "348px", width: "100%" }}>
          {/* <ProfilePicture /> */}
          <ProfilePicture page="personalProfile" />
        </Grid>
      </Grid>
      <Grid sx={{ maxWidth: "712px", width: "100%", marginTop: "16px" }}>
        <Password handleSettingPagechange={handleSettingPagechange} />
      </Grid>
    </Grid>
  );
}

export default PersonalInfo;
