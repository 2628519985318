//employee constants
import {
  GET_RECENT_PAYMENTS,
  GET_RECENT_PAYMENTS_SUCCESS,
  GET_RECENT_PAYMENTS_FAILURE,
} from "../../constants/DashBoard/RecentPayments";

const INIT_STATE = {
  recentPayments: [],
  loadingGetRecentPayments: false,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_RECENT_PAYMENTS:
      return {
        ...state,
        loadingGetRecentPayments: true,
      };
    case GET_RECENT_PAYMENTS_SUCCESS:
      return {
        ...state,
        loadingGetRecentPayments: false,
        recentPayments: action.payload,
      };
    // eslint-disable-next-line no-fallthrough
    case GET_RECENT_PAYMENTS_FAILURE:
      return {
        ...state,
        loadingGetRecentPayments: false,
        recentPayments: "Error in  getting Club Data",
      };
    default:
      return state;
  }
};
