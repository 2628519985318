import { call, put, takeEvery } from "redux-saga/effects";
//actions
import {
  GetEmployeeSalariesSuccess,
  GetEmployeeSalariesFailure,
} from "../../actions/EmployeeSalaries";
//constant
import { GET_EMPLOYEE_SALARIES } from "../../constants/EmployeeSalaries";
//request type
import getRequest from "../../../util/APIHelperGet";

export const fetchEmployeeSalariesRequest = async (actions) => {
  // eslint-disable-next-line no-return-await

  return await getRequest(
    `${actions.payload.request}?` +
      new URLSearchParams({
        limit: actions.payload.rowsPerPage,
        date: actions?.payload.date,
        page: actions.payload.page,
        orderBy: JSON.stringify({
          field: actions.payload?.sortBy?.field,
          direction: actions.payload?.sortBy?.direction,
        }),
        filter: JSON.stringify({
          employeeName: [
            actions.payload?.searchEmployee
              ? actions.payload?.searchEmployee
              : "",
          ],
          country: actions.payload?.dist?.countries,
          companyName: actions.payload?.dist?.customers,
          First_Name: actions.payload?.dist?.employeeName,
        }),
      })
  )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

function* fetchEmployeeSalaries(params) {
  try {
    let response = yield call(fetchEmployeeSalariesRequest, params);
    yield put(GetEmployeeSalariesSuccess(response));
  } catch (error) {
    console.error(error);
    yield put(GetEmployeeSalariesFailure(error));
  }
}

export default function* rootSaga() {
  yield takeEvery(GET_EMPLOYEE_SALARIES, fetchEmployeeSalaries);
}
