import React, { useEffect, useState } from "react";
import moment from "moment";

import LeaveItem from "components/common/LeaveItem";
import { getProfileImage, isEmptyObject } from "components/Screens/CommonUtils";

import avatarImage from "assets/images/event-image.svg";

function HolidayInfo({ allHolidays }) {
  const [first, second] = allHolidays || [];
  const [firstImage, setFirstImage] = useState(avatarImage);
  const [secondImage, setSecondImage] = useState(avatarImage);

  function formatHoliday(holidayDate) {
    if (holidayDate) {
      return moment(holidayDate).format("MMM DD, YYYY");
    }
    return "";
  }

  function CountHolidays(holidayDate) {
    const startDate = moment(holidayDate?.StartDate);
    const endDate = holidayDate?.EndDate ? moment(holidayDate.EndDate) : null;

    if (!endDate || !endDate.isValid()) {
      return 1;
    }

    return endDate.diff(startDate, "days") + 1;
  }

  useEffect(() => {
    if (first && isEmptyObject(first) && first?.Picture_Path) {
      getProfileImage(first?.Holiday_Id, first?.Picture_Version, "publicHoliday").then((img) => {
        setFirstImage(img);
      });
    } else {
      setFirstImage(avatarImage);
    }

    if (second && isEmptyObject(second) && second?.Picture_Path) {
      getProfileImage(second?.Holiday_Id, second?.Picture_Version, "publicHoliday").then((img) => {
        setSecondImage(img);
      });
    } else {
      setSecondImage(avatarImage);
    }
  }, [first, second]);

  return (
    <div className="mt-15">
      <p className="heading-h4-semibold mb-1">Upcoming Holidays</p>
      <div className="flex items-center justify-between space-x-2 w-full">
        <LeaveItem
          image={firstImage[first?.Holiday_Id] ? firstImage[first?.Holiday_Id] : avatarImage}
          key={first?.Holiday_Id}
          type={first?.Name}
          isHoliday
          noOfLeaves={CountHolidays(first)}
          dateFrom={formatHoliday(first?.StartDate)}
          dateTo={formatHoliday(first?.EndDate)}
        />
        <LeaveItem
          image={secondImage[second?.Holiday_Id] ? secondImage[second?.Holiday_Id] : avatarImage}
          key={second?.Holiday_Id}
          type={second?.Name}
          isHoliday
          noOfLeaves={CountHolidays(second)}
          dateFrom={formatHoliday(second?.StartDate)}
          dateTo={formatHoliday(second?.EndDate)}
        />
      </div>
    </div>
  );
}

export default HolidayInfo;
