//employee constants
import { ADD_EMPLOYEE_DATA } from "../constants/AddEmployeeData";

const INIT_STATE = {
  addEmployeeData: {
    // employeeProfileData: {
    //     name: "",
    //     email: "",
    //     profileJobTitle: {},
    //     birthday: "",
    //     contactNumber: "",
    //     country: {},
    //     residentialAddress: "",
    //     state: "",
    //     city: null,
    //     code: "",
    // },
  },
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case ADD_EMPLOYEE_DATA:
      return {
        ...state,
        addEmployeeData: action.payload,
      };

    default:
      return state;
  }
};
