import React from "react";
//material ui
import {
  Button,
  Grid,
  Paper,
  Typography,
  AvatarGroup,
  Avatar,
  Divider,
} from "@mui/material";
import { baseUrl } from "../../../../util/APIBaseUrl";

function PayrollDetailsWidget(props) {
  const { title, subtitle, amount, avatarGroup, urls } = props;
  // title,subtitle,amount

  return (
    <Grid container>
      <Grid item container direction="row" alignItems="center" columnGap={2}>
        {title && amount != null ? (
          <Grid item>
            <Grid
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "5px",
                padding: "0px 24px",
              }}
            >
              <Typography
                sx={{
                  fontFamily: "Poppins-Medium",
                  fontSize: "18px",
                  fontWeight: 600,
                  lineHeight: "28px",
                  color: "212231",
                }}
              >
                {amount}
              </Typography>
              <Typography
                sx={{
                  fontFamily: "Poppins-Regular",
                  fontSize: "12px",
                  fontWeight: 400,
                  lineHeight: "16px",
                  color: "212231",
                }}
              >
                {title}
              </Typography>
            </Grid>
            <Divider
              orientation="horizontal"
              variant="middle"
              flexItem
              sx={{ color: "#C7C5D0", margin: "4px 0px" }}
            />
            <Grid
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "24px",
                padding: "0px 24px",
              }}
            >
              <Typography
                sx={{
                  fontFamily: "Poppins-Regular",
                  fontSize: "12px",
                  fontWeight: 400,
                  lineHeight: "16px",
                  color: "212231",
                }}
              >
                {subtitle}
              </Typography>
              {avatarGroup ? (
                <Grid item>
                  <AvatarGroup
                    //total={24}
                    sx={{
                      "& .MuiAvatar-root": {
                        width: "30px",
                        height: "30px",
                      },
                    }}
                  >
                    <Avatar
                      alt="Agnes Walker"
                      src={
                        urls?.length
                          ? `${baseUrl}/employee/${urls[0]?.employeeId}/logo`
                          : "/static/images/avatar/4.jpg"
                      }
                    />
                    {urls?.length > 1 ? (
                      <Avatar
                        alt="Trevor Henderson"
                        src={
                          urls?.length >= 1
                            ? `${baseUrl}/employee/${urls[1]?.employeeId}/logo`
                            : "/static/images/avatar/4.jpg"
                        }
                      />
                    ) : null}
                  </AvatarGroup>
                </Grid>
              ) : null}
            </Grid>
          </Grid>
        ) : null}
      </Grid>
    </Grid>
  );
}

export default PayrollDetailsWidget;
