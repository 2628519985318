import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { DateRangePicker } from "react-date-range";
import { addDays, format } from "date-fns";

import moment from "moment";

import { usePermissions } from "components/Hook";

import { GetAllHolidays } from "store/actions/Holidays";

import { baseUrl } from "util/APIBaseUrl";

import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import "./Daterange.css";

function Daterange({
  months = 2,
  onChange,
  leavesCalender = [],
  setSelectedDateRange = () => {},
  holidaysCalender,
  disabledFutureDates,
  validDates,
}) {
  const { loggedInUser, loggedInUserType } = usePermissions();
  const allHolidays = useSelector((state) => state?.Holidays?.allHolidays);

  const dispatch = useDispatch();
  const [state, setState] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
    },
  ]);

  const isHolidayIndicator = (day) => {
    if (loggedInUserType === "edge") {
      return (
        holidaysCalender?.find(
          (leave) =>
            moment(leave?.Date).format("MMM DD, YYYY") == moment(day).format("MMM DD, YYYY") ||
            moment(leave?.EndDate).format("MMM DD, YYYY") == moment(day).format("MMM DD, YYYY")
        ) || false
      );
    }

    return (
      allHolidays?.data?.find(
        (leave) =>
          moment(day).isSame(moment(leave?.StartDate.split("T")[0]), "day") ||
          (leave.EndDate &&
            moment(day).isBetween(
              moment(leave?.StartDate.split("T")[0]),
              moment(leave?.EndDate.split("T")[0]),
              null,
              "[]"
            ))
      ) || false
    );
  };

  function customDayContent(day) {
    let leavesIndicator = null;
    let holidaysIndicator = null;

    if (
      leavesCalender.find(
        (leave) => moment(leave?.leaveDate).format("MMM DD, YYYY") == moment(day).format("MMM DD, YYYY")
      )
    ) {
      leavesIndicator = (
        <div
          id="leave-daterange"
          style={{
            height: "5px",
            width: "5px",
            borderRadius: "100%",
            background: "#FFC200",
          }}
        />
      );
    }
    if (isHolidayIndicator(day)) {
      holidaysIndicator = (
        <div
          id="holiday-daterange"
          style={{
            height: "5px",
            width: "5px",
            borderRadius: "100%",
            background: "#1EE3CF",
          }}
        />
      );
    }

    return (
      <div style={{ flexDirection: "column", gap: "13px", marginTop: "-9px" }}>
        <div style={{ gap: "2px", marginTop: "24px" }}>
          {leavesIndicator}
          {holidaysIndicator}
        </div>
        <span style={{ marginTop: "-22px", fontFamily: "POPPINS-REGULAR" }} id="date">
          {format(day, "d")}
        </span>
      </div>
    );
  }

  useEffect(() => {
    if (loggedInUserType != "edge" && loggedInUserType !== "employee")
      dispatch(
        GetAllHolidays({
          request: `${baseUrl}/customer/${loggedInUser?.customerId}/publicHolidays`,
          // TOOD: Add a region here
          region: "",
          startDate: addDays(new Date(), -365),
          endDate: addDays(new Date(), 365),
        })
      );
  }, []);

  return (
    <div className="date-range-container">
      <DateRangePicker
        onChange={(item) => {
          if (onChange) onChange(item.selection);
          setState([item.selection]);
          setSelectedDateRange({
            startDate: moment(item.selection?.startDate).format("MMM DD, YYYY"),
            endDate: moment(item.selection?.endDate).format("MMM DD, YYYY"),
          });
        }}
        showSelectionPreview={months !== 1}
        editableDateInputs={months !== 1}
        months={months}
        ranges={state}
        dayContentRenderer={customDayContent}
        direction="horizontal"
        color="#131599"
        weekStartsOn={1}
        ariaLabels={{
          dateInput: {
            startDate: "rdr-start-date",
            endDate: "rdr-end-date",
          },
          monthPicker: "rdr-month-date",
          yearPicker: "rdr-year-date",
          prevButton: "rdr-prev-date",
          nextButton: "rdr-next-date",
        }}
        minDate={validDates && new Date(validDates?.startDate)}
        maxDate={
          (validDates && new Date(validDates.endDate)) || (disabledFutureDates && moment().toDate()) || undefined
        }
      />
    </div>
  );
}

export default Daterange;
