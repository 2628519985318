//employee constants
import {
    RESET_GET_DOCUMENTS_LIST,
    GET_DOCUMENTS_LIST,
    GET_DOCUMENTS_LIST_SUCCESS,
    GET_DOCUMENTS_LIST_FAILURE,
} from "../../constants/Doucments/GetDocumentsList";

const INIT_STATE = {
    documentsList: {},
    loadingDocumentsList: true,
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case RESET_GET_DOCUMENTS_LIST:
            return INIT_STATE;
        case GET_DOCUMENTS_LIST:
            return {
                ...state,
                loadingDocumentsList: true,
            };
        case GET_DOCUMENTS_LIST_SUCCESS:
            if (action.payload.ok) {
                return {
                    ...state,
                    loadingDocumentsList: false,
                    documentsList: action?.payload?.payload,
                };
            }
        // eslint-disable-next-line no-fallthrough
        case GET_DOCUMENTS_LIST_FAILURE:
            return {
                ...state,
                loadingDocumentsList: false,
                documentsList: "Error in  getting Club Data",
            };
        default:
            return state;
    }
};
