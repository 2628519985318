import { call, put, takeEvery } from "redux-saga/effects";
//actions
import {
    GetPlatformConfigSuccess,
    GetPlatformConfigFailure,
} from "../../actions/PlatformConfig";
//constant
import { GET_PLATFORM_CONFIG } from "../../constants/PlatformConfig";
//request type
import getRequest from "../../../util/APIHelperGet";
import { baseUrl } from "../../../util/APIBaseUrl";

export const fetchPlatformConfigRequest = async (actions) => {
    // eslint-disable-next-line no-return-await
    return await getRequest(`${baseUrl}/platformConfig`)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return error;
        });
};

function* fetchPlatformConfig(params) {
    try {
        let response = yield call(fetchPlatformConfigRequest, params);
        yield put(GetPlatformConfigSuccess(response));
    } catch (error) {
        console.error(error);
        yield put(GetPlatformConfigFailure(error));
    }
}

export default function* rootSaga() {
    yield takeEvery(GET_PLATFORM_CONFIG, fetchPlatformConfig);
}
