import {
  RESER_ADD_CUSTOMER,
  ADD_CUSTOMER,
  ADD_CUSTOMER_SUCCESS,
  ADD_CUSTOMER_FAILURE,
  ADD_GOOGLE_CUSTOMER,
  ADD_GOOGLE_CUSTOMER_SUCCESS,
  ADD_GOOGLE_CUSTOMER_FAILURE,
} from "../../../constants/Customers/AddCustomer/AddCustomer";

// for user authentication

export const ResetAddCustomer = (data) => {
  return {
    type: RESER_ADD_CUSTOMER,
    payload: data,
  };
};

export const AddCustomer = (data) => {
  return {
    type: ADD_CUSTOMER,
    payload: data,
  };
};
export const AddCustomerSuccess = (payload) => {
  return {
    type: ADD_CUSTOMER_SUCCESS,
    payload,
  };
};
export const AddCustomerFailure = (payload) => {
  return {
    type: ADD_CUSTOMER_FAILURE,
    payload,
  };
};

export const AddGoogleCustomer = (data) => {
  return {
    type: ADD_GOOGLE_CUSTOMER,
    payload: data,
  };
};
export const AddGoogleCustomerSuccess = (payload) => {
  return {
    type: ADD_GOOGLE_CUSTOMER_SUCCESS,
    payload,
  };
};
export const AddGoogleCustomerFailure = (payload) => {
  return {
    type: ADD_GOOGLE_CUSTOMER_FAILURE,
    payload,
  };
};
