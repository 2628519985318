import React from "react";

function YellowDotIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="11" height="10" viewBox="0 0 11 10" fill="none">
      <circle cx="5.05176" cy="4.99268" r="5" fill="#FFC200" />
    </svg>
  );
}

export default YellowDotIcon;
