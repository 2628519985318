//employee constants
import {
  RESET_ADD_USER_PERMISSIONS,
  ADD_USER_PERMISSIONS,
  ADD_USER_PERMISSIONS_SUCCESS,
  ADD_USER_PERMISSIONS_FAILURE,
} from "../../constants/Settings/AddUserPermissions";

const INIT_STATE = {
  addUserPermissions: {},
  loadingAddUserPermissions: false,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case RESET_ADD_USER_PERMISSIONS:
      return INIT_STATE;

    case ADD_USER_PERMISSIONS:
      return {
        ...state,
        loadingAddUserPermissions: true,
      };
    case ADD_USER_PERMISSIONS_SUCCESS:
      return {
        ...state,
        loadingAddUserPermissions: false,
        addUserPermissions: action.payload,
      };
    // eslint-disable-next-line no-fallthrough
    case ADD_USER_PERMISSIONS_FAILURE:
      return {
        ...state,
        loadingAddUserPermissions: false,
        addUserPermissions: "Error in  getting Club Data",
      };
    default:
      return state;
  }
};
