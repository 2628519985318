//employee constants
import {
  RESET_RETRY_PAYMENT,
  RETRY_PAYMENT,
  RETRY_PAYMENT_SUCCESS,
  RETRY_PAYMENT_FAILURE,
} from "../../constants/Payment/RetryPayment";

const INIT_STATE = {
  retryPayment: {},
  loadingRetryPayment: true,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case RESET_RETRY_PAYMENT:
      return INIT_STATE;
    case RETRY_PAYMENT:
      return {
        ...state,
        loadingRetryPayment: true,
      };
    case RETRY_PAYMENT_SUCCESS:
      if (action.payload.ok) {
        // localStorage.setItem(
        //     "bankAccountDetails",
        //     action?.payload?.payload
        // );
        return {
          ...state,
          loadingRetryPayment: false,
          retryPayment: action?.payload?.payload,
        };
      }
    // eslint-disable-next-line no-fallthrough
    case RETRY_PAYMENT_FAILURE:
      // localStorage.removeItem("bankAccountDetails");
      return {
        ...state,
        loadingRetryPayment: false,
        retryPayment: "Error in  getting Club Data",
      };
    default:
      return state;
  }
};
