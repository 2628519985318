import { all } from "redux-saga/effects";
import loggedInUserInfo from "./LoggedInUserInfo";
import AuthUser from "./AuthUser";
import RegisterUser from "./RegisterUser";
import GetUsers from "./GetUsers";
import Logout from "./Logout";
import EmployeeInfo from "./EmployeeInfo";
import CustomerInfo from "./CustomerInfo";
import PayrollSummary from "./PayrollSummary";
import EmployeesCountInfo from "./EmployeesCountInfo";
import UserDetails from "./UserDetails";
import UserAdditionalInfo from "./UserAdditionalInfo";
import EmployeeExperience from "./EmployeeExperience";
import EmployeePeers from "./EmployeePeers";
import GetEmployeesList from "./GetEmployeesList";
import AddEmployee from "./AddEmployee";
import GetEmployeeDetails from "./GetEmployeeDetails";
import RecentPayments from "./DashBoard/RecentPayments";
import GetNoticeBoard from "./DashBoard/GetNoticeBoard";
import AddEmployeeEducation from "./Workforce/AddEmployee/AddEmployeeEducation";
import AddEmployeeExperience from "./Workforce/AddEmployee/AddEmployeeExperience";
import AddEmployeeSalary from "./Workforce/AddEmployee/AddEmployeeSalary";
import GetCustomerDetails from "./Customers/GetCustomerDetails";
import AddCustomer from "./Customers/AddCustomer";
import GetCustomerPaymentSummary from "./Customers/GetCustomerPaymentSummary";
import DropdownData from "./DropdownData";
import AssignEmployee from "./Workforce/AssignEmployee";
import UnAssignEmployee from "./Workforce/UnAssignEmployee";
import EmployeeSalaryDetails from "./Workforce/EmployeeDetils/SalaryDetails";
import CurrentPayroll from "./Payroll/CurrentPayroll";
import PaymentHistory from "./Payroll/PaymentHistory";
import CustomerUserandCompanyProfileData from "./Settings/CustomerUser&CompanyProfileData";
import AssignCustomertoEmployee from "./Customers/AssignCustomertoEmployee";
import SalaryInfo from "./Workforce/EmployeeDetils/SalaryInfo";
import WorkforceDocs from "./Workforce/EmployeeDetils/Documents";
import EducationInfo from "./Workforce/EmployeeDetils/EducationInfo";
import ExperienceInfo from "./Workforce/EmployeeDetils/ExperienceInfo";
import EditUserProfile from "./Settings/EditUserProfile";
import EditCompanyInfo from "./Settings/EditCompanyInfo";
import ChangeUserPassword from "./Settings/ChangeUserPassword";
import ManageUser from "./Settings/ManageUser";
import AddUserPermissions from "./Settings/AddUserPermissions";
import RemoveUserPermissions from "./Settings/RemoveUserPermissions";
import AddUser from "./Settings/AddUser";
import CalculateEmployeeSalary from "./Workforce/AddEmployee/CalculateEmployeeSalary";
import Logo from "./Logo";
import SearchEmployeeAndCustomer from "./SearchEmployee&Customer";
import DeleteUser from "./Settings/DeleteUser";
import GoogleSignup from "./Google/Google_signup";
import UploadLogo from "./Logo/uploadLogo";
import GetPaymentDetails from "./Payroll/GetPaymentDetails";
import GetCustomerBankInfo from "./Payment/GetCustomerBankInfo";
import AddBankAccountInfo from "./Payment/AddBankAccountInfo";
import AddPermissions from "./Permissions/AddPermissions";
import RemovePermissions from "./Permissions/RemovePermissions";
import DisableCustomer from "./Customers/DisableCustomer";
import SignUp from "./SignUp/SignUp";
import GetUserDetails from "./Settings/GetUserDetails";
import EditUserDetails from "./Settings/EditUserDetails";
import EditEmployeeProfile from "./EmployeePortal/EditEmployeeProfile";
import EmployeePaySlipDetails from "./EmployeePortal/EmployeePaySlipDetails";
import EmployeeDocuments from "./EmployeePortal/EmployeeDocuments";
import GetCustomerPaymentById from "./Customers/GetCustomerPaymentById";
import ForgotPassword from "./ForgotPassword";
import GiveRaise from "./Raises/GiveRaise";
import RetryPayment from "./Payment/RetryPayment";
import GetCustomerList from "./Customers/GetCustomerList";
import PlatformConfig from "./PlatformConfig";
import EmployeeSalaries from "./EmployeeSalaries";
import EmployeePayrollById from "./EmployeeSalaries/EmployeePayrollById";
import GetDocumentsList from "./Doucments/GetDocumentsList";
import Holidays from "./Holidays";
import AddAchInfo from "./Payment/AddAchInfo";
import ImportEmployees from "./Workforce/ImportEmployees";
import ActivationEmail from "./Customers/ActivationEmail";
import DeleteBankAcc from "./Payment/DeleteBankAcc";
import GetEmployeeDetials from "./EmployeePortal/GetEmployeeDetials";
import TimeoffLeaveTypes from "./Timeoff/LeaveTypes";
import TimeoffLeaveRequests from "./Timeoff/LeaveRequests";
import TimeoffUpcomingLeaves from "./Timeoff/UpcomingLeaves";
import PayoneerBalance from "./Payoneer/EmployeePayoneerBalance";
import PayoneerStatus from "./Payoneer/EmployeePayoneerStatus";
import PayoneerConsent from "./Payoneer/EmployeePayoneerConsent";
import PayoneerRegistrationLink from "./Payoneer/EmployeePayoneerRegistrationLink";
import PayoneerRevokeAccessToken from "./Payoneer/EmployeePayoneerRevokeAccessToken";
import PayoneerSaveAccessToken from "./Payoneer/EmployeePayoneerSaveAccessToken";
import AdminSummary from "./AdminSummary";

export default function* rootSaga(getState) {
  yield all([
    EmployeePayrollById(),
    GetEmployeeDetials(),
    DeleteBankAcc(),
    AddAchInfo(),
    Holidays(),
    ActivationEmail(),
    ImportEmployees(),
    EmployeeSalaries(),
    GetDocumentsList(),
    PlatformConfig(),
    GetCustomerList(),
    RetryPayment(),
    EditUserDetails(),
    EditEmployeeProfile(),
    EmployeePaySlipDetails(),
    EmployeeDocuments(),
    ForgotPassword(),
    GetCustomerPaymentById(),
    loggedInUserInfo(),
    AuthUser(),
    RegisterUser(),
    GetUsers(),
    Logout(),
    EmployeeInfo(),
    CustomerInfo(),
    PayrollSummary(),
    EmployeesCountInfo(),
    UserDetails(),
    UserAdditionalInfo(),
    EmployeeExperience(),
    EmployeePeers(),
    GetEmployeesList(),
    AddEmployee(),
    GetEmployeeDetails(),
    RecentPayments(),
    GetNoticeBoard(),
    AddEmployeeEducation(),
    AddEmployeeExperience(),
    AddEmployeeSalary(),
    GetCustomerDetails(),
    GetCustomerPaymentSummary(),
    AddCustomer(),
    DropdownData(),
    AssignEmployee(),
    UnAssignEmployee(),
    EmployeeSalaryDetails(),
    CurrentPayroll(),
    PaymentHistory(),
    CustomerUserandCompanyProfileData(),
    AssignCustomertoEmployee(),
    SalaryInfo(),
    WorkforceDocs(),
    ExperienceInfo(),
    EducationInfo(),
    EditUserProfile(),
    EditCompanyInfo(),
    ChangeUserPassword(),
    ManageUser(),
    RemoveUserPermissions(),
    AddUserPermissions(),
    CalculateEmployeeSalary(),
    Logo(),
    AddUser(),
    SearchEmployeeAndCustomer(),
    DeleteUser(),
    GoogleSignup(),
    UploadLogo(),
    GetPaymentDetails(),
    GetCustomerBankInfo(),
    AddBankAccountInfo(),
    AddPermissions(),
    RemovePermissions(),
    DisableCustomer(),
    SignUp(),
    GetUserDetails(),
    GiveRaise(),
    TimeoffLeaveTypes(),
    TimeoffLeaveRequests(),
    TimeoffUpcomingLeaves(),
    PayoneerBalance(),
    PayoneerStatus(),
    PayoneerConsent(),
    PayoneerRegistrationLink(),
    PayoneerRevokeAccessToken(),
    PayoneerSaveAccessToken(),
    AdminSummary(),
  ]);
}
