import {
    PAYONEER_GET_EMPLOYEE_CONSENT_FAILED,
    PAYONEER_SET_EMPLOYEE_CONSENT,
  } from "store/constants/Payoneer/PayoneerGetEmployeeConsent";
  
  const initialState = {
    consentInfo: {
      consentLink: null,
      error: null,
      isLoading: true,
    },
  };
  
  const consentReducer = (state = initialState, action) => {
    switch (action.type) {
      case PAYONEER_SET_EMPLOYEE_CONSENT:
        return {
          ...state,
          consentInfo: {
            consentLink: action.payload,
            error: null,
            isLoading: false,
          },
        };
      case PAYONEER_GET_EMPLOYEE_CONSENT_FAILED:
        return {
          ...state,
          consentInfo: {
            consentLink: null,
            error: action.payload,
            isLoading: false,
          },
        };
      default:
        return state;
    }
  };
  
  export default consentReducer;
  