/* eslint-disable */

import React from "react";
import { useCallback } from "react";
import { useMemo } from "react";
import { isEmptyObject, isValidResponse } from "./Screens/CommonUtils";

export const usePermissions = (props) => {
  const loggedInUserType = useMemo(() => {
    let loggedInUser = JSON.parse(localStorage.getItem("loggedInUser"));

    if (isEmptyObject(loggedInUser)) {
      return loggedInUser?.type;
    }
  }, []);

  const loggedInUser = useMemo(() => {
    return JSON.parse(localStorage.getItem("loggedInUser"));
  }, []);

  const userAdditionalInfo = useMemo(() => {
    return JSON.parse(localStorage.getItem("loggedInUser"));
  }, []);

  const dropdownData = useMemo(() => {
    return JSON.parse(sessionStorage.getItem("dropdownData"));
  }, []);

  const accessToken = useMemo(() => {
    return localStorage.getItem("accessToken");
  }, []);

  const canRead = useCallback((module) => {
    switch (module) {
      case "searchBar":
        if (loggedInUserType === "customer") {
          return true;
        } else if (loggedInUserType === "edge") {
          return true;
        } else {
          return false;
        }
    }
  }, []);

  return {
    canRead,
    loggedInUserType,
    loggedInUser: isEmptyObject(loggedInUser) ? loggedInUser : {},
    userAdditionalInfo: isEmptyObject(userAdditionalInfo) ? userAdditionalInfo : {},
    dropdownData: isValidResponse(dropdownData) ? dropdownData : {},
    accessToken: accessToken,
  };
};

// export default Hook;
