import React, { useState, useEffect, useMemo, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  Typography,
  Snackbar,
  Alert,
  Paper,
  List,
  ListItem,
  FormControl,
  Autocomplete,
  Grid,
  Box,
} from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import WarningIcon from "@mui/icons-material/Warning";

import TextFieldV2 from "components/common/TextFieldV2/TextFieldWithIcon";
import { isValidResponse } from "components/Screens/CommonUtils";
import { usePermissions } from "components/Hook";

import { AddUser, ResetAddUser } from "store/actions/Settings/AddUser";
import { AddPermissions, ResetAddPermissions } from "store/actions/Permissions/AddPermissions";
import { ResetGetUserDetails } from "store/actions/Settings/GetUserDetails";

import "./add-or-edit-user.scss";

function CustomPopperComponent({ children, className }) {
  return (
    <Box
      sx={{
        background: "#FFFFFF",
        boxShadow: "0px 1px 1px rgba(2, 0, 110, 0.22), 0px 1px 6px rgba(2, 0, 110, 0.25)",
        borderRadius: "12px ",
      }}
    >
      <Paper
        square
        className={className}
        style={{
          marginTop: 4,
          zIndex: 999,
        }}
      >
        {children}
      </Paper>
    </Box>
  );
}

function AddOrEditUsers(props) {
  const { user, mode, customerId, openDialog, handleClose, fetchUsers } = props;
  const dispatch = useDispatch();
  const { dropdownData } = usePermissions();

  const [openAlert, setOpenAlert] = useState(false);
  const [userData, setUserData] = useState({ permissions: null, country: null });
  const [dropdownData_, setDropdownData_] = useState({});
  const [permDropdownOption, setPermDropdownOption] = useState([]);

  const addUser = useSelector((state) => state?.AddUser?.addUser);
  const addPermissions = useSelector((state) => state?.AddPermissions?.addPermissions);

  const actionMode = useMemo(
    () => ({
      edit: {
        customer: `customerUser/${user?.userId}`,
        permissionRequest: `customerUser/${user?.userId}/addUserPermission`,
        method: "PATCH",
      },

      add: {
        customer: "customerUsers/",
        permissionRequest: `customerUser/${addUser?.payload?.userId}/addUserPermission`,
        method: "POST",
      },

      delete: {
        customer: `customerUser/${user?.userId}`,
        method: "DELETE",
      },
    }),
    [user?.userId, addUser]
  );

  const handleUserData = (event, stateName, fieldType) => {
    const { target } = event;
    const name = target.name || stateName;

    if (fieldType === "number") {
      const onlyNums = event.target.value.replace(/[^0-9+]/g, "");

      setUserData({
        ...userData,
        [name]: onlyNums,
      });
    } else {
      setUserData({
        ...userData,
        [name]: target.value,
      });
    }
  };

  const checkError = (field, view) => {
    if (view !== "textField") {
      return !userData[field]?.value;
    }
    return userData[field] === "";
  };

  const callbackFromMultiSelect = (option, key) => {
    userData[key] = option || { key: null, value: null };
    setUserData({
      ...userData,
    });
  };

  const listDescriptionStyle = {
    mb: 1,
    fontFamily: "Poppins-Regular",
    fontWeight: 350,
    fontSize: "12px",
    lineHeight: "16px",
    letterSpacing: "0.4px",
    color: "#7C7EB6",
  };

  const listTitleStyle = {
    mb: 0.5,
    fontFamily: "Poppins-Regular",
    fontWeight: 450,
    fontSize: "14px",
    lineHeight: "20px",
    letterSpacing: "0.1px",
    color: "#292A3D",
  };

  const getError = (errors) => {
    if (errors != null && errors !== undefined) {
      return errors.map((error) => {
        return (
          <List
            sx={{
              listStyleType: "disc",
              paddingRight: "0px !important",
              pl: 3,
              "& .MuiListItem-root": {
                paddingRight: "0px",
                paddingLeft: "0px",
                paddingTop: "0px",
                paddingBottom: "0px",
              },
            }}
          >
            <ListItem
              sx={{
                display: "list-item",
              }}
            >
              <Typography variant="h6" color="secondary" fontSize="12px">
                {`${error?.msg || error?.description || ""} ${error?.param || ""}`}
              </Typography>
            </ListItem>
          </List>
        );
      });
    }
    return null;
  };

  const handleAddOrEdit = () => {
    const { email, firstName, country, phoneNumber, permissions } = userData;

    if (email && firstName && country && phoneNumber && permissions) {
      dispatch(
        AddUser({
          userData,
          method: actionMode[mode]?.method,
          customerId,
          request: actionMode[mode]?.customer,
        })
      );
    } else if (mode === "delete") {
      dispatch(
        AddUser({
          userData: {},
          customerId,
          request: actionMode[mode]?.customer,
          method: actionMode[mode]?.method,
        })
      );
    }
    return null;
  };

  const getUserPermission = () => {
    return user?.permissions?.includes("CustomerAdmin") ? "CustomerAdmin" : "CustomerEmployee";
  };

  const addPermissionsCallback = useCallback(() => {
    if (isValidResponse(addUser)) {
      if (mode === "delete") {
        fetchUsers();
      } else {
        dispatch(
          AddPermissions({
            request: actionMode[mode]?.permissionRequest,
            permissionName: [userData?.permissions?.value],
          })
        );
        setTimeout(() => {
          fetchUsers();
        }, 0);
      }
      handleClose();
    } else if (Object.keys(addUser).length && !addUser?.ok) {
      setOpenAlert(true);
    } else {
      setOpenAlert(false);
    }
  }, [addUser]);

  useEffect(() => {
    addPermissionsCallback();
  }, [addUser]);

  useEffect(() => {
    if (isValidResponse(addPermissions)) {
      dispatch(ResetGetUserDetails({}));
      dispatch(ResetAddUser({}));
      dispatch(ResetAddPermissions({}));
    }
    // else if (Object.keys(addPermissions).length && addPermissions?.ok == false) {
    //   setOpenAlert(true);
    // } else {
    //   setOpenAlert(false);
    // }
  }, [addPermissions]);

  useEffect(() => {
    let countrySelected = [];
    let selectedPermission = [];

    if (mode === "edit" && user) {
      countrySelected = dropdownData?.payload?.country.filter((country) => country?.countryCode === user?.country);
      selectedPermission = permDropdownOption.find((permission) => permission?.value === getUserPermission());

      setUserData({
        ...user,
        permissions: selectedPermission,
        country: {
          key: countrySelected?.length && countrySelected[0]?.countryCode,
          value: countrySelected?.length && countrySelected[0]?.countryCode,
        },
      });
    }
  }, [user, permDropdownOption]);

  useEffect(() => {
    let permissionsOption = [];
    dispatch(ResetAddUser({}));

    permissionsOption = [
      {
        title: "Customer Admin",
        description: "User can add new Users, Edit Company & Bank Details",
        value: "CustomerAdmin",
      },
      {
        title: "Customer Employee",
        description: "Limited Access User",
        value: "CustomerEmployee",
      },
    ];

    setPermDropdownOption(permissionsOption);

    if (!Object.keys(dropdownData_).length) {
      const countries = [];
      const customerPermissions = [];
      let Permissions = [];

      if (isValidResponse(dropdownData)) {
        dropdownData?.payload?.country?.map((data) => {
          return countries.push({
            key: data.countryCode2,
            value: data.countryCode,
          });
        });

        dropdownData?.payload?.customerPermission?.map((permission) => {
          return customerPermissions.push({
            key: permission.description,
            value: permission.permissionName,
            valueDescription: permission.permissionName === "CustomerAdmin" ? "Admin" : "Employee",
          });
        });

        Permissions = customerPermissions;

        setDropdownData_({
          ...dropdownData_,
          countries,
          Permissions,
        });
      }
    }
  }, []);

  return (
    <Dialog
      open={openDialog}
      onClose={handleClose}
      sx={{
        "& .MuiPaper-root": {
          width: mode === "delete" ? "400px" : "660px",
          padding: "4px",
        },
      }}
    >
      {mode === "delete" ? (
        <div className="flex flex-col items-center justify-center mt-1 gap-025 mb-15">
          <WarningIcon sx={{ color: "#BA1A1A", width: "33px", height: "30px", marginTop: "8px" }} />
          <Typography
            fontWeight={600}
            color="#292A3D"
            sx={{
              fontFamily: "Poppins !important",
              fontSize: "20px",
            }}
          >
            Delete User
          </Typography>
          <Typography
            fontWeight={400}
            color="#212121"
            sx={{
              fontFamily: "Poppins !important",
              fontSize: "14px",
            }}
          >
            Are you sure you want to delete the following user?
          </Typography>

          <div
            style={{
              background: "#F7F7F7",
              marginTop: "12px",
              width: "362px",
              height: "74px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Typography fontWeight={600} color="#292A3D" sx={{ fontFamily: "Poppins !important", fontSize: "14px" }}>
              {`${user?.firstName || ""} ${user?.lastName || ""}`}
            </Typography>
            <Typography fontWeight={400} color="#292A3D" sx={{ fontFamily: "Poppins !important", fontSize: "14px" }}>
              {user?.email}
            </Typography>
          </div>
        </div>
      ) : (
        <div>
          <div className="flex items-center justify-between py-1 px-075">
            <Typography
              variant="h6"
              fontWeight={500}
              color="#292A3D"
              sx={{
                fontFamily: "Poppins !important",
                color: "080D46",
                fontWeight: 600,
                fontSize: "20px",
              }}
            >
              {mode === "edit" ? "Edit User" : "Add User"}
            </Typography>
            <div className="cursor-pointer">
              <CloseOutlinedIcon onClick={handleClose} />
            </div>
          </div>
          <Grid item container gap={1} className="user-form-wrapper">
            <FormControl variant="outlined" fullWidth error>
              <div className="flex items-center w-full space-x-2">
                <div className="flex flex-col items-start w-full">
                  <p className="label-sm neutral-80 uppercase pt-05 pb-025">First Name</p>
                  <TextFieldV2
                    error={checkError("firstName", "textField")}
                    value={userData?.firstName}
                    onChange={(e) => handleUserData(e, "firstName", "text")}
                    variant="filled"
                    height={42}
                    type="text"
                    name="firstName"
                    aria-label="first name"
                  />
                </div>
                <div className="flex flex-col items-start w-full">
                  <p className="label-sm neutral-80 uppercase pt-05 pb-025">Last Name</p>
                  <TextFieldV2
                    error={checkError("lastName", "textField")}
                    value={userData?.lastName}
                    onChange={(e) => handleUserData(e, "lastName", "text")}
                    variant="filled"
                    height={42}
                    type="text"
                    name="lastName"
                    size="small"
                  />
                </div>
              </div>
            </FormControl>

            <FormControl variant="outlined" fullWidth error>
              <div className="flex items-center w-full space-x-2">
                <div className="flex flex-col items-start w-full">
                  <p className="label-sm neutral-80 uppercase pt-05 pb-025">Email Address</p>
                  <TextFieldV2
                    error={checkError("email", "textField")}
                    value={userData?.email}
                    onChange={(e) => handleUserData(e, "email", "text")}
                    height={42}
                    type="text"
                    variant="filled"
                    name="email"
                  />
                </div>
                <div className="flex flex-col items-start w-full">
                  <p className="label-sm neutral-80 uppercase pt-05 pb-025">Phone Number</p>
                  <TextFieldV2
                    value={userData?.phoneNumber}
                    onChange={(e) => handleUserData(e, "phoneNumber", "number")}
                    height={42}
                    type="number"
                    variant="filled"
                    name="phoneNumber"
                  />
                </div>
              </div>
            </FormControl>

            <Grid item xs={12}>
              <FormControl variant="outlined" fullWidth error>
                <div className="flex items-center w-full space-x-1">
                  <Grid item xs={6}>
                    <p className="pr-05 label-sm neutral-80 uppercase pt-05 pb-025">Country</p>
                    <Autocomplete
                      freeSolo
                      className="pr-05"
                      id="user-country"
                      value={userData?.country}
                      PaperComponent={CustomPopperComponent}
                      getOptionLabel={(option) => option.value || ""}
                      options={dropdownData_?.countries?.map((option) => option)}
                      onChange={(event, option) => {
                        callbackFromMultiSelect(option, "country");
                      }}
                      renderOption={(listProps, option) => {
                        return (
                          <li {...listProps}>
                            <div className="flex flex-col">
                              <Typography sx={listTitleStyle}>{option.value}</Typography>
                              <Typography sx={listDescriptionStyle}>{option.value}</Typography>
                            </div>
                          </li>
                        );
                      }}
                      renderInput={(params) => (
                        <TextFieldV2 height={42} hiddenLabel {...params} variant="filled" type="text" name="country" />
                      )}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <p className="pl-05 label-sm neutral-80 uppercase pt-05 pb-025">Permission</p>
                    <Autocomplete
                      className="pl-05"
                      height={42}
                      freeSolo
                      id="user-permissions"
                      value={userData?.permissions}
                      PaperComponent={CustomPopperComponent}
                      getOptionLabel={(option) => option.title || ""}
                      options={permDropdownOption.map((option) => option)}
                      onChange={(event, option) => {
                        callbackFromMultiSelect(option, "permissions");
                      }}
                      renderOption={(titleProps, option) => {
                        return (
                          <li {...titleProps}>
                            <div className="flex flex-col">
                              <Typography sx={listTitleStyle}>{option.title}</Typography>
                              <Typography sx={listDescriptionStyle}>{option.description}</Typography>
                            </div>
                          </li>
                        );
                      }}
                      renderInput={(params) => (
                        <TextFieldV2
                          height={42}
                          hiddenLabel
                          {...params}
                          variant="filled"
                          type="text"
                          name="permissions"
                        />
                      )}
                    />
                  </Grid>
                </div>
              </FormControl>
            </Grid>
          </Grid>
        </div>
      )}
      <DialogActions>
        {mode === "delete" ? (
          <div className="flex items-center justify-center w-full gap-1 mb-075 px-05">
            <Button
              sx={{
                height: "48px",
                border: "1px solid #CCCCCC",
                color: "#212231",
                width: "50%",
                "&:hover": { backgroundColor: "#EEEEEE" },
              }}
              onClick={handleClose}
            >
              Cancel
            </Button>
            <Button
              sx={{
                height: "48px",
                background: "#BA1A1A",
                color: "#FFFFFF",
                width: "50%",
                "&:hover": { backgroundColor: "#BA4A4A" },
              }}
              onClick={handleAddOrEdit}
            >
              Yes, Delete User
            </Button>
          </div>
        ) : (
          <Button
            variant="contained"
            fullWidth
            sx={{ height: "48px", marginTop: "5px", marginBottom: "10px" }}
            onClick={handleAddOrEdit}
          >
            <Typography variant="button" color="white">
              {mode === "edit" ? "Update Information" : "Add User"}
            </Typography>
          </Button>
        )}
      </DialogActions>

      <Snackbar
        open={openAlert}
        autoHideDuration={2500}
        onClose={() => setOpenAlert(false)}
        sx={{
          "&.MuiSnackbar-root": {
            position: "relative",
            bottom: 20,
            left: 0,
            marginTop: 5,
            padding: 1,
          },
        }}
      >
        <Alert onClose={() => setOpenAlert(false)} severity="error">
          {!addUser || !addUser?.ok ? getError(addUser?.payload?.error) : null}
        </Alert>
      </Snackbar>
    </Dialog>
  );
}

export default AddOrEditUsers;
