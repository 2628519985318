import { Button } from "@mui/material";
import Modal from "components/common/LeaveReplacement/Modal";
import { useNavigate } from "react-router-dom";

const LeaveReplacementSuccessModal = ({ open, closeModal }) => {
  const navigate = useNavigate()
  return (
    <div>
      <Modal open={open} width={364}>
        <div className="w-full">
          <div className="flex flex-col items-center justify-center">
            <p className="modal-title pt-2 pb-1">Request Submitted!</p>
            <p className="label-lg pb-2">Our team will respond to your request within 1-2 business days.</p>
            <Button
              size={"md"}
              variant="contained"
              className={"button-md"}
              onClick={() => { closeModal(); navigate("/main/timeoff/leave#Scheduled") }}
              sx={{
                color: "white",
                backgroundColor: "#131599",
                padding: "9px, 24px, 9px, 24px",
                borderRadius: "99px",
                boxShadow: "0px 1px 14px rgba(2, 0, 110, 0.12)"
              }}
            >
              View leave request
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default LeaveReplacementSuccessModal;
