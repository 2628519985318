//employee constants
import { RESET_ADD_USER, ADD_USER, ADD_USER_SUCCESS, ADD_USER_FAILURE } from "../../constants/Settings/AddUser";

const INIT_STATE = {
  addUser: {},
  loadingAddUser: false,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case RESET_ADD_USER:
      return INIT_STATE;

    case ADD_USER:
      return {
        ...state,
        loadingAddUser: true,
      };
    case ADD_USER_SUCCESS:
      return {
        ...state,
        loadingAddUser: false,
        addUser: action.payload,
      };
    // eslint-disable-next-line no-fallthrough
    case ADD_USER_FAILURE:
      return {
        ...state,
        loadingAddUser: false,
        addUser: "Error in  getting Club Data",
      };
    default:
      return state;
  }
};
