import { call, put, takeEvery } from "redux-saga/effects";
import {
  ForgotPasswordSuccess,
  ForgotPasswordFailure,
} from "../../actions/ForgotPassword";
import { FORGOT_PASSWORD } from "../../constants/ForgotPassword";
import postRequest from "../../../util/APIHelperPost";
import { baseUrl } from "../../../util/APIBaseUrl";

//function
const getUtcFormatDate = (date) => {
  return new Date(date).toISOString();
};

export const fetchForgotPasswordRequest = async (actions) => {
  let body = {
    // picture: actions.payload?.employeeProfileData?.imageBlob,
    email: actions?.payload?.email,
    type: actions?.payload?.type,
  };

  body = JSON.stringify(body);

  return await postRequest(`${baseUrl}/forgotPassword`, body, true, "POST")
    .then((response) => response)
    .catch((error) => {
      throw error;
    });
};

function* fetchForgotPassword(params) {
  try {
    let response = yield call(fetchForgotPasswordRequest, params);
    yield put(ForgotPasswordSuccess(response));
  } catch (error) {
    console.error(error);
    yield put(ForgotPasswordFailure(error));
  }
}

export default function* rootSaga() {
  yield takeEvery(FORGOT_PASSWORD, fetchForgotPassword);
}
