import React from "react";

function UpcommingHolidayLeftIcon(props) {
  return (
    <svg width="37" height="37" viewBox="0 0 37 37" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="18.5" cy="18.5" r="18.5" transform="rotate(-180 18.5 18.5)" fill="#F1EFFF" />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M14.2586 19.1769L18.9772 23.7711L17.9449 24.8313L11.4162 18.4748L17.7727 11.9461L18.8329 12.9784L14.2388 17.697L25.0315 17.5528L25.0512 19.0326L14.2586 19.1769Z"
        fill="#3035AE"
      />
    </svg>
  );
}

export default UpcommingHolidayLeftIcon;
