// for add employee

export const RESET_CUSTOMER_USER_AND_COMPANY_PROFILE =
    "reset_customer_user_and_company_profile";
export const CUSTOMER_USER_AND_COMPANY_PROFILE =
    "customer_user_and_company_profile";
export const CUSTOMER_USER_AND_COMPANY_PROFILE_SUCCESS =
    "customer_user_and_company_profile_success";
export const CUSTOMER_USER_AND_COMPANY_PROFILE_FAILURE =
    "customer_user_and_company_profile_failure";
