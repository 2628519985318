import React from "react";

import GeneralInformation from "./GeneralInformation";
import ProfilePictureV2 from "../ProfilePictureV2";

import "../profile-v2.scss";

function NewPersonalProfileV2(props) {
  const { customerUserandCompanyProfileData, managersList } = props;

  return (
    <div className="profile-main">
      <div className="personal-info">
        <GeneralInformation
          title="Personal Information"
          customerUserandCompanyProfileData={customerUserandCompanyProfileData}
          managersList={managersList}
        />
      </div>
      <div className="profile-picture">
        <ProfilePictureV2 page="personalProfile" />
      </div>
    </div>
  );
}

export default NewPersonalProfileV2;
