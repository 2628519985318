import React from "react";
import PropTypes from "prop-types";
import { useFormContext, Controller } from "react-hook-form";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { Box, FormHelperText, TextField } from "@mui/material";
import InsertInvitationOutlinedIcon from "@mui/icons-material/InsertInvitationOutlined";

import "./DatePicker.scss";

function CustomDatePicker({
  name,
  label,
  helperText,
  sx,
  placeholder,
  value: propValue,
  onChange: propOnChange,
  error: propError,
  size = "small",
  variant = "outlined",
  disabled,
  width,
  monthYearOnly = false,
  minDate = null,
  ...other
}) {
  const formContext = useFormContext();
  const isControlled = propValue !== undefined && propOnChange !== undefined;

  const handleChange = (date, fieldOnChange) => {
    const year = date?.getFullYear();

    if (date === null) {
      fieldOnChange(null);
    } else if (year?.toString().length !== 4) {
      fieldOnChange("Invalid Date");
      return;
    } else {
      fieldOnChange(date);
    }

    if (propOnChange) {
      propOnChange(date);
    }
  };

  const renderTextField = (field = {}, fieldError) => (
    <div
      className={`w-100p date-picker-container ${propError || fieldError ? "has-error" : ""} ${disabled ? "is-disabled" : ""}`}
    >
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <Box shrink htmlFor={name}>
          {label}
        </Box>
        <DatePicker
          {...other}
          value={isControlled ? propValue : field.value || null}
          disabled={disabled}
          onChange={(date) => {
            handleChange(date, field?.onChange);
          }}
          DialogProps={{
            className: "date-picker-container",
          }}
          inputFormat={monthYearOnly ? "MM/yyyy" : "MM/dd/yyyy"}
          error={!!fieldError}
          components={{
            OpenPickerIcon: InsertInvitationOutlinedIcon,
          }}
          PopperProps={{
            placement: "bottom-start",
            className: "date-picker-container",
            modifiers: [
              {
                name: "offset",
                options: {
                  offset: [-20],
                },
              },
            ],
          }}
          minDate={minDate}
          views={monthYearOnly ? ["year", "month"] : ["year", "month", "day"]}
          renderInput={(params) => (
            <TextField
              size={size}
              variant={variant}
              error={!!fieldError}
              {...params}
              inputProps={{
                ...params.inputProps,
                placeholder: monthYearOnly ? "MM/YYYY" : "MM/DD/YYYY",
              }}
              sx={{ width, ...sx }}
            />
          )}
        />
        {fieldError && (
          <FormHelperText className="custom-helper-text">{fieldError.message || helperText}</FormHelperText>
        )}
      </LocalizationProvider>
    </div>
  );

  if (formContext && !isControlled) {
    return (
      <Controller
        name={name}
        control={formContext.control}
        render={({ field, fieldState: { error } }) => renderTextField(field, error)}
      />
    );
  }

  return renderTextField({ value: propValue, onChange: propOnChange }, propError ? { message: helperText } : null);
}

CustomDatePicker.propTypes = {
  name: PropTypes.string.isRequired,
  sx: PropTypes.shape({}),
  label: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.oneOf([null])]),
  onChange: PropTypes.func,
  error: PropTypes.bool,
  helperText: PropTypes.string,
  size: PropTypes.string,
  variant: PropTypes.string,
  disabled: PropTypes.bool,
  monthYearOnly: PropTypes.bool,
  minDate: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.oneOf([null])]),
};

CustomDatePicker.defaultProps = {
  helperText: "",
  sx: {},
  placeholder: "",
  value: undefined,
  onChange: undefined,
  error: false,
  size: "small",
  disabled: false,
  variant: "outlined",
  monthYearOnly: false,
  minDate: null,
};

export default CustomDatePicker;
