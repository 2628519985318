import {
    PAYONEER_GET_EMPLOYEE_REGISTRATION_LINK,
    PAYONEER_SET_EMPLOYEE_REGISTRATION_LINK,
    PAYONEER_GET_EMPLOYEE_REGISTRATION_LINK_FAILED,
  } from "store/constants/Payoneer/PayoneerGetEmployeeRegistrationLink";
  
  export const fetchRegistrationLink = () => {
    return {
      type: PAYONEER_GET_EMPLOYEE_REGISTRATION_LINK,
    };
  };
  
  export const setRegistrationLink = (balanceInfo) => {
    return { type: PAYONEER_SET_EMPLOYEE_REGISTRATION_LINK, payload: balanceInfo };
  };
  
  export const fetchRegistrationLinkFailed = (error) => {
    return { type: PAYONEER_GET_EMPLOYEE_REGISTRATION_LINK_FAILED, payload: error };
  };
  