import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import { Grid } from "@mui/material";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";

import PageLoader from "components/common/PageLoader";
import { usePermissions } from "components/Hook";

import { CustomerInfo } from "store/actions/CustomerInfo";
import { UserAdditionalInfo } from "store/actions/UserAdditionalInfo";
import { fetchAdminSummaryRequest } from "store/actions/AdminSummary";

import { getMissingPaymentMethodCustomersCount } from "services/Admin/Dashboard";

import customersCountIcon from "assets/images/customersCountIcon.svg";
import employeesCountIcon from "assets/images/employeesCountIcon.svg";

import "./AdminDashboard.css";

// main function
function AdminDashboard() {
  // constants
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { loggedInUserType } = usePermissions();

  // component state
  const [firstName, setFirstName] = useState(null);
  const [, setUserData] = useState(null);
  const [haveMissingPaymentMethodCustomers, setHaveMissingPaymentMethodCustomers] = useState(null);

  // redux state
  const userAdditionalInfo = useSelector((state) => state.UserAdditionalInfo.userAdditionalInfo);
  const loadingAdditionalInfo = useSelector((state) => state.UserAdditionalInfo.loadingAdditionalInfo);

  const fetchMissingPaymentMethodCustomersCount = async () => {
    const res = await getMissingPaymentMethodCustomersCount();
    setHaveMissingPaymentMethodCustomers(res);
  };

  useEffect(() => {
    setFirstName(userAdditionalInfo?.user?.firstName);
  }, [userAdditionalInfo]);
  // functions

  useEffect(() => {
    const Data = JSON.parse(localStorage.getItem("loggedInUser"));
    let request = null;

    if (loggedInUserType == "edge") {
      if (Data.userId != undefined) {
        request = `user/${Data.userId}/summary`;
        dispatch(UserAdditionalInfo(request));
        dispatch(
          CustomerInfo({
            request: "customers",
            employeeId: Data.userId,
            rowsPerPage: 5,
            page: 0,
          })
        );
      }
      setUserData(Data);
    }
  }, []);

  const summary = useSelector((state) => state?.AdminSummary?.summary);
  const loadingAdminSummary = useSelector((state) => state?.AdminSummary?.loadingAdminSummary);

  const getDayStatus = () => {
    const currentTime = new Date();
    const currentHour = currentTime.getHours();

    if (currentHour >= 5 && currentHour < 12) {
      return "Morning";
    }
    if (currentHour >= 12 && currentHour < 17) {
      return "Afternoon";
    }
    if (currentHour >= 17 && currentHour < 20) {
      return "Evening";
    }
    return "Evening";
  };

  useEffect(() => {
    fetchMissingPaymentMethodCustomersCount();
    dispatch(fetchAdminSummaryRequest());
  }, [dispatch]);

  return (
    <>
      {loadingAdditionalInfo || loadingAdminSummary ? (
        <PageLoader />
      ) : (
        // eslint-disable-next-line react/jsx-no-useless-fragment
        <></>
      )}
      <div className="summary-section">
        <div className="greetings-text">
          <h2>{`Good ${getDayStatus()}, ${firstName || ""}!`}</h2>
          <p>Here’s a quick summary of customers and employees on the platform.</p>
        </div>

        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <div className="summary-card">
              <div className="summary-card-top-section">
                <div
                  className="active-customers-employees"
                  onClick={() => {
                    navigate("/main/customers?status=enabled", { state: { status: "disabled" } });
                  }}
                >
                  <h2 className="active-customers-employees-count">{summary?.customers?.activated}</h2>
                  <p className="active-customers-employees-label">Active Customers</p>
                </div>

                <div className="countIcon">
                  <img src={customersCountIcon} alt="customers count" />
                </div>
              </div>

              <div className="summary-grid">
                <div
                  className="summary-item"
                  onClick={() => {
                    navigate("/main/customers?status=disabled", { state: { status: "disabled" } });
                  }}
                >
                  <h6>{summary?.customers?.deactivated}</h6>
                  <p>Deactivated</p>
                </div>

                <div className="separator" />

                <div
                  className="summary-item"
                  onClick={() => {
                    navigate("/main/customers?status=churned", { state: { status: "disabled" } });
                  }}
                >
                  <h6>{summary?.customers?.churned}</h6>
                  <p>Churned</p>
                </div>
              </div>
            </div>
          </Grid>
          <Grid item xs={12} md={6}>
            <div className="summary-card">
              <div className="summary-card-top-section">
                <div
                  className="active-customers-employees"
                  onClick={() => {
                    navigate("/main/workforce?status=active", { state: { status: "disabled" } });
                  }}
                >
                  <h2 className="active-customers-employees-count">{summary?.employees?.active}</h2>
                  <p className="active-customers-employees-label">Active Employees</p>
                </div>
                <div className="countIcon">
                  <img src={employeesCountIcon} alt="count of employees" />
                </div>
              </div>
              <div className="summary-grid">
                <div
                  className="summary-item"
                  onClick={() => {
                    navigate("/main/workforce?status=assigned", { state: { status: "disabled" } });
                  }}
                >
                  <h6>{summary?.employees?.assigned}</h6>
                  <p>Assigned</p>
                </div>
                <div className="separator" />
                <div
                  className="summary-item"
                  onClick={() => {
                    navigate("/main/workforce?status=unassigned", { state: { status: "disabled" } });
                  }}
                >
                  <h6>{summary?.employees?.unassigned}</h6>
                  <p>Unassigned</p>
                </div>
                <div className="separator" />
                <div
                  className="summary-item"
                  onClick={() => {
                    navigate("/main/workforce?status=pending", { state: { status: "disabled" } });
                  }}
                >
                  <h6>{summary?.employees?.pending}</h6>
                  <p>Pending</p>
                </div>
                <div className="separator" />
                <div
                  className="summary-item"
                  onClick={() => {
                    navigate("/main/workforce?status=inactive", { state: { status: "disabled" } });
                  }}
                >
                  <h6>{summary?.employees?.inactive}</h6>
                  <p>Inactive</p>
                </div>
              </div>
            </div>
          </Grid>

          {haveMissingPaymentMethodCustomers && (
            <Grid item xs={12} className="view-customer-grid">
              <div
                className="summary-card"
                onClick={() => {
                  navigate("/main/customers?payment-method-type=MISSING");
                }}
              >
                <div className="summary-card-top-section">
                  <p>View customers with missing payment details</p>
                  <ChevronRightIcon />
                </div>
              </div>
            </Grid>
          )}
        </Grid>
      </div>
    </>
  );
}

export default AdminDashboard;
