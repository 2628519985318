import { IconButton, Tooltip } from "@mui/material";
import "./Documents.css";
import CircularProgress from "@mui/material/CircularProgress";
import DocsIcon from "assets/images/file.svg";
import { usePermissions } from "components/Hook";
import getRequest from "util/APIHelperGet";
import { baseUrl } from "util/APIBaseUrl";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { showSnackbar } from "store/actions/Utility";

function Documents({ documents = [], requestId }) {
  const { loggedInUserType } = usePermissions();
  const dispatch = useDispatch();

  const [documentDownloadLoading, setDocumentDownloadLoading] = useState({ loader: false, documentId: null });

  const DocumentItem = (name, size, documentId) => {
    return (
      <div className="prescription-item">
        <div className="flex items-center">
          {documentDownloadLoading?.loader && documentDownloadLoading?.documentId === documentId ? (
            <IconButton color="default" sx={{ p: 0, width: "21px", height: "21px" }}>
              <CircularProgress size={20} color="inherit" />
            </IconButton>
          ) : (
            <img src={DocsIcon} alt="docs icon" />
          )}

          <Tooltip title={name || ""}>
            <span className="file-name" onClick={() => handleDownloadDocument(name, documentId)}>
              {name}
            </span>
          </Tooltip>
        </div>
        <span className="file-size">{size}</span>
      </div>
    );
  };

  const handleDownloadDocument = async (name, documentId) => {
    const request = {
      edge: `/admin/leave/document/${requestId}/${documentId}`,
    };

    setDocumentDownloadLoading({ loader: true, documentId });
    try {
      const response = await getRequest(`${baseUrl}/${request[loggedInUserType]}`, true, "export");

      if (!response?.ok) {
        return;
      }

      setDocumentDownloadLoading({ loader: false, documentId: null });
      const url = window.URL.createObjectURL(response?.payload);
      const link = document.createElement("a");
      link.style.display = "none";
      link.href = url;

      link.download = name;
      document.body.appendChild(link);
      link.click();
      window.URL.revokeObjectURL(url);
      dispatch(showSnackbar({ dispatch, type: "success", message: "Downloaded Successfully!" }));
    } catch (error) {
      setDocumentDownloadLoading({ loader: false, documentId: null });
    }
  };

  return (
    <div className="py-05">
      <div className="flex justify-between items-center px-1">
        <p className="document-title">Documents Attached ({documents?.length || 0})</p>
        {/* <p className='download-title'>Download all</p> */}
      </div>
      <div className="prescription-list">
        {documents.map(
          (document, index) => DocumentItem(document?.name, document?.size, document?.documentId)
          // <DocumentItem key={index} name={document?.name} size={document?.size} documentId={document?.documentId} />
        )}
      </div>
    </div>
  );
}

export default Documents;
