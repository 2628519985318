import { call, put, takeEvery } from "redux-saga/effects";
//actions
import {
  changeUserPasswordSuccess,
  changeUserPasswordFailure,
} from "../../actions/Settings/ChangeUserPassword";
//constant
import { CHANGE_USER_PASSWORD } from "../../constants/Settings/ChangeUserPassword";
//request type
import postRequest from "../../../util/APIHelperPost";
import { baseUrl } from "../../../util/APIBaseUrl";

export const fetchChangeUserPasswordRequest = async (actions) => {
  // eslint-disable-next-line no-return-await
  let body = JSON.stringify({
    password:
      actions?.payload?.userPassword?.currentPassword ||
      actions?.payload?.userPassword?.oldPassword,
    newPassword: actions.payload.userPassword.newPassword,
    confirmPassword: actions.payload?.userPassword?.confirmPassword,
    email: actions.payload.userEmail,
  });

  return await postRequest(`${baseUrl}/${actions.payload.request}`, body)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

function* fetchChangeUserPassword(params) {
  try {
    let response = yield call(fetchChangeUserPasswordRequest, params);
    yield put(changeUserPasswordSuccess(response));
  } catch (error) {
    console.error(error);
    yield put(changeUserPasswordFailure(error));
  }
}

export default function* rootSaga() {
  yield takeEvery(CHANGE_USER_PASSWORD, fetchChangeUserPassword);
}
