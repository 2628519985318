import React from "react";
import { useNavigate } from "react-router-dom";

import { Grid } from "@mui/material";

import Button from "components/Button/Button";

import "./PageToolbar.scss";

function ToolBar({ setCurrentTab, currentTab, tabs }) {
  const navigate = useNavigate();

  return (
    <Grid className="toolbar-container">
      <Grid className="page-toolbar-wrapper">
        {tabs?.map(({ title, link, count, key }) => (
          <Button
            key={title}
            className="page-toolbar-tab"
            type="button"
            id={`tab-${title}`}
            variant={title === currentTab ? "primary" : "ghost"}
            onClick={() => {
              window.location.hash = key;
              setCurrentTab(title);
              if (link) navigate(link);
            }}
          >
            <div className="para-body-m-medium">{`${title} ${count ? `(${count})` : ""}`}</div>
          </Button>
        ))}
      </Grid>
    </Grid>
  );
}

export default ToolBar;
