import {
    PAYONEER_REVOKE_ACCESS_TOKEN_FETCH,
    PAYONEER_REVOKE_ACCESS_TOKEN_SUCCESS,
    PAYONEER_PAYONEER_REVOKE_ACCESS_TOKEN_FAILED,
  } from "store/constants/Payoneer/PayoneerRevokeAccessToken";
  
  const initialState = {
    revokeInfo: {
      revokeAccessToken: false,
      error: null,
      isLoading: false,
    },
  };
  
  const revokeAccessTokenReducer = (state = initialState, action) => {
    switch (action.type) {
        case PAYONEER_REVOKE_ACCESS_TOKEN_FETCH:
        return {
          ...state,
          revokeInfo: {
            revokeAccessToken: false,
            error: null,
            isLoading: true,
          },
        };
      case PAYONEER_REVOKE_ACCESS_TOKEN_SUCCESS:
        return {
          ...state,
          revokeInfo: {
            revokeAccessToken: true,
            error: null,
            isLoading: false,
          },
        };
      case PAYONEER_PAYONEER_REVOKE_ACCESS_TOKEN_FAILED:
        return {
          ...state,
          revokeInfo: {
            revokeAccessToken: false,
            error: action.payload,
            isLoading: false,
          },
        };
      default:
        return state;
    }
  };
  
  export default revokeAccessTokenReducer;
  