import React from "react";
import PropTypes from "prop-types";
import { useFormContext, Controller } from "react-hook-form";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import { InputAdornment } from "@mui/material";

function Text({
  name,
  label,
  helperText,
  type,
  sx,
  placeholder,
  isOptional,
  value: propValue,
  minValue,
  onChange: propOnChange,
  error: propError,
  size = "small",
  variant = "outlined",
  disabled = false,
  helperTextWithoutError = "",
  multiline = false,
  rows = 1,
  maxRows,
  minRows,
  startIcon,
  endIcon,
  ...other
}) {
  const formContext = useFormContext();

  const handleChange = (event, field) => {
    const newEvent = { ...event };
    let newValue = event.target.value;
    if (type === "number" && minValue !== undefined) {
      newValue = newValue === "" ? "" : Math.max(Number(newValue), minValue);
      newEvent.target.value = newValue;
    }
    if (field) {
      field.onChange(newEvent, field);
    } else if (propOnChange) {
      propOnChange(newEvent, field);
    }
  };

  const renderTextField = (field = {}, fieldError) => (
    <FormControl sx={{ ...sx }} className="w-100p" data-testid="form-control">
      <InputLabel
        shrink
        htmlFor={name}
        className="paragraph text-md text-left font-medium line-height-lg mb-025 pos-relative non-transform"
      >
        {label && `${label} ${isOptional ? "(optional)" : ""}`}
      </InputLabel>
      <div className="ant-text-component">
        <TextField
          {...field}
          id={name}
          name={name}
          type={type}
          placeholder={placeholder}
          error={!!fieldError}
          helperText={fieldError ? fieldError.message : helperText || helperTextWithoutError}
          variant={variant}
          disabled={disabled}
          fullWidth
          multiline={multiline}
          rows={rows}
          maxRows={maxRows}
          minRows={minRows}
          InputProps={{
            classes: {
              input: "paragraph font-regular text-lg line-height-xl flex",
              root: `custom-text-input-root text-field-padding text-field-input-${size} ${disabled ? "disabled-input" : ""} ${multiline ? "multiline-input" : ""}`,
              focused: "custom-text-input-focused",
              error: "custom-text-input-error",
            },
            "data-testid": "text-field",
            startAdornment: startIcon && <InputAdornment position="start">{startIcon}</InputAdornment>,
            endAdornment: endIcon && <InputAdornment position="end">{endIcon}</InputAdornment>,
          }}
          FormHelperTextProps={{
            classes: {
              root: `paragraph text-sm font-regular line-height-sm ${!helperTextWithoutError ? "error-text-color" : ""} ml-0`,
            },
          }}
          onChange={(e) => handleChange(e, field)}
          {...other}
        />
      </div>
    </FormControl>
  );

  return formContext && !propValue && !propOnChange ? (
    <Controller
      name={name}
      control={formContext.control}
      render={({ field, fieldState: { error } }) => renderTextField(field, error)}
    />
  ) : (
    renderTextField({ value: propValue, onChange: propOnChange }, propError ? { message: helperText } : null)
  );
}

Text.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  helperText: PropTypes.string,
  helperTextWithoutError: PropTypes.string,
  type: PropTypes.string,
  sx: PropTypes.shape({}),
  placeholder: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  minValue: PropTypes.number,
  onChange: PropTypes.func,
  error: PropTypes.bool,
  size: PropTypes.oneOf(["small", "medium"]),
  disabled: PropTypes.bool,
  multiline: PropTypes.bool,
  rows: PropTypes.number,
  maxRows: PropTypes.number,
  minRows: PropTypes.number,
  variant: PropTypes.oneOf(["standard", "outlined", "filled"]),
  isOptional: PropTypes.bool,
};

Text.defaultProps = {
  helperTextWithoutError: "",
  helperText: "",
  type: "text",
  sx: {},
  placeholder: "",
  value: undefined,
  minValue: undefined,
  onChange: undefined,
  error: false,
  size: "small",
  disabled: false,
  multiline: false,
  rows: 1,
  maxRows: undefined,
  minRows: undefined,
  variant: "outlined",
  isOptional: false,
};

export default Text;
