import React from "react";
//material ui grid
import { Grid } from "@mui/material";
//components
import Header from "../../Header/Header";
import PageHeader from "../PageHeader";
import Payroll from "./Payroll";
import PayrollHistory from "./PayrollHistory";
import PayrollDetails from "./PayrollDetails";
import { ResetGetPaymentDetails } from "../../../store/actions/Payroll/GetPaymentDetails";
//redux
import { useSelector, useDispatch } from "react-redux";
import { ResetGiveRaise } from "../../../store/actions/Raises/GiveRaise";
import { ResetUserAdditionalInfo } from "../../../store/actions/UserAdditionalInfo";
import { useLocation } from "react-router-dom";

const headersWithoutPaymentails = ["Payroll", "Payroll History"];

const headersWithPaymentails = [
  "Payroll",
  "Payroll History",
  "Payroll Details",
];

function index(props) {
  const dispatch = useDispatch();
  const { state } = useLocation();
  //component state
  const [option, setOption] = React.useState("Payroll");
  const [customerSubscription, setCustomerSubscription] = React.useState({});

  //redux states
  const paymentDetails = useSelector(
    (state) => state.GetPaymentDetails.paymentDetails
  );

  //function
  const selectPageHeading = (opt) => {
    if (opt == "Payroll History" || opt == "Payroll") {
      dispatch(ResetGetPaymentDetails({}));
    }
    setOption(opt);
  };

  React.useEffect(() => {
    if (Object.keys(customerSubscription).length) {
    }
  }, [customerSubscription]);

  React.useEffect(() => {
    let customerSubscription = null;

    if (state != null && state != undefined) {
      // setOption(state?.page);
      selectPageHeading(state?.page);
    } else {
      selectPageHeading("Payroll");
    }

    // getSubscription();
    dispatch(ResetGiveRaise({}));
    dispatch(ResetUserAdditionalInfo({}));

    if (customerSubscription != null && customerSubscription != undefined) {
      setCustomerSubscription(JSON.parse(customerSubscription));
    }
  }, []);
  return (
    <>
      {/* Here is code  */}
      <Grid container direction="column" rowSpacing={3}>
        <Grid item container direction="row">
          <Grid item xs={12}>
            {option == "Payroll" ? (
              <Payroll
                customerSubscription={customerSubscription}
                selectPageHeading={selectPageHeading}
              />
            ) : option == "Payroll History" ? (
              <PayrollHistory
                selectPageHeading={selectPageHeading}
                // handleSelectOption={handleSelectOption}
              />
            ) : option == "Payroll Details" ? (
              <PayrollDetails />
            ) : null}
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}

export default index;
