//employee constants
import {
  RESET_ADD_EMPLOYEE_Education,
  ADD_EMPLOYEE_Education,
  ADD_EMPLOYEE_Education_SUCCESS,
  ADD_EMPLOYEE_Education_FAILURE,
} from "../../../constants/Workforce/AddEmployee/AddEmployeeEducation";

const INIT_STATE = {
  addEmployeeEducation: {},
  loadingAddEmployeeEducation: false,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case RESET_ADD_EMPLOYEE_Education:
      return INIT_STATE;

    case ADD_EMPLOYEE_Education:
      return {
        ...state,
        loadingAddEmployeeEducation: true,
      };
    case ADD_EMPLOYEE_Education_SUCCESS:
      return {
        ...state,
        loadingAddEmployeeEducation: false,
        addEmployeeEducation: action.payload,
      };
    // eslint-disable-next-line no-fallthrough
    case ADD_EMPLOYEE_Education_FAILURE:
      return {
        ...state,
        loadingAddEmployeeEducation: false,
        addEmployeeEducation: "Error in  getting Club Data",
      };
    default:
      return state;
  }
};
