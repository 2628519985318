///////////////////  ExpandSidebarMenu ///////////////////
import { ConnectedTv } from "@mui/icons-material";
import React from "react";

function ExpandSidebarMenu(props) {
  const { style } = props;
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      // style={style}
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M15.8613 17.5764C14.914 17.5764 14.146 16.8084 14.146 15.8611C14.146 14.9138 14.914 14.1458 15.8613 14.1458C16.8086 14.1458 17.5766 14.9138 17.5766 15.8611C17.5766 16.8084 16.8086 17.5764 15.8613 17.5764ZM15.8613 10.7152C14.914 10.7152 14.146 9.94729 14.146 8.99997C14.146 8.05265 14.914 7.28469 15.8613 7.28469C16.8086 7.28469 17.5766 8.05265 17.5766 8.99997C17.5766 9.94729 16.8086 10.7152 15.8613 10.7152ZM15.8613 3.85414C14.914 3.85414 14.146 3.08618 14.146 2.13886C14.146 1.19154 14.914 0.423584 15.8613 0.423584C16.8086 0.423584 17.5766 1.19154 17.5766 2.13886C17.5766 3.08618 16.8086 3.85414 15.8613 3.85414ZM9.00022 17.5764C8.05289 17.5764 7.28494 16.8084 7.28494 15.8611C7.28494 14.9138 8.05289 14.1458 9.00022 14.1458C9.94754 14.1458 10.7155 14.9138 10.7155 15.8611C10.7155 16.8084 9.94754 17.5764 9.00022 17.5764ZM9.00022 10.7152C8.05289 10.7152 7.28494 9.94729 7.28494 8.99997C7.28494 8.05265 8.05289 7.28469 9.00022 7.28469C9.94754 7.28469 10.7155 8.05265 10.7155 8.99997C10.7155 9.94729 9.94754 10.7152 9.00022 10.7152ZM9.00022 3.85414C8.05289 3.85414 7.28494 3.08618 7.28494 2.13886C7.28494 1.19154 8.05289 0.423584 9.00022 0.423584C9.94754 0.423584 10.7155 1.19154 10.7155 2.13886C10.7155 3.08618 9.94754 3.85414 9.00022 3.85414ZM2.13911 17.5764C1.19178 17.5764 0.423828 16.8084 0.423828 15.8611C0.423828 14.9138 1.19178 14.1458 2.13911 14.1458C3.08643 14.1458 3.85438 14.9138 3.85438 15.8611C3.85438 16.8084 3.08643 17.5764 2.13911 17.5764ZM2.13911 10.7152C1.19178 10.7152 0.423828 9.94729 0.423828 8.99997C0.423828 8.05265 1.19178 7.28469 2.13911 7.28469C3.08643 7.28469 3.85438 8.05265 3.85438 8.99997C3.85438 9.94729 3.08643 10.7152 2.13911 10.7152ZM2.13911 3.85414C1.19178 3.85414 0.423828 3.08618 0.423828 2.13886C0.423828 1.19154 1.19178 0.423584 2.13911 0.423584C3.08643 0.423584 3.85438 1.19154 3.85438 2.13886C3.85438 3.08618 3.08643 3.85414 2.13911 3.85414Z"
        fill={style?.fill ? style?.fill : "#14151F"}
      />
    </svg>
  );
}

export default ExpandSidebarMenu;

/// sidebar
