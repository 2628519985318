import React from "react";
import { Checkbox, Tooltip } from "@mui/material";

import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

import "./TextCheckBox.scss";

function TextCheckBox({ text, checked, toolTipContent, onChange, disabled = false }) {
  const handleClick = () => {
    if (checked || !disabled) {
      onChange({ target: { checked: !checked } });
    }
  };

  return (
    <div
      className="flex flex-row items-center justify-start"
      onClick={handleClick}
      style={{ cursor: `${disabled ? "" : "pointer"}` }}
    >
      <Checkbox className="check-box" checked={checked} onChange={onChange} disabled={!checked && disabled} />
      <p className="para-body-l-regular">{text}</p>
      {disabled && (
        <Tooltip title={toolTipContent} placement="right">
          <InfoOutlinedIcon className="money-icon ml-05 cursor-pointer" />
        </Tooltip>
      )}
    </div>
  );
}

export default TextCheckBox;
