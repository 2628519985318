//employee constants
import {
  RESER_EMPLOYEE_SALARY_DETAILS,
  EMPLOYEE_SALARY_DETAILS,
  EMPLOYEE_SALARY_DETAILS_SUCCESS,
  EMPLOYEE_SALARY_DETAILS_FAILURE,
} from "../../../constants/Workforce/EmployeeDetils/SalaryDetails";

const INIT_STATE = {
  employeeSalaryDetails: {},
  loadingEmployeeSalaryDetils: false,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case RESER_EMPLOYEE_SALARY_DETAILS:
      return INIT_STATE;

    case EMPLOYEE_SALARY_DETAILS:
      return {
        ...state,
        loadingEmployeeSalaryDetils: true,
      };

    case EMPLOYEE_SALARY_DETAILS_SUCCESS:
      return {
        ...state,
        loadingEmployeeSalaryDetils: false,
        employeeSalaryDetails: action.payload,
      };

    // eslint-disable-next-line no-fallthrough
    case EMPLOYEE_SALARY_DETAILS_FAILURE:
      return {
        ...state,
        loadingEmployeeSalaryDetils: false,
        employeeSalaryDetails: "Error in  getting Club Data",
      };
    default:
      return state;
  }
};
