import { call, put, takeEvery } from "redux-saga/effects";
import { setRegistrationLink, fetchRegistrationLinkFailed } from "../../actions/Payoneer/PayoneerGetEmployeeRegistrationLink";
import { PAYONEER_GET_EMPLOYEE_REGISTRATION_LINK } from "store/constants/Payoneer/PayoneerGetEmployeeRegistrationLink";
import { baseUrl } from "util/APIBaseUrl";
import postRequest from "util/APIHelperPost";

const fetchRegistrationLinkFromAPI = async () => {
  return postRequest(`${baseUrl}/payoneer/create-registration-link`);
};

function* fetchRegistrationLinkSaga() {
  const registrationInfo = yield call(fetchRegistrationLinkFromAPI);
  if (registrationInfo?.ok) {
    yield put(setRegistrationLink(registrationInfo?.payload?.registrationLink));
  } else {
    yield put(fetchRegistrationLinkFailed(registrationInfo?.payload?.error[0]));
  }
}

export default function* rootSaga() {
  yield takeEvery(PAYONEER_GET_EMPLOYEE_REGISTRATION_LINK, fetchRegistrationLinkSaga);
}
