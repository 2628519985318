/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable array-callback-return */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-dupe-else-if */
import React, { useRef, useState, useEffect, useMemo } from "react";

import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";

import { Typography, Grid, Chip, Box, Paper, Button, Grow } from "@mui/material";
import FilterListIcon from "@mui/icons-material/FilterList";

import AvatarIcon from "components/Icons/AvatarIcon";
import ProfleWithIcon from "components/Widgets/ProfileWithIcon";
import DataTable from "components/Widgets/DataTable";
import EditCustomer from "components/Widgets/Edit";
import Filter from "components/Widgets/Filter";
import DeleteCustomer from "components/Widgets/Delete";
import PageLoader from "components/common/PageLoader";
import { convertDate, currencyFormat, getCurrencySymbol, getValue } from "components/Screens/CommonUtils";
import SendActivationEmail from "components/Widgets/SendActivationEmail";

import { CustomerInfo, ResetCustomerInfo } from "store/actions/CustomerInfo";
import { GetCustomerDetails } from "store/actions/Customers/GetCustomerDetails";
import { DropdownData } from "store/actions/DropdownData";
import { DeleteUser, ResetDeleteUser } from "store/actions/Settings/DeleteUser";
import { DisableCustomer, ResetDisableCustomer } from "store/actions/Customers/DisableCustomer";
import { ResetGetCustomerPaymentById } from "store/actions/Customers/GetCustomerPaymentById";
import { GetCustomersList, ResetGetCustomersList } from "store/actions/Customers/GetCustomerList";
import { CustomerActivationEmail } from "store/actions/Customers/ActivationEmail";

import getRequest from "util/APIHelperGet";
import { baseUrl } from "util/APIBaseUrl";

import totalEmployeesIcon from "../../Icons/TotalEmployeesIcon.png";
import { useDebounce } from "components/useDebounce";

// constants
let filterRemoved = false;

const styles = {
  position: "absolute",
  width: 300,
  top: 35,
  right: 0,
  left: -275,
  zIndex: 1,
  border: "1px light gray",
  p: 3,
  bgcolor: "background.paper",
};

const tableheadCells = [
  {
    id: "Profile_Picture",

    mobileView: true,
    label: "",
    align: "right",
    width: "20px",
    sort: false,
  },
  {
    id: "Company_Name",
    wrapCell: true,
    numeric: false,
    width: "230px",
    disablePadding: false,
    label: <Typography variant="h5">Customer Name</Typography>,
  },
  {
    id: "Contact_Email",
    width: "250px",
    numeric: false,
    wrapCell: true,
    disablePadding: false,
    label: <Typography variant="h5">Email</Typography>,
  },

  {
    id: "Total_Employees",
    numeric: true,
    disablePadding: true,
    label: (
      <Typography variant="h5" pl={3}>
        Employees
      </Typography>
    ),
    width: "20px",
  },
  {
    id: "Last_Payment",
    numeric: true,
    disablePadding: true,
    label: (
      <Typography variant="h5" pl={3}>
        Last Payment
      </Typography>
    ),
  },

  {
    id: "Payment_Date",
    numeric: true,
    width: "120px",
    disablePadding: true,
    label: (
      <Typography variant="h5" pl={3}>
        Payment Date
      </Typography>
    ),
  },
  {
    id: "paymentMethodType",
    numeric: true,
    width: "120px",
    disablePadding: true,
    label: (
      <Typography variant="h5" pl={3}>
        Payment Method
      </Typography>
    ),
  },
  {
    id: "On_Boarding_Date",
    numeric: true,
    width: "120px",
    disablePadding: false,
    label: (
      <Typography variant="h5" pl={3}>
        Onboard Date
      </Typography>
    ),
  },
  {
    id: "Status",
    numeric: true,
    disablePadding: true,
    align: "center",
    label: <Typography variant="h5">Status</Typography>,
  },
  {
    id: "action",
    label: (
      <Typography variant="h5" textAlign="center">
        Actions
      </Typography>
    ),
    align: "center",
    textAlign: "center",
    width: "160px",
    sort: false,
    mobileView: false,
  },
];

function Customers() {
  const openFilterMenu = useRef(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const searchParams = new URLSearchParams(location.search);
  const statusFromUrl = searchParams.get("status");
  const paymentMethodTypeUrl = searchParams.get("payment-method-type");

  // component states
  const [open, setOpen] = useState(false);
  const [tableRows, setTableRows] = useState([]);
  const [resetPage, setResetPage] = useState(false);
  const [selectedRowID, setSelectedRowID] = useState(null);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const [DropdownData_, setDropdownData_] = useState({});
  const [apiFilters, setApiFilters] = useState({});
  const [sortBy, setSortBy] = useState({});
  const [searchcustomerInfo, setSearchCustomerInfo] = useState("");
  const [selectedOptions, setSelectedOptions] = useState(
    statusFromUrl
      ? { status: [{ key: statusFromUrl, value: statusFromUrl.charAt(0).toUpperCase() + statusFromUrl.slice(1) }] }
      : {}
  );
  const [navigateToPage, setNavigateToPage] = useState(null);

  // debounce input
  const debounceSearchCustomer = useDebounce(searchcustomerInfo);

  // redux state
  const customerInfo = useSelector((state) => state.CustomerInfo.customerInfo);
  const loadingCustomerInfo = useSelector((state) => state.CustomerInfo.loadingCustomerInfo);

  const dropdownData = useSelector((state) => state.DropdownData.dropdownData);
  const loadingDropdownData = useSelector((state) => state.DropdownData.loadingDropdownData);

  const customerDetails = useSelector((state) => state.GetCustomerDetails.customerDetails);
  const loadingCustomerDetails = useSelector((state) => state.GetCustomerDetails.loadingCustomerDetails);

  const disableCustomer = useSelector((state) => state.DisableCustomer.disableCustomer);
  const loadingDisableCustomer = useSelector((state) => state.DisableCustomer.loadingDisableCustomer);

  const deleteUser = useSelector((state) => state.DeleteUser.deleteUser);
  const loadingDeleteUser = useSelector((state) => state.DeleteUser.loadingDeleteUser);

  const getCustomerList = useSelector((state) => state.GetCustomerList.getCustomerList);

  // functions
  const closeOpenMenus = (e) => {
    if (
      openFilterMenu.current &&
      open &&
      !openFilterMenu.current.contains(e.target) &&
      !document.getElementsByClassName("MuiAutocomplete-popper").length
    ) {
      setOpen(false);
    }
  };

  document.addEventListener("mousedown", closeOpenMenus);

  const handleClick = () => {
    setOpen(!open);
  };

  const getStatusDropdownOptions = (dropdownDataValue) => {
    const statusMap = {
      enabled: "Activated",
      disabled: "Deactivated",
      new: "New",
      churned: "Churned",
    };

    const statusOption = (dropdownDataValue?.payload?.customerStatus || [])?.map((item) => ({
      key: item,
      value: statusMap[item] || item,
    }));

    return statusOption || [];
  };

  const StatusOption = useMemo(() => {
    let statusOption = [];
    if (dropdownData !== undefined && Object.keys(dropdownData).length && dropdownData.ok) {
      statusOption = getStatusDropdownOptions(dropdownData);
    }
    return statusOption;
  }, [loadingDropdownData]);

  const handleDelete = (event, key, option) => {
    event.preventDefault();
    setPage(() => 0);
    setOpen(false);
    if (selectedOptions[key]) {
      const newdict = { ...selectedOptions };
      newdict[key] = selectedOptions[key].filter((selectedOption) => selectedOption !== option);
      filterRemoved = true;
      if (!newdict[key].length && key === "customers") {
        dispatch(ResetGetCustomersList({}));
      }
      setSelectedOptions(newdict);
    }
  };

  const searchCustomer = (customerName) => {
    setSearchCustomerInfo(customerName);
  };

  const callbackFromMultiSelect = (option, key) => {
    selectedOptions[key] = option;
    setPage(() => 0);
    setResetPage(true);
    setSelectedOptions({
      ...selectedOptions,
    });

    if (!Object.keys(option).length && key === "customers") {
      dispatch(ResetGetCustomersList({}));
    }
  };

  const handleRowsPerPage = (pagination) => {
    setRowsPerPage(pagination.rowsPerPage);
    setPage(pagination.page);
  };

  const handleSelectedRow = (selectedCustomerID, navigateTo) => {
    setSelectedRowID(selectedCustomerID);
    setNavigateToPage(navigateTo);
    if (selectedCustomerID != null && selectedCustomerID !== undefined) {
      dispatch(
        GetCustomerDetails({
          customerId: selectedCustomerID,
        })
      );
    }
  };

  const SendCustomerActivationEmail = (selectedCustomerID) => {
    const request = `customer/${selectedCustomerID}/onboardingEmail`;

    if (selectedCustomerID) {
      dispatch(
        CustomerActivationEmail({
          request,
        })
      );
    }
  };

  const handleDeleteRow = (selectedCustomerID, deleteAction) => {
    let loggedInUser = {};
    let deleteUserRequest = "";
    let disableCustomerRequest = "";
    loggedInUser = JSON.parse(localStorage.getItem("loggedInUser"));
    if (selectedCustomerID !== undefined && selectedCustomerID != null && deleteAction === "delete") {
      if (loggedInUser?.type === "edge") {
        deleteUserRequest = `customer/${selectedCustomerID}`;
      }
      dispatch(DeleteUser({ request: deleteUserRequest }));
    } else if (
      selectedCustomerID !== undefined &&
      selectedCustomerID != null &&
      (deleteAction === "disable" || deleteAction === "enable" || deleteAction === "churned")
    ) {
      if (loggedInUser?.type === "edge") {
        if (deleteAction === "disable") {
          disableCustomerRequest = `customer/${selectedCustomerID}/disable`;
        } else if (deleteAction === "enable") {
          disableCustomerRequest = `customer/${selectedCustomerID}/enable`;
        } else if (deleteAction === "churned") {
          disableCustomerRequest = `customer/${selectedCustomerID}/churn`;
        }
      }
      dispatch(DisableCustomer({ request: disableCustomerRequest }));
    }
  };

  const handleRequestSortBy = (request) => {
    let loggedInUser = {};
    loggedInUser = JSON.parse(localStorage.getItem("loggedInUser"));
    setSortBy(request);

    dispatch(
      CustomerInfo({
        request: "customers",
        employeeId: loggedInUser?.userId,
        rowsPerPage,
        page,
        dist: apiFilters,
        sortBy: {
          field: request.orderBy,
          direction: request.order,
        },
      })
    );
  };

  const handleReset = () => {
    setOpen(false);
    setSelectedOptions({ customers: [], status: [], paymentMethodType: [] });
    filterRemoved = true;
    dispatch(ResetGetCustomersList({}));
  };

  const handleExport = async () => {
    const response = await getRequest(
      `${baseUrl}/customers/export?${new URLSearchParams({
        limit: rowsPerPage,
        export: true,
        page,
        orderBy: JSON.stringify({
          field: sortBy?.field,
          direction: sortBy?.direction,
        }),
        filter: JSON.stringify({
          companyName: apiFilters?.customers,
          status: apiFilters?.status,
          paymentMethodType: apiFilters?.paymentMethodType,
        }),
      })}`,
      true,
      "export"
    );

    if (!response?.ok) {
      return;
    }
    const url = window.URL.createObjectURL(response?.payload);
    const link = document.createElement("a");
    link.style.display = "none";
    link.href = url;

    link.download = "Customers";
    document.body.appendChild(link);
    link.click();
    window.URL.revokeObjectURL(url);
  };

  // methods

  useEffect(() => {
    let apiFilter = {};
    let arr = [];
    let loggedInUser = {};
    loggedInUser = JSON.parse(localStorage.getItem("loggedInUser"));
    if (Object.keys(selectedOptions).length > 0) {
      arr = [];
      Object.keys(selectedOptions).map((header) => {
        selectedOptions[header].map((option) => {
          arr.push(option.key);
        });
        apiFilter[header] = arr;
        arr = [];
      });

      setApiFilters(apiFilter);

      dispatch(
        CustomerInfo({
          request: "customers",
          employeeId: loggedInUser?.userId,
          rowsPerPage,
          // page: 0,
          page,
          dist: apiFilter,
          sortBy: {
            field: sortBy.orderBy,
            direction: sortBy.order,
          },
        })
      );

      apiFilter = [];
    } else if (Object.keys(selectedOptions).length > 0 && filterRemoved) {
      dispatch(
        CustomerInfo({
          request: "customers",
          employeeId: loggedInUser?.userId,
          rowsPerPage,
          page,
          dist: null,
          sortBy: {
            field: sortBy.orderBy,
            direction: sortBy.order,
          },
        })
      );
    }
  }, [selectedOptions, rowsPerPage, page]);

  useEffect(() => {
    if (customerDetails?.ok) {
      if (selectedRowID != null && navigateToPage === "details") {
        navigate(`/main/customer/details/${selectedRowID}`);
      } else if (navigateToPage === "addCustomer") {
        navigate("/main/addCustomer", {
          state: {
            navigatedFor: "editCustomer",
            editCustomerId: selectedRowID,
            navigateTo: "customers",
            redirectTo: "customers",
          },
        });
      }
    }
  }, [customerDetails]);

  useEffect(() => {
    let loggedInUser = {};
    loggedInUser = JSON.parse(localStorage.getItem("loggedInUser"));

    if (Object.keys(deleteUser)?.length && deleteUser?.ok && deleteUser?.payload) {
      if (Object.keys(loggedInUser).length) {
        if (loggedInUser?.type === "edge") {
          dispatch(DropdownData());
          dispatch(
            CustomerInfo({
              request: "customers",
              employeeId: loggedInUser?.userId,
              rowsPerPage,
              page,
              dist: apiFilters,
              sortBy: {
                field: sortBy.orderBy,
                direction: sortBy.order,
              },
            })
          );
        }
      }
      dispatch(ResetDeleteUser({}));
    }
  }, [deleteUser]);

  useEffect(() => {
    let loggedInUser = {};

    loggedInUser = JSON.parse(localStorage.getItem("loggedInUser"));

    if (Object.keys(disableCustomer).length && disableCustomer?.ok && disableCustomer?.payload) {
      if (Object.keys(loggedInUser).length) {
        if (loggedInUser?.type === "edge") {
          dispatch(DropdownData());
          dispatch(
            CustomerInfo({
              request: "customers",
              employeeId: loggedInUser?.userId,
              rowsPerPage,
              page,
              dist: apiFilters,
              sortBy: {
                field: sortBy.orderBy,
                direction: sortBy.order,
              },
            })
          );
        }
      }
      dispatch(ResetDisableCustomer({}));
    }
  }, [disableCustomer]);

  useEffect(() => {
    const Data = [];
    if (
      customerInfo != null &&
      Object.keys(customerInfo).length &&
      customerInfo.ok &&
      customerInfo?.payload?.rows?.length
    ) {
      customerInfo?.payload?.rows?.map((customer) => {
        Data.push({
          id: customer.customerId,
          Profile_Picture: (
            <Box display="flex" alignItems="flex-end" justifyContent="flex-end" ml={0} pl={0} sx={{ ml: 0, pl: 0 }}>
              <AvatarIcon
                shape="circle"
                size={{
                  width: "8vh",
                  height: "8vh",
                  borderRadius: "50%",
                }}
                source={`${baseUrl}/customer/${customer.customerId}/logo?id=${customer?.pictureVersion}&variant=thumbnail`}
              />
            </Box>
          ),
          Company_Name: (
            <ProfleWithIcon
              type="textOnly"
              data={{
                name: getValue(customer.companyName),
                role: getValue(customer.industry),
              }}
            />
          ),
          Contact_Email: <Typography variant="h3">{getValue(customer.contactEmail)}</Typography>,
          Total_Employees: (
            <Typography variant="h3" textAlign="center">
              {currencyFormat(getValue(customer?.totalEmployees))}
            </Typography>
          ),
          Last_Payment: (
            <Typography variant="h3" textAlign="center">
              {getCurrencySymbol("en-US", "USD", currencyFormat(getValue(customer?.lastPayment)))}
            </Typography>
          ),
          paymentMethodType: (
            <Typography fontSize="12px" fontFamily="Poppins-Medium" textAlign="center">
              {customer?.paymentMethodType
                ? customer?.paymentMethodType.replace(/_/g, " ").replace(/\b\w/g, (c) => c.toUpperCase())
                : "-"}
            </Typography>
          ),
          Payment_Date: (
            <Typography variant="h3" textAlign="center">
              {convertDate(customer?.paymentDate)}
            </Typography>
          ),
          On_Boarding_Date: (
            <Typography variant="h3" align="center">
              {convertDate(customer?.onboardingDate)}
            </Typography>
          ),
          action: (
            <>
              <EditCustomer
                selectedId={customer.customerId}
                handleSelectedRow={handleSelectedRow}
                navigateTo="addCustomer"
              />
              <DeleteCustomer
                selectedId={customer.customerId}
                companyName={customer.companyName}
                handleDeleteRow={handleDeleteRow}
                title="Customer"
                customerStatus={customer?.status}
                type="edit"
                totalEmployees={customer?.totalEmployees}
              />

              <SendActivationEmail
                status={customer?.status}
                selectedId={customer.customerId}
                SendCustomerActivationEmail={SendCustomerActivationEmail}
                navigateTo="addCustomer"
              />
            </>
          ),
          Status: (
            <Button
              variant="contained"
              disableElevation
              sx={{
                backgroundColor:
                  customer?.status === "enabled"
                    ? "assigned.light"
                    : customer?.status === "disabled"
                      ? "unAssigned.light"
                      : "pending.light",

                color:
                  customer?.status === "enabled"
                    ? "assigned.main"
                    : customer?.status === "disabled"
                      ? "unAssigned.main"
                      : "pending.main",

                fontWeight: "bold",
                borderRadius: 2,
                ml: -4,
                width: "90px",
                height: "27px",

                "&:hover": {
                  backgroundColor:
                    customer?.status === "enabled"
                      ? "assigned.dark"
                      : customer?.status === "disabled"
                        ? "unAssigned.dark"
                        : "pending.dark",
                },
              }}
            >
              <Typography fontSize="12px" fontFamily="Poppins-Medium">
                {/* {getValue(customer.status)} */}
                {customer?.status === "enabled"
                  ? "Activated"
                  : customer?.status === "disabled"
                    ? "Deactivated"
                    : customer?.status === "new"
                      ? "New"
                      : customer?.status === "churned"
                        ? "Churned"
                        : "-"}
              </Typography>
            </Button>
          ),
        });
      });
    }

    setTableRows(Data);
  }, [customerInfo]);

  useEffect(() => {
    let newCustomers = [];

    if (getCustomerList?.ok) {
      newCustomers = getCustomerList?.payload?.map((data) => ({
        key: data.companyName,
        value: data.companyName,
      }));
    }

    setDropdownData_((prev) => ({ ...prev, status: StatusOption, customers: newCustomers }));
  }, [getCustomerList]);

  useEffect(() => {
    if (debounceSearchCustomer?.length >= 3) {
      dispatch(
        GetCustomersList({
          customerName: debounceSearchCustomer,
          loaderFlag: false,
        })
      );
    } else {
      dispatch(ResetGetCustomersList({}));
    }
  }, [debounceSearchCustomer]);

  useEffect(() => {
    const paymentMethodType = [
      { key: "ACH", value: "ACH" },
      { key: "CREDIT_CARD", value: "Credit Card" },
      { key: "CHEQUE", value: "Check" },
      { key: "MISSING", value: "Missing" },
    ];

    dispatch(ResetDeleteUser({}));
    dispatch(ResetGetCustomerPaymentById({}));
    dispatch(ResetGetCustomersList({}));
    dispatch(ResetDisableCustomer({}));

    setSelectedOptions({ status: StatusOption?.filter((option) => option.key === (statusFromUrl || "enabled")) });

    setDropdownData_((prev) => ({ ...prev, status: StatusOption, paymentMethodType }));

    return () => {
      dispatch(ResetCustomerInfo({}));
    };
  }, []);

  useEffect(() => {
    if (paymentMethodTypeUrl) {
      setSelectedOptions(() => ({
        paymentMethodType: [
          {
            key: "MISSING",
            value: "Missing",
          },
        ],
      }));
    }
  }, [paymentMethodTypeUrl]);
  return (
    <>
      {(loadingCustomerDetails || loadingCustomerInfo || loadingDeleteUser || loadingDisableCustomer) && <PageLoader />}
      <Grid container direction="column" rowSpacing={3}>
        <Grid item>
          <Grid
            item
            container
            direction="row"
            justifyContent="flex-end"
            columnSpacing={3}
            style={{
              left: "-80px",
              marginTop: "-25px",
            }}
          >
            <Grid item>
              {Object.keys(selectedOptions).map((key) => {
                return selectedOptions[key]?.map((option) => {
                  return (
                    <Chip
                      key={option.key}
                      label={
                        <Typography variant="h6" fontWeight="300">
                          {option.value}
                        </Typography>
                      }
                      clickable
                      sx={{
                        bgcolor: "#D3E0F4",
                        mx: 0.5,
                        height: 26,
                        borderRadius: "10px",
                        color: "#1161D5",
                        "& .MuiChip-deleteIcon": {
                          color: "#3C7DDC",
                          fontSize: "1.0rem",
                        },
                      }}
                      onDelete={(e) => handleDelete(e, key, option)}
                    />
                  );
                });
              })}
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <DataTable
            rowsData={tableRows}
            selectedOptions={apiFilters}
            resetPage={resetPage}
            totalCount={customerInfo?.payload?.count ? customerInfo.payload.count : 0}
            tableheadCells={tableheadCells}
            title={`Your Customers (${customerInfo?.payload ? customerInfo?.payload?.count : 0})`}
            exportButton={
              <Button
                disableElevation
                variant="contained"
                id="export-customer"
                fullWidth
                sx={{
                  width: "140px",
                  height: "35px",
                  borderRadius: "5px",
                  color: "white",
                }}
                onClick={handleExport}
              >
                <Typography variant="h6" color="white">
                  Export
                </Typography>
              </Button>
            }
            subTitle={null}
            toolBar
            titleIcon={totalEmployeesIcon}
            slectedRowNavigation="/main/details"
            handleRowsPerPage={handleRowsPerPage}
            handleSelectedRow={handleSelectedRow}
            openDetailsPage="Company_Name"
            handleRequestSortBy={handleRequestSortBy}
            sortByName="Company_Name"
            filterButton={
              <Box id="filter-btn" sx={{ position: "relative" }} ref={openFilterMenu}>
                <FilterListIcon onClick={handleClick} />
                <Grow
                  in={open}
                  style={{ transformOrigin: "right 0 0 " }}
                  {...(open ? { timeout: 350 } : {})}
                  unmountOnExit
                >
                  <Paper elevation={3} sx={styles}>
                    <Filter
                      searchCustomer={searchCustomer}
                      data={DropdownData_}
                      selectedOptions={selectedOptions}
                      handleReset={handleReset}
                      callbackFromMultiSelect={callbackFromMultiSelect}
                    />
                  </Paper>
                </Grow>
              </Box>
            }
            AddNewRowButton={
              <Button
                id="add-customer"
                disableElevation
                variant="contained"
                fullWidth
                sx={{
                  width: "145px",
                  height: "35px",
                  borderRadius: "5px",
                  color: "white",
                }}
                onClick={() =>
                  navigate("/main/addCustomer", {
                    state: {
                      navigatedFor: "addCustomer",
                      navigateTo: "customers",
                      redirectTo: "customers",
                    },
                  })
                }
              >
                <Typography variant="h6" color="white">
                  Add Customer
                </Typography>
              </Button>
            }
          />
        </Grid>
      </Grid>
    </>
  );
}

export default Customers;
