import { Alert, Box, Button, Collapse, Grid, IconButton, Paper, Snackbar, Stack, Typography } from "@mui/material";
import React, { useState } from "react";
import { Line } from "rc-progress";
import Upload from "rc-upload";
import { BsFileEarmarkCheck, BsFileEarmarkPlus } from "react-icons/bs";
// import CloseIcon from "@mui/icons-material/Close";
import { baseUrl } from "../../util/APIBaseUrl";
import { makeRequest } from "../../util/ApiUploadFile";
import { getError } from "../Screens/CommonUtils";
import getRequest from "../../util/APIHelperGet";
import postRequest from "../../util/APIHelperPost";

function FileUpload(props) {
  const { onUploadSuccess } = props;

  //component states
  const [fileName, setFileName] = useState(null);
  const [fileSize, setFileSize] = useState();
  const [file, setFile] = useState();
  const [openAlert, setOpenAlert] = React.useState(false);
  const [successAlert, setSuccessAlert] = React.useState(false);
  const [percentage, setPercentage] = useState(0);
  const [isUploading, setIsUploading] = useState(false);
  const [uploadRes, setUploadRes] = useState({});

  //methods
  const uploadFileProps = {
    accept: ".csv",
    beforeUpload(file) {
      setFileName(null);
      // Start upload
      setIsUploading(true);
      // Set file details
      setFile(file);
      setFileName(file.name);
      setFileSize(Math.floor(file.size));
    },
    onSuccess(res) {
      // Finish upload
      if (res.success) {
        setOpenAlert(false);
        setIsUploading(false);
        setUploadRes(res);
        onUploadSuccess(true);
        setSuccessAlert(true);
      } else {
        setUploadRes(res);
        setOpenAlert(true);
        setIsUploading(false);
      }
    },
    onProgress(step) {
      // Update progress
      setPercentage(Math.round(step.percent));
    },
    onError(err) {
      //   setSuccessAlert(true);
      setOpenAlert(true);
    },
    customRequest: async ({ onError, onProgress, onSuccess, withCredentials }) => {
      //

      const fmData = new FormData();
      fmData.append("file", file);

      const config = {
        path: `${baseUrl}/import?type=employee`,
        method: "POST",
        body: fmData,

        onUploadProgress: (event) => {
          const percent = Math.floor((event.loaded / event.total) * 100);

          setPercentage(percent);
          // if (percent === 100) {
          //     setTimeout(() => setPercentage(0), 1000);
          // }
          onProgress({ percent: (event.loaded / event.total) * 100 });
        },
      };

      try {
        const response = await makeRequest(config, true, fmData);
        onSuccess(response);
      } catch (err) {
        onError({ err });
      }
    },
  };

  const handleDownloadErrors = async (errors) => {
    let body = JSON.stringify({ errors });
    const response = await postRequest(`${baseUrl}/download`, body, true, "POST", null, "export");

    if (!response?.ok) {
      return;
    }
    const url = window.URL.createObjectURL(response?.payload);
    const link = document.createElement("a");
    link.style.display = "none";
    link.href = url;
    link.setAttribute("download", "Employees.txt");
    // link.download = "Employees";
    document.body.appendChild(link);
    link.click();
    window.URL.revokeObjectURL(url);
    // alert("your file has downloaded!");
  };

  const getSuccessMsg = (errorCount) => {
    if (errorCount) {
      return "There are errors while importing the employee. For detailed information please download the error log";
    }
    return "Successfully able to import all the employee updates.";
  };

  return (
    <>
      <Grid item xs={12} pr={5} pb={2} display="flex" justifyContent="right">
        <Upload {...uploadFileProps}>
          <Button
            id="importEmployees"
            variant="contained"
            startIcon={<BsFileEarmarkPlus color="white" size="16px" />}
            sx={{ backgroundColor: "secondary.main" }}
            disableElevation
          >
            <Typography color="white" id="import-employees" variant="h6">
              Import Employees
            </Typography>
          </Button>
        </Upload>
      </Grid>
      {fileName && (
        <Paper elevation={1}>
          <Grid
            Container
            display="flex"
            flexDirection="row"
            // columnGap={1.2}
            px={5}
            pt={1.5}
            // alignItems="center"
            // pb={openAlert || successAlert ? 0 : 2.5}
            // mb={openAlert || successAlert ? -2.5 : 0.5}
            xs={12}
          >
            <Grid item xs={4} display="flex" flexDirection="row">
              <BsFileEarmarkCheck fontSize="28px" />
              <Typography variant="h6" pl={2}>
                {fileName}
              </Typography>
            </Grid>

            <Grid item container display="flex" flexDirection="column" xs={7}>
              <Grid item container columnGap={2}>
                <Grid item xs={7}>
                  <Line
                    percent={percentage}
                    strokeWidth={3}
                    trailWidth={3}
                    trailColor="#FFF"
                    strokeColor={isUploading ? "#F64A14" : !uploadRes?.payload?.success ? "#F64A14" : "#40CAC1"}
                  />
                </Grid>
                <Grid item xs={3}>
                  <Typography variant="h6">
                    {isUploading ? `Uploading ${percentage}% ` : !uploadRes?.payload?.success ? "Failed" : `Finished`}
                  </Typography>
                </Grid>
              </Grid>

              <Grid item textAlign="start">
                {Object.keys(uploadRes).length ? (
                  <Box
                    sx={{
                      // border: "1px solid red",
                      background: !uploadRes?.payload?.success ? "#F64A14" : "#40CAC1",
                      // width: "16.5rem",
                      width: "60%",
                      py: 1.5,
                      px: 1.5,
                      // mt: 2,
                      borderRadius: "12px",
                    }}
                  >
                    <Stack direction="row" justifyContent="space-between" alignItems="center">
                      <Typography variant="h6" color="white">
                        {" "}
                        {!uploadRes?.payload?.success ? (
                          "some thing went wrong"
                        ) : (
                          <div
                            dangerouslySetInnerHTML={{
                              __html: `${getSuccessMsg(uploadRes?.payload?.errors?.count)} 
                              <br />  Successfully Imported: ${uploadRes?.payload.imported}  Successfully Updated: ${
                                uploadRes?.payload.updated
                              }`,
                            }}
                          />
                        )}
                      </Typography>
                      {uploadRes?.payload?.errors?.count ? (
                        <Button
                          id="downlaod Erros"
                          variant="contained"
                          // startIcon={
                          //   <BsFileEarmarkPlus color="white" size="16px" />
                          // }
                          sx={{ backgroundColor: "secondary.main" }}
                          disableElevation
                          onClick={() => handleDownloadErrors(uploadRes?.payload?.errors?.data)}
                        >
                          <Typography color="white" variant="h6">
                            Downlaod
                          </Typography>
                        </Button>
                      ) : null}
                    </Stack>
                  </Box>
                ) : null}
              </Grid>
            </Grid>
            <Grid item xs={1}>
              <Typography color="primary" variant="h6">{`${fileSize} KB`}</Typography>
            </Grid>
          </Grid>
        </Paper>
      )}
    </>
  );
}

export default FileUpload;
