import { call, put, takeEvery } from "redux-saga/effects";
//actions
import {
    EmployeeCountInfoSuccess,
    EmployeeCountInfoFailure,
} from "../../actions/EmployeesCountInfo";
//constant
import { EMPLOYEES_COUNT_INFO } from "../../constants/EmployeesCountInfo";
//request type
import getRequest from "../../../util/APIHelperGet";
import { baseUrl } from "../../../util/APIBaseUrl";

export const fetchEmployeesCountInfoRequest = async (actions) => {
    // eslint-disable-next-line no-return-await
    return await getRequest(`${baseUrl}/customer/summary`)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return error;
        });
};

function* fetchEmployeesCountInfo(params) {
    try {
        let response = yield call(fetchEmployeesCountInfoRequest, params);
        yield put(EmployeeCountInfoSuccess(response));
    } catch (error) {
        console.error(error);
        yield put(EmployeeCountInfoFailure(error));
    }
}

export default function* rootSaga() {
    yield takeEvery(EMPLOYEES_COUNT_INFO, fetchEmployeesCountInfo);
}
