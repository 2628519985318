import React from "react";

function RedDotIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="10" viewBox="0 0 16 10" fill="none">
      <circle cx="7" cy="5" r="5" fill="#DE3730" />
    </svg>
  );
}

export default RedDotIcon;
