import {
  RESER_CURRENT_PAYROLL,
  CURRENT_PAYROLL,
  CURRENT_PAYROLL_SUCCESS,
  CURRENT_PAYROLL_FAILURE,
} from "../../constants/Payroll/CurrentPayroll";

// for user authentication
export const ResetCurrentPayroll = (data) => {
  return {
    type: RESER_CURRENT_PAYROLL,
    payload: data,
  };
};

export const CurrentPayroll = (data) => {
  return {
    type: CURRENT_PAYROLL,
    payload: data,
  };
};
export const CurrentPayrollSuccess = (payload) => {
  return {
    type: CURRENT_PAYROLL_SUCCESS,
    payload,
  };
};
export const CurrentPayrollFailure = (payload) => {
  return {
    type: CURRENT_PAYROLL_FAILURE,
    payload,
  };
};
