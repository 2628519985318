import React from "react";
//material ui
import { Button, FormControl, Grid, TextField, Toolbar, Typography, Alert, List, ListItem } from "@mui/material";

import { Box } from "@mui/system";

//icons
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

//enum
import { SignInScreenPermissions } from "../SignIn.enum";

//router
import { useForm } from "react-hook-form";

//redux dispatch
import { useSelector, useDispatch } from "react-redux";
import { ResetForgotPassword, ForgotUserPassword } from "../../../../store/actions/ForgotPassword";
import TextFieldV2 from "../../../common/TextFieldV2/TextFieldV2";
import { EmailOutlined } from "@mui/icons-material";

// images
import loginImage from "../../../Icons/login-image.png";
import PageLoader from "../../../common/PageLoader";
import TermsAndPolicy from "../../../common/TermAndPolicy";
import { USER_TYPES } from "constants";

//constants
const userCredentials = {
  email: "",
  password: "",
  loginPortal: null,
};

function ForgotPassword(props) {
  //props
  const { changeScreen, pathname } = props;
  const { handleSubmit } = useForm();

  const dispatch = useDispatch();

  //component state
  const [openAlert, setOpenAlert] = React.useState(false);
  const [userCredential, setUserCredential] = React.useState(userCredentials);
  const [loginButtonClicked, setLoginButtonClicked] = React.useState(false);

  // redux state
  const forgotPasswordInfo = useSelector((state) => state.ForgotPassword.forgotPasswordInfo);
  const loadingForgotPasswordInfo = useSelector((state) => state.ForgotPassword.loadingForgotPasswordInfo);

  // functions

  const handleInputChange = (event) => {
    const value = event.target.value;
    setUserCredential({
      ...userCredential,
      [event.target.name]: value,
    });
  };

  const getError = (errors) => {
    if (errors != null && errors != undefined) {
      return errors.map((error, index) => {
        return (
          <List
            sx={{
              listStyleType: "disc",
              paddingRight: "0px !important",
              pl: 3,
              "& .MuiListItem-root": {
                paddingRight: "0px",
                paddingLeft: "0px",
                paddingTop: "0px",
                paddingBottom: "0px",
              },

              "&.MuiList-root": {
                paddingTop: "0px",
                paddingBottom: "0px",
              },
            }}
          >
            <ListItem
              sx={{
                display: "list-item",
              }}
            >
              {(error?.msg ? error.msg : error?.description ? error.description : "Undefined Error") +
                " " +
                (error?.param ? error?.param : " ")}
            </ListItem>
          </List>
        );
      });
    } else {
      return null;
    }
  };

  const checkError = (field, view) => {
    if (loginButtonClicked) {
      if (view != "textField") {
        if (userCredential[field]?.key != null) {
          return false;
        } else return true;
      } else {
        if (userCredential[field] != "") {
          return false;
        } else return true;
      }
    }
  };

  const loginHandler = (event) => {
    setLoginButtonClicked(true);
    if (userCredential.email != "") {
      setOpenAlert(false);
      dispatch(
        ForgotUserPassword({
          email: userCredential?.email,
          type: pathname,
        })
      );
    } else {
      setOpenAlert(true);
    }
  };

  //methods

  React.useEffect(() => {
    dispatch(ResetForgotPassword({}));
  }, []);

  return (
    <>
      {loadingForgotPasswordInfo && <PageLoader />}
      <Grid
        container
        direction="row"
        justifyContent="space-evenly"
        alignItems="center"
        sx={{ backgroundColor: "#FFFFFF", overflowY: "auto" }}
      >
        <Grid
          item
          xl={7}
          lg={7}
          md={7}
          sm={12}
          xs={12}
          display="flex"
          flexDirection="row"
          justifyContent="center"
          alignItems="center"
        >
          <form onSubmit={handleSubmit(loginHandler)}>
            <Grid item container sx={{ maxWidth: "330px" }}>
              <Grid item container direction="column" rowSpacing={2}>
                <Grid item sx={{ cursor: "pointer" }}>
                  <Box
                    display="flex"
                    flexDirection="row"
                    onClick={() => {
                      changeScreen(SignInScreenPermissions.SIGN_IN);
                    }}
                  >
                    <ArrowBackIcon textAlign="left" fontSize="12px" />
                    <Typography
                      fontSize="13px"
                      sx={{
                        fontFamily: "Poppins-Medium !important",
                        paddingLeft: "5px",
                      }}
                    >
                      Back
                    </Typography>
                  </Box>
                </Grid>
                <Grid item>
                  <Box
                    component="img"
                    sx={{
                      height: "auto",
                      maxWidth: "100%",
                    }}
                    alt="edge"
                    src="assets/Images/edge-logo.svg"
                  />
                </Grid>
                <Grid item>
                  <Typography
                    sx={{
                      fontFamily: "Poppins-Bold !important",
                      color: "#000000",
                      fontSize: "32px",
                      fontWeight: "600",
                      lineHeight: "44px",
                      marginTop: "20px",
                      "@media screen and (min-width: 912px)": {
                        marginTop: "0px",
                      },
                    }}
                  >
                    Password Recovery
                  </Typography>
                </Grid>

                <Grid item>
                  <FormControl variant="outlined" fullWidth error>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        border: "1px solid #B2B4E2",
                        borderRadius: "12px",
                        backgroundColor: "#FFFFFF",
                      }}
                    >
                      <EmailOutlined sx={{ color: "#3D3E5C", marginLeft: "18px" }} />
                      <TextFieldV2
                        label="Email Address"
                        variant="filled"
                        name="email"
                        id="email"
                        type="text"
                        onChange={handleInputChange}
                        error={checkError("email", "textField")}
                        sx={{
                          width: "100%",
                        }}
                      />
                    </Box>
                  </FormControl>

                  {forgotPasswordInfo?.ok != true ? (
                    <Typography
                      fontSize="13px"
                      variant="h3Lite"
                      sx={{
                        fontFamily: "Poppins-Medium !important",
                        paddingLeft: "5px",
                        color: "grey !important",
                      }}
                    >
                      Recovery password will be send to this email
                    </Typography>
                  ) : null}
                </Grid>

                <Grid item>
                  {forgotPasswordInfo?.ok == false || forgotPasswordInfo?.ok ? (
                    <Alert
                      color="success"
                      open={openAlert}
                      autoHideDuration={3500}
                      variant="filled"
                      icon={false}
                      sx={{
                        backgroundColor: forgotPasswordInfo?.ok ? "#38E25D" : "#F64A14",
                        padding: "initial",
                        px: "5px",
                        fontSize: "12px",
                        textAlign: "center",

                        borderRadius: "5px !important",
                        color: "#ffffff !important",
                        "&.MuiPaper-root": {
                          color: "#ffffff !important",
                        },
                      }}
                    >
                      <Typography
                        sx={{
                          fontFamily: "Poppins-Regular !important",
                          fontWeight: "400",
                          fontSize: "13px",
                        }}
                      >
                        {forgotPasswordInfo?.ok == false
                          ? getError(forgotPasswordInfo?.payload?.error)
                          : forgotPasswordInfo?.ok
                          ? "Email sent "
                          : "Enter all information before proceeding"}
                      </Typography>
                    </Alert>
                  ) : null}
                </Grid>

                <Grid item>
                  <Button
                    type="submit"
                    variant="contained"
                    fullWidth={true}
                    sx={{
                      backgroundColor: "#3D43BB",
                      height: "54px",
                      fontFamily: "Poppins-Regular !important",
                      fontWeight: "500",
                      lineHeight: "20px",
                      borderRadius: "99px",
                      fontSize: "16px",
                      color: "white",
                      boxShadow: "0px 1px 14px rgba(2, 0, 110, 0.12)",
                    }}
                  >
                    {forgotPasswordInfo?.ok ? "Resend Email" : "Send Email"}
                  </Button>
                  <TermsAndPolicy userType={pathname === "customer" ? USER_TYPES.CUSTOMER : USER_TYPES.EMPLOYEE} />
                </Grid>

                <Grid item textAlign="center"></Grid>
                <Grid item textAlign="center">
                  <Typography variant="h3Lite" fontSize="9px">
                    {process.env.REACT_APP_NAME}
                    {" " + process.env.REACT_APP_VERSION}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </form>
        </Grid>
        <Grid
          item
          xl={5}
          lg={5}
          md={5}
          sm={12}
          xs={12}
          backgroundColor="#3D43BB"
          minHeight="100vh"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <Box
            component="img"
            sx={{
              height: "auto",
              maxWidth: "528px",
              width: "100%",
              objectFit: "contain",
              backgroundColor: "#3D43BB",
              // override on screens greater than 912px
              "@media screen and (min-width: 912px)": {
                objectFit: "cover",
                maxWidth: "400px",
                width: "100%",
              },
            }}
            alt="The house from the offer."
            src="assets/Images/login-image.png"
          />
        </Grid>
      </Grid>
    </>
  );
}

export default ForgotPassword;
