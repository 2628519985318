import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import axios from "axios";

import Text from "components/common/Text";
import TextCheckBox from "components/common/TextCheckBox";
import Button from "components/common/Button";
import DragAndDrop from "components/common/DragAndDrop";
import { AlertType } from "components/common/Snackbar";
import SelectedFile from "components/common/DragAndDrop/SelectedFile";
import Modal from "components/common/Modal";

import { getUploadDocumentSignedUrl } from "services/BambooHrTabs/documents";
import { showSnackbar } from "store/actions/Utility";

import "../Document.scss";

function DocumentModal({ type, open, onClose, onSave, modalData, documentType, isLoading }) {
  const dispatch = useDispatch();
  const params = useParams();

  const [fileName, setFileName] = useState("");
  const [shareWithEmployee, setShareWithEmployee] = useState(false);
  const [shareWithCustomer, setShareWithCustomer] = useState(false);
  const [fileUploadStatus, setFileUploadStatus] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [updatedFileName, setUpdatedFileName] = useState(null);
  const [uploadProgress, setUploadProgress] = useState(0);

  useEffect(() => {
    if (modalData) {
      setFileName(modalData?.documentName);
      setShareWithEmployee(modalData?.shareWithEmployee);
      setShareWithCustomer(modalData?.shareWithCustomer);
    } else {
      setFileName("");
      setShareWithEmployee(false);
      setShareWithCustomer(false);
    }
  }, [modalData]);

  useEffect(() => {
    if (open && type === "add") {
      setFileName("");
      setFileUploadStatus(false);
    }
  }, [open, type]);

  const handleInputField = (e) => {
    setFileName(e.target.value);
  };

  const isSaveAllowed = () => {
    if (type === "add") {
      return fileUploadStatus && fileName;
    }
    return !!fileName;
  };

  const handleFileUpload = async (selectedFiles) => {
    if (selectedFiles.length === 0) {
      setFileUploadStatus(false);
    } else {
      setFileUploadStatus(false);
      const file = selectedFiles[0];
      setSelectedFile(file);

      const unixTimeStamp = Math.floor(Date.now() / 1000);
      const newFileName = `${unixTimeStamp}_${file.name}`;

      setUpdatedFileName(newFileName);
      let response = null;
      try {
        response = await getUploadDocumentSignedUrl(params?.id, newFileName, file.type, file.size);
      } catch (error) {
        dispatch(
          showSnackbar({
            type: AlertType.error,
            message: error?.message,
            dispatch,
          })
        );
      }

      try {
        const options = {
          headers: {
            "Content-Type": file.type,
          },
          onUploadProgress: (progressEvent) => {
            const percentage = Math.round((progressEvent.loaded * 100) / progressEvent.total);
            setUploadProgress(percentage);
          },
        };
        await axios.put(response?.url, file, options);

        setFileUploadStatus(true);
      } catch (error) {
        dispatch(
          showSnackbar({
            type: AlertType.error,
            message: error?.message,
            dispatch,
          })
        );
      }
    }
  };

  const handleSave = async () => {
    await onSave({ fileName, updatedFileName, selectedFile, shareWithEmployee, shareWithCustomer });
    setFileName("");
    setShareWithCustomer(false);
    setShareWithEmployee(false);
    onClose();
  };
  return (
    <Modal
      width="652px"
      title={type === "add" ? "Add Document" : "Edit Document"}
      open={open}
      onClose={() => onClose()}
    >
      <div className="mt-15 mb-1">
        {modalData ? (
          <SelectedFile
            files={[
              {
                id: 1,
                fileName: `${modalData?.documentName}${modalData?.documentExtension}`,
                error: "",
                size: modalData?.size,
              },
            ]}
            loading={false}
            showGreenTick={false}
            showButtons={false}
          />
        ) : (
          <DragAndDrop
            loading={!fileUploadStatus}
            helperText="PDF, PNG, JPEG (max. 3MB)"
            supportedFormats={["application/pdf", "image/png", "image/jpeg"]}
            maxFileSize={3 * 1024 * 1024}
            onFileSelect={handleFileUpload}
            uploadProgress={uploadProgress}
            isFileUploaded={fileUploadStatus}
          />
        )}
      </div>

      <Text
        className="mb-1"
        name="documentName"
        label="Document name"
        placeholder="e.g. Workflow Guide"
        fontSize="16px"
        fontWeight="400"
        onChange={handleInputField}
        value={fileName}
      />
      <div className="flex flex-row mt-1 pb-15">
        <TextCheckBox
          text="Share With Employee"
          checked={shareWithEmployee}
          onChange={(e) => setShareWithEmployee(e.target.checked)}
        />
        {documentType !== "Signed Documents" && (
          <div className="ml-5">
            <TextCheckBox
              text="Share With Customer"
              checked={shareWithCustomer}
              onChange={(e) => setShareWithCustomer(e.target.checked)}
            />
          </div>
        )}
      </div>
      <div className="flex flex-row justify-end mt-05 mb-05">
        <Button label="Cancel" variant="outlined" onClick={() => onClose()} size="large" />
        <div className="ml-05">
          <Button label="Save" isDisabled={!isSaveAllowed()} onClick={handleSave} isLoading={isLoading} size="large" />
        </div>
      </div>
    </Modal>
  );
}

export default DocumentModal;
