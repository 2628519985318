import React from "react";
import { useNavigate } from "react-router-dom";

import { Typography } from "@mui/material";

import Button from "components/Button/Button";

import GreenCheck from "assets/images/green_check.svg";

import "./AddAchInfo.scss";

function AddAchSuccessScreen({ setOpenEch, newCustomer }) {
  const navigate = useNavigate();
  return (
    <div className="ach-success-screen">
      <img src={GreenCheck} alt="checkmark" className="green-check-icon" />
      <h2 className="payment-method-success-title">Payment method added successfully!</h2>
      <h3 className="payment-method-success-description">
        You can change these details later in the Payment Method page.
      </h3>
      <Button
        type="button"
        variant="primary"
        size="lg"
        id="add-accounts-btn"
        className="add-accounts-btn"
        onClick={() => {
          if (newCustomer) {
            navigate("/main/dashboard");
          } else {
            setOpenEch(false);
          }
        }}
      >
        <Typography variant="h6" color="white">
          Continue
        </Typography>
      </Button>
    </div>
  );
}

export default AddAchSuccessScreen;
