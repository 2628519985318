import {
  RESET_DELETE_USER,
  DELETE_USER,
  DELETE_USER_SUCCESS,
  DELETE_USER_FAILURE,
} from "../../constants/Settings/DeleteUser";

// for add employee in admin workforce table

export const ResetDeleteUser = (data) => {
  return {
    type: RESET_DELETE_USER,
    payload: data,
  };
};

export const DeleteUser = (data) => {
  return {
    type: DELETE_USER,
    payload: data,
  };
};
export const DeleteUserSuccess = (payload) => {
  return {
    type: DELETE_USER_SUCCESS,
    payload,
  };
};
export const DeleteUserFailure = (payload) => {
  return {
    type: DELETE_USER_FAILURE,
    payload,
  };
};
