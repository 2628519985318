/* eslint-disable react/jsx-key */
import React from "react";
import { Avatar, Grid, Typography } from "@mui/material";
import { capitalizeFirstLetter } from "../Screens/CommonUtils";

function gridFunction(data, shape, icon, height, width, type, source, textColor) {
  return (
    <Grid container direction="row" alignItems="center" columnSpacing={2}>
      {type != "textOnly" ? (
        <Grid item>
          {icon || (
            <Avatar
              variant={shape == "rounded" ? "rounded" : "circle"}
              src={source || null}
              sx={[height && { height }, width && { width }]}
            />
          )}
        </Grid>
      ) : null}
      <Grid item sx={{ textAlign: "start" }}>
        <Grid item container direction="column" textAlign="start">
          <Typography variant={textColor ? "h4" : "h3"} color={textColor || "black"} fontSize="13px">
            {capitalizeFirstLetter(data?.name)}
          </Typography>
          <Typography
            variant="paragraph"
            color={textColor || "#080d467a"}
            style={{
              paddingTop: textColor ? "2px" : "4px",
              fontSize: "13px",
            }}
          >
            {capitalizeFirstLetter(data?.role)}
          </Typography>

          {data?.joinDate ? (
            <Typography
              variant="h3"
              style={{
                paddingTop: "3px",
                color: "#060E64",
                fontSize: "13px",
              }}
            >
              {data.joinDate}
            </Typography>
          ) : null}

          {data?.country ? (
            <Typography
              variant="h4"
              style={{
                paddingTop: "3px",
                color: "#060E64",
                fontSize: "13px",
              }}
            >
              {data.country}
            </Typography>
          ) : null}

          {data?.status ? (
            <Typography
              variant="h3"
              sx={{
                paddingTop: "3px",
                color: "warning.main",
                fontSize: "13px",
              }}
            >
              {data.status}
            </Typography>
          ) : null}
        </Grid>
      </Grid>
    </Grid>
  );
}

function ProfleWithIcon(props) {
  const { data, shape, icon, height, width, type, source, textColor } = props;

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{data ? gridFunction(data, shape, icon, height, width, type, source, textColor) : null}</>;
}

export default ProfleWithIcon;
