import {
    RESET_GET_EMPLOYEE_PAYROLL_BY_ID,
    GET_EMPLOYEE_PAYROLL_BY_ID,
    GET_EMPLOYEE_PAYROLL_BY_ID_SUCCESS,
    GET_EMPLOYEE_PAYROLL_BY_ID_FAILURE,
  } from "../../constants/EmployeeSalaries/EmployeePayrollById";
  
  // for user authentication
  export const ResetGetEmployeePayrollById = (data) => {
    return {
      type: RESET_GET_EMPLOYEE_PAYROLL_BY_ID,
      payload: data,
    };
  };
  
  export const GetEmployeePayrollById = (data) => {
    return {
      type: GET_EMPLOYEE_PAYROLL_BY_ID,
      payload: data,
    };
  };
  export const GetEmployeePayrollByIdSuccess = (payload) => {
    return {
      type: GET_EMPLOYEE_PAYROLL_BY_ID_SUCCESS,
      payload,
    };
  };
  export const GetEmployeePayrollByIdFailure = (payload) => {
    return {
      type: GET_EMPLOYEE_PAYROLL_BY_ID_FAILURE,
      payload,
    };
  };
  