import { call, put, takeEvery } from "redux-saga/effects";
//actions
import {
  GetAllHolidaysSuccess,
  GetAllHolidaysFailure,
} from "../../actions/Holidays";
//constant
import { GET_ALL_HOLIDAYS } from "../../constants/Holidays";
//request type
import getRequest from "../../../util/APIHelperGet";

export const fetchAllHolidaysSalariesRequest = async (actions) => {
  // eslint-disable-next-line no-return-await

  return await getRequest(
    `${actions.payload.request}?` +
      new URLSearchParams({
        region: actions.payload.region,
        startDate: actions.payload.startDate,
        endDate: actions.payload.endDate,
      })
  )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

function* fetchAllHolidaysSalaries(params) {
  try {
    let response = yield call(fetchAllHolidaysSalariesRequest, params);
    yield put(GetAllHolidaysSuccess(response));
  } catch (error) {
    console.error(error);
    yield put(GetAllHolidaysFailure(error));
  }
}

export default function* rootSaga() {
  yield takeEvery(GET_ALL_HOLIDAYS, fetchAllHolidaysSalaries);
}
