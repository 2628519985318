import React, { useEffect, useState } from "react";
//materail ui
import {
    Avatar,
    Grid,
    IconButton,
    Button,
    Paper,
    Typography,
    Box,
} from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
// icons
import { BsCreditCard2Back } from "react-icons/bs";
import { FiCalendar } from "react-icons/fi";
import errorIcon from "../Icons/ErrorIcon.png";

function BonusDetails(props) {
    const { country, bonusFor, statusItem, bonusAmount } = props;

    return (
        <Paper elevation={5} sx={{ p: 1, height: "10vh" }}>
            <Grid
                item
                container
                direction="row"
                columnSpacing={2}
                rowSpacing={2}
                justifyContent="center"
                alignItems="center"
                height="100%"
                xs={12}
            >
                <Grid item pb={0}>
                    <BsCreditCard2Back fontSize="24px" />
                </Grid>
                <Grid item>
                    {/* <Box display="flex" flexDirection="row"> */}
                    {/* <img src={errorIcon} height={12} width={"auto"} /> */}

                    <Typography
                        variant="h5"
                        sx={{
                            fontSize: "15px",
                            // color: "warning.main",
                            pl: "5px",
                            pb: "3px",
                        }}
                        textAlign="start !important"
                    >
                        <b>{bonusAmount} $ </b> (1000 Rs)
                    </Typography>
                    {/* </Box> */}

                    <Typography
                        variant="h5"
                        sx={{ lineHeight: "12px", fontSize: "12px" }}
                        textAlign="start !important"
                    >
                        {bonusFor}
                    </Typography>
                    <Typography
                        variant="h6"
                        sx={{
                            fontFamily: "Poppins-Light",
                            color: "grey !important",
                        }}
                    >
                        {country}
                    </Typography>
                </Grid>
                {/* <Grid item xs={3} pt={3}>
                <Button
                    color="secondary"
                    onClick={() => {
                        navigate("/main/payroll");
                    }}
                    sx={{
                        height: "45px",
                        ml: "20px",
                        background: "#FFFFFF",
                        boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.08)",
                        borderRadius: "7px",
                    }}
                >
                    <FiCalendar fontSize="18px" />
                </Button>
            </Grid> */}
            </Grid>
        </Paper>
    );
}

export default BonusDetails;
