import React, { useState } from "react";
import "./../../ManageTimeoff/ManageTimeoff.css";
import EmployeeTakenLeaves from "./EmployeeTakenLeaves";
import EmployeeUpcommingHolidays from "./EmployeeUpcommingHolidays";
import EmployeeTimeOffRequests from "./EmployeeTimeOffRequests";
import "./EmployeeTimeOffDetails.css";
import { useDispatch } from "react-redux";
import { NEW_ACTIVITY } from "../EmployeeTimeoffNewActivity/Enum";

function EmployeeTimeOffDetails(props) {
  const [screenView, setScreenView] = useState(NEW_ACTIVITY.ALL_EMPLOYEES_REQUESTS);
  const [leaveTakenSuccessfully, setLeaveTakenSuccessfully] = useState(false);

  return (
    <div className="manage-timeoff-wrapper-admin ">
      <EmployeeTakenLeaves
        setLeaveTakenSuccessfully={setLeaveTakenSuccessfully}
        leaveTakenSuccessfully={leaveTakenSuccessfully}
      />

      {screenView === NEW_ACTIVITY.ALL_EMPLOYEES_REQUESTS && <EmployeeUpcommingHolidays portal="admin" />}
      <EmployeeTimeOffRequests
        setScreenView={setScreenView}
        screenView={screenView}
        leaveTakenSuccessfully={leaveTakenSuccessfully}
      />
    </div>
  );
}

export default EmployeeTimeOffDetails;
