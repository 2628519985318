import {
  Avatar,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Typography,
} from "@mui/material";
import React from "react";
//redux dispatch
import { useSelector, useDispatch } from "react-redux";
import { DeleteUser, ResetDeleteUser } from "../../../../store/actions/Settings/DeleteUser";
import { baseUrl } from "../../../../util/APIBaseUrl";
import { ManageUser } from "../../../../store/actions/Settings/ManageUser";
import { getValue } from "../../CommonUtils";
import { Box, textAlign } from "@mui/system";
import PageLoader from "components/common/PageLoader";

function RemoveUser(props) {
  const dispatch = useDispatch();
  //props
  const { openRemoveUser, editableUser, handleCloseRemoveUser, setOpenRemoveUser } = props;

  //component state
  const [profileUrl, setProfileUrl] = React.useState(null);

  //redux state
  const deleteUser = useSelector((state) => state.DeleteUser.deleteUser);
  const loadingDeleteUser = useSelector((state) => state.DeleteUser.loadingDeleteUser);

  //function
  const handleDeleteUser = (userId) => {
    setOpenRemoveUser(false);
    let loggedInUser = {};
    let deleteUserRequest = "";
    loggedInUser = JSON.parse(localStorage.getItem("loggedInUser"));

    if (userId) {
      if (loggedInUser?.type == "edge") {
        deleteUserRequest = `user/${userId}`;
      } else if (loggedInUser?.type == "customer") {
        deleteUserRequest = `customerUser/${userId}`;
      }
      dispatch(
        DeleteUser({
          request: deleteUserRequest,
        })
      );
    }
  };

  //method
  React.useEffect(() => {
    let loggedInUser = JSON.parse(localStorage.getItem("loggedInUser"));
    let userProfileUrl = null;
    if (
      editableUser &&
      Object.keys(editableUser).length &&
      Object.keys(loggedInUser).length &&
      loggedInUser.userId != undefined
    ) {
      if (loggedInUser?.type == "customer") {
        userProfileUrl = `${baseUrl}/customerUser/${editableUser?.userId}/logo?id=${editableUser?.pictureVersion}`;
      } else if (loggedInUser?.type == "edge") {
        userProfileUrl = `${baseUrl}/user/${editableUser?.userId}/logo?id=${editableUser?.pictureVersion}`;
      }

      setProfileUrl(userProfileUrl);
    }
  }, [editableUser]);

  React.useEffect(() => {
    let loggedInUser = {};
    let manageUserRequest = "";
    let userAdditionalInfo = JSON.parse(sessionStorage.getItem("userAdditionalInfo"));

    loggedInUser = JSON.parse(localStorage.getItem("loggedInUser"));

    if (Object.keys(deleteUser).length && deleteUser?.ok && deleteUser?.payload) {
      if (Object.keys(loggedInUser).length && loggedInUser.userId != undefined) {
        if (loggedInUser?.type == "customer") {
          manageUserRequest = `customer/${userAdditionalInfo?.customerId}/customerUsers`;
        } else if (loggedInUser?.type == "edge") {
          manageUserRequest = `users`;
        }

        dispatch(
          ManageUser({
            request: manageUserRequest,
            rowsPerPage: 15,
            page: 0,
            sortBy: {},
          })
        );
      }
      setOpenRemoveUser(false);
      dispatch(ResetDeleteUser({}));
    }
  }, [deleteUser]);

  return (
    <>
      {loadingDeleteUser && <PageLoader />}
      <Dialog
        open={openRemoveUser}
        onClose={handleCloseRemoveUser}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{
          "& .MuiDialog-paper": {
            py: "35px",
            px: "24px",
            width: "312px",
            height: "360px",
            textAlign: "center",
            borderRadius: "12px !important",
          },
        }}
      >
        <Grid container direction="column" justifyContent="center" alignItems="center" spacing={2}>
          <Grid item textAlign={"center"}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                textAlign: "center",
                flexDirection: "column",
              }}
            >
              <Avatar
                sx={{
                  width: "71px",
                  height: "71px",
                  borderRadius: "12px",
                  textAlign: "center",
                }}
                src={profileUrl}
              />
              <Typography
                sx={{
                  fontFamily: "Poppins-Medium",
                  fontSize: "14px",
                  marginTop: "12px",
                  fontWeight: 500,
                  lineHeight: "20px",
                  color: "#000000",
                  letterSpacing: "0.1px",
                  pt: 0.5,
                }}
              >
                {getValue(editableUser?.firstName) + " " + getValue(editableUser?.lastName)}
              </Typography>
            </Box>
          </Grid>

          <Grid item>
            <Typography
              sx={{
                fontFamily: "Poppins-Regular",
                fontSize: "24px",
                fontWeight: 400,
                lineHeight: "32px",
                color: "#1C1B1F",
              }}
            >
              Are you sure you want to delete this user?
            </Typography>
          </Grid>

          <Grid
            item
            mt={1.5}
            sx={{
              marginLeft: "auto",
              display: "flex",
              alignItems: "center",
              gap: "12px",
            }}
          >
            <Button
              variant="text"
              sx={{
                borderRadius: "99px",
                width: "99px",
                height: "40px",
              }}
              onClick={() => setOpenRemoveUser(false)}
            >
              <Typography variant="h5" color="#4950C7">
                Cancel
              </Typography>
            </Button>

            <Button
              variant="contained"
              disableElevation
              sx={{
                borderRadius: "99px",
                width: "99px",
                height: "40px",
                backgroundColor: "#131599",
              }}
              onClick={() => handleDeleteUser(editableUser?.userId)}
            >
              <Typography variant="h5" color="white">
                Yes
              </Typography>
            </Button>
          </Grid>
        </Grid>

        {/* <DialogTitle id="alert-dialog-title">
                {"Use Google's location service?"}
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    Let Google help apps determine location. This means sending
                    anonymous location data to Google, even when no apps are
                    running.
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleCloseRemoveUser}>Disagree</Button>
                <Button onClick={handleCloseRemoveUser} autoFocus>
                    Agree
                </Button>
            </DialogActions> */}
      </Dialog>
    </>
  );
}

export default RemoveUser;
