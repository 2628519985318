import {
  RESET_EDUCATION_INFO,
  EDUCATION_INFO,
  EDUCATION_INFO_SUCCESS,
  EDUCATION_INFO_FAILURE,
} from "../../../constants/Workforce/EmployeeDetils/EducationInfo";

// for user authentication
export const ResetEducationInfo = (data) => {
  return {
    type: RESET_EDUCATION_INFO,
    payload: data,
  };
};

export const educationInfo = (data) => {
  return {
    type: EDUCATION_INFO,
    payload: data,
  };
};
export const educationInfoSuccess = (payload) => {
  return {
    type: EDUCATION_INFO_SUCCESS,
    payload,
  };
};
export const educationInfoFailure = (payload) => {
  return {
    type: EDUCATION_INFO_FAILURE,
    payload,
  };
};
