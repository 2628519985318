import React, { useState, useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Grid, Typography, TextField, InputAdornment, Box, Button } from "@mui/material";

import { getFormattedDateAndTime, getValue } from "components/Screens/CommonUtils";
import DataTable from "components/Widgets/DataTable";
import { useDebounce } from "components/useDebounce";
import AddOrEditUsers from "components/Widgets/AddOrEditUsers";
import PageLoader from "components/common/PageLoader";

import { ManageUser } from "store/actions/Settings/ManageUser";
import { ResetAddUser } from "store/actions/Settings/AddUser";

import SearchIcon from "@mui/icons-material/Search";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import ManageAccountsOutlinedIcon from "@mui/icons-material/ManageAccountsOutlined";

const tableheadCells = [
  {
    id: "First_Name",
    wrapCell: true,
    numeric: false,
    width: "20%",
    label: <Typography variant="h5">Employee Name</Typography>,
  },
  {
    id: "Email",
    width: "20%",
    numeric: false,
    wrapCell: true,
    disablePadding: false,
    label: <Typography variant="h5">Email Address</Typography>,
  },
  {
    id: "Phone_Number",
    numeric: false,
    wrapCell: true,
    disablePadding: false,
    label: <Typography variant="h5">Phone Number</Typography>,
  },
  {
    id: "Country",
    numeric: false,
    disablePadding: false,
    label: <Typography variant="h5">Country</Typography>,
  },
  {
    id: "Permission",
    numeric: false,
    disablePadding: false,
    label: <Typography variant="h5">Permission</Typography>,
    sort: false,
  },
  {
    id: "Last_Active",
    numeric: false,
    disablePadding: false,
    label: <Typography variant="h5">Last Active</Typography>,
  },
  {
    id: "action",
    numeric: false,
    disablePadding: false,
    width: "20px",
    label: <Typography variant="h5">Action</Typography>,
  },
];

function Users(props) {
  const { customerId } = props;
  const manageUserRequest = `customer/${customerId}/customerUsers`;

  const dispatch = useDispatch();
  const [page, setPage] = useState(0);
  const [sortBy, setSortBy] = useState({});
  const [rowsData, setRowsData] = useState([]);
  const [rowsPerPage] = useState(100);
  const [searchEmployee, setSearchEmployee] = useState("");
  const [adOrEdit, setAddOrEdit] = useState({ open: false, mode: null });

  const manageUser = useSelector((state) => state.ManageUser.manageUser);
  const loadingManageUser = useSelector((state) => state.ManageUser.loadingManageUser);

  const debounceSearchEmployee = useDebounce(searchEmployee, 500);

  const handleSearchEmployee = (event) => {
    setSearchEmployee(event.target.value);
    setPage(0);
  };

  const handleRequestSortBy = (request) => {
    setSortBy(request);
    dispatch(
      ManageUser({
        request: manageUserRequest,
        rowsPerPage,
        page,
        sortBy: {
          field: request.orderBy,
          direction: request.order,
        },
      })
    );
  };

  const fetchUsers = useCallback(() => {
    dispatch(
      ManageUser({
        request: manageUserRequest,
        rowsPerPage,
        page,
        searchEmployee: debounceSearchEmployee,
        sortBy: {
          field: sortBy?.orderBy,
          direction: sortBy?.order,
        },
      })
    );
  }, [customerId, dispatch, manageUserRequest, rowsPerPage, page, debounceSearchEmployee, sortBy]);

  useEffect(() => {
    fetchUsers();
  }, [debounceSearchEmployee]);

  useEffect(() => {
    const Data = [];
    if (manageUser?.ok) {
      manageUser?.payload?.rows?.map((user) => {
        return (
          user?.mainUser !== true &&
          Data.push({
            id: user?.userId,
            First_Name: (
              <Typography variant="h6" textAlign="left">
                {getValue(`${user?.firstName} ${user?.lastName}`)}
              </Typography>
            ),
            Email: (
              <Typography variant="h6" textAlign="left">
                {getValue(user?.email)}
              </Typography>
            ),
            Phone_Number: (
              <Typography variant="h6" textAlign="left">
                {getValue(user?.phoneNumber)}
              </Typography>
            ),
            Country: (
              <Typography variant="h6" textAlign="left">
                {getValue(user?.country || "N/A")}
              </Typography>
            ),
            Permission: (
              <Typography variant="h6" textAlign="left">
                {getValue(user?.permissions[0] || "N/A")}
              </Typography>
            ),
            Last_Active: (
              <Typography variant="h6" textAlign="left">
                {user?.lastActive ? getFormattedDateAndTime(user?.lastActive) : "N/A"}
              </Typography>
            ),
            action: (
              <div className="flex items-center justify-start space-x-1">
                <EditOutlinedIcon
                  onClick={() => {
                    setAddOrEdit({ open: true, mode: "edit", user });
                  }}
                  fontSize="small"
                  color="muted"
                  sx={{ "&:hover": { color: "#1161D5" } }}
                />
                <DeleteOutlinedIcon
                  onClick={() => setAddOrEdit({ open: true, mode: "delete", user })}
                  fontSize="small"
                  color="muted"
                  sx={{ "&:hover": { color: "#1161D5" } }}
                />
              </div>
            ),
          })
        );
      });
    }
    setRowsData(Data);
  }, [manageUser, customerId]);

  return (
    <>
      {loadingManageUser ? <PageLoader /> : null}
      <Grid item container direction="column" rowSpacing={3}>
        {manageUser?.payload?.count > 1 ? (
          <Grid item xs={12}>
            <DataTable
              rowsData={rowsData}
              tableheadCells={tableheadCells}
              title={`Users (${rowsData?.length ? rowsData.length : 0})`}
              titleIcon={ManageAccountsOutlinedIcon}
              svgIcon
              subTitle={null}
              toolBar
              disablePagination
              handleRequestSortBy={handleRequestSortBy}
              sortByName="month"
              openDetailsPage="month"
              rowTextAlign="center"
              SearchEmployee={
                <TextField
                  id="search-emp"
                  sx={{
                    width: "200px !important",
                    "&.MuiTextField-root": {
                      marginTop: 0,
                    },
                    "& .MuiInputBase-root": {
                      backgroundColor: "white",
                      borderRadius: "5px",
                      fontFamily: "Poppins-Medium !important",
                      height: "38px",
                    },
                    "& .MuiInputBase-input": {},
                  }}
                  variant="outlined"
                  placeholder="Search by employee name"
                  size="small"
                  name="employee"
                  value={searchEmployee}
                  onChange={handleSearchEmployee}
                  margin="normal"
                  InputProps={{
                    disableUnderline: true,
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                />
              }
              exportButton={
                <Button
                  disableElevation
                  variant="contained"
                  fullWidth
                  onClick={() => setAddOrEdit({ open: true, mode: "add", user: null })}
                  sx={{
                    width: "120px",
                    height: "35px",
                    gap: "6px",
                    borderRadius: "5px",
                    color: "white",
                  }}
                >
                  <AddOutlinedIcon style={{ width: "20px" }} />
                  <Typography id="user" variant="h6" color="white">
                    Add User
                  </Typography>
                </Button>
              }
            />
          </Grid>
        ) : (
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            height="calc(100vh - 180px)"
            bgcolor="white"
            mt={1}
          >
            <ManageAccountsOutlinedIcon sx={{ width: 80, height: 80, color: "#BBBBBB" }} />
            <Typography
              sx={{
                fontFamily: "Poppins",
                fontSize: "20px",
                fontWeight: 600,
                lineHeight: "30px",
                textAlign: "center",
                color: "#292A3D",
                marginTop: "10px",
              }}
            >
              You have not added any users yet!
            </Typography>
            <Typography
              sx={{
                fontFamily: "Poppins",
                fontSize: "16px",
                fontWeight: 400,
                lineHeight: "24px",
                textAlign: "center",
                color: "#212121",
                marginBottom: "16px",
              }}
            >
              Start by adding a new user.
            </Typography>
            <Button
              disableElevation
              variant="contained"
              fullWidth
              onClick={() => setAddOrEdit({ open: true, mode: "add", user: null })}
              sx={{
                width: "120px",
                height: "35px",
                gap: "6px",
                borderRadius: "5px",
                color: "white",
              }}
            >
              <AddOutlinedIcon style={{ width: "20px" }} />
              <Typography id="user" variant="h6" color="white">
                Add User
              </Typography>
            </Button>
          </Box>
        )}
      </Grid>

      {adOrEdit?.open && (
        <AddOrEditUsers
          user={adOrEdit?.user}
          mode={adOrEdit?.mode}
          customerId={customerId}
          openDialog={adOrEdit?.open}
          fetchUsers={() => {
            dispatch(
              ManageUser({
                request: manageUserRequest,
                rowsPerPage,
                page,
                searchEmployee: debounceSearchEmployee,
                sortBy: {
                  field: sortBy?.orderBy,
                  direction: sortBy?.order,
                },
              })
            );
          }}
          handleClose={() => {
            setAddOrEdit({ open: false, mode: null, user: null });
            dispatch(ResetAddUser({}));
          }}
        />
      )}
    </>
  );
}

export default Users;
