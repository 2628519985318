import { call, put, takeEvery } from "redux-saga/effects";
//actions
import { EditUserProfileSuccess, EditUserProfileFailure } from "../../actions/Settings/EditUserProfile";
//constant
import { EDIT_USER_PROFILE } from "../../constants/Settings/EditUserProfile";
//request type
import postRequest from "../../../util/APIHelperPost";
import { baseUrl } from "../../../util/APIBaseUrl";

const getUtcFormatDate = (date) => {
  return new Date(date).toISOString();
};

export const fetchEditUserProfileRequest = async (actions) => {
  // eslint-disable-next-line no-return-await
  let body = {
    email: actions.payload?.companyProfileData?.email,
    firstName: actions.payload?.companyProfileData?.firstName,
    lastName: actions.payload?.companyProfileData?.lastName,
    country: actions.payload?.companyProfileData?.country,
    timezone: actions.payload?.companyProfileData?.timezone,
    phoneNumber: actions.payload?.companyProfileData?.phoneNumber,
    departmentId: actions.payload?.companyProfileData?.departmentId,
  };

  body = JSON.stringify(body);

  return await postRequest(`${baseUrl}/${actions.payload.request}`, body)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

function* fetchEditUserProfile(params) {
  try {
    let response = yield call(fetchEditUserProfileRequest, params);
    yield put(EditUserProfileSuccess(response));
  } catch (error) {
    yield put(EditUserProfileFailure(error));
  }
}

export default function* rootSaga() {
  yield takeEvery(EDIT_USER_PROFILE, fetchEditUserProfile);
}
