import {
  Alert,
  Autocomplete,
  Button,
  Checkbox,
  Collapse,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  Paper,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import React from "react";
import { Fragment } from "react";
import { useParams } from "react-router-dom";
//redux
import { useSelector, useDispatch } from "react-redux";
import {
  postADocForWorkforce,
  ResetPostADocForWorkforce,
} from "../../../store/actions/Workforce/EmployeeDetils/Documents";
//mui
import { BsFileEarmarkCheck, BsFileEarmarkPlus } from "react-icons/bs";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import { EmployeeDocumentsRequest } from "../../../store/actions/EmployeePortal/EmployeeDocuments";
import EmployeeDocuments from "../Employee/EmployeeDocuments";
import { getError } from "../CommonUtils";
import EditDocument from "../Workforce/UserDetails/Documents/EditDocument";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import ConflictsInRecordes from "./ConflictsInRecordes";

function UploadFile(props) {
  const dispatch = useDispatch();
  const params = useParams();
  const workerDocs = useSelector((state) => state.WorkforceDocs);
  const empDocs = useSelector((state) => state.EmployeeDocuments);
  // component state
  const [fileName, setFileName] = React.useState(null);
  const [selectedFile, setSelectedFile] = React.useState(null);
  const [openAlert, setOpenAlert] = React.useState(false);
  const [shareWithEmployee, setShareWithEmployee] = React.useState(true);
  const [shareWithCustomer, setShareWithCustomer] = React.useState(true);
  const [fileSize, setFileSize] = React.useState(null);
  const [editDocument, setEditDocument] = React.useState();
  const [openEditDocument, setOpenEditDocument] = React.useState(false);

  const [uploadDocumentType, setUploadDocumentType] = React.useState("");

  const [payrollDate, setPayrollDate] = React.useState(new Date());
  const [exchangeRate, setExchangeRate] = React.useState(null);

  const platformConfig = useSelector((state) => state.PlatformConfig.platformConfig);

  const handleChooseFile = (e) => {
    setSelectedFile(null);

    dispatch(ResetPostADocForWorkforce({}));
    e.preventDefault();
    let inFile = e.target.files[0];

    // setFileName(inFile.name);

    setFileSize(Math.floor(inFile?.size));
    setSelectedFile(inFile);
  };

  const handleDateChange = (date) => {
    setPayrollDate(date);
  };

  const handleAlertClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenAlert(false);
  };

  const handleDocUpload = (e) => {
    let uploadDoc;
    e.preventDefault();
    dispatch(ResetPostADocForWorkforce({}));
    if (selectedFile && selectedFile) {
      var form_data = new FormData();
      form_data.append("file", selectedFile);

      form_data.append("exchangeRate", exchangeRate);
      form_data.append("payrollMonth", payrollDate);

      uploadDoc = `uploadPayrollCSV`;
      dispatch(
        postADocForWorkforce({
          request: uploadDoc,
          body: form_data,
        })
      );
    }
  };

  //methods
  React.useEffect(() => {
    // Only fetch if the details are not in redux store
    if (workerDocs?.documents?.status) {
      setOpenAlert(true);
    } else {
      setOpenAlert(false);
    }
  }, [workerDocs]);

  React.useEffect(() => {
    if (workerDocs?.documents?.ok) {
      setFileName("");
      setExchangeRate(null);
      setPayrollDate(new Date());
      setUploadDocumentType("");
      setSelectedFile(null);
      // dispatch(ResetPostADocForWorkforce({}));
    }
  }, [workerDocs]);

  React.useEffect(() => {
    dispatch(ResetPostADocForWorkforce({}));
  }, []);

  return (
    <>
      <Grid container direction="row" rowSpacing={3} columnSpacing={3}>
        <Grid item container direction="row" alignItems="center" columnSpacing={3}>
          <Grid item xs={4}>
            <input
              color="primary"
              accept="application/csv"
              type="file"
              onChange={handleChooseFile}
              id="icon-button-file"
              style={{ display: "none" }}
            />

            {/* <label>Select File</label> */}
            <TextField
              value={selectedFile?.name ? selectedFile?.name : ""}
              readOnly
              fullWidth
              sx={{
                "& .MuiInputBase-root": {
                  backgroundColor: "white",
                  borderRadius: "6px",
                  fontFamily: "Poppins-Regular !important",
                  height: "35px",
                },
                "& .MuiInputBase-input": {
                  paddingTop: 1,
                  paddingLeft: 2,
                  paddingBottom: 1,
                  fontSize: "12px",
                  "&::placeholder": {
                    color: "black",
                    opacity: 1,
                  },
                },
                "& .MuiOutlinedInput-root": {
                  paddingRight: "0px !important",
                },
              }}
              id="userName"
              variant={"outlined"}
              placeholder="Selected File Name"
              size="small"
              name="email"
              InputProps={{
                disableUnderline: true,
                endAdornment: (
                  <InputAdornment position="start">
                    <Tooltip title="Choose File">
                      <label htmlFor="icon-button-file">
                        <IconButton component="span" aria-label="icon-button-file">
                          <UploadFileIcon fontSize="20px" color="primary" />
                        </IconButton>
                      </label>
                    </Tooltip>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>

          <Grid item xs={4}>
            <TextField
              value={exchangeRate}
              // onChange={(e) => setFileName(e.target.value)}
              sx={{
                "& .MuiInputBase-root": {
                  backgroundColor: "white",
                  borderRadius: "6px",
                  fontFamily: "Poppins-Regular !important",
                  height: "35px",
                },
                "& .MuiInputBase-input": {
                  paddingLeft: 2,
                  fontSize: "12px",
                  "&::placeholder": {
                    color: "black",
                    opacity: 1,
                  },
                },
              }}
              id="userName"
              variant={"outlined"}
              onChange={(e) => setExchangeRate(e.target.value)}
              placeholder="Enter Exchange Rate"
              size="small"
              name="email"
              fullWidth={true}
              InputProps={{
                disableUnderline: true,
              }}
            />
          </Grid>

          <Grid item>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                views={["month", "year"]}
                id="dateOfJoining"
                value={payrollDate}
                onChange={(newValue) => {
                  handleDateChange(newValue);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    sx={{
                      background: "white",
                      "& .MuiOutlinedInput-root": {
                        borderRadius: "5px",
                        fontSize: "12px",
                        height: "35px",
                        fontFamily: "Poppins-Regular",
                      },

                      "& .MuiSvgIcon-root": {
                        fill: "#1161D5",
                      },
                    }}
                  />
                )}
              />
            </LocalizationProvider>
          </Grid>

          <Grid item>
            <Button
              disableElevation
              disabled={!selectedFile}
              variant="contained"
              fullWidth
              onClick={handleDocUpload}
              sx={{
                width: "140px",
                height: "35px",
                borderRadius: "5px",
                color: "white",
              }}
            >
              <Typography variant="h6" color="white">
                Upload File
              </Typography>
            </Button>
          </Grid>
        </Grid>

        {workerDocs?.documents?.ok === false ? (
          <Grid item xs={12} sx={{ width: "100%", display: "flex", justifyContent: "center" }}>
            {/* {empDocs?.docs?.length ?  */}
            <ConflictsInRecordes />
            {/* // : null} */}
          </Grid>
        ) : workerDocs?.documents?.ok === true ? (
          <Stack direction="row" spacing="10px" alignItems="center">
            <Typography
              variant="h4"
              sx={{
                // textAlign: 'center',
                fontFamily: "Poppins-Medium",
                fontWeight: 400,
                p: 3,
                lineHeight: "28px",
                fontSize: "20px !important",
              }}
            >
              Payroll has been updated Successfully !
            </Typography>
          </Stack>
        ) : null}
      </Grid>
    </>
  );
}

export default UploadFile;
