//employee constants
import {
  RESER_UNASSIGN_EMPLOYEE,
  UNASSIGN_EMPLOYEE,
  UNASSIGN_EMPLOYEE_SUCCESS,
  UNASSIGN_EMPLOYEE_FAILURE,
} from "../../../constants/Workforce/UnAssignEmployee";

const INIT_STATE = {
  unAssignEmployee: {},
  loadingUnAssignInfo: false,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case RESER_UNASSIGN_EMPLOYEE:
      return INIT_STATE;
    case UNASSIGN_EMPLOYEE:
      return {
        ...state,
        loadingUnAssignInfo: true,
      };
    case UNASSIGN_EMPLOYEE_SUCCESS:
      return {
        ...state,
        loadingUnAssignInfo: false,
        unAssignEmployee: action.payload,
      };
    // eslint-disable-next-line no-fallthrough
    case UNASSIGN_EMPLOYEE_FAILURE:
      return {
        ...state,
        loadingUnAssignInfo: false,
        unAssignEmployee: "Error in  getting Club Data",
      };
    default:
      return state;
  }
};
