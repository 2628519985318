import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Chip, Fade, IconButton } from "@mui/material";
import { addDays } from "date-fns";

import UpcommingHolidayLeftIcon from "../../TimeoffAdmin/EmployeeTimeOffDetails/Icons/UpcommingHolidayLeftIcon";
import UpcommingHolidayRightIcon from "../../TimeoffAdmin/EmployeeTimeOffDetails/Icons/UpcommingHolidayRightIcon";

import ArrowIcon from "assets/images/left-arrow.svg";
// import RightArror from "assets/images/right-Arrow.svg";
// import ArrowIconSmall from "assets/images/left-arrow.svg";

import { usePermissions } from "components/Hook";
import combineUpcomingLeavesAndHolidays from "util/combineUpcomingLeavesAndHolidays";
import { baseUrl } from "util/APIBaseUrl";
import { convertDate } from "components/Screens/CommonUtils";

import { GetAllHolidays } from "store/actions/Holidays";

import "./UpcomingLeavesAndHolidaysBar.css";
import moment from "moment";
import { makeRequest } from "util/APIHelper";

const UpcomingLeavesAndHolidaysBar = (props) => {
  const { portal, setTotalLeavesAndHolidaysCount } = props;
  const dispatch = useDispatch();

  const { loggedInUserType, loggedInUser } = usePermissions();

  const [employeeDetails, setEmployeeDetails] = useState(null);
  const [leavesAndHolidays, setLeavesAndHolidays] = useState([]);
  const [leaveAndHolidayIndex, setLeaveAndHolidayIndex] = useState(0);

  const [cardShownOnScreenFlag, setCardShownOnScreenFlag] = useState(true);
  const getEmployeeDetails = useSelector((state) => state.GetEmployeeDetails.getEmployeeDetails);

  const employeeId = loggedInUser?.userId;
  const requestOptions = {
    path: `${baseUrl}/employee/${employeeId}`,
    method: "GET",
  };

  const fetchEmployeeDetails = async () => {
    try {
      const response = await makeRequest(requestOptions, true /* loaderFlag */, false /* apiType */);
      if (response.ok) {
        setEmployeeDetails(response.payload);
      } else {
        console.error("Failed to fetch employee details");
      }
    } catch (error) {
      console.error("Error fetching employee details:", error);
    }
  };

  useEffect(() => {
    if (loggedInUserType === "employee") {
      fetchEmployeeDetails();
    }
  }, [loggedInUser, loggedInUserType]);

  useEffect(() => {
    let holidaysRequest = "";

    holidaysRequest = {
      customer: `${baseUrl}/customer/${loggedInUser?.customerId}/publicHolidays`,
      employee: `${baseUrl}/employee/publicHolidays`,
      edge: `${baseUrl}/employee/${getEmployeeDetails?.payload?.employeeId}/leave_holidays`,
    };

    if ((loggedInUserType == "edge" && getEmployeeDetails?.payload?.employeeId) || loggedInUserType !== "edge") {
      const region =
        loggedInUserType === "employee"
          ? employeeDetails?.country
          : loggedInUserType === "edge"
            ? getEmployeeDetails?.payload?.country
            : "";

      if (region)
        dispatch(
          GetAllHolidays({
            request: holidaysRequest[loggedInUserType],
            region: region,
            startDate: portal == "admin" ? moment().startOf("year").format("YYYY-MM-DD") : new Date(),
            endDate: portal == "admin" ? moment().endOf("year").format("YYYY-MM-DD") : addDays(new Date(), 365),
          })
        );
    }
  }, [portal, employeeDetails?.employeeId]);

  const upcomingLeaves = useSelector((state) => state?.TimeoffUpcomingLeaves?.upcomingLeaves);
  const allHolidays = useSelector((state) => state?.Holidays?.allHolidays);

  useEffect(() => {
    setCardShownOnScreenFlag(false);
    setTimeout(() => setCardShownOnScreenFlag(true), 300);
  }, [leaveAndHolidayIndex]);

  useEffect(() => {
    let holidays = [];
    let leavesHolidays = [];

    if (portal === "admin") {
      holidays = allHolidays?.result?.holidays || [];
      leavesHolidays = allHolidays?.result?.leaves || [];
    } else {
      holidays = allHolidays?.data;
    }
    // holidays = allHolidays?.data;
    if (setTotalLeavesAndHolidaysCount) {
      setTotalLeavesAndHolidaysCount(
        combineUpcomingLeavesAndHolidays(portal === "admin" ? leavesHolidays : upcomingLeaves, holidays).length || 0
      );
    }

    setLeavesAndHolidays(
      combineUpcomingLeavesAndHolidays(portal === "admin" ? leavesHolidays : upcomingLeaves, holidays)
    );
  }, [upcomingLeaves, allHolidays]);

  return (
    <div className="leaves-holidays-container">
      {loggedInUserType !== "edge" ? (
        <img
          src={ArrowIcon}
          className="cursor-pointer bg-primary-95 leaves-holidays-arrow-icon"
          onClick={() => {
            if (leaveAndHolidayIndex !== 0) {
              setLeaveAndHolidayIndex(leaveAndHolidayIndex - 1);
            }
          }}
          alt="left arrow"
        />
      ) : (
        <IconButton
          onClick={() => {
            if (leaveAndHolidayIndex !== 0) {
              setLeaveAndHolidayIndex(leaveAndHolidayIndex - 1);
            }
          }}
        >
          <UpcommingHolidayLeftIcon />
        </IconButton>
      )}

      <Fade className="w-100p" in={cardShownOnScreenFlag}>
        <div>
          <div className="leaves-holidays-card-wrapper">
            <div>
              <div className="pending-leave-reason">{leavesAndHolidays[leaveAndHolidayIndex]?.label || ""}</div>

              <div className="pending-leave-duration">
                <div className="pending-leave-date">
                  {convertDate(leavesAndHolidays[leaveAndHolidayIndex]?.startDate)}
                </div>{" "}
                {leavesAndHolidays[leaveAndHolidayIndex]?.endDate && (
                  <>
                    <img className="pending-leave-arrow" src={ArrowIcon} alt="left arrow" />{" "}
                    <div className="pending-leave-date">
                      {convertDate(leavesAndHolidays[leaveAndHolidayIndex]?.endDate)}
                    </div>
                  </>
                )}
              </div>
            </div>

            <div className="timeoff-used-wrapper">
              <div className="timeoff-used-label">
                {leavesAndHolidays[leaveAndHolidayIndex]?.isLeave ? "Duration" : "Total days off"}
              </div>
              {/* <div className="timeoff-used-value">
                {leavesAndHolidays[leaveAndHolidayIndex]?.totalTimeOff}{" "}
                {leavesAndHolidays[leaveAndHolidayIndex]?.totalTimeOff == 1 ? "leave" : "leaves"}
              </div> */}
              <Chip
                className="secondary-20 custom-chip"
                size="small"
                variant="outlined"
                id="total-timeoff-used"
                label={`${leavesAndHolidays[leaveAndHolidayIndex]?.totalTimeOff} ${leavesAndHolidays[leaveAndHolidayIndex]?.totalTimeOff == 1
                  ? leavesAndHolidays[leaveAndHolidayIndex]?.isHoliday
                    ? "Day"
                    : "leave"
                  : leavesAndHolidays[leaveAndHolidayIndex]?.isHoliday
                    ? "Days"
                    : "leaves"
                  }`}
              />
            </div>
          </div>
        </div>
      </Fade>
      {loggedInUserType !== "edge" ? (
        <img
          src={ArrowIcon}
          onClick={() => {
            if (leaveAndHolidayIndex !== leavesAndHolidays.length - 1) {
              setLeaveAndHolidayIndex(leaveAndHolidayIndex + 1);
            }
          }}
          className="cursor-pointer bg-primary-95 leaves-holidays-arrow-icon leaves-holidays-arrow-right"
          alt="left arrow"
        />
      ) : (
        <IconButton
          onClick={() => {
            if (leaveAndHolidayIndex !== leavesAndHolidays.length - 1) {
              setLeaveAndHolidayIndex(leaveAndHolidayIndex + 1);
            }
          }}
        >
          <UpcommingHolidayRightIcon />
        </IconButton>
      )}
    </div>
  );
};

export default UpcomingLeavesAndHolidaysBar;
