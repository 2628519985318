import { Divider, List, ListItem, Chip } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import Grid from "components/common/Grid";
import { employeeSalaryDetails } from "store/actions/Workforce/EmployeeDetils/SalaryDetails";
import { MONTH_NAMES_SHORT } from "constants";

import "./EmployeeStubsGrid.css";
import { usePermissions } from "components/Hook";
import EmployeeStubsGridSkeleton from "./EmployeeStubsGridSkeleton";

import noPaySlipsImg from "assets/images/noPaySlips.svg";
import ChevronIcon from "assets/images/chevron-right-black.svg";
import NoteIcon from "assets/images/note-icon-fill.svg";

function EmployeeStubsGridCellA(props) {
  const payrollId = props?.row?.payrollId;
  const navigate = useNavigate();
  const getUTCDate = (dateStr) => new Date(`${dateStr}T00:00:00Z`);

  return (
    <div
      className="flex items-center space-x-2 employee-stubs-grid-cella ml-05"
      onClick={() => navigate(`${payrollId}`)}
    >
      <img src={NoteIcon} alt="note icon" className="w-2 h-2" />
      <div className="">
        <div className="primary-20 button-md" id="payroll-month">{`${
          MONTH_NAMES_SHORT[getUTCDate(props?.row?.paymentDate).getUTCMonth()]
        } ${getUTCDate(props?.row?.paymentDate).getUTCFullYear()}`}</div>
      </div>
    </div>
  );
}

// TODO: will remove the comment after feedback from design team.
// function EmployeeStubsGridCell_B(props) {
//   const payrollId = props?.row?.payrollId;
//   const navigate = useNavigate();
//   return (
//     <div className="employee-stubs-grid-cellb" onClick={() => navigate(`${payrollId}`)}>
//       <div className="neutral-20 button-md" id="payroll-month">
//         {`${new Date(props?.row?.paymentDate).getDate()}
//         ${MONTH_NAMES_SHORT[new Date(props?.row?.paymentDate).getMonth()]},
//         ${new Date(props?.row?.paymentDate).getFullYear()}`}
//       </div>
//     </div>
//   );
// }

function EmployeeStubsGridCellC(props) {
  const payrollId = props?.row?.payrollId;
  const paidStatus = props?.row?.status === "paid";

  const navigate = useNavigate();

  return (
    <div className="items-center employee-stubs-grid-cellc mr-05" onClick={() => navigate(`${payrollId}`)}>
      <Chip
        className="secondary-20"
        size="small"
        variant="outlined"
        id="no-of-leaves"
        label={paidStatus ? "Paid" : "Processing"}
        sx={{
          "&.MuiChip-root": { height: "24px !important", border: "none", padding: "4px, 8px, 4px, 8px", gap: "10px" },
          "& .MuiChip-label": {
            fontSize: "12px",
            lineHeight: "16px",
            fontFamily: "Poppins",
            fontWeight: 500,
            letterSpacing: "0.5px",
          },
          background: paidStatus ? "#EBFAE6" : "#FFEFD3",
          color: paidStatus ? "#477E35" : "#977100",
        }}
      />
      <img src={ChevronIcon} alt="arrow icon" className="w-1 h-1 neutral-20" />
    </div>
  );
}

function EmployeeStubsGridHeader() {
  return (
    <div>
      <div className="flex items-center justify-between py-1 neutral-60a button-md px-05">
        <div className="pl-4 header-one ml-05">Month</div>
        {/* TODO: will remove the comment after feedback from design team. */}
        {/* <div className="header-two pl-05">Pay date</div> */}
        <div className="header-three pl-05">Status</div>
      </div>
      <Divider style={{ borderBottom: "1px solid #ececfb" }} />
    </div>
  );
}

function EmployeeSlipsHeader({ disabledFilter, payrollDetails, open, setOpen, selectYear, setSelectedYear }) {
  return (
    <div className="flex items-center p-1 bg-white mt-075 rounded-xl">
      <p className="title-sm mr-05 neutral-60a">Filter</p>
      <div className="employee-stubs-grid-header">
        <div style={{ position: "relative" }}>
          <button
            id="year-paystubs"
            type="button"
            disabled={disabledFilter}
            style={{
              border: `1px solid ${disabledFilter ? "#C7C5D0" : "#777680"} `,
              borderRadius: "22px",
              padding: "6px 10px 6px 16px",
              color: `${disabledFilter ? "#C7C5D0" : "#3D3E5C"} `,
              backgroundColor: "transparent",
              fontFamily: "Poppins",
              fontSize: "12px",
              lineHeight: "16px",
              letterSpacing: "0.1px",
              cursor: "pointer",
              whiteSpace: "nowrap",
              display: "flex",
              flexDirection: "column",
              borderBottom: `${open ? "none" : `1px solid ${disabledFilter ? "#C7C5D0" : "#777680"} `}`,
              borderBottomLeftRadius: `${open ? "0px" : "22px"}`,
              borderBottomRightRadius: `${open ? "0px" : "22px"}`,
            }}
            onClick={() => setOpen(!open)}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                gap: "8px",
              }}
            >
              {selectYear}
              <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M5.25 7.5L9 11.25L12.75 7.5H5.25Z" fill={`${disabledFilter ? "#C7C5D0" : "#3D3E5C"} `} />
              </svg>
            </div>
          </button>
          {open && (
            <List
              sx={{
                position: "absolute",
                top: "16px",
                left: "0px",
                padding: "12px 0px 16px",
                marginTop: "12px",
                listStyle: "none",
                width: "100%",
                border: "1px solid #777680",
                borderRadius: "22px",
                borderTop: `${open && "none"}`,
                borderTopLeftRadius: `${open && "0px"}`,
                borderTopRightRadius: `${open && "0px"}`,
                backgroundColor: "#FFFFFF",
              }}
            >
              {payrollDetails?.employeeSalaryDetails?.payload?.years?.map((year) => {
                return (
                  <ListItem
                    onClick={() => {
                      setSelectedYear(year);
                      setOpen(false);
                    }}
                    sx={{
                      padding: "6px 16px",
                      fontFamily: "Poppins-Medium",
                      fontSize: "14px",
                      lineHeight: "20px",
                      cursor: "pointer",
                      color: selectYear === year ? "#4950C7" : "#3D3E5C",
                      backgroundColor: selectYear === year ? "#F1EFFF" : "unset",
                      "&:hover": {
                        backgroundColor: "#F1EFFF",
                        color: "#4950C7",
                      },
                    }}
                  >
                    {year}
                  </ListItem>
                );
              })}
            </List>
          )}
        </div>
      </div>
    </div>
  );
}

function EmployeeStubsGrid() {
  const [selectYear, setSelectedYear] = useState(new Date().getFullYear());
  const [open, setOpen] = useState(false);
  const [yearFlag, setYearFlag] = useState(true);
  const [employeePayroll, setEmployeePayroll] = useState({});
  const [noDataScreen, setNoDataScreen] = useState(false);

  const dispatch = useDispatch();
  const payrollDetails = useSelector((state) => state.EmployeeSalaryDetails);
  const { loggedInUser } = usePermissions();

  useEffect(() => {
    if (payrollDetails?.employeeSalaryDetails?.payload?.years?.length) {
      setSelectedYear(payrollDetails?.employeeSalaryDetails?.payload?.years[0]);
    }
  }, [employeePayroll]);

  useEffect(() => {
    if (Object.keys(payrollDetails?.employeeSalaryDetails).length && yearFlag) {
      setEmployeePayroll(payrollDetails);
      setYearFlag(false);
    }

    if (payrollDetails?.employeeSalaryDetails?.payload?.rows?.length < 1) {
      setNoDataScreen(true);
    } else {
      setNoDataScreen(false);
    }
  }, [payrollDetails]);

  React.useEffect(() => {
    if (selectYear) {
      dispatch(
        employeeSalaryDetails({
          request: `employee/${loggedInUser.userId}/payroll?${new URLSearchParams({
            orderBy: JSON.stringify({
              field: "paymentDate",
              direction: "Desc",
            }),
            filter: JSON.stringify({
              year: selectYear,
            }),
          })}`,
        })
      );
    }
  }, [selectYear]);

  return (
    <div>
      <EmployeeSlipsHeader
        disabledFilter={noDataScreen}
        selectYear={selectYear}
        setSelectedYear={setSelectedYear}
        payrollDetails={payrollDetails}
        open={open}
        setOpen={setOpen}
      />
      {payrollDetails?.loadingEmployeeSalaryDetils ? (
        <EmployeeStubsGridSkeleton />
      ) : (
        <div>
          {!noDataScreen ? (
            <Grid
              className="p-0 border-none rounded-xl"
              header={EmployeeStubsGridHeader}
              rows={payrollDetails.employeeSalaryDetails.ok ? payrollDetails.employeeSalaryDetails.payload.rows : []}
              columns={[
                { key: "paymentDate", component: EmployeeStubsGridCellA },
                // TODO: will remove the comment after feedback from design team.
                // { key: "payrollAmount", component: EmployeeStubsGridCell_B },
                { key: "status", component: EmployeeStubsGridCellC },
              ]}
              employeePaySlip
            />
          ) : (
            <div className="no-payslips-screen">
              <div className="no-payslips-content">
                <img className="no-payslips-img" alt="no payslips" src={noPaySlipsImg} />
                <h3 className="no-payslips-title">No Pay Slips Found</h3>
                <p className="no-payslips-details">
                  Your first pay slip will be available after your initial pay period.
                </p>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
}

export default EmployeeStubsGrid;
