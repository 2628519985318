import React from "react";

const BlogIcon = () => {
  return (
    <svg
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.9998 7.8715H17.8332V8.20483V15.7048C17.8332 16.8094 16.9377 17.7048 15.8332 17.7048H4.1665C3.06193 17.7048 2.1665 16.8094 2.1665 15.7048V3.70483H13.3332H14.4998V7.3715V7.8715H14.9998ZM13.4754 17.0382H14.1825L13.9468 16.3715C13.8734 16.1637 13.8332 15.9395 13.8332 15.7048V4.8715V4.3715H13.3332H3.33317H2.83317V4.8715V15.7048C2.83317 16.4412 3.43012 17.0382 4.1665 17.0382H13.4754ZM14.9998 8.53817H14.4998V9.03817V15.7048C14.4998 16.4412 15.0968 17.0382 15.8332 17.0382C16.5696 17.0382 17.1665 16.4412 17.1665 15.7048V9.03817V8.53817H16.6665H14.9998ZM7.83317 7.03817V9.3715H5.49984V7.03817H7.83317ZM11.1665 7.8715V8.53817H9.6665V7.8715H11.1665ZM11.1665 11.2048V11.8715H5.49984V11.2048H11.1665ZM11.1665 13.7048V14.3715H5.49984V13.7048H11.1665Z"
        stroke="white"
      />
    </svg>
  );
};

export default BlogIcon;
