import {
  EMPLOYEE_PREV_EXP,
  EMPLOYEE_PREV_EXP_SUCCESS,
  EMPLOYEE_PREV_EXP_FAILURE,
} from "../constants/EmployeeExperience";

const INIT_STATE = {
  prevExperiences: [],
  isLoadingData: false,
  error: null,
};

export default (state = INIT_STATE, action) => {
  //
  switch (action.type) {
    case EMPLOYEE_PREV_EXP:
      return {
        ...state,
        isLoadingData: true,
      };
    case EMPLOYEE_PREV_EXP_SUCCESS:
      return {
        ...state,
        isLoadingData: false,
        prevExperiences: action?.payload?.payload,
        error: null,
      };
    case EMPLOYEE_PREV_EXP_FAILURE:
      return {
        ...state,
        isLoadingData: false,
        prevExperiences: "Error in Fetching Employee Experiencess",
        error: true
      };
    default:
      return state;
  }
};
