import React from "react";
//material ui
import { Box, Grid, Typography } from "@mui/material";
//icons
import WorkOutlineOutlinedIcon from "@mui/icons-material/WorkOutlineOutlined";
//redux dispatch
import { useSelector } from "react-redux";
import { getValue } from "../../../CommonUtils";

function EmploymentInformation(props) {
  //redux state
  const customerDetails = useSelector((state) => state.GetCustomerDetails.customerDetails);

  return (
    <>
      <Grid item container direction="row" justifyContent="space-between" alignItems="center" xs={12} sx={{ pb: 3 }}>
        <Grid item>
          <Box display="flex" flexDirection="column" rowGap={1.15}>
            <WorkOutlineOutlinedIcon sx={{ fontSize: "28px !important" }} />
            <Typography variant="h3" fontSize="15px">
              Contact Information
            </Typography>
          </Box>
        </Grid>
      </Grid>

      <Grid item container direction="column" rowSpacing={1.75} xs={12}>
        <Grid item>
          <Box display="flex" alignItems="center" justifyContent="space-between">
            <Typography
              variant="h6"
              // color="secondary.alt"
              fontSize="13px"
            >
              Contact Person
            </Typography>
            <Typography variant="h5" id="contact-person">
              {getValue(customerDetails?.payload?.contactPerson)}
            </Typography>
          </Box>
        </Grid>
        <Grid item>
          <Box display="flex" alignItems="center" justifyContent="space-between">
            <Typography
              variant="h6"
              // color="secondary.alt"
              fontSize="13px"
            >
              Contact Number
            </Typography>
            <Typography variant="h5" id="contact">
              {getValue(customerDetails?.payload?.contactNumber)}
            </Typography>
          </Box>
        </Grid>
        <Grid item>
          <Box display="flex" alignItems="center" justifyContent="space-between">
            <Typography
              variant="h6"
              // color="secondary.alt"
              fontSize="13px"
            >
              Contact Email
            </Typography>
            <Typography variant="h5" id="email">
              {getValue(customerDetails?.payload?.contactEmail)}
            </Typography>
          </Box>
        </Grid>
        <Grid item>
          <Box display="flex" alignItems="center" justifyContent="space-between">
            <Typography
              variant="h6"
              // color="secondary.alt"
              fontSize="13px"
            >
              Account Manager
            </Typography>
            <Typography variant="h5" id="account-manager">
              {getValue(customerDetails?.payload?.edgeEmployee?.First_Name) +
                " " +
                getValue(customerDetails?.payload?.edgeEmployee?.Last_Name)}
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </>
  );
}

export default EmploymentInformation;
