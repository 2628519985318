//employee constants
import { ADD_EMPLOYEE_EDUCATION_DATA } from "../../../constants/Workforce/AddEmployee/AddEmployeeEducationData";

const INIT_STATE = {
    addEmployeeEducationData: {},
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case ADD_EMPLOYEE_EDUCATION_DATA:
            return {
                ...state,
                addEmployeeEducationData: action.payload,
            };

        default:
            return state;
    }
};
