import {
  RESET_GET_USER_DETAILS,
  GET_USER_DETAILS,
  GET_USER_DETAILS_SUCCESS,
  GET_USER_DETAILS_FAILURE,
} from "../../constants/Settings/GetUserDetails";

// for add employee in admin workforce table

export const ResetGetUserDetails = (data) => {
  return {
    type: RESET_GET_USER_DETAILS,
    payload: data,
  };
};

export const GetUserDetails = (data) => {
  return {
    type: GET_USER_DETAILS,
    payload: data,
  };
};
export const GetUserDetailsSuccess = (payload) => {
  return {
    type: GET_USER_DETAILS_SUCCESS,
    payload,
  };
};
export const GetUserDetailsFailure = (payload) => {
  return {
    type: GET_USER_DETAILS_FAILURE,
    payload,
  };
};
