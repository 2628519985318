//employee constants
import {
  RESET_CHANGE_USER_PASSWORD,
  CHANGE_USER_PASSWORD,
  CHANGE_USER_PASSWORD_SUCCESS,
  CHANGE_USER_PASSWORD_FAILURE,
} from "../../constants/Settings/ChangeUserPassword";

const INIT_STATE = {
  changeUserPassword: {},
  loadingChangeUserPassword: false,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case RESET_CHANGE_USER_PASSWORD:
      return INIT_STATE;

    case CHANGE_USER_PASSWORD:
      return {
        ...state,
        loadingChangeUserPassword: true,
      };
    case CHANGE_USER_PASSWORD_SUCCESS:
      return {
        ...state,
        loadingChangeUserPassword: false,
        changeUserPassword: action.payload,
      };
    // eslint-disable-next-line no-fallthrough
    case CHANGE_USER_PASSWORD_FAILURE:
      return {
        ...state,
        loadingChangeUserPassword: false,
        changeUserPassword: "Error in  getting Club Data",
      };
    default:
      return state;
  }
};
