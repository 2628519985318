import React from "react";
//redux
import { useSelector } from "react-redux";
//material ui
import { Box, Grid, Typography } from "@mui/material";
//widget
import AmountDetails from "../AmountDetails";
//table
import DataTable from "../../Widgets/DataTable";
//widgets
import ProfleWithIcon from "../../Widgets/ProfileWithIcon";
import AvatarIcon from "../../Icons/AvatarIcon";
import EmailWithIcon from "../../Widgets/EmailWithIcon";
import PageLoader from "../../common/PageLoader";
//api base urls
import { baseUrl } from "../../../util/APIBaseUrl";
//common functions
import { convertDate, currencyFormat, getCurrencySymbol, getValue } from "../CommonUtils";

const tableheadCells = [
  {
    id: "Profile_Picture",
    sort: false,
    numeric: true,
    disablePadding: true,
    label: "",
    align: "right",
    width: "20px,",
  },
  {
    id: "employeeName",
    numeric: false,
    disablePadding: false,
    label: (
      <Typography variant="h5" fontSize="14px">
        Employee Name
      </Typography>
    ),
  },
  {
    id: "workEmail",
    numeric: false,
    disablePadding: false,
    label: (
      <Typography variant="h5" fontSize="14px">
        Work Email
      </Typography>
    ),
  },
  {
    id: "country",
    numeric: false,
    disablePadding: false,
    label: (
      <Typography variant="h5" fontSize="14px" textAlign="center">
        Country
      </Typography>
    ),
  },
  {
    id: "costToCompany",
    numeric: true,
    disablePadding: false,
    label: (
      <Typography variant="h5" fontSize="14px" textAlign="center">
        Cost To Company
      </Typography>
    ),
  },

  // {
  //     id: "date",
  //     numeric: true,
  //     disablePadding: false,
  //     label: (
  //         <Typography variant="h5" fontSize="14px">
  //             Date
  //         </Typography>
  //     ),
  // },
];

function PayrollDetails(props) {
  //component state
  const [tableRows, setTableRows] = React.useState([]);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [page, setPage] = React.useState(0);

  //redux states
  const paymentDetails = useSelector((state) => state.GetPaymentDetails.paymentDetails);

  const loadingPaymentDetails = useSelector((state) => state.GetPaymentDetails.loadingPaymentDetails);

  //functions

  const handleRowsPerPage = (pagination) => {
    setRowsPerPage(pagination.rowsPerPage);
    setPage(pagination.page);
  };

  const handleSelectedRow = (selectedId) => {};

  //methods
  React.useEffect(() => {
    let payrollData = [];

    if (paymentDetails && Object.keys(paymentDetails).length && paymentDetails?.payload?.details?.rows?.length) {
      paymentDetails.payload.details.rows.map((employee, index) => {
        payrollData.push({
          id: employee.employeeId,
          Profile_Picture: (
            <Box display="flex" alignItems="flex-end" justifyContent="flex-end" ml={0} pl={0}>
              <AvatarIcon
                shape={"circle"}
                size={{
                  width: "8vh",
                  height: "8vh",
                  borderRadius: "50%",
                }}
                source={`${baseUrl}/employee/${employee?.employeeId}/logo?id=${employee?.pictureVersion}`}
              />
            </Box>
          ),
          employeeName: (
            <ProfleWithIcon
              type="textOnly"
              data={{
                name: getValue(employee?.employeeName),
                role: getValue(employee?.jobTitle),
              }}
            />
          ),
          workEmail: (
            <EmailWithIcon
              type="textOnly"
              data={getValue(employee?.email)}
              iconButton={true}
              variant="h3"
              fontsize="12px"
            />
          ),
          country: <Typography variant="h6Sidebar">{getValue(employee?.country)}</Typography>,
          costToCompany: (
            <Typography variant="h3" fontSize="12px" color="primary" textAlign="center">
              {getCurrencySymbol("en-US", "USD", currencyFormat(getValue(employee?.costToCompany)))}
            </Typography>
          ),
          // date: (
          //     <Typography
          //         variant="h3"
          //         fontSize="12px"
          //         textAlign="center"
          //     >
          //         {convertDate(employee?.paymentDate)}
          //     </Typography>
          // ),
        });
      });
    }

    setTableRows(payrollData);
  }, [paymentDetails]);

  return (
    <>
      {loadingPaymentDetails && <PageLoader />}
      <Grid container direction="row" rowSpacing={3}>
        <Grid item xs={12}>
          <AmountDetails
            details={[
              {
                title: "Total Payroll",
                amount: getCurrencySymbol(
                  "en-US",
                  "USD",
                  currencyFormat(getValue(paymentDetails?.payload?.totalPayroll))
                ),
                amountTextColor: {
                  color:
                    Object.keys(paymentDetails).length && paymentDetails?.payload?.status == "incomplete"
                      ? "#F64A14"
                      : null,
                },
              },
              {
                title: "Payment Date",
                amount: convertDate(paymentDetails?.payload?.paymentDate),
                amountTextColor: {
                  color:
                    Object.keys(paymentDetails).length && paymentDetails?.payload?.status == "incomplete"
                      ? "#F64A14"
                      : null,
                },
              },
              {
                title: "Employees",
                amount: currencyFormat(getValue(paymentDetails?.payload?.totalEmployees)),
              },
            ]}
          />
        </Grid>

        <Grid item xs={12}>
          <DataTable
            rowsData={tableRows}
            totalCount={paymentDetails?.payload?.details?.count ? paymentDetails.payload.details.count : 0}
            tableheadCells={tableheadCells}
            title={null}
            rowTextAlign={"center"}
            subTitle={null}
            toolBar={false}
            slectedRowNavigation={"/main/details"}
            handleRowsPerPage={handleRowsPerPage}
            handleSelectedRow={handleSelectedRow}
            openDetailsPage={"employeeName"}
          />
        </Grid>
      </Grid>
    </>
  );
}

export default PayrollDetails;
