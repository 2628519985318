import Modal from "components/common/LeaveReplacement/Modal";
import LeaveReplacement from "components/common/LeaveReplacement";

const LeaveReplacementModal = ({
  open,
  closeModal,
  employeeDetails,
  setShowReplacementSuccessModal,
  onDataReceived,
}) => {
  return (
    <div>
      <Modal open={open} width={530}>
        <div className="w-full">
          <LeaveReplacement
            closeModal={closeModal}
            employeeDetails={employeeDetails}
            onDataReceived={onDataReceived}
            setShowReplacementSuccessModal={setShowReplacementSuccessModal}
          />
        </div>
      </Modal>
    </div>
  );
};

export default LeaveReplacementModal;
