import {
    RESET_DISABLE_CUSTOMER,
    DISABLE_CUSTOMER,
    DISABLE_CUSTOMER_SUCCESS,
    DISABLE_CUSTOMER_FAILURE,
} from "../../constants/Customers/DisableCustomer";

// for employee information

export const ResetDisableCustomer = (data) => {
    return {
        type: RESET_DISABLE_CUSTOMER,
        payload: data,
    };
};

export const DisableCustomer = (data) => {
    return {
        type: DISABLE_CUSTOMER,
        payload: data,
    };
};
export const DisableCustomerSuccess = (payload) => {
    return {
        type: DISABLE_CUSTOMER_SUCCESS,
        payload,
    };
};
export const DisableCustomerFailure = (payload) => {
    return {
        type: DISABLE_CUSTOMER_FAILURE,
        payload,
    };
};
