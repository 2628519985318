import React from "react";
import { useNavigate } from "react-router-dom";
import { getProfileImage, isEmptyObject } from "../../CommonUtils";
// images
import avatarImage from "../../../../assets/images/fallback-image.svg";
import { CardMedia, Grid, Typography } from "@mui/material";

function Employee(props) {
  const { employee, setShowDropdown = () => {}, clearSearchField = () => {}, showHoverStyles = false } = props;
  const navigate = useNavigate();

  const handleNaviagte = (employeeToNavigate) => {
    setShowDropdown(false);
    clearSearchField();
    if (employeeToNavigate?.companyName) {
      return navigate(`/main/customer/details/${employeeToNavigate?.customerId}`);
    }
    if (employeeToNavigate?.name) {
      return navigate(`/main/details/${employeeToNavigate?.id}`);
    }
    if (employeeToNavigate?.employeeId) return navigate(`/main/details/${employeeToNavigate?.employeeId}`);
    return "";
  };

  const [imageSrc, setImageSrc] = React.useState(avatarImage);

  const getSearchedDeatils = (searchedUser) => {
    let user = {};
    if (searchedUser?.companyName) {
      user = {
        name: searchedUser?.companyName,
        customerId: searchedUser?.customerId,
      };
    } else if (searchedUser?.name || searchedUser?.firstName) {
      user = {
        name: searchedUser?.name || `${searchedUser?.firstName} ${searchedUser?.lastName}`,
        employeeId: searchedUser?.id,
      };
    }
    return user;
  };

  React.useEffect(() => {
    if (isEmptyObject(employee) && employee.picturePath) {
      getProfileImage(employee?.employeeId, employee?.pictureVersion).then((img) => {
        setImageSrc(img);
      });
    } else {
      setImageSrc(avatarImage);
    }
  }, [employee?.picturePath, employee?.id]);

  return (
    <Grid
      sx={{
        display: "flex",
        alignItems: "center",
        width: "100%",
        gap: "12px",
        padding: "4px 20px",
        marginBottom: "10px",
        cursor: "pointer",
        "&:hover": {
          backgroundColor: showHoverStyles ? "#FBFAFF" : "none",
        },
        "&:last-child": {
          marginBottom: "0px",
        },
      }}
      onClick={() => {
        handleNaviagte(employee);
      }}
    >
      <CardMedia
        component="img"
        image={imageSrc[employee?.employeeId] ? imageSrc[employee?.employeeId] : avatarImage}
        alt="Employee Image"
        sx={{
          width: "34px",
          height: "34px",
        }}
      />
      <Grid
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "6px",
        }}
      >
        <Typography
          sx={{
            fontFamily: "Poppins-Regular",
            fontSize: "12px",
            fontWeight: 400,
            lineHeight: "16px",
            color: "#3D43BB",
          }}
        >
          {getSearchedDeatils(employee)?.name}
          {/* {employee?.firstName + " " + employee?.lastName} */}
        </Typography>
        <Typography
          sx={{
            fontFamily: "Poppins-Regular",
            fontSize: "12px",
            fontWeight: 400,
            lineHeight: "12px",
            color: "#B2B4E2",
          }}
        >
          {employee?.jobTitle}
        </Typography>
      </Grid>
    </Grid>
  );
}

export default Employee;
