import React from "react";

const PayIcon = () => {
  return (
    <svg
      width="22"
      height="15"
      viewBox="0 0 22 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M2.58317 0.20459H19.4165C20.337 0.20459 21.0832 1.0254 21.0832 2.03792V13.0379C21.0832 14.0504 20.337 14.8713 19.4165 14.8713H2.58317C1.6627 14.8713 0.916504 14.0504 0.916504 13.0379V2.03792C0.916504 1.0254 1.6627 0.20459 2.58317 0.20459ZM2.74984 2.03792V13.0379H19.2498V2.03792H2.74984ZM4.58317 11.2046V9.37126H6.4165V11.2046H4.58317ZM7.33317 11.2046V9.37126H10.0832V11.2046H7.33317ZM15.5832 11.2046V9.37126H17.4165V11.2046H15.5832ZM12.8332 11.2046V9.37126H14.6665V11.2046H12.8332ZM4.58317 4.78792H7.33317V7.53792H4.58317V4.78792Z"
        fill="#FBFAFF"
      />
    </svg>
  );
};

export default PayIcon;
