import React, { useState, useEffect, useCallback } from "react";
import { useDispatch } from "react-redux";

import TableComponent from "components/common/TableComponent";
import CustomDropdown from "components/common/Dropdown";

import { getEmployeePaySlips } from "services/BambooHrTabs/salarydetails";

import { showSnackbar } from "store/actions/Utility";

import { formatPaymentDateMonth } from "util/utils";
import { baseUrl } from "util/APIBaseUrl";
import getRequest from "util/APIHelperGet";

import NoPaySlipsFound from "./NoPaySlips";

import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import PageLoader from "components/common/PageLoader";

function PaySlips({ employeeID }) {
  const currentYear = new Date().getFullYear().toString();
  const [year, setYear] = useState(currentYear);
  const [paySlips, setPaySlips] = useState([]);
  const [yearsAvailable, setYearsAvailable] = useState([currentYear]);
  const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalCount, setTotalCount] = useState(0);

  const dispatch = useDispatch();

  const fetchPaySlips = useCallback(async () => {
    setIsLoading(true);
    try {
      const data = await getEmployeePaySlips(employeeID, year, page, rowsPerPage);

      const formattedSlips = data?.payload?.rows.map((slip) => ({
        ...slip,
        payrollMonth: formatPaymentDateMonth(slip?.payrollMonth),
        paymentDate: formatPaymentDateMonth(slip?.paymentDate, true),
      }));
      setPaySlips(formattedSlips);
      setTotalCount(data.totalCount);
      const years = data?.payload?.years;
      if (years && years.length > 0) {
        setYearsAvailable(years);
      }
    } catch (error) {
      console.error("Failed to fetch pay slips:", error);
      showSnackbar({ dispatch, type: "error", message: "Failed to fetch pay slips" });
    } finally {
      setIsLoading(false);
    }
  }, [employeeID, year, page, rowsPerPage, dispatch]);

  useEffect(() => {
    fetchPaySlips();
  }, [fetchPaySlips]);

  const handleYearChange = (selectedYear) => {
    setYear(selectedYear);
    setPage(0);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const downloadPayslip = async (payrollId) => {
    const response = await getRequest(
      `${baseUrl}/employee/${employeeID}/payroll/${payrollId}?action=pdf`,
      true,
      "export"
    );

    if (!response?.ok) {
      return;
    }
    const url = window.URL.createObjectURL(response?.payload);
    const link = document.createElement("a");
    link.style.display = "none";
    link.href = url;

    link.download = "Salary Slip";
    document.body.appendChild(link);
    link.click();
    window.URL.revokeObjectURL(url);
    showSnackbar({ dispatch, type: "success", message: "Pay slip downloaded successfully!" });
  };

  const handleAction = useCallback((action, row) => {
    if (action.label === "Download") {
      downloadPayslip(row.payrollId);
    }
  }, []);

  const columns = [
    { id: "payrollMonth", label: "Month" },
    {
      id: "allowance",
      label: "Allowance",
      render: (value) => <p className="para-body-m-regular">{value}</p>,
    },
    {
      id: "deductions",
      label: "Deductions",
      render: (value) => <p className="para-body-m-regular">{value}</p>,
    },
    {
      id: "netSalary",
      label: "Net Pay",
      render: (value) => <p className="para-body-m-regular">{value}</p>,
    },
    { id: "paymentDate", label: "Payment Date" },
    {
      id: "actions",
      label: "Actions",
      align: "right",
      type: "action",
      actions: [
        {
          icon: <FileDownloadOutlinedIcon className="payslip-download-icon" />,
          label: "Download",
        },
      ],
    },
  ];

  const yearOptions = (yearsAvailable?.length > 0 ? yearsAvailable : [currentYear]).map((filterYear) => ({
    key: filterYear,
    label: filterYear,
  }));

  return (
    <div className="flex flex-col">
      {isLoading && <PageLoader />}
      <div className="pt-175 px-2">
        <h4 className="m-0 heading-h4-semibold">Pay Slips</h4>
        <div className="payslip-filter para-body-s-medium flex gap-075 items-center justify-start py-075">
          <span>Filter:</span>
          <CustomDropdown
            options={yearOptions}
            onSelect={handleYearChange}
            buttonLabel={year}
            disabled={yearsAvailable.length < 2}
            style={{
              fontFamily: "var(--font-family-paragraph, Inter)",
              height: "28px",
              padding: "2px 12px",
              borderRadius: "var(--border-radius-full)",
              fontSize: "var(--font-size-sm, 12px)",
              fontWeight: "var(--font-weight-medium)",
              lineHeight: "var(--line-height-sm, 18px)",
              border: "1px solid var(--color-border-neutral, #888)",
              opacity: yearsAvailable.length < 2 ? 0.5 : 1,
              cursor: yearsAvailable.length < 2 ? "not-allowed" : "pointer",
            }}
          />
        </div>
      </div>
      {paySlips.length > 0 ? (
        <TableComponent
          columns={columns}
          data={paySlips}
          totalCount={totalCount}
          page={page}
          rowsPerPage={rowsPerPage}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
          rowsPerPageOptions={[10, 25, 50]}
          onAction={handleAction}
        />
      ) : (
        <NoPaySlipsFound />
      )}
    </div>
  );
}

export default PaySlips;
