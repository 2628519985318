import React from "react";

function BackIcon(props) {
  return (
    <svg width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M4.41394 9.99997L10.7069 16.293L9.29294 17.707L0.585938 8.99997L9.29294 0.292969L10.7069 1.70697L4.41394 7.99997H18.9999V9.99997H4.41394Z"
        fill="black"
      />
    </svg>
  );
}

export default BackIcon;
