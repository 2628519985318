import { useEffect, useState } from "react";
import { Chip, Divider } from "@mui/material";
import { differenceInDays } from "date-fns";
import { convertDate, getWorkingDuration } from "components/Screens/CommonUtils";
import { baseUrl } from "util/APIBaseUrl";

import LeaveType from "../Common/LeaveType";
import EmployeeAvatar from "components/Screens/Timeoff/TimeoffAdmin/EmployeeTimeOffDetails/EmployeeAvatar";

import PendingTimeIcon from "assets/images/pending-timer.svg";
import LeftArrow from "assets/images/left-arrow.svg";
import AvatarImage from "assets/images/fallback-image.svg";
import RejectedIcon from "assets/images/rejected.svg";
import CheckMarksIcon from "assets/images/accepted.svg";

import "./HistoryLeavesCard.css";

const HistoryLeavesCard = ({
  leave: {
    leaveType,
    actionByName,
    employeeId,
    employeeName,
    leaveStatus,
    dateFrom,
    status,
    dateTo,
    createdAt,
    description,
    noOfLeaves,
    pictureVersion,
    cancelledReason,
    approvedReason,
    rejectedReason
  },
}
) => {

  const [imageSrc, setImageSrc] = useState(AvatarImage);

  useEffect(() => {
    if (employeeId && pictureVersion) {
      const empImageUrl = `${baseUrl}/employee/${employeeId}/logo?id=${pictureVersion}&variant=thumbnail`;
      setImageSrc(empImageUrl);
    } else {
      setImageSrc(AvatarImage);
    }
  }, [employeeId, pictureVersion]);

  return (
    <div className="history-leaves-card-container pt-05 space-y-1">
      <div className="pending-leaves-top-section pb-05">
        <div className="pending-leaves-date">
          <img className="pending-leaves-time-icon" src={PendingTimeIcon} alt="pending time" />
          <div>
            <div className="pending-leave-reason">{leaveType}</div>
            <div className="pending-leave-duration">
              <div className="pending-leave-date">{convertDate(dateFrom)}</div>{" "}
              <img className="pending-leave-arrow" src={LeftArrow} alt="left arrow" />{" "}
              <div className="pending-leave-date">{convertDate(dateTo)}</div>
            </div>
          </div>
        </div>
      </div>

      <Divider light />
      <div className="pending-timeoff-details-wrapper pb-05">
        <div className="pending-timeoff-details">
          <div className="pending-timeoff-details-text">Duration</div>
          <Chip variant="outlined" label={`${noOfLeaves}  ${noOfLeaves == 1 ? "leave" : "leaves"}`} />
        </div>

        <div className="pending-timeoff-details">
          <div className="pending-timeoff-details-text">Notice Period</div>
          <Chip variant="outlined" label={`${differenceInDays(new Date(dateFrom), new Date(createdAt))} Days`} />
        </div>
        <div className="pending-timeoff-details">
          <div className="pending-timeoff-details-text">Status</div>
          <LeaveType type={leaveStatus} style={{ minWidth: "107px" }} />
        </div>
      </div>

      {(cancelledReason || approvedReason || rejectedReason || description) ?
        <>
          <Divider light />
          <div className="pending-timeoff-comments">Comments:</div>
          {description && <div className="flex ml-1 pb-05">
            <EmployeeAvatar
              img={imageSrc}
              size={{
                width: "44px",
                height: "44px",
                borderRadius: "50%",
              }}
            />

            <div className="history-timeoff-comment-wrapper">
              <div className="history-timeoff-comment">{description ? description : ""}</div>
              <div className="history-timeoff-comment-author pb-025">{employeeName}</div>
            </div>
          </div>}
          {(cancelledReason || approvedReason || rejectedReason) ?
            <div className="flex ml-1 pb-05">
              <EmployeeAvatar
                img={imageSrc}
                size={{
                  width: "44px",
                  height: "44px",
                  borderRadius: "50%",
                }}
              />

              <div className="history-timeoff-comment-wrapper">
                <div className="history-timeoff-comment">{approvedReason || rejectedReason || cancelledReason}</div>
              </div>
            </div>
            : ""}

        </>
        : <></>
      }

      <div className="mt-1 ml-1">
        {
          status === "Approved" ?
            <button className="button button-md cursor-pointer accept flex">
              <img className={'pr-05'} src={CheckMarksIcon} alt={"Accepted"} />
              <span>Accepted</span>
            </button> :
            <button className="button button-md cursor-pointer reject flex">
              <img className={'pr-05'} src={RejectedIcon} alt={"Accepted"} />
              <span>Rejected</span>
            </button>
        }
      </div>
      <div className="history-timeoff-request-date pb-1 ml-1">
        Request date: {convertDate(createdAt)} ({getWorkingDuration(createdAt)} ago)
      </div>
    </div>
  );
};

export default HistoryLeavesCard;
