import { call, put, takeEvery } from "redux-saga/effects";
//actions
import { LogoSuccess, LogoFailure } from "../../actions/Logo";
//constant
import { LOGO } from "../../constants/Logo";
//request type
import getRequest from "../../../util/APIHelperGet";
import { baseUrl } from "../../../util/APIBaseUrl";

export const fetchProfileLogoRequest = async (actions) => {
    // eslint-disable-next-line no-return-await
    return await getRequest(`${baseUrl}/${actions.payload.request}`)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return error;
        });
};

function* fetchProfileLogo(params) {
    try {
        let response = yield call(fetchProfileLogoRequest, params);
        yield put(LogoSuccess(response));
    } catch (error) {
        console.error(error);
        yield put(LogoFailure(error));
    }
}

export default function* rootSaga() {
    yield takeEvery(LOGO, fetchProfileLogo);
}
