import { RESET_ADD_USER, ADD_USER, ADD_USER_SUCCESS, ADD_USER_FAILURE } from "../../constants/Settings/AddUser";

// for add employee in admin workforce table

export const ResetAddUser = (data) => {
  return {
    type: RESET_ADD_USER,
    payload: data,
  };
};

export const AddUser = (data) => {
  return {
    type: ADD_USER,
    payload: data,
  };
};
export const AddUserSuccess = (payload) => {
  return {
    type: ADD_USER_SUCCESS,
    payload,
  };
};
export const AddUserFailure = (payload) => {
  return {
    type: ADD_USER_FAILURE,
    payload,
  };
};
