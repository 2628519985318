import { Chip } from "@mui/material";

import PendingLeaveIcon from "assets/images/pending-leave-chip-icon.svg";
import CalendarIcon from "assets/images/scheduled-calendar.svg";
import RejectedIcon from "assets/images/rejected-icon.svg";
import CheckMarksIcon from "assets/images/taken-leave-icon.svg";

import "./LeaveType.css";

function LeaveType({ type, style }) {
  type = type?.toLowerCase?.();
  let chipClassName = "pending-chip";
  let chipIcon = "pending-icon";
  let chipLabel = "Pending";
  let pendingIcon = PendingLeaveIcon;

  if (type === "scheduled") {
    chipClassName = "scheduled-chip";
    chipIcon = "scheduled-icon";
    chipLabel = "Scheduled";
    pendingIcon = CalendarIcon;
  } else if (type === "taken") {
    chipClassName = "taken-chip";
    chipIcon = "scheduled-icon";
    chipLabel = "Taken";
    pendingIcon = CheckMarksIcon;
  } else if (type === "approved") {
    chipClassName = "scheduled-chip";
    chipIcon = "scheduled-icon";
    chipLabel = "Taken";
    pendingIcon = CalendarIcon;
  } else if (type === "rejected") {
    chipClassName = "rejected-chip";
    chipIcon = "rejected-icon";
    chipLabel = "Rejected";
    pendingIcon = RejectedIcon;
  } else if (type === "cancelled") {
    chipClassName = "cancelled-chip";
    chipIcon = "cancelled-icon";
    chipLabel = "Cancelled";
    pendingIcon = "";
  }

  return (
    <Chip
      icon={pendingIcon && <img className={chipIcon} src={pendingIcon} alt={type} />}
      className={chipClassName + " label-sm custom-chip"}
      size="small"
      id="leave-status"
      label={chipLabel}
      sx={{
        ...style,
      }}
    />
  );
}

export default LeaveType;
