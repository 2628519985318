import {
  RESER_UNASSIGN_EMPLOYEE,
  UNASSIGN_EMPLOYEE,
  UNASSIGN_EMPLOYEE_SUCCESS,
  UNASSIGN_EMPLOYEE_FAILURE,
} from "../../../constants/Workforce/UnAssignEmployee";

// for user authentication
export const ResetUnAssignEmployee = (data) => {
  return {
    type: RESER_UNASSIGN_EMPLOYEE,
    payload: data,
  };
};

export const UnAssignEmployee = (data) => {
  return {
    type: UNASSIGN_EMPLOYEE,
    payload: data,
  };
};
export const UnAssignEmployeeSuccess = (payload) => {
  return {
    type: UNASSIGN_EMPLOYEE_SUCCESS,
    payload,
  };
};
export const UnAssignEmployeeFailure = (payload) => {
  return {
    type: UNASSIGN_EMPLOYEE_FAILURE,
    payload,
  };
};
