import React from "react";
//components
import SignIn from "./SignIn";

function index(props) {
    const [loginRole, setLoginRole] = React.useState(null);
    const handleChildRole = (role) => {
        setLoginRole(role);
    };
    return <SignIn />;
}

export default index;
