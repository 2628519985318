import React, { useEffect, useState } from 'react';
import { Grid } from '@mui/material';

import PageTitle from "components/Widgets/PageTitle";
import PayrollDetails from './PayrollDetails';

function PayrollHistoryDetail() {
  const [payrollMonth, setPayrollMonth] = useState('');

  const getPayrollMonth = (month) => {
    setPayrollMonth(month);
  };
  return (
    <Grid sx={{ background: '#FBFAFF', minHeight: 'calc(100vh - 51px)' }}>
      <Grid
        container
        rowSpacing={3}
        sx={{
          marginTop: '5px',
        }}
      >
        <PageTitle
          background="#FBFAFF"
          page={{
            title: `Payroll for ${payrollMonth}`,
            linkName: 'Payroll',
            link: 'back',
            child: "details"
          }}
        />
      </Grid>

      <Grid container>
        <PayrollDetails getPayrollMonth={getPayrollMonth} />
      </Grid>
    </Grid>
  );
}

export default PayrollHistoryDetail;
