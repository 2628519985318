import React from "react";

const AddSwappedIcon = () => {
  return (
    <svg width="39" height="39" viewBox="0 0 39 39" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="0.5" y="0.5" width="38" height="38" rx="11.5" fill="white" stroke="#F1EFFF" />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M19.5837 19.4167H24.8337V20.5834H19.5837V25.8334H18.417V20.5834H13.167V19.4167H18.417V14.1667H19.5837V19.4167Z"
        fill="#131599"
      />
    </svg>
  );
};

export default AddSwappedIcon;
