import React from "react";
import { Helmet } from "react-helmet";
import { Grid, Typography, Box } from "@mui/material";

import PageHeader from "components/common/PageHeader";

import "../termsandpolicy.css";

const EmployeePrivacyPolicy = () => {
  return (
    <>
      <Helmet>
        <title>Edge | Employee Policy</title>
      </Helmet>
      <Box sx={{ overflow: "auto" }}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <PageHeader title={"Employee Policy"} subTitle={"Last Updated: February 13, 2024"} />
          </Grid>
          <Grid item xs={12} container justifyContent="center" alignItems="center">
            <Grid item xs={8} sx={{ marginY: 6 }}>
              <Typography className="label-terms">
                By accepting employment with Edge Services & Solutions LLC and our affiliated entities (
                <b className="link-class">“Edge”</b>,<b className="link-class">“Company”</b>,{" "}
                <b className="link-class">“we”</b> or <b className="link-class">“us”</b>), you expressly acknowledge
                that you have read, understand and agree to all of the terms of this Employee Privacy Policy (
                <b className="link-class">“Privacy Policy”</b>) as outlined below and as it may be modified by us from
                time to time with or without prior notice.
              </Typography>
              <br />
              <Typography className="label-terms">
                While this Privacy Policy is intended to describe the broadest range of our data processing activities
                globally, those processing activities may be more limited in some jurisdictions based on the
                restrictions of their laws. For example, the laws of a country may limit the types of personal data we
                can collect or the manner in which we process that data. In those instances, we adjust our internal
                policies and practices to reflect the requirements of local law.
              </Typography>
              <br />
              <Typography className="label-terms">
                For personal data collected under this Privacy Policy, the controller will be Edge and the Company
                affiliates by which you are employed. For, (i) specific security concerns around your data, (ii) in the
                event you feel that you have not received proper attention to your data request, or (iii) have any other
                data privacy concerns, please contact us by emailing{" "}
                <a href="mailto:privacy@onedge.co" className="link-class">
                  privacy@onedge.co
                </a>
                .
              </Typography>
              <br />
              <Typography className="label-terms">
                <span className="terms-list-title">
                  Who is collecting your personal data (who is the data controller)?
                </span>
              </Typography>
              <Typography className="label-terms">
                The Edge entity that is a party to your employment contract or contract for services or otherwise
                employs you will be the data controller of your personal data.
              </Typography>
              <br />
              <Typography className="label-terms">
                <span className="terms-list-title">Applicability of Other Edge Privacy Policies</span>
              </Typography>
              <Typography className="label-terms">
                The websites of Edge (e.g.,{" "}
                <a href="https://onedge.co" className="link-class" target="blank">
                  https://onedge.co
                </a>
                ) have separate privacy policies and terms of use that apply to their use. Additionally, some of our
                third party products and services may have separate privacy policies and terms of use that apply to
                their use. Any personal data collected in connection with your use of those websites or products and
                services are not subject to this Privacy Policy. If you are unsure how or if this Privacy Policy applies
                to you, please contact us at{" "}
                <a href="mailto:privacy@onedge.co" className="link-class">
                  privacy@onedge.co
                </a>
                .
              </Typography>
              <br />
              <Typography className="label-terms">
                <span className="terms-list-title">What is Personal Data?</span>
              </Typography>
              <Typography className="label-terms">
                Personal data, also known as personally identifiable information, for purposes of this Privacy Policy
                means any information that (i) directly and clearly identifies an individual, or (ii) can be used in
                combination with other information to identify an individual. Personal data does not include such
                information if it is anonymous or if it has been rendered de-identified by removing personal
                identifiers.
              </Typography>
              <br />
              <Typography className="label-terms">
                Examples of personal data include:
                <br />
                <ol start="1">
                  <li className="terms-list-text pl-1">An individual’s name.</li>
                  <li className="terms-list-text pl-1">Employee ID number.</li>

                  <li className="terms-list-text pl-1">Home address.</li>

                  <li className="terms-list-text pl-1">Home phone number.</li>

                  <li className="terms-list-text pl-1">Personal email address.</li>
                  <li className="terms-list-text pl-1">Names of family members.</li>

                  <li className="terms-list-text pl-1">Date of birth.</li>
                </ol>
              </Typography>
              <br />
              <Typography className="label-terms">
                <span className="terms-list-title">What is Sensitive Personal Data?</span>
              </Typography>
              <Typography className="label-terms">
                Sensitive personal data is a subset of personal data that may be more sensitive in nature for the
                individual concerned.
              </Typography>
              <br />
              <Typography className="label-terms">
                Examples of sensitive personal data include:
                <ol start="1">
                  <li className="terms-list-text pl-1">Race and ethnic information.</li>
                  <li className="terms-list-text pl-1">Sexual orientation.</li>

                  <li className="terms-list-text pl-1">Political/religious beliefs.</li>

                  <li className="terms-list-text pl-1">
                    Social security or other taxpayer/government issued identification numbers.
                  </li>

                  <li className="terms-list-text pl-1">Financial information.</li>
                  <li className="terms-list-text pl-1">National identification number or passport information.</li>

                  <li className="terms-list-text pl-1">
                    Health or medical information, including genetic information.
                  </li>
                  <li className="terms-list-text pl-1">Criminal records.</li>
                </ol>
              </Typography>
              <br />

              <Typography className="label-terms">
                <span className="terms-list-title">What Personal Data Do We Collect?</span>
              </Typography>
              <Typography className="label-terms">
                We collect and maintain different types of personal data about you in accordance with applicable law.
                This includes the following:
                <ul>
                  <li className="terms-list-text pl-1">Name</li>
                  <li className="terms-list-text pl-1">Gender</li>

                  <li className="terms-list-text pl-1">Home address</li>

                  <li className="terms-list-text pl-1">Telephone number</li>

                  <li className="terms-list-text pl-1">
                    Browsing and click history, device information, browser information, operating system information,
                    IP address, page views, button clicks, and information about how you navigate our platform and
                    services.
                  </li>

                  <li className="terms-list-text pl-1">Date of birth</li>

                  <li className="terms-list-text pl-1">Marital status</li>

                  <li className="terms-list-text pl-1">Employee identification number</li>

                  <li className="terms-list-text pl-1">Emergency contacts</li>

                  <li className="terms-list-text pl-1">Residency</li>

                  <li className="terms-list-text pl-1">Work permit status</li>

                  <li className="terms-list-text pl-1">Military status</li>

                  <li className="terms-list-text pl-1">Nationality</li>

                  <li className="terms-list-text pl-1">Passport information</li>

                  <li className="terms-list-text pl-1">
                    Social security or other taxpayer/government identification number
                  </li>

                  <li className="terms-list-text pl-1">Payroll information, banking details</li>

                  <li className="terms-list-text pl-1">Wage and benefit information</li>

                  <li className="terms-list-text pl-1">Retirement account information</li>

                  <li className="terms-list-text pl-1">
                    Sick pay, Paid Time Off, retirement accounts, pensions, insurance and other benefits information
                    (including the gender, age, nationality and passport information for any spouse, minor children or
                    other eligible dependents and beneficiaries).
                  </li>

                  <li className="terms-list-text pl-1">
                    Information from interviews and phone-screenings you may have had, if any.
                  </li>

                  <li className="terms-list-text pl-1">
                    Date of hire, date(s) of promotion(s), work history, technical skills, educational background,
                    professional certifications and registrations, language capabilities, and training records.
                  </li>

                  <li className="terms-list-text pl-1">Beneficiary and emergency contact information.</li>
                  <li className="terms-list-text pl-1">
                    Forms and information relating to the application for, or in respect of changes to, employee health
                    and welfare benefits; including, short and long-term disability, medical and dental care, etc.
                  </li>

                  <li className="terms-list-text pl-1">
                    Records of work absences, vacation/paid time off, entitlement and requests, salary history and
                    expectations, performance appraisals, letters of appreciation and commendation, and disciplinary and
                    grievance procedures (including monitoring compliance with and enforcing our policies).
                  </li>
                </ul>
              </Typography>
              <br />
              <Typography className="label-terms">
                Where permitted by law and applicable we may collect the results of credit and criminal background
                checks, screening, health certifications, driving license number, vehicle registration, and driving
                history.
                <ol start="1">
                  <li className="terms-list-text pl-1">
                    Information required for us to comply with laws, the requests and directions of law enforcement
                    authorities or court orders (e.g., child support and debt payment information).
                  </li>
                  <li className="terms-list-text pl-1">
                    Acknowledgements regarding our policies, including employee handbooks, ethics and/or conflicts of
                    interest policies, and computer and other corporate resource usage policies.
                  </li>

                  <li className="terms-list-text pl-1">
                    Information captured on security systems and key card entry systems.
                  </li>

                  <li className="terms-list-text pl-1">
                    Voicemails, e-mails, correspondence, documents, and other work product and communications created,
                    stored or transmitted for professional or job-related purposes using our networks, applications,
                    devices, computers, or communications equipment.
                  </li>

                  <li className="terms-list-text pl-1">
                    Date of resignation or termination, reason for resignation or termination, information relating to
                    administering termination of employment (e.g. references).
                  </li>

                  <li className="terms-list-text pl-1">Letters of offer and acceptance of employment.</li>

                  <li className="terms-list-text pl-1">
                    Your resume or CV, cover letter, previous and/or relevant work experience or other experience,
                    education, transcripts, or other information you provide to us in support of an application and/or
                    the application and recruitment process.
                  </li>

                  <li className="terms-list-text pl-1">References and interview notes.</li>

                  <li className="terms-list-text pl-1">
                    Information relating to any previous applications you may have made to Edge and/or any previous
                    employment history with Edge.
                  </li>
                </ol>
              </Typography>
              <br />
              <Typography className="label-terms">
                Apart from personal data relating to yourself, you may also provide us with personal data of related
                parties, notably your dependents and other family members, for purposes of your HR administration and
                management, including the administration of benefits and to contact your next-of-kin in an emergency.
                Before you provide such third-party personal data to us you must first inform these third parties of any
                such data which you intend to provide and of the processing to be carried out by us. You must ensure and
                secure evidence that these related parties, or their legal representatives if they are minors, have
                given their free and express consent that their personal data may be processed by Edge and/or its
                affiliates and subcontractors for the purposes described in this Privacy Policy.
              </Typography>
              <br />
              <Typography className="label-terms">
                <span className="terms-list-title">How is Data Collected?</span>
              </Typography>
              <Typography className="label-terms">
                Generally, we collect personal data directly from you in circumstances where you provide personal data
                (during the onboarding process, for example). However, in some instances, the personal data we collect
                has been inferred about you based on other information you provide us, through your interactions with
                us, or from third parties. When we collect your personal data from third parties it is either because
                you have given us express consent to do so, your consent was implied by your actions (e.g., your use of
                a third-party employee service made available to you by us), or because you provided explicit consent to
                the third-party to provide the personal data to us. Where permitted or required by applicable law or
                regulatory requirements, we may collect personal data about you without your knowledge or consent.
              </Typography>
              <br />
              <Typography className="label-terms">
                We reserve the right to monitor the use of our equipment, devices, computers, network, applications,
                software, and similar assets and resources for the safety and protection of employees and intellectual
                property. In the event such monitoring occurs, it may result in the collection of personal data about
                you. If required by applicable law, we will notify you of such monitoring and obtain your consent.
              </Typography>
              <br />
              <Typography className="label-terms">
                <span className="terms-list-title">How We Process and Use Your Personal Data</span>
              </Typography>
              <Typography className="label-terms">
                We may collect and process your personal data in the systems for various purposes subject to local laws
                and any applicable collective bargaining agreements and works council agreements, including:
                <ul>
                  <li className="terms-list-text pl-1">
                    Improving and personalizing our platform and services by using aggregated information collected
                    detailing your internet activity, browsing history and geolocation data
                  </li>
                  <li className="terms-list-text pl-1">
                    Recruitment, training, development, promotion, career, and succession planning
                  </li>

                  <li className="terms-list-text pl-1">
                    Appropriate vetting for recruitment and team allocation including, where relevant and appropriate,
                    credit checks, right to work verification, identity fraud checks, relevant employment history,
                    relevant regulatory status and professional qualifications
                  </li>

                  <li className="terms-list-text pl-1">
                    Providing and administering remuneration, salary, benefits, and incentive schemes and providing
                    relevant information to payroll
                  </li>

                  <li className="terms-list-text pl-1">
                    Allocating and managing duties and responsibilities and the business activities to which they relate
                  </li>

                  <li className="terms-list-text pl-1">
                    Identifying and communicating effectively with other employees and management
                  </li>

                  <li className="terms-list-text pl-1">
                    Managing and operating conduct, performance, capability, absence, and grievance related reviews,
                    allegations, complaints, investigations, and processes and other informal and formal human resources
                    (<b>“HR”</b>) processes and making related management decisions
                  </li>

                  <li className="terms-list-text pl-1">
                    Consultations or negotiations with representatives of the workforce
                  </li>

                  <li className="terms-list-text pl-1">
                    Conducting surveys for benchmarking and identifying improved ways of working employee relations and
                    engagement at work (these will often be anonymous but may include profiling data such as age to
                    support analysis of results)
                  </li>

                  <li className="terms-list-text pl-1">
                    Processing information about absence or medical information regarding physical or mental health or
                    condition in order to assess eligibility for incapacity or permanent disability related remuneration
                    or benefits, determine fitness for work, facilitate a return to work, make adjustments or reasonable
                    accommodations to duties or the workplace and make management decisions regarding employment or
                    engagement or continued employment or engagement or redeployment and conduct related management
                    processes
                  </li>

                  <li className="terms-list-text pl-1">
                    For planning, managing and carrying out restructuring or redundancies or other change programs
                    including appropriate consultation, selection, alternative employment searches and related
                    management decisions
                  </li>

                  <li className="terms-list-text pl-1">
                    Operating email, IT, Internet, intranet, social media, HR related and other company policies and
                    procedures. The company carries out monitoring of Edge’s IT systems to protect and maintain the
                    systems, to ensure compliance with Edge policies and to locate information through searches where
                    needed for a legitimate business purpose
                  </li>

                  <li className="terms-list-text pl-1">
                    Complying with applicable laws and regulation (for example maternity or parental leave legislation,
                    working time and health and safety legislation, taxation rules, worker consultation requirements,
                    other employment laws and regulation to which Edge is subject in the conduct of its business)
                  </li>

                  <li className="terms-list-text pl-1">
                    Monitoring programs to ensure equality of opportunity and diversity with regard to personal
                    characteristics protected under local anti-discrimination laws
                  </li>

                  <li className="terms-list-text pl-1">
                    Planning, due diligence and implementation in relation to a commercial transaction or service
                    transfer involving Edge that impacts on your relationship with Edge (for example mergers and
                    acquisitions or a transfer of your employment under automatic transfer rules)
                  </li>

                  <li className="terms-list-text pl-1">
                    For business operational and reporting documentation such as the preparation of annual reports or
                    tenders for work or client team records including the use of your personal photo
                  </li>

                  <li className="terms-list-text pl-1">
                    In order to operate the relationship with third-party customer and suppliers including the
                    disclosure of relevant vetting information in line with the appropriate requirements of regulated
                    customers to those customers, contact or professional CV details or resume, or your personal photo
                    for identification to clients or disclosure of information to data processors for the provision of
                    services to Edge
                  </li>

                  <li className="terms-list-text pl-1">
                    Where relevant for publishing appropriate internal or external communications or publicity material
                    including via social media in appropriate circumstances, provided that privacy rights are preserved
                  </li>

                  <li className="terms-list-text pl-1">
                    To enforce our legal rights and obligations, and for any purposes in connection with any legal
                    claims made by, against or otherwise involving you
                  </li>

                  <li className="terms-list-text pl-1">
                    To comply with lawful requests by public authorities (including without limitation to meet national
                    security or law enforcement requirements), discovery requests, or where otherwise required or
                    permitted by applicable laws, court orders, government regulations, or regulatory authorities
                    (including without limitation data protection, tax and employment), whether within or outside your
                    country
                  </li>

                  <li className="terms-list-text pl-1">
                    Other purposes permitted by applicable privacy and data protection legislation including where
                    applicable, legitimate interests pursued by Edge where this is not overridden by the interests or
                    fundamental rights and freedoms of employees.
                  </li>
                </ul>
              </Typography>
              <br />
              <Typography className="label-terms">
                <span className="terms-list-title">Legal Basis for processing</span>
              </Typography>
              <Typography className="label-terms">
                Where applicable data protection laws require us to process your personal data on the basis of a
                specific lawful justification, we generally process your personal data under one of the following bases:
              </Typography>
              <br />
              <Typography className="label-terms">
                Compliance with a legal obligation to which Edge is subject; Entering into at-will employment (for US
                only) or performance under an employment contract with Edge; For Edge’s legitimate interests being those
                purposes described in the section above headed “How We Process and Use Your Personal Information”; Your
                consent where required and a legitimate legal basis under applicable local laws.
              </Typography>
              <br />
              <Typography className="label-terms">
                We may on occasion process your personal data for the purpose of the legitimate interests of a
                third-party where this is not overridden by your interests.
              </Typography>
              <br />
              <Typography className="label-terms">
                <span className="terms-list-title">Processing of Special Categories of Personal Data</span>
              </Typography>
              <Typography className="label-terms">
                “Special Categories of Personal Data” includes information revealing racial or ethnic origin, political
                opinions, religious or philosophical beliefs, trade union membership, health, sex life or sexual
                orientation, as well as genetic and biometric data.
              </Typography>
              <br />
              <Typography className="label-terms">
                From time to time you may provide us with information which constitutes Special Categories of Personal
                Data or information from which Special Categories of Personal Data may be deduced. In such cases, where
                required by law, we will obtain your express written consent to our processing of Special Categories of
                Personal Data. If separate consent is not required by local law, by providing this information to Edge,
                you give your freely given, informed, explicit consent for us to process those Special Categories of
                Personal Data for the purposes set out in How We Process and Use Your Personal Data section above.
              </Typography>
              <br />
              <Typography className="label-terms">
                You may withdraw your consent at any time by contacting us. Where you have withdrawn consent but Edge
                retains the personal data we will only continue to process that Special Category Personal Data where
                necessary for those purposes where we have another appropriate legal basis such as processing necessary
                to comply with legal obligations related to employment or social security. However, this may mean that
                we cannot (for example) administer certain benefits or contact your next-of-kin in an emergency or
                provide support to you above and beyond our legal obligations. You give your knowledgeable, freely
                given, express consent to Edge for Edge to use, disclose and otherwise process any personal health
                information about you that is provided to Edge by any of your personal health information custodians,
                for the purposes set out in the How We Process and Use Your Personal Information section above.
              </Typography>
              <br />
              <Typography className="label-terms">
                <span className="terms-list-title">Sharing Personal Data</span>
              </Typography>
              <Typography className="label-terms">
                Your personal data may be shared, including to our affiliates, subsidiaries, and other third parties, as
                follows:
                <ol start="1">
                  <li className="terms-list-text pl-1">Where you request us or provide your consent to us.</li>
                  <li className="terms-list-text pl-1">
                    In order to carry out the uses of personal data described above (see, How We Process and Use Your
                    Personal Data). When using or collaborating with third parties in the operation of our business,
                    including in connection with providing many of the benefits and services we offer our employees
                    (e.g., human resources information systems, financial investment service providers, insurance
                    providers). When we share personal data with third parties we typically require that they only use
                    or disclose such personal data in a manner consistent with the use and disclosure provisions of this
                    Privacy Policy and applicable law.
                  </li>

                  <li className="terms-list-text pl-1">
                    We may buy or sell businesses and other assets. In such transactions, employee data is generally one
                    of the transferred business assets and we reserve the right to include your personal data as an
                    asset in any such transfer. Also, in the event that we, or substantially all of our assets, are
                    acquired, your personal data may be one of the transferred assets.
                  </li>

                  <li className="terms-list-text pl-1">
                    Where required by law, by order or requirement of a court, administrative agency, or government
                    tribunal, which includes in response to a lawful request by public authorities, including to meet
                    national security or law enforcement requirements or in response to legal process.
                  </li>

                  <li className="terms-list-text pl-1">
                    If we determine it is necessary or desirable to comply with the law or to protect or defend our
                    rights or property.
                  </li>

                  <li className="terms-list-text pl-1">
                    As necessary to protect the rights, privacy, safety, or property of an identifiable person or group
                    or to detect, prevent or otherwise address fraud, security or technical issues, or to protect
                    against harm to the rights, property or safety of Edge, our users, applicants, candidates, employees
                    or the public or as otherwise required by law.
                  </li>

                  <li className="terms-list-text pl-1">
                    Where the personal data is public and exempted from coverage under applicable data protection laws.
                  </li>

                  <li className="terms-list-text pl-1">
                    To seek advice from our lawyers and other professional advisors.
                  </li>

                  <li className="terms-list-text pl-1">
                    To professional advisors (e.g. bankers, lawyers, accountants) and potential buyers and vendors in
                    connection with the sale, disposal or acquisition by use of a business or assets.
                  </li>
                </ol>
              </Typography>
              <br />
              <Typography className="label-terms">
                <span className="terms-list-title">Access to Personal Data We Collect</span>
              </Typography>
              <Typography className="label-terms">
                To the extent access is required by applicable law, you can request access to the personal data that we
                hold about you. There are two separate processes to request access to personal data. If you want to
                review personal data, please submit a request by contacting us. If you want to correct your personal
                data, you may do this through the self-serve capabilities within our platform.
              </Typography>
              <br />
              <Typography className="label-terms">
                When requesting access to your personal data, please note that we may request specific information from
                you to enable us to confirm your identity and right to access, as well as to search for and provide you
                with the personal data that we hold about you.
              </Typography>
              <br />
              <Typography className="label-terms">
                We reserve the right not to grant access to personal data that we hold about you if access is not
                required by applicable law. There are also instances where applicable law or regulatory requirements
                allow or require us to refuse to provide some or all of the personal data that we hold about you. In
                addition, the personal data may have been destroyed, erased or made anonymous. In the event that we
                cannot provide you with access to your personal data, we will inform you of the reasons why, subject to
                any legal or regulatory restrictions.
              </Typography>
              <br />
              <Typography className="label-terms">
                <span className="terms-list-title">Correction of Collected Personal Data</span>
              </Typography>
              <Typography className="label-terms">
                We endeavor to ensure that personal data in our possession is accurate, current and complete. If an
                individual believes that the personal data about him or her is incorrect, incomplete or outdated, he or
                she may request the revision or correction of that data. We reserve the right not to change any personal
                data we consider to be accurate or if such correction is not required by applicable law.
              </Typography>
              <br />

              <Typography className="label-terms">
                <span className="terms-list-title">Retention of Collected Data</span>
              </Typography>
              <Typography className="label-terms">
                Except as otherwise permitted or required by applicable law or regulatory requirements, we may retain
                your personal data only for as long as we believe it is necessary to fulfill the purposes for which the
                personal data was collected (including, for the purpose of meeting any legal, accounting or other
                reporting requirements or obligations) and for IT archival purposes.
              </Typography>
              <br />
              <Typography className="label-terms">
                You may request that we delete the personal data about you that we hold, provided that we reserve the
                right not to grant such request if we are not required to delete personal data under applicable law.
                There are instances where applicable law or regulatory requirements allow or require us to refuse to
                delete this personal data. In the event that we cannot delete your personal data, we will inform you of
                the reasons why, subject to any legal or regulatory restrictions.
              </Typography>
              <br />
              <Typography className="label-terms">
                To request deletion of your personal data, please submit your request via email to{" "}
                <a href="mailto:privacy@onedge.co" className="link-class">
                  privacy@onedge.co
                </a>
              </Typography>
              <br />
              <Typography className="label-terms">
                <span className="terms-list-title">Resolving Concerns</span>
              </Typography>
              <Typography className="label-terms">
                If you have questions or concerns regarding the handling of your personal data, please contact us at the
                following email address:{" "}
                <a href="mailto:privacy@onedge.co" className="link-class">
                  privacy@onedge.co
                </a>
              </Typography>
              <br />
              <Typography className="label-terms">
                <span className="terms-list-title">Changes to Privacy Policy</span>
              </Typography>
              <Typography className="label-terms">
                We may change this Privacy Policy at any time by posting notice of such a change in the revision table
                below. The effective date of each version of this Privacy Policy is identified in the revision table.
              </Typography>
              <br />
              <Typography className="label-terms">
                <span className="terms-list-title">Security of Collected Information</span>
              </Typography>
              <Typography className="label-terms">
                We are committed to protecting the security of the personal data collected, and we take reasonable
                physical, electronic, and administrative safeguards to help protect the data from unauthorized or
                inappropriate access or use.
              </Typography>
              <br />
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default EmployeePrivacyPolicy;
0;
