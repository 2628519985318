import {
    RESET_CUSTOMER_ACTIVATION_EMAIL,
    CUSTOMER_ACTIVATION_EMAIL,
    CUSTOMER_ACTIVATION_EMAIL_SUCCESS,
    CUSTOMER_ACTIVATION_EMAIL_FAILURE,
} from "../../constants/Customers/ActivationEmail";

// for employee information

export const ResetCustomerActivationEmail = (data) => {
    return {
        type: RESET_CUSTOMER_ACTIVATION_EMAIL,
        payload: data,
    };
};

export const CustomerActivationEmail = (data) => {
    return {
        type: CUSTOMER_ACTIVATION_EMAIL,
        payload: data,
    };
};
export const CustomerActivationEmailSuccess = (payload) => {
    return {
        type: CUSTOMER_ACTIVATION_EMAIL_SUCCESS,
        payload,
    };
};
export const CustomerActivationEmailFailure = (payload) => {
    return {
        type: CUSTOMER_ACTIVATION_EMAIL_FAILURE,
        payload,
    };
};
