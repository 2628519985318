import { useSelector } from "react-redux";
import LeaveCard from "../Common/LeaveCards";
import { useEffect, useState } from "react";
import PageLoader from "components/common/PageLoader";

const CancelledLeaves = () => {
  const [cancelledLeaveRequests, setCancelledLeaveRequests] = useState([]);
  const leaveRequests = useSelector((state) => state?.TimeoffLeaveRequests?.leaveRequests || []);
  const loadingLeaveRequests = useSelector((state) => state?.TimeoffLeaveRequests?.loadingLeaveRequests);

  useEffect(() => {
    setCancelledLeaveRequests(leaveRequests?.cancelled || []);
  }, [leaveRequests]);
  const NoDataCard = (_) => {
    return (
      <div className="no-card-container">
        <p className="no-card-text">No cancelled time-off</p>
      </div>
    );
  };

  return (
    <>
      {loadingLeaveRequests && <PageLoader />}
      <div className="pending-leaves-container-emp flex-col">
        <div className="mt-1">
          {cancelledLeaveRequests?.length ? (
            <div className="flex flex-col gap-075">
              {cancelledLeaveRequests.map((leave) => (
                <LeaveCard type={"cancelled"} data={leave} />
              ))}
            </div>
          ) : (
            <NoDataCard />
          )}
        </div>
      </div>
    </>
  );
};
export default CancelledLeaves;
