// react
import React, { useEffect, useState } from "react";

// mui
import { Box, Button, CardMedia, Grid, Typography } from "@mui/material";
// images
import avatarImage from "../../../assets/images/fallback-image.svg";

// css
import "./UpcomingHolidayCard.css";
import {
  getCountryFlag,
  getHolidayDate,
  getHolidayDay,
  getHolidayEndDate,
  getMonthFromDate,
  getProfileImage,
  isEmptyObject,
} from "../CommonUtils";

import { getOrdinalSuffix } from "util/utils";
import { baseUrl } from "util/APIBaseUrl";
// import { usePermissions } from "../../Hook";

const UpcomingHolidayCard = (props) => {
  const { holiday } = props;
  //component state
  const [imageSrc, setImageSrc] = useState(avatarImage);

  //functions

  useEffect(() => {
    if (isEmptyObject(holiday) && holiday?.Picture_Path) {
      getProfileImage(holiday?.Holiday_Id, holiday?.Picture_Version, "publicHoliday").then((img) => {
        setImageSrc(img);
      });
    } else {
      setImageSrc(avatarImage);
    }
  }, [holiday?.Picture_Path, holiday?.Holiday_Id]);

  return (
    <Box item xs={3} className="holidaycard">
      <CardMedia
        component="img"
        image={`${baseUrl}/publicHoliday/${holiday?.Holiday_Id}/logo?id=${holiday?.Picture_Version}`}
        alt={holiday?.Picture_Path}
        className="holidaycard-image"
      />
      <Grid className="holidaycard-content">
        <Grid
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "4px",
          }}
        >
          <Typography className="holidaycard-title" id="holiday-name">
            {holiday?.Name}
          </Typography>
          <Typography className="holidaycard-date" id="hoiday-date">
            {`${getHolidayDate(holiday?.StartDate)}${getOrdinalSuffix(
              getHolidayDate(holiday?.StartDate)
            )}  ${getMonthFromDate(holiday?.StartDate)}  (${getHolidayDay(holiday?.StartDate)})  ${getHolidayEndDate(
              holiday?.EndDate
            )} `}
          </Typography>
        </Grid>
        <Grid
          sx={{
            display: "flex",
            alignItems: "center",
            gap: "12px",
            marginTop: "16px",
          }}
        >
          {/* TODO: replace with Flag component */}
          {getCountryFlag(holiday?.Region)}

          {/* TODO: Button Common Components will br replaced here */}
          {/* {loggedInUserType === "customer" && ( */}
          <Button size="sm" variant="outlined" disableRipple className="holidaycard-button">
            {`${holiday?.employeesCount} Employees will be on this holiday`}
          </Button>
          {/* )} */}
        </Grid>
        {/* <Typography className="holidaycard-description">
                    Lorem ipsum dolor sit amet consectetur. Enim volutpat
                    feugiat amet augue nibh. Orci sed felis nunc aliquam sit
                    enim. Blandit et vel vulputate senectus.Lorem ipsum dolor
                    sit amet consectetur.
                </Typography> */}
      </Grid>
    </Box>
  );
};

export default UpcomingHolidayCard;
