import { call, put, takeEvery } from "redux-saga/effects";
//actions
import {
  DisableCustomerSuccess,
  DisableCustomerFailure,
} from "../../actions/Customers/DisableCustomer";
//constant
import { DISABLE_CUSTOMER } from "../../constants/Customers/DisableCustomer";
//request type
import getRequest from "../../../util/APIHelperGet";
//base url
import { baseUrl } from "../../../util/APIBaseUrl";

export const fetchDisableCustomerRequest = async (actions) => {
  return await getRequest(`${baseUrl}/${actions.payload.request}`)
    // return await getRequest(
    //     `${baseUrl}/customer/${actions.payload.customerId}/assign`
    // )

    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

function* fetchDisableCustomer(params) {
  try {
    let response = yield call(fetchDisableCustomerRequest, params);

    yield put(DisableCustomerSuccess(response));
  } catch (error) {
    console.error(error);
    yield put(DisableCustomerFailure(error));
  }
}

export default function* rootSaga() {
  yield takeEvery(DISABLE_CUSTOMER, fetchDisableCustomer);
}
