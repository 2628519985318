import { useEffect, useState } from "react";
import TimeoffBarChart from "../../../../Graphs/TimeoffBarChart";
import moment from "moment";

function TimeoffChart(props) {
  const { leaveData, showSpecificBarGraph } = props;

  const [chartData, setChartData] = useState([]);

  useEffect(() => {
    let chartData = [];

    leaveData?.forEach((leave, index) => {
      const monthName = moment(leave?.month).format("MMMM");
      let monthData = { month: monthName, total: leave?.data?.count };
      leave?.data?.dataList?.forEach((leaveDetails, index) => {
        const leaveTypeLowerCase = leaveDetails?.leaveType.toLowerCase();
        monthData[leaveTypeLowerCase] = leaveDetails?.count;
      });
      chartData.push(monthData);
    });

    setChartData(chartData);
  }, [leaveData]);

  return <TimeoffBarChart chartData={chartData} showSpecificBarGraph={showSpecificBarGraph} />;
}

export default TimeoffChart;
