import React from "react";

const CardHoverArrowIcon = () => {
  return (
    <svg width="13" height="14" viewBox="0 0 13 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M7.65958 6.74513L3.82983 2.91538L4.57236 2.17285L9.14464 6.74513L4.57236 11.3174L3.82983 10.5749L7.65958 6.74513Z"
        fill="#131599"
      />
    </svg>
  );
};

export default CardHoverArrowIcon;
