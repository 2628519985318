import React from "react";

const BubbleTipIcon = () => {
  return (
    <svg
      width="29"
      height="25"
      viewBox="0 0 29 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.2497 23.8364C15.4799 25.1698 13.5554 25.1698 12.7856 23.8364L1.03524 3.48428C0.265437 2.15095 1.22769 0.484285 2.76729 0.484285L26.2679 0.484287C27.8075 0.484287 28.7698 2.15095 28 3.48429L16.2497 23.8364Z"
        fill="white"
      />
    </svg>
  );
};

export default BubbleTipIcon;
