//employee constants
import {
    RESER_PAYROLL_PAYMENT_HISTORY,
    PAYROLL_PAYMENT_HISTORY,
    PAYROLL_PAYMENT_HISTORY_SUCCESS,
    PAYROLL_PAYMENT_HISTORY_FAILURE,
} from "../../constants/Payroll/PaymentHistory";

const INIT_STATE = {
    payrollPaymentHistory: {},
    loadingPayrollPaymentsHistory: true,
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case RESER_PAYROLL_PAYMENT_HISTORY:
            return INIT_STATE;
        case PAYROLL_PAYMENT_HISTORY:
            return {
                ...state,
                loadingPayrollPaymentsHistory: true,
            };
        case PAYROLL_PAYMENT_HISTORY_SUCCESS:
            return {
                ...state,
                loadingPayrollPaymentsHistory: false,
                payrollPaymentHistory: action.payload,
            };
        // eslint-disable-next-line no-fallthrough
        case PAYROLL_PAYMENT_HISTORY_FAILURE:
            return {
                ...state,
                loadingPayrollPaymentsHistory: false,
                payrollPaymentHistory: "Error in  getting Club Data",
            };
        default:
            return state;
    }
};
