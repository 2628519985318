import { call, put, takeEvery } from "redux-saga/effects";
import { SigunpSuccess, SigunpFailure } from "../../actions/SignUp/SignUp";
import { SIGNUP } from "../../constants/SignUp/SignUp";
import postRequest from "../../../util/APIHelperPost";
import { baseUrl } from "../../../util/APIBaseUrl";

export const fetchSignupRequest = async (actions) => {
  let body = {
    email: actions?.payload?.userCredential?.email,
    password: actions?.payload?.userCredential?.password,
  };

  body = JSON.stringify(body);

  return await postRequest(`${baseUrl}/${actions.payload.request}`, body, true)
    .then((response) => response)
    .catch((error) => {
      throw error;
    });
};

function* fetchSignup(params) {
  try {
    let response = yield call(fetchSignupRequest, params);
    yield put(SigunpSuccess(response));
  } catch (error) {
    console.error(error);
    yield put(SigunpFailure(error));
  }
}

export default function* rootSaga() {
  yield takeEvery(SIGNUP, fetchSignup);
}
