import {
  RESET_UPLOAD_LOGO,
  UPLOAD_LOGO,
  UPLOAD_LOGO_SUCCESS,
  UPLOAD_LOGO_FAILURE,
} from "../../constants/Logo/uploadLogo";

// for user authentication
export const ResetUploadLogo = (data) => {
  return {
    type: RESET_UPLOAD_LOGO,
    payload: data,
  };
};

export const UploadLogo = (data) => {
  return {
    type: UPLOAD_LOGO,
    payload: data,
  };
};
export const UploadLogoSuccess = (payload) => {
  return {
    type: UPLOAD_LOGO_SUCCESS,
    payload,
  };
};
export const UploadLogoFailure = (payload) => {
  return {
    type: UPLOAD_LOGO_FAILURE,
    payload,
  };
};
