import axios from "axios";
import { baseUrl } from "./APIBaseUrl";

let token = {};
let number = 0;
let refreshTokenResponse;
let refreshTokenFlag = false;

//get token from local storage
export const getIdToken = () => {
  token = {
    accessToken: localStorage.getItem("accessToken"),
    refreshToken: localStorage.getItem("refreshToken"),
  };

  return token;
};

export const makeRequest = async (opts, loaderFlag) => {
  token = await getIdToken();

  const options = {
    ...opts,
    headers: {
      // "Content-Type": opts?.contentType,
    },
  };

  if (token != null) {
    options.headers["Authorization"] = `Bearer ${token.accessToken}`;
  }

  // Return the fetch response or throw the error.
  const continueRequest = async (accessToken) => {
    options.headers["Authorization"] = `Bearer ${accessToken}`;
    // const response = await fetch(options.path, options);
    let response = await axios.post(options.path, options.body, options);
    // let data = await response.json();

    //     return data;
    // }

    return {
      // ok: response.ok,
      status: response.status,
      payload: response.data,
    };
  };

  const resolvePromise = (interval) => {
    return new Promise((resolve) => {
      interval = setInterval(async () => {
        if (!refreshTokenFlag) {
          let accessToken = localStorage.getItem("accessToken");

          if (accessToken) {
            clearInterval(interval);
            let res = await continueRequest(accessToken);

            resolve(res);
          }
        }
      }, 2000);
    });
  };

  number++;

  if (number > 0 && loaderFlag != false) {
    sessionStorage.setItem("loaderFlag", true);
    // document.getElementById("loaderButton").click();
  }

  // eslint-disable-next-line no-return-await

  try {
    let response = await axios.post(options.path, options.body, options);

    if (!response.ok && response.status == 403) {
      throw new Error(response.status);
    }
    number--;

    if (number == 0) {
      sessionStorage.setItem("loaderFlag", false);
      // document.getElementById("hideLoaderButton").click();
    }

    // let data = await response.json();
    return {
      status: response.status,
      payload: response.data,
    };
  } catch (error) {
    if (refreshTokenFlag) {
      let interval;
      let res = await resolvePromise(interval);
      number--;

      if (number == 0) {
        sessionStorage.setItem("loaderFlag", false);
        // document.getElementById("hideLoaderButton").click();
      }

      return res;
    } else {
      if (error.response.status == 403) {
        const requestOptions = {
          method: "POST",
          headers: {
            "Content-Type": "application/json",

            Authorization: `Bearer ${token.accessToken}`,
          },
          body: JSON.stringify({
            refreshToken: `${token.refreshToken}`,
          }),
        };
        refreshTokenFlag = true;
        refreshTokenResponse = await fetch(`${baseUrl}/refresh`, requestOptions);

        number--;

        if (number <= 0) {
          sessionStorage.setItem("loaderFlag", false);
          // document.getElementById("hideLoaderButton").click();
        }

        if (refreshTokenResponse.ok && refreshTokenResponse.status == 201) {
          let data = await refreshTokenResponse.json();
          localStorage.setItem("accessToken", data.accessToken);
          localStorage.setItem("refreshToken", data.refreshToken);
          //continue previous request
          refreshTokenFlag = false;
          let res = await continueRequest(data.accessToken);
          return res;
        } else {
          localStorage.clear();
          window.location.replace("/");
        }
      }
      if (error.response.status == 401) {
        localStorage.clear();
        window.location.replace("/");
      } else {
        number--;

        if (number == 0) {
          sessionStorage.setItem("loaderFlag", false);
          // document.getElementById("hideLoaderButton").click();
        }
        // return error;
        return {
          // ok: response.ok,
          status: error.response.status,
          payload: error.response.data,
        };
      }
    }
  }
};
