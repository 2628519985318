import { call, put, takeEvery } from "redux-saga/effects";
import { startOfMonth, format } from "date-fns";

import {
  AddEmployeeSalarySuccess,
  AddEmployeeSalaryFailure,
} from "../../../actions/Workforce/AddEmployee/AddEmployeeSalary";
import { ADD_EMPLOYEE_SALARY } from "../../../constants/Workforce/AddEmployee/AddEmployeeSalary";
import postRequest from "../../../../util/APIHelperPost";
import { baseUrl } from "../../../../util/APIBaseUrl";

export const fetchAddEmployeeSalaryRequest = async (actions) => {
  const startingDate = actions?.payload?.salaryData?.startingDate;
  const body = JSON.stringify({
    grossSalary: actions.payload.salaryData.grossPay,
    costToCustomer: actions.payload.salaryData.costToCustomer,
    platformFee: actions.payload?.salaryData?.edgeFee,
    // platformFee:actions.payload?.salaryData?.edgefee,
    additionalDeduction: actions.payload?.salaryData?.deduction,
    discounted: actions.payload?.salaryData?.discount,
    percentage: actions.payload?.salaryData?.discountPercentage,
    flatAmount: actions.payload?.salaryData?.flatDiscount,
    startDate: startingDate ? format(startOfMonth(new Date(startingDate)), "yyyy-MM-dd") : "",
    duration: actions.payload?.salaryData?.discountDuration?.key,
  });

  return await postRequest(`${baseUrl}/employee/${actions.payload.employeeId}/salary`, body)
    .then((response) => response)
    .catch((error) => {
      throw error;
    });
};

function* fetchAddEmployeeSalary(params) {
  try {
    const response = yield call(fetchAddEmployeeSalaryRequest, params);
    yield put(AddEmployeeSalarySuccess(response));
  } catch (error) {
    console.error(error);
    yield put(AddEmployeeSalaryFailure(error));
  }
}

export default function* rootSaga() {
  yield takeEvery(ADD_EMPLOYEE_SALARY, fetchAddEmployeeSalary);
}
