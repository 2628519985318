import { Grid, Typography } from "@mui/material";
import React from "react";
import Button from "../Button/Button";
//router
import { useNavigate } from "react-router-dom";

function PageToolBar(props) {
  const { tab1Title, tab2Title, setTab, tab, backgroundColor, width, maxWidth } = props;
  ////////
  const navigate = useNavigate();
  return (
    <Grid
      sx={{
        maxWidth: maxWidth || "1076px",
        width: width || "100%",
        margin: "0px auto",
      }}
    >
      <Grid
        sx={{
          border: "1px solid #D9DAF2",
          borderRadius: "99px",
          //   padding: "4px",
          height: "40px",
          px: "4px",
          marginTop: "8px",
          display: "flex",
          alignItems: "center",
          gap: "12px",
          backgroundColor: backgroundColor ? backgroundColor : null,
          boxShadow: "0px 1px 6px 0px #02006E0D 0px 3px 5px 0px #02006E00",
        }}
      >
        {tab1Title?.title && (
          <Button
            type="button"
            id={props?.tab1Title}
            variant={tab === 1 ? "primary" : "ghost"}
            size="sm"
            style={{ height: "32px" }}
            onClick={() => {
              window.location.hash = tab1Title?.title;
              setTab(1);
              if (tab1Title?.navLink) navigate(tab1Title?.navLink);
            }}
          >
            <Typography
              sx={{
                fontFamily: "Poppins-Regular",
                fontSize: "14px",
                fontWeight: 500,
                lineHeight: "20px",
                px: 0.5,
              }}
            >
              {tab1Title?.title}
            </Typography>
          </Button>
        )}

        <Button
          type="button"
          id={props?.tab2Title}
          variant={tab === 2 ? "primary" : "ghost"}
          size="sm"
          style={{ height: "32px" }}
          onClick={() => {
            window.location.hash = tab2Title?.title;
            setTab(2);
            if (tab2Title?.navLink) navigate(tab2Title?.navLink);
          }}
        >
          <Typography
            sx={{
              fontFamily: "Poppins-Medium",
              fontSize: "14px",
              fontWeight: 500,
              lineHeight: "20px",
              px: 0.5,
            }}
          >
            {tab2Title?.title}
          </Typography>
        </Button>

        {props?.tab3Title && (
          <Button
            type="button"
            variant={tab === 3 ? "primary" : "ghost"}
            size="sm"
            id={props?.tab3Title}
            style={{ height: "32px" }}
            onClick={() => {
              window.location.hash = props?.tab3Title?.title;
              setTab(3);
              if (props?.tab3Title?.navLink) navigate(props?.tab3Title?.navLink);
            }}
          >
            <Typography
              sx={{
                fontFamily: "Poppins-Medium",
                fontSize: "14px",
                fontWeight: 500,
                lineHeight: "20px",
                px: 0.5,
              }}
            >
              {props?.tab3Title?.title}
            </Typography>
          </Button>
        )}

        {/* TODO: Make This component Dynamic */}
        {props?.tab4Title && (
          <Button
            type="button"
            id={props?.tab4Title}
            variant={tab === 5 ? "primary" : "ghost"}
            size="sm"
            style={{ height: "32px" }}
            onClick={() => {
              window.location.hash = props?.tab4Title?.title;
              setTab(5);
              if (props?.tab4Title?.navLink) navigate(props?.tab4Title?.navLink);
            }}
          >
            <Typography
              sx={{
                fontFamily: "Poppins-Medium",
                fontSize: "14px",
                fontWeight: 500,
                lineHeight: "20px",
                px: 0.5,
              }}
            >
              {props?.tab4Title?.title}
            </Typography>
          </Button>
        )}
      </Grid>
    </Grid>
  );
}

export default PageToolBar;
