import {
    EMPLOYEE_PAY_SLIP_DETAILS,
    EMPLOYEE_PAY_SLIP_DETAILS_SUCCESS,
    EMPLOYEE_PAY_SLIP_DETAILS_FAILURE,
  } from "../../constants/EmployeePortal/EmployeePaySlipDetails";
  
  export const EmployeePaySlipDetails = (data) => {
    return {
      type: EMPLOYEE_PAY_SLIP_DETAILS,
      payload: data,
    };
  };
  export const EmployeePaySlipDetailsSuccess = (payload) => {
    return {
      type: EMPLOYEE_PAY_SLIP_DETAILS_SUCCESS,
      payload,
    };
  };
  export const EmployeePaySlipDetailsFailure = (payload) => {
    return {
      type: EMPLOYEE_PAY_SLIP_DETAILS_FAILURE,
      payload,
    };
  };
  