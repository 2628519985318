import React from "react";
import DisplayCompanyInfo from "../../DisplayCompanyInfo";
//redux dispatch
import { useSelector, useDispatch } from "react-redux";
import { getValue } from "components/Screens/CommonUtils";
import { EditUserProfile, ResetEditUserProfile } from "../../../../../store/actions/Settings/EditUserProfile";
import { GetCustomerUserandCompanyProfileData } from "store/actions/Settings/CustomerUser&CompanyProfileData";
import { convertTimezonesToDropdownOptions } from "util/utils";
import { usePermissions } from "../../../../Hook";

const initialGeneralInfo = {
  country: "",
  state: "",
  addressLine1: "",
  code: "",
  city: "",
  department: "",
};

const initailModal = {
  modalName: null,
  edit: false,
};

function PersonalInfoV2(props) {
  const { title } = props;
  const dispatch = useDispatch();

  // custom hook
  const { loggedInUser, loggedInUserType } = usePermissions();

  const countries = React.useMemo(() => {
    return (
      convertTimezonesToDropdownOptions(JSON.parse(sessionStorage?.getItem("dropdownData"))?.payload?.country) || []
    );
  }, []);

  const timezones = React.useMemo(() => {
    return (
      convertTimezonesToDropdownOptions(JSON.parse(sessionStorage.getItem("dropdownData"))?.payload?.timezone) || []
    );
  }, []);

  const edgeDepartments = React.useMemo(() => {
    let dropdownData = JSON.parse(sessionStorage.getItem("dropdownData"));
    return dropdownData?.payload?.edgeDepartments?.map((department) => ({
      value: department?.name,
      label: department?.name,
      departmentId: department?.departmentId,
    }));
  }, []);

  let personalInitialvalues = [
    {
      title: "First Name",
      value: null,
      name: "firstName",
      type: "text",
      isRequired: true,
    },
    {
      title: "Last Name",
      value: null,
      name: "lastName",
      type: "text",
      isRequired: false,
    },
    {
      title: "Email",
      value: null,
      name: "email",
      type: "email",
    },
    {
      title: "Phone Number",
      value: null,
      name: "phoneNumber",
      type: "number",
    },
    {
      title: "Country / Region",
      value: null,
      name: "country",
      dropdownValues: countries,
      type: "select",
    },
    {
      title: "Time Zone",
      value: null,
      dropdownValues: timezones,
      name: "timezone",
      type: "select",
    },
  ];

  if (loggedInUserType === "edge") {
    const departmentField = {
      title: "Department",
      value: null,
      dropdownValues: edgeDepartments,
      name: "department",
      type: "select",
      isRequired: true,
      disabled: !loggedInUser?.permissions?.includes("EdgeAdmin"),
    };
    personalInitialvalues.push(departmentField);
  }
  // component state
  const [personalInfo, setPersonalInfo] = React.useState(initialGeneralInfo);
  const [editAbleModal, setEditAbleModal] = React.useState(initailModal);
  const [displayPersonalInfo, setDisplayPersonalInfo] = React.useState(personalInitialvalues);
  const [openAlert, setOpenAlert] = React.useState(false);
  const [saveInfo, setSaveInfo] = React.useState(false);

  //redux state
  const CompanyProfileData = useSelector(
    (state) => state.CustomerUserandCompanyProfileData.customerUserandCompanyProfileData
  );

  const editPersonalInfo = useSelector((state) => state.EditCompanyInfo.editCompanyInfo);

  //functions

  const handleInputChange = (event, fieldName) => {
    setPersonalInfo({
      ...personalInfo,
      [event.target.name]: event.target.value,
    });
  };

  const hanldeEdit = (editableModal) => {
    setEditAbleModal({
      modalName: editableModal,
      edit: true,
    });
  };

  const handleSave = (modalName, editable) => {
    handleSaveInfo();
  };

  const handleCancel = (modalName, editable) => {
    setEditAbleModal({
      modalName: modalName,
      edit: false,
    });
    setFieldValues();
  };

  const getSelectedDepartmentId = () => {
    const selectedDepartment = edgeDepartments?.find((d) => d?.value === personalInfo?.department);
    return selectedDepartment?.departmentId;
  };

  const checkValidation = () => {
    if (loggedInUserType == "edge") {
      return personalInfo.country != "" && personalInfo.department != "";
    }
    return personalInfo.country != "";
  };

  const handleSaveInfo = (event) => {
    let req = {
      customer: `customerUser/${loggedInUser?.userId}/editprofile`,
      edge: `v2/user/${loggedInUser?.userId}/editprofile`,
    };

    setSaveInfo(true);
    if (checkValidation()) {
      let payload = personalInfo;
      if (personalInfo.department) {
        const selectedDepartmentId = getSelectedDepartmentId();
        payload = { ...personalInfo, departmentId: selectedDepartmentId };
      }

      setOpenAlert(false);
      dispatch(
        EditUserProfile({
          companyProfileData: payload,
          customerId: loggedInUser?.userId,
          request: req[loggedInUserType],
        })
      );
      setEditAbleModal({
        modalName: "Personal Information",
        edit: false,
      });
    } else {
      setOpenAlert(true);
    }
  };

  const setFieldValues = () => {
    const departmentFieldIndex = personalInitialvalues?.findIndex((d) => d?.title === "Department");
    setPersonalInfo((prevInfo) => ({
      ...prevInfo,
      firstName: getValue(CompanyProfileData?.payload?.firstName),
      lastName: getValue(CompanyProfileData?.payload?.lastName),
      email: getValue(CompanyProfileData?.payload?.email),
      phoneNumber: getValue(CompanyProfileData?.payload?.phoneNumber),
      country: getValue(CompanyProfileData?.payload?.country),
      timezone: getValue(CompanyProfileData?.payload?.timezone),
      ...(departmentFieldIndex !== -1 && { department: getValue(CompanyProfileData?.payload?.departmentName) }),
    }));

    personalInitialvalues[0].value = getValue(CompanyProfileData?.payload?.firstName);
    personalInitialvalues[1].value = getValue(CompanyProfileData?.payload?.lastName);
    personalInitialvalues[2].value = getValue(CompanyProfileData?.payload?.email);
    personalInitialvalues[3].value = getValue(CompanyProfileData?.payload?.phoneNumber);
    personalInitialvalues[5].value = getValue(CompanyProfileData?.payload?.timezone);
    personalInitialvalues[4].value = getValue(CompanyProfileData?.payload?.country);

    if (departmentFieldIndex !== -1) {
      personalInitialvalues[departmentFieldIndex].value = getValue(CompanyProfileData?.payload?.departmentName);
    }
    setDisplayPersonalInfo(personalInitialvalues);
  };

  //methods

  React.useEffect(() => {
    let getProfileDataRequest = `customerUser/${loggedInUser?.userId}`;
    if (editPersonalInfo?.ok) {
      setEditAbleModal({
        modalName: null,
        edit: false,
      });
      dispatch(
        GetCustomerUserandCompanyProfileData({
          request: getProfileDataRequest,
        })
      );
      dispatch(ResetEditUserProfile({}));
    }
  }, [editPersonalInfo]);

  React.useEffect(() => {
    if (CompanyProfileData?.ok) {
      setFieldValues();
    }
  }, [CompanyProfileData]);

  return (
    <DisplayCompanyInfo
      data={displayPersonalInfo}
      title={title}
      fieldsData={personalInfo}
      hanldeEdit={hanldeEdit}
      editAbleModal={editAbleModal}
      handleInputChange={handleInputChange}
      handleCancel={handleCancel}
      handleSave={handleSave}
      icon={true}
      bgColor="#FFEFD3"
      iconColor="#F9BD00"
      open={openAlert}
      setOpenAlert={setOpenAlert}
      editCompanyInfo={editPersonalInfo}
      saveInfo={saveInfo}
    />
  );
}

export default PersonalInfoV2;
