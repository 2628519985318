import React from "react";
//material ui
import { Alert, Button, Grid, Paper, Snackbar, Toolbar, Typography, List, ListItem } from "@mui/material";
//widget
import Header from "../../../Header/Header";
//media querry
import FormWidget from "../../../Widgets/FormWidget";
//redux dispatch
import { useSelector, useDispatch } from "react-redux";
//router
import { useNavigate, useLocation } from "react-router-dom";
import { AddUser, ResetAddUser } from "../../../../store/actions/Settings/AddUser";
import { GetUserDetails } from "../../../../store/actions/Settings/GetUserDetails";
import { DropdownData } from "../../../../store/actions/DropdownData";
import { AddPermissions, ResetAddPermissions } from "../../../../store/actions/Permissions/AddPermissions";
import { baseUrl } from "../../../../util/APIBaseUrl";
import { Box } from "@mui/system";
import { getValue } from "../../CommonUtils";
import { useCallback } from "react";
import PageLoader from "../../../common/PageLoader";
import { usePermissions } from "components/Hook";

//constant

const user = {
  userName: "",
  firstName: "",
  lastName: "",
  email: "",
  password: "",
  phoneNumber: "",
  imageBlob: "",
  permissions: {
    key: null,
    value: "",
    valueDescription: null,
    permissionName: null,
  },
  department: {
    key: null,
    value: "",
  },
  country: { key: null, value: "None" },
  loadFromUrl: false,
};

function index(props) {
  const { handleSettingPagechange } = props;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { state } = useLocation();
  const { loggedInUser } = usePermissions();
  // const { state?.paramId } = state;

  //component state
  const [nextButtonClick, setNextButtonClick] = React.useState(false);
  const [userData, setUserData] = React.useState(user);
  const [data, setData] = React.useState([]);
  const [openAlert, setOpenAlert] = React.useState(false);

  const [buttonText, setButtonText] = React.useState("");
  const [heading, setHeading] = React.useState("");
  const [dropdownData_, setDropdownData_] = React.useState({});

  //redux state
  const addPermissions = useSelector((state) => state.AddPermissions.addPermissions);
  const loadingAddPermissions = useSelector((state) => state.AddPermissions.loadingAddPermissions);

  const userAdditionalInfo = useSelector((state) => state.UserAdditionalInfo.userAdditionalInfo);
  const loadingAdditionalInfo = useSelector((state) => state.UserAdditionalInfo.loadingAdditionalInfo);

  const getUserDetails = useSelector((state) => state.GetUserDetails.getUserDetails);
  const loadingGetUserDetails = useSelector((state) => state.GetUserDetails.loadingGetUserDetails);

  const addUser = useSelector((state) => state.AddUser.addUser);
  const loadingAddUser = useSelector((state) => state.AddUser.loadingAddUser);

  //functions
  const postLogo = async (actions) => {
    const response = await fetch(`${baseUrl}/${actions.request}`, {
      method: "POST",
      headers: {},
      body: actions.formData,
    });
    if (!response.ok) {
      return;
    }
    const data = await response.json();
  };

  const handleAlertClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenAlert(false);
  };

  const selectProfilePicture = (imageBlob, form_data) => {
    setUserData({
      ...userData,
      imageBlob: imageBlob,
      formData: form_data,
      loadFromUrl: false,
    });
  };

  const handleUserData = (event, stateName, fieldType) => {
    if (stateName == "permissions") {
    } else if (fieldType == "number") {
      const onlyNums = event.target.value.replace(/[^0-9\+]/g, "");

      setUserData({
        ...userData,
        [event.target.name]: onlyNums,
      });
    } else {
      setUserData({
        ...userData,
        [event.target.name]: event.target.value,
      });
    }
  };

  const callbackFromMultiSelect = (option, key) => {
    userData[key] = option;
    setUserData({
      ...userData,
    });
  };

  const handleCancelButton = (event) => {
    navigate("/main/settings");
  };

  const checkDepartment = () => {
    if (loggedInUserIsAdmin() && !userData?.department?.value) {
      return false;
    }
    return true;
  };

  const handleCreateUser = (event) => {
    dispatch(ResetAddPermissions({}));
    let adduserRequest = "";

    setNextButtonClick(true);
    if (
      userData.email != "" &&
      userData.firstName != "" &&
      userData.lastName != "" &&
      userData.country.key != null &&
      userData?.permissions &&
      Object.keys(userData.permissions).length &&
      checkDepartment()
    ) {
      if (loggedInUser?.type == "customer") {
        if (state?.navigatedFor == "editUser") {
          adduserRequest = `customerUser/${state?.paramId}`;
          dispatch(
            AddUser({
              request: adduserRequest,
              userData: userData,
              customerId: userAdditionalInfo?.customerId,
              method: "PATCH",
            })
          );
        } else if (state?.navigatedFor == "addUser") {
          adduserRequest = `customerUsers/`;
          dispatch(
            AddUser({
              request: adduserRequest,
              userData: userData,
              customerId: userAdditionalInfo?.customerId,
              method: "POST",
            })
          );
        }
      } else if (loggedInUser?.type == "edge") {
        if (state?.navigatedFor == "editUser") {
          adduserRequest = `v2/user/${state?.paramId}`;
          dispatch(
            AddUser({
              request: adduserRequest,
              userData: userData,
              method: "PATCH",
            })
          );
        } else if (state?.navigatedFor == "addUser") {
          adduserRequest = `v2/user`;
          dispatch(
            AddUser({
              request: adduserRequest,
              userData: userData,
              method: "POST",
            })
          );
        }
      }
    } else {
      setOpenAlert(true);
    }
  };

  const getError = (errors) => {
    if (errors != null && errors != undefined) {
      return errors.map((error, index) => {
        return (
          <List
            sx={{
              listStyleType: "disc",
              paddingRight: "0px !important",
              pl: 3,
              "& .MuiListItem-root": {
                paddingRight: "0px",
                paddingLeft: "0px",
                paddingTop: "0px",
                paddingBottom: "0px",
              },
            }}
          >
            <ListItem
              sx={{
                display: "list-item",
              }}
            >
              <Typography variant="h6" color="secondary" fontSize="12px">
                {(error?.msg ? error.msg : error?.description ? error.description : "") +
                  " " +
                  (error?.param ? error?.param : "")}
              </Typography>
            </ListItem>
          </List>
        );
      });
    } else {
      return null;
    }
  };

  const searchPermissionByPriority = () => {
    let foundObject = null;
    const priorityList = ["EdgeAdmin", "EdgeHREmployee", "EdgeEmployee"];

    for (let priority of priorityList) {
      // foundObject = arrayOfObjects.find((obj) => obj.priority === priority);
      if (getUserDetails?.payload?.permissions.includes(priority)) {
        foundObject = dropdownData_?.Permissions?.find((permission) => permission.permissionName === priority);
      }

      if (foundObject) {
        break;
      }
    }
    return foundObject;
  };

  const getSelectedPermission = useCallback(() => {
    const permission = searchPermissionByPriority();

    return {
      key: permission?.key,
      value: permission?.value,
      valueDescription: permission?.valueDescription,
      permissionName: permission?.permissionName,
    };
  }, [getUserDetails, dropdownData_]);

  const loggedInUserIsAdmin = () => {
    return loggedInUser?.permissions?.includes("EdgeAdmin");
  };

  //methods

  React.useEffect(() => {
    let logoRequest = "";
    let permissionRequest = "";

    if (Object.keys(addUser).length && addUser?.ok) {
      if (loggedInUser?.type == "customer") {
        if (state?.navigatedFor == "editUser") {
          logoRequest = `customerUser/${state?.paramId}/logo?id=${getUserDetails?.payload?.pictureVersion}`;
          permissionRequest = `customerUser/${state?.paramId}/addUserPermission`;
        } else if (state?.navigatedFor == "addUser") {
          logoRequest = `customerUser/${addUser?.payload?.userId}/logo`;
          permissionRequest = `customerUser/${addUser?.payload?.userId}/addUserPermission`;
        }

        dispatch(
          AddPermissions({
            request: permissionRequest,
            permissionName: [userData?.permissions?.value],
          })
        );
      } else if (loggedInUser?.type == "edge") {
        if (state?.navigatedFor == "editUser") {
          logoRequest = `user/${state?.paramId}/logo`;
          permissionRequest = `user/${state?.paramId}/addUserPermission`;
        } else if (state?.navigatedFor == "addUser") {
          logoRequest = `user/${addUser?.payload?.userId}/logo`;
          permissionRequest = `user/${addUser?.payload?.userId}/addUserPermission`;
        }

        dispatch(
          AddPermissions({
            request: permissionRequest,
            permissionName: [userData?.permissions?.value],
          })
        );
      }
      if (userData?.formData && !userData?.loadFromUrl) {
        postLogo({
          request: logoRequest,
          formData: userData?.formData,
        });
      }
    } else if (Object.keys(addUser).length && addUser?.ok == false) {
      setOpenAlert(true);
    } else {
      setOpenAlert(false);
    }
  }, [addUser]);

  React.useEffect(() => {
    if (Object.keys(addPermissions).length && addPermissions?.ok) {
      navigate("/main/settings");
      dispatch(ResetAddUser({}));
      dispatch(ResetAddPermissions({}));
    } else if (Object.keys(addPermissions).length && addPermissions?.ok == false) {
      setOpenAlert(true);
    } else {
      setOpenAlert(false);
    }
  }, [addPermissions]);

  React.useEffect(() => {
    let countrySelected = [];
    let departmentSelected = [];
    let logoRequest = "";
    let dropdownData = JSON.parse(sessionStorage.getItem("dropdownData"));

    if (
      state?.navigatedFor != null &&
      state?.navigatedFor != undefined &&
      state?.navigatedFor == "editUser" &&
      getUserDetails?.ok
    ) {
      const permission = getSelectedPermission();

      if (loggedInUser?.type == "customer") {
        logoRequest = `${baseUrl}/customerUser/${state?.paramId}/logo?id=${getUserDetails?.payload?.pictureVersion}`;
      } else if (loggedInUser?.type == "edge") {
        logoRequest = `${baseUrl}/user/${state?.paramId}/logo?id=${getUserDetails?.payload?.pictureVersion}`;
      }

      countrySelected = dropdownData?.payload?.country.filter(
        (country) => country?.countryCode == getUserDetails?.payload?.country
      );

      departmentSelected = dropdownData?.payload?.edgeDepartments.find(
        (department) => department?.departmentId == getUserDetails?.payload?.departmentId
      );

      setUserData({
        ...userData,
        userName: getValue(getUserDetails?.payload?.firstName) + " " + getValue(getUserDetails?.payload?.lastName),
        firstName: getValue(getUserDetails?.payload?.firstName),
        lastName: getValue(getUserDetails?.payload?.lastName),
        email: getValue(getUserDetails?.payload?.email),
        password: "",
        imageBlob: logoRequest,
        loadFromUrl: true,
        permissions: permission,
        phoneNumber: getValue(getUserDetails?.payload?.phoneNumber),
        country: {
          key: countrySelected[0]?.countryCode,
          value: countrySelected[0]?.countryCode,
        },
        department: {
          key: departmentSelected?.departmentId,
          value: departmentSelected?.name,
          valueDescription: departmentSelected?.name,
        },
      });
    }
  }, [getUserDetails, dropdownData_]);

  React.useEffect(() => {
    let addUserFields = [
      {
        name: "firstName",
        label: "FIRST NAME *",
        type: "text",
        maxLength: 30,
        lg: 6,
        required: true,
      },
      {
        name: "lastName",
        label: "LAST NAME *",
        type: "text",
        maxLength: 30,
        lg: 6,
        required: true,
      },
      {
        name: "email",
        // name: "noAutoFill",
        label: "EMAIL ADDRESS *",
        type: "text",
        lg: 6,
        required: true,
      },

      {
        name: "phoneNumber",
        label: "PHONE NUMBER",
        type: "number",
        maxLength: 20,
        lg: 6,
      },
      {
        name: "country",
        label: "COUNTRY *",
        type: "dropdown",
        lg: 6,
        required: true,
        options: dropdownData_.countries,
      },
      {
        name: "department",
        label: "Departments *",
        type: "dropdown",
        lg: 6,
        required: true,
        options: dropdownData_.departments,
        multiple: false,
        valueDescription: true,
        disabled: false,
      },
      {
        name: "permissions",
        label: "PERMISSIONS *",
        type: "dropdown",
        lg: 6,
        required: true,
        options: dropdownData_.Permissions,
        multiple: false,
        valueDescription: true,
      },
    ];

    if (state?.navigatedFor == "editUser") {
      const passwordField = {
        name: "password",
        // name: "noAutoFill",
        label: "Password",
        type: "text",
        lg: 6,
        required: false,
      };
      addUserFields.splice(3, 0, passwordField);
    }
    setData(addUserFields);
  }, [dropdownData_, userData]);

  React.useEffect(() => {
    let userDetailsReuqest = "";

    setNextButtonClick(false);
    setOpenAlert(false);
    dispatch(DropdownData());

    if (
      Object.keys(loggedInUser).length &&
      loggedInUser.userId != undefined &&
      !Object.keys(getUserDetails).length &&
      state?.paramId != undefined &&
      state?.paramId != null
    ) {
      if (loggedInUser?.type == "customer") {
        userDetailsReuqest = `customerUser/${state?.paramId}`;
      } else if (loggedInUser?.type == "edge") {
        userDetailsReuqest = `user/${state?.paramId}`;
      }
      dispatch(
        GetUserDetails({
          request: userDetailsReuqest,
        })
      );
    }

    if (!Object.keys(dropdownData_).length) {
      let countries = [];
      let edgePermissions = [];
      let customerPermissions = [];
      let Permissions = [];
      let edgeDepartments = [];
      let dropdownData = {};
      dropdownData = JSON.parse(sessionStorage.getItem("dropdownData"));

      if (dropdownData != undefined && Object.keys(dropdownData).length && dropdownData.ok) {
        dropdownData?.payload?.country?.map((data, index) => {
          countries.push({
            key: data.countryCode2,
            value: data.countryCode,
          });
        });
        dropdownData?.payload?.edgePermission?.map((permission, index) => {
          edgePermissions.push({
            key: permission.description,
            value: permission.permissionName,
            valueDescription:
              permission.permissionName == "EdgeAdmin"
                ? "Admin"
                : permission.permissionName == "EdgeEmployee"
                ? "Employee"
                : permission.permissionName == "EdgeHREmployee"
                ? "HR Employee"
                : null,
            permissionName: permission.permissionName,
          });
        });

        dropdownData?.payload?.customerPermission?.map((permission, index) => {
          customerPermissions.push({
            key: permission.description,
            value: permission.permissionName,
            valueDescription: permission.permissionName == "CustomerAdmin" ? "Admin" : "Employee",
          });
        });

        dropdownData?.payload?.edgeDepartments?.map((department) => {
          edgeDepartments.push({
            key: department?.departmentId,
            value: department?.name,
            valueDescription: department?.name,
          });
        });

        if (loggedInUser?.type == "customer") {
          Permissions = customerPermissions;
        } else if (loggedInUser?.type == "edge") {
          Permissions = edgePermissions;
        }

        setDropdownData_({
          ...dropdownData_,
          countries: countries,
          Permissions: Permissions,
          departments: edgeDepartments,
        });
      }
    }

    if (Object.keys(loggedInUser).length && loggedInUser.userId != undefined) {
      if (state?.navigatedFor == "editUser") {
        setButtonText("Update");
        setHeading("Edit User's Profile");
      } else if (loggedInUser?.type == "customer") {
        setButtonText("Submit");
        setHeading("Add User");
      } else if (loggedInUser?.type == "edge") {
        setButtonText("Submit");
        setHeading("Add Edge Employee");
      }
    }
  }, []);

  return (
    <>
      {loadingAddPermissions || loadingAddUser || loadingAdditionalInfo || loadingGetUserDetails ? (
        <PageLoader />
      ) : (
        <></>
      )}
      <Grid container direction="column" rowSpacing={3}>
        <Header
          data={{
            heading: heading,
            subHeader: null,
            profileWithIcon: null,
          }}
        />

        <Grid item>
          <Paper sx={{ p: 3 }}>
            {/* <Toolbar /> */}
            <Grid
              item
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
              sx={{
                height: "calc(54vh)",
                overflowY: "auto",
              }}
            >
              <FormWidget
                nextButtonClick={nextButtonClick}
                formData={userData}
                handleDataChange={handleUserData}
                selectProfilePicture={selectProfilePicture}
                callbackFromMultiSelect={callbackFromMultiSelect}
                data={data}
              />
            </Grid>
            <Grid item container direction="row">
              <Grid item lg={2} md={4} sm={4} xs={4}></Grid>
              <Grid item lg={5} md={8} sm={12} xs={12} fullWidth pt={1}>
                <Box backgroundColor="#D5F4F1C2" borderRadius="6px" maxWidth="465px" p={1}>
                  <Typography variant="h5" pt={1} pb={0.5} px={2} color="#5c6b69c2">
                    Permission options and description
                  </Typography>
                  {Object.keys(dropdownData_).length
                    ? dropdownData_?.Permissions.map((permission) => {
                        return (
                          <Box display="flex" justifyContent="space-between" pt={0.75} px={2}>
                            <Typography variant="h6" color="#489188d1" width="30%">
                              {permission.valueDescription}
                            </Typography>
                            <Typography variant="h6" color="#489188d1">
                              {permission.key}
                            </Typography>
                          </Box>
                        );
                      })
                    : "null"}
                </Box>
              </Grid>
            </Grid>

            <Toolbar variant="dense" />
            <Grid item container direction="row" columnSpacing={3} justifyContent="flex-end">
              <Grid item textAlign="center">
                <Snackbar
                  open={openAlert}
                  autoHideDuration={2500}
                  onClose={handleAlertClose}
                  sx={{
                    "&.MuiSnackbar-root": {
                      position: "relative",
                      bottom: 9,
                      left: 0,
                    },
                  }}
                >
                  <Alert onClose={handleAlertClose} severity="error">
                    {addUser?.ok == false
                      ? getError(addUser?.payload?.error)
                      : addPermissions?.ok == false
                      ? getError(addPermissions?.payload?.error)
                      : "REQUIRED FIELD MISSING!"}
                  </Alert>
                </Snackbar>
              </Grid>

              <Grid item>
                <Button
                  fullWidth
                  sx={{
                    width: "110px",
                    borderRadius: 2,
                    p: 1,
                  }}
                  onClick={handleCancelButton}
                >
                  <Typography variant="h6">Cancel</Typography>
                </Button>
              </Grid>

              <Grid item>
                <Button
                  variant="contained"
                  fullWidth
                  disableElevation
                  sx={{
                    width: "170px",
                    borderRadius: 1,
                    color: "white",
                    p: 1,
                  }}
                  onClick={handleCreateUser}
                >
                  <Typography variant="h6" color="white">
                    {buttonText}
                  </Typography>
                </Button>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </>
  );
}

export default index;
