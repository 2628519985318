import React from "react";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

import SignIn from "./SignIn";
import SignInV2 from "./SignInV2";
import ForgotPasswordV2 from "./ForgotPasswordV2";
import AddCustomerInfo from "components/Screens/Login/SignUp/AddCustomerInfoV2";
import PageLoader from "components/common/PageLoader";

import { SignInScreenPermissions } from "components/Screens/Login/SignIn.enum";

function index(props) {
  const dispatch = useDispatch();
  const location = useLocation();

  //component state
  const [screen, setScreen] = React.useState(SignInScreenPermissions.SIGN_IN);
  const [customerOnBoardingPassword, setCustomerOnBoardingPassword] = React.useState("");
  const userAdditionalInfo = useSelector((state) => state.UserAdditionalInfo.userAdditionalInfo);

  const loadingAdditionalInfo = useSelector((state) => state.UserAdditionalInfo.loadingAdditionalInfo);

  //functions
  const changeScreen = (screen) => {
    setScreen(screen);
  };

  return (
    <>
      <Helmet>
        <title>Edge | Sign In</title>
      </Helmet>
      {loadingAdditionalInfo && <PageLoader />}
      {screen == SignInScreenPermissions.SIGN_IN ? (
        window.location.pathname === "/admin" ? (
          <SignIn changeScreen={changeScreen} />
        ) : (
          <SignInV2 changeScreen={changeScreen} setCustomerOnBoardingPassword={setCustomerOnBoardingPassword} />
        )
      ) : // : screen == SignInScreenPermissions.ADD_PAYMENT_METHOD &&
      //   userAdditionalInfo.status === "enabled" ? (
      //   <AddPaymentMethodV2
      //     changeScreen={changeScreen}
      //     navigatedFrom={"signInScreen"}
      //   />
      // )

      screen == SignInScreenPermissions.ADD_CUSTOMER_INFO ? (
        <AddCustomerInfo changeScreen={changeScreen} navigatedFrom={"signInScreen"} />
      ) : // : screen == SignInScreenPermissions.CHANGE_PASSWORD ? (
      //   <ChangePasswordV2 changeScreen={changeScreen}  customerOnBoardingPassword={customerOnBoardingPassword}/>
      // )

      screen == SignInScreenPermissions.FORGOT_PASSWORD ? (
        <ForgotPasswordV2 changeScreen={changeScreen} pathname={location.pathname == "/admin" ? "admin" : "customer"} />
      ) : screen == SignInScreenPermissions.FORGOT_PASSWORD_EMPLOYEE ? (
        <ForgotPasswordV2 changeScreen={changeScreen} pathname={location.pathname == "/admin" ? "admin" : "employee"} />
      ) : null}
    </>
  );
}

export default index;
