import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";

import "./pageLoader.css";

export default function PageLoader() {
  return (
    <Box className="pageLoader">
      <CircularProgress />
    </Box>
  );
}
