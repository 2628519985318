import { useNavigate } from "react-router-dom";

import "./LeaveTypeCard.css";
import { Stack } from "@mui/material";

const LeaveTypeCard = ({ portal, style, leave, icon }) => {
  const navigate = useNavigate();
  // if (!leave) {
  //   return null;
  // }

  const { leaveType, taken, noOfLeaves, pending, scheduled, leaveTypeId, isFinite, countryCode } = leave || {};

  const handleRequestClick = () => {
    navigate("/main/timeoff/request", { state: { selectedLeaveTypeId: leave?.leaveTypeId } });
  };

  return (
    <div className="leavetype-card-container" style={{ ...style }}>
      <div className="leave-count-wrapper">
        <div className="leave-count-request-wrapper">
          <Stack direction="row" spacing="13px" alignItems="center">
            <div className="leave-count">
              <div className="leave-count-flex-row">
                <div className="leave-count-remaining" id="taken-leaves">
                  {taken}
                </div>
                {noOfLeaves > "-1" && isFinite && !(leaveType == "Sick" && countryCode == "Peru") && (
                  <div className="leave-count-total" id="no-of-leaves">
                    /{noOfLeaves}
                  </div>
                )}
              </div>
              <div className="leave-card-type" id="leave-type">
                {leaveType}
              </div>
            </div>

            {leaveType === "Swapped" ? icon : null}
          </Stack>

          {portal !== "admin" && (
            <div
              className="leave-request-btn"
              id={`request-leave-${leaveType}`}
              onClick={() => navigate(`/main/timeoff/request?leaveTypeId=${leaveTypeId}`)}
            >
              Request
            </div>
          )}
        </div>
        {portal !== "admin" && pending + scheduled > 0 && (
          <div className="leave-count-scheduled">+{pending + scheduled} Scheduled/pending</div>
        )}
      </div>
    </div>
  );
};

export default LeaveTypeCard;
