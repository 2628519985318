import { Typography, Dialog, DialogActions, DialogContent, Chip } from "@mui/material";

function ConsentDialog({ open, handleRevokeToken, handleClose }) {
  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <Typography
            sx={{
              fontFamily: "Poppins",
              fontWeight: 600,
              fontSize: "24px",
              textAlign: "center",
            }}
          >
            Are you sure you want to
            <br />
            revoke consent?
          </Typography>
          <Typography
            sx={{
              fontFamily: "Poppins",
              fontWeight: 400,
              fontSize: "16px",
              textAlign: "center",
              mt: 2,
            }}
          >
            You will not be able to view balance on
            <br />
            your Edge platform
          </Typography>
        </DialogContent>
        <DialogActions sx={{ display: "flex", justifyContent: "center", mb: 2 }}>
          <Chip
            label="Cancel"
            sx={{
              fontSize: "14px",
              border: "1px solid #131599",
              color: "#131599",
              fontWeight: 400,
              fontFamily: "Poppins",
              backgroundColor: "white",
              width: "91px",
              height: "37px !important",
            }}
            onClick={handleClose}
          />
          <Chip
            label="Confirm"
            sx={{
              fontSize: "14px",
              fontWeight: 400,
              fontFamily: "Poppins",
              backgroundColor: "#131599",
              color: "white",
              width: "91px",
              height: "37px !important",
              "&:hover": {
                backgroundColor: "#02006e",
              },
            }}
            onClick={handleRevokeToken}
          />
        </DialogActions>
      </Dialog>
    </>
  );
}

export default ConsentDialog;
