import React from "react";

const LockIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M7 10V7C7 4.23858 9.23858 2 12 2C14.7614 2 17 4.23858 17 7V10H18C19.0684 10 20 10.7764 20 11.8333V20.1667C20 21.2236 19.0684 22 18 22H6C4.93165 22 4 21.2236 4 20.1667V11.8333C4 10.7764 4.93165 10 6 10H7ZM9 10H15V7C15 5.34315 13.6569 4 12 4C10.3431 4 9 5.34315 9 7V10ZM6 12V20H18V12H6ZM12 17C11.4477 17 11 16.5523 11 16C11 15.4477 11.4477 15 12 15C12.5523 15 13 15.4477 13 16C13 16.5523 12.5523 17 12 17Z"
        fill="#00C0AF"
      />
    </svg>
  );
};

export default LockIcon;
