import React from "react";

const FailureRetryIcon = () => {
  return (
    <svg
      width="17"
      height="16"
      viewBox="0 0 17 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M5.44281 12.6667L6.9714 14.1953L6.0286 15.1381L2.89052 12L6.0286 8.86194L6.9714 9.80475L5.44281 11.3333H11.1667C12.2712 11.3333 13.1667 10.4379 13.1667 9.33334V7.33334H14.5V9.33334C14.5 11.1743 13.0076 12.6667 11.1667 12.6667H5.44281ZM11.5579 3.33401L10.0286 1.80475L10.9714 0.861938L14.1095 4.00001L10.9714 7.13808L10.0286 6.19527L11.5565 4.66734H5.83333C4.72876 4.66734 3.83333 5.56277 3.83333 6.66734V8.66734H2.5V6.66734C2.5 4.82639 3.99238 3.33401 5.83333 3.33401H11.5579Z"
        fill="#BA1A1A"
      />
    </svg>
  );
};

export default FailureRetryIcon;
