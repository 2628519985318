import { call, put, takeEvery } from "redux-saga/effects";
import {
    loggedInUserInfoSuccess,
    loggedInUserInfoFailure,
} from "../../actions/LoggedInUserInfo";
import { LOGGED_IN_USER_Info } from "../../constants/LoggedInUserInfo";
import postRequest from "../../../util/APIHelperPost";
import { baseUrl } from "../../../util/APIBaseUrl";

export const fetchLoggedInInfoRequest = async (actions) => {
    let body = JSON.stringify({
        email: actions.payload.userCredential.email,
        password: actions.payload.userCredential.password,
    });
    return await postRequest(`${baseUrl}/auth/`, body)
        .then((response) => response)
        .catch((error) => {
            throw error;
        });
};

function* fetchLoggedInInfo(params) {
    try {
        let response = yield call(fetchLoggedInInfoRequest, params);
        yield put(loggedInUserInfoSuccess(response));
    } catch (error) {
        console.error(error);
        yield put(loggedInUserInfoFailure(error));
    }
}

export default function* rootSaga() {
    yield takeEvery(LOGGED_IN_USER_Info, fetchLoggedInInfo);
}
// username: "karn.yong@mecallapi.com",
// password: "mecallapi",

// dev-r-zwu02g.us.auth0.com
// UijIxNAY0HioVrV5knvcprnF8IQloxrh
// naWhURivSEaIzpHPhGR4O3qlnEdsHUCWLwpB4xqHpXl3U6HmeUDJf0LuwDAtb7TW
