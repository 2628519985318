import React from "react";

import { convertDate } from "components/Screens/CommonUtils";

function LeavesValidityDates(props) {
  const { startDate, endDate } = props;
  return startDate && endDate ? (
    <div className="timeoff-valid">
      {" "}
      Valid from{" "}
      <span className="timeoff-valid-date" data-testid="validity-date-id">
        {" "}
        {convertDate(startDate)}{" "}
      </span>{" "}
      till{" "}
      <span className="timeoff-valid-date" data-testid="validity-end-date-id">
        {" "}
        {convertDate(endDate)}{" "}
      </span>
      &nbsp;
    </div>
  ) : null;
}

export default LeavesValidityDates;
