//employee constants
import { RESER_GIVE_RAISE, GIVE_RAISE, GIVE_RAISE_SUCCESS, GIVE_RAISE_FAILURE } from "../../constants/Raises/GiveRaise";
import jwt from "jwt-decode"; // import dependency

const INIT_STATE = {
  giveRaiseInfo: {},
  loadingGiveRaiseInfo: false,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case RESER_GIVE_RAISE:
      return INIT_STATE;

    case GIVE_RAISE:
      return {
        ...state,
        loadingGiveRaiseInfo: true,
      };
    case GIVE_RAISE_SUCCESS:
      return {
        ...state,
        loadingGiveRaiseInfo: false,
        giveRaiseInfo: action.payload,
      };
    // eslint-disable-next-line no-fallthrough
    case GIVE_RAISE_FAILURE:
      return {
        ...state,
        loadingGiveRaiseInfo: false,
        giveRaiseInfo: "Error in  getting Club Data",
      };

    default:
      return state;
  }
};
