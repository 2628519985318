import { call, put, takeEvery } from "redux-saga/effects";
//actions
import {
  PayrollPaymentHistorySuccess,
  PayrollPaymentHistoryFailure,
} from "../../actions/Payroll/PaymentHistory";
//constant
import { PAYROLL_PAYMENT_HISTORY } from "../../constants/Payroll/PaymentHistory";
//request type
import getRequest from "../../../util/APIHelperGet";
import { baseUrl } from "../../../util/APIBaseUrl";

export const fetchPayrollPaymentHistoryRequest = async (actions) => {
  // eslint-disable-next-line no-return-await

  return await getRequest(
    `${baseUrl}/customer/${actions.payload.customerId}/payments`
  )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

function* fetchPayrollPaymentHistory(params) {
  try {
    let response = yield call(fetchPayrollPaymentHistoryRequest, params);
    yield put(PayrollPaymentHistorySuccess(response));
  } catch (error) {
    console.error(error);
    yield put(PayrollPaymentHistoryFailure(error));
  }
}

export default function* rootSaga() {
  yield takeEvery(PAYROLL_PAYMENT_HISTORY, fetchPayrollPaymentHistory);
}
