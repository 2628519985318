import { call, put, takeEvery } from "redux-saga/effects";
import {
  AddEmployeeExperienceSuccess,
  AddEmployeeExperienceFailure,
} from "../../../actions/Workforce/AddEmployee/AddEmployeeExperience";
import { ADD_EMPLOYEE_EXPERIENCE } from "../../../constants/Workforce/AddEmployee/AddEmployeeExperience";
import postRequest from "../../../../util/APIHelperPost";
import { baseUrl } from "../../../../util/APIBaseUrl";

const getMonthandyear = (month, year) => {
  return new Date(year).setFullYear(
    new Date(year).getFullYear(),
    new Date(month).getMonth()
  );
};

export const fetchAddEmployeeExperienceRequest = async (actions) => {
  let body = JSON.stringify({
    companyName: actions?.payload?.experienceData?.company,
    country: actions?.payload?.experienceData?.countryLocation?.value,
    city: actions?.payload?.experienceData?.cityLocation,
    startDate: getMonthandyear(
      actions?.payload?.experienceData?.monthStartDate,
      actions?.payload?.experienceData?.yearStartDate
    ),
    endDate: getMonthandyear(
      actions?.payload?.experienceData?.monthEndDate,
      actions?.payload?.experienceData?.yearEndDate
    ),
    jobTitle: actions?.payload?.experienceData?.jobTitle,
    roleDescription: actions?.payload?.experienceData?.experienceDescription,
  });

  return await postRequest(
    `${baseUrl}/employee/${actions.payload.employeeId}/experience`,
    body
  )
    .then((response) => response)
    .catch((error) => {
      throw error;
    });
};

function* fetchAddEmployeeExperience(params) {
  try {
    let response = yield call(fetchAddEmployeeExperienceRequest, params);
    yield put(AddEmployeeExperienceSuccess(response));
  } catch (error) {
    console.error(error);
    yield put(AddEmployeeExperienceFailure(error));
  }
}

export default function* rootSaga() {
  yield takeEvery(ADD_EMPLOYEE_EXPERIENCE, fetchAddEmployeeExperience);
}
