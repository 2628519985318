import {
  RESET_EDIT_USER_PROFILE,
  EDIT_USER_PROFILE,
  EDIT_USER_PROFILE_SUCCESS,
  EDIT_USER_PROFILE_FAILURE,
} from "../../constants/Settings/EditUserProfile";

// for add employee in admin workforce table

export const ResetEditUserProfile = (data) => {
  return {
    type: RESET_EDIT_USER_PROFILE,
    payload: data,
  };
};

export const EditUserProfile = (data) => {
  return {
    type: EDIT_USER_PROFILE,
    payload: data,
  };
};
export const EditUserProfileSuccess = (payload) => {
  return {
    type: EDIT_USER_PROFILE_SUCCESS,
    payload,
  };
};
export const EditUserProfileFailure = (payload) => {
  return {
    type: EDIT_USER_PROFILE_FAILURE,
    payload,
  };
};
