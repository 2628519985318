import { Button, Grid, Paper, Typography } from "@mui/material";

import WorkOutlineOutlinedIcon from "@mui/icons-material/WorkOutlineOutlined";
import PeopleAltOutlinedIcon from "@mui/icons-material/PeopleAltOutlined";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import ManageAccountsOutlinedIcon from '@mui/icons-material/ManageAccountsOutlined';
import { BsCalendar4 } from "react-icons/bs";
import { VscHistory } from "react-icons/vsc";
import { AiOutlineDollarCircle } from "react-icons/ai";
import { RiProfileLine } from "react-icons/ri";
import { RiMenu2Fill } from "react-icons/ri";

function PageHeader(props) {
  const { pageHeadings, selectPageHeading, selectedOption } = props;

  const handleSelectOption = (opt) => {
    window.location.hash = opt;
    selectPageHeading(opt);
  };

  return (
    <Grid item container direction="row">
      <Grid item xs={12}>
        <Paper>
          <Grid item container direction="row" columnSpacing={10} sx={{ p: 1.75, pl: 0 }}>
            {pageHeadings && pageHeadings.length > 0
              ? pageHeadings.map((heading, index) => {
                return (
                  <Grid item ml={2} key={heading}>
                    {selectedOption == heading && (
                      <Button
                        key={heading}
                        variant="contained"
                        disableElevation
                        sx={{
                          height: "45px",
                          color: "white",
                          backgroundColor: "#3D43BB",
                          "&:hover": {
                            backgroundColor: "#dbefed",
                          },
                        }}
                        startIcon={
                          heading == "Profile" ? (
                            <PersonOutlineOutlinedIcon />
                          ) : heading == "Manage Users" ? (
                            <PeopleAltOutlinedIcon />
                          ) : heading == "Payroll" ? (
                            <BsCalendar4 />
                          ) : heading == "Payroll History" ? (
                            <VscHistory />
                          ) : heading == "Payment History" ? (
                            <VscHistory />
                          ) : heading == "Salary Details" ? (
                            <AiOutlineDollarCircle />
                          ) : heading == "Overview" ? (
                            <RiProfileLine />
                          ) : heading == "Users" ? (
                            <ManageAccountsOutlinedIcon />
                          ) : heading == "Notes" ? (
                            <RiMenu2Fill />
                          ) : (
                            <WorkOutlineOutlinedIcon />
                          )
                        }
                        onClick={() => {
                          handleSelectOption(heading);
                        }}
                      >
                        <Typography fontFamily="Poppins-Regular !important" fontSize="13px" color="#fffff">
                          {heading}
                        </Typography>
                      </Button>
                    )}
                    {selectedOption != heading && (
                      <Button
                        variant="contained"
                        disableElevation
                        color="secondary"
                        sx={{
                          height: "45px",
                          backgroundColor: "#fff",
                          color: "#080D46",
                          "&:hover": {
                            backgroundColor: "#dbefed",
                          },
                        }}
                        startIcon={
                          heading == "Profile" ? (
                            <PersonOutlineOutlinedIcon />
                          ) : heading == "Manage Users" ? (
                            <PeopleAltOutlinedIcon />
                          ) : heading == "Payroll" ? (
                            <BsCalendar4 />
                          ) : heading == "Payroll History" ? (
                            <VscHistory />
                          ) : heading == "Payment History" ? (
                            <VscHistory />
                          ) : heading == "Salary Details" ? (
                            <AiOutlineDollarCircle />
                          ) : heading == "Overview" ? (
                            <RiProfileLine />
                          ) : heading == "Users" ? (
                            <ManageAccountsOutlinedIcon />
                          ) : heading == "Notes" ? (
                            <RiMenu2Fill />
                          ) : (
                            <WorkOutlineOutlinedIcon />
                          )
                        }
                        onClick={() => {
                          handleSelectOption(heading);
                        }}
                      >
                        <Typography
                          fontFamily="Poppins-Medium !important"
                          fontSize="13px"
                          // color="secondary"
                          sx={{ color: "#3D43BB" }}
                        >
                          {heading}
                        </Typography>
                      </Button>
                    )}
                  </Grid>
                );
              })
              : null}
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  );
}

export default PageHeader;
