import React from "react";

function PeruFlag({ width, height }) {
  return (
    <svg
      width={width || "24"}
      height={height || "24"}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_40_1546)">
        <path
          d="M11.9998 24C13.48 24 14.8906 23.7192 16.1998 23.2295V0.770397C14.8906 0.280795 13.48 0 11.9998 0C10.5196 0 9.10901 0.280795 7.7998 0.770397V23.2289C9.10901 23.7192 10.5196 24 11.9998 24Z"
          fill="url(#paint0_linear_40_1546)"
        />
        <path
          d="M0 11.9998C0 6.85233 3.2472 2.47474 7.79999 0.770142V23.2293C3.2472 21.5247 0 17.1472 0 11.9998ZM24 11.9998C24 6.85233 20.7527 2.47474 16.2 0.770142V23.2287C20.7527 21.5247 24 17.1472 24 11.9998Z"
          fill="url(#paint1_linear_40_1546)"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_40_1546"
          x1="21.7018"
          y1="5.65678"
          x2="-7.10898"
          y2="24.4943"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#DFE9F2" />
          <stop offset="0.998" stopColor="#9EA7B0" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_40_1546"
          x1="25.4478"
          y1="7.34433"
          x2="-6.38399"
          y2="18.364"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.008" stopColor="#F44F5A" />
          <stop offset="0.447" stopColor="#EE3D4A" />
          <stop offset="1" stopColor="#E52030" />
        </linearGradient>
        <clipPath id="clip0_40_1546">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default PeruFlag;
