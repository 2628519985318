import {
  RESET_GET_EMPLOYEE_SALARIES,
  GET_EMPLOYEE_SALARIES,
  GET_EMPLOYEE_SALARIES_SUCCESS,
  GET_EMPLOYEE_SALARIES_FAILURE,
} from "../../constants/EmployeeSalaries";

// for user authentication
export const ResetGetEmployeeSalaries = (data) => {
  return {
    type: RESET_GET_EMPLOYEE_SALARIES,
    payload: data,
  };
};

export const GetEmployeeSalaries = (data) => {
  return {
    type: GET_EMPLOYEE_SALARIES,
    payload: data,
  };
};
export const GetEmployeeSalariesSuccess = (payload) => {
  return {
    type: GET_EMPLOYEE_SALARIES_SUCCESS,
    payload,
  };
};
export const GetEmployeeSalariesFailure = (payload) => {
  return {
    type: GET_EMPLOYEE_SALARIES_FAILURE,
    payload,
  };
};
