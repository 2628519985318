//employee constants
import {
    GET_NOTICE_BOARD,
    GET_NOTICE_BOARD_SUCCESS,
    GET_NOTICE_BOARD_FAILURE,
} from "../../constants/DashBoard/GetNoticeBoard";

const INIT_STATE = {
    noticeBoard: {},
    loadingGetNoticeBoard: false,
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_NOTICE_BOARD:
            return {
                ...state,
                loadingGetNoticeBoard: true,
            };
        case GET_NOTICE_BOARD_SUCCESS:
            return {
                ...state,
                loadingGetNoticeBoard: false,
                noticeBoard: action.payload,
            };
        // eslint-disable-next-line no-fallthrough
        case GET_NOTICE_BOARD_FAILURE:
            return {
                ...state,
                loadingGetNoticeBoard: false,
                noticeBoard: "Error in  getting Club Data",
            };
        default:
            return state;
    }
};
