import React, { useState } from "react";
import { Box, Button, ButtonBase, Stack, Typography } from "@mui/material";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import { useSelector } from "react-redux";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";

function ConflictsInRecords() {
  const [viewDetails, setViewDetials] = useState(false);

  const documents = useSelector((state) => state.WorkforceDocs.documents);
  return (
    <Box
      sx={{
        width: "100%",
        borderRadius: "5px",
        // height: viewDetails ? '450px' : '220px',
        backgroundColor: "white",
        // p: 3,
      }}
    >
      <Stack direction="column" spacing="5px">
        <Stack direction="row" spacing="10px" alignItems="center">
          <Typography
            variant="h4"
            sx={{
              // textAlign: 'center',
              fontFamily: "Poppins-Medium",
              fontWeight: 400,
              p: 3,
              lineHeight: "28px",
              fontSize: "20px !important",
            }}
          >
            {documents?.ok
              ? "Payroll has been updated Successfully !"
              : !documents?.payload?.data?.length
              ? documents?.payload?.error?.[0]?.description || ""
              : `There were conflicts for ${documents?.payload?.data?.length} records`}
          </Typography>

          {documents?.payload?.data?.length && (
            <Typography variant="h3" sx={{ textAlign: "center", px: 3 }}>
              <Button
                endIcon={
                  viewDetails ? (
                    <FaChevronUp style={{ fontSize: "16px" }} />
                  ) : (
                    <FaChevronDown style={{ fontSize: "16px" }} />
                  )
                }
                variant="contained"
                sx={{ color: "white" }}
                onClick={() => setViewDetials(!viewDetails)}
              >
                View details
              </Button>
            </Typography>
          )}
        </Stack>
        {viewDetails && documents?.payload?.data?.length ? (
          <Box
            sx={{
              background: "white",
              // minHeight: '200px',
              textAlign: "start",
              // pt: 3,
              // maxHeight: '230px',
              overflowY: "scroll",
              pb: 2,
            }}
          >
            <ul>
              {documents?.payload?.data?.map((conflict, index) => (
                <li key={index}>
                  <Typography
                    // variant="h1"
                    sx={{
                      textAlign: "start",
                      fontFamily: "Poppins-Regular",
                      fontWeight: 400,
                      p: 1,
                      lineHeight: "28px",
                      fontSize: "20px !important",
                    }}
                  >
                    {/* You can add any additional styling or formatting here */}
                    {conflict}
                  </Typography>
                </li>
              ))}
            </ul>
          </Box>
        ) : null}
      </Stack>
    </Box>
  );
}

export default ConflictsInRecords;
