import React from "react";
//material ui
import {
  Button,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  Link,
  TextField,
  Typography,
  Alert,
  List,
  ListItem,
} from "@mui/material";
import { Box } from "@mui/system";
//icons
import { Visibility, VisibilityOff } from "@mui/icons-material";

//enum
import { SignInScreenPermissions } from "../SignIn.enum";
//redux dispatch
import { useSelector, useDispatch } from "react-redux";
import { AuthUser, ResetAuthUser } from "../../../../store/actions/AuthUser";
//router
import { useNavigate, useLocation } from "react-router-dom";
import { useForm } from "react-hook-form";

//file
import GoogleSignup from "../SignUp/GoogleSignup";
import { UserAdditionalInfo } from "../../../../store/actions/UserAdditionalInfo";
import { ResetGoogleSigunp } from "../../../../store/actions/Google/Google_signup";
import { GetPlatformConfig } from "../../../../store/actions/PlatformConfig";
import { checkUserAdditionalInfo } from "../../CommonUtils";
import PageLoader from "../../../common/PageLoader";
import { baseUrl } from "../../../../util/APIBaseUrl";

//constants
const userCredentials = {
  email: "",
  password: "",
  loginPortal: null,
};

function SignIn(props) {
  //props
  const { changeScreen } = props;
  const { handleSubmit } = useForm();

  const dispatch = useDispatch();
  let navigateTo = useNavigate();
  const location = useLocation();

  //component state
  const [showPassword, setShowPassword] = React.useState(false);
  const [openAlert, setOpenAlert] = React.useState(false);
  const [userCredential, setUserCredential] = React.useState(userCredentials);
  const [loginButtonClicked, setLoginButtonClicked] = React.useState(false);

  // redux state
  const authUserData = useSelector((state) => state.AuthUser.authUserData);
  const loadingAuthUserData = useSelector((state) => state.AuthUser.loadingAuthUserData);

  const platformConfig = useSelector((state) => state.PlatformConfig.platformConfig);

  const loadingplatformConfig = useSelector((state) => state.PlatformConfig.loadingplatformConfig);

  const userAdditionalInfo = useSelector((state) => state.UserAdditionalInfo.userAdditionalInfo);
  const loadingAdditionalInfo = useSelector((state) => state.UserAdditionalInfo.loadingAdditionalInfo);

  const googleSignupInfo = useSelector((state) => state.GoogleSignup.googleSignupInfo);
  const loadingGoogleSignupInfo = useSelector((state) => state.GoogleSignup.loadingGoogleSignupInfo);

  // functions
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);

  const handleInputChange = (event) => {
    const value = event.target.value;
    setUserCredential({
      ...userCredential,
      [event.target.name]: value,
    });
  };

  const getError = (errors) => {
    if (errors != null && errors != undefined) {
      return errors.map((error, index) => {
        return (
          <List
            sx={{
              listStyleType: "disc",
              paddingRight: "0px !important",
              pl: 3,
              "& .MuiListItem-root": {
                paddingRight: "0px",
                paddingLeft: "0px",
                paddingTop: "0px",
                paddingBottom: "0px",
              },

              "&.MuiList-root": {
                paddingTop: "0px",
                paddingBottom: "0px",
              },
            }}
          >
            <ListItem
              sx={{
                display: "list-item",
              }}
            >
              {(error?.msg ? error.msg : error?.description ? error.description : "Undefined Error") +
                " " +
                (error?.param ? error?.param : " ")}
            </ListItem>
          </List>
        );
      });
    } else {
      return null;
    }
  };

  const checkError = (field, view) => {
    if (loginButtonClicked) {
      if (view != "textField") {
        if (userCredential[field]?.key != null) {
          return false;
        } else return true;
      } else {
        if (userCredential[field] != "") {
          return false;
        } else return true;
      }
    }
  };

  // maps browerser route on to api call: Defaults to Customer  type login
  const routesMap = {
    "/admin": "admin",
    "/employee": "employee",
  };

  const loginHandler = (event) => {
    setLoginButtonClicked(true);
    dispatch(ResetGoogleSigunp({}));
    dispatch(ResetAuthUser({}));
    if (userCredential.email != "" && userCredential.password != "") {
      setOpenAlert(false);
      const request = `${baseUrl}/auth`;

      dispatch(
        AuthUser({
          userCredential,
          request,
          loginPortal: routesMap[location.pathname] ? routesMap[location.pathname] : "customer",
        })
      );
    } else {
      setOpenAlert(true);
    }
  };

  //methods

  React.useEffect(() => {
    let loggedInUser = JSON.parse(localStorage.getItem("loggedInUser"));
    let accesstoken = localStorage.getItem("accessToken");
    let request = "";
    if (accesstoken && loggedInUser) {
      if (loggedInUser?.type == "customer") {
        request = `customer/${loggedInUser.customerId}/info`;
      } else if (loggedInUser?.type == "edge") {
        request = `user/${loggedInUser.userId}/summary`;
      } else if (loggedInUser?.type == "employee") {
        request = `employee/${loggedInUser.userId}`;
      }
      dispatch(UserAdditionalInfo(request));
    } else {
      // if (location.pathname == "/admin") {
      //   navigateTo("/admin");
      // } else if (location.pathname == "/employee") {
      //   navigateTo("/employee");
      // }
      // else {
      //   navigateTo("/");
      // }
    }

    if (Object.keys(authUserData).length > 0 && authUserData.ok != true) {
      setOpenAlert(true);
    }
  }, [authUserData]);

  React.useEffect(() => {
    let loggedInUser = JSON.parse(localStorage.getItem("loggedInUser"));
    let accesstoken = localStorage.getItem("accessToken");

    if (
      userAdditionalInfo != null &&
      Object.keys(userAdditionalInfo).length &&
      accesstoken != null &&
      accesstoken != "undefined" &&
      loggedInUser != null &&
      loggedInUser != "undefined"
    ) {
      if (loggedInUser?.type == "customer") {
     if (!loggedInUser?.bankAttached && userAdditionalInfo.status === "enabled") {
          navigateTo("/addPaymentMethod");
          // changeScreen(SignInScreenPermissions.ADD_PAYMENT_METHOD);
        } else {
          navigateTo("/main/dashboard");
        }
      } else if (loggedInUser?.type == "edge") {
          navigateTo("/main/dashboard");
      }
    } else {
      // if (location.pathname == "/admin") {
      //   navigateTo("/admin");
      // }
      // else if (location.pathname == "/") {
      //   navigateTo("/");
      // } else {
      //   navigateTo("/");
      // }
    }
    if (userAdditionalInfo != null && Object.keys(userAdditionalInfo).length > 0 && userAdditionalInfo.ok != true) {
      setOpenAlert(true);
    }
  }, [userAdditionalInfo]);

  React.useEffect(() => {
    dispatch(GetPlatformConfig({}));
  }, []);

  return (
    <>
      {loadingAuthUserData == true ||
      loadingAdditionalInfo == true ||
      loadingplatformConfig == true ||
      loadingGoogleSignupInfo == true ? (
        <PageLoader />
      ) : (
        <></>
      )}
      <Grid
        container
        direction="row"
        // justifyContent="space-evenly"
        justifyContent="center"
        alignItems="center"
        sx={{ backgroundColor: "#DBEFED", overflowY: "scroll" }}
      >
        <Grid
          item
          xl={5}
          lg={5}
          md={5}
          sm={12}
          xs={12}
          display="flex"
          flexDirection="row"
          justifyContent="center"
          alignItems="center"
        >
          <form onSubmit={handleSubmit(loginHandler)}>
            <Grid item container direction="column" rowSpacing={2} sx={{ maxWidth: "330px" }}>
              <Grid item>
                <Typography
                  sx={{
                    fontFamily: "Poppins-Bold !important",
                    color: "#010533",
                    fontSize: "35px",
                    lineHeight: "50px",
                    marginTop: "20px",
                    "@media screen and (min-width: 912px)": {
                      marginTop: "0px",
                    },
                  }}
                >
                  Admin Portal !
                </Typography>
              </Grid>

              {platformConfig?.payload?.ADMIN_BASIC_AUTH == "1" && (
                <>
                  <Grid item>
                    <FormControl variant="outlined" fullWidth error>
                      <label>
                        <Typography
                          fontWeight={500}
                          // color={"#000000"}
                          sx={{
                            fontFamily: "Poppins-Medium !important",
                            color: checkError("email", "textField") ? "#F64A14" : "000000",
                            fontSize: "13px",
                            lineHeight: "8px",
                            paddingTop: 1,
                          }}
                        >
                          Email
                        </Typography>
                      </label>
                      <TextField
                        sx={{
                          "& .MuiInputBase-root": {
                            backgroundColor: "white",
                            borderRadius: "6px",
                            fontFamily: "Poppins-Medium !important",
                          },
                          "& .MuiInputBase-input": {
                            paddingTop: 1,
                            paddingLeft: 2,
                            paddingBottom: 1,

                            fontSize: "13px",
                          },
                        }}
                        id="userName"
                        // variant={
                        //     checkError("email", "textField")
                        //         ? "outlined"
                        //         : "standard"
                        // }
                        error={checkError("email", "textField")}
                        placeholder="ABC@Hotmail.com"
                        size="small"
                        name="email"
                        fullWidth={true}
                        onChange={handleInputChange}
                        margin="dense"
                        InputProps={{
                          disableUnderline: true,
                          classes: {
                            notchedOutline: {
                              borderWidth: "1px",
                              borderColor: "yellow !important",
                            },
                          },
                        }}
                      />
                    </FormControl>
                  </Grid>

                  <Grid item>
                    <FormControl variant="outlined" fullWidth>
                      <label>
                        <Typography
                          fontWeight={500}
                          color={"#000000"}
                          sx={{
                            fontFamily: "Poppins-Medium !important",
                            color: checkError("password", "textField") ? "#F64A14" : "000000",
                            fontSize: "13px",
                            lineHeight: "8px",
                          }}
                        >
                          Password
                        </Typography>
                      </label>
                      <TextField
                        sx={{
                          "& .MuiInputBase-root": {
                            backgroundColor: "white",
                            borderRadius: "6px",
                            fontFamily: "Poppins-Medium !important",
                          },
                          "& .MuiInputBase-input": {
                            paddingTop: 1,
                            paddingLeft: 2,
                            paddingBottom: 1,
                            fontSize: "13px",
                          },
                        }}
                        id="password"
                        placeholder="Enter Password"
                        size="small"
                        name="password"
                        type={showPassword ? "text" : "password"}
                        fullWidth={true}
                        onChange={handleInputChange}
                        // variant={
                        //     checkError("password", "textField")
                        //         ? "outlined"
                        //         : "standard"
                        // }
                        error={checkError("password", "textField")}
                        margin="dense"
                        InputProps={{
                          disableUnderline: true,

                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowPassword}
                                onMouseDown={handleMouseDownPassword}
                              >
                                {showPassword ? <Visibility /> : <VisibilityOff />}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />

                      <Link
                        sx={{
                          paddingTop: "5px",
                          color: "#000000",
                          fontSize: "12px",
                          fontWeight: "500",
                          fontFamily: "Poppins-Medium !important",
                          cursor: "pointer",
                          width: 130,
                        }}
                      >
                        <Typography
                          fontWeight={500}
                          color={"#000000"}
                          onClick={() => {
                            changeScreen(SignInScreenPermissions.FORGOT_PASSWORD);
                          }}
                          sx={{
                            fontFamily: "Poppins-Medium !important",

                            fontSize: "13px",
                            lineHeight: "8px",
                          }}
                        >
                          Forgot Password?
                        </Typography>
                      </Link>
                    </FormControl>
                  </Grid>
                </>
              )}

              <Grid item>
                {authUserData?.ok == false || googleSignupInfo?.ok == false ? (
                  <Alert
                    open={openAlert}
                    autoHideDuration={3500}
                    variant="filled"
                    icon={false}
                    sx={{
                      backgroundColor: "#F64A14",
                      padding: "initial",
                      px: "5px",
                      fontSize: "12px",
                      textAlign: "center",

                      borderRadius: "5px !important",
                      color: "#ffffff !important",
                      "&.MuiPaper-root": {
                        color: "#ffffff !important",
                      },
                    }}
                  >
                    <Typography
                      sx={{
                        fontFamily: "Poppins-Regular !important",
                        fontWeight: "400",
                        fontSize: "13px",
                      }}
                    >
                      {authUserData?.ok == false
                        ? getError(authUserData?.payload?.error)
                        : googleSignupInfo?.ok == false
                        ? getError(googleSignupInfo?.payload?.error)
                        : "Enter all information before proceeding"}
                    </Typography>
                  </Alert>
                ) : null}
              </Grid>

              {platformConfig?.payload?.ADMIN_BASIC_AUTH == "1" && (
                <Grid item>
                  <Button
                    type="submit"
                    variant="contained"
                    fullWidth={true}
                    sx={{
                      backgroundColor: "#080D46",
                      height: "50px",
                      fontFamily: "Poppins-Regular !important",
                      borderRadius: "6px",
                      fontSize: "16px",
                      color: "white",
                    }}
                  >
                    Lets Go!
                  </Button>
                </Grid>
              )}
              {platformConfig?.ok && (
                <>
                  {platformConfig?.payload?.ADMIN_BASIC_AUTH == "1" && (
                    <Grid item sx={{ mt: 5 }}>
                      <Typography
                        sx={{
                          fontFamily: "Poppins-Bold !important",
                          color: "080D46",
                          fontSize: "18px",
                          fontWeight: "800",
                          lineHeght: "2px",
                          marginBottom: "0",
                        }}
                      >
                        Or Sign In with,
                      </Typography>
                    </Grid>
                  )}
                  <Grid item sx={{ paddingTop: "8px !important" }}>
                    <GoogleSignup changeScreen={changeScreen} />
                  </Grid>
                </>
              )}

              <Grid item textAlign="center"></Grid>
              <Grid item textAlign="center">
                <Typography variant="h3Lite" fontSize="9px">
                  {process.env.REACT_APP_NAME}
                  {" " + process.env.REACT_APP_VERSION}
                </Typography>
              </Grid>
            </Grid>
          </form>
        </Grid>

        <Grid item xl={7} lg={7} md={7} sm={12} xs={12} alignItems={"flex-end"} textAlign="end">
          {/* <Box
                    sx={{
                        background:
                            "radial-gradient(circle, rgba(245,245,245,1) 12%, rgba(204,240,240,1) 59%)",
                        height: "70vh",
                        width: "75vh",
                        borderRadius: "50%",
                        textAlign: "center",
                        boxShadow: "rgba(0, 0, 0, 0.04) 0px 3px 4px;",
                    }}
                > */}
          <Box
            component="img"
            // mt={-10}
            sx={{
              height: "auto",
              maxWidth: "100%",
              objectFit: "contain",
              // override on screens greater than 912px
              "@media screen and (min-width: 912px)": {
                objectFit: "cover",
                height: "100vh",
              },
            }}
            alt="The house from the offer."
            src="assets/fonts/Images/Group 48.png"
          />
          {/* </Box> */}
        </Grid>
      </Grid>
    </>
  );
}

export default SignIn;
