import {
  GET_NOTICE_BOARD,
  GET_NOTICE_BOARD_SUCCESS,
  GET_NOTICE_BOARD_FAILURE,
} from "../../constants/DashBoard/GetNoticeBoard";

// for user authentication
export const GetNoticeBoard = (data) => {
  return {
    type: GET_NOTICE_BOARD,
    payload: data,
  };
};
export const GetNoticeBoardSuccess = (payload) => {
  return {
    type: GET_NOTICE_BOARD_SUCCESS,
    payload,
  };
};
export const GetNoticeBoardFailure = (payload) => {
  return {
    type: GET_NOTICE_BOARD_FAILURE,
    payload,
  };
};
