import { call, put, takeEvery } from "redux-saga/effects";
// actions
import { educationInfoSuccess, educationInfoFailure } from "../../../actions/Workforce/EmployeeDetils/EducationInfo";
// constant
import { EDUCATION_INFO } from "../../../constants/Workforce/EmployeeDetils/EducationInfo";
// request type

import getRequest from "../../../../util/APIHelperGet";
import { baseUrl } from "../../../../util/APIBaseUrl";

export const fetchEducationInfoRequest = async (actions) => {
  return await getRequest(`${baseUrl}/${actions.payload.request}`)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

function* fetchEducationInfo(params) {
  try {
    const response = yield call(fetchEducationInfoRequest, params);
    yield put(educationInfoSuccess(response));
  } catch (error) {
    console.error(error);
    yield put(educationInfoFailure(error));
  }
}

export default function* rootSaga() {
  yield takeEvery(EDUCATION_INFO, fetchEducationInfo);
}