import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { convertDate } from "components/Screens/CommonUtils";
import EmployeeAvatar from "components/Screens/Timeoff/TimeoffAdmin/EmployeeTimeOffDetails/EmployeeAvatar";

import AvatarImage from "assets/images/fallback-image.svg";

import { baseUrl } from "util/APIBaseUrl";

import "./LeaveItem.css";

const LeaveItem = ({ emp: { holidayName, leaveType, employeeName, dateFrom, dateTo, employeeId, pictureVersion } }) => {
  const navigate = useNavigate();
  const [empImageSrc, setEmpImageSrc] = useState(AvatarImage);

  useEffect(() => {
    if (employeeId && pictureVersion) {
      const empImageUrl = `${baseUrl}/employee/${employeeId}/logo?id=${pictureVersion}&variant=thumbnail`;
      setEmpImageSrc(empImageUrl);
    } else {
      setEmpImageSrc(AvatarImage);
    }
  }, [employeeId, pictureVersion]);

  return (
    <div
      className="flex list-container items-center cursor-pointer"
      onClick={() => navigate(`/main/details/${employeeId}#Time-off`)}
    >
      <div className="list-leaves-icon">
        <div className="employee-avatar">
          <EmployeeAvatar img={empImageSrc} size={{ width: "57px", height: "57px", borderRadius: "50%" }} />
          <span className={`status-dot calendar-dot-indicator-${leaveType === "holiday" ? "green" : "orange"}`}></span>
        </div>
      </div>
      <div>
        <div className="heading-sm" id="emp-name">
          {employeeName}
        </div>
        <div style={{ fontFamily: "Poppins-Regular" }} className="label-sm neutral-30" id="date-range-out-emp">
          {convertDate(dateFrom)} - {convertDate(dateTo)}
        </div>
        <div style={{ fontFamily: "Poppins-Regular" }} className="holiday-label label-sm neutral-20" id="leave-type">
          {leaveType === "holiday" ? holidayName : leaveType}
        </div>
      </div>
    </div>
  );
};

export default LeaveItem;
