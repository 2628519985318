//employee constants
import { ADD_CUSTOMER_DATA } from "../../../constants/Customers/AddCustomer/AddCustomerData";

const INIT_STATE = {
  addCustomerData: {},
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case ADD_CUSTOMER_DATA:
      return {
        ...state,
        addCustomerData: action.payload,
      };

    default:
      return state;
  }
};
