import React from "react";
//material ui
// import {
//     Typography,
//     Grid,
//     Button,
//     FormControl,
//     TextField,
//     Autocomplete,
//     Paper,
// } from "@mui/material";
import {
  Alert,
  Button,
  Grid,
  Paper,
  Snackbar,
  Toolbar,
  Typography,
} from "@mui/material";
//media querry
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
//widgets
//Icons
import SaveRoundedIcon from "@mui/icons-material/SaveRounded";
import FormWidget from "../../../Widgets/FormWidget";
//redux dispatch
import { useSelector, useDispatch } from "react-redux";
import {
  EditCompanyProfileInfo,
  ResetEditCompanyProfile,
} from "../../../../store/actions/Settings/EditCompanyInfo";
//router
import { useNavigate } from "react-router-dom";
import { settingPages } from "../SettingPages.enumn";
import { GetCustomerUserandCompanyProfileData } from "../../../../store/actions/Settings/CustomerUser&CompanyProfileData";
import { baseUrl } from "../../../../util/APIBaseUrl";
import { checkUserAdditionalInfo } from "../../CommonUtils";

//constants
const profileData = {
  companyName: "",
  email: "",
  industry: "",
  contactPerson: "",
  contactNumber: "",
  country: { key: null, value: "None" },
  companyAddress: "",
  state: "",
  city: "",
  code: "",
  imageBlob: "",
  loadFromUrl: false,
};

function EditCompanyProfile(props) {
  const { handleEditChange } = props;
  const theme = useTheme();
  const dispatch = useDispatch();
  const matches = useMediaQuery(theme.breakpoints.down("lg"));
  const navigate = useNavigate();

  //redux state
  const userAdditionalInfo = useSelector(
    (state) => state.UserAdditionalInfo.userAdditionalInfo
  );

  //component state
  const [companyProfileData, setCompanyProfileData] =
    React.useState(profileData);
  const [openAlert, setOpenAlert] = React.useState(false);
  const [saveSettingsButtonClick, setSaveSettingsButtonClick] =
    React.useState(false);
  const [dropdownData_, setDropdownData_] = React.useState({});

  const customerUserandCompanyProfileData = useSelector(
    (state) =>
      state.CustomerUserandCompanyProfileData.customerUserandCompanyProfileData
  );

  const editCompanyInfo = useSelector(
    (state) => state.EditCompanyInfo.editCompanyInfo
  );

  //functions
  const postLogo = async (actions) => {
    const response = await fetch(`${baseUrl}/${actions.request}`, {
      method: "POST",
      headers: {},
      body: actions.formData,
    });
    if (!response.ok) {
      return;
    }
    // window.location.reload();
    const data = await response.json();
  };

  const getValue = (value) => {
    if (value != undefined && value != null && value != "") {
      return value;
    } else {
      return "";
    }
  };

  const handleAlertClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenAlert(false);
  };

  const handleCompanyProfileData = (event, fieldName, fieldType) => {
    if (fieldType == "number") {
      const onlyNums = event.target.value.replace(/[^0-9\+]/g, "");

      setCompanyProfileData({
        ...companyProfileData,
        [event.target.name]: onlyNums,
      });
    } else {
      setCompanyProfileData({
        ...companyProfileData,
        [event.target.name]: event.target.value,
      });
    }
  };

  const callbackFromMultiSelect = (option, key) => {
    companyProfileData[key] = option;
    setCompanyProfileData({
      ...companyProfileData,
    });
  };

  const selectProfilePicture = (imageBlob, formData) => {
    setCompanyProfileData({
      ...companyProfileData,
      imageBlob: imageBlob,
      loadFromUrl: false,
      formData: formData,
    });
  };

  const handleSaveSetting = (event) => {
    let loggedInUser = {};
    let logoRequest = "";
    loggedInUser = JSON.parse(localStorage.getItem("loggedInUser"));
    if (loggedInUser?.type == "customer") {
      logoRequest = `customer/${loggedInUser?.customerId}/logo`;
    }

    if (
      companyProfileData.companyName != "" &&
      companyProfileData.email &&
      companyProfileData.companyAddress &&
      companyProfileData.country.key != null
    ) {
      if (companyProfileData?.formData) {
        postLogo({
          request: logoRequest,
          formData: companyProfileData?.formData,
        });
      }

      dispatch(
        EditCompanyProfileInfo({
          companyProfileData: companyProfileData,
          customerId: loggedInUser?.userId,
        })
      );
    } else {
      setSaveSettingsButtonClick(true);
      setOpenAlert(true);
    }
  };

  const getError = (errors) => {
    if (errors != null && errors != undefined) {
      return errors.map((error, index) => {
        return (
          <List
            sx={{
              listStyleType: "disc",
              paddingRight: "0px !important",
              pl: 3,
              "& .MuiListItem-root": {
                paddingRight: "0px",
                paddingLeft: "0px",
                paddingTop: "0px",
                paddingBottom: "0px",
              },
            }}
          >
            <ListItem
              sx={{
                display: "list-item",
              }}
            >
              <Typography variant="h6" color="secondary" fontSize="12px">
                {(error?.msg
                  ? error.msg
                  : error?.description
                  ? error.description
                  : "") +
                  " " +
                  (error?.param ? error?.param : "")}
              </Typography>
            </ListItem>
          </List>
        );
      });
    } else {
      return null;
    }
  };

  //methods
  React.useEffect(() => {}, [companyProfileData]);

  React.useEffect(() => {
    let countryOption = [];
    let dropdownData = {};
    let companyProfileUrl = "";
    let loggedInUser = {};

    dropdownData = JSON.parse(sessionStorage.getItem("dropdownData"));
    loggedInUser = JSON.parse(localStorage.getItem("loggedInUser"));

    if (
      Object.keys(loggedInUser).length &&
      checkUserAdditionalInfo(userAdditionalInfo)
    ) {
      if (loggedInUser?.type == "customer") {
        companyProfileUrl = `${baseUrl}/customer/${userAdditionalInfo?.customerId}/logo?id=${customerUserandCompanyProfileData?.payload?.pictureVersion}`;
      } else if (loggedInUser?.type == "edge") {
        companyProfileUrl = `${baseUrl}/edge/logo}`;
      }
    }

    if (
      dropdownData != undefined &&
      dropdownData != null &&
      Object.keys(dropdownData).length &&
      dropdownData?.ok
    ) {
      countryOption = dropdownData?.payload?.country.filter(
        (user) =>
          user?.countryCode ==
          customerUserandCompanyProfileData?.payload?.customer?.country
      );
    }
    setCompanyProfileData({
      ...companyProfileData,
      companyName: getValue(
        customerUserandCompanyProfileData?.payload?.customer?.companyName
      ),
      email: getValue(
        customerUserandCompanyProfileData?.payload?.customer?.contactEmail
      ),
      companyAddress: getValue(
        customerUserandCompanyProfileData?.payload?.customer?.addressLine1
      ),
      industry: getValue(
        customerUserandCompanyProfileData?.payload?.customer?.industry
      ),
      contactPerson: getValue(
        customerUserandCompanyProfileData?.payload?.customer?.contactPerson
      ),
      contactNumber: getValue(
        customerUserandCompanyProfileData?.payload?.customer?.contactNumber
      ),
      country: {
        key: countryOption[0]?.countryCode2,
        value: countryOption[0]?.countryCode,
      },
      state: getValue(
        customerUserandCompanyProfileData?.payload?.customer?.state
      ),
      city: getValue(
        customerUserandCompanyProfileData?.payload?.customer?.city
      ),
      code: getValue(customerUserandCompanyProfileData?.payload?.customer?.zip),
      imageBlob: companyProfileUrl,
      loadFromUrl: true,
    });
  }, [customerUserandCompanyProfileData]);

  React.useEffect(() => {
    let loggedInUser = {};
    loggedInUser = JSON.parse(localStorage.getItem("loggedInUser"));

    let getProfileDataRequest = `customerUser/${loggedInUser?.userId}`;
    if (editCompanyInfo?.ok) {
      handleEditChange(settingPages.COMPANY_PROFILE);
      dispatch(
        GetCustomerUserandCompanyProfileData({
          request: getProfileDataRequest,
        })
      );
      dispatch(ResetEditCompanyProfile({}));
    }
  }, [editCompanyInfo]);

  React.useEffect(() => {
    let countries = [];
    let dropdownData = {};
    dropdownData = JSON.parse(sessionStorage.getItem("dropdownData"));

    if (
      dropdownData != undefined &&
      Object.keys(dropdownData).length &&
      dropdownData.ok
    ) {
      dropdownData?.payload?.country?.map((data, index) => {
        countries.push({
          key: data.countryCode2,
          value: data.countryCode,
        });
      });

      setDropdownData_({
        ...dropdownData_,
        countries: countries,
      });
    }
  }, []);

  return (
    <>
      <Paper sx={{ p: 3 }}>
        <Grid item container direction="row" xs={12}>
          <FormWidget
            nextButtonClick={saveSettingsButtonClick}
            formData={companyProfileData}
            handleDataChange={handleCompanyProfileData}
            selectProfilePicture={selectProfilePicture}
            callbackFromMultiSelect={callbackFromMultiSelect}
            data={[
              {
                name: "companyName",
                label: "COMPANY NAME *",
                type: "text",
                maxLength: 100,
                lg: 4,
                required: true,
              },
              {
                name: "email",
                label: "EMAIL ADDRESS *",
                type: "text",
                lg: 4,
                required: true,
              },
              {
                name: "industry",
                label: "INDUSTRY",
                type: "text",
                lg: 4,
              },
              {
                name: "contactPerson",
                label: "CONTACT PERSON",
                type: "text",
                maxLength: 70,
                lg: 4,
              },
              {
                name: "contactNumber",
                label: "CONTACT NUMBER",
                type: "number",
                maxLength: 20,
                lg: 4,
              },
              {
                name: "country",
                label: "COUNTRY *",
                type: "dropdown",
                lg: 4,
                required: true,
                options: dropdownData_.countries,
              },
              {
                name: "companyAddress",
                label: "COMPANY ADDRESS *",
                type: "text",
                maxLength: 100,
                lg: 12,
                required: true,
              },

              {
                name: "city",
                label: "CITY",
                type: "text",
                lg: 4,
              },
              {
                name: "state",
                label: "STATE",
                type: "text",
                lg: 4,
              },
              {
                name: "code",
                label: "ZIP",
                type: "text",
                lg: 4,
              },
            ]}
          />

          <Toolbar variant="dense" />
          <Grid
            item
            container
            direction="row"
            columnSpacing={3}
            justifyContent="flex-end"
          >
            <Grid item textAlign="center" sx={{ willChange: "transform" }}>
              <Snackbar
                open={openAlert}
                autoHideDuration={2500}
                onClose={handleAlertClose}
                sx={{
                  "&.MuiSnackbar-root": {
                    position: "relative",
                    bottom: 9,
                    left: 0,
                  },
                }}
              >
                <Alert onClose={handleAlertClose} severity="error">
                  {editCompanyInfo?.ok == false
                    ? getError(editCompanyInfo?.payload?.error)
                    : "REQUIRED FIELD MISSING!"}
                </Alert>
              </Snackbar>
            </Grid>
            <Grid item>
              <Button
                fullWidth
                sx={{
                  width: "100px",
                  borderRadius: "5px",
                }}
                onClick={() => handleEditChange(settingPages.COMPANY_PROFILE)}
              >
                <Typography variant="h6" color="secondary">
                  Cancel
                </Typography>
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                disableElevation
                startIcon={<SaveRoundedIcon />}
                fullWidth
                sx={{
                  width: "170px",
                  borderRadius: "5px",
                  color: "white",
                }}
                onClick={handleSaveSetting}
              >
                <Typography variant="h6" color="white">
                  SAVE SETTINGS
                </Typography>
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </>
  );
}

export default EditCompanyProfile;
