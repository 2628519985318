//employee constants
import {
  RESET_REMOVE_USER_PERMISSIONS,
  REMOVE_USER_PERMISSIONS,
  REMOVE_USER_PERMISSIONS_SUCCESS,
  REMOVE_USER_PERMISSIONS_FAILURE,
} from "../../constants/Settings/RemoveUserPermissions";

const INIT_STATE = {
  removeUserPermissions: {},
  loadingRemoveUserPermissions: true,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case RESET_REMOVE_USER_PERMISSIONS:
      return INIT_STATE;

    case REMOVE_USER_PERMISSIONS:
      return {
        ...state,
        loadingRemoveUserPermissions: true,
      };
    case REMOVE_USER_PERMISSIONS_SUCCESS:
      return {
        ...state,
        loadingRemoveUserPermissions: false,
        removeUserPermissions: action.payload,
      };
    // eslint-disable-next-line no-fallthrough
    case REMOVE_USER_PERMISSIONS_FAILURE:
      return {
        ...state,
        loadingRemoveUserPermissions: false,
        removeUserPermissions: "Error in  getting Club Data",
      };
    default:
      return state;
  }
};
