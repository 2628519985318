import React, { useState } from "react";

import EmergencyOutlinedIcon from "@mui/icons-material/EmergencyOutlined";
import { ModeEditOutlined } from "@mui/icons-material";

import Button from "components/common/Button";
import CustomModal from "components/common/Modal";

import NoEmergencyContact from "./NoEmergencyContact";
import EditEmergencyContact from "./EditEmergencyContact";

function EmergencyContact({ data, onUpdate, countryStates }) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const fullAddress = [data?.addressLine1, data?.zip, data?.city, data?.state].filter(Boolean).join(", ");

  const handleEdit = () => {
    setIsModalOpen(true);
  };

  const handleAdd = () => {
    setIsModalOpen(true);
  };

  const handleSave = (updatedData) => {
    onUpdate(updatedData);
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const isEmptyData = !data || Object.keys(data).length === 0;

  return (
    <div className="contact-container">
      <div className="flex justify-between items-center mb-125">
        <h4 className="heading-h4-semibold m-0 flex items-center">
          <EmergencyOutlinedIcon className="contact-icon" /> Emergency Contact
        </h4>
        {!isEmptyData && (
          <Button variant="outlined" size="small" label="Edit" startIcon={<ModeEditOutlined />} onClick={handleEdit} />
        )}
      </div>
      {isEmptyData ? (
        <NoEmergencyContact onAdd={handleAdd} />
      ) : (
        <div className="flex flex-col gap-1">
          <div className="flex justify-between items-center">
            <h5 className="m-0 heading-h5-regular">Name</h5>
            <p className="contact-value para-body-m-semibold">{data.name || "-"}</p>
          </div>
          <div className="flex justify-between items-center">
            <h5 className="m-0 heading-h5-regular">Relationship</h5>
            <p className="contact-value para-body-m-semibold">{data.relationship || "-"}</p>
          </div>
          <div className="flex justify-between items-center">
            <h5 className="m-0 heading-h5-regular">Phone number</h5>
            <p className="contact-value para-body-m-semibold">{data.phoneNumber || "-"}</p>
          </div>
          <div className="flex justify-between items-center">
            <h5 className="m-0 heading-h5-regular">Email</h5>
            <p className="contact-value para-body-m-semibold">{data.email || "-"}</p>
          </div>
          <div className="flex justify-between items-center">
            <h5 className="m-0 heading-h5-regular align-self-start">Address</h5>
            <p className="contact-value para-body-m-semibold admin-contact-address">{fullAddress || "-"}</p>
          </div>
        </div>
      )}
      <CustomModal
        open={isModalOpen}
        title="Emergency Contact"
        sx={{ maxWidth: "652px !important", width: "652px !important", padding: "24px !important" }}
      >
        <EditEmergencyContact
          onSave={handleSave}
          onCancel={handleCancel}
          initialData={data}
          countryStates={countryStates}
        />
      </CustomModal>
    </div>
  );
}

export default EmergencyContact;
