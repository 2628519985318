import {
  AUTH_USER,
  AUTH_USER_SUCCESS,
  AUTH_USER_FAILURE,
  RESET_AUTH_USER,
} from "../constants/AuthUser";

// for user authentication
export const ResetAuthUser = (data) => {
  return {
    type: RESET_AUTH_USER,
    payload: data,
  };
};

export const AuthUser = (data) => {
  return {
    type: AUTH_USER,
    payload: data,
  };
};
export const AuthUserSuccess = (payload) => {
  return {
    type: AUTH_USER_SUCCESS,
    payload,
  };
};
export const AuthUserFailure = (payload) => {
  return {
    type: AUTH_USER_FAILURE,
    payload,
  };
};
