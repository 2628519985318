import {
  GET_EMPLOYEE_Details,
  GET_EMPLOYEE_Details_SUCCESS,
  GET_EMPLOYEE_Details_FAILURE,
  RESET_GET_EMPLOYEE_Details,
} from "../constants/GetEmployeeDetails";

// for employee information

export const ResetGetEmployeeDetails = (data) => {
  return {
    type: RESET_GET_EMPLOYEE_Details,
    payload: {},
  };
};

export const GetEmployeeDetails = (data) => {
  return {
    type: GET_EMPLOYEE_Details,
    payload: data,
  };
};
export const GetEmployeeDetailsSuccess = (payload) => {
  return {
    type: GET_EMPLOYEE_Details_SUCCESS,
    payload,
  };
};
export const GetEmployeeDetailsFailure = (payload) => {
  return {
    type: GET_EMPLOYEE_Details_FAILURE,
    payload,
  };
};
