//employee constants
import {
  RESER_EXPERIENCE_INFO,
  EXPERIENCE_INFO,
  EXPERIENCE_INFO_SUCCESS,
  EXPERIENCE_INFO_FAILURE,
} from "../../../constants/Workforce/EmployeeDetils/ExperienceInfo";

const INIT_STATE = {
  experienceInfo: {},
  loadingExperienceInfo: false,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case RESER_EXPERIENCE_INFO:
      return INIT_STATE;

    case EXPERIENCE_INFO:
      return {
        ...state,
        loadingExperienceInfo: true,
      };

    case EXPERIENCE_INFO_SUCCESS:
      return {
        ...state,
        loadingExperienceInfo: false,
        experienceInfo: action.payload,
      };

    // eslint-disable-next-line no-fallthrough
    case EXPERIENCE_INFO_FAILURE:
      return {
        ...state,
        loadingExperienceInfo: false,
        experienceInfo: "Error in  getting Club Data",
      };
    default:
      return state;
  }
};
