import { call, put, takeEvery } from "redux-saga/effects";
//actions
import { CustomerInfoSuccess, CustomerInfoFailure } from "../../actions/CustomerInfo";
//constant
import { CUSTOMER_INFO } from "../../constants/CustomerInfo";
//request type
import getRequest from "../../../util/APIHelperGet";
import { baseUrl } from "../../../util/APIBaseUrl";

export const fetchGetUsersRequest = async (actions) => {
  // eslint-disable-next-line no-return-await
  // return await getRequest(
  //     `${baseUrl}/${actions.payload.request}?limit=${actions.payload.rowsPerPage}`
  // )
  return await getRequest(
    `${baseUrl}/${actions.payload.request}?` +
      new URLSearchParams({
        limit: actions.payload.rowsPerPage,
        page: actions.payload.page,
        orderBy: JSON.stringify({
          field: actions.payload?.sortBy?.field,
          direction: actions.payload?.sortBy?.direction,
        }),
        filter: JSON.stringify({
          // email: actions.payload?.dist?.email,
          companyName: actions.payload?.dist?.customers,
          status: actions.payload?.dist?.status,
          paymentMethodType: actions.payload?.dist?.paymentMethodType,
        }),
      })
  )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

function* fetchGetUsers(params) {
  try {
    let response = yield call(fetchGetUsersRequest, params);
    yield put(CustomerInfoSuccess(response));
  } catch (error) {
    console.error(error);
    yield put(CustomerInfoFailure(error));
  }
}

export default function* rootSaga() {
  yield takeEvery(CUSTOMER_INFO, fetchGetUsers);
}
