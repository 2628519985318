//employee constants
import {
  RESER_ADD_CUSTOMER,
  ADD_CUSTOMER,
  ADD_CUSTOMER_SUCCESS,
  ADD_CUSTOMER_FAILURE,
  ADD_GOOGLE_CUSTOMER,
  ADD_GOOGLE_CUSTOMER_SUCCESS,
  ADD_GOOGLE_CUSTOMER_FAILURE,
} from "../../../constants/Customers/AddCustomer/AddCustomer";
import jwt from "jwt-decode"; // import dependency

const INIT_STATE = {
  addCustomerInfo: {},
  loadingAddCustomerInfo: false,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case RESER_ADD_CUSTOMER:
      return INIT_STATE;

    case ADD_CUSTOMER:
      return {
        ...state,
        loadingAddCustomerInfo: true,
      };
    case ADD_CUSTOMER_SUCCESS:
      return {
        ...state,
        loadingAddCustomerInfo: false,
        addCustomerInfo: action.payload,
      };
    // eslint-disable-next-line no-fallthrough
    case ADD_CUSTOMER_FAILURE:
      return {
        ...state,
        loadingAddCustomerInfo: false,
        addCustomerInfo: "Error in  getting Club Data",
      };
    // setup company on google signup
    case ADD_GOOGLE_CUSTOMER:
      return {
        ...state,
        loadingAddCustomerInfo: true,
      };
    case ADD_GOOGLE_CUSTOMER_SUCCESS:
      if (action.payload.ok) {
        const user = jwt(action.payload?.payload?.accessToken); // decode your token here

        localStorage.setItem("accessToken", action.payload?.payload?.accessToken);
        localStorage.setItem("loggedInUser", JSON.stringify(user));
        localStorage.setItem("refreshToken", action.payload?.payload?.refreshToken);
        return {
          ...state,
          loadingAddCustomerInfo: false,
          addCustomerInfo: action.payload,
        };
      } else {
        return {
          ...state,
          loadingAddCustomerInfo: false,
          addCustomerInfo: action.payload,
        };
      }
    case ADD_GOOGLE_CUSTOMER_FAILURE:
      localStorage.removeItem("accessToken");
      return {
        ...state,
        loadingAddCustomerInfo: false,
        addCustomerInfo: "Error in  getting Customer Data",
      };
    default:
      return state;
  }
};
