import { useState } from "react";

import MedicalDocsModal from "components/Screens/Timeoff/ManageTimeoff/MedicalDocsModal";

import EmployeeAvatar from "components/Screens/Timeoff/TimeoffAdmin/EmployeeTimeOffDetails/EmployeeAvatar";
import AvatarIcon from "components/Icons/AvatarIcon";
import MedicalDocsIcon from "assets/images/medical-docs.svg";

import "./comment.css";
import NoCommentView from "../../PendingLeaves/PendingLeavesCard/NoCommentView";

function Comment({
  empImageSrc,
  customerImageSrc,
  pending,
  description,
  employeeName,
  requestId,
  reason = "",
  leaveType,
  documentsAttached,
  setReason = () => {},
  approvedReason,
  rejectedReason,
  loggedInUserType,
  actionByName,
  dateFrom,
  dateTo,
  noOfLeaves,
}) {
  const [medicalDocsModal, setMedicalDocsModal] = useState({
    show: false,
    id: null,
  });

  const handleCommentChange = (event) => {
    setReason(event.target.value);
  };

  return (
    <div className="space-y-1">
      <MedicalDocsModal
        open={medicalDocsModal.show}
        title="Medical Documents"
        leaveRequestId={requestId}
        closeModal={() =>
          setMedicalDocsModal({
            show: false,
            id: null,
          })
        }
        {...{ leaveType, dateFrom, dateTo, noOfLeaves }}
      />

      <div className="px-1 flex space-x-1">
        <EmployeeAvatar
          img={empImageSrc}
          size={{
            width: "44px",
            height: "44px",
            borderRadius: "50%",
          }}
        />
        {description ? (
          <div className="bg-white comment-container w-full">
            <p className="body-md break-word" id="comment-leave">
              {description}
            </p>
            <p className="label-sm neutral-20 comment-author" id="emp-name">
              {employeeName}
            </p>
          </div>
        ) : (
          <NoCommentView />
        )}

        {leaveType === "Sick" && documentsAttached && (
          <div
            className="flex medical-docs-btn cursor-pointer"
            style={{ marginRight: "0" }}
            onClick={() =>
              setMedicalDocsModal({
                show: true,
                id: requestId,
              })
            }
          >
            <img src={MedicalDocsIcon} alt="medical docs" />
            <div className="flex flex-col" style={{ alignSelf: "center", marginLeft: "0.5rem" }}>
              <div className="pop-regular title-md medical-docs-btn-label">Medical Docs</div>
              <div className="label-sm pop-regular neutral-60" id="medical-docs-btn">
                View
              </div>
            </div>
          </div>
        )}
      </div>

      {approvedReason || rejectedReason || (pending && loggedInUserType === "customer") ? (
        <div className="px-1 flex space-x-1">
          <EmployeeAvatar
            img={customerImageSrc}
            size={{
              width: "44px",
              height: "44px",
              borderRadius: "50%",
            }}
          />
          <div className="comment-container w-full">
            {pending ? (
              <textarea
                className="body-md custom-textarea w-full"
                style={{ fontFamily: "Poppins-Regular" }}
                placeholder="Add a comment (Optional)"
                value={reason}
                onChange={handleCommentChange}
              />
            ) : approvedReason || rejectedReason ? (
              <>
                <p className="body-md break-word" id="approved-reason">
                  {approvedReason || rejectedReason}
                </p>
                <p className="label-sm neutral-20 comment-author" id="action-taken-by">
                  {actionByName}
                </p>
              </>
            ) : (
              <></>
            )}
          </div>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
}

export default Comment;
