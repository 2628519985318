// SnackbarActions.js
import { RESET_SHOW_SNACKBAR, SHOW_SNACKBAR } from '../constants/Snackbar';

export const showSnackbar = (payload) => {
  setTimeout(() => {
    payload?.dispatch(resetSnackbar());
  }, 6000);

  return {
    type: SHOW_SNACKBAR,
    payload,
  };
};

export const resetSnackbar = () => ({
  type: RESET_SHOW_SNACKBAR,
});
