import React from "react";

function CompletedIcon(props) {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M7.16663 9.93395L8.029 9.05828L8.34129 9.37493L13.3871 4.25L14.2501 5.12504L8.34129 11.125L7.16663 9.93395ZM4.59129 9.37493L9.6371 4.25L10.5001 5.12504L4.59129 11.125L1.73364 8.21876L2.59664 7.34372L4.59129 9.37493Z"
        fill="#007B23"
      />
    </svg>
  );
}

export default CompletedIcon;
