import {
  RESER_ASSIGN_EMPLOYEE,
  ASSIGN_EMPLOYEE,
  ASSIGN_EMPLOYEE_SUCCESS,
  ASSIGN_EMPLOYEE_FAILURE,
} from "../../../constants/Workforce/AssignEmployee";

// for user authentication
export const ResetAssignEmployee = (data) => {
  return {
    type: RESER_ASSIGN_EMPLOYEE,
    payload: data,
  };
};

export const AssignEmployee = (data) => {
  return {
    type: ASSIGN_EMPLOYEE,
    payload: data,
  };
};
export const AssignEmployeeSuccess = (payload) => {
  return {
    type: ASSIGN_EMPLOYEE_SUCCESS,
    payload,
  };
};
export const AssignEmployeeFailure = (payload) => {
  return {
    type: ASSIGN_EMPLOYEE_FAILURE,
    payload,
  };
};
