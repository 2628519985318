import { Breadcrumbs, Grid, Link, Typography } from "@mui/material";
import { Link as RouterLink, useLocation } from "react-router-dom";

function PageTitle(props) {
  const { page, background } = props;
  const { title, linkName, link, child } = page;
  const location = useLocation();

  const handleClick = (event) => {
    if (link === "back") {
      event.preventDefault();
      window.history.back();
    }
  };

  return (
    <Grid
      container
      rowSpacing={3}
      sx={{
        background: background ? background : "white",
        marginTop: "5px",
        boxShadow: "none",
      }}
    >
      <Grid className="holidays-main-header">
        <Grid
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "4px",
          }}
        >
          <Breadcrumbs sx={{ fontSize: "12px" }}>
            <Link
              component={RouterLink}
              to="/main/dashboard"
              className="holidays-breadcrumbs-link"
              onClick={handleClick}
            >
              Home
            </Link>
            <Link
              component={RouterLink}
              to={link}
              className="holidays-breadcrumbs-link"
              aria-current={link === location.pathname ? "page" : undefined}
              onClick={handleClick}
            >
              {linkName}
            </Link>
            {child && <p className="holidays-breadcrumbs-link">{child}</p>}
          </Breadcrumbs>
          <Typography className="holidays-main-header-title">
            {title}
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default PageTitle;
