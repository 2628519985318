//employee constants
import { GET_EMPLOYEE_List, GET_EMPLOYEE_List_SUCCESS, GET_EMPLOYEE_List_FAILURE,RESET_EMPLOYEE_List } from "../constants/GetEmployeeList";

const INIT_STATE = {
  employeesList: {},
  loadingGetEmployeesList: false,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case RESET_EMPLOYEE_List:
      return INIT_STATE;
    case GET_EMPLOYEE_List:
      return {
        ...state,
        loadingGetEmployeesList: true,
      };
    case GET_EMPLOYEE_List_SUCCESS:
      return {
        ...state,
        loadingGetEmployeesList: false,
        employeesList: action?.payload?.payload,
      };
    // eslint-disable-next-line no-fallthrough
    case GET_EMPLOYEE_List_FAILURE:
      return {
        ...state,
        loadingGetEmployeesList: false,
        employeesList: "Error in  getting Club Data",
      };
    default:
      return state;
  }
};
