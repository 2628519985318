import {
  RESET_IMPORT_EMPLOYEES,
  IMPORT_EMPLOYEES,
  IMPORT_EMPLOYEES_SUCCESS,
  IMPORT_EMPLOYEES_FAILURE,
} from "../../../constants/Workforce/ImportEmployees";

// for user authentication
export const ResetImportEmployees = (data) => {
  return {
    type: RESET_IMPORT_EMPLOYEES,
    payload: data,
  };
};

export const ImportEmployees = (data) => {
  return {
    type: IMPORT_EMPLOYEES,
    payload: data,
  };
};
export const ImportEmployeesSuccess = (payload) => {
  return {
    type: IMPORT_EMPLOYEES_SUCCESS,
    payload,
  };
};
export const ImportEmployeesFailure = (payload) => {
  return {
    type: IMPORT_EMPLOYEES_FAILURE,
    payload,
  };
};
