import {
  EMPLOYEE_PORTAL_EDIT_PROFILE,
  EMPLOYEE_PORTAL_EDIT_PROFILE_SUCCESS,
  EMPLOYEE_PORTAL_EDIT_PROFILE_FAILURE,
  RESET_EMPLOYEE_PORTAL_EDIT_PROFILE,
} from "../../constants/EmployeePortal/EditEmployeeProfile";

export const ResetEditEmployeeProfile = (data) => {
  return {
    type: RESET_EMPLOYEE_PORTAL_EDIT_PROFILE,
    payload: data,
  };
};

export const EditEmployeeProfile = (data) => {
  return {
    type: EMPLOYEE_PORTAL_EDIT_PROFILE,
    payload: data,
  };
};
export const EditEmployeeProfileSuccess = (payload) => {
  return {
    type: EMPLOYEE_PORTAL_EDIT_PROFILE_SUCCESS,
    payload,
  };
};
export const EditEmployeeProfileFailure = (payload) => {
  return {
    type: EMPLOYEE_PORTAL_EDIT_PROFILE_FAILURE,
    payload,
  };
};
