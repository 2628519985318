import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";

import PageLoader from "components/common/PageLoader";

function index(props) {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => setIsLoading(false), 5000);
  }, []);

  return (
    <>
      <Helmet>
        <title>Edge | Community</title>
      </Helmet>
      {isLoading ? <PageLoader /> : null}
      <iframe
        onLoad={() => setIsLoading(false)}
        id="edgeCommunity"
        style={{
          border: 0,
          boxShadow: "none",
          width: "100%",
          height: "100vh",
        }}
        src="https://community.onedge.co"
      ></iframe>
    </>
  );
}

export default index;
