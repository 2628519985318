import {
    EMPLOYEES_COUNT_INFO,
    EMPLOYEES_COUNT_INFO_SUCCESS,
    EMPLOYEES_COUNT_INFO_FAILURE,
} from "../constants/EmployeesCountInfo";

// for employee information
export const EmployeeCountInfo = (data) => {
    return {
        type: EMPLOYEES_COUNT_INFO,
        payload: data,
    };
};
export const EmployeeCountInfoSuccess = (payload) => {
    return {
        type: EMPLOYEES_COUNT_INFO_SUCCESS,
        payload,
    };
};
export const EmployeeCountInfoFailure = (payload) => {
    return {
        type: EMPLOYEES_COUNT_INFO_FAILURE,
        payload,
    };
};
