import React from "react";

function ExpandIcon(props) {
  return (
    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M9.64661 3.64655L10.3536 4.35355L6.00011 8.70704L1.64661 4.35355L2.35361 3.64655L6.00011 7.29305L9.64661 3.64655Z"
        fill="#02006E"
      />
    </svg>
  );
}

export default ExpandIcon;
