import { call, put, takeEvery } from "redux-saga/effects";
//actions
import {
  GetCustomerPaymentSummarySuccess,
  GetCustomerPaymentSummaryFailure,
} from "../../actions/Customers/GetCustomerPaymentSummary";
//constant
import { GET_CUSTOMER_PAYMENT_SUMMARY } from "../../constants/Customers/GetCustomerPaymentSummary";
//request type
import getRequest from "../../../util/APIHelperGet";
//base url
import { baseUrl } from "../../../util/APIBaseUrl";

export const fetchGetCustomerPaymentSummaryRequest = async (actions) => {
  return await getRequest(
    `${baseUrl}/customerPayments/${actions.payload.customerId}?` +
      new URLSearchParams({
        limit: actions.payload.rowsPerPage,
        page: actions.payload.page,
      })
  )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

function* fetchGetCustomerPaymentSummary(params) {
  try {
    let response = yield call(fetchGetCustomerPaymentSummaryRequest, params);

    yield put(GetCustomerPaymentSummarySuccess(response));
  } catch (error) {
    console.error(error);
    yield put(GetCustomerPaymentSummaryFailure(error));
  }
}

export default function* rootSaga() {
  yield takeEvery(GET_CUSTOMER_PAYMENT_SUMMARY, fetchGetCustomerPaymentSummary);
}
