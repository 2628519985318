import React, { useState, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import ContactInfo from "./ContactInfo";
import EmergencyContact from "./EmergencyContact";
import PageLoader from "components/common/PageLoader";
import { GetEmployeeDetails } from "store/actions/GetEmployeeDetails";
import { updateEmployeeContactInfo, updateEmergencyContact } from "services/BambooHrTabs/contact";
import "./Contact.scss";

function Contact() {
  const [loading, setLoading] = useState(false);
  const [countryStates, setCountryStates] = useState([]);
  const dispatch = useDispatch();

  const countriesList = useMemo(() => JSON.parse(sessionStorage.getItem("dropdownData"))?.payload?.country || [], []);

  const getEmployeeDetails = useSelector((state) => state.GetEmployeeDetails.getEmployeeDetails);
  const LoadingGetEmployeeDetails = useSelector((state) => state.GetEmployeeDetails.loadingGET_EMPLOYEE_Details);

  const contactData = getEmployeeDetails?.payload;
  const employeeCountry = contactData?.country;
  const employeeID = contactData?.employeeId;
  const emergencyContactData = contactData?.emergencyContact;

  const handleUpdateInfo = async (updatedData, isEmergencyContact = false) => {
    try {
      setLoading(true);
      const updateFunction = isEmergencyContact ? updateEmergencyContact : updateEmployeeContactInfo;
      await updateFunction(employeeID, updatedData);
      dispatch(
        GetEmployeeDetails({
          employeeID,
          request: `employee/${employeeID}`,
        })
      );
    } catch (error) {
      console.error(`Failed to update ${isEmergencyContact ? "emergency contact" : "contact info"}:`, error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const country = countriesList.find((c) => c.countryCode?.toLowerCase() === employeeCountry?.toLowerCase());

    if (country && country.states) {
      setCountryStates(
        country.states.map((state) => ({
          value: state.name,
          label: state.name,
        }))
      );
    }
  }, [countriesList, employeeCountry]);

  return (
    <>
      {(LoadingGetEmployeeDetails || loading) && <PageLoader />}
      <div className="contact-wrapper">
        <ContactInfo
          data={contactData}
          onUpdate={(data) => handleUpdateInfo(data, false)}
          countryStates={countryStates}
        />
        <EmergencyContact
          data={emergencyContactData}
          onUpdate={(data) => handleUpdateInfo(data, true)}
          countryStates={countryStates}
        />
      </div>
    </>
  );
}

export default Contact;
