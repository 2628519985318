import {
  RESET_EDIT_COMPANY_INFO,
  EDIT_COMPANY_INFO,
  EDIT_COMPANY_INFO_SUCCESS,
  EDIT_COMPANY_INFO_FAILURE,
} from "../../constants/Settings/EditCompanyInfo";

// for add employee in admin workforce table

export const ResetEditCompanyProfile = (data) => {
  return {
    type: RESET_EDIT_COMPANY_INFO,
    payload: data,
  };
};

export const EditCompanyProfileInfo = (data) => {
  return {
    type: EDIT_COMPANY_INFO,
    payload: data,
  };
};
export const EditCompanyProfileSuccess = (payload) => {
  return {
    type: EDIT_COMPANY_INFO_SUCCESS,
    payload,
  };
};
export const EditCompanyProfileFailure = (payload) => {
  return {
    type: EDIT_COMPANY_INFO_FAILURE,
    payload,
  };
};
