import * as React from "react";
//material ui
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import { Grid, TableContainer } from "@mui/material";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import { visuallyHidden } from "@mui/utils";
import { maxWidth } from "@mui/system";

function EnhancedTableHead(props) {
  const { order, orderBy, tableheadCells, onRequestSort, rowCount } = props;

  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead
      sx={{ background: "white", marginBottom: "12px", borderRadius: "12px" }}
    >
      <TableRow>
        {tableheadCells.map((headCell) => (
          <>
            <TableCell
              key={headCell.id}
              align={headCell.numeric ? "center" : "left"}
              padding={headCell.disablePadding ? "none" : "normal"}
              // sortDirection={orderBy === headCell.id ? order : false}
              className="targetLastChild"
              sx={{
                padding: "16px 18px",
                fontSize: "13px",
                fontFamily: "Poppins-SemiBold",
                color: "#080D46",
                "& .MuiTableSortLabel-icon": {
                  color: "#3D43BB !important",
                  borderRadius: "10px",
                  backgroundColor: "#F4F4F4",
                },
                borderRight: "1px solid #D9DAF2",
              }}
            >
              <Typography variant="h5">{headCell.label}</Typography>
            </TableCell>
          </>
        ))}
      </TableRow>
    </TableHead>
  );
}

const EnhancedTableToolbar = (props) => {
  const {
    titleIcon,
    title,
    subTitle,
    filterButton,
    AddNewRowButton,
    SearchEmployee,
  } = props;
  return (
    <Grid
      container
      direction="row"
      justifyContent="space-between"
      sx={{ px: 2, py: 2 }}
    >
      <Grid item>
        <Box display="flex">
          {titleIcon ? (
            <img src={titleIcon} height={22} width={"auto"} />
          ) : null}
          <Typography variant="h2" style={{ fontWeight: "bold" }} pl={2}>
            {title != null ? title : null}
          </Typography>
          <Typography variant="paragraph" color="gray">
            {subTitle != null ? subTitle : null}
          </Typography>
        </Box>
      </Grid>
      {filterButton != null ||
      AddNewRowButton != null ||
      SearchEmployee != null ? (
        <Grid item>
          <Grid item container direction="row" columnSpacing={3}>
            {SearchEmployee != null ? <Grid item>{SearchEmployee}</Grid> : null}

            {filterButton != null ? <Grid item>{filterButton}</Grid> : null}
            {AddNewRowButton != null ? (
              <Grid item>{AddNewRowButton}</Grid>
            ) : null}
          </Grid>
        </Grid>
      ) : null}
      {/* {AddNewRowButton != null ? (
                <Grid item>{AddNewRowButton}</Grid>
            ) : null} */}
    </Grid>
  );
};

export default function DataTableV2(props) {
  const {
    handleRequestSortBy,
    totalCount,
    rowsData,
    tableheadCells,
    handleRowsPerPage,
    handleSelectedRow,
    sortByName,
    openDetailsPage,
    minimumHeight,
    tableHeader,
    tableRowContent,
    resetPage,
  } = props;

  //component state
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [headers, setHeaders] = React.useState([]);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    const direction = isAsc ? "desc" : "asc";

    const sortRequest = {
      orderBy: property,
      order: direction,
    };
    setOrder(direction);
    setOrderBy(property);
    handleRequestSortBy(sortRequest);
  };

  const handleRowSelected = (id) => {
    if (id) {
      handleSelectedRow(id, "details");
    }

    // setSelected(newSelected);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  //methods

  React.useEffect(() => {
    //
    setOrderBy(sortByName);
  }, [sortByName]);

  React.useEffect(() => {
    if (rowsData.length && rowsData != undefined && rowsData != null)
      setHeaders(Object.keys(rowsData[0]));
  }, [rowsData]);

  React.useEffect(() => {
    if (resetPage) {
      setPage(0);
    }
  }, [resetPage, totalCount]);

  React.useEffect(() => {
    if (handleRowsPerPage)
      handleRowsPerPage({ rowsPerPage: rowsPerPage, page: page });
  }, [rowsPerPage, page]);

  return (
    <Box sx={{ width: "100%" }}>
      <Paper
        sx={{
          overflowX: "hidden",
          width: "100%",
          mb: 2,
          pt: "0.2rem",
          borderRadius: "5px !important",
          background: "transparent",
          boxShadow: "none !important",
        }}
      >
        <TableContainer
          sx={{
            p: "12px 0px",
            minHeight:
              minimumHeight != false ? (rowsData.length < 3 ? 470 : 150) : 150,
            maxHeight:
              screen.width < 1600
                ? "32rem"
                : screen.width >= 1600 && screen.width < 1900
                ? "40rem"
                : "46rem",
          }}
        >
          <Table
            sx={{
              "& .MuiTableCell-root": {
                borderBottom: "none",
              },
            }}
            aria-labelledby="tableTitle"
            size={dense ? "small" : "medium"}
          >
            {tableHeader != false ? (
              <EnhancedTableHead
                order={order}
                tableheadCells={tableheadCells}
                orderBy={orderBy}
                onRequestSort={handleRequestSort}
                rowCount={rowsData.length}
              />
            ) : null}
            <Box sx={{ height: "12px" }}></Box>
            <TableBody sx={{ background: "white", overflowY: "scroll" }}>
              {rowsData.map((row, index) => {
                const isItemSelected = isSelected(row.name);
                const labelId = `enhanced-table-checkbox-${index}`;

                return (
                  <TableRow
                    hover={handleSelectedRow ? true : false}
                    textAlign={"start"}
                    role="checkbox"
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={index}
                    selected={isItemSelected}
                    sx={{
                      "&.MuiTableRow-root": {
                        verticalAlign: tableRowContent
                          ? "top !important"
                          : "center !important",
                      },
                    }}
                  >
                    {tableheadCells.map((header, index) => {
                      return (
                        <TableCell
                          className="targetLastChild"
                          key={index}
                          sx={{
                            whiteSpace: header?.wrapCell && "normal",
                            wordBreak: header?.wrapCell && "break-word",
                            cursor: header.id == openDetailsPage && "pointer",
                            borderRight: "1px solid #ECECFB",
                          }}
                          component="th"
                          id={labelId}
                          scope="row"
                          padding="dense"
                          align={header.align ? header.align : "left"}
                          width={header.width ? header.width : "auto"}
                          onClick={() => {
                            if (header.id == openDetailsPage)
                              handleRowSelected(row.id);
                          }}
                        >
                          {row[header.id]}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </Box>
  );
}
