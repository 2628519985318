import React, { useState, useMemo, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useForm, FormProvider, Controller } from "react-hook-form";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import moment from "moment";

import { Checkbox, FormControlLabel } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import { yupResolver } from "@hookform/resolvers/yup";

import CustomDatePicker from "components/common/DatePicker";
import Text from "components/common/Text";
import Button from "components/common/Button";
import Select from "components/common/Select";
import {
  addEmployeeExperience,
  deleteEmployeeExperience,
  editEdgeExperience,
  editEmployeeExperience,
} from "services/BambooHrTabs/experience";
import CustomModal from "components/common/Modal";
import PageLoader from "components/common/PageLoader";
import { convertDateToMonthAndYearShort as convertDate } from "components/Screens/CommonUtils";
import SearchableAutoComplete from "components/common/AutoComplete";

import { EMPLOYEMENT_TYPE } from "constants";
import AddExperienceSchema from "validations/AddExperienceSchema";

import "./Experience.scss";

function RenderDeleteComp({ data, closeModal, setLoading, loading, fetchExperience }) {
  const dispatch = useDispatch();
  const { jobTitle, companyName, startDate, endDate, id, Employee_ID: employeeId } = data;

  const handleExperienceDelete = async () => {
    setLoading(true);
    const response = await deleteEmployeeExperience(employeeId, id, dispatch);
    if (response?.success) {
      await fetchExperience();
    }
  };

  return (
    <div className="delete-modal-content">
      <p className="modal-heading">Delete other experience?</p>
      <p className="job-title mt-2">{jobTitle}</p>
      <p className="company-name">{companyName}</p>
      <p className="experience-date">{`${convertDate(startDate, endDate)}`}</p>
      <div className="flex w-full justify-end pt-05 gap-05">
        <Button type="submit" variant="outlined" color="inherit-text" onClick={closeModal} label="Cancel" />
        <Button
          onClick={handleExperienceDelete}
          color="error"
          size="large"
          type="submit"
          loading={loading}
          name="submitButton"
          label="Delete"
        />
      </div>
    </div>
  );
}

function AddEditExperienceModal({ experienceModal, closeModal, employeeId, setLoading, loading, fetchExperience }) {
  const dispatch = useDispatch();

  const [addMoreFields, setAddMoreFields] = useState([{ id: 1, value: "" }]);

  const countriesList = useMemo(() => JSON.parse(sessionStorage.getItem("dropdownData"))?.payload?.country || [], []);
  const countries = countriesList.map(({ countryCode }) => ({
    value: countryCode,
    label: countryCode,
  }));
  const { data, mode, show, source = "" } = experienceModal;

  const defaultValues = {
    jobTitle: source === "Edge" ? data?.designation : data?.jobTitle,
    employmentType: data?.employmentType,
    location: data?.location,
    startDate: source === "Edge" ? data?.joiningDate : data?.startDate,
    endDate: data?.endDate,
    companyName: source === "Edge" ? data?.customer?.companyName : data?.companyName,
    currentlyWorkingHere: data?.endDate === null,
    description: data?.description || [],
  };

  const methods = useForm({
    resolver: yupResolver(AddExperienceSchema),
    mode: "onChange",
    defaultValues,
  });

  const {
    reset,
    setValue,
    watch,
    handleSubmit,
    clearErrors,
    control,
    formState: { isValid },
  } = methods;

  useEffect(() => {
    if (data?.description?.length) {
      const initialFields = data.description.map((description, index) => ({
        id: index + 1,
        value: description,
      }));
      setAddMoreFields(initialFields);
    } else {
      setAddMoreFields([{ id: 1, value: "" }]);
    }
  }, [data]);

  useEffect(() => {
    if (data) {
      const initialFields = data.description
        ? data.description.map((desc, index) => ({
            id: index + 1,
            value: desc,
          }))
        : [{ id: 1, value: "" }];

      setAddMoreFields(initialFields);

      const descriptionDefaults = initialFields.reduce((acc, field) => {
        acc[`description${field.id}`] = field.value;
        return acc;
      }, {});

      reset({
        ...defaultValues,
        ...descriptionDefaults,
      });
    }
  }, [data, reset]);

  useEffect(() => {
    if (!show) {
      reset(defaultValues);
    }
  }, [show, reset]);

  const handleRemoveField = (id) => {
    setAddMoreFields(addMoreFields.filter((field) => field.id !== id));
  };

  const handleAddField = () => {
    const newId = addMoreFields.length > 0 ? addMoreFields[addMoreFields.length - 1].id + 1 : 1;
    setAddMoreFields([...addMoreFields, { id: newId, value: "" }]);
  };

  const currentlyWorkingHere = watch("currentlyWorkingHere");
  const startDate = watch("startDate");

  useEffect(() => {
    if (currentlyWorkingHere) {
      setValue("endDate", null);
      clearErrors("endDate");
    }
  }, [currentlyWorkingHere, setValue, clearErrors]);

  const onSubmit = async (payload) => {
    setLoading(true);

    const jobDescriptions = addMoreFields.map((field) => payload[`description${field.id}`]).filter(Boolean);

    const finalPayload = {
      ...payload,
      startDate: moment(payload.startDate).format("YYYY-MM-DD"),
      endDate: payload?.endDate ? moment(payload.endDate).format("YYYY-MM-DD") : null,
      description: jobDescriptions.length === 0 ? null : jobDescriptions,
    };

    if (mode === "Edit" && source === "Edge") {
      editEdgeExperience(employeeId, finalPayload, data?.employmentHistoryId, dispatch);
    } else if (mode === "Edit" && source === "") {
      await editEmployeeExperience(employeeId, finalPayload, data?.id, dispatch);
    } else if (mode === "Add") {
      await addEmployeeExperience(employeeId, finalPayload, dispatch);
    }

    await fetchExperience();
  };

  const handleOnDragEnd = (result) => {
    if (!result.destination) return;

    const reorderedFields = Array.from(addMoreFields);

    const [movedItem] = reorderedFields.splice(result.source.index, 1);
    reorderedFields.splice(result.destination.index, 0, movedItem);

    setAddMoreFields(reorderedFields);
  };

  const getExperienceTitle = () => {
    if (mode === "Edit" && source === "Edge") {
      return "Edit Edge Experience";
    }
    if (mode === "Edit") {
      return "Edit Other Experience";
    }
    return "Add Other Experience";
  };

  const addOneDay = (date) => {
    const newDate = new Date(date);
    newDate.setDate(newDate.getDate() + 1);
    return newDate;
  };

  return (
    <CustomModal open={show} width={mode === "Delete" ? "500px" : "700px"}>
      {loading && <PageLoader />}
      {mode === "Delete" ? (
        <RenderDeleteComp
          setLoading={setLoading}
          data={data}
          closeModal={closeModal}
          loading={loading}
          fetchExperience={fetchExperience}
        />
      ) : (
        <FormProvider {...methods}>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              handleSubmit((formData) => onSubmit(formData, e))(e);
            }}
          >
            <div>
              <div className="heading-h2-semibold mb-2">{getExperienceTitle()}</div>

              <div className="flex gap-2">
                <Text
                  disabled={mode === "Edit" && source === "Edge"}
                  label="Title"
                  name="jobTitle"
                  placeholder="e.g. Assistant Manager"
                />
                <Select
                  label="Employment type"
                  options={EMPLOYEMENT_TYPE}
                  name="employmentType"
                  placeholder="Select"
                  disabled={mode === "Edit" && source === "Edge"}
                />
              </div>

              <div className="flex gap-2 mt-1">
                <Text
                  label="Company"
                  name="companyName"
                  placeholder="e.g. Google"
                  disabled={mode === "Edit" && source === "Edge"}
                />
                <SearchableAutoComplete
                  name="location"
                  label="Location"
                  options={countries}
                  placeholder="Search for a country"
                  disabled={mode === "Edit" && source === "Edge"}
                />
              </div>
              <div className="flex checkbox-container">
                <Controller
                  name="currentlyWorkingHere"
                  control={control}
                  render={({ field }) => (
                    <FormControlLabel
                      control={
                        <Checkbox
                          {...field}
                          disabled={mode === "Edit" && source === "Edge"}
                          className="custom-checkbox"
                          checked={field.value}
                          onChange={(e) => field.onChange(e.target.checked)}
                        />
                      }
                      label="I am currently working in this role"
                      className="custom-label"
                    />
                  )}
                />
              </div>
              <div className="flex gap-1 mt-1">
                <CustomDatePicker disabled={mode === "Edit" && source === "Edge"} name="startDate" label="Start Date" />
                <CustomDatePicker
                  disabled={(mode === "Edit" && source === "Edge") || currentlyWorkingHere}
                  minDate={startDate ? addOneDay(startDate) : null}
                  name="endDate"
                  label="End Date"
                />
              </div>

              <div className="mt-1">
                <p className="para-body-m-medium text-headings-secondary">Job description (optional)</p>
                <DragDropContext onDragEnd={handleOnDragEnd}>
                  <Droppable droppableId="jobDescriptionFields">
                    {(provided) => (
                      <div {...provided.droppableProps} ref={provided.innerRef}>
                        {addMoreFields?.map((field, index) => (
                          <Draggable key={field.id} draggableId={field.id.toString()} index={index}>
                            {(providedTwo) => (
                              <div
                                ref={providedTwo.innerRef}
                                {...providedTwo.draggableProps}
                                className="field-container"
                              >
                                <div className="drag-icon-container" {...providedTwo.dragHandleProps}>
                                  {addMoreFields.length > 1 ? <DragIndicatorIcon className="drag-icon" /> : null}
                                </div>
                                <Controller
                                  name={`description${field.id}`}
                                  control={control}
                                  defaultValue={field.value || ""}
                                  render={({ field: controllerField }) => (
                                    <Text
                                      {...controllerField}
                                      placeholder="Enter roles, responsibilities, achievements, etc."
                                      className="job-description-input"
                                      label=""
                                    />
                                  )}
                                />
                                {addMoreFields.length > 1 ? (
                                  <IconButton onClick={() => handleRemoveField(field.id)} aria-label="delete">
                                    <CloseIcon className="delete-description-icon" />
                                  </IconButton>
                                ) : null}
                              </div>
                            )}
                          </Draggable>
                        ))}
                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                </DragDropContext>
                {addMoreFields?.length < 3 ? (
                  <div className="add-more-container" onClick={handleAddField}>
                    <AddIcon className="add-more-icon" />
                    <span className="add-more-text">Add More</span>
                  </div>
                ) : null}
              </div>
            </div>

            <div className="button-container">
              <Button
                type="button"
                name="cancelButton"
                variant="outlined"
                size="large"
                label="Cancel"
                onClick={closeModal}
                isFullWidth={false}
              />
              <Button size="large" isDisabled={!isValid} type="submit" label="Save" isFullWidth={false} />
            </div>
          </form>
        </FormProvider>
      )}
    </CustomModal>
  );
}

export default AddEditExperienceModal;
