//employee constants
import {
    RESET_LOGO,
    LOGO,
    LOGO_SUCCESS,
    LOGO_FAILURE,
} from "../../constants/Logo";

const INIT_STATE = {
    profileLogo: {},
    loadingProfileLogo: true,
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case RESET_LOGO:
            return INIT_STATE;
        case LOGO:
            return {
                ...state,
                loadingProfileLogo: true,
            };
        case LOGO_SUCCESS:
            return {
                ...state,
                loadingProfileLogo: false,
                profileLogo: action.payload,
            };
        // eslint-disable-next-line no-fallthrough
        case LOGO_FAILURE:
            return {
                ...state,
                loadingProfileLogo: false,
                profileLogo: "Error in  getting Club Data",
            };
        default:
            return state;
    }
};
