import { call, put, takeEvery } from "redux-saga/effects";
//actions
import {
  DeleteUserSuccess,
  DeleteUserFailure,
} from "../../actions/Settings/DeleteUser";
//constant
import { DELETE_USER } from "../../constants/Settings/DeleteUser";
//request type
import deleteRequest from "../../../util/ApiHelperDelete";
import { baseUrl } from "../../../util/APIBaseUrl";

export const fetchDeleteUserRequest = async (actions) => {
  return await deleteRequest(`${baseUrl}/${actions?.payload?.request}`)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

function* fetchDeleteUser(params) {
  try {
    let response = yield call(fetchDeleteUserRequest, params);
    yield put(DeleteUserSuccess(response));
  } catch (error) {
    console.error(error);
    yield put(DeleteUserFailure(error));
  }
}

export default function* rootSaga() {
  yield takeEvery(DELETE_USER, fetchDeleteUser);
}
