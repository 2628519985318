import {
  EMPLOYEE_DOCUMENTS_REQUEST,
  EMPLOYEE_DOCUMENTS_REQUEST_SUCCESS,
  EMPLOYEE_DOCUMENTS_REQUEST_FAILURE,
} from "../../constants/EmployeePortal/EmployeeDocuments";

export const EmployeeDocumentsRequest = (data) => {
  return {
    type: EMPLOYEE_DOCUMENTS_REQUEST,
    payload: data,
  };
};
export const EmployeeDocumentsRequestSuccess = (payload) => {
  return {
    type: EMPLOYEE_DOCUMENTS_REQUEST_SUCCESS,
    payload,
  };
};
export const EmployeeDocumentsRequestFailure = (payload) => {
  return {
    type: EMPLOYEE_DOCUMENTS_REQUEST_FAILURE,
    payload,
  };
};
