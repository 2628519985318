import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Divider, Box, TextField, InputAdornment } from "@mui/material";

import PageLoader from "components/common/PageLoader";
import { getCountryFlag } from "../CommonUtils";
import { getDateInFormat } from "helpers";

import { EmployeePeers } from "store/actions/EmployeePeers";
import { baseUrl } from "util/APIBaseUrl";

import avatarPlaceHolder from "assets/images/fallback-image.svg";

import "./EmployeePeers.css";

function EmployeePeersGridCell_A(props) {
  const [imageSrc, setImageSrc] = useState(
    `${baseUrl}/employee/${props?.row?.employeeId}/logo?id=${props?.row?.pictureVersion}`
  );
  const [isPlaceHolderImage, setIsPlaceHolderImage] = useState(false);

  const handleImageError = () => {
    setIsPlaceHolderImage(true);
    setImageSrc(avatarPlaceHolder);
  };

  return (
    <div className="employee-peers-cella">
      <img
        alt={props?.row?.firstName}
        src={imageSrc}
        onError={handleImageError}
        style={{
          objectFit: isPlaceHolderImage ? "contain" : "cover",
          padding: isPlaceHolderImage ? "1rem" : "0",
          paddingRight: "0",
        }}
      />
      <Box
        sx={{
          position: "absolute",
          bottom: "5px",
          left: "5px",
          border: "2px solid white",
          borderRadius: "99px",
          width: "26px",
          height: "26px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {getCountryFlag(props?.row?.country)}
      </Box>
      <div style={{ alignSelf: "center", marginLeft: "25px" }}>
        <div className="peer-name" id="peer-name">{`${props?.row?.firstName}  ${props?.row?.lastName}`}</div>
        <div className="peer-country">{props?.row?.jobTitle}</div>
        <div className="peer-join-date" id="peer-join-date">{`Join Date: ${getDateInFormat(
          props?.row?.startDate
        )}`}</div>
      </div>
    </div>
  );
}

function EmployeePeersGridCell_C(props) {
  return (
    <div className="employee-peers-cellb">
      <div>
        <Box component="img" sx={{ width: "5rem" }} src="../assets/Images/email-vector.png" />
      </div>
      <div>
        <a className="employee-email" id="peer-email" target="_blank" href={`mailto:${props?.row?.email}`}>
          {props?.row?.email}
        </a>
        <div className="employee-contact-btn">Contact</div>
      </div>
    </div>
  );
}

function EmployeePeersGridCell_B(props) {
  return (
    <div className="employee-peers-cellc">
      <div>
        <div className="peer-title">{props?.row?.jobTitle}</div>
      </div>
      <div className="employee-birthday">Role/Designation</div>
    </div>
  );
}

function EmployeePeersGridCell_D(props) {
  const isProfileAvailable = !!props?.row?.Community_Proifle_Url;
  return (
    <a href={props?.row?.Community_Proifle_Url} target="_blank" style={{ width: "10%" }}>
      <div className="employee-peers-celld-container">
        <div
          style={{
            cursor: isProfileAvailable ? "pointer" : "not-allowed",
            color: isProfileAvailable ? "#14151f" : "#b7afaf",
            border: isProfileAvailable ? "1px solid grey" : "1px solid #b7afaf",
          }}
          className="employee-peers-celld"
        >
          View Profile
        </div>
      </div>
    </a>
  );
}

function EmployeePeersList(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const EmployeePeers_ = useSelector((state) => state.EmployeePeers);
  const loadingEmployeePeers = useSelector((state) => state.EmployeePeers.isLoadingData);

  // On Enter in search field call endpoint.
  const searchPeers = (e) => {
    // create url with query strings escaped.

    let loggedInUser = JSON.parse(localStorage.getItem("loggedInUser"));
    const params = new URLSearchParams();
    params.set("filter", JSON.stringify({ name: e.target.value }));
    const _url = `employee/${loggedInUser.userId}/peers?${params}`;
    dispatch(EmployeePeers(_url));
  };

  React.useEffect(() => {
    // Only fetch if the details are not in redux store
    if (!Object.keys(EmployeePeers_?.peers || {})?.length) {
      let loggedInUser = JSON.parse(localStorage.getItem("loggedInUser"));
      dispatch(EmployeePeers(`employee/${loggedInUser.userId}/peers`));
    }
  }, []);

  return (
    <>
      <Helmet>
        <title>Edge | Peers</title>
      </Helmet>
      {loadingEmployeePeers == true && <PageLoader />}
      <div className="employee-paystubs-details">
        <div className="employee-paystubs-details-dA">
          <div>
            <div className="employee-paystubs-details-dAA">
              <Box component="img" src="../../assets/Images/backbtn.png" onClick={() => navigate(-1)} />
              <div className="employee-paystubs-details-slips">Your Peers</div>
            </div>
          </div>
        </div>

        <Divider sx={{ margin: "0px 0px 10px 0px" }} />
        <div className="peers-wrapper">
          <div className="employee-paystubs-filters">
            <TextField
              type="search"
              id="search-peers"
              onChange={searchPeers}
              placeholder="Search peers"
              sx={{
                ".MuiInputBase-root": {
                  borderRadius: "99px",
                  borderColor: "#BFC2FF",
                  outlineColor: "#BFC2FF",
                  fontFamily: "Poppins-Regular",
                  fontSize: "14px",
                  lineHeight: "20px",
                  paddingRight: "6px",
                  border: "1px solid #BFC2FF",
                  width: "315px",
                },
              }}
              InputProps={{
                disableUnderline: true,
                endAdornment: (
                  <InputAdornment position="start">
                    <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M14.9066 13.6148L19.8274 18.5355L18.5356 19.8272L13.6149 14.9065C12.3775 15.8684 10.8227 16.4412 9.13409 16.4412C5.09844 16.4412 1.8269 13.1696 1.8269 9.13397C1.8269 5.09832 5.09844 1.82678 9.13409 1.82678C13.1697 1.82678 16.4413 5.09832 16.4413 9.13397C16.4413 10.8226 15.8685 12.3774 14.9066 13.6148ZM9.13409 14.6144C12.1608 14.6144 14.6145 12.1607 14.6145 9.13397C14.6145 6.10724 12.1608 3.65358 9.13409 3.65358C6.10736 3.65358 3.6537 6.10724 3.6537 9.13397C3.6537 12.1607 6.10736 14.6144 9.13409 14.6144Z"
                        fill="#00201C"
                      />
                    </svg>
                  </InputAdornment>
                ),
              }}
            />
          </div>
          <br />
          {EmployeePeers_.peers?.rows?.map((row) => (
            <div className="employee-peers-listA">
              <EmployeePeersGridCell_A row={row} />
              {/* <EmployeePeersGridCell_B row={row} /> */}
              <EmployeePeersGridCell_C row={row} />

              <EmployeePeersGridCell_D row={row} />
            </div>
          ))}
        </div>

        {/* <div className="employee-peers-listA">
        <Grid
          header={null}
          rows={EmployeePeers_.peers?.rows || []}
          columns={[
            { key: "picture", component: EmployeePeersGridCell_A },
            { key: "keyb", component: EmployeePeersGridCell_B },
            { key: "keyc", component: EmployeePeersGridCell_C },
            { key: "keyc", component: EmployeePeersGridCell_D },
          ]}
        />
      </div> */}
      </div>
    </>
  );
}

export default EmployeePeersList;
