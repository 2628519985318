import React from "react";

const PayrollHistoryIcon = () => {
  return (
    <svg
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.2915 4.25073H13.1248C12.8486 4.25073 12.5836 4.35879 12.3883 4.55114C12.1929 4.74348 12.0832 5.00436 12.0832 5.27637V8.3533H7.9165C7.64024 8.3533 7.37529 8.46135 7.17993 8.6537C6.98458 8.84604 6.87484 9.10692 6.87484 9.37894V12.4559H2.70817C2.4319 12.4559 2.16695 12.5639 1.9716 12.7563C1.77625 12.9486 1.6665 13.2095 1.6665 13.4815V16.5584C1.6665 16.8304 1.77625 17.0913 1.9716 17.2837C2.16695 17.476 2.4319 17.5841 2.70817 17.5841H17.2915C17.5678 17.5841 17.8327 17.476 18.0281 17.2837C18.2234 17.0913 18.3332 16.8304 18.3332 16.5584V5.27637C18.3332 5.00436 18.2234 4.74348 18.0281 4.55114C17.8327 4.35879 17.5678 4.25073 17.2915 4.25073ZM13.1248 7.32766H17.2915V8.3533H13.1248V7.32766ZM7.9165 13.4815H12.0832V14.5071H7.9165V13.4815ZM13.1248 11.4302H17.2915V12.4559H13.1248V11.4302ZM13.1248 10.4046V9.37894H17.2915V10.4046H13.1248ZM12.0832 12.4559H7.9165V11.4302H12.0832V12.4559ZM7.9165 15.5328H12.0832V16.5584H7.9165V15.5328ZM13.1248 13.4815H17.2915V14.5071H13.1248V13.4815ZM17.2915 5.27637V6.30201H13.1248V5.27637H17.2915ZM12.0832 9.37894V10.4046H7.9165V9.37894H12.0832ZM6.87484 13.4815V14.5071H2.70817V13.4815H6.87484ZM2.70817 15.5328H6.87484V16.5584H2.70817V15.5328ZM13.1248 16.5584V15.5328H17.2915V16.5584H13.1248Z"
        fill="#FBFAFF"
      />
    </svg>
  );
};

export default PayrollHistoryIcon;
