import React from "react";

const DocumentIcon = () => {
  return (
    <svg
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.25 8.78491V16.3923C16.25 16.8896 16.0525 17.3665 15.7008 17.7182C15.3492 18.0698 14.8723 18.2673 14.375 18.2673H5.625C5.12772 18.2673 4.65081 18.0698 4.29917 17.7182C3.94754 17.3665 3.75 16.8896 3.75 16.3923V3.89233C3.75 3.39505 3.94754 2.91814 4.29917 2.56651C4.65081 2.21488 5.12772 2.01733 5.625 2.01733H9.48242C9.81383 2.01738 10.1316 2.14904 10.366 2.38335L15.884 7.90132C16.1183 8.13568 16.2499 8.45351 16.25 8.78491Z"
        stroke="white"
        stroke-width="1.5"
        stroke-linejoin="round"
      />
      <path
        d="M10 2.32983V7.01733C10 7.34885 10.1317 7.6668 10.3661 7.90122C10.6005 8.13564 10.9185 8.26733 11.25 8.26733H15.9375M6.875 11.3923H13.125M6.875 14.5173H13.125"
        stroke="white"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default DocumentIcon;
