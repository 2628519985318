import {
  RESER_GIVE_RAISE,
  GIVE_RAISE,
  GIVE_RAISE_SUCCESS,
  GIVE_RAISE_FAILURE,
} from "../../constants/Raises/GiveRaise";

// for user authentication

export const ResetGiveRaise = (data) => {
  return {
    type: RESER_GIVE_RAISE,
    payload: data,
  };
};

export const GiveRaise = (data) => {
  return {
    type: GIVE_RAISE,
    payload: data,
  };
};
export const GiveRaiseSuccess = (payload) => {
  return {
    type: GIVE_RAISE_SUCCESS,
    payload,
  };
};
export const GiveRaiseFailure = (payload) => {
  return {
    type: GIVE_RAISE_FAILURE,
    payload,
  };
};
