import React from "react";
//material ui
import {
  Button,
  getInputAdornmentUtilityClass,
  Grid,
  Paper,
  Toolbar,
  Typography,
} from "@mui/material";
//files
import ProfleWithIcon from "./../Widgets/ProfileWithIcon";
import AvatarIcon from "../Icons/AvatarIcon";
import { Permission } from "../Screens/Workforce/AdminWorkforce/AddEmployee/pages.enum";

//constants
const employeeDetails = {
  name: "Account manager",
  role: "aliraza30028@gmail.com",
};
function Header(props) {
  const { data } = props;

  //function
  const getName = (name) => {
    if (name != undefined || name != null) {
      return name;
    } else {
      return "-";
    }
  };

  const getRole = (role) => {
    if (role != undefined || role != null) {
      return role;
    } else {
      return "-";
    }
  };

  return (
    <Grid
      item
      container
      direction="row"
      justifyContent="space-between"
      alignItems="flex-start"
      lg={12}
      xs={12}
      rowSpacing={3}
    >
      <Grid item>
        {data && (
          <>
            <Grid
              item
              container
              direction="row"
              alignItems="flex-end"
              columnSpacing={3}
            >
              <Grid item>
                <Typography variant="h1">{data.heading}</Typography>

                <Typography
                  variant="h6"
                  pt={1}
                  sx={
                    data.subHeaderColor != null
                      ? data.subHeaderColor
                      : { color: "#43425D" }
                  }
                  letterSpacing={data.letterSpacing}
                  // color={`${data.subHeaderColor}`}
                >
                  {data.subHeader}
                </Typography>
              </Grid>
              {data.subHeader != null &&
              data.subHeaderButton != null &&
              data.subHeaderButton != false &&
              data.customerSubscription?.status == "incomplete" ? (
                <Grid item>
                  <Button
                    variant="contained"
                    sx={{
                      pt: "5px",
                      pl: "10px",
                      pr: "10px",
                      pb: "5px",
                      // mt: "-8px",
                      borderRadius: 2,
                      backgroundColor: "warning.main",
                    }}
                    onClick={() =>
                      window.open(`${data.customerSubscription?.invoice_link}`)
                    }
                  >
                    <Typography
                      variant="paragraph"
                      color="white"
                      fontWeight="550"
                    >
                      Run Again
                    </Typography>
                  </Button>
                </Grid>
              ) : null}
            </Grid>
          </>
        )}
      </Grid>

      {data && data.profileWithIcon != null ? (
        <Grid item lg={3} md={5} sm={8} xs={12}>
          <Paper sx={{ p: 2 }}>
            <ProfleWithIcon
              // data={data.profileWithIcon.userData}
              data={
                data.profileWithIcon.userData != null
                  ? {
                      name:
                        getName(
                          data.profileWithIcon?.userData.accountManager
                            ?.firstName
                        ) +
                        "  " +
                        getName(
                          data.profileWithIcon?.userData.accountManager
                            ?.lastName
                        ),
                      //   (data.profileWithIcon.userData
                      //       .accountManager
                      //       ? data.profileWithIcon
                      //             .userData.accountManager
                      //             .First_Name
                      //           ? data.profileWithIcon
                      //                 .userData
                      //                 .accountManager
                      //                 .First_Name
                      //           : "-"
                      //       : "-") +
                      //   " " +
                      //   "----",

                      role: getRole(
                        data.profileWithIcon.userData.accountManager?.email
                      ),
                    }
                  : null
              }
              icon={<AvatarIcon shape={data.profileWithIcon.shape} />}
              // shape={data.profileWithIcon.shape}
            />
          </Paper>
        </Grid>
      ) : null}
    </Grid>
  );
}

export default Header;
