import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Chip, Divider } from "@mui/material";
import { differenceInDays } from "date-fns";

import MedicalDocsModal from "../../MedicalDocsModal";
import { convertDate, getWorkingDuration } from "components/Screens/CommonUtils";
import EmployeeAvatar from "components/Screens/Timeoff/TimeoffAdmin/EmployeeTimeOffDetails/EmployeeAvatar";
import EditByHrStatus from "../../Common/EditByHrStatus";

import PendingTimeIcon from "assets/images/pending-timer.svg";
import PendingLeaveIcon from "assets/images/pending-leave-chip-icon.svg";
import LeftArrow from "assets/images/left-arrow.svg";
import CrossIcon from "assets/svgs/CrossIcon";
import AvatarImage from "assets/images/fallback-image.svg";
import MedicalDocsIcon from "assets/images/medical-docs.svg";

import { baseUrl } from "util/APIBaseUrl";
import { makeRequest } from "util/APIHelper";

import { showSnackbar } from "store/actions/Utility";
import { GET_LEAVE_REQUESTS_SUCCESS } from "store/constants/Timeoff";
import { FetchLeaveRequests } from "store/actions/Timeoff/LeaveRequests";

import "./PendingLeavesCard.css";
import LeaveType from "../../Common/LeaveType";
import NoCommentView from "./NoCommentView";

const PendingLeavesCard = ({
  leave: {
    leaveType,
    documentsAttached,
    employeeId,
    employeeName,
    country,
    dateFrom,
    dateTo,
    createdAt,
    description,
    noOfLeaves,
    requestId,
    picturePath,
    pictureVersion,
    hrEdit,
    hrApproved,
    status,
  },
}) => {
  const dispatch = useDispatch();

  const leaveRequests = useSelector((state) => state?.TimeoffLeaveRequests || []);

  const [imageSrc, setImageSrc] = useState(AvatarImage);

  const [medicalDocsModal, setMedicalDocsModal] = useState({
    show: false,
    id: null,
  });
  const [isCancellingRequest, setIsCancellingRequest] = useState({ state: false, showSnackbar: false });

  const handleCancelLeave = async () => {
    setIsCancellingRequest({
      state: true,
      showSnackbar: false,
    });

    await makeRequest({ path: `${baseUrl}/employee/${requestId}/leave/Cancelled`, method: "PUT" }, false);

    setIsCancellingRequest({
      state: false,
      showSnackbar: true,
    });

    const pendingLeaveReqs = leaveRequests?.leaveRequests?.pending?.filter((req) => req.requestId !== requestId);
    const leaveReqs = {
      ...leaveRequests.leaveRequests,
      pending: pendingLeaveReqs,
    };

    dispatch({
      type: GET_LEAVE_REQUESTS_SUCCESS,
      payload: { payload: { data: leaveReqs } },
    });

    dispatch(FetchLeaveRequests({}));
  };

  useEffect(() => {
    if (isCancellingRequest.showSnackbar)
      dispatch(showSnackbar({ dispatch, type: "success", message: "Leave request has been cancelled succesfully" }));
  }, [isCancellingRequest]);

  useEffect(() => {
    if (employeeId && pictureVersion) {
      const empImageUrl = `${baseUrl}/employee/${employeeId}/logo?id=${pictureVersion}&variant=thumbnail`;
      setImageSrc(empImageUrl);
    } else {
      setImageSrc(AvatarImage);
    }
  }, [employeeId, pictureVersion]);

  return (
    <div className="pending-leaves-card-container">
      <MedicalDocsModal
        open={medicalDocsModal.show}
        title="Medical Documents"
        leaveRequestId={requestId}
        closeModal={() =>
          setMedicalDocsModal({
            show: false,
            id: null,
          })
        }
        {...{ leaveType, dateFrom, dateTo, noOfLeaves }}
      />
      <div className="pending-leaves-top-section">
        <div className="pending-leaves-date">
          <img className="pending-leaves-time-icon" src={PendingTimeIcon} alt="pending time" />
          <div>
            <div className="pending-leave-reason" id="leave-type">
              {leaveType}
            </div>

            <div className="pending-leave-duration">
              <div className="pending-leave-date" id="start-date">
                {convertDate(dateFrom)}
              </div>
              {dateFrom !== dateTo && (
                <>
                  <img className="pending-leave-arrow" src={LeftArrow} alt="left arrow" />
                  <div className="pending-leave-date" id="end-date">
                    {convertDate(dateTo)}
                  </div>
                </>
              )}
            </div>
          </div>
        </div>

        <Chip
          variant="outlined"
          className="pending-leave-chip"
          icon={<CrossIcon className="chip-leaves-pending-icon" fill="#52537A" />}
          clickable
          label={
            <div className="cancel_leave_request_chip_label" id="cancel-request-btn" onClick={handleCancelLeave}>
              Cancel Request
            </div>
          }
        />
      </div>

      <Divider light />
      <div className=" pending-timeoff-details-wrapper" style={{ marginTop: "9px" }}>
        <div className="pending-timeoff-details">
          <div className="pending-timeoff-text-emp">Duration</div>
          <Chip
            variant="outlined"
            className="custom-chip"
            id="total-timeoff-used"
            label={`${noOfLeaves}  ${noOfLeaves == 1 ? "leave" : "leaves"}`}
          />
        </div>

        <div className="pending-timeoff-details">
          <div className="pending-timeoff-text-emp">Notice Period</div>
          <Chip
            variant="outlined"
            className="custom-chip"
            id="notice-period"
            label={`${differenceInDays(new Date(dateFrom), new Date(createdAt))} Days`}
          />
        </div>

        <div className="pending-timeoff-details">
          <div className="pending-timeoff-text-emp">Status</div>

          <LeaveType type="pending" />
        </div>
        {country === "Peru" && (leaveType === "Sick" || hrEdit || hrApproved) && (
          <div className="pending-timeoff-details">
            <div className="pending-timeoff-text-emp">HR doc review</div>
            <EditByHrStatus
              style={{ width: "fit-content !important" }}
              hrEdit={hrEdit}
              hrApproved={hrApproved}
              leaveStatus={status}
            />
          </div>
        )}
      </div>

      <Divider light />

      <div className="pending-timeoff-comments">Comments:</div>
      <div className="pending-timeoff-comments-container ml-1 mr-05">
        <EmployeeAvatar
          img={imageSrc}
          size={{
            width: "44px",
            height: "44px",
            borderRadius: "50%",
          }}
        />

        {description ? (
          <div className="pending-timeoff-comment-wrapper" style={{ marginLeft: "9px" }}>
            <div className="pending-timeoff-comment break-word" id="leave-description">
              {description ? description : ""}
            </div>
            <div className="pending-timeoff-comment-author" id="emp-name">
              {employeeName}
            </div>
          </div>
        ) : (
          <NoCommentView />
        )}

        {leaveType === "Sick" && documentsAttached && (
          <div
            className="flex medical-docs-btn cursor-pointer"
            onClick={() =>
              setMedicalDocsModal({
                show: true,
                id: requestId,
              })
            }
          >
            <img src={MedicalDocsIcon} alt="medical docs" />
            <div className="flex flex-col" style={{ alignSelf: "center", marginLeft: "0.5rem" }}>
              <div className="pop-regular title-md medical-docs-btn-label">Medical Docs</div>
              <div className="label-sm pop-regular neutral-60" id="medical-docs-btn">
                View
              </div>
            </div>
          </div>
        )}
      </div>

      <div className="pending-timeoff-request-date ml-05" id="request-date">
        Request date: {convertDate(createdAt)} ({getWorkingDuration(createdAt)} ago)
      </div>
    </div>
  );
};

export default PendingLeavesCard;
