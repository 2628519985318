import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Fade } from "@mui/material";

import PendingLeavesCard from "components/Screens/Timeoff/ManageTimeoff/PendingLeaves/PendingLeavesCard";

import LeftArror from "assets/images/left-Arrow-filled.svg";
import RightArror from "assets/images/right-Arrow-filled.svg";

import "./PendingLeaves.css";
import PageLoader from "components/common/PageLoader";

const PendingLeaves = () => {
  const dispath = useDispatch();

  const [pendingleaveRequests, setPendingleaveRequests] = useState([]);
  const leaveRequests = useSelector((state) => state?.TimeoffLeaveRequests?.leaveRequests || []);
  const loadingLeaveRequests = useSelector((state) => state?.TimeoffLeaveRequests?.loadingLeaveRequests);

  useEffect(() => {
    setPendingleaveRequests(leaveRequests?.pending || []);
  }, [leaveRequests]);

  const [cardShownOnScreenFlag, setCardShownOnScreenFlag] = useState(true);
  const [currentShownLeaveCard, setCurrentShownLeaveCard] = useState(0);

  useEffect(() => {
    setCardShownOnScreenFlag(false);
    setTimeout(() => setCardShownOnScreenFlag(true), 300);
  }, [currentShownLeaveCard]);

  return (
    <>
      {loadingLeaveRequests && <PageLoader />}
      <div className="pending-leaves-container-emp">
        {pendingleaveRequests.length ? (
          <>
            {" "}
            <img
              src={LeftArror}
              style={{
                filter: currentShownLeaveCard === 0 ? "grayscale(1)" : "",
                cursor: currentShownLeaveCard === 0 ? "not-allowed" : "pointer",
              }}
              onClick={() => {
                if (currentShownLeaveCard !== 0) setCurrentShownLeaveCard(currentShownLeaveCard - 1);
              }}
              alt="left arrow"
            />
            <Fade className="w-100p" in={cardShownOnScreenFlag}>
              <div>
                <PendingLeavesCard leave={pendingleaveRequests[currentShownLeaveCard] || {}} />
              </div>
            </Fade>
            <img
              style={{
                filter: currentShownLeaveCard === pendingleaveRequests.length - 1 ? "grayscale(1)" : "",
                cursor: currentShownLeaveCard === pendingleaveRequests.length - 1 ? "not-allowed" : "pointer",
              }}
              src={RightArror}
              onClick={() => {
                if (currentShownLeaveCard !== pendingleaveRequests.length - 1)
                  setCurrentShownLeaveCard(currentShownLeaveCard + 1);
              }}
              alt="left arrow"
            />
          </>
        ) : (
          <p className="m-auto text-center">No pending requests</p>
        )}
      </div>
    </>
  );
};

export default PendingLeaves;
