import { call, put, takeEvery } from "redux-saga/effects";
import {
  AssignEmployeeSuccess,
  AssignEmployeeFailure,
} from "../../../actions/Workforce/AssignEmployee";
import { ASSIGN_EMPLOYEE } from "../../../constants/Workforce/AssignEmployee";
import postRequest from "../../../../util/APIHelperPost";
import { baseUrl } from "../../../../util/APIBaseUrl";

const getUtcFromDate = (date) => {
  return new Date(date).toDateString();
};

export const fetchAssignEmployeeRequest = async (actions) => {
  let body = JSON.stringify({
    customerId: actions.payload?.customerId,
    jobTitle: actions.payload?.jobTitle,
    department: actions.payload?.department,
    employmentType: actions.payload.employmentType,
    startDate: getUtcFromDate(actions.payload.dateOfJoining),
  });

  return await postRequest(
    `${baseUrl}/employee/${actions.payload.employeeId}/${actions.payload.request}`,
    body
  )
    .then((response) => response)
    .catch((error) => {
      throw error;
    });
};

function* fetchAssignEmployee(params) {
  try {
    let response = yield call(fetchAssignEmployeeRequest, params);
    yield put(AssignEmployeeSuccess(response));
  } catch (error) {
    console.error(error);
    yield put(AssignEmployeeFailure(error));
  }
}

export default function* rootSaga() {
  yield takeEvery(ASSIGN_EMPLOYEE, fetchAssignEmployee);
}
