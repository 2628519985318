//employee constants
import {
  EMPLOYEE_PAY_SLIP_DETAILS,
  EMPLOYEE_PAY_SLIP_DETAILS_SUCCESS,
  EMPLOYEE_PAY_SLIP_DETAILS_FAILURE,
} from "../../constants/EmployeePortal/EmployeePaySlipDetails";

const INIT_STATE = {
  payslip: {},
  isLoading: false,
  error: null,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case EMPLOYEE_PAY_SLIP_DETAILS:
      return {
        ...state,
        isLoading: true,
      };
    case EMPLOYEE_PAY_SLIP_DETAILS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        payslip: action.payload.payload,
        error: null,
      };
    case EMPLOYEE_PAY_SLIP_DETAILS_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: "Error in getting Pay Details",
      };
    default:
      return state;
  }
};
