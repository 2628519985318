import {
    EMPLOYEE_PEERS,
    EMPLOYEE_PEERS_SUCCESS,
    EMPLOYEE_PEERS_FAILURE,
} from "../constants/EmployeePeers";


export const EmployeePeers = (data) => {
    return {
        type: EMPLOYEE_PEERS,
        payload: data,
    };
};
export const EmployeePeersSuccess = (payload) => {
    return {
        type: EMPLOYEE_PEERS_SUCCESS,
        payload,
    };
};
export const EmployeePeersFailure = (payload) => {
    return {
        type: EMPLOYEE_PEERS_FAILURE,
        payload,
    };
};
