import React from "react";

const CommunityIcon = () => {
  return (
    <svg
      width="14"
      height="19"
      viewBox="0 0 14 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M8.8335 14.6451C8.25373 14.8508 7.64123 14.9545 7.00016 14.9545C6.3591 14.9545 5.74659 14.8508 5.16683 14.6451V14.9545C5.16683 15.967 5.98764 16.7878 7.00016 16.7878C8.01268 16.7878 8.8335 15.967 8.8335 14.9545V14.6451ZM5.13126 12.6099C5.70249 12.9528 6.31999 13.1212 7.00016 13.1212C7.68211 13.1212 8.30107 12.9519 8.87354 12.6072C8.9203 12.3537 9.00725 12.1049 9.13035 11.8553C9.27505 11.562 9.45905 11.2826 9.72923 10.9231C9.78744 10.8457 10.2516 10.246 10.3867 10.0623C11.2013 8.95473 11.5835 8.01342 11.5835 6.70451C11.5835 4.1732 9.53147 2.12118 7.00016 2.12118C4.46886 2.12118 2.41683 4.1732 2.41683 6.70451C2.41683 7.96818 2.7896 8.86474 3.61189 9.97537C3.73015 10.1351 4.13744 10.6642 4.17085 10.7084C4.40118 11.0132 4.56412 11.2481 4.70453 11.4891C4.9232 11.8645 5.06837 12.2305 5.13126 12.6099ZM10.6668 13.0433V14.9545C10.6668 16.9796 9.02521 18.6212 7.00016 18.6212C4.97512 18.6212 3.3335 16.9796 3.3335 14.9545V13.0433C3.3335 12.8643 3.27009 12.669 3.12033 12.4118C3.02412 12.2467 2.89824 12.0652 2.70833 11.814C2.68423 11.7821 2.26897 11.2426 2.13844 11.0663C1.10273 9.66738 0.583496 8.41855 0.583496 6.70451C0.583496 3.16068 3.45634 0.287842 7.00016 0.287842C10.544 0.287842 13.4168 3.16068 13.4168 6.70451C13.4168 8.45604 12.8905 9.75227 11.8636 11.1485C11.7122 11.3544 11.24 11.9645 11.1947 12.0247C10.9872 12.3007 10.8583 12.4965 10.7745 12.6664C10.6986 12.8202 10.6668 12.9391 10.6668 13.0433Z"
        fill="#FBFAFF"
      />
    </svg>
  );
};

export default CommunityIcon;
