import React from "react";

// mui
import { Breadcrumbs, Grid, Link, Typography } from "@mui/material";
import DocumentCard from "../Workforce/UserDetailsV2/DocumentCard";
import ContractIcon from "../../../assets/svgs/ContractIcon";
import BackgroundDocumentsIcon from "../../../assets/svgs/BackgroundDocumentsIcon";
import NdaIcon from "../../../assets/svgs/NdaIcon";
import PersonalIdIcon from "../../../assets/svgs/PersonalIdIcon";
import { useSelector, useDispatch } from "react-redux";
import GetDocumentsList from "../../../store/reducers/Doucments/GetDocumentsList";
import { baseUrl } from "../../../util/APIBaseUrl";
import { usePermissions } from "../../Hook";

const index = () => {
  // constants
  const dispatch = useDispatch();

  //redux state
  // const documentsList = useSelector(
  //     (state) => state.GetDocumentsList.documentsList
  // );

  //custom hook
  const { loggedInUserType, loggedInUser } = usePermissions();

  // React.useState(() => {
  //     if (loggedInUserType === "customer") {
  //         dispatch(
  //             GetDocumentsList({
  //                 request: `${baseUrl}`,
  //             })
  //         );
  //     }
  // }, []);

  const documents = [
    {
      id: 1,
      image: <ContractIcon />,
      name: "Contract",
      documentAdded: true,
    },
    {
      id: 2,
      image: <BackgroundDocumentsIcon />,
      name: "Background Checks",
      documentAdded: true,
    },
    {
      id: 3,
      image: <NdaIcon />,
      name: "NDA",
      documentAdded: true,
    },
    {
      id: 4,
      image: <PersonalIdIcon />,
      name: "Personal ID",
      documentAdded: false,
    },
  ];

  return (
    <Grid sx={{ backgroundColor: "#FBFAFF", height: "100%" }}>
      <Grid
        container
        rowSpacing={3}
        sx={{
          marginTop: "5px",
          backgroundColor: "#FFFFFF",
        }}
      >
        <Grid
          sx={{
            maxWidth: "1076px",
            width: "100%",
            margin: "0px auto",
            padding: "12px 0px",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Grid
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "4px",
            }}
          >
            <Breadcrumbs sx={{ fontSize: "12px" }}>
              <Link
                href="/main/dashboard"
                sx={{
                  fontFamily: "Poppins-Regular",
                  fontSize: "12px",
                  fontWeight: 400,
                  color: "#212231",
                  textDecoration: "none",
                }}
              >
                Home
              </Link>
              <Link
                sx={{
                  fontFamily: "Poppins-Regular",
                  fontSize: "12px",
                  fontWeight: 400,
                  color: "#212231",
                  textDecoration: "none",
                }}
                aria-current="page"
              >
                Documents
              </Link>
            </Breadcrumbs>
            <Typography
              sx={{
                color: "#14151F",
                fontFamily: "Poppins-Semibold",
                fontSize: "20px",
                fontWeight: 600,
                lineHeight: "32px",
              }}
            >
              Account Details
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid container>
        <Grid
          sx={{
            maxWidth: "1076px",
            width: "100%",
            margin: "0px auto",
            padding: "18px 0px",
          }}
        >
          <Grid container sx={{ gap: "18.4px" }}>
            {documents?.map((item) => (
              <Grid sx={{ width: "164px" }}>
                <DocumentCard data={item} isEditable={true} />
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default index;
