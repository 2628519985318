import { call, put, takeEvery } from "redux-saga/effects";
//actions
import {
  EditCompanyProfileSuccess,
  EditCompanyProfileFailure,
} from "../../actions/Settings/EditCompanyInfo";
//constant
import { EDIT_COMPANY_INFO } from "../../constants/Settings/EditCompanyInfo";
//request type
import postRequest from "../../../util/APIHelperPost";
import { baseUrl } from "../../../util/APIBaseUrl";

export const fetchEditCompanyProfileRequest = async (actions) => {
  // eslint-disable-next-line no-return-await
  let body = {
    companyName: actions.payload.companyProfileData.companyName,
    contactEmail: actions.payload.companyProfileData.email,
    industry: actions?.payload?.companyProfileData?.industry,
    contactPerson: actions?.payload?.companyProfileData?.contactPerson,
    contactNumber: actions?.payload?.companyProfileData?.contactNumber,
    country: actions.payload?.companyProfileData?.country,
    // logo: actions.payload?.companyProfileData?.imageBlob,
    addressLine1: actions?.payload?.companyProfileData?.companyAddress,
    state: actions?.payload?.companyProfileData?.state,
    city: actions?.payload?.companyProfileData?.city,
    zip: actions?.payload?.companyProfileData?.zip,
  };

  if (actions?.payload?.companyProfileData?.loadFromUrl == false) {
    // body["logo"] = actions.payload?.companyProfileData?.imageBlob;
  }

  body = JSON.stringify(body);

  return await postRequest(
    `${baseUrl}/customer/${actions.payload.customerId}/editCompanyProfile`,
    body
  )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

function* fetchEditCompanyProfile(params) {
  try {
    let response = yield call(fetchEditCompanyProfileRequest, params);
    yield put(EditCompanyProfileSuccess(response));
  } catch (error) {
    console.error(error);
    yield put(EditCompanyProfileFailure(error));
  }
}

export default function* rootSaga() {
  yield takeEvery(EDIT_COMPANY_INFO, fetchEditCompanyProfile);
}
