import { Avatar, Grid, Typography } from "@mui/material";
import { Box, Stack } from "@mui/system";
import React from "react";
import Button from "../../../Button/Button";
import UserProfileWithInfo from "./UserProfileWithInfo";
import AddUser from "./../AddUser";
//redux dispatch
import { useSelector, useDispatch } from "react-redux";
import { ManageUser } from "../../../../store/actions/Settings/ManageUser";
import DisplayUserList from "./DisplayUserList";
import { ResetGetUserDetails } from "../../../../store/actions/Settings/GetUserDetails";
import RemoveUser from "./RemoveUser";
import AddIcon from "../../../../assets/svgs/AddIcon";
import { usePermissions } from "../../../Hook";

function index(props) {
  //constants
  const dispatch = useDispatch();
  // component state
  const [addorEditUser, setAddorEditUser] = React.useState({
    open: false,
    mode: null,
  });
  const [openRemoveUser, setOpenRemoveUser] = React.useState(false);

  const [editableUser, setEditAbleUser] = React.useState({});

  //custom hook
  const { loggedInUserType } = usePermissions();

  //redux state
  const manageUser = useSelector((state) => state.ManageUser.manageUser);

  //functions
  const fetchUserList = React.useCallback(
    (userAdditionalInfo, loggedInUser) => {
      let manageUserRequest = "";
      if (
        addorEditUser.open === false &&
        userAdditionalInfo &&
        Object.keys(userAdditionalInfo).length &&
        Object.keys(loggedInUser).length &&
        loggedInUser.userId != undefined
      ) {
        if (loggedInUser?.type == "customer") {
          manageUserRequest = `customer/${userAdditionalInfo?.customerId}/customerUsers`;
        } else if (loggedInUser?.type == "edge") {
          manageUserRequest = `users`;
        }

        dispatch(
          ManageUser({
            request: manageUserRequest,
            rowsPerPage: 5,
            page: 0,
            sortBy: {},
          })
        );
      }
    },
    [addorEditUser]
  );

  const handleRemoveUser = (user) => {
    setOpenRemoveUser(true);
    setEditAbleUser(user);
  };

  const handleCloseRemoveUser = (user) => {
    setOpenRemoveUser(false);
    setEditAbleUser({});
  };

  //methods
  React.useEffect(() => {
    let loggedInUser = {};
    let manageUserRequest = "";
    let userAdditionalInfo = JSON.parse(sessionStorage.getItem("userAdditionalInfo"));

    loggedInUser = JSON.parse(localStorage.getItem("loggedInUser"));
    fetchUserList(userAdditionalInfo, loggedInUser);
    dispatch(ResetGetUserDetails({}));
    // if (
    //     userAdditionalInfo &&
    //     Object.keys(userAdditionalInfo).length &&
    //     Object.keys(loggedInUser).length &&
    //     loggedInUser.userId != undefined
    // ) {
    //     if (loggedInUser?.type == "customer") {
    //         manageUserRequest = `customer/${userAdditionalInfo?.customerId}/customerUsers`;
    //     } else if (loggedInUser?.type == "edge") {
    //         manageUserRequest = `users`;
    //     }

    //     dispatch(
    //         ManageUser({
    //             request: manageUserRequest,
    //             rowsPerPage: 5,
    //             page: 0,
    //             sortBy: {},
    //         })
    //     );
    // }
  }, [addorEditUser]);

  return (
    <Grid
      // container
      // direction="row"
      sx={{
        background: "white",
        borderRadius: "24px",
        p: "16px",
      }}
    >
      {addorEditUser.open === false ? (
        <>
          <Grid item xs={12} mb={1}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <Typography
                alignSelf="center"
                sx={{
                  // marginTop: "20px",
                  fontFamily: "Poppins-Medium",
                  fontSize: "16px",
                  fontWeight: 500,
                  lineHeight: "24px",
                  color: "#292A3D",
                  letterSpacing: "0.15px",
                }}
              >
                Current Users
              </Typography>

              <Button
                type="button"
                variant="primary"
                size="md"
                onClick={() =>
                  setAddorEditUser({
                    open: true,
                    mode: "addUser",
                  })
                }
                leadIcon={<AddIcon />}
              >
                {loggedInUserType !== "edge" ? "Add New User" : "Add Edge Employee"}
              </Button>
            </Box>
          </Grid>
          <Grid sx={{ pt: "16px" }}>
            {manageUser && manageUser?.ok
              ? manageUser?.payload?.rows?.map((user, index) => {
                  return (
                    user?.mainUser !== true && (
                      <DisplayUserList
                        user={user}
                        setAddorEditUser={setAddorEditUser}
                        setEditAbleUser={setEditAbleUser}
                        handleRemoveUser={handleRemoveUser}
                      />
                    )
                  );
                })
              : null}
          </Grid>

          <RemoveUser
            openRemoveUser={openRemoveUser}
            setOpenRemoveUser={setOpenRemoveUser}
            editableUser={editableUser}
            handleCloseRemoveUser={handleCloseRemoveUser}
          />
        </>
      ) : (
        <AddUser
          navigatedFor={addorEditUser.mode}
          paramId={addorEditUser.mode === "editUser" ? editableUser.userId : null}
          addorEditUser={addorEditUser}
          setAddorEditUser={setAddorEditUser}
        />
      )}
    </Grid>
  );
}

export default index;
