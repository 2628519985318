//employee constants
import {
    DROPDOWN_DATA,
    DROPDOWN_DATA_SUCCESS,
    DROPDOWN_DATA_FAILURE,
} from "../constants/DropdownData";

const INIT_STATE = {
    dropdownData: {},
    loadingdropdownData: true,
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case DROPDOWN_DATA:
            return {
                ...state,
                loadingdropdownData: true,
            };
        case DROPDOWN_DATA_SUCCESS:
            if (action?.payload?.ok) {
                sessionStorage.setItem(
                    "dropdownData",
                    JSON.stringify(action.payload)
                );
                return {
                    ...state,
                    loadingdropdownData: false,
                    dropdownData: action.payload,
                };
            } else {
                sessionStorage.removeItem("dropdownData");
            }

        // eslint-disable-next-line no-fallthrough
        case DROPDOWN_DATA_FAILURE:
            sessionStorage.removeItem("dropdownData");
            return {
                ...state,
                loadingdropdownData: false,
                dropdownData: "Error in  getting Club Data",
            };
        default:
            return state;
    }
};
