//employee constants
import {
  GET_EMPLOYEE_BY_ID,
  GET_EMPLOYEE_BY_ID_SUCCESS,
  GET_EMPLOYEE_BY_ID_FAILURE,
} from "../../constants/EmployeePortal/GetEmployeeDetials";

const INIT_STATE = {
  employeeDetails: {},
  isLoadingEmployeeDetails: false,
  error: null,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_EMPLOYEE_BY_ID:
      return {
        ...state,
        isLoadingEmployeeDetails: true,
      };
    case GET_EMPLOYEE_BY_ID_SUCCESS:
      return {
        ...state,
        isLoadingEmployeeDetails: false,
        employeeDetails: action.payload.payload,
        error: null,
      };
    case GET_EMPLOYEE_BY_ID_FAILURE:
      return {
        ...state,
        isLoadingEmployeeDetails: false,
        error: "Error in getting Pay Details",
      };
    default:
      return state;
  }
};
