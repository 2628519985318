import {
  PAYONEER_GET_EMPLOYEE_BALANCE,
  PAYONEER_GET_EMPLOYEE_BALANCE_FAILED,
  PAYONEER_RESET_EMPLOYEE_BALANCE,
  PAYONEER_SET_EMPLOYEE_BALANCE,
} from "store/constants/Payoneer/PayoneerGetEmployeeBalance";

const initialState = {
  balanceInfo: {
    balances: [],
    error: null,
    isLoading: false,
  },
};

const balanceReducer = (state = initialState, action) => {
  switch (action.type) {
    case PAYONEER_GET_EMPLOYEE_BALANCE:
      return {
        ...state,
        balanceInfo: {
          balances: [],
          error: null,
          isLoading: true,
        },
      };
    case PAYONEER_SET_EMPLOYEE_BALANCE:
      return {
        ...state,
        balanceInfo: {
          balances: action.payload,
          error: null,
          isLoading: false,
        },
      };
    case PAYONEER_RESET_EMPLOYEE_BALANCE:
      return {
        ...state,
        ...initialState,
      };
    case PAYONEER_GET_EMPLOYEE_BALANCE_FAILED:
      return {
        ...state,
        balanceInfo: {
          balances: [],
          error: action.payload,
          isLoading: false,
        },
      };
    default:
      return state;
  }
};

export default balanceReducer;
