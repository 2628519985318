import React from "react";

const CreditCardIcon = ({ color = "black", style }) => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={style}
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M15.75 6V3.75H2.25V6H15.75ZM15.75 7.5H2.25V12.75H15.75V7.5ZM2.11364 2.25H15.8864C16.6395 2.25 17.25 2.92157 17.25 3.75V12.75C17.25 13.5784 16.6395 14.25 15.8864 14.25H2.11364C1.36052 14.25 0.75 13.5784 0.75 12.75V3.75C0.75 2.92157 1.36052 2.25 2.11364 2.25Z"
        fill={color}
      />
    </svg>
  );
};

export default CreditCardIcon;
