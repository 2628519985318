import React from "react";

import { Grid, Typography } from "@mui/material";

import Notifications from "../DashBoard/CustomerDashBoardV2/Notifications";
import GetInTouchForm from "./GetInTouchForm";
import useStyles from "./GetInTouchStyles";

const Header = () => (
  <Grid
    sx={{
      width: "752px",
      marginX: "auto",
      padding: "16px 0px",
      display: "flex",
      flexDirection: "column",
    }}
  >
    <Typography
      sx={{
        color: "#14151F",
        fontFamily: "Poppins-Semibold",
        fontSize: "20px",
        fontWeight: 600,
        lineHeight: "32px",
      }}
    >
      Expand your team with Edge!
    </Typography>
    <Typography
      sx={{
        fontFamily: "Poppins-Regular",
        fontSize: "12px",
        fontWeight: 400,
        color: "#212231",
        textDecoration: "none",
      }}
    >
      We will hire the best talent for your needs
    </Typography>
  </Grid>
);

const GetInTouch = () => {
  const classes = useStyles();

  return (
    <Grid continer display="flex" className={classes.container}>
      <Grid
        container
        rowSpacing={3}
        mt={0}
        sx={{
          background: "#FBFAFF",
          width: "calc(100% - 328px)",
          display: "flex",
          flexDirection: "column",
          gap: "0px",
        }}
      >
        <Grid container className={classes.header}>
          <Header />

          <Grid className={classes.getInTouchContainer}>
            <GetInTouchForm />
          </Grid>
        </Grid>
      </Grid>

      <Grid className={classes.notificationsContainer}>
        <Notifications />
      </Grid>
    </Grid>
  );
};

export default GetInTouch;
