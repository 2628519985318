//auth constants
import {
  RESET_USER_ADDITIONAL_INFO,
  USER_ADDITIONAL_INFO,
  USER_ADDITIONAL_INFO_SUCCESS,
  USER_ADDITIONAL_INFO_FAILURE,
} from "../constants/UserAdditionalInfo";

const INIT_STATE = {
  userAdditionalInfo: {},
  loadingAdditionalInfo: false,
};

export default (state = INIT_STATE, action) => {
  //
  switch (action.type) {
    case RESET_USER_ADDITIONAL_INFO:
      return INIT_STATE;

    case USER_ADDITIONAL_INFO:
      return {
        ...state,
        loadingAdditionalInfo: true,
      };
    case USER_ADDITIONAL_INFO_SUCCESS:
      if (action?.payload?.ok) {
        sessionStorage.setItem("userAdditionalInfo", JSON.stringify(action?.payload?.payload));
        return {
          ...state,
          loadingAdditionalInfo: false,
          userAdditionalInfo: action?.payload?.payload,
        };
      } else {
        sessionStorage.removeItem("userAdditionalInfo");
      }

    // eslint-disable-next-line no-fallthrough
    case USER_ADDITIONAL_INFO_FAILURE:
      // localStorage.removeItem("accessToken");
      sessionStorage.removeItem("userAdditionalInfo");
      return {
        ...state,
        loadingAdditionalInfo: false,
        userAdditionalInfo: "Error in  getting userAdditionalInfo",
      };
    default:
      return state;
  }
};
