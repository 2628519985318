import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";

import ProfileStrip from "components/common/ProfileStrip";
import PageLoader from "components/common/PageLoader";
import { getValue } from "components/Screens/CommonUtils";

import { employeeSalaryInfo } from "store/actions/Workforce/EmployeeDetils/SalaryInfo";

import "./EmployeeProfileStrip.scss";

export default function EmployeeProfileStrip({ employeeDetails }) {
  const dispatch = useDispatch();

  const salaryInfo = useSelector((state) => state.SalaryInfo);
  const loadingEmployeeSalaryInfo = useSelector((state) => state.SalaryInfo.loadingEmployeeSalaryInfo);

  useEffect(() => {
    if (employeeDetails?.status === "assigned" && employeeDetails?.employeeId) {
      dispatch(employeeSalaryInfo({ request: `employee/${employeeDetails?.employeeId}/salary` }));
    }
  }, [employeeDetails?.employeeId]);

  const getSalary = (salary) => {
    if (!salary) {
      return "N/A";
    }

    const finalSalary = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
      minimumFractionDigits: 0,
    }).format(salary);
    return finalSalary;
  };

  return (
    <>
      {loadingEmployeeSalaryInfo && <PageLoader />}
      <ProfileStrip userData={employeeDetails} leaveStatus image showIcon type="employee" />
      {employeeDetails?.status === "assigned" && (
        <div className="assignment-information-card mt-15">
          <div className="custom-section-container w-full">
            <p className="overline-md text-headings-secondary">ASSIGNED TO</p>
            <p className="heading-h4-semibold">{getValue(employeeDetails?.customerInfo?.companyName, "N/A")}</p>
            <p className="heading-h5-medium color-text-primary">
              {getValue(employeeDetails?.customerInfo?.designation, "N/A")}
            </p>

            <div className="section-child mt-1">
              <p className="para-body-xs-regular pb-2p color-text-highlight">Assigned On</p>
              <p className="para-body-s-regular">
                {moment(employeeDetails?.customerInfo?.joiningDate).format("MMM DD, YYYY")}
              </p>
            </div>
            <div className="section-child">
              <p className="para-body-xs-regular pb-2p color-text-highlight">Salary</p>
              <p className="para-body-s-regular">{getSalary(salaryInfo?.employeeSalaryInfo?.payload?.netSalary)}</p>
            </div>

            {/* this will be included later once manager logic will be implementeds */}
            {/* <div className="section-child">
              <p className="para-body-xs-regular pb-2p color-text-highlight">Manager</p>
              <p className="para-body-s-regular">N/A</p>
            </div> */}
          </div>
        </div>
      )}
    </>
  );
}
