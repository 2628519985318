import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Helmet } from "react-helmet";
import { Stack } from "@mui/material";

import TimeoffLayout from "components/Screens/Timeoff/Layout";
import LeaveTypeCard from "components/Screens/Timeoff/ManageTimeoff/LeaveTypeCard";
import ToolBar from "components/Widgets/PageToolBarV2";
import PendingLeaves from "components/Screens/Timeoff/ManageTimeoff/PendingLeaves";
import UpcomingLeavesAndHolidaysBar from "components/Screens/Timeoff/ManageTimeoff/UpcomingLeavesAndHolidaysBar";
import ScheduledLeaves from "components/Screens/Timeoff/ManageTimeoff/ScheduledLeaves";
import CancelledLeaves from "components/Screens/Timeoff/ManageTimeoff/CancelledLeaves";
import RejectedLeaves from "components/Screens/Timeoff/ManageTimeoff/RejectedLeaves";
import TakenLeaves from "components/Screens/Timeoff/ManageTimeoff/TakenLeaves";
import PageLoader from "components/common/PageLoader";
import LeavesValidityDates from "components/Screens/Timeoff/TimeoffAdmin/EmployeeTimeOffDetails/LeavesValidityDates";

import { FetchLeaveTypes } from "store/actions/Timeoff/LeaveTypes";
import { FetchLeaveRequests } from "store/actions/Timeoff/LeaveRequests";
import { FetchUpcomingLeaves } from "store/actions/Timeoff/UpcomingLeaves";

import { TIMEOFF_REQUESTS } from "constants";

import "./ManageTimeoff.css";

const ManageTimeoff = () => {
  const dispatch = useDispatch();

  const [currentTab, setCurrentTab] = useState(TIMEOFF_REQUESTS[0].title);
  const [tabs, setTabs] = useState(TIMEOFF_REQUESTS);
  const [totalLeavesAndHolidaysCount, setTotalLeavesAndHolidaysCount] = useState(0);
  const [loading, setLoading] = useState(false);

  const leaveTypes = useSelector((state) => state?.TimeoffLeaveTypes?.leaveTypes);
  const loadingLeaveTypes = useSelector((state) => state?.TimeoffLeaveTypes?.loadingLeaveTypes);

  const leaveRequests = useSelector((state) => state?.TimeoffLeaveRequests?.leaveRequests);
  const loadingLeavesRequest = useSelector((state) => state?.TimeoffLeaveTypes?.loadingLeavesRequest);

  const fetchLeavesCount = () => {
    const allTabsCount = TIMEOFF_REQUESTS.map((activity) => ({
      ...activity,
      count: leaveRequests?.[activity?.title?.toLowerCase()]?.length,
    }));
    setTabs(allTabsCount || TIMEOFF_REQUESTS);
  };

  const renderLeaveTypeCards = () => {
    const cards = [];
    for (let i = 0; i < leaveTypes?.length; i++) {
      if (leaveTypes[i].visible) cards.push(<LeaveTypeCard key={i} leave={leaveTypes[i]} />);
    }

    return <div className="leave-type-cards-container">{cards}</div>;
  };

  const renderToolBar = () => {
    return <ToolBar tabs={tabs} currentTab={currentTab} setCurrentTab={setCurrentTab} />;
  };

  const renderContent = () => {
    switch (currentTab) {
      case "Pending":
        return <PendingLeaves />;
      case "Scheduled":
        return <ScheduledLeaves />;
      case "Rejected":
        return <RejectedLeaves />;
      case "Cancelled":
        return <CancelledLeaves />;
      case "Taken":
        return <TakenLeaves />;
      default:
        return <PendingLeaves />;
    }
  };

  useEffect(() => {
    dispatch(FetchLeaveRequests({}));
    dispatch(FetchLeaveTypes({}));
    dispatch(FetchUpcomingLeaves({}));
    fetchLeavesCount();
  }, []);

  useEffect(() => {
    const updateLeavesCount = async () => {
      try {
        setLoading(true);
        await fetchLeavesCount();
        await dispatch(FetchLeaveTypes({}));
        await dispatch(FetchUpcomingLeaves({}));
      } catch (error) {
        console.error("Error updating leaves count:", error);
      } finally {
        setLoading(false);
      }
    };

    updateLeavesCount();
  }, [leaveRequests]);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      await dispatch(FetchLeaveRequests({}));
      setLoading(false);
    };

    fetchData();
  }, [dispatch]);

  return (
    <>
      <Helmet>
        <title>Edge | Timeoff</title>
      </Helmet>
      {(loadingLeaveTypes || loadingLeavesRequest || loading) && <PageLoader />}
      <div className="manage-timeoff-wrapper">
        <TimeoffLayout />
        <div className="manage-timeoff-container">
          <Stack direction="row" spacing="12px" alignItems="start" justifyContent="space-between">
            <div className="timeoff-subheading timeoff-subheading-margin">Leaves taken</div>

            {leaveTypes?.length && leaveTypes?.[0]?.startDate && leaveTypes?.[0]?.endDate && (
              <LeavesValidityDates startDate={leaveTypes?.[0]?.startDate} endDate={leaveTypes?.[0]?.endDate} />
            )}
          </Stack>

          {renderLeaveTypeCards()}

          <div className="timeoff-subheading upcoming-leaves-margin">
            Upcoming leaves & holidays ({totalLeavesAndHolidaysCount})
          </div>
          <UpcomingLeavesAndHolidaysBar setTotalLeavesAndHolidaysCount={setTotalLeavesAndHolidaysCount} />

          <div className="timeoff-subheading upcoming-leaves-margin">Your time-off requests</div>

          {renderToolBar()}

          {renderContent()}
        </div>
      </div>
    </>
  );
};

export default ManageTimeoff;
