//employee constants
import {
    RESET_SEARCH_EMPLOYEE_AND_CUSTOMER,
    SEARCH_EMPLOYEE_AND_CUSTOMER,
    SEARCH_EMPLOYEE_AND_CUSTOMER_SUCCESS,
    SEARCH_EMPLOYEE_AND_CUSTOMER_FAILURE,
} from "../../constants/SearchEmployee&Customer";

const INIT_STATE = {
    searchEmployeeAndCustomer: {},
    loadingEmployeeAndCustomer: true,
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case RESET_SEARCH_EMPLOYEE_AND_CUSTOMER:
            return INIT_STATE;

        case SEARCH_EMPLOYEE_AND_CUSTOMER:
            return {
                ...state,
                loadingEmployeeAndCustomer: true,
            };
        case SEARCH_EMPLOYEE_AND_CUSTOMER_SUCCESS:
            return {
                ...state,
                loadingEmployeeAndCustomer: false,
                searchEmployeeAndCustomer: action.payload,
            };
        // eslint-disable-next-line no-fallthrough
        case SEARCH_EMPLOYEE_AND_CUSTOMER_FAILURE:
            return {
                ...state,
                loadingEmployeeAndCustomer: false,
                searchEmployeeAndCustomer: "Error in  getting Club Data",
            };
        default:
            return state;
    }
};
