import React, { useEffect, useState } from "react";

import { useSelector, useDispatch } from "react-redux";

import Dialog from "@mui/material/Dialog";

import { Autocomplete, Divider, FormControl, Grid, Paper, Typography } from "@mui/material";
import { Box } from "@mui/system";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";

import Button from "components/Button/Button";
import SectionLoader from "components/common/SectionLoader";
import TextFieldV2 from "components/common/TextFieldV2/TextFieldV2";
import { isValidResponse } from "components/Screens/CommonUtils";
import { usePermissions } from "components/Hook";

import { AddAchInfo, ResetAddAchInfo } from "store/actions/Payment/AddAchInfo";
import { CustomerBankInfo } from "store/actions/Payment/GetCustomerBankInfo";
import { showSnackbar } from "store/actions/Utility";

import { baseUrl } from "util/APIBaseUrl";

import CrossIcon from "assets/svgs/CrossIcon";

import "./AddAchInfo.scss";
import AddAchSuccessScreen from "./AddAchSuccessScreen";

const accHolderType = [
  { type: "individual", Name: "Individual" },
  { type: "company", Name: "Company" },
];

const accType = [
  { type: "savings", Name: "Savings" },
  { type: "checking", Name: "Checking" },
];

const initialInfo = {
  accHolderType: { type: null, Name: null },
  accType: { type: null, Name: null },
  accHolderName: "",
  routingNumber: "",
  accNumber: "",
  confirmAccNumber: "",
};

function CustomPopperComponent(propsForPopper) {
  const { children } = propsForPopper;
  return (
    <Paper
      {...propsForPopper}
      square
      style={{
        marginTop: 4,
        zIndex: 9999,
        border: "0.5px solid #B2B4E2",
        borderRadius: "12px",
        boxShadow: "0px 1px 1px rgba(2, 0, 110, 0.22), 0px 1px 6px rgba(2, 0, 110, 0.25)",
      }}
    >
      {children}
    </Paper>
  );
}

export default function AddEchInfo(props) {
  const { open, handleClose, editAch, setOpenEch, newCustomer } = props;

  const dispatch = useDispatch();

  const [openAlert, setOpenAlert] = useState(false);
  const [alertText, setAlertText] = useState("Enter Required Fields");
  const [achInfo, setAchInfo] = useState(initialInfo);
  const [achInfoButton, setAchInfoButton] = useState(false);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [errorStates, setErrorStates] = useState({
    accNumberError: false,
    routingNumberError: false,
  });

  const { loggedInUser, loggedInUserType } = usePermissions();

  const addAchBankInfo = useSelector((state) => state.AddAchInfo.addAchBankInfo);
  const loadingAddAchBankInfo = useSelector((state) => state.AddAchInfo.loadingAddAchBankInfo);
  const isAddAchInfoSuccess = useSelector((state) => state.AddAchInfo?.addAchBankInfo?.ok);

  const handleAccHolderType = (fieldName, option) => {
    setAchInfo({ ...achInfo, [fieldName]: option });
  };

  const handleAchInfo = (event) => {
    setAchInfo({ ...achInfo, [event.target.name]: event.target.value });
    if (event.target.name === "routingNumber") {
      setErrorStates((prevState) => ({ ...prevState, routingNumberError: false }));
    }
    if (event.target.name === "accNumber") {
      setErrorStates((prevState) => ({ ...prevState, accNumberError: false }));
    }
  };

  const handleAddAchInfo = () => {
    const request = {
      customer: `${baseUrl}/customer/${loggedInUser?.customerId}/account/manual`,
    };
    setAchInfoButton(true);
    setErrorStates((prevState) => ({
      ...prevState,
      accNumberError: false,
      routingNumberError: false,
    }));

    if (
      achInfo?.accHolderType?.type != null &&
      achInfo?.accType?.type != null &&
      achInfo?.accNumber != "" &&
      achInfo?.routingNumber != "" &&
      achInfo?.accHolderName != ""
    ) {
      setOpenAlert(false);
      dispatch(
        AddAchInfo({
          request: request[loggedInUserType],
          body: {
            accountHolderType: achInfo?.accHolderType?.type,
            accountType: achInfo?.accType?.type,
            accountHolderName: achInfo?.accHolderName,
            accountNumber: achInfo?.accNumber,
            routingNumber: achInfo?.routingNumber,
          },
          hidePlaidBanner: props?.hidePlaidBanner,
        })
      );
    } else {
      setOpenAlert(true);
    }
  };

  const checkError = (field, view) => {
    if (achInfoButton) {
      if (view != "textField") {
        if (achInfo[field]?.type != null) {
          return false;
        }
        return true;
      }
      if (achInfo[field] != "") {
        return false;
      }
      return true;
    }
    return "";
  };

  useEffect(() => {
    if (isValidResponse(addAchBankInfo)) {
      dispatch(
        CustomerBankInfo({
          request: `${baseUrl}/customer/${loggedInUser?.customerId}/bankInfo`,
        })
      );
      setAchInfo(initialInfo);
      setAchInfoButton(false);
    } else if (addAchBankInfo?.ok == false) {
      if (addAchBankInfo?.payload?.error[0]?.description == "Invalid Routing Number") {
        setErrorStates((prevState) => ({ ...prevState, routingNumberError: true }));
      } else if (addAchBankInfo?.payload?.error[0]?.description == "Invalid Account Number") {
        setErrorStates((prevState) => ({ ...prevState, accNumberError: true }));
      } else {
        setAlertText(addAchBankInfo?.payload?.error[0]?.description);
        setOpenAlert(true);
      }
    }
  }, [addAchBankInfo]);

  useEffect(() => {
    if (isAddAchInfoSuccess) {
      setShowSuccessMessage(true);
      setOpenEch(false);
      setOpenAlert(false);
      dispatch(ResetAddAchInfo({}));
    }
  }, [isAddAchInfoSuccess]);

  useEffect(() => {
    if (openAlert) {
      dispatch(
        showSnackbar({
          type: "error",
          message: alertText,
          dispatch,
        })
      );
    }
  }, [openAlert, alertText]);

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{
          "& .MuiDialog-paper": {
            pt: loadingAddAchBankInfo ? "0px" : "35px",
            px: "24px",
            width: "375px",
            boxShadow: "0px 1px 14px rgba(2, 0, 110, 0.12)",
            borderRadius: "12px !important",
          },
        }}
        className="ach-modal"
      >
        {(() => {
          if (showSuccessMessage) {
            return <AddAchSuccessScreen setOpenEch={setOpenEch} newCustomer={newCustomer} />;
          }
          if (loadingAddAchBankInfo) {
            return (
              <div className="ach-loader">
                <SectionLoader size={120} />
                <h2 className="ach-loading-heading">
                  Saving your ACH details.
                  <br /> Just a moment...
                </h2>
              </div>
            );
          }
          return (
            <Grid container direction="column" spacing={2} className="ach-form">
              <div className="flex justify-between ">
                <Typography className="ach-form-title">{editAch ? "Edit ACH Details" : "Add ACH Details"}</Typography>
                <CrossIcon
                  className="cursor-pointer"
                  onClick={() => {
                    setOpenEch(false);
                    setOpenAlert(false);
                    setAchInfo(initialInfo);
                    setAchInfoButton(false);
                    dispatch(ResetAddAchInfo({}));
                  }}
                />
              </div>

              <Grid item>
                <FormControl variant="outlined" fullWidth error>
                  <Autocomplete
                    id="free-solo-demo"
                    freeSolo
                    value={achInfo?.accHolderType}
                    sx={{
                      marginTop: "14px",
                      "& label + .MuiInput-formControl": {
                        marginTop: "1vw",
                      },
                    }}
                    onChange={(event, option) => {
                      handleAccHolderType("accHolderType", option);
                    }}
                    PaperComponent={CustomPopperComponent}
                    options={accHolderType.map((option) => option)}
                    getOptionLabel={(option) => option.Name || ""}
                    renderOption={(renderProps, option) => {
                      return (
                        <li {...renderProps}>
                          <Box className="list-item">
                            <Typography className="dropdown-value">{option.Name}</Typography>
                          </Box>
                        </li>
                      );
                    }}
                    renderInput={(params) => (
                      <Box
                        sx={{
                          marginBottom: checkError("accHolderType", "dropdown") ? "20px" : "0px",
                        }}
                        className={`ach-details-field ${checkError("accHolderType", "dropdown") ? "ach-details-field-error" : ""}`}
                      >
                        <TextFieldV2
                          error={checkError("accHolderType", "dropdown")}
                          {...params}
                          label="Account holder type"
                          variant="filled"
                          type="text"
                          name="accHolderType"
                          className="w-full"
                          helperText={checkError("accHolderType", "dropdown") && <>Account holder type is required.</>}
                        />
                        {checkError("accHolderType", "dropdown") && <ErrorOutlineIcon className="error-outline-icon" />}
                      </Box>
                    )}
                  />
                </FormControl>
              </Grid>

              <Grid item>
                <FormControl variant="outlined" fullWidth error>
                  <Box
                    sx={{
                      marginBottom: checkError("accHolderName", "textField") ? "20px" : "0px",
                    }}
                    className={`ach-details-field ${checkError("accHolderName", "textField") ? "ach-details-field-error" : ""}`}
                  >
                    <TextFieldV2
                      error={checkError("accHolderName", "textField")}
                      value={achInfo?.accHolderName}
                      onChange={handleAchInfo}
                      label="Account holder name"
                      variant="filled"
                      type="text"
                      name="accHolderName"
                      className="w-full"
                      helperText={checkError("accHolderName", "textField") && <>Account holder name is required.</>}
                    />
                    {checkError("accHolderName", "textField") && <ErrorOutlineIcon className="error-outline-icon" />}
                  </Box>
                </FormControl>
              </Grid>

              <Divider light className="section-divider" />

              <Grid item>
                <FormControl variant="outlined" fullWidth error>
                  <Autocomplete
                    id="free-solo-demo"
                    freeSolo
                    value={achInfo?.accType}
                    sx={{
                      "& label + .MuiInput-formControl": {
                        marginTop: "1vw",
                      },
                    }}
                    onChange={(event, option) => {
                      handleAccHolderType("accType", option);
                    }}
                    PaperComponent={CustomPopperComponent}
                    options={accType.map((option) => option)}
                    getOptionLabel={(option) => option.Name || ""}
                    renderOption={(renderProps, option) => {
                      return (
                        <li {...renderProps}>
                          <Box className="list-item">
                            <Typography className="dropdown-value">{option.Name}</Typography>
                          </Box>
                        </li>
                      );
                    }}
                    renderInput={(params) => (
                      <Box
                        sx={{
                          marginBottom: checkError("accType", "dropdown") ? "20px" : "0px",
                        }}
                        className={`ach-details-field ${checkError("accType", "dropdown") ? "ach-details-field-error" : ""}`}
                      >
                        <TextFieldV2
                          {...params}
                          error={checkError("accType", "dropdown")}
                          label="Account type"
                          variant="filled"
                          type="text"
                          name="accType"
                          helperText={checkError("accType", "dropdown") && <>Account type is required.</>}
                          className="w-full"
                        />
                        {checkError("accType", "dropdown") && <ErrorOutlineIcon className="error-outline-icon" />}
                      </Box>
                    )}
                  />
                </FormControl>
              </Grid>

              <Grid item>
                <FormControl
                  variant="outlined"
                  fullWidth
                  error={checkError("routingNumber", "textField") || errorStates?.routingNumberError}
                >
                  <Box
                    sx={{
                      marginBottom:
                        checkError("routingNumber", "textField") || errorStates?.routingNumberError ? "20px" : "0px",
                    }}
                    className={`ach-details-field ${checkError("routingNumber", "textField") || errorStates?.routingNumberError ? "ach-details-field-error" : ""}`}
                  >
                    <Box className="routing-account-number">
                      <TextFieldV2
                        error={checkError("routingNumber", "textField") || errorStates?.routingNumberError}
                        value={achInfo?.routingNumber}
                        onChange={handleAchInfo}
                        label="ACH routing number"
                        variant="filled"
                        name="routingNumber"
                        helperText={
                          (checkError("routingNumber", "textField") || errorStates?.routingNumberError) && (
                            <>Please enter a valid 9-digit routing number.</>
                          )
                        }
                        fullWidth
                      />
                      {(checkError("routingNumber", "textField") || errorStates?.routingNumberError) && (
                        <ErrorOutlineIcon className="error-outline-icon" />
                      )}
                    </Box>
                  </Box>
                </FormControl>
              </Grid>

              <Grid item>
                <FormControl
                  variant="outlined"
                  fullWidth
                  error={checkError("accNumber", "textField") || errorStates?.accNumberError}
                >
                  <Box
                    sx={{
                      marginBottom:
                        checkError("accNumber", "textField") || errorStates?.accNumberError ? "20px" : "0px",
                    }}
                    className={`ach-details-field ${checkError("accNumber", "textField") || errorStates?.accNumberError ? "ach-details-field-error" : ""}`}
                  >
                    <Box className="routing-account-number">
                      <TextFieldV2
                        error={checkError("accNumber", "textField") || errorStates?.accNumberError}
                        value={achInfo?.accNumber}
                        onChange={handleAchInfo}
                        label="Account number"
                        variant="filled"
                        name="accNumber"
                        helperText={
                          (checkError("accNumber", "textField") || errorStates?.accNumberError) && (
                            <>Please enter a valid account number.</>
                          )
                        }
                        fullWidth
                      />
                      {(checkError("accNumber", "textField") || errorStates?.accNumberError) && (
                        <ErrorOutlineIcon className="error-outline-icon" />
                      )}
                    </Box>
                  </Box>
                </FormControl>
              </Grid>

              <Button
                type="button"
                variant="primary"
                size="lg"
                id="add-accounts-btn"
                className="save-details-btn mt-125 mb-1"
                onClick={() => handleAddAchInfo()}
              >
                <Typography variant="h6" color="white">
                  Save Details
                </Typography>
              </Button>
            </Grid>
          );
        })()}
      </Dialog>
    </div>
  );
}
