import { baseUrl } from "util/APIBaseUrl";
import { makeRequest } from "util/APIHelper";

export const updateEmployeePersonalInfo = async (employeeId, body) => {
  const {
    payload: { error },
  } = await makeRequest(
    {
      path: `${baseUrl}/v2/employee/${employeeId}`,
      contentType: "application/json",
      method: "PATCH",
      body: JSON.stringify(body),
    },
    false
  );
  if (error) {
    throw new Error("Profile could not be updated");
  }
};

export default updateEmployeePersonalInfo;
