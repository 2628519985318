//employee constants
import {
  RESET_GET_EMPLOYEE_SALARIES,
  GET_EMPLOYEE_SALARIES,
  GET_EMPLOYEE_SALARIES_SUCCESS,
  GET_EMPLOYEE_SALARIES_FAILURE,
} from "../../constants/EmployeeSalaries";

const INIT_STATE = {
  employeeSalaries: {},
  loadingEmployeeSalaries: false,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case RESET_GET_EMPLOYEE_SALARIES:
      return INIT_STATE;
    case GET_EMPLOYEE_SALARIES:
      return {
        ...state,
        loadingEmployeeSalaries: true,
      };
    case GET_EMPLOYEE_SALARIES_SUCCESS:
      if (action.payload.ok) {
        return {
          ...state,
          loadingEmployeeSalaries: false,
          employeeSalaries: action?.payload?.payload,
        };
      }
    // eslint-disable-next-line no-fallthrough
    case GET_EMPLOYEE_SALARIES_FAILURE:
      return {
        ...state,
        loadingEmployeeSalaries: false,
        employeeSalaries: "Error in  getting Club Data",
      };
    default:
      return state;
  }
};
