import React, { useEffect, useState } from "react";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { addDays, differenceInDays } from "date-fns";

import { Chip, Divider, Stack } from "@mui/material";
import TimeoffLayout from "components/Screens/Timeoff/Layout";
import Daterange from "components/common/Daterange";
import Button from "components/Button/Button";
import CustomDropdown from "components/common/Dropdown";
import FileUploader from "components/common/FileUploader";
import PageLoader from "components/common/PageLoader";
import { convertDate } from "components/Screens/CommonUtils";

import LeftArrowSmall from "assets/images/left-arrow.svg";
import DateRangeIcon from "assets/images/date-range-picker.svg";
import CheckMarkIcon from "assets/images/check-marks.svg";
// import CrossIcon from "assets/svgs/CrossIcon";
import RejectedIcon from "assets/images/rejected-icon.svg";

import { FetchLeaveTypes } from "store/actions/Timeoff/LeaveTypes";
import { createLeaveRequest, getLeaveCount, uploadMedicalDocs } from "services/useTimeoffService";
import { showSnackbar } from "store/actions/Utility";
import { GetAllHolidays } from "store/actions/Holidays";
import { FetchUpcomingLeaves } from "store/actions/Timeoff/UpcomingLeaves";

import { baseUrl } from "util/APIBaseUrl";
import { getDatesBetweenDateRange } from "util/combineUpcomingLeavesAndHolidays";

import { leaveTypeIds } from "./Enum";

import "./RequestTimeoff.css";

function RequestTimeoff() {
  const location = useLocation();
  const selectedLeaveTypeIdFromCard = location.state?.selectedLeaveTypeId;

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const leaveTypes = useSelector((state) => state?.TimeoffLeaveTypes?.leaveTypes || []);
  const upcomingLeaves = useSelector((state) => state?.TimeoffUpcomingLeaves?.upcomingLeaves);
  const userAdditionalInfo = useSelector((state) => state.UserAdditionalInfo.userAdditionalInfo);

  const [validDates, setValidDates] = useState();
  const [timeOffTypes, setTimeOffTypes] = useState([]);
  const [filesToUpload, setFilesToUpload] = useState([]);
  const [selectedLeaveTypeRules, setSelectedLeaveTypeRules] = useState(null);
  const [showUploadDocumentSection, setShowUploadDocumentSection] = useState(false);
  const [submittingTimeoffRequest, setSubmittingTimeoffRequest] = useState({ state: false, showSnackbar: false });
  const [timeoffRequestLoader, setTimeoffRequestLoader] = useState(true);
  const [selectedTimeOff, setSelectedTimeOff] = useState({});
  const [leavesCalendar, setLeavesCalendar] = useState([]);
  const [leaveRequest, setLeaveRequest] = useState({
    selectedDateRange: { startDate: new Date(), endDate: new Date() },
    selectedLeaveType: null,
    description: null,
  });
  const [canUserUploadDocuments, setCanUserUploadDocuments] = useState(
    leaveRequest?.selectedLeaveType === 2 && leaveRequest?.attachDocument
  );

  const timeoffTypeLabel = timeOffTypes.find((leave) => leave?.key === leaveRequest?.selectedLeaveType)?.label;

  // eslint-disable-next-line consistent-return
  const handleCreateLeaveRequest = async () => {
    if (canUserUploadDocuments && filesToUpload.length === 0) {
      return setSubmittingTimeoffRequest({
        state: false,
        showSnackbar: true,
        type: "failure",
        snackbarMessage: "Upload doctor's note to request sick leave",
      });
    }
    const payload = { ...leaveRequest };
    setSubmittingTimeoffRequest({
      state: true,
      showSnackbar: false,
    });

    const res = await createLeaveRequest(payload);

    const { payload: resp } = res;

    if (!res?.ok || !resp?.success) {
      const errorMessage = !res?.ok ? `${resp?.error[0]?.msg} ${resp?.error[0]?.param}` : resp?.message;
      return setSubmittingTimeoffRequest({
        state: false,
        showSnackbar: true,
        type: "failure",
        snackbarMessage: errorMessage || "An error occurred while requesting for leave.",
      });
    }

    await uploadMedicalDocs(resp?.requestId, filesToUpload);

    setSubmittingTimeoffRequest({
      state: false,
      showSnackbar: true,
      type: "success",
      snackbarMessage:
        "Your request has been sent to your employer. You will be notified once he/she responds to your request",
    });

    setTimeout(() => {
      navigate("/main/timeoff");
    }, 0);
  };

  const calculateTotalDays = () => {
    const pending = selectedLeaveTypeRules?.pending || 0;
    const scheduled = selectedLeaveTypeRules?.scheduled || 0;

    return pending + scheduled;
  };

  const calculateTotalTakenDays = () => {
    const pending = selectedLeaveTypeRules?.pending || 0;
    const scheduled = selectedLeaveTypeRules?.scheduled || 0;
    const taken = selectedLeaveTypeRules?.taken || 0;

    return pending + scheduled + taken;
  };

  const getAllowedTimeOff = () => {
    if (!selectedLeaveTypeRules) return " ";
    if (!selectedLeaveTypeRules?.isFinite) return "Unlimited";
    if (
      !leaveRequest?.selectedLeaveType ||
      selectedLeaveTypeRules?.taken === undefined ||
      selectedLeaveTypeRules?.noOfLeaves === undefined
    )
      return "";
    switch (leaveRequest?.selectedLeaveType) {
      case leaveTypeIds.UNPAID:
        if (selectedLeaveTypeRules?.noOfLeaves > -1) {
          return `${selectedLeaveTypeRules?.noOfLeaves - calculateTotalTakenDays()}`;
        }
        return calculateTotalTakenDays();

      default:
        if (selectedLeaveTypeRules?.noOfLeaves > -1) {
          return `${selectedLeaveTypeRules?.noOfLeaves - calculateTotalTakenDays()}`;
        }
        return calculateTotalTakenDays();
    }
  };

  const calculateNoOfLeaves = async () => {
    if (leaveRequest?.selectedLeaveType) {
      const tempSelectedTimeOff = await getLeaveCount(
        leaveRequest?.selectedDateRange?.startDate,
        leaveRequest?.selectedDateRange?.endDate,
        leaveRequest?.selectedLeaveType
      );

      setSelectedTimeOff(tempSelectedTimeOff?.payload);
    }
  };

  // eslint-disable-next-line consistent-return
  const getChipValuesForRegulations = (timeoffType, noOfDays) => {
    if (timeoffType === "Notice Period") {
      const daysDifference = differenceInDays(new Date(leaveRequest?.selectedDateRange?.startDate), new Date()) + 1;

      if (daysDifference > noOfDays) {
        return {
          noOfDays: daysDifference,
          icon: <img alt="check" src={CheckMarkIcon} />,
          bgColor: "#b9efd8",
          textColor: "#007b23",
        };
      }
      return {
        noOfDays: daysDifference,
        icon: <img alt="reject" className="rejected-icon" src={RejectedIcon} />,
        bgColor: "#ffe4e7",
        textColor: "#97000c",
        borderColor: "1px solid #FFC6CA",
        iconColor: "#97000c",
      };
    }

    if (timeoffType === "Frequency") {
      return { noOfDays };
    }
    return null;
  };

  const timeofRules = () => {
    return (
      <div className="request-timeoff-rules">
        <div className="request-timeoff-rules-heading">Time off rules and regulations</div>
        <Divider light />

        {selectedLeaveTypeRules?.leavePolicies?.map(({ description, title, noOfDays, type }) => (
          <>
            <div className="request-timeoff-rules-subheading">{title}</div>

            {noOfDays && (
              <div>
                <Chip
                  className="ml-1 notice-period-chip custom-chip"
                  style={{
                    background: getChipValuesForRegulations(type, noOfDays)?.bgColor,
                    color: getChipValuesForRegulations(type, noOfDays)?.textColor,
                    marginBlock: "5px",
                    border: getChipValuesForRegulations(type, noOfDays)?.borderColor,
                  }}
                  label={
                    <div className="notice-period-chip-label flex" style={{ gap: "8px" }}>
                      {getChipValuesForRegulations(type, noOfDays)?.icon}
                      <div>
                        {getChipValuesForRegulations(type, noOfDays)?.noOfDays}{" "}
                        {getChipValuesForRegulations(type, noOfDays)?.noOfDays === 1 ? "Day" : "Days"}
                      </div>
                    </div>
                  }
                />
              </div>
            )}
            <div
              className="request-timeoff-rules-para title-sm time-off-rules-list"
              dangerouslySetInnerHTML={{ __html: description }}
            />

            <Divider light />
          </>
        ))}
      </div>
    );
  };

  const updateTotalLeaves = (startDate, endDate) => {
    const currentDate = new Date(startDate);

    while (currentDate <= new Date(endDate)) {
      currentDate.setDate(currentDate.getDate() + 1);
    }
  };

  const getLeavesValidationMessage = () => {
    if (selectedTimeOff?.leaves == 0 && selectedTimeOff?.holidays?.length > 0)
      return "You already have this day off as a holiday";
    if (selectedLeaveTypeRules?.taken >= selectedLeaveTypeRules?.noOfLeaves)
      return "You have already taken the maximum allowed leaves";
    if (selectedLeaveTypeRules?.taken < selectedLeaveTypeRules?.noOfLeaves)
      return "Selected number of days is more than the number of days allowed";
    return "You are unable to apply for leave on the selected dates.";
  };

  const renderDateRangeWrapper = () => (
    <div className="date-range-wrapper">
      <div className="flex ml-1 mt-1 mb-075">
        <img src={DateRangeIcon} alt="date range picker icon" />
        <p className="neutral-20 ml-05">{canUserUploadDocuments ? "Step 1/2 : " : ""} Select date range</p>
      </div>

      <Divider light />

      <Daterange
        leavesCalender={leavesCalendar}
        onChange={(selectedDate) => {
          setLeaveRequest({
            ...leaveRequest,
            selectedDateRange: selectedDate,
          });
        }}
        validDates={validDates}
      />
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <div className="request-timeoff-indicators-container ml-1">
          <div className="request-timeoff-indicator-box-label">
            <span className="request-timeoff-today-box-indicator" />
            <span className="title-sm">Today</span>
          </div>

          <div className="request-timeoff-indicator-box-label">
            <span className="request-timeoff-selected-box-indicator" />
            <span className="title-sm">Selected</span>
          </div>
        </div>

        <div className="flex gap-05 mr-1" style={{ marginBottom: "0px !important" }}>
          <span className="align-self-center calendar-dot-indicator-orange" />
          <p className="body-md align-self-center">Leaves</p>
          <span className="align-self-center calendar-dot-indicator-green" />
          <p className="body-md align-self-center">Holidays</p>
        </div>
      </Stack>

      <Divider light />

      <div className="timeoff-selected ml-1">
        <div className="label-lg neutral-60 pop-regular">Time-off selected</div>
        <div className="title-md primary-30 mt-05 pop-regular ">
          {selectedTimeOff?.leaves < 0 ? (
            "Selected date range not allowed"
          ) : (
            <>
              {`${selectedTimeOff?.leaves} ${timeoffTypeLabel} leave${selectedTimeOff?.leaves > 1 ? "s " : " "}`} (
              {leaveTypes[0]?.countryCode === "Peru" ? "Including weekends" : "Excluding weekends & holidays"})
            </>
          )}
        </div>

        <div className="pending-leave-duration mt-025">
          <div className="pending-leave-date">
            {moment(leaveRequest?.selectedDateRange?.startDate).format("MMM DD, YYYY")}
          </div>
          {convertDate(leaveRequest?.selectedDateRange?.startDate) !==
            convertDate(leaveRequest?.selectedDateRange?.endDate) && (
            <>
              <img className="pending-leave-arrow" src={LeftArrowSmall} alt="left arrow" />{" "}
              <div className="pending-leave-date">
                {moment(leaveRequest?.selectedDateRange?.endDate).format("MMM DD, YYYY")}
              </div>
            </>
          )}
        </div>

        <div className="pt-025">
          {selectedTimeOff?.leaves < 1 && <p className="label-md error-50">{getLeavesValidationMessage()}</p>}
        </div>
      </div>

      <div className="label-lg pop-regular mt-025 ml-1 mb-075">
        {Object.keys(selectedTimeOff?.holidays || {})?.length > 0
          ? `Holidays: ${selectedTimeOff?.holidays?.map((holiday) => holiday.Name).join(", ")}`
          : ""}
      </div>

      <Divider light />

      <div className="label-lg ml-1 mt-05">Enter note {leaveTypes[0]?.countryCode !== "Peru" ? "(Optional)" : ""}</div>

      <textarea
        rows={3}
        value={leaveRequest?.description || ""}
        className="request-timeoff-textarea"
        onChange={(e) => {
          setLeaveRequest({
            ...leaveRequest,
            description: e.target.value,
          });
        }}
      />

      <div className="request-timeoff-action-btns ml-1">
        <Button variant="outlined" size="lg" className="" onClick={() => navigate("/main/timeoff")}>
          Cancel
        </Button>
        <Button
          variant="primary"
          size="lg"
          disabled={selectedTimeOff?.leaves <= 0}
          onClick={() => {
            if (canUserUploadDocuments) {
              setShowUploadDocumentSection(true);
            } else {
              handleCreateLeaveRequest();
            }
          }}
        >
          {canUserUploadDocuments ? "Next" : "Confirm"}
        </Button>
      </div>
    </div>
  );

  const renderCancelMessage = () => (
    <div className="annual-accordian">
      Unfortunately, you have used up all your
      {timeoffTypeLabel} leaves. However, there are some leaves that are scheduled that you can cancel to request more
      {timeoffTypeLabel} leaves.
      <b onClick={() => navigate("/main/timeoff")} className="cursor-pointer">
        <u>Cancel scheduled leaves.</u>
      </b>
    </div>
  );

  const renderEmergencyMessage = () => (
    <div className="annual-accordian">
      Unfortunately, you have used up all your
      {timeoffTypeLabel} leaves. However, you can schedule unpaid time-off in case of an emergency.
    </div>
  );

  const getContent = () => {
    const allowedTimeOff = getAllowedTimeOff();

    if (allowedTimeOff > 0 || allowedTimeOff === "Unlimited") {
      return renderDateRangeWrapper();
    }
    if (calculateTotalDays() > 0) {
      return renderCancelMessage();
    }
    return renderEmergencyMessage();
  };

  useEffect(() => {
    if (submittingTimeoffRequest?.showSnackbar && submittingTimeoffRequest?.type)
      dispatch(
        showSnackbar({
          type: submittingTimeoffRequest?.type,
          message: submittingTimeoffRequest?.snackbarMessage,
          dispatch,
        })
      );
  }, [submittingTimeoffRequest]);
  useEffect(() => {
    calculateNoOfLeaves();
  }, [leaveRequest?.selectedDateRange, leaveRequest?.selectedLeaveType]);

  useEffect(() => {
    setCanUserUploadDocuments(leaveRequest?.selectedLeaveType === 2 && leaveRequest?.attachDocument);
  }, [leaveRequest]);

  useEffect(() => {
    setTimeoffRequestLoader(true);
    setSubmittingTimeoffRequest((prevState) => ({
      ...prevState,
      state: true,
      showSnackbar: false,
    }));

    setTimeOffTypes(
      leaveTypes?.reduce((acc, leave) => {
        if (leave.visible) {
          acc.push({
            label: leave?.leaveType,
            key: leave?.leaveTypeId,
            attachDocument: leave?.attachDocument,
            startDate: leave?.startDate,
            endDate: leave?.endDate,
          });
        }
        return acc;
      }, [])
    );

    const selectedType = leaveTypes.find(
      (type) => type.leaveTypeId === Number(new URLSearchParams(window.location.search).get("leaveTypeId"))
    );

    setLeaveRequest({
      ...leaveRequest,
      selectedLeaveType:
        Number(new URLSearchParams(window.location.search).get("leaveTypeId")) ?? leaveTypes[0]?.leaveTypeId,
      isVisible: leaveTypes[0]?.visible,
      attachDocument: selectedType?.attachDocument,
    });

    setSubmittingTimeoffRequest((prevState) => ({
      ...prevState,
      state: false,
      showSnackbar: false,
    }));
    setTimeoffRequestLoader(false);
  }, [leaveTypes]);

  useEffect(() => {
    const selectedTimeOffRules = leaveTypes.find((type) => type?.leaveTypeId === leaveRequest?.selectedLeaveType);
    setSelectedLeaveTypeRules(selectedTimeOffRules || null);
  }, [leaveTypes, leaveRequest?.selectedLeaveType]);

  useEffect(() => {
    dispatch(FetchUpcomingLeaves({}));
    if (leaveTypes.length === 0) dispatch(FetchLeaveTypes({}));
  }, []);

  useEffect(() => {
    if (leaveRequest?.selectedDateRange) {
      updateTotalLeaves(leaveRequest?.selectedDateRange?.startDate, leaveRequest?.selectedDateRange?.endDate);
    }
  }, [leaveRequest?.selectedDateRange]);

  useEffect(() => {
    if (selectedLeaveTypeIdFromCard) {
      setLeaveRequest((prev) => ({
        ...prev,
        selectedLeaveType: selectedLeaveTypeIdFromCard,
      }));
    }
  }, [selectedLeaveTypeIdFromCard]);

  useEffect(() => {
    const holidaysRequest = `${baseUrl}/employee/publicHolidays`;
    const region = userAdditionalInfo?.country;

    if (region)
      dispatch(
        GetAllHolidays({
          request: holidaysRequest,
          region,
          startDate: addDays(new Date(), -365),
          endDate: addDays(new Date(), 365),
        })
      );
  }, [userAdditionalInfo]);

  useEffect(() => {
    const dates = [];

    upcomingLeaves?.map((leave) => dates.push(...getDatesBetweenDateRange(leave?.dateFrom, leave?.dateTo)));

    setLeavesCalendar(dates || []);
  }, [upcomingLeaves]);

  useEffect(() => {
    if (leaveTypes.length > 0) {
      setValidDates({
        startDate: new Date(leaveTypes[0]?.startDate),
        endDate: new Date(leaveTypes[0]?.endDate),
      });
    }
  }, [leaveTypes]);

  return (
    <>
      {submittingTimeoffRequest?.state && <PageLoader />}
      {!timeoffRequestLoader && (
        <div className="manage-timeoff-wrapper">
          <TimeoffLayout />

          <div className="request-timeoff-container">
            <div className="request-timeoff-type-wrapper">
              <Stack direction="row" spacing={2}>
                <div>
                  <div className="request-timeoff-type-label">Select type</div>

                  <CustomDropdown
                    options={timeOffTypes}
                    disabled={!!showUploadDocumentSection}
                    onSelect={(key) => {
                      const selectedType = timeOffTypes.find((type) => type.key === key);
                      setLeaveRequest({
                        ...leaveRequest,
                        selectedLeaveType: key,
                        attachDocument: selectedType?.attachDocument,
                      });
                      setValidDates({
                        startDate: selectedType?.startDate ? selectedType.startDate : null,
                        endDate: selectedType?.endDate ? selectedType.endDate : null,
                      });
                    }}
                    buttonLabel={timeoffTypeLabel}
                  />
                </div>
                <div>
                  <div className="request-timeoff-type-label">Allowed time-off YTD</div>

                  <div className="request-timeoff-type-value">{getAllowedTimeOff()}</div>
                </div>
              </Stack>
              {getAllowedTimeOff() > 0 && !!calculateTotalDays() && (
                <Chip
                  className="request-timeoff-type-chip"
                  label={`You have ${calculateTotalDays()} days scheduled and pending in the category. You can cancel them and make a new request here`}
                />
              )}
            </div>

            <div className="request-timeoff-wrapper">
              {showUploadDocumentSection ? (
                <>
                  {" "}
                  <div className="date-range-wrapper w-100p">
                    <div className="flex ml-1 mt-1 mb-075 " style={{ width: "654px" }}>
                      <img src={DateRangeIcon} alt="date range picker icon" />
                      <p className="neutral-20 ml-05"> Step 2/2 : Attach your medical documents</p>
                    </div>

                    <Divider light />

                    <div className="timeoff-selected ml-1">
                      <div className="label-lg neutral-60 pop-regular">Time-off selected</div>
                      <div className="step-2-dates">
                        <div className="title-md primary-30 mt-05 pop-regular ">
                          {selectedTimeOff?.leaves < 0 ? (
                            "Selected daterange not allowed"
                          ) : (
                            <>
                              {`${selectedTimeOff?.leaves} ${timeoffTypeLabel} leave${selectedTimeOff?.leaves > 1 ? "s " : " "}`}
                              (
                              {leaveTypes[0]?.countryCode === "Peru"
                                ? "Including weekends"
                                : "Excluding weekends & holidays"}
                              )
                            </>
                          )}
                        </div>
                        <Button
                          variant="outlined"
                          size="md"
                          className="mr-1 edit-button"
                          onClick={() => {
                            setShowUploadDocumentSection(false);
                          }}
                        >
                          Edit
                        </Button>
                      </div>

                      <div className="pending-leave-duration mt-025">
                        <div className="pending-leave-date">
                          {moment(leaveRequest?.selectedDateRange?.startDate).format("MMM DD, YYYY")}
                        </div>
                        <img className="pending-leave-arrow" src={LeftArrowSmall} alt="left arrow" />{" "}
                        <div className="pending-leave-date">
                          {moment(leaveRequest?.selectedDateRange?.endDate).format("MMM DD, YYYY")}
                        </div>
                      </div>
                    </div>

                    <div className="label-lg pop-regular neutral-60 mt-025 ml-1 mb-075">
                      {Object.keys(selectedTimeOff?.holidays || {})?.length > 0
                        ? `Holidays: ${selectedTimeOff?.holidays?.map((holiday) => holiday.Name).join(", ")}`
                        : ""}
                    </div>

                    <div className="label-lg ml-1 mb-1">
                      Note:
                      <span className="text-italic"> {leaveRequest?.description}</span>
                    </div>
                    <Divider light />

                    <div>
                      <p className="neutral-20 ml-1 mt-15">Attach doctor’s note</p>
                      <p className="ml-1 label-sm neutral-60">You can attach as many documents as needed</p>

                      <FileUploader onFileUpload={(files) => setFilesToUpload(files)} />
                    </div>

                    <div className="label-lg ml-1 mt-05">
                      Enter note {leaveTypes[0]?.countryCode !== "Peru" ? "(Optional)" : ""}{" "}
                    </div>

                    <textarea
                      rows={3}
                      value={leaveRequest?.description || ""}
                      className="request-timeoff-textarea"
                      onChange={(e) => {
                        setLeaveRequest({
                          ...leaveRequest,
                          description: e.target.value,
                        });
                      }}
                    />
                    <div className="request-timeoff-action-btns ml-1 mt-1">
                      <Button variant="outlined" size="lg" className="" onClick={() => navigate("/main/timeoff")}>
                        Cancel
                      </Button>
                      <Button variant="primary" size="lg" onClick={handleCreateLeaveRequest}>
                        Confirm
                      </Button>
                    </div>
                  </div>
                  {timeofRules()}
                </>
              ) : (
                <>
                  {getContent()}
                  {timeofRules()}
                </>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default RequestTimeoff;
