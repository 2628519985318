//employee constants
import {
    RESET_CUSTOMER_ACTIVATION_EMAIL,
    CUSTOMER_ACTIVATION_EMAIL,
    CUSTOMER_ACTIVATION_EMAIL_SUCCESS,
    CUSTOMER_ACTIVATION_EMAIL_FAILURE,
} from "../../constants/Customers/ActivationEmail";

const INIT_STATE = {
    customerActivationEmail: {},
    loadingCustomerActivationEmail: true,
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case RESET_CUSTOMER_ACTIVATION_EMAIL:
            return INIT_STATE;

        case CUSTOMER_ACTIVATION_EMAIL:
            return {
                ...state,
                loadingCustomerActivationEmail: true,
            };
        case CUSTOMER_ACTIVATION_EMAIL_SUCCESS:
            return {
                ...state,
                loadingCustomerActivationEmail: false,
                customerActivationEmail: action.payload,
            };
        // eslint-disable-next-line no-fallthrough
        case CUSTOMER_ACTIVATION_EMAIL_FAILURE:
            return {
                ...state,
                loadingCustomerActivationEmail: false,
                customerActivationEmail: "Error in  getting Club Data",
            };
        default:
            return state;
    }
};
