import React from "react";

import { getValue } from "components/Screens/CommonUtils";
import Button from "components/common/Button";

import GmailIcon from "assets/images/gmail.svg";

function ChangePassword({ contactInfo }) {
  return (
    <div className="flex flex-wrap">
      {contactInfo.map((info) => (
        <div key={info.label} className="w-50p mt-1">
          <p className="text-headings-secondary heading-h5-regular">{info.label}</p>
          <p className="color-text-body-secondary para-body-m-medium">{getValue(info.value)}</p>
        </div>
      ))}
      <div className="pt-1">
        <Button
          label="Link with Google"
          variant="outlined"
          color="secondary"
          startIcon={<img src={GmailIcon} alt="gmail" />}
        />
      </div>
    </div>
  );
}

export default ChangePassword;
