import React from "react";
import Daterange from "components/common/Daterange";

const CalendarWithLeaves = ({ setSelectedDateRange, selectedDateRange, employeesTimeOffDates }) => {
  return (
    <>
      <Daterange
        months={1}
        leavesCalender={employeesTimeOffDates?.leaves || []}
        onChange={(selectedDate) => {
          setSelectedDateRange({
            ...selectedDateRange,
            startDate: selectedDate?.startDate,
            endDate: selectedDate?.endDate,
          });
        }}
        holidaysCalender={employeesTimeOffDates?.holidays || []}
      />
      <div className="flex gap-05 calendar-dot-indicator-wrapper ml-1 mt-0">
        <span className="align-self-center calendar-dot-indicator-orange" />

        <p className="body-md align-self-center">Leaves</p>

        <span className="align-self-center calendar-dot-indicator-green" />

        <p className="body-md align-self-center">Holidays</p>
      </div>
    </>
  );
};

export default CalendarWithLeaves;
