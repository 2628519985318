import React from "react";
//material ui
import { Box, Button, Grid, Paper, Stack, Toolbar, Typography } from "@mui/material";
//component
import PersonalInfo from "./PersonalInfo";
import EmploymentInformation from "./EmploymentInformation";
import CustomerProfile from "./Profile";
import { useSelector } from "react-redux";

function CustomerOverview(props) {
  const customerDetails = useSelector((state) => state.GetCustomerDetails.customerDetails);
  return (
    <Grid item container direction="row" justifyContent="space-between" xs={12} columnSpacing={3} rowSpacing={3}>
      <Grid item xs={12} style={{ height: "100%" }}>
        <Paper
          style={{
            height: "100%",
            overflowX: "hidden",
            overflowY: "auto",
          }}
        >
          <Stack
            direction="column"
            spaing={2}
            justifyContent="flex-end"
            alignContent="flex-end"
            alignItems="flex-end"
            textAlign="end"
          >
            <Button
              textAlign="end"
              variant="contained"
              disableElevation
              sx={{
                mt: 3,
                mr: 5,
                backgroundColor:
                  customerDetails?.payload?.status == "enabled"
                    ? "assigned.light"
                    : customerDetails?.payload?.status == "disabled"
                    ? "unAssigned.light"
                    : "pending.light",

                color:
                  customerDetails?.payload?.status == "enabled"
                    ? "assigned.main"
                    : customerDetails?.payload?.status == "disabled"
                    ? "unAssigned.main"
                    : "pending.main",

                fontWeight: "bold",
                borderRadius: 2,
                // ml: -4,
                width: "90px",
                height: "27px",

                "&:hover": {
                  backgroundColor:
                    customerDetails?.payload?.status == "enabled"
                      ? "assigned.dark"
                      : customerDetails?.payload?.status == "disabled"
                      ? "unAssigned.dark"
                      : "pending.dark",
                },
              }}
            >
              <Typography fontSize="12px" fontFamily="Poppins-Medium" textAlign="end">
                {/* {getValue(customer.status)} */}
                {customerDetails?.payload?.status == "enabled"
                  ? "Activated"
                  : customerDetails?.payload?.status == "disabled"
                  ? "Deactivated"
                  : customerDetails?.payload?.status == "new"
                  ? "New"
                  : customerDetails?.payload?.status == "churned"
                  ? "Churned"
                  : "-"}
              </Typography>
            </Button>

            <CustomerProfile />
          </Stack>
          {/* <Grid item container direction="row" columnSpacing={5}>
            <Grid item xs={12}>
              <CustomerProfile />
            </Grid>
          </Grid> */}
        </Paper>
      </Grid>
    </Grid>
  );
}

export default CustomerOverview;
