import React from "react";
import { Helmet } from "react-helmet";
import { Grid } from "@mui/material";

import Header from "components/Header/Header";
import Customers from "./Customers";

function index(props) {
  const [userData, setUserData] = React.useState({});

  //methods
  React.useEffect(() => {
    setUserData(JSON.parse(localStorage.getItem("loggedInUser")));
  }, []);

  return (
    <>
      <Helmet>
        <title>Edge | Customers</title>
      </Helmet>
      <Grid
        container
        direction="column"
        rowSpacing={3}
        // sx={{ overflowX: "hidden" }}
      >
        <Header
          data={{
            heading: "Customers",
            profileWithIcon: null,
          }}
        />
        {/* DataTable */}
        <Grid item>
          <Customers />
        </Grid>
      </Grid>
    </>
  );
}

export default index;
