import React, { useEffect, useState } from "react";

import AddIcon from "@mui/icons-material/Add";
import WorkOffOutlinedIcon from "@mui/icons-material/WorkOffOutlined";

import Timeline from "components/common/Timeline";
import { convertDateToMonthAndYearShort as convertDate, getDurationFromTwoDates } from "components/Screens/CommonUtils";
import CustomButton from "components/common/Button";
import AddEditExperienceModal from "./AddEditExperienceModal";
import { getEmployeeExperience } from "services/BambooHrTabs/experience";
import PageLoader from "components/common/PageLoader";
import { usePermissions } from "components/Hook";
import EditDeleteMenu from "../Education/EditOrDeleteMenu";

import "./Experience.scss";

function ExperienceTabV2({ employeeId }) {
  const { loggedInUserType } = usePermissions();

  const [role, setRole] = useState("");
  const [experience, setExperience] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [experienceModal, setExperienceModal] = useState({
    show: false,
    mode: "Add",
    data: null,
  });
  const [loading, setLoading] = useState(false);

  const getDuration = (startDate, endDate) => getDurationFromTwoDates(startDate, endDate);
  const getDate = (startDate, endDate) => convertDate(startDate, endDate);

  const closeModal = () => {
    setExperienceModal({ show: false, mode: "Add", data: null });
  };

  const fetchExperience = async () => {
    setLoading(true);
    if (employeeId) {
      const response = await getEmployeeExperience(employeeId);

      if (response) {
        setExperience(response);
      }

      setLoading(false);
      closeModal();
    }
  };

  useEffect(() => {
    if (loggedInUserType === "edge") setRole("admin");
    else if (loggedInUserType === "employee") setRole("employee");
    else if (loggedInUserType === "customer") setRole("customer");
    fetchExperience();
  }, [employeeId]);

  return (
    <div className="pb-3">
      {loading && <PageLoader />}
      <p data-testid="edge-experience-title" className="heading-h4-semibold color-text-heading-primary">
        {role === "customer" || role === "employee" ? "Experience at Edge" : "Edge Experience"}
      </p>

      {experience?.edge?.length === 0 ? (
        <div className="no-experience-container">
          <WorkOffOutlinedIcon className="work-off-icon" />
          <p className="para-body-l-regular para-body-l-regular color-text-heading-primary ">
            {role === "employee" && "No Edge experience to show as you have not been assigned to a customer yet."}

            {role === "admin" && "No Edge experience to show as the employee has not been assigned to a customer yet."}

            {role === "customer" && "No experience added yet."}
          </p>
        </div>
      ) : (
        <Timeline
          timelineData={experience?.edge?.map((item) => {
            return {
              key: item.employmentHistoryId,
              id: item.employmentHistoryId,
              title: item?.designation || "N/A",
              company: item?.customer?.companyName || "N/A",
              icon: role !== "customer" && (
                <EditDeleteMenu setOpenModal={setExperienceModal} menuItem={item} isSourceEdge />
              ),
              date: getDate(item?.joiningDate, item?.endDate),
              duration: getDuration(item?.joiningDate, item?.endDate),
              description: item?.description,
            };
          })}
        />
      )}
      <div className="mb-3">
        <div className="flex justify-between items-center  mt-4">
          <p data-testid="other-experience-title" className="heading-h4-semibold color-text-heading-primary">
            Other Experience
          </p>
          {role !== "customer" && experience?.other?.length > 0 ? (
            <AddIcon onClick={() => setExperienceModal({ show: true, mode: "Add", data: null })} className="add-icon" />
          ) : null}
        </div>

        {experience?.other?.length === 0 && role === "employee" && (
          <div className="para-body-m-regular color-text-body-primary mb-05">
            {" "}
            Add your 2 most recent work experience.
          </div>
        )}

        {experience?.other?.length === 0 ? (
          <div className={`no-experience-other-${role}`}>
            {role === "admin" || role === "customer" ? (
              <p className="para-body-l-regular para-body-l-regular color-text-heading-primary ">
                Employee has not added any other experience yet.
              </p>
            ) : null}
            {role !== "customer" && (
              <CustomButton
                onClick={() => setExperienceModal({ show: true, mode: "Add", data: null })}
                startIcon={<AddIcon />}
                variant="outlined"
                size="large"
                label="Add Other Experience"
                className={role === "employee" ? "add-other-btn" : ""}
                data-testid="more-vert-icon"
                isFullWidth={role === "employee"}
              />
            )}
          </div>
        ) : (
          <Timeline
            timelineData={experience?.other?.map((item) => {
              return {
                key: item?.id,
                id: item?.id,
                title: item?.jobTitle || "N/A",
                company: item?.companyName || "N/A",
                icon: role !== "customer" && <EditDeleteMenu setOpenModal={setExperienceModal} menuItem={item} />,
                date: getDate(item?.startDate, item?.endDate),
                duration: getDuration(item?.startDate, item?.endDate),
                description: item?.description,
              };
            })}
          />
        )}
      </div>
      <AddEditExperienceModal
        {...{
          experienceModal,
          setExperienceModal,
          closeModal,
          employeeId,
          showModal,
          setShowModal,
          setLoading,
          loading,
          fetchExperience,
        }}
        data-testid="add-edit-experience-modal"
      />
    </div>
  );
}

export default ExperienceTabV2;
