import {
    PAYONEER_GET_EMPLOYEE_CONSENT,
    PAYONEER_SET_EMPLOYEE_CONSENT,
    PAYONEER_GET_EMPLOYEE_CONSENT_FAILED,
  } from "store/constants/Payoneer/PayoneerGetEmployeeConsent";
  
  export const fetchConsent = () => {
    return {
      type: PAYONEER_GET_EMPLOYEE_CONSENT,
    };
  };
  
  export const setConsent = (balanceInfo) => {
    return { type: PAYONEER_SET_EMPLOYEE_CONSENT, payload: balanceInfo };
  };
  
  export const fetchConsentFailed = (error) => {
    return { type: PAYONEER_GET_EMPLOYEE_CONSENT_FAILED, payload: error };
  };
  