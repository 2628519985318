import {
    GET_EMPLOYEE_List,
    GET_EMPLOYEE_List_SUCCESS,
    GET_EMPLOYEE_List_FAILURE,
    RESET_EMPLOYEE_List
} from "../constants/GetEmployeeList";

// for employee information
export const ResetGetEmployeeList = (data) => {
    return {
        type: RESET_EMPLOYEE_List,
        payload: data,
    };
};
export const GetEmployeeList = (data) => {
    return {
        type: GET_EMPLOYEE_List,
        payload: data,
    };
};
export const GetEmployeeListSuccess = (payload) => {
    return {
        type: GET_EMPLOYEE_List_SUCCESS,
        payload,
    };
};
export const GetEmployeeListFailure = (payload) => {
    return {
        type: GET_EMPLOYEE_List_FAILURE,
        payload,
    };
};
