import {
  RESET_ADD_EMPLOYEE_Education,
  ADD_EMPLOYEE_Education,
  ADD_EMPLOYEE_Education_SUCCESS,
  ADD_EMPLOYEE_Education_FAILURE,
} from "../../../constants/Workforce/AddEmployee/AddEmployeeEducation";

// for user authentication

export const ResetAddEmployeeEducation = (data) => {
  return {
    type: RESET_ADD_EMPLOYEE_Education,
    payload: data,
  };
};

export const AddEmployeeEducation = (data) => {
  return {
    type: ADD_EMPLOYEE_Education,
    payload: data,
  };
};
export const AddEmployeeEducationSuccess = (payload) => {
  return {
    type: ADD_EMPLOYEE_Education_SUCCESS,
    payload,
  };
};
export const AddEmployeeEducationFailure = (payload) => {
  return {
    type: ADD_EMPLOYEE_Education_FAILURE,
    payload,
  };
};
