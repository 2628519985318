import React, { useMemo } from "react";
// material ui
import { Box, Grid, Typography } from "@mui/material";
// redux dispatch
import { useSelector, useDispatch } from "react-redux";
// Icons
import { RiBankLine } from "react-icons/ri";
// router
import { useParams } from "react-router-dom";
import { CustomerBankInfo } from "../../../../../store/actions/Payment/GetCustomerBankInfo";
import { baseUrl } from "../../../../../util/APIBaseUrl";
// common functions
import { getValue } from "../../../CommonUtils";

function BankInfo() {
  // constants
  const dispatch = useDispatch();
  const params = useParams();

  // redux state
  const bankAccountsDetails = useSelector((state) => state.GetCustomerBankInfo.bankAccountInfo);

  const isCreditCard = useMemo(() => {
    if (bankAccountsDetails?.paymentMethodType === "card") return true;
    return false;
  }, [bankAccountsDetails]);

  React.useEffect(() => {
    dispatch(
      CustomerBankInfo({
        request: `${baseUrl}/customer/${params?.id}/bankInfo`,
      })
    );
  }, []);

  return (
    <>
      <Grid item container direction="row" justifyContent="space-between" alignItems="center" sx={{ pb: 3.5 }}>
        <Grid item>
          <Box display="flex" flexDirection="column" rowGap={1.25}>
            <RiBankLine color="secondary.main" size="27" />
            <Typography variant="h3" fontSize="15px">
              Payment Information
            </Typography>
          </Box>
        </Grid>
      </Grid>

      {bankAccountsDetails != null && Object.keys(bankAccountsDetails).length ? (
        <Grid item container direction="column" rowSpacing={1.5} xs={12}>
          <Grid item>
            <Box display="flex" alignItems="center" justifyContent="space-between">
              <Typography variant="h6" color="secondary.alt" fontSize="13px" marginBottom="15px">
                Payment Method
              </Typography>
              <Typography variant="h5">{getValue(bankAccountsDetails?.paymentMethodType?.toUpperCase())}</Typography>
            </Box>
          </Grid>
          <Grid item>
            <Box display="flex" alignItems="center" justifyContent="space-between">
              <Typography variant="h6" color="secondary.alt" fontSize="13px">
                Account Number
              </Typography>
              <Typography variant="h5">{getValue(`**** **** **** ${bankAccountsDetails?.last4}`)}</Typography>
            </Box>
          </Grid>
          <Grid item>
            <Box display="flex" alignItems="center" justifyContent="space-between">
              <Typography variant="h6" color="secondary.alt" fontSize="13px">
                Account Name
              </Typography>
              <Typography variant="h5">
                {getValue(bankAccountsDetails?.accountName || bankAccountsDetails?.name)}
              </Typography>
            </Box>
          </Grid>

          <Grid item>
            <Box display="flex" alignItems="center" justifyContent="space-between">
              <Typography variant="h6" color="secondary.alt" fontSize="13px">
                {isCreditCard ? "Brand" : "Bank Name"}
              </Typography>
              <Typography variant="h5">
                {isCreditCard ? getValue(bankAccountsDetails?.brand) : getValue(bankAccountsDetails?.bankName)}
              </Typography>
            </Box>
          </Grid>

          <Grid item>
            <Box display="flex" alignItems="center" justifyContent="space-between">
              <Typography variant="h6" color="secondary.alt" fontSize="13px">
                {isCreditCard ? "Expiry" : "  Routing Number"}
              </Typography>
              <Typography variant="h5">
                {isCreditCard ? getValue(bankAccountsDetails?.expiry) : getValue(bankAccountsDetails?.routingNumber)}
              </Typography>
            </Box>
          </Grid>

          <Grid item>
            <Box display="flex" alignItems="start" justifyContent="space-between">
              <Typography variant="h6" color="secondary.alt" fontSize="13px">
                Country
              </Typography>
              <Box sx={{ wordBreak: "break-all" }}>
                <Typography variant="h5" textAlign="end">
                  {getValue(bankAccountsDetails?.country ? bankAccountsDetails?.country : "")}
                </Typography>
              </Box>
            </Box>
          </Grid>

          <Grid item>
            <Box display="flex" alignItems="center" justifyContent="space-between">
              <Typography variant="h6" color="secondary.alt" fontSize="13px">
                Currency
              </Typography>
              <Typography variant="h5">{getValue(bankAccountsDetails.currency)}</Typography>
            </Box>
          </Grid>

          <Grid item>
            <Box display="flex" alignItems="center" justifyContent="space-between">
              <Typography variant="h6" color="secondary.alt" fontSize="13px">
                Status
              </Typography>
              <Typography variant="h5">{getValue(bankAccountsDetails?.status)}</Typography>
            </Box>
          </Grid>
        </Grid>
      ) : (
        <Grid item>
          <Box display="flex" alignItems="center">
            <Typography variant="h6" color="secondary.alt" fontSize="13px">
              No payment method added by customer
            </Typography>
          </Box>
        </Grid>
      )}
    </>
  );
}

export default BankInfo;
