import {
    EMPLOYEE_PREV_EXP,
    EMPLOYEE_PREV_EXP_SUCCESS,
    EMPLOYEE_PREV_EXP_FAILURE,
} from "../constants/EmployeeExperience";


export const EmployeeExperience = (data) => {
    return {
        type: EMPLOYEE_PREV_EXP,
        payload: data,
    };
};
export const EmployeeExperienceSuccess = (payload) => {
    return {
        type: EMPLOYEE_PREV_EXP_SUCCESS,
        payload,
    };
};
export const EmployeeExperienceFailure = (payload) => {
    return {
        type: EMPLOYEE_PREV_EXP_FAILURE,
        payload,
    };
};
