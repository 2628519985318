//employee constants
import { CUSTOMER_INFO, CUSTOMER_INFO_SUCCESS, CUSTOMER_INFO_FAILURE ,RESET_CUSTOMER_INFO} from "../constants/CustomerInfo";

const INIT_STATE = {
  customerInfo: {},
  loadingCustomerInfo: false,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case RESET_CUSTOMER_INFO:
      return INIT_STATE

    case CUSTOMER_INFO:
      return {
        ...state,
        loadingCustomerInfo: true,
      };
    case CUSTOMER_INFO_SUCCESS:
      // const newState = Object.assign(
      //     {},
      //     state.customerInfo,
      //     action.payload
      // );

      return {
        ...state,
        loadingCustomerInfo: false,
        customerInfo: action.payload,
      };
    // eslint-disable-next-line no-fallthrough
    case CUSTOMER_INFO_FAILURE:
      return {
        ...state,
        loadingCustomerInfo: false,
        customerInfo: "Error in  getting Club Data",
      };
    default:
      return state;
  }
};
