import React from "react";
import moment from "moment";

import PakistanFlag from "assets/svgs/PakistanFlag";
import PeruFlag from "assets/svgs/PeruFlag";

export default function EmployeePersonalInfo({ employeeDetails }) {
  const renderCountryFlag = (country) => {
    if (country === "Pakistan")
      return (
        <div className="flex mr-05">
          <PakistanFlag width="18" height="18" />
        </div>
      );
    if (country === "Peru")
      return (
        <div className="flex mr-05">
          <PeruFlag width="18" height="18" />
        </div>
      );
    return null;
  };

  const personalInfo = [
    {
      label: "Country",
      value: (
        <span className="flex items-center color-text-body-secondary para-body-m-medium">
          {renderCountryFlag(employeeDetails?.country)} {employeeDetails?.country}
        </span>
      ),
    },
    { label: "National ID", value: employeeDetails?.cnic_dni_cex },
    { label: "Gender", value: employeeDetails?.gender },
    {
      label: "Date of birth",
      value: moment(employeeDetails?.dateOfBirth).format("MMM D, YYYY"),
    },
  ];

  return (
    <div className="flex flex-wrap">
      {personalInfo.map((info) => (
        <div key={info.label} className="w-50p mt-1">
          <p className="text-headings-secondary heading-h5-regular">{info.label}</p>
          <p className="color-text-body-secondary para-body-m-medium">{info.value}</p>
        </div>
      ))}
    </div>
  );
}
