import { baseUrl } from "util/APIBaseUrl";
import getRequest from "util/APIHelperGet";
import postRequest from "util/APIHelperPost";

export const getEmployeeTimeOffDetails = async (payload) => {
  try {
    const { employeeId, startDate, endDate, leaveTypeId } = payload;
    const response = await getRequest(
      `${baseUrl}/admin/leaveRequests?employeeId=${employeeId || ""}&startDate=${startDate || ""}&endDate=${
        endDate || ""
      }&leaveTypeId=${leaveTypeId || ""}`,
      true
    );
    return response.payload;
  } catch (error) {
    console.log(error);
  }
};

export const getEmployeeLeavesDataForAdmin = async (employeeId) => {
  try {
    const response = await getRequest(`${baseUrl}/admin/leave_types/${employeeId}`, true);
    return response.payload;
  } catch (error) {
    console.log(error);
  }
};

export const addEmployeeNewLeaveRequest = async (body) => {
  try {
    const response = await postRequest(`${baseUrl}/admin/createLeave`, JSON.stringify(body));
    return response.payload;
  } catch (error) {
    throw error;
  }
};

export const getTotalLeaveCount = async (employeeId, leaveId, startDate, endDate) => {
  try {
    const response = await getRequest(
      `${baseUrl}/admin/${employeeId}/count_leaves/${leaveId}?dateFrom=${startDate}&dateTo=${endDate}`
    );
    return response.payload;
  } catch (error) {
    throw error;
  }
};

export const getEmployeeDocuments = async (request) => {
  try {
    const response = await getRequest(`${baseUrl}/${request}`, true);
    return response.payload;
  } catch (error) {
    console.log(error);
  }
};

export const addSwappedLeaves = async (body) => {
  try {
    const response = await postRequest(`${baseUrl}/admin/swapped_leaves`, JSON.stringify(body));
    return response.payload;
  } catch (error) {
    throw error;
  }
};

export const getEmployeesLeaveAndHolidays = async (employeeId, startDate, endDate) => {
  try {
    const response = await getRequest(
      `${baseUrl}/admin/employee/${employeeId}/leave_holidays?startDate=${startDate}&endDate=${endDate}`,
      true
    );
    return response.payload;
  } catch (error) {
    console.log(error);
  }
};
