import {
  RESET_MANAGE_USER,
  MANAGE_USER,
  MANAGE_USER_SUCCESS,
  MANAGE_USER_FAILURE,
} from "../../constants/Settings/ManageUser";

// for add employee in admin workforce table

export const ResetManageUser = (data) => {
  return {
    type: RESET_MANAGE_USER,
    payload: data,
  };
};

export const ManageUser = (data) => {
  return {
    type: MANAGE_USER,
    payload: data,
  };
};
export const ManageUserSuccess = (payload) => {
  return {
    type: MANAGE_USER_SUCCESS,
    payload,
  };
};
export const ManageUserFailure = (payload) => {
  return {
    type: MANAGE_USER_FAILURE,
    payload,
  };
};
