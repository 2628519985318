import React, { useState } from "react";
import { useForm, FormProvider, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import Button from "components/common/Button";
import Text from "components/common/Text";
import PhoneInput from "components/common/PhoneInput";

import { editContactInfoSchema } from "validations/BambooHRTabs/ContactSchema";

import "./Contact.scss";
import SearchableAutocomplete from "components/common/AutoComplete";

function EditContactInfo({ onSave, initialData, onCancel, countryStates }) {
  const methods = useForm({
    defaultValues: initialData,
    resolver: yupResolver(editContactInfoSchema),
    mode: "onChange",
  });
  const [isLoading, setIsLoading] = useState(false);

  const {
    handleSubmit,
    formState: { errors, isValid },
    control,
    trigger,
  } = methods;

  const onSubmit = (data) => {
    if (isValid) {
      setIsLoading(true);
      onSave(data);
    }
  };

  const handleSaveClick = async () => {
    const result = await trigger();
    if (result) {
      handleSubmit(onSubmit)();
    }
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)} className="edit-contact-form">
        <div className="my-15">
          <div className="form-row mb-1">
            <Text
              name="email"
              label="Work email address"
              placeholder="name@example.com"
              error={!!errors.email}
              helperText={errors.email?.message}
            />
          </div>
          <div className="form-row mb-1 two-columns">
            <Controller
              name="phoneNumber"
              control={control}
              defaultValue=""
              render={({ field: { onChange, value } }) => (
                <PhoneInput
                  name="phoneNumber"
                  label="Phone number"
                  value={value}
                  onChange={onChange}
                  error={!!errors.phoneNumber}
                  helperText={errors.phoneNumber?.message}
                  isOptional
                />
              )}
            />
            <Text
              name="personalEmail"
              label="Personal email address"
              placeholder="name@example.com"
              error={!!errors.personalEmail}
              helperText={errors.personalEmail?.message}
              isOptional
            />
          </div>
          <div className="form-row mb-1 three-columns">
            <SearchableAutocomplete
              name="state"
              label="State"
              options={countryStates}
              placeholder="Select"
              error={!!errors.state}
              helperText={errors.state?.message}
            />
            <Text
              name="city"
              label="City"
              placeholder="e.g. Middletown"
              error={!!errors.city}
              helperText={errors.city?.message}
            />
            <Text
              name="zip"
              label="ZIP code"
              placeholder="e.g. 07701"
              error={!!errors.zip}
              helperText={errors.zip?.message}
              className="zip-code-input"
            />
          </div>
          <div className="form-row mb-1">
            <Text
              name="addressLine1"
              label="Address"
              placeholder="e.g. House 11, 350 East Main Road"
              error={!!errors.addressLine1}
              helperText={errors.addressLine1?.message}
            />
          </div>
        </div>
        <div className="form-actions">
          <Button variant="outlined" size="large" label="Cancel" onClick={onCancel} className="mr-1" />
          <Button
            variant="contained"
            isLoading={isLoading}
            color={!isValid && "disabled"}
            size="large"
            label="Save"
            onClick={handleSaveClick}
            disabled={!isValid}
          />
        </div>
      </form>
    </FormProvider>
  );
}

export default EditContactInfo;
