import { call, put, takeEvery } from "redux-saga/effects";
//actions
import {
  GetCustomerDetailsSuccess,
  GetCustomerDetailsFailure,
} from "../../actions/Customers/GetCustomerDetails";
//constant
import { GET_CUSTOMER_Details } from "../../constants/Customers/GetCustomerDetails";
//request type
import getRequest from "../../../util/APIHelperGet";
//base url
import { baseUrl } from "../../../util/APIBaseUrl";

export const fetchGetCustomerDetailsRequest = async (actions) => {
  return await getRequest(`${baseUrl}/customer/${actions.payload.customerId}`)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

function* fetchGetCustomerDetails(params) {
  try {
    let response = yield call(fetchGetCustomerDetailsRequest, params);

    yield put(GetCustomerDetailsSuccess(response));
  } catch (error) {
    console.error(error);
    yield put(GetCustomerDetailsFailure(error));
  }
}

export default function* rootSaga() {
  yield takeEvery(GET_CUSTOMER_Details, fetchGetCustomerDetails);
}
