import {
  RESET_EDIT_USER_DETAILS,
  EDIT_USER_DETAILS,
  EDIT_USER_DETAILS_SUCCESS,
  EDIT_USER_DETAILS_FAILURE,
} from "../../constants/Settings/EditUserDetails";

// for add employee in admin workforce table

export const ResetEditUserDetails = (data) => {
  return {
    type: RESET_EDIT_USER_DETAILS,
    payload: data,
  };
};

export const EditUserDetails = (data) => {
  return {
    type: EDIT_USER_DETAILS,
    payload: data,
  };
};
export const EditUserDetailsSuccess = (payload) => {
  return {
    type: EDIT_USER_DETAILS_SUCCESS,
    payload,
  };
};
export const EditUserDetailsFailure = (payload) => {
  return {
    type: EDIT_USER_DETAILS_FAILURE,
    payload,
  };
};
