import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useRoutes, Navigate } from "react-router-dom";
import * as Sentry from "@sentry/react";
import { useFlags } from "launchdarkly-react-client-sdk";

import MainComponent from "components/main/main.component";
import DashBoard from "components/Screens/DashBoard";
import Community from "components/Screens/Community";
import Workforce from "components/Screens/Workforce";
import UserDetails from "components/Screens/Workforce/UserDetailsV2";
import Payroll from "components/Screens/PayrollV2";
import PayrollDetails from "components/Screens/Payroll/PayrollDetails";
import UpcomingHolidays from "components/Screens/UpcomingHolidays/UpcomingHolidays";
import GetInTouch from "components/Screens/GetInTouch";
import Customers from "components/Screens/Customers";
import Payment from "components/Screens/PaymentV2";
import Setting from "components/Screens/SettingV2";
import UserDetail from "components/Screens/Setting/MangeRoles/userDetail";
import Login from "components/Screens/Login";
import SignUp from "components/Screens/Login/SignUp";
import AddEmployee from "components/Screens/Workforce/AdminWorkforce/AddTalent";
import AddCustomer from "components/Screens/Customers/AddCustomer";
import CustomerDetials from "components/Screens/Customers/CustomerDetials";
import AddUser from "components/Screens/Setting/AddUser";
import Profile from "components/Screens/SettingV2/Profile";
import ProfileV2 from "components/Screens/SettingV2/Profile/ProfileV2";
import AddBonus from "components/Screens/Customers/CustomerDetials/AddBonus";
import EditSalary from "components/Screens/Workforce/UserDetailsV2/EditSalary";
import EditPayroll from "components/Screens/PayrollV2/EditPayroll/index";
import EmployeePayStub from "components/Screens/Employee/EmployeePayStub";
import EmployeePayStubDetails from "components/Screens/Employee/EmployeePayStubDetails";
import EmployeeProfile from "components/Screens/Employee/EmployeeProfile";
import EmployeeDocuments from "components/Screens/Employee/EmployeeDocuments";
import CompanyDetails from "components/Screens/Employee/CompanyDetailsv2";
import EmployeePeersList from "components/Screens/Employee/EmployeePeersList";
import PayrollHistoryDetail from "components/Screens/PayrollHistoryDetail";
import EmployeeUpComingHolidays from "components/Screens/Employee/EmployeeUpComingHolidays";
import Documents from "components/Screens/Documents";
import Salaries from "components/Screens/Salaries";
import PayrollUpload from "components/Screens/PayrollUpload";
import ChangePassword from "components/Screens/Login/ChangePassword";
import AddPaymentMethodV2 from "components/Screens/Login/AddPaymentMethodV2";
import ResendLink from "components/Screens/Login/ChangePassword/ResendLink";
import LeaveStatus from "components/Screens/Timeoff/LeaveStatus";
import LeaveDetails from "components/Screens/Timeoff/ManageTimeoff/Customer/Details";
import FeedbackScreen from "components/Screens/Feedback/Feedback";
import ManageTimeoff from "components/Screens/Timeoff/ManageTimeoff";
import RequestTimeoff from "components/Screens/Timeoff/ManageTimeoff/RequestTimeoff";
import LeaveTimeoff from "components/Screens/Timeoff/ManageTimeoff/Customer/LeaveTimeOff";
import EmployeePayoneer from "components/Screens/Employee/EmployeePayoneer";

import { GetPlatformConfig } from "store/actions/PlatformConfig";
import { UserAdditionalInfo } from "store/actions/UserAdditionalInfo";

import useRequirePermission from "./useRequirePermission";
import { capitalizeFirstLetter, getAge } from "util/utils";
import TimeoffAdmin from "components/Screens/Timeoff/TimeoffAdmin";
import EmployeeTimeOffDetails from "components/Screens/Timeoff/TimeoffAdmin/EmployeeTimeOffDetails";
import EmployeeTimeoffNewActivity from "components/Screens/Timeoff/TimeoffAdmin/EmployeeTimeoffNewActivity";
import TimeOffUtilisationReport from "components/Screens/Timeoff/TimeoffAdmin/UtilisationReport";
import EdgeVivo from "components/Screens/EdgeVivo";
import Terms from "components/Screens/TermsAndPolicy/Terms";
import CustomerPrivacyPolicy from "components/Screens/TermsAndPolicy/CustomerPolicy";
import EmployeePrivacyPolicy from "components/Screens/TermsAndPolicy/EmployeePolicy";

import { marketplacePrivateRoutes, marketplacePublicRoutes } from "routes/Marketplace.route";

function PrivateRoute({ Component }) {
  const accessToken = localStorage.getItem("accessToken");
  const loggedInUser = JSON.parse(localStorage.getItem("loggedInUser"));

  const dispatch = useDispatch();

  const userAdditionalInfo = useSelector((state) => state.UserAdditionalInfo.userAdditionalInfo);

  let request;
  useEffect(() => {
    dispatch(GetPlatformConfig());
    if (accessToken && loggedInUser && loggedInUser?.type !== "employee") {
      if (loggedInUser?.type == "customer") {
        request = `customer/${loggedInUser.customerId}/info`;
      } else if (loggedInUser?.type == "edge") {
        request = `user/${loggedInUser.userId}/summary`;
      } else if (loggedInUser?.type == "employee") {
        request = `employee/${loggedInUser?.userId}`;
      }
      dispatch(UserAdditionalInfo(request));
    }
  }, []);

  useEffect(() => {
    if (window.heap && loggedInUser?.email) {
      heap.identify(loggedInUser.email);
      /* eslint-disable camelcase */
      const {
        companyName,
        totalEmployees,
        industry,
        city,
        country,
        state,
        dateOfBirth,
        gender,
        peersCount,
        customerInfo,
        jobTitle,
        Community_Profile_Url,
        paymentMethod,
      } = userAdditionalInfo;

      const { name, type, permissions, userId } = loggedInUser;

      const paymentMethodType = {
        CREDIT_CARD: "Credit Card",
        CHEQUE: "Cheque",
        ACH: "ACH",
      };

      let userProps;
      /* eslint-disable camelcase */
      if (type === "customer") {
        userProps = {
          "Full Name": name,
          "Account Name": companyName,
          Industry: industry,
          "No. of Edge Associates": totalEmployees,
          "User Type": capitalizeFirstLetter(type),
          "User Role": permissions,
          "Edge User ID": userId,
          City: city,
          State: state,
          Country: country,
          "Payment Method": paymentMethodType[paymentMethod] || "Missing",
        };
      } else if (type === "employee") {
        userProps = {
          "Full Name": name,
          Age: `${getAge(dateOfBirth)}`,
          Gender: gender,
          "No. of Peers": peersCount,
          "User Type": capitalizeFirstLetter(type),
          "User Role": permissions,
          "Working With": customerInfo?.companyName,
          Industry: customerInfo?.industry,
          Designation: jobTitle,
          "Edge User ID": userId,
          City: city,
          State: state,
          Country: country,
          "Active Customer ID": customerInfo?.customerId,
          "Community Profile Created": !!Community_Profile_Url,
        };
      } else {
        userProps = {
          "Full Name": name,
          "Edge User ID": userId,
          "User Roles": permissions?.join(" - "),
          "User Type": "Admin",
          Designation: userAdditionalInfo?.user?.jobTitle,
        };
      }

      heap.addUserProperties(userProps);

      if (window.location.hostname !== "localhost") {
        Sentry.setUser(userProps);
      }

      window?.analytics?.identify(loggedInUser.email, userProps);
    }
  }, [window.heap]);

  useEffect(() => {
    const { name, type, userId } = loggedInUser;

    window.usetifulTags = {
      userId,
      role: type === "customer" ? "Customer" : "Employee",
      firstName: name,
    };

    (function (w, d, s) {
      const a = d.getElementsByTagName("head")[0];
      const r = d.createElement("script");
      r.async = 1;
      r.src = s;
      r.setAttribute("id", "usetifulScript");
      r.dataset.token = "f606cde932518c45e82ccc4011e1313b";
      a.appendChild(r);
    })(window, document, "https://www.usetiful.com/dist/usetiful.js");

    return () => {
      const script = document.getElementById("usetifulScript");
      if (script) {
        script.parentNode.removeChild(script);
      }
    };
  }, []);

  const isAuth = accessToken != null && accessToken != undefined;

  return isAuth ? <Component /> : <Navigate to="/" />;
}

function PrivatePage(props) {
  const { Component, Permission, type } = props;
  const isAccessible = useRequirePermission(Permission, type);
  if (isAccessible) {
    return <Component />;
  }
  return <Navigate to="/main/dashboard" />;
}

function Index() {
  const flags = useFlags();

  return useRoutes([
    { path: "/", element: <Login /> },
    { path: "/admin", element: <Login /> },
    { path: "/employee", element: <Login /> },
    { path: "/changePassword", element: <ChangePassword /> },
    { path: "/leaveStatus", element: <LeaveStatus /> },
    { path: "/addPaymentMethod", element: <AddPaymentMethodV2 /> },
    { path: "/signup/:loginPortal", element: <SignUp /> },
    { path: "/resend-link", element: <ResendLink /> },
    { path: "/terms", element: <Terms /> },
    { path: "/policy", element: <CustomerPrivacyPolicy /> },
    { path: "/employee-policy", element: <EmployeePrivacyPolicy /> },
    { path: "marketplace", children: marketplacePublicRoutes },
    {
      path: "main",
      element: <PrivateRoute Component={MainComponent} />,
      children: [
        {
          path: "dashboard",
          element: <DashBoard />,
        },
        {
          path: "community",
          element: <Community />,
        },

        {
          path: "workforce",
          element: <Workforce />,
        },
        {
          path: "details",
          element: <UserDetails />,
          children: [
            {
              path: ":id",
              element: <UserDetails />,
            },
          ],
        },
        {
          path: "editSalary",
          element: <EditSalary />,
        },
        {
          path: "payroll",
          element: <PrivatePage Component={Payroll} page="payroll" type={["customer"]} />,
        },
        {
          path: "payroll/detail/:id",
          element: <PrivatePage Component={PayrollHistoryDetail} page="payrollHistoryDetail" type={["customer"]} />,
        },
        {
          path: "payrollHistory/:id",
          element: <PayrollDetails />,
        },
        {
          path: "settings",
          element: (
            <PrivatePage
              Component={Setting}
              page="settings"
              Permission={["EdgeAdmin", "CustomerAdmin", "CustomerEmployee"]}
            />
          ),
        },
        {
          path: "customers",
          element: <PrivatePage Component={Customers} page="customers" type={["edge"]} />,
        },

        {
          path: "payment",
          element: <PrivatePage Component={Payment} page="payment" Permission={["CustomerAdmin"]} />,
        },

        {
          path: "documents",
          element: (
            <PrivatePage
              Component={Documents}
              page="documents"
              Permission={["EdgeAdmin", "CustomerAdmin", "CustomerEmployee"]}
            />
          ),
        },

        {
          path: "workforcepayrolls",
          element: <PrivatePage Component={Salaries} page="salaries" Permission={["EdgeAdmin"]} />,
        },

        {
          path: "payrollsupload",
          element: <PrivatePage Component={PayrollUpload} page="payrollsupload" Permission={["EdgeAdmin"]} />,
        },

        {
          path: "user/details",
          element: <UserDetail />,
          children: [{ path: ":id", element: <UserDetail /> }],
        },
        {
          path: "adduser",
          element: <AddUser />,
        },
        {
          path: "addemployee",
          element: <AddEmployee />,
        },
        {
          path: "addcustomer",
          element: <AddCustomer />,
        },

        {
          path: "customers/addBonus",
          element: <AddBonus />,
        },
        {
          path: "customers/editPayroll",
          element: <EditPayroll />,
        },
        {
          path: "customer/details",
          element: <CustomerDetials />,
          children: [{ path: ":id", element: <CustomerDetials /> }],
        },
        {
          path: "profile",
          element: flags?.teamManagement ? (
            <PrivatePage Component={ProfileV2} page="profile" type={["edge", "customer"]} />
          ) : (
            <PrivatePage Component={Profile} page="profile" type={["edge", "customer"]} />
          ),
        },
        {
          path: "paystubs/:id",
          element: <PrivatePage Component={EmployeePayStubDetails} page="paystubs" type={["employee"]} />,
        },
        {
          path: "paystubs",
          element: <PrivatePage Component={EmployeePayStub} page="paystubs" type={["employee"]} />,
        },
        {
          path: "eprofile",
          element: <PrivatePage Component={EmployeeProfile} page="paystubs" type={["employee"]} />,
        },

        {
          path: "edocuments",
          element: <PrivatePage Component={EmployeeDocuments} page="paystubs" type={["employee"]} />,
        },
        {
          path: "edge-wallet",
          element: <PrivatePage Component={EmployeePayoneer} page="paystubs" type={["employee"]} />,
        },
        {
          path: "peers",
          element: <PrivatePage Component={EmployeePeersList} page="paystubs" type={["employee"]} />,
        },
        {
          path: "timeoff/leave",
          element: <PrivatePage Component={LeaveTimeoff} page="timeoff" type={["customer"]} />,
        },
        {
          path: "timeoff",
          element: <PrivatePage Component={ManageTimeoff} page="timeoff" type={["employee"]} />,
        },
        {
          path: "timeoff/request",
          element: <PrivatePage Component={RequestTimeoff} page="timeoff" type={["employee"]} />,
        },
        {
          path: "timeoff/admin",
          element: <PrivatePage Component={TimeoffAdmin} page="timeoff" type={["edge"]} />,
        },
        {
          path: "details/timeoff",
          element: <PrivatePage Component={LeaveDetails} page="timeoff" type={["customer"]} />,
        },
        {
          path: "admin/timeoff/new-activity",
          element: <PrivatePage Component={EmployeeTimeoffNewActivity} page="timeoff" type={["edge"]} />,
        },
        {
          path: "timeoff/admin/Utilisation-report",
          element: <PrivatePage Component={TimeOffUtilisationReport} page="timeoff" type={["edge"]} />,
        },

        {
          path: "timeoff/admin/employee",
          element: <PrivatePage Component={EmployeeTimeOffDetails} page="timeoff" type={["edge"]} />,
        },

        {
          path: "upcoming",
          element: <PrivatePage Component={EmployeeUpComingHolidays} page="holidays" type={["employee", "customer"]} />,
        },
        {
          path: "holidays",
          element: <UpcomingHolidays />,
        },
        {
          path: "contact",
          element: <GetInTouch />,
        },
        {
          path: "company",
          element: <PrivatePage Component={CompanyDetails} page="paystubs" type={["employee"]} />,
        },
        {
          path: "feedback",
          element: <PrivatePage Component={FeedbackScreen} page="feedback" type={["employee", "customer"]} />,
        },
        {
          path: "vivo",
          element: <PrivatePage Component={EdgeVivo} page="vivo" type={["employee"]} />,
        },
        {
          path: "marketplace",
          children: marketplacePrivateRoutes,
        },
      ],
    },
  ]);
}

export default Index;
