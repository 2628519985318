import React from "react";

const HomeIcon = () => {
  return (
    <svg
      width="19"
      height="18"
      viewBox="0 0 19 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M14.9091 7.6381L9.90801 2.637L4.9069 7.6381H4.90802V15.9714H14.908V7.6381H14.9091ZM16.5747 9.30367V15.9714C16.5747 16.8919 15.8285 17.6381 14.908 17.6381H4.90802C3.98755 17.6381 3.24135 16.8919 3.24135 15.9714V9.30365L2.01067 10.5343L0.833008 9.35667L8.7305 1.45918C9.38082 0.808855 10.4352 0.808855 11.0855 1.45918L18.983 9.35667L17.8053 10.5343L16.5747 9.30367Z"
        fill="#FBFAFF"
      />
    </svg>
  );
};

export default HomeIcon;
{
  /*  */
}
