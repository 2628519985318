import { call, put, takeEvery } from "redux-saga/effects";
//actions
import {
  GetDocumentsListSuccess,
  GetDocumentsListFailure,
} from "../../actions/Doucments/GetDocumentsList";
//constant
import { GET_DOCUMENTS_LIST } from "../../constants/Doucments/GetDocumentsList";
//request type
import getRequest from "../../../util/APIHelperGet";

export const fetchDocumentsListRequest = async (actions) => {
  // eslint-disable-next-line no-return-await

  return await getRequest(
    `${actions.payload.request}?`
    // new URLSearchParams({
    //     limit: actions.payload.rowsPerPage,
    //     page: actions.payload.page,
    // })
  )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

function* fetchDocumentsList(params) {
  try {
    let response = yield call(fetchDocumentsListRequest, params);
    yield put(GetDocumentsListSuccess(response));
  } catch (error) {
    console.error(error);
    yield put(GetDocumentsListFailure(error));
  }
}

export default function* rootSaga() {
  yield takeEvery(GET_DOCUMENTS_LIST, fetchDocumentsList);
}
