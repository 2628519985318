import React from "react";

const SunIcon = () => {
  return (
    <svg
      width="46"
      height="46"
      viewBox="0 0 46 46"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.9463 42.9592L18.8965 37.8676C17.1286 37.514 15.5021 36.8069 14.017 35.8168L13.9463 42.9592ZM30.2111 3.78223L25.261 8.87382C27.0289 9.2274 28.6554 9.93457 30.1404 10.9246L30.2111 3.78223ZM2.49023 31.5031L9.63261 31.4324C8.64258 29.9474 7.93541 28.3209 7.58183 26.553L2.49023 31.5031ZM41.6672 15.2383L34.5249 15.309C35.4442 16.7941 36.1513 18.4206 36.5756 20.1885L41.6672 15.2383ZM2.49023 15.2383L7.58183 20.1885C7.93541 18.4206 8.64258 16.7941 9.63261 15.309L2.49023 15.2383ZM41.6672 31.5031L36.5756 26.553C36.2221 28.3209 35.5149 29.9474 34.5249 31.4324L41.6672 31.5031ZM13.9463 3.78223L14.017 10.9246C15.5021 10.0053 17.1286 9.29812 18.8965 8.87382L13.9463 3.78223ZM30.2111 42.9592L30.1404 35.8168C28.6554 36.8069 27.0289 37.514 25.261 37.8676L30.2111 42.9592Z"
        fill="#FFDF9C"
      />
      <path
        d="M9.91598 31.8567L7.08732 38.4334L13.664 35.6047C12.1789 34.544 10.906 33.2711 9.91598 31.8567ZM34.2425 14.8847L37.0712 8.30811L30.5652 11.1368C31.9796 12.1975 33.2525 13.4704 34.2425 14.8847ZM7.51162 20.7542L0.864258 23.3707L7.51162 25.9873C7.29947 25.1387 7.22875 24.2901 7.22875 23.3707C7.22875 22.4514 7.29947 21.6028 7.51162 20.7542ZM36.6469 25.9873L43.2942 23.3707L36.6469 20.7542C36.7883 21.6028 36.9297 22.5221 36.9297 23.3707C36.9297 24.2901 36.859 25.1387 36.6469 25.9873ZM13.5932 11.2075L7.0166 8.37882L9.84527 14.9555C10.906 13.4704 12.1789 12.1975 13.5932 11.2075ZM30.5652 35.534L37.1419 38.3627L34.3132 31.786C33.2525 33.2711 31.9796 34.544 30.5652 35.534ZM24.6958 8.80312L22.0792 2.15576L19.4627 8.80312C20.3113 8.66169 21.2306 8.52026 22.0792 8.52026C22.9278 8.52026 23.8472 8.59097 24.6958 8.80312ZM19.4627 37.9384L22.0792 44.5857L24.6958 37.9384C23.8472 38.1505 22.9986 38.2212 22.0792 38.2212C21.1599 38.2212 20.3113 38.1505 19.4627 37.9384Z"
        fill="#F9BD00"
      />
      <path
        d="M22.0797 36.8069C29.5003 36.8069 35.5159 30.7913 35.5159 23.3707C35.5159 15.9501 29.5003 9.93457 22.0797 9.93457C14.6591 9.93457 8.64355 15.9501 8.64355 23.3707C8.64355 30.7913 14.6591 36.8069 22.0797 36.8069Z"
        fill="#F9BD00"
      />
    </svg>
  );
};

export default SunIcon;
