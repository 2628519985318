import React, { useState, useRef, useEffect } from "react";

import DropdownIcon from "assets/images/chevron-bottom.svg";
import CloseIcon from "@mui/icons-material/Close";
import "./Dropdown.css";
import PeruIcon from "components/Screens/Timeoff/TimeoffAdmin/EmployeeTimeOffDetails/Icons/PeruIcon";
import PakistanIcon from "components/Screens/Timeoff/TimeoffAdmin/EmployeeTimeOffDetails/Icons/PakistanIcon";
import { IconButton, Stack } from "@mui/material";
import SortArrowIcon from "components/Screens/Timeoff/TimeoffAdmin/EmployeeTimeOffDetails/Icons/SortArrowIcon";

const CustomDropdown = ({
  options,
  disabled = false,
  onSelect,
  buttonLabel,
  style,
  customDropdownStyles,
  placeholder,
  onClear,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const dropdownRef = useRef(null);

  const handleToggle = () => {
    if (!disabled) setIsOpen(!isOpen);
  };

  const handleSelect = (option) => {
    setSelectedOption(option);
    onSelect(option);
    setIsOpen(false);
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  return (
    <div className="custom-dropdown" ref={dropdownRef} style={customDropdownStyles}>
      <button
        className="dropdown-button"
        disabled={disabled}
        id={buttonLabel}
        onClick={handleToggle}
        style={{ ...style, alignItems: "center" }}
      >
        {/* <span className="notificationDot"></span> {buttonLabel || placeholder} */}
        {placeholder === "Flags" ? (
          <>
            <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
              <span className="notificationDot"></span>
              <span>{buttonLabel || placeholder}</span>
            </div>
          </>
        ) : (
          buttonLabel || placeholder
        )}

        <Stack direction="row">
          {buttonLabel && onClear && (
            <IconButton sx={{ width: "20px", height: "20px" }} onClick={() => onClear()}>
              <CloseIcon style={{ width: "15px", height: "15px" }} />
            </IconButton>
          )}
          <img
            className="dropdown-icon"
            style={{
              filter: disabled
                ? "invert(62%) sepia(9%) saturate(272%) hue-rotate(211deg) brightness(91%) contrast(88%)"
                : "",
            }}
            src={DropdownIcon}
            alt="chevron-bottom"
          />
        </Stack>
      </button>
      {isOpen && (
        <div className="dropdown-list">
          {options.map((option) => (
            <div
              key={option}
              className={`dropdown-item ${option === selectedOption ? "selected" : ""}`}
              onClick={() => {
                if (!option.disabled) handleSelect(option.key);
              }}
              data-testid={`option-${option.key}`}
            >
              {placeholder === "Country" ? (
                <>
                  <div
                    className={option.disabled ? `filter-gray cursor-not-allowed` : ""}
                    style={{ display: "flex", alignItems: "center", gap: "5px" }}
                  >
                    <IconButton sx={{ width: "17px", height: "17px", padding: "0px" }}>
                      {option.label === "Peru" ? <PeruIcon /> : <PakistanIcon />}
                    </IconButton>
                    <span>{option.label}</span>
                  </div>
                </>
              ) : (
                option.label
              )}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default CustomDropdown;
