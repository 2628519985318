import { UPCOMING_LEAVES, GET_UPCOMING_LEAVES_SUCCESS, GET_UPCOMING_LEAVES_FAILURE } from "store/constants/Timeoff";

export const FetchUpcomingLeaves = (data) => {
  return {
    type: UPCOMING_LEAVES,
    payload: data,
  };
};

export const UpcomingLeavesSucess = (payload) => {
  return {
    type: GET_UPCOMING_LEAVES_SUCCESS,
    payload,
  };
};

export const UpcomingLeavesFailure = (payload) => {
  return {
    type: GET_UPCOMING_LEAVES_FAILURE,
    payload,
  };
};
