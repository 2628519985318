import { call, put, takeEvery } from "redux-saga/effects";

import {
  postADocForWorkforceSuccess,
  postADocForWorkforceFailure,
} from "store/actions/Workforce/EmployeeDetils/Documents";

import { POST_DOC_FOR_WORKFORCE } from "store/constants/Workforce/EmployeeDetils/Documents";

import { baseUrl } from "util/APIBaseUrl";
import { makeRequest } from "util/APIHelper";

export const postDocReq = async (actions) => {
  const options = {
    path: `${baseUrl}/${actions.payload.request}`,
    method: "POST",
    body: actions.payload.body,
  };
  return await makeRequest(options, true)
    .then((response) => response)
    .catch((error) => {
      throw error;
    });
};

function* postDoc(params) {
  try {
    const response = yield call(postDocReq, params);

    yield put(postADocForWorkforceSuccess(response));
  } catch (error) {
    console.error(error);
    yield put(postADocForWorkforceFailure(error));
  }
}

export default function* rootSaga() {
  yield takeEvery(POST_DOC_FOR_WORKFORCE, postDoc);
}
