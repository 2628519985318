import "./sectionHeader.css";

const SectionHeader = ({ heading, subHeading }) => {
  return (
    <div className="container-header">
      <div className="section-heading">{heading}</div>
      <div className="section-subheading">{subHeading}</div>
    </div>
  );
};

export default SectionHeader;
