//employee constants
import {
  RESET_GET_CUSTOMER_PAYMENT_BY_ID,
  GET_CUSTOMER_PAYMENT_BY_ID,
  GET_CUSTOMER_PAYMENT_BY_ID_SUCCESS,
  GET_CUSTOMER_PAYMENT_BY_ID_FAILURE,
} from "../../constants/Customers/GetCustomerPaymentById";

const INIT_STATE = {
  customerPaymentById: {},
  loadingCustomerPaymentById: false,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case RESET_GET_CUSTOMER_PAYMENT_BY_ID:
      return INIT_STATE;

    case GET_CUSTOMER_PAYMENT_BY_ID:
      return {
        ...state,
        loadingCustomerPaymentById: true,
      };

    case GET_CUSTOMER_PAYMENT_BY_ID_SUCCESS:
      return {
        ...state,
        loadingCustomerPaymentById: false,
        customerPaymentById: action.payload,
      };
    // eslint-disable-next-line no-fallthrough
    case GET_CUSTOMER_PAYMENT_BY_ID_FAILURE:
      return {
        ...state,
        loadingCustomerPaymentById: false,
        customerPaymentById: "Error in  getting Club Data",
      };
    default:
      return state;
  }
};
