import { useSelector } from "react-redux";
import LeaveCard from "../Common/LeaveCards";
import PageLoader from "components/common/PageLoader";
import { useEffect, useState } from "react";

const RejectedLeaves = () => {
  const [rejectedLeaveRequests, setRejectedLeaveRequests] = useState([]);
  const leaveRequests = useSelector((state) => state?.TimeoffLeaveRequests?.leaveRequests || []);
  const loadingLeaveRequests = useSelector((state) => state?.TimeoffLeaveRequests?.loadingLeaveRequests);

  useEffect(() => {
    setRejectedLeaveRequests(leaveRequests?.rejected || []);
  }, [leaveRequests]);

  const NoDataCard = (_) => {
    return (
      <div className="no-card-container">
        <p className="no-card-text">No rejected time-off</p>
      </div>
    );
  };

  return (
    <>
      {loadingLeaveRequests && <PageLoader />}
      <div className="pending-leaves-container-emp flex-col">
        <div className="mt-1">
          {rejectedLeaveRequests?.length ? (
            <div className="flex flex-col gap-075">
              {rejectedLeaveRequests.map((leave) => (
                <LeaveCard type={"rejected"} data={leave} />
              ))}
            </div>
          ) : (
            <NoDataCard />
          )}
        </div>
      </div>
    </>
  );
};
export default RejectedLeaves;
