import {
  RESET_REMOVE_PERMISSIONS,
  REMOVE_PERMISSIONS,
  REMOVE_PERMISSIONS_SUCCESS,
  REMOVE_PERMISSIONS_FAILURE,
} from "../../constants/Permissions/RemovePermissions";

// for add employee in admin workforce table

export const ResetRemovePermissions = (data) => {
  return {
    type: RESET_REMOVE_PERMISSIONS,
    payload: data,
  };
};

export const RemovePermissions = (data) => {
  return {
    type: REMOVE_PERMISSIONS,
    payload: data,
  };
};
export const RemovePermissionsSuccess = (payload) => {
  return {
    type: REMOVE_PERMISSIONS_SUCCESS,
    payload,
  };
};
export const RemovePermissionsFailure = (payload) => {
  return {
    type: REMOVE_PERMISSIONS_FAILURE,
    payload,
  };
};
