//employee constants
import { RESET_SIGNUP, SIGNUP, SIGNUP_SUCCESS, SIGNUP_FAILURE } from "../../constants/SignUp/SignUp";
import jwt from "jwt-decode"; // import dependency

const INIT_STATE = {
  signUpIfno: {},
  loadingSignupInfo: false,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case RESET_SIGNUP:
      return INIT_STATE;

    case SIGNUP:
      return {
        ...state,
        loadingSignupInfo: true,
      };
    case SIGNUP_SUCCESS:
      if (action.payload.ok) {
        const user = jwt(action.payload?.payload?.accessToken); // decode your token here
        localStorage.setItem("accessToken", action.payload?.payload?.accessToken);
        localStorage.setItem("loggedInUser", JSON.stringify(user));
        localStorage.setItem("refreshToken", action.payload?.payload?.refreshToken);
        return {
          ...state,
          loadingSignupInfo: false,
          signUpIfno: action.payload,
        };
      } else {
        return {
          ...state,
          loadingSignupInfo: false,
          signUpIfno: action.payload,
        };
      }
    // eslint-disable-next-line no-fallthrough
    case SIGNUP_FAILURE:
      localStorage.removeItem("accessToken");
      return {
        ...state,
        loadingSignupInfo: false,
        signUpIfno: "Error in  getting Customer Data",
      };
    default:
      return state;
  }
};
