import React from "react";

function NdaIcon(props) {
  return (
    <svg
      width="45"
      height="42"
      viewBox="0 0 45 42"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.2279 34.9374C1.08005 31.6068 5.4977 10.6092 18.3463 13.7055C30.8235 16.7123 39.8951 5.82222 44.0748 14.2313C45.7043 17.5096 44.8938 23.1263 35.89 33.0735C28.0381 41.7481 25.6004 38.9327 12.2279 34.9374Z"
        fill="#79A1E8"
      />
      <path
        d="M34.9172 40.9323C36.4378 40.9323 37.6705 40.5665 37.6705 40.1153C37.6705 39.6641 36.4378 39.2983 34.9172 39.2983C33.3966 39.2983 32.1639 39.6641 32.1639 40.1153C32.1639 40.5665 33.3966 40.9323 34.9172 40.9323Z"
        fill="#473CBA"
      />
      <path
        d="M17.4848 41.1389C24.4114 41.1389 30.0266 40.7731 30.0266 40.3219C30.0266 39.8707 24.4114 39.5049 17.4848 39.5049C10.5581 39.5049 4.94292 39.8707 4.94292 40.3219C4.94292 40.7731 10.5581 41.1389 17.4848 41.1389Z"
        fill="#473CBA"
      />
      <path
        d="M35.4725 10.1817L33.6631 13.8324H37.2818L35.4725 10.1817Z"
        fill="#D7ECF9"
      />
      <path
        d="M33.6631 13.8324V32.2216L37.2818 32.2215V13.8324H33.6631Z"
        fill="#F9D015"
      />
      <path
        d="M33.6631 32.2216V33.988C33.6631 34.9993 34.4732 35.8192 35.4724 35.8192C36.4716 35.8192 37.2817 34.9993 37.2817 33.988V32.2216H33.6631Z"
        fill="#7693DD"
      />
      <path
        d="M37.2818 31.5462H33.6631V32.7671H37.2818V31.5462Z"
        fill="#D7ECF9"
      />
      <path
        d="M35.5336 24.407C35.3405 24.407 35.184 24.2486 35.184 24.0532V20.5145C35.184 20.3191 35.3405 20.1606 35.5336 20.1606C35.7267 20.1606 35.8832 20.3191 35.8832 20.5145V24.0532C35.8832 24.2486 35.7267 24.407 35.5336 24.407Z"
        fill="#4B31AA"
      />
      <path
        d="M37.6314 13.8324C37.6314 13.8297 37.6307 13.8273 37.6306 13.8247C37.6302 13.8048 37.6284 13.7849 37.6247 13.7651C37.6243 13.7635 37.6243 13.7617 37.624 13.76C37.62 13.7406 37.6138 13.7214 37.6064 13.7026C37.6044 13.6974 37.6022 13.6923 37.6 13.6872C37.5979 13.6828 37.5965 13.6781 37.5944 13.6738L35.7851 10.023C35.7257 9.90333 35.6048 9.82787 35.4726 9.82787C35.3403 9.82787 35.2195 9.90342 35.1601 10.023L33.3508 13.6738C33.3486 13.6782 33.3471 13.6828 33.3451 13.6873C33.3429 13.6923 33.3408 13.6975 33.3388 13.7026C33.3314 13.7214 33.3252 13.7406 33.3211 13.76C33.3208 13.7617 33.3207 13.7635 33.3204 13.7651C33.3166 13.7849 33.3149 13.8048 33.3145 13.8247C33.3144 13.8273 33.3137 13.8297 33.3137 13.8324L33.3136 31.4413C33.3192 31.4418 33.3248 31.4427 33.3304 31.4433C33.3205 31.4761 33.3135 31.5101 33.3135 31.5462V32.4021V32.767V33.9878C33.3135 35.1927 34.282 36.1729 35.4724 36.1729C36.6628 36.1729 37.6313 35.1927 37.6314 33.9878V32.767V32.3611V31.5462C37.6314 31.5431 37.6305 31.54 37.6305 31.5369C37.6308 31.5369 37.6311 31.5369 37.6314 31.5369V13.8324ZM35.4725 10.9709L36.7153 13.4785H34.2297L35.4725 10.9709ZM36.9322 33.9879C36.9321 34.8025 36.2772 35.4653 35.4724 35.4653C34.6675 35.4653 34.0127 34.8026 34.0127 33.9879V33.1209H36.9322V33.9879ZM36.9322 32.4132H34.0127V31.9001H36.9322V32.4132ZM36.9322 31.1924H35.877C35.8805 31.1722 35.8832 31.1517 35.8832 31.1304V25.8225C35.8832 25.6271 35.7267 25.4686 35.5336 25.4686C35.3405 25.4686 35.184 25.6271 35.184 25.8225V31.1304C35.184 31.1517 35.1866 31.1722 35.1902 31.1924H34.0127L34.0128 14.1862H36.9322V31.1924Z"
        fill="#4B31AA"
      />
      <path
        d="M26.1256 7.17434V1.21507L35.307 1.21499C37.4498 1.21499 39.1869 2.97316 39.1869 5.14191V7.17434H26.1256Z"
        fill="#473CBA"
      />
      <path
        d="M9.09682 4.61474V37.1282H27.1856C29.3283 37.1282 31.0654 35.3701 31.0654 33.2014V5.15854C31.0654 2.98086 32.8097 1.21499 34.9613 1.21499H12.4558C10.6007 1.21499 9.09682 2.73714 9.09682 4.61474Z"
        fill="white"
      />
      <path
        d="M9.09682 37.1282H27.1856C29.3283 37.1282 31.0654 35.3701 31.0654 33.2014V8.91924L9.09682 31.1544V37.1282Z"
        fill="#E1F0FA"
      />
      <path
        d="M25.9396 37.1282H7.73851C5.76305 37.1282 4.16169 35.5073 4.16169 33.508V30.831H22.0437V33.1851C22.0437 35.3628 23.7879 37.1282 25.9396 37.1282Z"
        fill="#7693DD"
      />
      <path
        d="M35.307 0.861122H34.9612H12.4557C10.4638 0.861122 8.8344 2.45891 8.75119 4.45462C8.98448 4.45418 9.21776 4.45276 9.45096 4.44967C9.536 2.84666 10.8511 1.56885 12.4557 1.56885H32.6294C31.4774 2.33815 30.7157 3.6601 30.7157 5.15855V33.2013C30.7157 35.1714 29.1321 36.7742 27.1855 36.7742H25.9395C24.9922 36.7742 24.1017 36.4009 23.4319 35.723C22.7621 35.0451 22.3933 34.1437 22.3933 33.1851V30.831C22.3933 30.6356 22.2367 30.4771 22.0437 30.4771H9.44641V27.5181C9.21269 27.4662 8.97975 27.4092 8.74717 27.349V30.4772H4.1617C3.96862 30.4772 3.81207 30.6357 3.81207 30.8311V33.508C3.81207 35.6994 5.57347 37.4822 7.73851 37.4822H25.9396H27.1856C29.5177 37.4822 31.415 35.5618 31.415 33.2014V7.52811H39.1869C39.38 7.52811 39.5365 7.36967 39.5365 7.17425V5.14191C39.5365 2.78155 37.6392 0.86121 35.307 0.861122ZM7.73851 36.7742C5.95902 36.7742 4.51132 35.309 4.51132 33.5078V31.1848H21.6941V33.185C21.6941 34.3328 22.1357 35.4118 22.9376 36.2235C23.1431 36.4314 23.366 36.6155 23.6029 36.7742H7.73851ZM38.8372 6.82038H31.415V5.15855C31.415 3.1792 33.0058 1.56885 34.9612 1.56885H35.307C37.2536 1.56894 38.8372 3.17177 38.8372 5.14191V6.82038Z"
        fill="#4B31AA"
      />
      <path
        d="M18.7487 27.3341H3.0666V4.64368H18.7487C20.0797 4.64368 21.1587 5.73571 21.1587 7.08287V24.895C21.1587 26.2421 20.0798 27.3341 18.7487 27.3341Z"
        fill="#E1F0FA"
      />
      <path
        d="M16.4762 27.3341H0.79406V4.64368H16.4762C17.8071 4.64368 18.8861 5.73571 18.8861 7.08287V24.895C18.8861 26.2421 17.8072 27.3341 16.4762 27.3341Z"
        fill="#7693DD"
      />
      <path
        d="M1.56688 27.3341H16.4762C17.8071 27.3341 18.8861 26.2421 18.8861 24.8949V9.8047L1.56688 27.3341Z"
        fill="#473CBA"
      />
      <path
        d="M18.7914 10.6388H0.773788V18.0699H18.7914V10.6388Z"
        fill="#F9D015"
      />
      <path
        d="M10.72 18.0699H18.7914V10.6388H18.0621L10.72 18.0699Z"
        fill="#E5B900"
      />
      <path
        d="M13.9545 4.68197H12.148V27.2703H13.9545V4.68197Z"
        fill="#E1F0FA"
      />
      <path
        d="M26.3603 11.0515C28.0257 11.0515 29.3758 9.68507 29.3758 7.99946C29.3758 6.31384 28.0257 4.94737 26.3603 4.94737C24.6949 4.94737 23.3448 6.31384 23.3448 7.99946C23.3448 9.68507 24.6949 11.0515 26.3603 11.0515Z"
        fill="#13CECE"
      />
      <path
        d="M26.3603 11.4054C24.5048 11.4054 22.9952 9.8775 22.9952 7.99946C22.9952 6.12141 24.5048 4.59351 26.3603 4.59351C28.2158 4.59351 29.7254 6.12141 29.7254 7.99946C29.7254 9.8775 28.2157 11.4054 26.3603 11.4054ZM26.3603 5.30124C24.8904 5.30124 23.6944 6.51172 23.6944 7.99946C23.6944 9.48719 24.8904 10.6977 26.3603 10.6977C27.8302 10.6977 29.0262 9.48719 29.0262 7.99946C29.0262 6.51172 27.8302 5.30124 26.3603 5.30124Z"
        fill="#4B31AA"
      />
      <path
        d="M5.44691 29.6884L4.57286 29.2155L3.58229 29.6884V27.3883H5.44691V29.6884Z"
        fill="#E1F0FA"
      />
      <path
        d="M18.7487 4.28981H0.794061C0.600982 4.28981 0.444439 4.44826 0.444439 4.64368V27.3341C0.444439 27.5295 0.600982 27.688 0.794061 27.688H3.23268V29.6885C3.23268 29.8097 3.29395 29.9225 3.39508 29.9873C3.45198 30.0238 3.51701 30.0423 3.5823 30.0423C3.63308 30.0423 3.68404 30.0311 3.73141 30.0084L4.56299 29.6114L5.28199 30.0005C5.39037 30.059 5.52113 30.0559 5.62654 29.9919C5.73195 29.9279 5.79646 29.8128 5.79646 29.6884V27.6879H18.7487C20.2703 27.6879 21.5082 26.4349 21.5082 24.8948V7.08278C21.5083 5.54276 20.2704 4.28981 18.7487 4.28981ZM5.0973 29.098L4.73772 28.9035C4.64017 28.8507 4.52375 28.8477 4.42367 28.8955L3.93192 29.1303V27.7423H5.0973V29.098ZM14.304 4.99754H16.4762C17.6123 4.99754 18.5365 5.93298 18.5365 7.08278V10.2848H14.3041V4.99754H14.304ZM14.304 10.9925H18.4418V17.716H14.304V10.9925ZM11.7984 26.9802H3.55346H1.14368V18.4237H11.7984V26.9802ZM11.7984 17.716H1.14368V10.9925H11.7984V17.716ZM11.7984 10.2848H1.14368V4.99754H4.08646H11.7984V10.2848ZM13.6048 26.9165H12.4977V5.03585H13.6048V26.9165H13.6048ZM16.4762 26.9802H14.304V18.4237H18.5364V24.8948C18.5365 26.0448 17.6123 26.9802 16.4762 26.9802ZM20.8091 24.8949C20.8091 26.0448 19.8848 26.9802 18.7487 26.9802H18.3094C18.8772 26.4683 19.2358 25.7233 19.2358 24.8949V7.08278C19.2358 6.25438 18.8772 5.5095 18.3094 4.99754H18.7487C19.8848 4.99754 20.8091 5.93298 20.8091 7.08278V24.8949Z"
        fill="#4B31AA"
      />
      <path
        d="M26.3603 19.6266C28.0257 19.6266 29.3758 18.2601 29.3758 16.5745C29.3758 14.8889 28.0257 13.5224 26.3603 13.5224C24.6949 13.5224 23.3448 14.8889 23.3448 16.5745C23.3448 18.2601 24.6949 19.6266 26.3603 19.6266Z"
        fill="#13CECE"
      />
      <path
        d="M26.3603 19.9804C24.5048 19.9804 22.9952 18.4525 22.9952 16.5745C22.9952 14.6964 24.5048 13.1685 26.3603 13.1685C28.2158 13.1685 29.7254 14.6964 29.7254 16.5745C29.7254 18.4525 28.2157 19.9804 26.3603 19.9804ZM26.3603 13.8763C24.8904 13.8763 23.6944 15.0868 23.6944 16.5745C23.6944 18.0622 24.8904 19.2727 26.3603 19.2727C27.8302 19.2727 29.0262 18.0622 29.0262 16.5745C29.0262 15.0868 27.8302 13.8763 26.3603 13.8763Z"
        fill="#4B31AA"
      />
      <path
        d="M25.8174 9.51931C25.728 9.51931 25.6384 9.48472 25.5702 9.41563L24.6674 8.50186C24.5308 8.36367 24.5308 8.13959 24.6674 8.00149C24.8039 7.86322 25.0254 7.86322 25.1618 8.00149L25.8174 8.66499L27.6338 6.82666C27.7702 6.68839 27.9918 6.68839 28.1281 6.82666C28.2648 6.96485 28.2648 7.18893 28.1281 7.32703L26.0646 9.41563C25.9964 9.48472 25.9068 9.51931 25.8174 9.51931Z"
        fill="#4B31AA"
      />
      <path
        d="M26.3603 27.9866C28.0257 27.9866 29.3758 26.6202 29.3758 24.9346C29.3758 23.2489 28.0257 21.8825 26.3603 21.8825C24.6949 21.8825 23.3448 23.2489 23.3448 24.9346C23.3448 26.6202 24.6949 27.9866 26.3603 27.9866Z"
        fill="#13CECE"
      />
      <path
        d="M26.3603 28.3405C24.5048 28.3405 22.9952 26.8126 22.9952 24.9346C22.9952 23.0565 24.5048 21.5286 26.3603 21.5286C28.2158 21.5286 29.7254 23.0565 29.7254 24.9346C29.7254 26.8126 28.2157 28.3405 26.3603 28.3405ZM26.3603 22.2363C24.8904 22.2363 23.6944 23.4468 23.6944 24.9346C23.6944 26.4223 24.8904 27.6328 26.3603 27.6328C27.8302 27.6328 29.0262 26.4223 29.0262 24.9346C29.0262 23.4468 27.8302 22.2363 26.3603 22.2363Z"
        fill="#4B31AA"
      />
      <path
        d="M25.8174 18.0943C25.728 18.0943 25.6384 18.0597 25.5702 17.9906L24.6674 17.0769C24.5308 16.9387 24.5308 16.7146 24.6674 16.5765C24.8039 16.4382 25.0254 16.4382 25.1618 16.5765L25.8174 17.24L27.6338 15.4017C27.7702 15.2634 27.9918 15.2634 28.1281 15.4017C28.2648 15.5399 28.2648 15.764 28.1281 15.902L26.0646 17.9906C25.9964 18.0597 25.9068 18.0943 25.8174 18.0943Z"
        fill="#4B31AA"
      />
      <path
        d="M25.8174 26.4544C25.728 26.4544 25.6384 26.4198 25.5702 26.3507L24.6674 25.4369C24.5308 25.2988 24.5308 25.0747 24.6674 24.9366C24.8039 24.7983 25.0254 24.7983 25.1618 24.9366L25.8174 25.6001L27.6338 23.7617C27.7702 23.6235 27.9918 23.6235 28.1281 23.7617C28.2648 23.8999 28.2648 24.124 28.1281 24.2621L26.0646 26.3507C25.9964 26.4198 25.9068 26.4544 25.8174 26.4544Z"
        fill="#4B31AA"
      />
    </svg>
  );
}

export default NdaIcon;
