import React from "react";

const CrossIcon = ({ fill = "black", className = "", onClick = () => {} }) => {
  return (
    <svg
      className={className}
      onClick={onClick}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.758 17.2428L12.001 11.9998L17.244 17.2428M17.244 6.75684L12 11.9998L6.758 6.75684"
        stroke={fill}
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default CrossIcon;
