import { call, put, takeEvery } from "redux-saga/effects";
//actions
import { AddUserSuccess, AddUserFailure } from "../../actions/Settings/AddUser";
//constant
import { ADD_USER } from "../../constants/Settings/AddUser";
//request type
import postRequest from "../../../util/APIHelperPost";
import { baseUrl } from "../../../util/APIBaseUrl";

export const fetchAddUserRequest = async (actions) => {
  // eslint-disable-next-line no-return-await
  let body = JSON.stringify({
    customerId: actions?.payload?.customerId,
    email: actions.payload?.userData?.email,
    firstName: actions.payload?.userData?.firstName,
    lastName: actions.payload?.userData?.lastName,
    phoneNumber: actions.payload?.userData?.phoneNumber,
    password: actions.payload?.userData?.password,

    country: actions.payload?.userData?.country?.value,
    departmentId: actions.payload?.userData?.department?.key,
  });

  return await postRequest(`${baseUrl}/${actions.payload.request}`, body, true, actions.payload.method)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

function* fetchAddUser(params) {
  try {
    let response = yield call(fetchAddUserRequest, params);
    yield put(AddUserSuccess(response));
  } catch (error) {
    console.error(error);
    yield put(AddUserFailure(error));
  }
}

export default function* rootSaga() {
  yield takeEvery(ADD_USER, fetchAddUser);
}
