import { call, put, takeEvery } from "redux-saga/effects";
import { setStatus, fetchStatusFailed } from "../../actions/Payoneer/PayoneerGetEmployeeStatus";
import { PAYONEER_GET_EMPLOYEE_STATUS } from "store/constants/Payoneer/PayoneerGetEmployeeStatus";
import { baseUrl } from "util/APIBaseUrl";
import getRequest from "util/APIHelperGet";

const fetchStatusFromAPI = async () => {
  return await getRequest(`${baseUrl}/payoneer/payee-status`);
};

function* fetchStatussSaga() {
  const statusInfo = yield call(fetchStatusFromAPI);
  if (statusInfo?.ok) {
    yield put(setStatus(statusInfo?.payload));
  } else {
    yield put(fetchStatusFailed(statusInfo?.payload?.error[0]));
  }
}

export default function* rootSaga() {
  yield takeEvery(PAYONEER_GET_EMPLOYEE_STATUS, fetchStatussSaga);
}
