import React from "react";

function SortArrowIcon(props) {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M9.74998 4.06045V16.5H8.24998V4.06045L3.53023 8.7802L2.46973 7.7197L8.99998 1.18945L15.5302 7.7197L14.4697 8.7802L9.74998 4.06045Z"
        fill="black"
      />
    </svg>
  );
}

export default SortArrowIcon;
