//employee constants
import {
  RESET_MANAGE_USER,
  MANAGE_USER,
  MANAGE_USER_SUCCESS,
  MANAGE_USER_FAILURE,
} from "../../constants/Settings/ManageUser";

const INIT_STATE = {
  manageUser: {},
  loadingManageUser: false,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case RESET_MANAGE_USER:
      return INIT_STATE;

    case MANAGE_USER:
      return {
        ...state,
        loadingManageUser: true,
      };
    case MANAGE_USER_SUCCESS:
      return {
        ...state,
        loadingManageUser: false,
        manageUser: action.payload,
      };
    // eslint-disable-next-line no-fallthrough
    case MANAGE_USER_FAILURE:
      return {
        ...state,
        loadingManageUser: false,
        manageUser: "Error in  getting Club Data",
      };
    default:
      return state;
  }
};
