import { useState, useEffect } from "react";
import { Helmet } from "react-helmet";

import FeedbackModal from "./FeedbackModal";

const FeedbackScreen = () => {
  const [isModalOpen, setModalOpen] = useState(false);

  useEffect(() => {
    setModalOpen(true);
  }, []);

  const handleModalClose = () => {
    setModalOpen(false);
  };

  return (
    <>
      <Helmet>
        <title>Edge | Feedback</title>
      </Helmet>
      <div>
        <FeedbackModal open={isModalOpen} onClose={handleModalClose} />
      </div>
    </>
  );
};

export default FeedbackScreen;
