import React from "react";

import CircleCross from "../../../assets/images/circle-x.svg";
import CircleCheck from "../../../assets/images/circle-checked.svg";

const ErrorsIndicatorList = ({ errors }) => {
  return (
    <div
      style={{
        borderRadius: "12px",
        border: "1px solid #F1EFFF",
        background: "#fff8f8",
        margin: "18px 0",
        padding: "1rem",
        display: "flex",
        flexDirection: "column",
        gap: "8px",
      }}
    >
      {errors.map((err) => (
        <span
          style={{
            display: "flex",
            gap: "8px",
            // marginLeft: "1rem",
          }}
        >
          <img
            style={{ alignSelf: "flex-start" }}
            src={err?.isChecked ? CircleCheck : CircleCross}
            alt="icon"
          />
          <div
            style={{
              fontFamily: "Poppins-Regular",
              fontSize: "13px",
              fontStyle: "normal",
              fontWeight: 400,
              color: "#5E5D67",
            }}
          >
            {err.message}
          </div>
        </span>
      ))}
    </div>
  );
};

export default ErrorsIndicatorList;
