import { GET_UPCOMING_LEAVES_FAILURE, GET_UPCOMING_LEAVES_SUCCESS, UPCOMING_LEAVES } from "store/constants/Timeoff";

const INIT_STATE = {
  upcomingLeaves: [],
  loadingLeavesRequest: true,
  LeavesRequestError: "",
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case UPCOMING_LEAVES:
      return {
        ...state,
        loadingLeavesRequest: true,
      };
    case GET_UPCOMING_LEAVES_SUCCESS:
      return {
        ...state,
        loadingLeavesRequest: false,
        upcomingLeaves: action.payload?.payload?.data,
      };
    case GET_UPCOMING_LEAVES_FAILURE:
      return {
        ...state,
        loadingLeavesRequest: false,
        LeavesRequestError: "Error in  getting Leave requests",
      };
    default:
      return state;
  }
};
