import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";

import PageLoader from "../../../common/PageLoader";
import Document from "components/common/Document";
import { AlertType } from "components/common/Snackbar";
import DocumentList from "./DocumentList/DocumentList";
import NoDocumentScreen from "./NoDocumentScreen/NoDocumentScreen";

import { showSnackbar } from "store/actions/Utility";
import { getEmployeeDocumentCount } from "services/BambooHrTabs/documents";

import "./Document.scss";

function EmployeeDocs({ noPadding = false }) {
  const dispatch = useDispatch();
  const params = useParams();

  const [isLoading, setIsLoading] = React.useState(false);
  const [folders, setFolders] = React.useState([]);
  const [folderName, setFolderName] = React.useState();
  const [folderDetailScreen, setFolderDetailScreen] = React.useState(false);

  const loggedInUser = JSON.parse(localStorage.getItem("loggedInUser"));

  const loggedInUserType = loggedInUser?.type;
  const employeeId = loggedInUserType === "employee" ? loggedInUser?.userId : params?.id;

  const getEmployeeDocument = async () => {
    try {
      setIsLoading(true);
      const count = await getEmployeeDocumentCount(employeeId);
      if (loggedInUserType == "edge") {
        setFolders(count);
      } else {
        setFolders(count.filter((folder) => folder.count > 0));
      }
    } catch (error) {
      dispatch(
        showSnackbar({
          type: AlertType.error,
          message: error?.message,
          dispatch,
        })
      );
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getEmployeeDocument();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, employeeId]);

  const handleBackClicked = () => {
    setFolderDetailScreen(false);
    setFolderName();
  };

  return (
    <div className={!noPadding && "pt-175 pb-15 documents-container"}>
      {isLoading && <PageLoader />}
      <div className="flex">
        {folders.length < 1 && loggedInUserType !== "edge" ? (
          <div className="w-full">
            <NoDocumentScreen />
          </div>
        ) : (
          // eslint-disable-next-line react/jsx-no-useless-fragment
          <>
            {folderDetailScreen ? (
              <DocumentList
                noPadding={noPadding}
                loggedInUserType={loggedInUserType}
                documentType={folderName}
                employeeId={employeeId}
                onBackClicked={() => {
                  handleBackClicked();
                  getEmployeeDocument();
                }}
              />
            ) : (
              <div className={!noPadding && "pl-2"}>
                <p className="heading-h4-semibold">Documents</p>
                <div className="flex flex-wrap">
                  {folders?.map((folder) => (
                    <div
                      key={folder.Document_Type}
                      className="mt-1 mr-1"
                      onClick={() => {
                        setFolderName(folder?.Document_Type);
                        setFolderDetailScreen(true);
                      }}
                    >
                      <Document
                        title={folder?.Document_Type}
                        primaryInfo={`${folder?.count} ${folder?.count <= 1 ? "document" : "documents"}`}
                      />
                    </div>
                  ))}
                </div>
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
}

export default EmployeeDocs;
