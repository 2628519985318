import React from "react";
import { CardMedia } from "@mui/material";

import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

function LeaveItem({ icon, type, noOfLeaves, dateFrom, dateTo, image, isHoliday }) {
  const formatLeaveCount = (numOfLeaves) => {
    if (numOfLeaves === 1) {
      return isHoliday ? "day" : "leave";
    }
    return isHoliday ? "days" : "leaves";
  };

  return (
    <div className="flex items-center space-x-075 w-full border rounded-xl px-1 py-05">
      {image ? (
        <CardMedia
          component="img"
          image={image}
          alt="load.."
          sx={{
            width: "56px",
            height: "56px",
            borderRadius: "12px",
          }}
        />
      ) : (
        <div className="rounded-lg color-surface-secondary h-35 w-35 flex">
          {icon && <span className="h-15 w-15 m-1 color-icon-information">{icon}</span>}
        </div>
      )}
      <div>
        <div className="para-body-xs-regular color-text-highlight">
          {type} {!isHoliday && "leaves"}
        </div>
        <div className="flex items-center space-x-1">
          <div className="para-body-s-semibold">{dateFrom}</div>
          {dateTo && <ArrowForwardIcon fontSize="sm" />}
          <div className="para-body-s-semibold">{dateTo}</div>
        </div>
        <div className="flex items-center space-x-1 pt-025">
          <p className="para-body-s-regular">Total {isHoliday ? "holidays" : "time off used"}</p>
          <div className="border px-05 rounded-2xl">
            <p className="para-body-s-medium">
              {noOfLeaves} {formatLeaveCount(noOfLeaves)}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LeaveItem;
