import { call, put, takeEvery } from "redux-saga/effects";
//actions
import {
  ImportEmployeesSuccess,
  ImportEmployeesFailure,
} from "../../../actions/Workforce/ImportEmployees";
//constant
import { IMPORT_EMPLOYEES } from "../../../constants/Workforce/ImportEmployees";
//request type
import { baseUrl } from "../../../../util/APIBaseUrl";
import postRequest from "../../../../util/APIHelperPost";

export const fetchImportEmployeesInfoRequest = async (actions) => {
  const formData = new FormData();
  formData.append("file", actions?.payload?.file);

  let body = JSON.stringify({
    file: actions?.payload?.file,
  });

  const data = new URLSearchParams(formData);

  let contentType = "multipart/form-data";

  return await postRequest(
    `${baseUrl}/${actions.payload.request}`,

    formData,
    true,
    "POST",
    "multipart/form-data",
    true
  )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

function* fetchImportEmployeesInfo(params) {
  try {
    let response = yield call(fetchImportEmployeesInfoRequest, params);
    yield put(ImportEmployeesSuccess(response));
  } catch (error) {
    console.error(error);
    yield put(ImportEmployeesFailure(error));
  }
}

export default function* rootSaga() {
  yield takeEvery(IMPORT_EMPLOYEES, fetchImportEmployeesInfo);
}
