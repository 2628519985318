//employee constants
import {
  RESET_CALCULATE_EMPLOYEE_SALARY,
  CALCULATE_EMPLOYEE_SALARY,
  CALCULATE_EMPLOYEE_SALARY_SUCCESS,
  CALCULATE_EMPLOYEE_SALARY_FAILURE,
} from "../../../constants/Workforce/AddEmployee/CalculateEmployeeSalary";

const INIT_STATE = {
  calculateEmployeeSalary: {},
  loadingCalculateEmployeeSalary: false,
};

export default (state = INIT_STATE, action) => {
  //
  switch (action.type) {
    case RESET_CALCULATE_EMPLOYEE_SALARY:
      return INIT_STATE;
    case CALCULATE_EMPLOYEE_SALARY:
      return {
        ...state,
        loadingCalculateEmployeeSalary: true,
      };
    case CALCULATE_EMPLOYEE_SALARY_SUCCESS:
      return {
        ...state,
        loadingCalculateEmployeeSalary: false,
        calculateEmployeeSalary: action.payload,
      };
    // eslint-disable-next-line no-fallthrough
    case CALCULATE_EMPLOYEE_SALARY_FAILURE:
      return {
        ...state,
        loadingCalculateEmployeeSalary: false,
        calculateEmployeeSalary: "Error in  getting Club Data",
      };
    default:
      return state;
  }
};
