import React from "react";
import "./Button.css";

function Button({
  type = "button",
  variant,
  size,
  style,
  leadIcon,
  tailIcon,
  children,
  onClick,
  disabled,
  className = "",
}) {
  return (
    <button
      // eslint-disable-next-line react/button-has-type
      type={type}
      className={`${variant} ${size} ${disabled ? "disabled" : ""} ${className}`}
      style={style}
      onClick={onClick}
      disabled={disabled}
    >
      {leadIcon && <div>{leadIcon}</div>}
      {children}
      {tailIcon && <div>{tailIcon}</div>}
    </button>
  );
}

export default Button;
