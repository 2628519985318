import { call, put, takeEvery } from "redux-saga/effects";
//actions
import {
  UserAdditionalInfoSuccess,
  UserAdditionalInfoFailure,
} from "../../actions/UserAdditionalInfo";
//constant
import { USER_ADDITIONAL_INFO } from "../../constants/UserAdditionalInfo";
//request type
import getRequest from "../../../util/APIHelperGet";
import { baseUrl } from "../../../util/APIBaseUrl";

export const fetchUserAdditionalInfoRequest = async (actions) => {
  // eslint-disable-next-line no-return-await

  return await getRequest(`${baseUrl}/${actions.payload}`)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

function* fetchUserAdditionalInfo(params) {
  try {
    let response = yield call(fetchUserAdditionalInfoRequest, params);
    yield put(UserAdditionalInfoSuccess(response));
  } catch (error) {
    console.error(error);
    yield put(UserAdditionalInfoFailure(error));
  }
}

export default function* rootSaga() {
  yield takeEvery(USER_ADDITIONAL_INFO, fetchUserAdditionalInfo);
}
