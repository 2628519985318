import { call, put, takeEvery } from "redux-saga/effects";
import { saveAccessTokenSuccess, saveAccessTokenFailed } from "../../actions/Payoneer/PayoneerSaveAccessToken";
import { baseUrl } from "util/APIBaseUrl";
import { PAYONEER_SAVE_ACCESS_TOKEN_FETCH } from "store/constants/Payoneer/PayoneerSaveAccessToken";
import postRequest from "util/APIHelperPost";
import { fetchBalance } from "store/actions/Payoneer/PayoneerGetEmployeeBalance";

const saveAccessTokenFromAPI = async () => {
  let code = localStorage.getItem("payoneerCode");
  let body = {
    code,
  };
  body = JSON.stringify(body);
  const request = `${baseUrl}/payoneer/payee-access-token/`;
  return await postRequest(request, body)
    .then((response) => response)
    .catch((error) => error);
};

function* saveAccessTokenSaga() {
  const saveAccessTokenInfo = yield call(saveAccessTokenFromAPI);
  if (saveAccessTokenInfo?.ok) {
    yield put(saveAccessTokenSuccess());
    yield put(fetchBalance());
    localStorage.removeItem("payoneerCode");
  } else {
    yield put(saveAccessTokenFailed(saveAccessTokenInfo?.payload?.error[0]));
    localStorage.removeItem("payoneerCode");
  }
}

export default function* rootSaga() {
  yield takeEvery(PAYONEER_SAVE_ACCESS_TOKEN_FETCH, saveAccessTokenSaga);
}
