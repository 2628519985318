import React from "react";
//material ui
import { Alert, Button, Grid, Paper, Snackbar, Toolbar, Typography, List, ListItem } from "@mui/material";
//widget
import Header from "../../../Header/Header";
//widgets
import FormWidget from "../../../Widgets/FormWidget";
//redux dispatch
import { useSelector, useDispatch } from "react-redux";
//router
import { useNavigate, useLocation } from "react-router-dom";
//api base url
import { baseUrl } from "../../../../util/APIBaseUrl";
//redux actions
import { AddCustomer, ResetAddCustomer } from "../../../../store/actions/Customers/AddCustomer/AddCustomer";
import {
  AssignCustomertoEmployee,
  ResetAssignCustomertoEmployee,
} from "../../../../store/actions/Customers/AssignCustomertoEmployee";
import { GetUsersInfo } from "../../../../store/actions/GetUsersInfo";
import { DropdownData } from "../../../../store/actions/DropdownData";
import { GetCustomerDetails } from "../../../../store/actions/Customers/GetCustomerDetails";
import { usePermissions } from "../../../Hook";
import { getDateFromUtcFormat } from "../../CommonUtils";
import PageLoader from "../../../common/PageLoader";

//constant
const customer = {
  companyName: "",
  email: "",
  industry: "",
  contactPerson: "",
  contactNumber: "",
  country: { key: null, value: "None" },
  companyAddress: "",
  state: "",
  city: "",
  code: "",
  imageBlob: "",
  employee: { key: null, value: "None" },
  customerJoiningDate: new Date(),
};

function index(props) {
  const dispatch = useDispatch();
  const { state } = useLocation();
  const { navigatedFor, editCustomerId, redirectTo } = state;
  const navigate = useNavigate();

  //component state
  const [nextButtonClick, setNextButtonClick] = React.useState(false);
  const [customerData, setCustomerData] = React.useState(customer);
  const [openAlert, setOpenAlert] = React.useState(false);
  const [requiredFiledMissing, setRequiredFiledMissing] = React.useState(false);
  const [dropdownData_, setDropdownData_] = React.useState({});
  const [edgeEmployeeDropdownOptions, setEdgeEmployeeDropdownOptions] = React.useState([]);

  //custom hook
  const { loggedInUser } = usePermissions();

  //redux state
  const getUsersInfo = useSelector((state) => state.GetUsers.getUsersInfo);
  const loadingGetUsersInfo = useSelector((state) => state.GetUsers.loadingGetUsersInfo);

  const addCustomerInfo = useSelector((state) => state.AddCustomer.addCustomerInfo);
  const loadingAddCustomerInfo = useSelector((state) => state.AddCustomer.loadingAddCustomerInfo);

  const assignCustomerToEmployee = useSelector((state) => state.AssignCustomertoEmployee.assignCustomerToEmployee);
  const loadingAssignCustomerToEmployee = useSelector(
    (state) => state.AssignCustomertoEmployee.loadingAssignCustomerToEmployee
  );

  const addCustomerData = useSelector((state) => state.AddCustomerData.addCustomerData);

  const customerDetails = useSelector((state) => state.GetCustomerDetails.customerDetails);
  const loadingCustomerDetails = useSelector((state) => state.GetCustomerDetails.loadingCustomerDetails);

  //functions

  const getValue = (value) => {
    if (value != undefined && value != null) {
      return value;
    } else {
      return "";
    }
  };

  const postLogo = async (actions) => {
    const response = await fetch(`${baseUrl}/${actions.request}`, {
      method: "POST",
      headers: {},
      body: actions.formData,
    });
    if (!response.ok) {
      return;
    }
    // window.location.reload();
    const data = await response.json();
  };

  const handleAlertClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenAlert(false);
  };

  const selectProfilePicture = (imageBlob, formData) => {
    setCustomerData({
      ...customerData,
      imageBlob: imageBlob,
      loadFromUrl: false,
      formData: formData,
    });
  };

  const callbackFromDatePicker = (targetName, newValue) => {
    setCustomerData({
      ...customerData,
      [targetName]: newValue,
    });
  };

  const handleCustomerData = (event, fieldName, fieldType) => {
    if (fieldType === "number") {
      const onlyNums = event.target.value.replace(/[^0-9\+]/g, "");

      setCustomerData({
        ...customerData,
        [event.target.name]: onlyNums,
      });
    } else {
      setCustomerData({
        ...customerData,
        [event.target.name]: event.target.value,
      });
    }
  };

  const callbackFromMultiSelect = (option, key) => {
    customerData[key] = option;
    setCustomerData({
      ...customerData,
    });
  };

  const handleCreateCustomer = (event) => {
    let logoRequest = "";

    if (
      customerData.companyName != "" &&
      customerData.contactPerson != "" &&
      customerData.email &&
      customerData.companyAddress &&
      customerData?.country?.key != null &&
      customerData?.employee?.key != null
    ) {
      setRequiredFiledMissing(false);
      // dispatch(AddCustomer(customerData));
      if (navigatedFor == "addCustomer") {
        dispatch(
          AddCustomer({
            customerData: customerData,

            method: "POST",
            request: "customers",
          })
        );
      } else if (navigatedFor == "editCustomer") {
        logoRequest = `customer/${customerDetails?.payload.customerId}/logo`;
        if (customerData?.formData) {
          postLogo({
            request: logoRequest,
            formData: customerData?.formData,
          });
        }
        dispatch(
          AddCustomer({
            customerData: customerData,
            customerId: customerDetails?.payload.customerId,
            method: "PATCH",
            request: `customer/${customerDetails?.payload.customerId}`,
          })
        );
      }
    } else {
      setRequiredFiledMissing(true);
      setNextButtonClick(true);
      setOpenAlert(true);
    }
  };

  const getError = (errors) => {
    if (errors != null && errors != undefined) {
      return errors.map((error, index) => {
        return (
          <List
            sx={{
              listStyleType: "disc",
              paddingRight: "0px !important",
              pl: 3,
              "& .MuiListItem-root": {
                paddingRight: "0px",
                paddingLeft: "0px",
                paddingTop: "0px",
                paddingBottom: "0px",
              },
            }}
          >
            <ListItem
              sx={{
                display: "list-item",
              }}
            >
              <Typography variant="h6" color="secondary" fontSize="12px">
                {(error?.msg ? error.msg : error?.description ? error.description : "") +
                  " " +
                  (error?.param ? error?.param : "")}
              </Typography>
            </ListItem>
          </List>
        );
      });
    } else {
      return null;
    }
  };

  //methods
  React.useEffect(() => {
    let dropdownData = {};
    let countrySelected = [];
    dropdownData = JSON.parse(sessionStorage.getItem("dropdownData"));

    if (navigatedFor != null && navigatedFor != undefined && navigatedFor == "editCustomer" && customerDetails?.ok) {
      countrySelected = dropdownData?.payload?.country.filter(
        (country) => country?.countryCode == customerDetails?.payload?.country
      );
      setCustomerData({
        companyName: getValue(customerDetails?.payload?.companyName),
        email: getValue(customerDetails?.payload?.contactEmail),
        industry: getValue(customerDetails?.payload?.industry),
        contactPerson: getValue(customerDetails?.payload?.contactPerson),
        contactNumber: getValue(customerDetails?.payload?.contactNumber),
        country: {
          key: countrySelected && countrySelected.length ? countrySelected && countrySelected[0]?.countryCode2 : null,
          value: countrySelected && countrySelected.length ? countrySelected[0]?.countryCode : null,
        },
        companyAddress: getValue(customerDetails?.payload?.addressLine1),
        customerJoiningDate: getDateFromUtcFormat(customerDetails?.payload?.onBoardingDate),
        state: getValue(customerDetails?.payload?.state),
        city: getValue(customerDetails?.payload?.city),
        code: getValue(customerDetails?.payload?.zip),
        imageBlob: `${baseUrl}/customer/${customerDetails?.payload?.customerId}/logo?id=${customerDetails?.payload?.pictureVersion}`,
        loadFromUrl: true,
        employee: {
          key: getValue(customerDetails?.payload?.edgeEmployee?.Edge_Employee_ID),
          value:
            getValue(customerDetails?.payload?.edgeEmployee?.First_Name) +
            " " +
            getValue(customerDetails?.payload?.edgeEmployee?.Last_Name),
        },
      });
    }
  }, [customerDetails]);

  React.useEffect(() => {
    let logoRequest;

    if (addCustomerInfo != null && addCustomerInfo != undefined && Object.keys(addCustomerInfo).length) {
      if (addCustomerInfo?.ok) {
        if (navigatedFor == "addCustomer") {
          setNextButtonClick(false);
          setOpenAlert(false);

          if (loggedInUser) {
            logoRequest = `customer/${addCustomerInfo?.payload.customerId}/logo`;
          }
          if (customerData?.formData) {
            postLogo({
              request: logoRequest,
              formData: customerData?.formData,
            });
          }

          dispatch(
            AssignCustomertoEmployee({
              edgeEmployeeId: customerData?.employee?.key,
              customerId: addCustomerInfo.payload.customerId,
            })
          );

          dispatch(ResetAddCustomer({}));
          // }
        } else if (navigatedFor == "editCustomer") {
          if (customerDetails?.payload?.customerId) {
            setNextButtonClick(false);
            setOpenAlert(false);

            dispatch(
              AssignCustomertoEmployee({
                edgeEmployeeId: customerData?.employee?.key,
                customerId: customerDetails?.payload?.customerId,
              })
            );

            dispatch(ResetAddCustomer({}));
          }
        }
      } else {
        setNextButtonClick(true);
        setOpenAlert(true);
      }
    }
    // }
  }, [addCustomerInfo]);

  React.useEffect(() => {
    if (
      assignCustomerToEmployee != null &&
      assignCustomerToEmployee != undefined &&
      Object.keys(assignCustomerToEmployee).length
    ) {
      if (assignCustomerToEmployee?.ok) {
        dispatch(DropdownData());
        dispatch(ResetAssignCustomertoEmployee({}));
        // navigate("/main/customers");
        if (redirectTo == "customers") {
          navigate("/main/customers");
        } else if (redirectTo == "details") {
          // navigate(`/main/details/${editEmployeeId}`);
          navigate(`/main/customer/details/${editCustomerId}`, {
            state: {
              redirectFrom: "editCustomer",
            },
          });
        }
      }
    }
  }, [assignCustomerToEmployee]);

  React.useEffect(() => {
    let edgeEmployeeDropdown = [];

    if (Object.keys(getUsersInfo).length && getUsersInfo.ok) {
      getUsersInfo?.payload?.rows?.map((employee, index) => {
        edgeEmployeeDropdown.push({
          key: getValue(employee?.userId),
          value: getValue(employee?.firstName) + " " + getValue(employee?.lastName),
        });
      });

      setEdgeEmployeeDropdownOptions(edgeEmployeeDropdown);
    }
  }, [getUsersInfo]);

  React.useEffect(() => {
    let countries = [];
    let dropdownData = {};
    let countrySelected = [];

    dropdownData = JSON.parse(sessionStorage.getItem("dropdownData"));
    dispatch(
      GetUsersInfo({
        permissions: loggedInUser?.permissions,
        limit: 50,
      })
    );

    setNextButtonClick(false);
    setOpenAlert(false);

    if (
      navigatedFor == "editCustomer" &&
      editCustomerId != null &&
      editCustomerId != undefined &&
      !Object.keys(customerDetails).length
    ) {
      dispatch(
        GetCustomerDetails({
          customerId: editCustomerId,
        })
      );
    }

    if (dropdownData != undefined && Object.keys(dropdownData).length && dropdownData.ok) {
      dropdownData?.payload?.country?.map((data, index) => {
        countries.push({
          key: data.countryCode2,
          value: data.countryCode,
        });
      });

      setDropdownData_({
        ...dropdownData_,
        countries: countries,
      });
    }
  }, []);

  return (
    <>
      {(loadingAddCustomerInfo || loadingAssignCustomerToEmployee || loadingCustomerDetails || loadingGetUsersInfo) && (
        <PageLoader />
      )}
      <Grid container direction="column" rowSpacing={3}>
        <Header
          data={{
            heading: navigatedFor == "editCustomer" ? "Edit Customer" : "Add New Customer",
            subHeader: null,
            profileWithIcon: null,
          }}
        />

        <Grid item>
          <Paper sx={{ p: 3 }}>
            <Toolbar />
            <Grid
              item
              container
              direction="row"
              sx={{
                minHeight: "calc(54vh)",
                height: "100%",
                overflowY: "auto",
              }}
            >
              <FormWidget
                nextButtonClick={nextButtonClick}
                formData={customerData}
                handleDataChange={handleCustomerData}
                selectProfilePicture={selectProfilePicture}
                callbackFromMultiSelect={callbackFromMultiSelect}
                callbackFromDatePicker={callbackFromDatePicker}
                data={[
                  {
                    name: "companyName",
                    label: "COMPANY NAME *",
                    type: "text",
                    maxLength: 100,
                    lg: 4,
                    required: true,
                  },
                  {
                    name: "email",
                    label: "EMAIL ADDRESS *",
                    type: "text",
                    lg: 4,
                    required: true,
                  },
                  {
                    name: "industry",
                    label: "INDUSTRY",
                    type: "text",
                    lg: 4,
                  },
                  {
                    name: "customerJoiningDate",
                    label: "Customer Joining Date",
                    type: "datePicker",
                    lg: 4,
                    disableFuture: false,
                    required: false,
                  },
                  {
                    name: "contactPerson",
                    label: "CONTACT PERSON *",
                    type: "text",
                    maxLength: 70,
                    lg: 4,
                    required: true,
                  },
                  {
                    name: "contactNumber",
                    label: "CONTACT NUMBER",
                    type: "number",
                    maxLength: 20,
                    lg: 4,
                  },
                  {
                    name: "country",
                    label: "COUNTRY *",
                    type: "dropdown",
                    lg: 4,
                    required: true,
                    options: dropdownData_.countries,
                  },
                  {
                    name: "companyAddress",
                    label: "COMPANY ADDRESS *",
                    type: "text",
                    maxLength: 100,
                    lg: 12,
                    required: true,
                  },

                  {
                    name: "city",
                    label: "CITY",
                    type: "text",
                    lg: 4,
                  },
                  {
                    name: "state",
                    label: "STATE",
                    type: "text",
                    lg: 4,
                  },
                  {
                    name: "code",
                    label: "ZIP",
                    type: "text",
                    lg: 4,
                  },
                  {
                    name: "employee",
                    label: "ACCOUNT MANAGER *",
                    type: "dropdown",
                    lg: 4,
                    options: edgeEmployeeDropdownOptions,
                    required: true,
                  },
                ]}
              />
            </Grid>
            <Toolbar variant="dense" />
            <Grid item container direction="row" columnSpacing={3} justifyContent="flex-end">
              <Grid item textAlign="center">
                <Snackbar
                  open={openAlert}
                  autoHideDuration={2500}
                  onClose={handleAlertClose}
                  sx={{
                    "&.MuiSnackbar-root": {
                      position: "relative",
                      bottom: 9,
                      left: 0,
                    },
                  }}
                >
                  <Alert onClose={handleAlertClose} severity="error">
                    {addCustomerInfo?.ok == false
                      ? getError(addCustomerInfo?.payload?.error)
                      : requiredFiledMissing
                      ? " REQUIRED FIELD MISSING!"
                      : null}
                  </Alert>
                </Snackbar>
              </Grid>

              <Grid item>
                <Button
                  variant="text"
                  fullWidth
                  sx={{
                    width: "70px",
                    height: "38px",
                    borderRadius: 1,

                    p: 1,
                  }}
                  onClick={() => {
                    if (redirectTo == "customers") {
                      navigate("/main/customers");
                    } else if (redirectTo == "details") {
                      navigate(`/main/customer/details/${editCustomerId}`, {
                        state: {
                          redirectFrom: "editCustomer",
                        },
                      });
                    }
                  }}
                >
                  <Typography variant="h6">Cancel</Typography>
                </Button>
              </Grid>

              <Grid item>
                <Button
                  variant="contained"
                  fullWidth
                  disableElevation
                  sx={{
                    width: "170px",
                    height: "38px",
                    borderRadius: 1,

                    p: 1,
                  }}
                  onClick={handleCreateCustomer}
                >
                  <Typography variant="h6" color="white">
                    {navigatedFor == "editCustomer" ? "Save" : "Create Customer"}
                  </Typography>
                </Button>
              </Grid>
              {/* </Grid> */}
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </>
  );
}

export default index;
