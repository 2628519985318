//employee constants
import {
  EMPLOYEES_COUNT_INFO,
  EMPLOYEES_COUNT_INFO_SUCCESS,
  EMPLOYEES_COUNT_INFO_FAILURE,
} from "../constants/EmployeesCountInfo";

const INIT_STATE = {
  employeesCountInfo: {},
  loadingEmployeesCountInfo: true,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case EMPLOYEES_COUNT_INFO:
      return {
        ...state,
        loadingEmployeesCountInfo: true,
      };
    case EMPLOYEES_COUNT_INFO_SUCCESS:
      return {
        ...state,
        loadingEmployeesCountInfo: false,
        employeesCountInfo: action.payload,
      };
    // eslint-disable-next-line no-fallthrough
    case EMPLOYEES_COUNT_INFO_FAILURE:
      return {
        ...state,
        loadingEmployeesCountInfo: false,
        employeesCountInfo: "Error in  getting Club Data",
      };
    default:
      return state;
  }
};
