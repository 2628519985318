import React from "react";
//material ui
import { Button, Grid, Paper, Typography } from "@mui/material";
//router
import { useNavigate } from "react-router-dom";
//redux
import { useSelector } from "react-redux";

function AmountDetails(props) {
  //contants
  const { details, editPayroll, action, handleLastPayrollClicked, payrollWidget, itemColumns } = props;

  return (
    <Paper sx={{ p: 2.2 }}>
      <Grid item container direction="row" alignItems="center" xs={12}>
        <Grid
          item
          container
          direction="row"
          columnGap={payrollWidget ? 0 : 14}
          xs={editPayroll ? 10 : 12}
          alignItems="center"
        >
          {details && details.length > 0
            ? details.map((detail, index) => {
                if (detail != null) {
                  return (
                    <Grid
                      item
                      key={detail?.title}
                      xs={payrollWidget && itemColumns}
                      sx={handleLastPayrollClicked && detail?.itemClickable ? { cursor: "pointer" } : null}
                      onClick={detail?.itemClickable ? handleLastPayrollClicked : null}
                    >
                      <Typography
                        variant="h5"
                        fontSize="20px"
                        id={detail?.title}
                        sx={[{ mt: 1 }, detail?.amountTextColor != null ? detail?.amountTextColor : {}]}
                      >
                        {detail?.amount}
                      </Typography>
                      <Typography variant="h6" fontSize="14px" color="secondary.alt" sx={{ whiteSpace: "pre-line" }}>
                        {detail?.title}
                      </Typography>
                      {detail?.subtext ? (
                        <Typography variant="h6" fontSize="10px" color="primary" lineHeight="10px">
                          {detail?.subtext}
                        </Typography>
                      ) : null}
                    </Grid>
                  );
                }
              })
            : null}
        </Grid>

        {action ? (
          <Grid item xs={2} textAlign="end" pr={1}>
            {action}
          </Grid>
        ) : null}
      </Grid>
    </Paper>
  );
}

export default AmountDetails;
