//employee constants
import {
  RESET_DELETE_USER,
  DELETE_USER,
  DELETE_USER_SUCCESS,
  DELETE_USER_FAILURE,
} from "../../constants/Settings/DeleteUser";

const INIT_STATE = {
  deleteUser: {},
  loadingDeleteUser: false,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case RESET_DELETE_USER:
      return INIT_STATE;

    case DELETE_USER:
      return {
        ...state,
        loadingDeleteUser: true,
      };
    case DELETE_USER_SUCCESS:
      return {
        ...state,
        loadingDeleteUser: false,
        deleteUser: action.payload,
      };
    // eslint-disable-next-line no-fallthrough
    case DELETE_USER_FAILURE:
      return {
        ...state,
        loadingDeleteUser: false,
        deleteUser: "Error in  getting Club Data",
      };
    default:
      return state;
  }
};
