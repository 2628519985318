//employee constants
import { ADD_EMPLOYEE_EXPERIENCE_DATA } from "../../../constants/Workforce/AddEmployee/AddEmployeeExperienceData";

const INIT_STATE = {
    addEmployeeExperienceData: {},
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case ADD_EMPLOYEE_EXPERIENCE_DATA:
            return {
                ...state,
                addEmployeeExperienceData: action.payload,
            };

        default:
            return state;
    }
};
