import React from "react";
import { Helmet } from "react-helmet";
import { useSelector, useDispatch } from "react-redux";
import { Divider, Box } from "@mui/material";

import EmployeeDocument from "components/common/Widgets/EmployeeDocuments";
import PageLoader from "components/common/PageLoader";

import { EmployeeDocumentsRequest } from "store/actions/EmployeePortal/EmployeeDocuments";

import "./EmployeeDocuments.css";

function EmployeeDocuments(props) {
  const dispatch = useDispatch();
  const empDocs = useSelector((state) => state.EmployeeDocuments);
  const loadingEmpDocs = useSelector((state) => state.EmployeeDocuments.isLoading);
  React.useEffect(() => {
    // Only fetch if the details are not in redux store
    if (empDocs && empDocs?.docs && !Object.keys(empDocs?.docs)?.length) {
      let loggedInUser = JSON.parse(localStorage.getItem("loggedInUser"));
      dispatch(EmployeeDocumentsRequest(`employees/${loggedInUser.userId}/document`));
    }
  }, []);
  return (
    <>
      <Helmet>
        <title>Edge | Documents</title>
      </Helmet>
      {loadingEmpDocs && <PageLoader />}
      <div className="employee-documents-A">
        <div className="employee-paystubs-details-dA">
          <div>
            <div className="employee-paystubs-details-dAA">
              <div className="employee-paystubs-details-slips">Your document</div>
            </div>
            <div className="employee-paystubs-details-edit">View Your Documents</div>
          </div>
        </div>
        <Divider />
        <br />
        {empDocs?.docs?.length ? <EmployeeDocument empDocs={empDocs} /> : null}
      </div>
    </>
  );
}

export default EmployeeDocuments;
