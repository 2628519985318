import postRequest from "util/APIHelperPost";
import getRequest from "util/APIHelperGet";
import { baseUrl } from "util/APIBaseUrl";

export const getRandomPrompts = async () => {
  try {
    const response = await getRequest(`${baseUrl}/get_random_prompts`);
    return response;
  } catch (error) {
    console.error("Failed to get prompts list:", error);
    throw error;
  }
};

export const getAllUsers = async () => {
  try {
    const response = await getRequest(`${baseUrl}/get_all_users`);
    return response;
  } catch (error) {
    console.error("Failed to get users list:", error);
    throw error;
  }
};

export const sendMessage = async ({ thread_id, text, email }) => {
  try {
    const payload = { thread_id, text, email };
    const response = await postRequest(`${baseUrl}/process_message`, JSON.stringify(payload));
    return response;
  } catch (error) {
    console.error("Failed to send message:", error);
    throw error;
  }
};

export const getThreadMessages = async (threadId) => {
  try {
    const response = await getRequest(`${baseUrl}/get_thread_messages?thread_id=${threadId}`);
    return response;
  } catch (error) {
    console.error(`Failed to get messages for thread ${threadId}:`, error);
    throw error;
  }
};

export const getUserThreads = async ({ email }) => {
  try {
    const response = await getRequest(`${baseUrl}/get_user_threads?email=${email}`);
    return response;
  } catch (error) {
    console.error(`Failed to get threads for user ${email}:`, error);
    throw error;
  }
};

export const deleteUserThread = async ({ email, thread_id }) => {
  try {
    const response = await getRequest(`${baseUrl}/delete_user_thread?email=${email}&thread_id=${thread_id}`);
    return response;
  } catch (error) {
    console.error(`Failed to Delete the conversation ${thread_id}  for user ${email}:`, error);
    throw error;
  }
};
