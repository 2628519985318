import { useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import moment from "moment";

import CustomModal from "components/common/Modal";
import Select from "components/common/Select";
import Text from "components/common/Text";
import CustomDatePicker from "components/common/DatePicker";
import Button from "components/common/Button";
import { AlertType } from "components/common/Snackbar";

import { EMPLOYEMENT_TYPE } from "constants";

import EmploymentInformationSchema from "validations/BambooHRTabs/EmploymentInformationSchema";

import { assignEmployeeToACustomer, editEmployeeAssignment } from "services/Admin/Dashboard";
import { showSnackbar } from "store/actions/Utility";
import { GetEmployeeDetails } from "store/actions/GetEmployeeDetails";
import SearchableAutoComplete from "components/common/AutoComplete";

import "./AssignToCustomerModal.scss";

function AssignToCustomerModal({
  open,
  closeModal,
  employeeId,
  showAssignToCustomerModalForEdit,
  initialEmploymentDetails,
  fetchEmployeeDetailsByIDV2,
  employmentStatus,
}) {
  const dispatch = useDispatch();
  const [assigningEmployee, setAssigningEmployee] = useState(false);

  const departmentsList = useMemo(
    () => JSON.parse(sessionStorage.getItem("dropdownData"))?.payload?.department || [],
    []
  );
  const customerList = useMemo(() => JSON.parse(sessionStorage.getItem("dropdownData"))?.payload?.customer || [], []);
  const companies = customerList.map(({ companyName, customerId }) => ({
    value: companyName,
    label: companyName,
    id: customerId,
  }));
  const departments = departmentsList.map(({ department }) => ({
    value: department,
    label: department,
  }));

  const methods = useForm({
    resolver: yupResolver(EmploymentInformationSchema),
    mode: "onChange",
    values: initialEmploymentDetails,
  });

  const {
    handleSubmit,
    reset,
    formState: { isValid },
  } = methods;

  const closeAssignToCustomerModal = () => {
    reset({});
    closeModal();
  };

  const onSubmit = async (data) => {
    setAssigningEmployee(true);

    const payload = {
      ...data,
      ...(!showAssignToCustomerModalForEdit && {
        customerId: companies.find((company) => company.label === data?.company)?.id,
      }),
      employeeId,
      startDate: moment(data.dateOfJoining).format("YYYY-MM-DD"),
    };

    let res;
    if (showAssignToCustomerModalForEdit) res = await editEmployeeAssignment(employeeId, payload);
    else res = await assignEmployeeToACustomer(employeeId, payload);

    setAssigningEmployee(false);

    if (res?.payload?.error?.length > 0) {
      dispatch(
        showSnackbar({
          type: AlertType.error,
          message: res?.payload?.error?.[0]?.description || res?.payload?.error?.[0]?.msg || "An error occurred.",
          dispatch,
        })
      );
      closeAssignToCustomerModal();
      return;
    }

    fetchEmployeeDetailsByIDV2();
    dispatch(
      GetEmployeeDetails({
        customerID: companies.find((company) => company.label === data?.company)?.id,
        employeeID: employeeId,
        request: `employee/${employeeId}`,
      })
    );
    closeAssignToCustomerModal();
    dispatch(showSnackbar({ type: AlertType.success, message: "Assigned to Customer successfully", dispatch }));
  };

  return (
    <CustomModal open={open} title="Assign to Customer" width="700px" onClose={closeAssignToCustomerModal}>
      <FormProvider {...methods}>
        <form
          className="mt-15"
          onSubmit={(e) => {
            e.preventDefault();
            handleSubmit((data) => onSubmit(data, e))(e);
          }}
        >
          <div className="flex flex-col gap-1">
            <div className="flex gap-1">
              <SearchableAutoComplete
                name="company"
                label="Company"
                options={companies}
                placeholder="Search by company name"
                disabled={showAssignToCustomerModalForEdit}
              />
              <Select label="Department" name="department" placeholder="Select" options={departments} />
            </div>

            <div className="flex gap-1">
              <Text label="Job title" name="jobTitle" placeholder="e.g. Client Associate" />
              <CustomDatePicker
                name="dateOfJoining"
                disabled={showAssignToCustomerModalForEdit && employmentStatus !== "pending"}
                label="Date of joining"
              />
            </div>

            <div className="flex gap-1">
              <Select label="Employment type" name="employmentType" placeholder="Select" options={EMPLOYEMENT_TYPE} />
            </div>
          </div>
          <div className="modal-action-buttons">
            <Button onClick={closeAssignToCustomerModal} label="Cancel" variant="outlined" size="large" />
            <Button
              isLoading={assigningEmployee}
              isDisabled={!isValid}
              type="submit"
              label="Save"
              variant="contained"
              size="large"
            />
          </div>
        </form>
      </FormProvider>
    </CustomModal>
  );
}

export default AssignToCustomerModal;
