import React from "react";

function NoteTextIcon(props) {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M16.2655 12.401V3.59483H3.45659V16.4038H12.2627V14.0021C12.2627 13.1178 12.9796 12.401 13.8639 12.401H16.2655ZM15.9339 14.0021H13.8639V16.0722L15.9339 14.0021ZM3.45659 18.0049C2.57231 18.0049 1.85547 17.288 1.85547 16.4038V3.59483C1.85547 2.71056 2.57231 1.99371 3.45659 1.99371H16.2655C17.1498 1.99371 17.8666 2.71056 17.8666 3.59483V14.3337L14.1955 18.0049H3.45659ZM5.85826 14.0021V12.401H10.6616V14.0021H5.85826ZM5.85826 10.7999V9.19874H13.8639V10.7999H5.85826ZM5.85826 7.59763V5.99651H13.8639V7.59763H5.85826Z"
        fill="black"
      />
    </svg>
  );
}

export default NoteTextIcon;
