import React from "react";
import {
  Grid,
  Paper,
  Badge,
  Box,
  Button,
  IconButton,
  Popover,
  Typography,
  ListItem,
  List,
  Menu,
  MenuItem,
} from "@mui/material";

//Components
import TaskGrid from "../Screens/DashBoard/CustomerDashBoard/TaskGrid";
import { FiberManualRecord } from "@mui/icons-material";
//Icons
import { BiBell } from "react-icons/bi";

// //router
import { useSelector, useDispatch } from "react-redux";

function Notifications() {
  //   const dispatch = useDispatch();

  //component states
  const [anchorEl, setAnchorEl] = React.useState(null);

  //redux state
  const noticeBoard = useSelector((state) => state.GetNoticeBoard.noticeBoard);

  //Functions
  const handleNotificationClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <>
      <IconButton
        sx={{
          background: "#fff",
          // width: "45px",
          // height: "45px",
          padding: "5px",
        }}
        onClick={handleNotificationClick}
      >
        {noticeBoard?.ok && noticeBoard?.payload.length ? (
          <Badge
            badgeContent={noticeBoard?.payload.length}
            color="warning"
            overlap="circular"
          >
            <BiBell color={anchorEl ? "primary.main" : "grey"} />
          </Badge>
        ) : (
          <BiBell color={anchorEl ? "primary.main" : "grey"} />
        )}
      </IconButton>

      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={anchorEl ? true : false}
        onClose={(e) => setAnchorEl(null)}
        onClick={() => setAnchorEl(null)}
        sx={{
          "& .MuiPaper-root": {
            minWidth: "244px",
            padding: "15px 0px",
          },
          "& .MuiList-root": {
            padding: "0px",
          },
        }}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            minWidth: 80,
            borderRadius: "12px !important",
            boxShadow:
              "0px 1px 1px rgba(2, 0, 110, 0.22), 0px 1px 6px rgba(2, 0, 110, 0.25) !important",
            padding: "0px",
            "&:before": {
              content: '""',
              display: "none",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{
          horizontal: "right",
          vertical: "top",
        }}
        anchorOrigin={{
          horizontal: "right",
          vertical: "bottom",
        }}
      >
        {noticeBoard?.ok && noticeBoard?.payload.length ? (
          noticeBoard.payload.map((notice, index) => (
            <MenuItem
              key={index}
              sx={{
                pl: "15px",
                pb: "10px",
              }}
            >
              <FiberManualRecord sx={{ fontSize: "small" }} />
              <Typography
                sx={{
                  fontFamily: "Poppins-Medium",
                  fontSize: "13px",
                  lineHeight: "20px",
                  color: "#2C2D3E",
                  letterSpacing: "0.1px",
                  pl: "10px",
                }}
              >
                {notice?.notice}
              </Typography>
            </MenuItem>
          ))
        ) : (
          <MenuItem
            sx={{
              pl: "15px",
              pb: "10px",
            }}
          >
            <FiberManualRecord sx={{ fontSize: "small" }} />
            <Typography
              sx={{
                fontFamily: "Poppins-Medium",
                fontSize: "13px",
                lineHeight: "20px",
                color: "#2C2D3E",
                letterSpacing: "0.1px",
                pl: "10px",
              }}
            >
              No New Notifications
            </Typography>
          </MenuItem>
        )}
      </Menu>
    </>
  );
}

export default Notifications;
