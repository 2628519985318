import {
  PAYONEER_SAVE_ACCESS_TOKEN_FETCH,
  PAYONEER_SAVE_ACCESS_TOKEN_SUCCESS,
  PAYONEER_PAYONEER_SAVE_ACCESS_TOKEN_FAILED,
} from "store/constants/Payoneer/PayoneerSaveAccessToken";

export const fetchSaveAccessToken = () => {
  return {
    type: PAYONEER_SAVE_ACCESS_TOKEN_FETCH,
  };
};

export const saveAccessTokenSuccess = () => {
  return {
    type: PAYONEER_SAVE_ACCESS_TOKEN_SUCCESS,
  };
};

export const saveAccessTokenFailed = (error) => {
  return { type: PAYONEER_PAYONEER_SAVE_ACCESS_TOKEN_FAILED, payload: error };
};
