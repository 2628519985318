import { useEffect, useState } from "react";
import { Chip } from "@mui/material";

import Modal from "components/common/Modal";
import Button from "components/Button/Button";

import { downloadMedicalDoc, getMedicalDocsListing } from "services/useTimeoffService";

import DocsIcon from "assets/images/docs.svg";
import LeftArrow from "assets/images/left-arrow.svg";
import { convertDate } from "components/Screens/CommonUtils";
import PageLoader from "components/common/PageLoader";

const MedicalDocsModal = ({ open, closeModal, leaveRequestId, leaveType, dateFrom, dateTo, noOfLeaves }) => {
  const [medicalDocs, setMedicalDocs] = useState([]);
  const [loadingDocs, setLoadingDocs] = useState(false);

  const fetchMedicalDocs = async () => {
    setLoadingDocs(true);
    const res = await getMedicalDocsListing(leaveRequestId);
    setMedicalDocs(res?.payload?.data);
    setLoadingDocs(false);
  };

  useEffect(() => {
    if (open) fetchMedicalDocs();

    return () => {
      setMedicalDocs([]);
    };
  }, [open]);

  return (
    <Modal open={open} title={`Medical Documents`}>
      {loadingDocs && <PageLoader />}
      <div className="ml-1">
        <div className="pop-regular primary-30 label-lg mt-1">{leaveType} </div>

        <div className="pending-leave-duration">
          <div className="pending-leave-date">{convertDate(dateFrom)}</div>{" "}
          <img className="pending-leave-arrow" src={LeftArrow} alt="left arrow" />{" "}
          <div className="pending-leave-date">{convertDate(dateTo)}</div>
        </div>
      </div>
      <div className="pending-timeoff-details pending-timeoff-details-first">
        <div className="pending-timeoff-details-text text-left" style={{ width: "60%" }}>
          Duration
        </div>
        <Chip
          className="secondary-20"
          size="small"
          variant="outlined"
          label={`${noOfLeaves}  ${noOfLeaves == 1 ? "leave" : "leaves"}`}
        />
      </div>

      {medicalDocs.length ? (
        <>
          {medicalDocs.map(({ name, documentId }) => (
            <div className="mt-1 flex justify-between" style={{ background: "#FBFAFF", padding: "0.5rem" }}>
              <div className="flex overflow-anywhere text-left">
                <img src={DocsIcon} alt="docs icon" />
                <div className="ml-025"> {name}</div>
              </div>

              <div
                className="pop-regular primary-30 label-lg cursor-pointer"
                style={{ alignSelf: "center" }}
                id="medical-docs-download"
                onClick={() => downloadMedicalDoc(leaveRequestId, documentId, name)}
              >
                View
              </div>
            </div>
          ))}
        </>
      ) : (
        <p className="my-2"> No medical docs found</p>
      )}

      <Button variant="primary" size="md" className="mt-1 float-right" onClick={() => closeModal()}>
        Done
      </Button>
    </Modal>
  );
};

export default MedicalDocsModal;
