import moment from "moment";
import jwt from "jwt-decode";

import { baseUrl } from "./APIBaseUrl";

let token = {};
let refreshTokenResponse;
let refreshTokenFlag = false;
let refreshTokenRefreshedAt;

export const getIdToken = () => {
  token = {
    accessToken: localStorage.getItem("accessToken"),
    refreshToken: localStorage.getItem("refreshToken"),
  };

  return token;
};

// eslint-disable-next-line consistent-return
export const makeRequest = async (opts, loaderFlag, apiType) => {
  token = await getIdToken();
  const headers = {};
  if (opts.contentType) {
    headers["Content-Type"] = opts.contentType;
  }
  const options = {
    ...opts,
    headers,
  };

  if (token != null) {
    options.headers.Authorization = `Bearer ${token.accessToken}`;
  }

  refreshTokenFlag = false;

  const continueRequest = async (accessToken) => {
    options.headers.Authorization = `Bearer ${accessToken}`;
    const response = await fetch(options.path, options);
    let data;
    if (apiType) {
      data = await response.blob();
    } else {
      data = await response.json();
    }

    return {
      ok: response.ok,
      status: response.status,
      payload: data,
    };
  };

  const resolvePromise = () => {
    return new Promise((resolve) => {
      const intervalId = setInterval(async () => {
        if (!refreshTokenFlag) {
          const accessToken = localStorage.getItem("accessToken");

          if (accessToken) {
            clearInterval(intervalId);
            const res = await continueRequest(accessToken);
            resolve(res);
          }
        }
      }, 2000);
    });
  };

  // eslint-disable-next-line no-return-await
  try {
    const response = await fetch(options.path, options);

    if (!response.ok && response.status == 403) {
      throw new Error(response.status);
    }

    let data;
    if (apiType && response.status === 200) {
      data = await response.blob();
    } else {
      data = await response.json();
    }
    return {
      ok: response.ok,
      status: response.status,
      payload: data,
    };
  } catch (error) {
    if (refreshTokenFlag) {
      const res = await resolvePromise();
      return res;
    }
    if (error.message == 403) {
      if (refreshTokenRefreshedAt && moment().diff(refreshTokenRefreshedAt, "seconds") < 10) {
        const res = await resolvePromise();
        return res;
      }

      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token.accessToken}`,
        },
        body: JSON.stringify({
          refreshToken: `${token.refreshToken}`,
        }),
      };
      refreshTokenFlag = true;

      refreshTokenResponse = await fetch(`${baseUrl}/refresh`, requestOptions);
      if (refreshTokenResponse.ok && refreshTokenResponse.status == 201) {
        const data = await refreshTokenResponse.json();
        const user = jwt(data.accessToken);

        localStorage.setItem("accessToken", data.accessToken);
        localStorage.setItem("refreshToken", data.refreshToken);
        localStorage.setItem("loggedInUser", JSON.stringify(user));

        refreshTokenFlag = false;
        refreshTokenRefreshedAt = moment();

        const res = await continueRequest(data.accessToken);
        return res;
      }
      localStorage.clear();
      window.location.replace("/");
    } else if (error.message == 401) {
      localStorage.clear();
      window.location.replace("/");
    } else {
      return error;
    }
  }
};
