import React from "react";

const LogoutIcon = () => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M6.62197 15.0001C6.31309 15.874 5.47966 16.5001 4.5 16.5001C3.25736 16.5001 2.25 15.4927 2.25 14.2501C2.25 13.0074 3.25736 12.0001 4.5 12.0001C5.47966 12.0001 6.31309 12.6262 6.62197 13.5001H13.125C14.1605 13.5001 15 12.6606 15 11.6251C15 10.5895 14.1605 9.75006 13.125 9.75006H4.875C3.01104 9.75006 1.5 8.23902 1.5 6.37506C1.5 4.5111 3.01104 3.00006 4.875 3.00006H7.18934L6.21967 2.03039L7.28033 0.969727L10.0607 3.75006L7.28033 6.53039L6.21967 5.46973L7.18934 4.50006H4.875C3.83947 4.50006 3 5.33952 3 6.37506C3 7.41059 3.83947 8.25006 4.875 8.25006H13.125C14.989 8.25006 16.5 9.7611 16.5 11.6251C16.5 13.489 14.989 15.0001 13.125 15.0001H6.62197ZM4.5 13.5001C4.08579 13.5001 3.75 13.8358 3.75 14.2501C3.75 14.6643 4.08579 15.0001 4.5 15.0001C4.91421 15.0001 5.25 14.6643 5.25 14.2501C5.25 13.8358 4.91421 13.5001 4.5 13.5001ZM13.5 1.50006C14.7426 1.50006 15.75 2.50742 15.75 3.75006C15.75 4.9927 14.7426 6.00006 13.5 6.00006C12.2574 6.00006 11.25 4.9927 11.25 3.75006C11.25 2.50742 12.2574 1.50006 13.5 1.50006ZM13.5 3.00006C13.0858 3.00006 12.75 3.33584 12.75 3.75006C12.75 4.16427 13.0858 4.50006 13.5 4.50006C13.9142 4.50006 14.25 4.16427 14.25 3.75006C14.25 3.33584 13.9142 3.00006 13.5 3.00006Z"
        fill="#292A3D"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M6.62197 15.0001C6.31309 15.874 5.47966 16.5001 4.5 16.5001C3.25736 16.5001 2.25 15.4927 2.25 14.2501C2.25 13.0074 3.25736 12.0001 4.5 12.0001C5.47966 12.0001 6.31309 12.6262 6.62197 13.5001H13.125C14.1605 13.5001 15 12.6606 15 11.6251C15 10.5895 14.1605 9.75006 13.125 9.75006H4.875C3.01104 9.75006 1.5 8.23902 1.5 6.37506C1.5 4.5111 3.01104 3.00006 4.875 3.00006H7.18934L6.21967 2.03039L7.28033 0.969727L10.0607 3.75006L7.28033 6.53039L6.21967 5.46973L7.18934 4.50006H4.875C3.83947 4.50006 3 5.33952 3 6.37506C3 7.41059 3.83947 8.25006 4.875 8.25006H13.125C14.989 8.25006 16.5 9.7611 16.5 11.6251C16.5 13.489 14.989 15.0001 13.125 15.0001H6.62197ZM4.5 13.5001C4.08579 13.5001 3.75 13.8358 3.75 14.2501C3.75 14.6643 4.08579 15.0001 4.5 15.0001C4.91421 15.0001 5.25 14.6643 5.25 14.2501C5.25 13.8358 4.91421 13.5001 4.5 13.5001ZM13.5 1.50006C14.7426 1.50006 15.75 2.50742 15.75 3.75006C15.75 4.9927 14.7426 6.00006 13.5 6.00006C12.2574 6.00006 11.25 4.9927 11.25 3.75006C11.25 2.50742 12.2574 1.50006 13.5 1.50006ZM13.5 3.00006C13.0858 3.00006 12.75 3.33584 12.75 3.75006C12.75 4.16427 13.0858 4.50006 13.5 4.50006C13.9142 4.50006 14.25 4.16427 14.25 3.75006C14.25 3.33584 13.9142 3.00006 13.5 3.00006Z"
        fill="black"
        fill-opacity="0.2"
      />
    </svg>
  );
};

export default LogoutIcon;
