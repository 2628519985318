import React from "react";
//enum
import { settingPages } from "./../SettingPages.enumn";
//component
import MangeUsers from "./ManageUsers";
import AddUser from "../AddUser";

function index(props) {
  const [settingPage, setSettingPage] = React.useState(settingPages.MANAGE_USERS);

  //functions

  const handleSettingPagechange = (edit) => {
    setSettingPage(edit);
  };

  return settingPage == settingPages.MANAGE_USERS ? (
    <MangeUsers handleSettingPagechange={handleSettingPagechange} />
  ) : //  :
  // settingPage == settingPages.ADD_USER ? (
  //     <AddUser  />
  // )
  null;
}

export default index;
