import React from "react";

import { Tabs, Tab, Box } from "@mui/material";

import "./tabs.scss";

function TabComponent({ tabs, tab, setTab }) {
  const handleChange = (event, newValue) => {
    setTab(newValue);
  };

  return (
    <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
      <Tabs value={tab} onChange={handleChange} className="custom-tabs">
        {tabs.map((tabItem) => (
          <Tab
            label={tabItem.label}
            disabled={tabItem?.isDisabled}
            classes={{ root: "custom-tab", wrapper: "custom-tab-wrapper" }}
          />
        ))}
      </Tabs>
    </Box>
  );
}

export default TabComponent;
