import React from "react";
import PropTypes from "prop-types";
import {
  Card as MuiCard,
  CardHeader as MuiCardHeader,
  CardContent as MuiCardContent,
  CardActions as MuiCardActions,
  CardMedia as MuiCardMedia,
} from "@mui/material";

import "./Card.scss";

function Card({ children, header, content, actions, media }) {
  return (
    <MuiCard className="custom-card">
      {media && media}
      {header && header}
      {content && content}
      {children}
      {actions && actions}
    </MuiCard>
  );
}

Card.propTypes = {
  children: PropTypes.node,
  header: PropTypes.node,
  content: PropTypes.node,
  actions: PropTypes.node,
  media: PropTypes.node,
};

Card.defaultProps = {
  children: null,
  header: null,
  content: null,
  actions: null,
  media: null,
};

function CardHeader({
  title,
  subheader,
  avatar,
  isSmallHeading,
  rightAction,
  onClick,
  disabled,
  className = "",
  sx = {},
}) {
  let titleClass = avatar || isSmallHeading ? "custom-header-title-sm" : "custom-header-title-lg";
  if (disabled) {
    titleClass += " text-disabled";
  }

  let subHeaderClass = "custom-header-subheader";
  if (disabled) {
    subHeaderClass += " text-disabled";
  }

  return (
    <MuiCardHeader
      onClick={onClick}
      title={title}
      subheader={subheader}
      avatar={avatar}
      action={rightAction}
      className={`custom-header-root ${className}`}
      sx={{ ...sx }}
      classes={{
        title: titleClass,
        subheader: subHeaderClass,
        avatar: "custom-header-avatar",
        action: "custom-header-action",
      }}
    />
  );
}

CardHeader.propTypes = {
  title: PropTypes.string.isRequired,
  subheader: PropTypes.node,
  avatar: PropTypes.node,
  rightAction: PropTypes.node,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
};

CardHeader.defaultProps = {
  subheader: null,
  avatar: null,
  rightAction: null,
  onClick: () => {},
  disabled: false,
};

function CardContent({ children }) {
  return <MuiCardContent className="custom-card-content">{children}</MuiCardContent>;
}

CardContent.propTypes = {
  children: PropTypes.node,
};

CardContent.defaultProps = {
  children: null,
};

function CardActions({ action1, action2, action3 }) {
  return (
    <MuiCardActions>
      {action1}
      {action2}
      {action3}
    </MuiCardActions>
  );
}

CardActions.propTypes = {
  action1: PropTypes.node,
  action2: PropTypes.node,
  action3: PropTypes.node,
};

CardActions.defaultProps = {
  action1: null,
  action2: null,
  action3: null,
};

function CardMedia({ image, ratio = "4x3" }) {
  const getRatioHeight = () => {
    switch (ratio) {
      case "4x3":
        return "75%";
      case "1x9":
        return "10%";
      default:
        return "auto";
    }
  };

  return (
    <MuiCardMedia
      component="div"
      sx={{
        position: "relative",
        paddingBottom: getRatioHeight(),
        width: "100%",
        height: 0,
        overflow: "hidden",
      }}
    >
      <img src={image} alt="card-img" className="card-img" />
    </MuiCardMedia>
  );
}

CardMedia.propTypes = {
  image: PropTypes.string.isRequired,
  ratio: PropTypes.oneOf(["4x3", "1x9"]),
};

CardMedia.defaultProps = {
  ratio: "4x3",
};

export default Card;
export { CardHeader, CardContent, CardActions, CardMedia };
