import { call, put, takeEvery } from "redux-saga/effects";
//actions
import {
  GetCustomersListSuccess,
  GetCustomersListFailure,
} from "../../actions/Customers/GetCustomerList";
//constant
import { GET_CUSTOMER_LIST } from "../../constants/Customers/GetCustomerList";
//request type
import getRequest from "../../../util/APIHelperGet";
//base url
import { baseUrl } from "../../../util/APIBaseUrl";

export const fetchGetCustomersListRequest = async (actions) => {
  return await getRequest(
    `${baseUrl}/customers/search?name=${actions.payload.customerName}`,
    actions?.payload?.loaderFlag
  )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

function* fetchGetCustomersList(params) {
  try {
    let response = yield call(fetchGetCustomersListRequest, params);

    yield put(GetCustomersListSuccess(response));
  } catch (error) {
    console.error(error);
    yield put(GetCustomersListFailure(error));
  }
}

export default function* rootSaga() {
  yield takeEvery(GET_CUSTOMER_LIST, fetchGetCustomersList);
}
