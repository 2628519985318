import React from "react";
import "./reportedFlags.css";
import { FaChevronRight } from "react-icons/fa";
import { IconButton, Skeleton } from "@mui/material";
import { useNavigate } from "react-router-dom";
import DropdownIcon from "assets/images/chevron-bottom.svg";

const leaveYearSkeltonStyle = {
  borderRadius: "26px",
  backgroundColor: "rgb(189 192 255 / 30%)",
};

const ReportedFlags = (props) => {
  const { timeOffFlags, timeOffFlagsLoading } = props;

  const navigate = useNavigate();
  const handleIconClick = (flag) => {
    const queryParams = new URLSearchParams();

    if (flag?.filter) {
      for (const key in flag.filter) {
        queryParams.append(key, flag.filter[key]);
      }
    }

    const queryString = queryParams.toString();

    if (queryString) {
      navigate(`/main/admin/timeoff/new-activity?${queryString}`);
    } else {
      navigate("/main/admin/timeoff/new-activity");
    }
  };

  return (
    <div className="container" style={{ padding: "0px" }}>
      <div className="header">
        <div style={{ fontSize: "16px", lineHeight: "24px", fontFamily: "Poppins-SemiBold", letterSpacing: "0.15px" }}>
          {" "}
          Flags reported this week{" "}
        </div>
        <span className="notificationDot"></span>
      </div>
      <ul className="list" style={{ padding: "9px 16px 14px 16px", backgroundColor: "#fbfaff" }}>
        <div style={{ borderRadius: "14px", background: "#ffffff" }}>
          {timeOffFlagsLoading ? (
            <>
              <li className="listItem" style={{ display: "flex", flexDirection: "column", gap: "20px" }}>
                <Skeleton
                  variant="rectangle"
                  sx={{ ...leaveYearSkeltonStyle, borderRadius: "16px", width: "100%", height: "41px" }}
                />

                <Skeleton
                  variant="rectangle"
                  sx={{ ...leaveYearSkeltonStyle, borderRadius: "16px", width: "100%", height: "41px" }}
                />

                <Skeleton
                  variant="rectangle"
                  sx={{ ...leaveYearSkeltonStyle, borderRadius: "16px", width: "100%", height: "41px" }}
                />

                <Skeleton
                  variant="rectangle"
                  sx={{ ...leaveYearSkeltonStyle, borderRadius: "16px", width: "100%", height: "41px" }}
                />
              </li>
            </>
          ) : (
            timeOffFlags?.map(
              (flag, index) =>
                flag?.name !== "New Activity this week" && (
                  <li key={index} className="listItem">
                    <div style={{ display: "flex", gap: "3px" }}>
                      <div
                        className="count"
                        id="flag-count"
                        style={{ fontFamily: "Poppins-SemiBold", fontSize: "14px", lineHeight: "20px" }}
                      >
                        {flag?.value || 0}
                      </div>
                      <div
                        className="title"
                        id="flag-name"
                        style={{ fontFamily: "Poppins-Regular", fontSize: "14px", lineHeight: "20px" }}
                      >
                        {flag?.name || "-"}
                      </div>
                    </div>
                    <div className="chevron">
                      <IconButton onClick={() => handleIconClick(flag)}>
                        {/* <FaChevronRight /> */}

                        <img
                          className="dropdown-icon"
                          src={DropdownIcon}
                          alt="chevron-bottom"
                          style={{ transform: "rotate(-90deg)" }}
                        />
                      </IconButton>
                    </div>
                  </li>
                )
            )
          )}
        </div>
      </ul>
    </div>
  );
};

export default ReportedFlags;
