import {
  RESET_ADD_EMPLOYEE_EXPERIENCE,
  ADD_EMPLOYEE_EXPERIENCE,
  ADD_EMPLOYEE_EXPERIENCE_SUCCESS,
  ADD_EMPLOYEE_EXPERIENCE_FAILURE,
} from "../../../constants/Workforce/AddEmployee/AddEmployeeExperience";

// for user authentication
export const ResetAddEmployeeExperience = (data) => {
  return {
    type: RESET_ADD_EMPLOYEE_EXPERIENCE,
    payload: data,
  };
};

export const AddEmployeeExperience = (data) => {
  return {
    type: ADD_EMPLOYEE_EXPERIENCE,
    payload: data,
  };
};

export const AddEmployeeExperienceSuccess = (payload) => {
  return {
    type: ADD_EMPLOYEE_EXPERIENCE_SUCCESS,
    payload,
  };
};

export const AddEmployeeExperienceFailure = (payload) => {
  return {
    type: ADD_EMPLOYEE_EXPERIENCE_FAILURE,
    payload,
  };
};
