import React, { useState, useEffect, useId, useRef, useLayoutEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import { Typography, Grid, TextField, InputAdornment, List, ListItem } from "@mui/material";

import PageLoader from "components/common/PageLoader";
import { getMonthFromDate, isEmptyObject, isValidResponse } from "components/Screens/CommonUtils";
import Button from "components/Button/Button";
import WorkforceCard from "components/WorkforceCard/WorkforceCard";
import Employee from "components/Screens/Workforce/CustomerWorkforceV2/Employee";
import PageTitle from "components/Widgets/PageTitle";

import { EmployeeInfo } from "store/actions/EmployeeInfo";
import { GetEmployeeDetails } from "store/actions/GetEmployeeDetails";

import { baseUrl } from "util/APIBaseUrl";
import getRequest from "util/APIHelperGet";

import { usePermissions } from "components/Hook";

function index() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const id = useId();
  const ref = useRef(null);

  //component states
  const [apiFilters, setApiFilters] = useState({});
  const [timer, setTimer] = useState(null);
  const [selectedEmployee, setSelectedEmployee] = useState(null);
  const [searchedEmployees, setSearchedEmployees] = useState({});
  const [selectedCountry, setSelectedCountry] = useState("All");
  const [sortBy, setSortBy] = useState(false);
  const [width, setWidth] = useState(0);
  const [open, setOpen] = useState(false);

  //custom hook
  const { loggedInUser, loggedInUserType } = usePermissions();

  //redux state
  const userAdditionalInfo = useSelector((state) => state.UserAdditionalInfo.userAdditionalInfo);
  const loadingAdditionalInfo = useSelector((state) => state.UserAdditionalInfo.loadingAdditionalInfo);

  const employeesInfo = useSelector((state) => state.EmployeeInfo.employeesInfo);
  const loadingEmployeesInfo = useSelector((state) => state.EmployeeInfo.loadingEmployeesInfo);

  const getEmployeeDetails = useSelector((state) => state.GetEmployeeDetails.getEmployeeDetails);
  const loadingGET_EMPLOYEE_Details = useSelector((state) => state.GetEmployeeDetails.loadingGET_EMPLOYEE_Details);

  //functions

  const handleSearchEmployee = (event) => {
    setApiFilters({
      ...apiFilters,
      name: event.target.value,
    });
  };

  const searchCustomer = ({ name }) => {
    getRequest(`${baseUrl}/customer/${userAdditionalInfo?.customerId}/employees/search?name=${name}`)
      .then((res) => {
        setSearchedEmployees(res.payload);
      })
      .catch((error) => console.error(error));
  };

  const handleSlectedEmployee = (employee) => {
    setSelectedEmployee(employee);
    let request = "";
    if (isEmptyObject(loggedInUser)) {
      request = {
        customer: `customer/${loggedInUser?.customerId}/employee/${employee?.id}`,
        edge: `employee/${employee?.id}`,
      };

      dispatch(
        GetEmployeeDetails({
          request: request[loggedInUserType],
        })
      );
    }
  };

  //methods

  useEffect(() => {
    if (loggedInUser?.customerId) {
      dispatch(
        EmployeeInfo({
          customerID: loggedInUser?.customerId,
          rowsPerPage: 500,
          page: 0,
          dist: apiFilters,
          sort: {
            field: "Edge_Joining_Date",
            direction: sortBy ? "asc" : "desc",
          },
        })
      );
    }
  }, [loggedInUser, apiFilters, sortBy]);

  useEffect(() => {
    if (isValidResponse(getEmployeeDetails)) {
      if (selectedEmployee?.id) {
        navigate(`/main/details/${selectedEmployee?.id}`);
      }
    }
  }, [getEmployeeDetails]);

  useEffect(() => {
    if (apiFilters?.name) {
      clearTimeout(timer);
      const newTimer = setTimeout(() => {
        searchCustomer(apiFilters);
      }, 500);

      setTimer(newTimer);
    }
  }, [apiFilters.name]);

  useLayoutEffect(() => {
    setWidth(ref.current.offsetWidth);
  }, []);

  return (
    <>
      {loadingGET_EMPLOYEE_Details == true || loadingAdditionalInfo == true || loadingEmployeesInfo == true ? (
        <PageLoader />
      ) : (
        <></>
      )}
      <Grid
        container
        direction="column"
        rowSpacing={3}
        sx={{
          backgroundColor: "#FBFAFF",
          height: "calc(100vh - 22px)",
          display: "block",
        }}
      >
        <Grid container rowSpacing={3} sx={{ background: "white", marginTop: "29px" }}>
          <Grid
            sx={{
              maxWidth: "1076px",
              width: "100%",
              margin: "0px auto",
              padding: "0px 0px 12px 0px",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <PageTitle
              page={{
                title: "Workforce",
                linkName: "Workforce",
                link: "",
              }}
            />
            <Button
              type="button"
              variant="primary"
              size="md"
              leadIcon={
                <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M9.22674 5.9589L3.52208 11.6622C3.21821 11.9309 3.03023 12.3069 3 12.6509V15.0022L5.29799 15.0036C5.69866 14.9753 6.07162 14.7888 6.37109 14.4462L12.0425 8.77479L9.22674 5.9589ZM10.2875 4.89836L13.1032 7.71413L14.8986 5.91872C14.9635 5.85376 15 5.76567 15 5.67381C15 5.58195 14.9635 5.49386 14.8986 5.4289L12.5708 3.10114C12.5066 3.03641 12.4192 3 12.328 3C12.2368 3 12.1494 3.03641 12.0852 3.10114L10.2875 4.89836ZM12.328 1.5C12.8191 1.5 13.2898 1.69606 13.6336 2.04257L15.9593 4.36824C16.3055 4.7145 16.5 5.18413 16.5 5.67381C16.5 6.16349 16.3055 6.63312 15.9593 6.97938L7.46813 15.4678C6.94427 16.0721 6.20167 16.4434 5.35095 16.5017H1.5V15.7517L1.50244 12.5905C1.56633 11.7996 1.934 11.0642 2.49487 10.5701L11.0215 2.04347C11.3673 1.69562 11.8375 1.5 12.328 1.5Z"
                    fill="white"
                  />
                </svg>
              }
              style={{
                fontFamily: "Poppins-Medium",
              }}
              onClick={() => navigate("/main/customers/editPayroll")}
            >
              {`Edit ${getMonthFromDate(userAdditionalInfo?.paymentDate)} payroll`}
            </Button>
          </Grid>
        </Grid>
        <Grid
          sx={{
            maxWidth: "1076px",
            width: "100%",
            margin: "0px auto",
            position: "relative",
            zIndex: "2",
          }}
        >
          <Grid
            sx={{
              background: "#FFFFFF",
              border: "1px solid #F1EFFF",
              borderRadius: "99px",
              padding: "6px 12px 6px 6px",
              marginTop: "16px",
              position: "relative",
            }}
          >
            <Grid
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "8px",
              }}
            >
              <TextField
                ref={ref}
                type="search"
                id="search"
                onChange={handleSearchEmployee}
                placeholder="Search employees"
                sx={{
                  ".MuiInputBase-root": {
                    borderRadius: "99px",
                    borderColor: "#BFC2FF",
                    outlineColor: "#BFC2FF",
                    fontFamily: "Poppins-Regular",
                    fontSize: "14px",
                    lineHeight: "20px",
                    paddingRight: "6px",
                    border: "1px solid #BFC2FF",
                  },
                }}
                InputProps={{
                  disableUnderline: true,
                  endAdornment: (
                    <InputAdornment position="start">
                      <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M14.9066 13.6148L19.8274 18.5355L18.5356 19.8272L13.6149 14.9065C12.3775 15.8684 10.8227 16.4412 9.13409 16.4412C5.09844 16.4412 1.8269 13.1696 1.8269 9.13397C1.8269 5.09832 5.09844 1.82678 9.13409 1.82678C13.1697 1.82678 16.4413 5.09832 16.4413 9.13397C16.4413 10.8226 15.8685 12.3774 14.9066 13.6148ZM9.13409 14.6144C12.1608 14.6144 14.6145 12.1607 14.6145 9.13397C14.6145 6.10724 12.1608 3.65358 9.13409 3.65358C6.10736 3.65358 3.6537 6.10724 3.6537 9.13397C3.6537 12.1607 6.10736 14.6144 9.13409 14.6144Z"
                          fill="#00201C"
                        />
                      </svg>
                    </InputAdornment>
                  ),
                }}
              />
              {apiFilters?.name?.length >= 3 && (
                <Grid
                  sx={{
                    position: "absolute",
                    top: "60px",
                    backgroundColor: "#FFFFFF",
                    boxShadow: " 0px 1px 1px rgba(2, 0, 110, 0.22), 0px 1px 6px rgba(2, 0, 110, 0.25)",
                    padding: "18px 0px",
                    borderRadius: "12px",
                    zIndex: 10,
                    maxHeight: "23rem",
                    overflow: "scroll",
                    width: { width },
                  }}
                >
                  {searchedEmployees.length > 0 ? (
                    searchedEmployees?.map((employee) => {
                      return <Employee handleSlectedEmployee={handleSlectedEmployee} employee={employee} />;
                    })
                  ) : (
                    <Typography
                      sx={{
                        fontFamily: "Poppins-Regular",
                        fontSize: "12px",
                        lineHeight: "16px",
                        color: "#3D43BB",
                        textAlign: "center",
                      }}
                    >
                      No Result Found
                    </Typography>
                  )}
                </Grid>
              )}

              <Button
                type="button"
                variant="outlined"
                size="sm"
                id="out-today-btn"
                style={{
                  fontFamily: "Poppins-Medium",
                  fontSize: "14px",
                  lineHeight: "20px",
                }}
              >
                <div
                  style={{
                    width: "17px",
                    height: "17px",
                    clipPath: "circle()",
                  }}
                  className="bg-error-50"
                />
                Out today
              </Button>

              <div style={{ position: "relative" }}>
                <button
                  id="country-selector-workforce"
                  style={{
                    border: "1px solid #777680",
                    borderRadius: "22px",
                    padding: "6px 16px",
                    color: "#14151f",
                    backgroundColor: "transparent",
                    fontFamily: "Poppins-Medium",
                    fontSize: "14px",
                    lineHeight: "20px",
                    letterSpacing: "0.1px",
                    cursor: "pointer",
                    whiteSpace: "nowrap",
                    display: "flex",
                    flexDirection: "column",
                    borderBottom: `${open ? "none" : "1px solid #777680"}`,
                    borderBottomLeftRadius: `${open ? "0px" : "22px"}`,
                    borderBottomRightRadius: `${open ? "0px" : "22px"}`,
                    backgroundColor: selectedCountry != "All" ? "#F1EFFF" : "transparent",
                  }}
                  onClick={() => setOpen(!open)}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "8px",
                    }}
                  >
                    {selectedCountry != "All" ? selectedCountry : "Select Country"}
                    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M5.25 7.5L9 11.25L12.75 7.5H5.25Z" fill="#3D3E5C" />
                    </svg>
                  </div>
                </button>
                {open && (
                  <List
                    sx={{
                      position: "absolute",
                      top: "16px",
                      left: "0px",
                      padding: "12px 0px 16px",
                      marginTop: "12px",
                      listStyle: "none",
                      width: "100%",
                      border: "1px solid #777680",
                      borderRadius: "22px",
                      borderTop: `${open && "none"}`,
                      borderTopLeftRadius: `${open && "0px"}`,
                      borderTopRightRadius: `${open && "0px"}`,
                      backgroundColor: "#FFFFFF",
                    }}
                  >
                    <ListItem
                      onClick={() => {
                        setSelectedCountry("All");
                        setApiFilters({
                          ...apiFilters,
                          countries: [],
                        });
                        setOpen(false);
                      }}
                      sx={{
                        padding: "6px 16px",
                        fontFamily: "Poppins-Medium",
                        fontSize: "14px",
                        lineHeight: "20px",
                        color: "#3D3E5C",
                        cursor: "pointer",
                        color: selectedCountry === "All" ? "#4950C7" : "#3D3E5C",
                        backgroundColor: selectedCountry === "All" ? "#F1EFFF" : "unset",
                        "&:hover": {
                          backgroundColor: "#F1EFFF",
                          color: "#4950C7",
                        },
                      }}
                    >
                      All
                    </ListItem>
                    {employeesInfo?.countryList?.map((country) => {
                      return (
                        <ListItem
                          onClick={() => {
                            setSelectedCountry(country);
                            setApiFilters({
                              ...apiFilters,
                              countries: [country],
                            });
                            setOpen(false);
                          }}
                          id="country-list-item"
                          sx={{
                            padding: "6px 16px",
                            fontFamily: "Poppins-Medium",
                            fontSize: "14px",
                            lineHeight: "20px",
                            wordBreak: "break-word",
                            color: "#3D3E5C",
                            cursor: "pointer",
                            color: selectedCountry === country ? "#4950C7" : "#3D3E5C",
                            backgroundColor: selectedCountry === country ? "#F1EFFF" : "unset",
                            "&:hover": {
                              backgroundColor: "#F1EFFF",
                              color: "#4950C7",
                            },
                          }}
                        >
                          {country}
                        </ListItem>
                      );
                    })}
                  </List>
                )}
              </div>
              <Button
                type="button"
                variant="outlined"
                id="join-date-btn"
                size="sm"
                style={{
                  fontFamily: "Poppins-Medium",
                  fontSize: "14px",
                  lineHeight: "20px",
                }}
                onClick={() => setSortBy((prev) => !prev)}
                tailIcon={
                  <div
                    style={{
                      transform: !sortBy && "rotate(-180deg)",
                    }}
                  >
                    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M9.74998 4.06045V16.5H8.24998V4.06045L3.53023 8.7802L2.46973 7.7197L8.99998 1.18945L15.5302 7.7197L14.4697 8.7802L9.74998 4.06045Z"
                        fill="black"
                      />
                    </svg>
                  </div>
                }
              >
                Join Date
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          sx={{
            maxWidth: "1076px",
            width: "100%",
            margin: "0px auto",
          }}
        >
          <Typography
            sx={{
              marginTop: "20px",
              fontFamily: "Poppins-Regular",
              fontSize: "14px",
              lineHeight: "20px",
              color: "#212231",
            }}
          >
            All results ({employeesInfo?.rows?.length}/{employeesInfo?.rows?.length})
          </Typography>
        </Grid>
        <Grid
          sx={{
            maxWidth: "1076px",
            width: "100%",
            margin: "0px auto",
          }}
        >
          <Grid
            container
            sx={{
              paddingTop: "15px",
              display: "flex",
              gap: "17.2px",
            }}
          >
            {employeesInfo?.rows?.map((workforce) => {
              return (
                <Grid sx={{ width: "165px" }}>
                  <WorkforceCard key={id} data={workforce} />
                </Grid>
              );
            })}
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}

export default index;
