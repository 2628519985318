import { call, put, takeEvery } from "redux-saga/effects";
//actions
import {
  AddUserPermissionsSuccess,
  AddUserPermissionsFailure,
} from "../../actions/Settings/AddUserPermissions";
//constant
import { ADD_USER_PERMISSIONS } from "../../constants/Settings/AddUserPermissions";
//request type
import postRequest from "../../../util/APIHelperPost";
import { baseUrl } from "../../../util/APIBaseUrl";

export const fetchAddUserPermissionsRequest = async (actions) => {
  // eslint-disable-next-line no-return-await
  let body = JSON.stringify({
    email: actions.payload?.userProfileData?.email,
    firstName: actions.payload?.userProfileData?.name.split(" ")[0],
    lastName: actions.payload?.userProfileData?.name.split(" ")[1],
    country: actions.payload?.userProfileData?.country?.value,
    phoneNumber: actions.payload?.userProfileData?.phoneNumber,
  });

  return await postRequest(`${baseUrl}/${actions.payload.request}`, body)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

function* fetchAddUserPermissions(params) {
  try {
    let response = yield call(fetchAddUserPermissionsRequest, params);
    yield put(AddUserPermissionsSuccess(response));
  } catch (error) {
    console.error(error);
    yield put(AddUserPermissionsFailure(error));
  }
}

export default function* rootSaga() {
  yield takeEvery(ADD_USER_PERMISSIONS, fetchAddUserPermissions);
}
