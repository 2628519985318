import { call, put, takeEvery } from "redux-saga/effects";
//actions
import { GetPaymentDetailsSuccess, GetPaymentDetailsFailure } from "../../actions/Payroll/GetPaymentDetails";
//constant
import { GET_PAYMENT_DETAILS } from "../../constants/Payroll/GetPaymentDetails";
//request type
import getRequest from "../../../util/APIHelperGet";
import { baseUrl } from "../../../util/APIBaseUrl";

export const fetchPaymentDetailsRequest = async (actions) => {
  // eslint-disable-next-line no-return-await

  return await getRequest(`${baseUrl}/customer/${actions.payload.customerId}/payment/${actions.payload.paymentId}`)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

function* fetchPaymentDetails(params) {
  try {
    let response = yield call(fetchPaymentDetailsRequest, params);
    yield put(GetPaymentDetailsSuccess(response));
  } catch (error) {
    console.error(error);
    yield put(GetPaymentDetailsFailure(error));
  }
}

export default function* rootSaga() {
  yield takeEvery(GET_PAYMENT_DETAILS, fetchPaymentDetails);
}
