import {
  RESER_SALARY_INFO,
  SALARY_INFO,
  SALARY_INFO_SUCCESS,
  SALARY_INFO_FAILURE,
} from "../../../constants/Workforce/EmployeeDetils/SalaryInfo";

// for user authentication
export const ResetemployeeSalaryInfo = (data) => {
  return {
    type: RESER_SALARY_INFO,
    payload: data,
  };
};

export const employeeSalaryInfo = (data) => {
  return {
    type: SALARY_INFO,
    payload: data,
  };
};
export const employeeSalaryInfoSuccess = (payload) => {
  return {
    type: SALARY_INFO_SUCCESS,
    payload,
  };
};
export const employeeSalaryInfoFailure = (payload) => {
  return {
    type: SALARY_INFO_FAILURE,
    payload,
  };
};
