import { call, put, takeEvery } from "redux-saga/effects";

import {
  EmployeeExperienceSuccess,
  EmployeeExperienceFailure,
} from "../../actions/EmployeeExperience";

import { EMPLOYEE_PREV_EXP } from "../../constants/EmployeeExperience";

import getRequest from "../../../util/APIHelperGet";
import { baseUrl } from "../../../util/APIBaseUrl";

export const fetchEmployeeExperienceRequest = async (actions) => {
  return await getRequest(`${baseUrl}/${actions.payload}`)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

function* fetchEmployeeExperience(params) {
  try {
    let response = yield call(fetchEmployeeExperienceRequest, params);
    yield put(EmployeeExperienceSuccess(response));
  } catch (error) {
    console.error(error);
    yield put(EmployeeExperienceFailure(error));
  }
}

export default function* rootSaga() {
  yield takeEvery(EMPLOYEE_PREV_EXP, fetchEmployeeExperience);
}
