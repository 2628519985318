import React from "react";

function UpcommingHolidayRightIcon(props) {
  return (
    <svg width="37" height="37" viewBox="0 0 37 37" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="18.5" cy="18.5" r="18.5" fill="#F1EFFF" />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M22.7414 17.8231L18.0228 13.2289L19.0551 12.1687L25.5838 18.5252L19.2273 25.0539L18.1671 24.0216L22.7612 19.303L11.9685 19.4472L11.9488 17.9674L22.7414 17.8231Z"
        fill="#3035AE"
      />
    </svg>
  );
}

export default UpcommingHolidayRightIcon;
