import { baseUrl } from "util/APIBaseUrl";
import getRequest from "util/APIHelperGet";

export const getEmployeesNewActivity = async (payload) => {
  try {
    const { employeeId, startDate, endDate, country, leaveTypeId, flag, customerId, sortBy } = payload;
    let urlSearchParams = {
      employeeId,
      customerId,
      startDate: startDate || "",
      endDate: endDate || "",
      country: country || "",
      leaveTypeId: leaveTypeId || "",
      flag: flag || "",
    };

    if (sortBy?.fieldName) {
      urlSearchParams = {
        ...urlSearchParams,
        orderBy: JSON.stringify({
          field: sortBy?.fieldName,
          direction: sortBy?.direction,
        }),
      };
    }
    const response = await getRequest(`${baseUrl}/admin/leaveRequests?` + new URLSearchParams(urlSearchParams));

    return response.payload;
  } catch (error) {
    console.log(error);
  }
};

export const getEmployeesLeaveRequestById = async (requestId) => {
  try {
    const response = await getRequest(`${baseUrl}/admin/leave/${requestId}`, true);
    return response.payload;
  } catch (error) {
    console.log(error);
  }
};
