import React from "react";
import { FormProvider, useForm } from "react-hook-form";
import { Avatar, Box } from "@mui/material";

import "components/Screens/SettingV2/AddUser/AssignEmployeeModal/AssignEmployeeModalForm/AssignEmployeeModalForm.scss";
import SearchableAutoComplete from "components/common/AutoComplete";
import { CardHeader } from "components/common/Card";
import CustomChip from "components/common/Chip";

import { baseUrl } from "util/APIBaseUrl";

function AssignEmployeeModalForm({ setAddBtnDisable, setSelectedEmployeeIds, employeeList }) {
  const optionList = employeeList
    ?.sort((a, b) => a.firstName.localeCompare(b.firstName))
    .map((employee) => ({
      label: `${employee.firstName} ${employee.lastName}`,
      email: employee.email,
      disabled: employee.manager,
      value: employee.employeeId,
      render: () => {
        return (
          <div className="flex items-center card-header-container">
            <CardHeader
              onClick={(event) => {
                if (employee.manager) {
                  event.stopPropagation();
                }
              }}
              disabled={employee.manager}
              title={`${employee.firstName} ${employee.lastName}`}
              subheader={employee.email}
              avatar={<Avatar src={`${baseUrl}/employee/${employee.employeeId}/logo?id=${employee?.pictureVersion}`} />}
              rightAction={
                employee.manager && (
                  <Box ml="auto">
                    <CustomChip
                      disabled
                      sx={{ maxWidth: 220 }}
                      label={`Managed by  ${employee?.manager?.firstName} ${employee?.manager?.lastName}`}
                    />
                  </Box>
                )
              }
            />
          </div>
        );
      },
    }));

  const methods = useForm({ mode: "onChange" });
  const { watch } = methods;
  const employeeIds = watch("EmployeeList");
  const isEmpty = !employeeIds?.length;
  setAddBtnDisable(isEmpty);
  setSelectedEmployeeIds(employeeIds);

  return (
    <FormProvider {...methods}>
      <SearchableAutoComplete
        noPaddingInList
        label="Employee(s)"
        name="EmployeeList"
        size="medium"
        placeholder="Name or email address"
        options={optionList}
        filterOptions={(options, { inputValue }) =>
          options.filter(
            (option) =>
              option.label.toLowerCase().includes(inputValue.toLowerCase()) ||
              option.email.toLowerCase().includes(inputValue.toLowerCase())
          )
        }
        multiple
      />
    </FormProvider>
  );
}

export default AssignEmployeeModalForm;
