import {
  RESET_FORGOT_PASSWORD,
  FORGOT_PASSWORD,
  FORGOT_PASSWORD_SUCCESS,
  FORGOT_PASSWORD_FAILURE,
} from "../constants/ForgotPassword";

// for add employee in admin workforce table

export const ResetForgotPassword = (data) => {
  return {
    type: RESET_FORGOT_PASSWORD,
    payload: data,
  };
};

export const ForgotUserPassword = (data) => {
  return {
    type: FORGOT_PASSWORD,
    payload: data,
  };
};
export const ForgotPasswordSuccess = (payload) => {
  return {
    type: FORGOT_PASSWORD_SUCCESS,
    payload,
  };
};
export const ForgotPasswordFailure = (payload) => {
  return {
    type: FORGOT_PASSWORD_FAILURE,
    payload,
  };
};
