import React from "react";
import { settingPages } from "../SettingPages.enumn";
//components
import CompanyProfile from "./CompanyProfile";
import EditCompanyProfile from "./EditCompanyProfile";

function index(props) {
  //component state
  const [editProfile, setEditProfile] = React.useState(settingPages.COMPANY_PROFILE);
  //functions
  const handleEditChange = (edit) => {
    setEditProfile(edit);
  };

  return (
    <>
      {editProfile == settingPages.COMPANY_PROFILE ? (
        <CompanyProfile handleEditChange={handleEditChange} />
      ) : editProfile == settingPages.EDIT_COMPANY_PROFILE ? (
        <EditCompanyProfile handleEditChange={handleEditChange} />
      ) : null}
    </>
  );
}

export default index;
