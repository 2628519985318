import React from "react";

const BackgroundDocumentsIcon = () => {
  return (
    <svg
      width="51"
      height="50"
      viewBox="0 0 51 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="0.554443"
        y="0.218994"
        width="49.5107"
        height="49.5107"
        rx="24.7553"
        fill="#FBFAFF"
      />
      <g clip-path="url(#clip0_1122_18083)">
        <path
          d="M12.1523 35.3047C2.24947 32.3871 6.17373 13.9941 17.5873 16.7063C28.671 19.3402 36.7293 9.80084 40.4422 17.1669C41.8898 20.0386 41.1698 24.9585 33.1716 33.672C26.1967 41.2705 24.0313 38.8044 12.1523 35.3047Z"
          fill="#79A1E8"
        />
        <path
          d="M16.1093 40.7902C21.4117 40.7902 25.7101 40.5276 25.7101 40.2037C25.7101 39.8798 21.4117 39.6172 16.1093 39.6172C10.8069 39.6172 6.50842 39.8798 6.50842 40.2037C6.50842 40.5276 10.8069 40.7902 16.1093 40.7902Z"
          fill="#473CBA"
        />
        <path
          d="M25.5512 11.4656C26.5762 11.4656 27.407 12.2949 27.407 13.3178V17.0731H22.4581V11.4656H25.5512Z"
          fill="#473CBA"
        />
        <path
          d="M23.6953 28.8022V13.3178C23.6953 12.2949 24.5263 11.4656 25.5512 11.4656H13.5314C12.4513 11.4656 11.5757 12.3395 11.5757 13.4175V28.8022H23.6953Z"
          fill="#F9D015"
        />
        <path
          d="M11.5757 28.8022H23.6953V15.2314L11.5757 27.3276V28.8022Z"
          fill="#E5B900"
        />
        <path
          d="M25.5513 11.178H13.5315C12.3074 11.178 11.3098 12.1615 11.2886 13.3784C11.3579 13.3382 11.4422 13.3143 11.5421 13.3143C11.6777 13.3143 11.7847 13.3583 11.864 13.4275V13.4176C11.864 12.4999 12.612 11.7533 13.5315 11.7533H24.0901C23.6702 12.1442 23.4072 12.701 23.4072 13.318V28.5147H17.5931C17.7435 28.6575 17.7831 28.8962 17.7124 29.0899H23.6954C23.8545 29.0899 23.9836 28.9611 23.9836 28.8023V17.3607H27.4071C27.5662 17.3607 27.6952 17.232 27.6952 17.0731V13.3178C27.6953 12.1379 26.7335 11.178 25.5513 11.178ZM27.119 16.7855H23.9836V13.3178C23.9836 12.4551 24.687 11.7531 25.5514 11.7531C26.4158 11.7531 27.119 12.4551 27.119 13.3178V16.7855H27.119Z"
          fill="#4B31AA"
        />
        <path
          d="M25.3603 8.97472C25.2012 8.97472 25.0721 8.84595 25.0721 8.68712V6.69532C25.0721 6.53649 25.2012 6.40771 25.3603 6.40771C25.5194 6.40771 25.6485 6.53649 25.6485 6.69532V8.68712C25.6485 8.84595 25.5194 8.97472 25.3603 8.97472Z"
          fill="#4B31AA"
        />
        <path
          d="M28.222 9.77748C28.1685 9.77748 28.1144 9.76267 28.066 9.73154C27.9323 9.6454 27.8939 9.46737 27.9802 9.33392L29.0003 7.7562C29.0867 7.62276 29.265 7.58436 29.3987 7.6705C29.5324 7.75663 29.5709 7.93466 29.4846 8.06811L28.4644 9.64583C28.4093 9.7311 28.3167 9.77748 28.222 9.77748Z"
          fill="#4B31AA"
        />
        <path
          d="M30.2416 12.0744C30.1359 12.0744 30.0341 12.0161 29.9836 11.9154C29.9125 11.7732 29.9701 11.6005 30.1125 11.5295L31.4429 10.8656C31.5851 10.7946 31.7583 10.8521 31.8295 10.9943C31.9006 11.1364 31.843 11.3091 31.7007 11.3802L30.3702 12.044C30.3289 12.0647 30.2849 12.0744 30.2416 12.0744Z"
          fill="#4B31AA"
        />
        <path
          d="M19.6316 30.9122C20.6566 30.9122 21.4875 30.0829 21.4875 29.06V25.3047H16.5386V30.9122H19.6316Z"
          fill="#473CBA"
        />
        <path
          d="M17.7758 13.5757V29.0601C17.7758 30.083 18.6067 30.9123 19.6316 30.9123H7.61196C6.53178 30.9123 5.65613 30.0384 5.65613 28.9604V13.5757H17.7758Z"
          fill="white"
        />
        <path
          d="M17.7758 29.06V15.9626L5.65613 28.0588V28.9603C5.65613 30.0383 6.53178 30.9123 7.61188 30.9123H19.6316C18.6067 30.9123 17.7758 30.083 17.7758 29.06Z"
          fill="#E1F0FA"
        />
        <path
          d="M21.4876 25.0172H18.0641V13.5757C18.0641 13.4169 17.9351 13.2881 17.7759 13.2881H5.6562C5.49707 13.2881 5.36804 13.4169 5.36804 13.5757V28.9604C5.36804 30.1954 6.37466 31.2 7.61196 31.2H19.6317C20.814 31.2 21.7758 30.24 21.7758 29.06V25.3048C21.7758 25.146 21.6467 25.0172 21.4876 25.0172ZM7.61196 30.6248C6.69251 30.6248 5.94437 29.8781 5.94437 28.9604V13.8633H17.4877V29.06C17.4877 29.677 17.7507 30.2338 18.1706 30.6248H7.61196V30.6248ZM21.1994 29.0601C21.1994 29.9229 20.4961 30.6248 19.6317 30.6248C18.7673 30.6248 18.064 29.9229 18.064 29.0601V25.5924H21.1994V29.0601H21.1994Z"
          fill="#4B31AA"
        />
        <path
          d="M31.9148 32.1344C30.9287 32.1344 30.0437 31.7036 29.4373 31.0207V24.1247C29.4373 23.49 29.1047 22.9332 28.6043 22.6172V22.5563C28.6043 21.6246 27.8784 20.8311 26.9455 20.798C25.9661 20.7633 25.1611 21.5454 25.1611 22.5151V31.5186L24.5265 30.8852C23.8359 30.1959 22.7161 30.1959 22.0254 30.8852C21.3347 31.5744 21.3346 32.692 22.0253 33.3812L26.0935 37.4415C26.2284 37.5994 26.3755 37.7462 26.5336 37.8808L26.5917 37.9387L26.5923 37.9306C27.2654 38.4809 28.1257 38.8113 29.0636 38.8113H38.4532V32.1342H31.9148V32.1344Z"
          fill="white"
        />
        <path
          d="M36.0384 31.8079H32.001V39.1955H36.0384V31.8079Z"
          fill="#FF931E"
        />
        <path
          d="M41.5253 31.8079H35.2362V39.9705H41.5253V31.8079Z"
          fill="#473CBA"
        />
        <path
          d="M41.5253 31.5204H32.001C31.8419 31.5204 31.7129 31.6492 31.7129 31.808V31.8394C30.9327 31.7877 30.2329 31.4394 29.7254 30.9066V24.1249C29.7254 23.4469 29.3963 22.8453 28.8902 22.4675C28.8444 21.4169 27.9944 20.5477 26.9558 20.5108C26.4037 20.4897 25.8831 20.6905 25.4872 21.0718C25.0911 21.4534 24.873 21.9661 24.873 22.5153V30.8245L24.7303 30.6821C23.9286 29.8817 22.6237 29.8818 21.8217 30.682C21.4332 31.0697 21.2193 31.5852 21.2192 32.1334C21.2192 32.6817 21.4331 33.1971 21.8216 33.5848L25.9063 37.6617C26.6749 38.5417 27.8048 39.0993 29.0637 39.0993H31.7129V39.1958C31.7129 39.3546 31.8419 39.4834 32.001 39.4834H34.948V39.9707C34.948 40.1295 35.077 40.2583 35.2362 40.2583H41.5253C41.6844 40.2583 41.8135 40.1295 41.8135 39.9707V31.808C41.8135 31.6492 41.6844 31.5204 41.5253 31.5204ZM28.8924 23.29C29.0541 23.5286 29.1491 23.8157 29.1491 24.1248V30.0448C28.9845 29.6729 28.8924 29.2622 28.8924 28.8302V23.29ZM29.0637 38.524C28.1609 38.524 27.3358 38.1906 26.702 37.6422L22.229 33.1781C21.9494 32.899 21.7955 32.528 21.7955 32.1335C21.7955 31.7389 21.9496 31.3679 22.2292 31.0888C22.8065 30.5128 23.7457 30.5128 24.3229 31.0888L26.7373 33.4986C26.8498 33.611 27.0324 33.611 27.1448 33.4986C27.2574 33.3863 27.2574 33.2042 27.1448 33.0919L25.4494 31.3998V22.5152C25.4494 22.1234 25.605 21.7578 25.8875 21.4857C26.1573 21.2259 26.5087 21.0846 26.8825 21.0846C26.9001 21.0846 26.9177 21.085 26.9353 21.0856C27.6967 21.1127 28.3162 21.7725 28.3162 22.5564V28.8303C28.3162 30.7431 29.8222 32.3112 31.7129 32.4161V38.524H29.0637ZM34.9481 38.9081H32.2893V32.0956H34.9481V38.9081ZM41.2371 39.6829H35.5243V32.0956H41.2371V39.6829Z"
          fill="#4B31AA"
        />
        <path
          d="M33.6574 37.6974C33.8861 37.6974 34.0715 37.5124 34.0715 37.2841C34.0715 37.0559 33.8861 36.8708 33.6574 36.8708C33.4287 36.8708 33.2433 37.0559 33.2433 37.2841C33.2433 37.5124 33.4287 37.6974 33.6574 37.6974Z"
          fill="white"
        />
        <path
          d="M33.6575 37.985C33.2702 37.985 32.9552 37.6706 32.9552 37.2841C32.9552 36.8977 33.2702 36.5833 33.6575 36.5833C34.0447 36.5833 34.3598 36.8977 34.3598 37.2841C34.3598 37.6706 34.0447 37.985 33.6575 37.985ZM33.6575 37.1585C33.588 37.1585 33.5315 37.2148 33.5315 37.2841C33.5315 37.3535 33.588 37.4098 33.6575 37.4098C33.727 37.4098 33.7835 37.3535 33.7835 37.2841C33.7835 37.2148 33.7269 37.1585 33.6575 37.1585Z"
          fill="#4B31AA"
        />
      </g>
      <defs>
        <clipPath id="clip0_1122_18083">
          <rect
            width="36.4454"
            height="34.3824"
            fill="white"
            transform="translate(5.36804 6.40771)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default BackgroundDocumentsIcon;
