//employee constants
import {
    RESET_GET_EMPLOYEE_PAYROLL_BY_ID,
    GET_EMPLOYEE_PAYROLL_BY_ID,
    GET_EMPLOYEE_PAYROLL_BY_ID_SUCCESS,
    GET_EMPLOYEE_PAYROLL_BY_ID_FAILURE,
} from "../../constants/EmployeeSalaries/EmployeePayrollById";

const INIT_STATE = {
    employeePayrollById: {},
    loadingEmployeePayrollById: true,
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case RESET_GET_EMPLOYEE_PAYROLL_BY_ID:
            return INIT_STATE;
        case GET_EMPLOYEE_PAYROLL_BY_ID:
            return {
                ...state,
                loadingEmployeePayrollById: true,
            };
        case GET_EMPLOYEE_PAYROLL_BY_ID_SUCCESS:
            if (action.payload.ok) {
                return {
                    ...state,
                    loadingEmployeePayrollById: false,
                    employeePayrollById: action?.payload?.payload,
                };
            }
        // eslint-disable-next-line no-fallthrough
        case GET_EMPLOYEE_PAYROLL_BY_ID_FAILURE:
            return {
                ...state,
                loadingEmployeePayrollById: false,
                employeePayrollById: "Error in  getting Club Data",
            };
        default:
            return state;
    }
};
