import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Grid, Typography, Box } from "@mui/material";

import "../termsandpolicy.css";
import PageHeader from "components/common/PageHeader";
import { GetPlatformConfig } from "store/actions/PlatformConfig";

const Terms = () => {
  const dispatch = useDispatch();
  const platformConfig = useSelector((state) => state.PlatformConfig.platformConfig);
  const loadingplatformConfig = useSelector((state) => state.PlatformConfig.loadingplatformConfig);

  useEffect(() => {
    if (!platformConfig?.payload && !loadingplatformConfig) {
      dispatch(GetPlatformConfig());
    }
  }, []);

  return (
    <>
      <Box sx={{ overflow: "auto" }}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <PageHeader title={"Terms of Service"} subTitle={"Last Updated: February 5, 2024"} />
          </Grid>
          <Grid item xs={12} container justifyContent="center" alignItems="center">
            <Grid item xs={8} sx={{ marginY: 6 }}>
              <Typography className="label-terms">
                Welcome to onedge.co (the <b className="label-terms">“Edge Platform”</b>) which is owned and operated by
                Edge Services & Solutions LLC and our affiliated entities (
                <b className="label-terms">“Edge”, “Company”, “we”</b> or <b className="label-terms">“us”</b>).
              </Typography>
              <br />
              <Typography className="label-terms">
                These terms of service (this <b className="label-terms">“Agreement”</b> or these{" "}
                <b className="label-terms">“Terms”</b>) form a binding agreement between you as a user (
                <b className="label-terms">“you”</b> or <b className="label-terms">“your”</b>) and us and shall be the
                equivalent of a written paper agreement between you and us. The effective date of this Agreement is when
                you accept or are deemed to accept this Agreement as discussed below.
              </Typography>
              <br />
              <Typography className="label-terms">
                These Terms apply to your use of our services and software provided on the Edge Platform, any services,
                content, communications, and product features relating to the Edge Platform and as set forth in these
                Terms (the <b className="label-terms">“Services”</b>).
              </Typography>
              <br />
              <Typography className="label-terms">
                NOTE: THESE TERMS CONTAIN A BINDING ARBITRATION PROVISION WITH A CLASS ACTION WAIVER AND A WAIVER OF THE
                RIGHT TO A JURY. PLEASE REVIEW SECTION 23 BELOW BEFORE DOWNLOADING OR USING OUR SERVICES.
              </Typography>
              <br />
              <Typography className="label-terms">
                We reserve the right to make modifications to these Terms at any time and for any reason. Please check
                these Terms regularly to ensure you are aware of any modifications made by us. By continuing to access
                or use the Services or by clicking to accept or agree to the revised Terms after those revisions become
                effective, you agree to be bound by the revised Terms. If you do not agree to these Terms of Service,
                you must immediately stop using our services.
              </Typography>
              <ol start="1" className="">
                <li>
                  <Typography className="label-terms flex">
                    <Typography className="terms-list-items">
                      <b>Platform Service.</b> Edge provides a platform that enables Customers and Employees to connect
                      and manage their business relationships. <b className="label-terms">“Customers”</b> are
                      individuals and/or businesses seeking to retain Employees to provide services, and{" "}
                      <b className="label-terms">“Employees”</b> are individuals and/or businesses seeking to provide
                      services for Customers. Customers and Employees are hereinafter referred to as “Users.” Subject to
                      these Terms, we provide the Services including hosting and maintaining the Edge Platform, offering
                      a global talent marketplace for Customers and Employees, conducting background checks, automating
                      payroll, providing HR services, assisting with regulation and compliance issues, and more. You
                      acknowledge that Edge does not guarantee any particular User’s offered services.
                    </Typography>
                  </Typography>
                </li>
                <br />
                <li>
                  <Typography className="label-terms flex">
                    <Typography className="terms-list-items">
                      <b>General.</b> Subject to the terms of this Agreement, you are responsible for your use of the
                      Services, and for any information, content, reports, data, databases, graphics, interfaces, web
                      pages, text, files, software, product names, company names, trademarks, logos and trade-names
                      (collectively, the <b className="label-terms">“Content”</b>) you post to the Services, any Content
                      you access from the Services, and for any consequences thereof.
                    </Typography>
                  </Typography>
                </li>
                <br />
                <li>
                  <Typography className="label-terms flex justify-center">
                    <Typography className="terms-list-items">
                      <b>Privacy; Data Protection.</b> By accessing or using the Services, you intend to and expressly
                      agree to be bound by all the terms and conditions of this Agreement and our Privacy Policy
                      (available at{" "}
                      <a href={platformConfig?.payload?.TERMS_URL} className="link-class" target="blank">
                        {platformConfig?.payload?.TERMS_URL}
                      </a>
                      ) (the <b className="label-terms">“Privacy Policy”</b>), which is incorporated herein by
                      reference. If you do not agree to these terms and conditions, you may not use the Services. Any
                      information that you provide to us is subject to the Privacy Policy, which governs our collection
                      and use of your information. You understand that through your use of the Services you consent to
                      the collection and use of your information as set forth in the Privacy Policy.
                      <br />
                      <br />
                      You acknowledge and agree that Edge may collect and process your personal data in connection with
                      your receipt of the Services. Edge shall process such personal data in accordance with Edge’s Data
                      Processing Addendum (available at{" "}
                      <a href="onedge.co/data-processing-addendum" className="link-class" target="blank">
                        onedge.co/data-processing-addendum
                      </a>
                      ) which forms an integral part of these Terms.
                    </Typography>
                  </Typography>
                </li>
                <br />
                <li>
                  <Typography className="label-terms flex justify-center">
                    <Typography className="terms-list-items">
                      <b>Accounts.</b> Some Services offered by the Company require you to create an account. If you
                      create an account, you agree that all the information you provide will be true and accurate, and
                      that you have the authority to create the account. You are responsible for keeping your account
                      information secure and confidential. You agree to inform us immediately if you suspect that your
                      account or login information has been compromised. If you provide the Company an email address as
                      part of an account creation, the Company may furnish you with written correspondence regarding
                      your account and/or other Edge-related material, including promotional materials.
                    </Typography>
                  </Typography>
                </li>
                <br />
                <li>
                  <Typography className="label-terms flex justify-center">
                    <Typography className="terms-list-items">
                      <b>Eligibility.</b> You may provide access to the Services to other Users within your organization
                      as authorized by us and/or by your subscription level to our Services in place from time to time
                      (each, an <b className="label-terms">“Authorized User”</b>). You are solely responsible for
                      Authorized User activities and interaction with the Services. You agree to ensure all Authorized
                      Users are aware of all restrictions of use in these Terms, the Privacy Policy, and any other rules
                      or requirements applicable to the Services. You agree to cause Authorized Users to comply with
                      such provisions. You are solely responsible for all actions taken by and interactions with
                      Authorized Users, including providing any disclosures governing an Authorized User’s interaction
                      with the Services, and any applicable Privacy Policy or further obligations required by us or any
                      applicable law. Any obligation imposed on you by these Terms shall be applicable to you and to any
                      Authorized User.
                      <br />
                      <br />
                      By using our Services, you represent and warrant that you and each Authorized User:
                      <ul>
                        <li className="terms-list-text">If an individual, are at least 18 years old;</li>
                        <li className="terms-list-text">Are legally qualified to enter a binding contract with us;</li>
                        <li className="terms-list-text">
                          Are not located in a country that is subject to a U.S. Government embargo, or that has been
                          designated by the U.S. Government as a “terrorist supporting” country;
                        </li>

                        <li className="terms-list-text">
                          Are not on any list of individuals prohibited from conducting business with the United States;
                        </li>

                        <li className="terms-list-text">Are not prohibited by law from using our Services;</li>

                        <li className="terms-list-text">Do not have more than one account on our Services; and</li>

                        <li className="terms-list-text">
                          Have not previously been removed from our Services by us, unless you have our express written
                          permission to create a new account.
                        </li>
                      </ul>
                      <br />
                      If at any time you cease to meet these requirements, we reserve the right to delete your account
                      with us, to revoke all credentials held by you and any of your Authorized Users and to delete all
                      Content uploaded by you to our Services.
                      <br /> <br />
                      In addition, your eligibility to use the Services as a Employee may require you to meet certain
                      minimum requirements mandated by the Company. Failure to meet these minimum requirements may be
                      grounds for the Company to deny you access to the Services as an Employee. The Company shall have
                      sole discretion to mandate, interpret, and enforce any such minimum requirements.
                    </Typography>
                  </Typography>
                </li>
                <br />
                <li>
                  <Typography className="label-terms flex justify-center">
                    <Typography className="terms-list-items">
                      <b>Intellectual Property Ownership.</b> To the extent permitted by applicable law, Edge shall
                      assign all intellectual property rights such that Customer shall be the sole and exclusive owner
                      of all right, title and interest in and to any and all materials and other deliverables provided
                      or created by a Employee in the provision of services to Customer via the Edge Platform (
                      <b className="label-terms">“Customer IP”</b>). Edge shall ensure that Employee, as part of its
                      engagement by Edge and its provision of services for Customer via the Edge Platform, shall
                      execute, in accordance with the local laws and regulations where it performs the services, an
                      invention assignment agreement in favor of Edge in connection with the Customer IP. Subject to the
                      terms and conditions hereof, Edge shall assign all right, title and interest in and to any
                      Customer IP to Customer.
                      <br /> <br />
                      Except as otherwise specifically stated herein with respect to the assignment of Customer IP, Edge
                      does not assign and expressly retains all intellectual property rights in all other Edge
                      materials, including the Edge Platform and the Services (<b className="label-terms">“Edge IP”</b>
                      ). No User has any right, license, or authorization with respect to the Edge IP except as
                      expressly set forth herein. All other rights in and to the Edge IP are expressly reserved by Edge.
                      All other rights in and to the Edge IP are expressly reserved by Edge. In furtherance of the
                      foregoing, Users hereby unconditionally and irrevocably grant to Edge an assignment of all right,
                      title, and interest in and to the Resultant Data, including all intellectual property rights
                      relating thereto. <b className="label-terms">“Resultant Data”</b> means data and information
                      related to a User’s use of the Services that is used by Edge in an aggregate and anonymized
                      manner, including to compile statistical and performance information related to the provision and
                      operation of the Services.
                    </Typography>
                  </Typography>
                </li>
                <br />
                <li>
                  <Typography className="label-terms flex justify-center">
                    <Typography className="terms-list-items">
                      <b>User Content.</b> The Services may allow you from time to time to upload Content and permit the
                      hosting, sharing, or publishing of Content. You have sole responsibility for all Content uploaded
                      to the Services by you. We are not responsible and make no representation or warranty concerning
                      any Content uploaded to the Services by any third party. We reserve the right to impose
                      restrictions on the Content you upload, including, but not limited to, the amount of Content that
                      can be uploaded and the length or expiry period for Content. You shall be solely responsible for
                      the Content you submit and the consequences of our posting, display, distribution, or publishing
                      of such Content. In connection with any Content you or any of your Authorized Users submit, you
                      affirm, represent, and warrant that: (i) you own or have the necessary licenses, rights, consents,
                      and permissions to use and authorize us to use all patent, trademark, trade secret, copyright or
                      other proprietary rights in and to any and all Content to enable inclusion and use of the Content
                      in the manner contemplated by these Terms; and (ii) you have the written consent, release, or
                      permission to use the name, image, or likeness of each individual identified in the Content.
                      <br /> <br />
                      Without limiting the foregoing, you agree that you will not transmit, submit or upload any Content
                      to the Edge Platform and/or Services or act in any way that: (a) restricts or inhibits use of the
                      Services; (b) imposes an unreasonably or disproportionately large load on our infrastructure; (c)
                      violates the legal rights of others, including defaming, abuse, stalking or threatening Users; (d)
                      infringes (or results in the infringement of) the intellectual property rights, moral rights,
                      publicity, privacy, or other rights of any third party; (e) is (or you reasonably believe or
                      should reasonably believe to be) stolen, illegal, counterfeit, fraudulent, pirated, violent or
                      unauthorized, or in furtherance of any illegal, counterfeiting, fraudulent, pirating,
                      unauthorized, or violent activity, or that involves (or you reasonably believe or should
                      reasonably believe to involve) any stolen, illegal, counterfeit, fraudulent, pirated, or
                      unauthorized material; (f) does not comply with all applicable laws, rules and regulations; or (g)
                      posts, stores, transmits, offers, or solicits anything that contains the following, or that you
                      know contains links to the following or to locations that in turn contain links to the following:
                      material that we determine to be offensive (including material promoting or glorifying hate,
                      violence, bigotry, or any entity (past or present) principally dedicated to such causes or items
                      associated with such an entity), material that is racially or ethnically insensitive, defamatory,
                      harassing or threatening, pornography or obscene material, any virus, worm, trojan horse, or other
                      harmful or disruptive component; or anything that encourages conduct that would be considered a
                      criminal offense, give rise to civil liability, violate any law or regulation or is otherwise
                      inappropriate or offensive.
                      <br /> <br />
                      In connection with Content, you further agree that you will not submit material that is
                      copyrighted, protected by trade secret, or otherwise subject to third-party proprietary rights,
                      including privacy and publicity rights, unless you are the owner of such rights or have permission
                      from their rightful owner to post the material and to grant us the necessarily rights granted in
                      these Terms. You agree to defend, indemnify, and hold us harmless for all damage or liability we
                      incur because of your violation of this Section.
                    </Typography>
                  </Typography>
                </li>
                <br />
                <li>
                  <Typography className="label-terms flex justify-center">
                    <Typography className="terms-list-items">
                      <b>Non-Circumvention.</b> You acknowledge and agree that a substantial portion of the compensation
                      we receive for providing Customers with specialized Employees comes through the fees described in
                      Section 10, and that in exchange a substantial value to Customers is the relationships they make
                      with Employees identified through the Services (each, an{" "}
                      <b className="label-terms">“Edge-Sourced Employee”</b>). For 36 months from the time you retain an
                      Edge-Sourced Employee through the Services as a Customer (the{" "}
                      <b className="label-terms">“Non-Circumvention Period”</b>), you agree to use the Edge Platform as
                      your exclusive method to pay and receive services from such Edge-Sourced Employee. You shall pay
                      to Edge an amount of $97,200.00 for each Edge-Sourced Employee who you pay or from whom you
                      receive services during the Non-Circumvention Period in violation of this Section 8 (each, a{" "}
                      <b className="label-terms">“Non-Circumvention Breach”</b>). The parties intend that these damages
                      constitute compensation, and not a penalty. The parties acknowledge and agree that Edge’s harm
                      caused by a Non-Circumvention Breach would be impossible or very difficult to accurately estimate,
                      and that the liquidated damages are a reasonable estimate of the anticipated or actual harm that
                      might arise from a Non-Circumvention Breach. Your payment of the liquidated damages is your sole
                      liability and entire obligation and Edge’s exclusive remedy for any Non-Circumvention Breach. You
                      acknowledge and agree that in the event of a Non-Circumvention Breach, and after giving ten days
                      written notice to you, Edge Group may charge all funds and liquidated damages due hereunder
                      against any one of your payment methods held on file.
                    </Typography>
                  </Typography>
                </li>
                <br />
                <li>
                  <Typography className="label-terms flex justify-center">
                    <Typography className="terms-list-items">
                      <b>Compliance with Laws.</b> You agree to use the Services only for lawful purposes, and to
                      conduct your business and any transaction that uses the Services, in accordance with all
                      applicable laws and regulations, including all federal and state laws and regulations relating to
                      taxes, banking, money laundering, securities and counterterrorism. You will not use the Services
                      for any fraudulent or illegal activity.
                    </Typography>
                  </Typography>
                </li>
                <br />
                <li>
                  <Typography className="label-terms flex justify-center">
                    <Typography className="terms-list-items">
                      <b>Fees; Payment Processing.</b> In general, we make money by charging fees to Users relating to
                      use of our Services. The amount of our fees and charges may be disclosed on the appropriate page
                      on the Edge Platform summarizing the transaction and requiring your authorization before
                      proceeding or through a separate written agreement with you. By using our Services, you agree to
                      pay all fees. Subject to applicable law and as authorized by our agreements with you, we reserve
                      the right to adjust our pricing and fees at any time. All payments will be processed through
                      third-party payment processors. We use Stripe, Inc. (“Stripe”) as a third-party payment processor
                      and may use other sub-processors. We also use Plaid Inc. (“Plaid”), to facilitate communication
                      between your bank and credit card provider for purposes of facilitating transactions made through
                      the Services, and for related analytics and business services. The processing of payments will be
                      subject to Stripe’s Service Agreement (available at{" "}
                      <a href="https://stripe.com/ssa" className="link-class" target="blank">
                        https://stripe.com/ssa
                      </a>
                      ), its Privacy Policy (available at{" "}
                      <a href="https://stripe.com/privacy" className="link-class" target="blank">
                        https://stripe.com/privacy
                      </a>
                      ), and Plaid’s Privacy Policy (available at{" "}
                      <a href="https://plaid.com/legal/#consumers" className="link-class" target="blank">
                        https://plaid.com/legal/#consumers
                      </a>
                      ), in addition to the Privacy Policy and these Terms. We are not responsible for any errors by
                      Stripe or Plaid or any sub-processor. We have no control over, and shall not be responsible for,
                      the use of information collected through the websites of Stripe or Plaid website.
                    </Typography>
                  </Typography>
                </li>
                <br />
                <li>
                  <Typography className="label-terms flex justify-center">
                    <Typography className="terms-list-items">
                      <b>Your License to the Services.</b> Subject to your payment of applicable fees and continued
                      compliance with this Agreement and any separate agreements we may have with you, we hereby grant
                      you a limited, non-exclusive, revocable, non-transferable, non-sublicensable license to access the
                      Services as authorized in these Terms. Except for the limited license to access the Services
                      identified in the preceding paragraph, you acknowledge that nothing contained in these Terms shall
                      be construed as granting or conferring to you, by implication, estoppel or otherwise, any right,
                      title, or interest to any intellectual property of the Company, our third-party service providers
                      or any other third party, including any (i) inventions (whether patentable or not in any country),
                      patents, patent applications, invention disclosures, improvements, trade secrets, proprietary
                      information, know how, information, or technical data; (ii) copyright protected works, copyright
                      registrations, mask works, mask work registrations or applications in the United States or any
                      foreign country; (iii) trademarks, trademark registrations, service marks, logos or applications
                      therefor in the United States or any foreign country; (iv) trade secrets; or (v) any other
                      tangible or intangible proprietary rights anywhere in the world.
                    </Typography>
                  </Typography>
                </li>
                <br />
                <li>
                  <Typography className="label-terms flex">
                    <Typography className="terms-list-items">
                      <b>Restrictions On Use.</b> You agree that you will not:
                      <br />
                      <br />
                      <ol type="a">
                        <li className="terms-list-text pl-1">
                          Distribute the Services for any purpose, including, without limitation, compiling an internal
                          database, redistributing or reproducing the Services by way of the press or media or through
                          any commercial network, cable or satellite system;
                        </li>
                        <li className="terms-list-text pl-1">
                          Create derivative works of, reverse engineer, decompile, disassemble, adapt, translate,
                          transmit, arrange, modify, copy, bundle, sell, sublicense, export, merge, transfer, adapt,
                          loan, rent, lease, assign, share, outsource, host, publish, make available to any person or
                          otherwise use, either directly or indirectly, the Services in whole or in part, in any form or
                          by any means whatsoever, be they physical, electronic or otherwise;
                        </li>
                        <li className="terms-list-text pl-1">
                          Permit, allow or do anything that would infringe or otherwise prejudice the proprietary rights
                          of the Company or its licensors or allow any third party to access the Services;
                        </li>
                        <li className="terms-list-text pl-1">
                          Probe, scan or test the vulnerability of the Services and any system or network;
                        </li>
                        <li className="terms-list-text pl-1">
                          Breach or otherwise circumvent any security or authentication measures;
                        </li>
                        <li className="terms-list-text pl-1">
                          Access, tamper with, or use non-public areas or parts of the Services, or shared areas of the
                          Services you have not been invited to;
                        </li>
                        <li className="terms-list-text pl-1">
                          Access, search, or create accounts for the Services by any means other than our publicly
                          supported interfaces;
                        </li>
                        <li className="terms-list-text pl-1">
                          Promote or advertise products or services other than your own without appropriate
                          authorization;
                        </li>
                        <li className="terms-list-text pl-1">
                          Circumvent storage space, usage, or transaction limits for improper means, including
                          attempting to circumvent our fees;
                        </li>
                        <li className="terms-list-text pl-1">
                          Publish or share materials that are unlawful or indecent, or that contain extreme acts of
                          violence or terrorist activity;
                        </li>
                        <li className="terms-list-text pl-1">
                          Advocate bigotry or hatred against any person or group of people based on their race,
                          religion, ethnicity, sex, gender identity, sexual orientation, disability, or impairment;
                        </li>
                        <li className="terms-list-text pl-1">
                          Harass or abuse our personnel or representatives or agents performing services on behalf of
                          the Company;
                        </li>
                        <li className="terms-list-text pl-1">
                          Store, publish or share material that is fraudulent, defamatory, or misleading; or
                        </li>
                        <li className="terms-list-text pl-1">Violate the law in any way.</li>
                      </ol>
                    </Typography>
                  </Typography>
                </li>
                <br />
                <li>
                  <Typography className="label-terms flex justify-center">
                    <Typography className="terms-list-items">
                      <b>License Grant to Your Content.</b> Except for your non-public personal information, you hereby
                      grant to the Company the perpetual, unlimited, royalty-free, worldwide, non-exclusive,
                      irrevocable, transferable license to use, display, reproduce and store any Content or other
                      information you provide on or through the Services or which is sent to the Company by email or
                      other correspondence. The Company shall not be obligated to provide you compensation of any sort
                      for such license. The Company is not subject to any obligations of confidentiality regarding any
                      such Content or information unless specifically agreed to by the Company in writing or required by
                      law. You represent and warrant that you have the right to grant the license set out above.
                    </Typography>
                  </Typography>
                </li>
                <br />
                <li>
                  <Typography className="label-terms flex justify-center">
                    <Typography className="terms-list-items">
                      <b>Suspension of Transactions and Services.</b> Without limiting our ability to terminate under
                      this Agreement or our right to change, modify, suspend or discontinue the Services at any time as
                      set forth elsewhere in these Terms, we also reserve the right to cancel or suspend transactions
                      and/or our Services due to fraud or compliance-related concerns.
                    </Typography>
                  </Typography>
                </li>
                <br />
                <li>
                  <Typography className="label-terms flex justify-center">
                    <Typography className="terms-list-items">
                      <b>Third-Party Links and Services.</b> Our Services may contain links to other websites. The
                      Company does not assume responsibility for the accuracy or appropriateness of the information,
                      data, opinions, advice or statements contained at such websites, and when you access such
                      websites, you are doing so at your own risk. In providing links to the other websites, the Company
                      is in no way acting as a publisher or disseminator of the material contained on those other
                      websites and does not seek to monitor or control such websites. A link to another website should
                      not be construed to mean that the Company is affiliated or associated with same. The Company does
                      not recommend or endorse any of the content, including without limitation any hyperlinks to, or
                      content found, on other websites. The mention of another party or its product or service on the
                      Edge Platform should not be construed as an endorsement of that party or its product or service.
                      The Company will not be responsible for any damages you or any third party may suffer because of
                      the transmission, storage or receipt of confidential or proprietary information that you make or
                      that you expressly or implicitly authorize the Company to make, or for any errors or any changes
                      made to any transmitted, stored or received information.
                    </Typography>
                  </Typography>
                </li>
                <br />
                <li>
                  <Typography className="label-terms flex justify-center">
                    <Typography className="terms-list-items">
                      <b>Feedback.</b> If you or any of your employees or Authorized Users sends or transmits any
                      communications or materials to us by mail, email, telephone, or otherwise, suggesting or
                      recommending changes to the Services, including, without limitation, new features or functionality
                      relating thereto, or any comments, questions, suggestions, or the like (
                      <b className="label-terms">“Feedback”</b>), we are free to use such Feedback and any ideas,
                      know-how, concepts, techniques, or other intellectual property rights contained in the Feedback,
                      for any purpose whatsoever, without any attribution or compensation to any party, although we are
                      not required to use any Feedback.
                    </Typography>
                  </Typography>
                </li>
                <br />
                <li>
                  <Typography className="label-terms flex justify-center">
                    <Typography className="terms-list-items">
                      <b>Indemnity.</b> Except as expressly provided in a separate agreement, you agree to indemnify,
                      defend and hold harmless the Company, its agents, suppliers, licensors, affiliates and their
                      respective directors, officers and employees (collectively, the{" "}
                      <b className="label-terms">“Indemnified Parties”</b>) against all losses, liabilities, suits,
                      claims, actions, costs, damages and expenses (including, without limitation, reasonable attorneys’
                      fees and expenses) sustained, incurred or paid by any Indemnified Party directly or indirectly
                      resulting from, arising out of, relating to or in connection with: (i) violation of these Terms by
                      you, any Authorized User or other person using your account or credentials; (ii) your or any
                      Authorized User’s access to or use of the Services, (iii) any information used, stored, or
                      transmitted in connection with your account or credentials; (iv) breach of the rights of any third
                      party, including but not limited to privacy, publicity, intellectual property, or other
                      proprietary rights, by you, any Authorized User or anyone using your account or credentials; or
                      (v) violation of any law, regulation, or other legal requirement. This paragraph is intended to be
                      applicable to the maximum extent allowed by law.
                    </Typography>
                  </Typography>
                </li>
                <br />
                <li>
                  <Typography className="label-terms flex">
                    <Typography className="terms-list-items">
                      <b>Limitations on Liability.</b>
                      <br /> <br />
                      <ol type="a">
                        <li className="terms-list-text pl-1">
                          <b>NO WARRANTIES.</b> EXCEPT AS EXPRESSLY PROVIDED IN A SEPARATE AGREEMENT WITH YOU, THE
                          SERVICES ARE PROVIDED “AS IS” WITHOUT ANY REPRESENTATIONS, WARRANTIES, GUARANTEES OR
                          CONDITIONS, OF ANY KIND, WHETHER EXPRESS OR IMPLIED, STATUTORY OR OTHERWISE, INCLUDING, BUT
                          NOT LIMITED TO, WARRANTIES AS TO UNINTERRUPTED OR ERROR-FREE OPERATION, AVAILABILITY,
                          ACCURACY, COMPLETENESS, RELIABILITY, TIMELINESS, LEGALITY, SUITABILITY, PRIVACY, SECURITY,
                          MERCHANTABILITY, QUALITY, TITLE, NON-INFRINGEMENT OR FITNESS FOR A PARTICULAR PURPOSE, OR
                          THOSE ARISING OUT OF A COURSE OF DEALING OR USAGE OF TRADE.
                        </li>
                        <br />
                        <li className="terms-list-text pl-1">
                          <b>NO SPECIAL DAMAGES.</b> IN NO EVENT WILL THE COMPANY, ITS AFFILIATES, AGENTS, LICENSORS,
                          SUPPLIERS OR THEIR RESPECTIVE DIRECTORS, OFFICERS OR EMPLOYEES BE LIABLE FOR ANY SPECIAL,
                          INDIRECT, INCIDENTAL, PUNITIVE, EXEMPLARY, AGGRAVATED, ECONOMIC OR CONSEQUENTIAL DAMAGES,
                          HOWSOEVER CAUSED, INCLUDING BUT NOT LIMITED TO: DAMAGES FOR LOSS OF USE, LOST PROFITS OR LOST
                          SAVINGS, EVEN IF THE COMPANY OR ANY OF ITS LAWFUL AGENTS OR EMPLOYEES HAVE BEEN ADVISED OF THE
                          POSSIBILITY OF SUCH DAMAGES OR CLAIM.
                        </li>
                        <br />
                        <li className="terms-list-text pl-1">
                          <b>ADDITIONAL LIMITATION.</b> IN NO EVENT WILL COMPANY, ITS AFFILIATES, AGENTS, LICENSORS,
                          SUPPLIERS OR THEIR RESPECTIVE DIRECTORS, OFFICERS OR EMPLOYEES BE LIABLE FOR DAMAGES OR LOSSES
                          RESULTING FROM: VIRUSES, DATA CORRUPTION, FAILED MESSAGES, TRANSMISSION ERRORS OR PROBLEMS;
                          TELECOMMUNICATIONS SERVICE PROVIDERS; LINKS TO THIRD PARTY WEBSITES; PERSONAL INJURY; THIRD
                          PARTY CONTENT, PRODUCTS OR SERVICES; DAMAGES OR LOSSES CAUSED BY YOU OR YOUR RESPECTIVE
                          EMPLOYEES, AGENTS OR SUBCONTRACTORS; LOSS OF USE OR LACK OF AVAILABILITY OF FACILITIES,
                          INCLUDING COMPUTER RESOURCES, ROUTERS AND STORED DATA; THE USE OR INABILITY TO USE THE
                          SERVICES; ANY OTHER WEBSITES ACCESSED TO OR FROM THE SERVICES; OR EVENTS BEYOND THE REASONABLE
                          CONTROL OF THE COMPANY, EVEN IF THE COMPANY OR ANY OF ITS LAWFUL AGENTS OR EMPLOYEES HAVE BEEN
                          ADVISED OF THE POSSIBILITY OF SUCH DAMAGES OR CLAIM.
                        </li>
                        <br />
                        <li className="terms-list-text pl-1">
                          <b>AGGREGATE LIABILITY.</b> IN NO CASE WILL THE COMPANY'S, ITS AFFILIATES', AGENTS',
                          LICENSORS', SUPPLIERS' AND THEIR RESPECTIVE DIRECTORS', OFFICERS' AND EMPLOYEES' CUMULATIVE
                          TOTAL LIABILITY FOR ANY CAUSE WHATSOEVER (INCLUDING WITHOUT LIMITATION BREACH OF CONTRACT,
                          TORT, NEGLIGENCE, GROSS NEGLIGENCE OR OTHERWISE) ARISING UNDER OR RELATING TO THE SERVICES BE
                          MORE THAN THE VALUE OF THE FEES ACTUALLY PAID TO US BY YOU DURING THE SIX (6) MONTHS
                          IMMEDIATELY PRECEDING THE DATE OF ANY CLAIM GIVING RISE TO SUCH LIABILITY.
                        </li>
                      </ol>
                    </Typography>
                  </Typography>
                </li>
                <br />
                <li>
                  <Typography className="label-terms flex">
                    <Typography className="terms-list-items">
                      <b>Term and Termination.</b>
                      <br />
                      <br />
                      <ol type="a">
                        <li className="terms-list-text pl-1">
                          We may, at any time and in its sole discretion, without prior notice, and without any
                          liability to you, modify or discontinue any portion of our Services, either temporarily or
                          permanently.
                        </li>
                        <li className="terms-list-text pl-1">
                          At any time and in our sole discretion, without prior notice, and without any liability to
                          you, we may (i) cancel or deactivate your account; or (ii) temporarily or permanently,
                          suspend, restrict, or terminate access to any or all of Services for any reason or for no
                          reason. We are under no obligations to disclose its reason for any termination or suspension
                          of the Service to you or generally.
                        </li>
                        <li className="terms-list-text pl-1">
                          In the event of any termination of this Agreement, discontinuation of all Services, or
                          termination of your right to access all Services: (i) all fees and amounts payable to us by
                          you shall immediately become due, (ii) we may delete any of your User data without any
                          liability to you, provided that we also reserve the right to retain your User data for seven
                          (7) years following termination; and (iii) we may cancel any open transactions that are
                          pending at the time of the termination of your right to access all Services or discontinuation
                          of the Services. We reserve the right to retain aggregated anonymized User data for longer to
                          improve Services, prevent fraud and produce aggregated statistics.
                        </li>

                        <li className="terms-list-text pl-1">
                          The provisions of these Terms relating to indemnification, our limitations of liability and
                          disclaimers, your warranties and any other provisions which by their terms should reasonably
                          survive termination, shall survive termination of the Services or this Agreement.{" "}
                        </li>
                      </ol>
                    </Typography>
                  </Typography>
                </li>
                <br />
                <li>
                  <Typography className="label-terms flex justify-center">
                    <Typography className="terms-list-items">
                      <b>Disclaimers.</b> Edge is not a law firm, and is not permitted to engage in the practice of law.
                      Edge employees do not act as your attorney or otherwise provide legal advice to you. Any
                      templates, forms, and other sample documents available to you on Edge are made available to you
                      for informational purposes only and are not a substitute for the advice of an attorney, and may
                      not be relied upon by you in any manner whatsoever with regard to the legality or sufficiency of
                      such materials for your situation or needs.
                      <br /> <br />
                      Your use any portion of the Services does not create an attorney-client relationship with us. You
                      understand and agree that you or your attorneys or advisors represent you in any legal matter you
                      undertake related in any manner to any template, form or any other document you obtain through the
                      Edge Platform. Accordingly, while communications between you and Edge are protected by our Privacy
                      Policy, they are not protected by any attorney-client privilege or attorney work product doctrine.
                      Edge is prohibited from providing any kind of advice, explanation, opinion, or recommendation to
                      you about possible legal rights, remedies, defenses, options, selection of forms or strategies.
                      <br /> <br />
                      Any information we provide is comprised of a compilation of frequently encountered legal and
                      compliance issues generally applicable to engagements between Employees and Customers, and is not
                      intended to be comprehensive of matters specific to your circumstances. At no time do we review
                      your contracts, templates, forms, or other documents or the information you input for legal
                      sufficiency, draw legal conclusions, provide legal advice or apply the law to the facts of your
                      particular situation or needs.
                      <br /> <br />
                      Edge and the Services are not a substitute for the advice of an attorney. Although Edge takes
                      every reasonable effort to ensure that the information and documents on the Edge Platform are
                      up-to-date and reflect our best understanding of compliance matters related to engagements with
                      independent contractors, the information on the Edge Platform is not legal advice and is not
                      guaranteed to be correct, complete or up-to-date. Because the law changes rapidly, varies from
                      jurisdiction to jurisdiction, and is also subject to varying interpretations by different courts
                      and certain government and administrative bodies, Edge cannot guarantee that all the information
                      on the Edge Platform is accurate, up-to-date, complete or sufficient for your specific legal or
                      compliance needs.
                    </Typography>
                  </Typography>
                </li>
                <br />
                <li>
                  <Typography className="label-terms flex justify-center">
                    <Typography className="terms-list-items">
                      <b>Separate Agreement.</b> From time to time, we may enter into separate agreements to govern your
                      use of the Services. Where the terms of such separate agreement(s) conflict with these Terms,
                      those terms will prevail, but only to the extent they conflict.
                    </Typography>
                  </Typography>
                </li>
                <br />
                <li>
                  <Typography className="label-terms flex justify-center">
                    <Typography className="terms-list-items">
                      <b>Notices.</b> We may provide you with notices in any of the following methods: (1) via the
                      Services, including by a banner or pop-up within the applicable Edge Platform, account or
                      elsewhere; (2) by e-mail, sent to the e-mail address you provided us; and/or (3) through any other
                      means, including any phone number, SMS or text message or physical address you provided us. Such
                      notice to you will be deemed received and effective upon receipt or twenty-four (24) hours after
                      it was published or sent through any of the foregoing methods, unless otherwise indicated in the
                      notice. If we choose to notify you through electronic communication, you agree to receive such
                      communications, including but not limited to our applicable policies, statements, reports,
                      invoices, receipts, disclosures, legal or regulatory statements or documents via electronic
                      delivery.
                    </Typography>
                  </Typography>
                </li>
                <br />
                <li>
                  <Typography className="label-terms flex justify-center">
                    <Typography className="terms-list-items">
                      <b>Binding Arbitration.</b>
                      <span className="terms-list-text2">
                        {" "}
                        PLEASE READ THIS SECTION CAREFULLY, AS IT LIMITS THE MANNER IN WHICH YOU MAY SEEK RELIEF, AND
                        REQUIRES YOU ARBITRATE DISPUTES WITH US. If you have a dispute with us, we will first seek to
                        resolve such a dispute through our support team.
                      </span>
                      <br /> <br />
                      Except for disputes in which either party seeks equitable and other relief for the alleged
                      unlawful use of intellectual property, copyrights, trademarks, trade names, logos, trade secrets
                      or patents, any dispute, claim or controversy arising out of or relating to this Agreement or the
                      breach, termination, enforcement, interpretation or validity thereof, including the determination
                      of the scope or applicability of this Agreement to arbitrate, will be determined by binding
                      arbitration. The location of the arbitration will be determined by Edge to be in either (i) the
                      state of your residence or (ii) the county and state of our chosen legal counsel at the time of
                      the dispute. The arbitration will be administered by the American Arbitration Association (AAA) in
                      accordance with its Commercial Arbitration Rules. Disputes will not be resolved in any other forum
                      or venue. You agree that any arbitration will be conducted by a sole arbitrator. Pre-arbitration
                      discovery will be limited to the greatest extent provided by the rules of AAA, the arbitration
                      award will not include factual findings or conclusions of law, and no punitive damages will be
                      awarded. Notwithstanding any other rules, no arbitration proceeding brought against Edge will be
                      consolidated with any other arbitration proceeding without Edge’s written consent. Judgment may be
                      entered upon any award granted in any arbitration in any court of competent jurisdiction in the
                      county and state in which Edge maintains its principal office at the time the award is rendered,
                      in the county and state where Edge is organized or incorporated or in any other court having
                      jurisdiction. The arbitrator will, in the award, allocate all of the costs of the arbitration,
                      including the fees of the arbitrator and the reasonable attorneys’ fees of the prevailing party,
                      against the party who did not prevail.
                      <br /> <br />
                      <span className="terms-list-text2">
                        NOTICE: BOTH YOU AND EDGE ARE AGREEING TO HAVE ALL DISPUTES, CLAIMS OR CONTROVERSIES ARISING OUT
                        OF OR RELATING TO THIS AGREEMENT DECIDED BY NEUTRAL BINDING ARBITRATION AND ARE GIVING UP ANY
                        RIGHTS IT MIGHT POSSESS TO HAVE THOSE MATTERS LITIGATED IN A COURT OR JURY TRIAL. BY AGREEING TO
                        THIS ARBITRATION AGREEMENT, BOTH YOU AND EDGE ARE GIVING UP THEIR RESPECTIVE JUDICIAL RIGHTS TO
                        DISCOVERY AND APPEAL EXCEPT TO THE EXTENT THAT THEY ARE SPECIFICALLY PROVIDED FOR UNDER THIS
                        AGREEMENT. BY AGREEING TO ARBITRATION, YOU ALSO GIVE UP YOUR RIGHT TO PARTICIPATE IN A CLASS
                        ACTION OR OTHER CLASS PROCEEDING. IF ANY PARTY REFUSES TO SUBMIT TO ARBITRATION AFTER AGREEING
                        TO THIS PROVISION, THAT PARTY MAY BE COMPELLED TO ARBITRATE UNDER FEDERAL OR STATE LAW. THIS
                        ARBITRATION AGREEMENT EVIDENCES A TRANSACTION IN INTERSTATE COMMERCE, AND THUS TO THE EXTENT
                        APPLICABLE, THE U.S. FEDERAL ARBITRATION ACT GOVERNS THE INTERPRETATION AND ENFORCEMENT OF THIS
                        PROVISION. BOTH YOU AND EDGE CONFIRM THEIR AGREEMENT TO THIS ARBITRATION PROVISION IS VOLUNTARY.
                      </span>
                    </Typography>
                  </Typography>
                </li>
                <br />
                <li>
                  <Typography className="label-terms flex justify-center">
                    <Typography className="terms-list-items">
                      <b>Governing law.</b> This Agreement shall be governed pursuant to the laws of the State of
                      California, USA, without regard to principles of conflict of laws. you agree that Edge may
                      initiate a proceeding related to the enforcement or validity of Edge’s intellectual property
                      rights in any court having jurisdiction. With respect to any proceeding that is not subject to
                      arbitration under this Agreement or policies incorporated herein, you agree that any proceedings
                      shall be in the federal or state courts located in Washington. You waive any objection to venue
                      and to submit to personal jurisdiction in any such courts.
                    </Typography>
                  </Typography>
                </li>
                <br />
                <li>
                  <Typography className="label-terms flex justify-center">
                    <Typography className="terms-list-items">
                      <b>Interpretation.</b> The division of this Agreement into sections and the insertion of headings
                      are for convenience of reference only and shall not affect the construction or interpretation of
                      this Agreement. In this Agreement, words importing the singular number include the plural and vice
                      versa; words importing gender include all genders; and words importing persons include
                      individuals, sole proprietors, partnerships, corporations, trusts and unincorporated associations.
                    </Typography>
                  </Typography>
                </li>
                <br />
                <li>
                  <Typography className="label-terms flex justify-center">
                    <Typography className="terms-list-items">
                      <b>Entire Agreement.</b> This Agreement as it may be amended from time to time in accordance with
                      these Terms, and all other legal notices and policies on this website, constitutes the entire
                      agreement between you and the Company with respect to the use of the Services.
                    </Typography>
                  </Typography>
                </li>
                <br />
                <li>
                  <Typography className="label-terms flex justify-center">
                    <Typography className="terms-list-items">
                      <b>Amendment and Waiver.</b> The Company reserves the right, in its discretion, to amend this
                      Agreement at any time by posting amendments on this website You are responsible for periodically
                      reviewing the amendments on this website, and you are deemed to be aware of such amendments. If
                      you do not agree to the amended terms and conditions, you shall immediately stop using this
                      website. Access to this website or use of the Edge Platform after any amendments have been posted
                      shall constitute your acknowledgement and acceptance of the amended terms and conditions. No
                      supplement, modification or amendment to this Agreement and no waiver of any provision of this
                      Agreement shall be binding on the Company unless executed by the Company in writing. No waiver of
                      any of the provisions of this Agreement shall be deemed to be or shall constitute a waiver of any
                      other provision (whether or not similar) nor shall such waiver constitute a continuing waiver
                      unless otherwise expressly provided.
                    </Typography>
                  </Typography>
                </li>
                <br />
                <li>
                  <Typography className="label-terms flex justify-center">
                    <Typography className="terms-list-items">
                      <b>Severability.</b> Any provision of this Agreement which is held by a court of competent
                      jurisdiction to be illegal, invalid or unenforceable in such jurisdiction shall, as to that
                      jurisdiction, be ineffective to the extent of such illegality, invalidity or unenforceability and
                      shall otherwise be enforced to the maximum extent permitted by law, all without affecting the
                      remaining provisions of this Agreement or affecting the legality, validity or enforceability of
                      such provision in any other jurisdiction.
                    </Typography>
                  </Typography>
                </li>
                <br />
                <li>
                  <Typography className="label-terms flex justify-center">
                    <Typography className="terms-list-items">
                      <b>Inurement.</b> This Agreement shall inure to the benefit of and be binding upon each of us and
                      our respective successors and permitted assigns. You acknowledge having read this Agreement before
                      accepting it, having the authority to accept this Agreement and having received a copy of this
                      Agreement.
                    </Typography>
                  </Typography>
                </li>
                <br />
                <li>
                  <Typography className="label-terms flex justify-center">
                    <Typography className="terms-list-items">
                      <b>Assignment.</b> You shall not assign or transfer any of your rights or obligations under this
                      Agreement without our prior written consent, which we may withhold at our sole and absolute
                      discretion. Any actual or attempted assignment hereof by you contrary to the terms of this
                      Agreement shall be null and void. We may, at our discretion, assign some or all our rights or
                      obligations to a third party, without your consent or approval. If we are acquired by, sold to, or
                      merged with a third-party entity, we reserve the right to transfer or assign all your User data as
                      part of such merger, acquisition, sale, or change of control.
                    </Typography>
                  </Typography>
                </li>
                <br />
                <li>
                  <Typography className="label-terms flex">
                    <Typography className="terms-list-items">
                      <b>Support.</b> Please direct questions, complaints, or issues to{" "}
                      <a href="mailto:edgetos@onedge.co" className="link-class">
                        edgetos@onedge.co.
                      </a>
                    </Typography>
                  </Typography>
                </li>
                <br />
              </ol>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default Terms;
