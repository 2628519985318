import React, { useState, useEffect } from "react";

import { useForm, FormProvider } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { format } from "date-fns";

import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import PercentIcon from "@mui/icons-material/Percent";

import Button from "components/common/Button";
import Text from "components/common/Text";
import DatePicker from "components/common/DatePicker";
import TextCheckBox from "components/common/TextCheckBox";

import SalaryDetailsSchema from "validations/BambooHRTabs/SalaryDetailsSchema";

function AddEditSalaryDetails({ onSave, onCancel, initialData = {}, employeeStatus }) {
  const [discounted, setDiscounted] = useState(initialData.discounted || false);

  const methods = useForm({
    resolver: yupResolver(SalaryDetailsSchema),
    mode: "onChange",
    context: { employeeStatus },
    defaultValues: {
      costToCustomer: initialData.costToCustomer || "",
      grossSalary: initialData.grossSalary || "",
      additionalDeduction: initialData.additionalDeduction || "",
      discounted: initialData.discounted || false,
      percentage: initialData.percentage || "",
      flatAmount: initialData.flatAmount || "",
      startDate: initialData.startDate ? new Date(initialData.startDate) : null,
      duration: initialData.duration || "",
    },
  });

  const {
    watch,
    setValue,
    clearErrors,
    formState: { isValid },
  } = methods;

  const costToCustomer = watch("costToCustomer");
  const grossSalary = watch("grossSalary");
  const additionalDeduction = watch("additionalDeduction");
  const percentage = watch("percentage");
  const flatAmount = watch("flatAmount");

  useEffect(() => {
    if (costToCustomer && grossSalary && Number(costToCustomer) > 0 && Number(grossSalary) > 0) {
      const netSalary = Number(grossSalary) - Number(additionalDeduction || 0);
      const platformFee = Number(costToCustomer) - Number(grossSalary);

      setValue("netSalary", netSalary.toFixed(2));
      setValue("platformFee", platformFee.toFixed(2));
    } else {
      setValue("netSalary", "");
      setValue("platformFee", "");
    }
  }, [costToCustomer, grossSalary, additionalDeduction, discounted, percentage, flatAmount, setValue]);

  useEffect(() => {
    if (percentage) {
      setValue("flatAmount", "");
      clearErrors("flatAmount");
    }
  }, [percentage, setValue, clearErrors]);

  useEffect(() => {
    if (flatAmount) {
      setValue("percentage", "");
      clearErrors("percentage");
    }
  }, [flatAmount, setValue, clearErrors]);

  const onSubmit = (data) => {
    const filteredData = Object.fromEntries(
      Object.entries(data).filter(([key, value]) => {
        if (key === "grossSalary" || key === "costToCustomer") {
          return value !== 0 && value !== "0" && value !== "";
        }
        return true;
      })
    );

    if (filteredData.startDate instanceof Date && !Number.isNaN(filteredData.startDate)) {
      const updatedStartDate = new Date(filteredData.startDate.getFullYear(), filteredData.startDate.getMonth(), 1);

      filteredData.startDate = format(updatedStartDate, "yyyy-MM-dd");
    }

    onSave(filteredData);
  };

  return (
    <FormProvider {...methods}>
      <form className="flex flex-col gap-1 mt-15" onSubmit={methods.handleSubmit(onSubmit)}>
        <div className="form-row two-columns">
          <Text
            name="costToCustomer"
            label="Cost to customer"
            type="number"
            minValue={0}
            startIcon={<AttachMoneyIcon className="money-icon" />}
          />
          <Text
            name="grossSalary"
            label="Gross pay"
            type="number"
            minValue={0}
            startIcon={<AttachMoneyIcon className="money-icon" />}
          />
        </div>
        <div className="form-row two-columns">
          <Text
            name="platformFee"
            label="Platform fee"
            minValue={0}
            disabled
            type="number"
            startIcon={<AttachMoneyIcon className="money-icon" />}
            readOnly
          />
          <Text
            name="additionalDeduction"
            label="Deduction (optional)"
            type="number"
            minValue={0}
            startIcon={<AttachMoneyIcon className="money-icon" />}
          />
        </div>
        <div className="form-row two-columns">
          <TextCheckBox
            name="discounted"
            text="Apply discount"
            toolTipContent="You can only apply discount if cost to customer is added"
            onChange={(e) => {
              if (!e.target.checked) {
                methods.setValue("percentage", "");
                methods.setValue("flatAmount", "");
                methods.setValue("startDate", null);
                methods.setValue("duration", "");
                clearErrors(["percentage", "flatAmount", "startDate", "duration"]);
              }
              setDiscounted(e.target.checked);
              methods.setValue("discounted", e.target.checked);
              clearErrors(["percentage", "flatAmount", "startDate", "duration"]);
            }}
            checked={discounted}
            disabled={!costToCustomer || Number(costToCustomer) <= 0}
          />
        </div>

        {discounted && (
          <div className="flex flex-col gap-1">
            <div className="form-row two-columns">
              <Text
                name="percentage"
                label="Percentage discount"
                type="number"
                endAdornment="%"
                disabled={!!flatAmount}
                minValue={0}
                startIcon={<PercentIcon className="money-icon" />}
              />
              <Text
                name="flatAmount"
                label="Flat discount"
                type="number"
                startAdornment="$"
                disabled={!!percentage}
                minValue={0}
                startIcon={<AttachMoneyIcon className="money-icon" />}
              />
            </div>
            <div className="form-row two-columns">
              <DatePicker name="startDate" label="Discount starts from" monthYearOnly />
              <Text name="duration" label="Duration (months)" type="number" minValue={0} />
            </div>
          </div>
        )}

        <Text disabled name="netSalary" label="Net pay" type="number" startAdornment="$" readOnly />

        <div className="form-actions">
          <Button variant="outlined" size="large" label="Cancel" onClick={onCancel} />
          <Button
            variant="contained"
            size="large"
            label="Save"
            disabled={!isValid}
            color={!isValid && "disabled"}
            type="submit"
          />
        </div>
      </form>
    </FormProvider>
  );
}

export default AddEditSalaryDetails;
