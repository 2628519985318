import { call, put, takeEvery } from "redux-saga/effects";

import { LeaveRequestsSucess, LeaveRequestsFailure } from "store/actions/Timeoff/LeaveRequests";

import { LEAVE_REQUESTS } from "store/constants/Timeoff";

import getRequest from "util/APIHelperGet";
import { baseUrl } from "util/APIBaseUrl";

export const fetcheLeavesRequests = async (actions) => {
  const { userId, type: userType, customerId } = JSON.parse(localStorage.getItem("loggedInUser"));

  return await getRequest(
    `${baseUrl}/${userType === "customer" ? "customer" : "employee"}/${
      userType === "customer" ? customerId : userId
    }/leave`
  )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

function* fetchLeaveRequests(params) {
  try {
    let response = yield call(fetcheLeavesRequests, params);
    yield put(LeaveRequestsSucess(response));
  } catch (error) {
    console.error(error);
    yield put(LeaveRequestsFailure(error));
  }
}

export default function* rootSaga() {
  yield takeEvery(LEAVE_REQUESTS, fetchLeaveRequests);
}
