import React from "react";
import { Box, CardMedia, Grid, Typography } from "@mui/material";
import notificationImg from "../../../../assets/images/notifications-image.svg";

const NotificationMessage = ({ message, label }) => {
  const extractTextFromHTML = (htmlString) => {
    if (htmlString) {
      const tempElement = document.createElement("div");
      tempElement.innerHTML = htmlString;
      return tempElement.textContent || tempElement.innerText;
    }
    return "";
  };
  return (
    <Grid
      sx={{
        padding: "4px 16px",
        display: "flex",
        alignItems: "start",
        gap: "14px",
      }}
      id="notification-message"
    >
      <img src={notificationImg} width="42px" height="42px" sx={{ width: "42px", height: "42px" }} />
      <Box sx={{ display: "flex", flexDirection: "column", gap: "8px" }}>
        <Typography
          sx={{
            fontFamily: "Poppins-Regular",
            fontWeight: 400,
            fontSize: "12px",
            lineHeight: "19px",
            color: "#14151F",
          }}
        >
          {extractTextFromHTML(message)}
        </Typography>
        <Typography
          variant="subtitle2"
          sx={{
            fontFamily: "Poppins-Regular",
            fontWeight: 400,
            fontSize: "10px",
            lineHeight: "15px",
            color: "#918F9A",
          }}
        >
          {label}
        </Typography>
      </Box>
    </Grid>
  );
};

export default NotificationMessage;
