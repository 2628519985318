//employee constants
import {
  GET_PLATFORM_CONFIG,
  GET_PLATFORM_CONFIG_SUCCESS,
  GET_PLATFORM_CONFIG_FAILURE,
} from "../../constants/PlatformConfig";

const INIT_STATE = {
  platformConfig: {},
  loadingPlatformConfig: false,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_PLATFORM_CONFIG:
      return {
        ...state,
        loadingPlatformConfig: true,
      };
    case GET_PLATFORM_CONFIG_SUCCESS:
      if(action?.payload?.ok)
      localStorage.setItem("TIMEOFF_ALLOWED_COUNTRIES",action?.payload?.payload?.TIMEOFF_ALLOWED_COUNTRIES)
      return {
        ...state,
        loadingPlatformConfig: false,
        platformConfig: action.payload,
      };
    // eslint-disable-next-line no-fallthrough
    case GET_PLATFORM_CONFIG_FAILURE:
      return {
        ...state,
        loadingPlatformConfig: false,
        platformConfig: "Error in  getting Club Data",
      };
    default:
      return state;
  }
};
