import React, { useState } from "react";

import PayrollHistoryEmployeeTable from "./PayrollHistoryEmployeeTable";
import PageLoader from "components/common/PageLoader";

import ChevronIcon from "assets/images/chevron-bottom.svg";

import "./PayrollHistoryEmployeeBreakdown.css";

const PayrollHistoryEmployeeBreakdown = ({ employeesBreakdown = [] }) => {
  const [isTableVisible, setIsTaleVisible] = useState(true);

  const handleTableVisibility = () => {
    setIsTaleVisible((prevVal) => !prevVal);
  };

  return (
    <>
      {!employeesBreakdown.length && <PageLoader />}
      <div onClick={handleTableVisibility} className="pd-employee-breakdown">
        <p className="pd-employee-breakdown-heading">
          <p>Employee Breakdown</p>
          <img src={ChevronIcon} alt="chevron" className={`chevronIcon ${isTableVisible ? "rotated" : ""}`} />
        </p>

        <div className={`toggleDiv ${isTableVisible ? "visible" : ""}`}>
          <div className="pd-breakdown-table-head">
            <div className="pd-breakdown-table-head-name">Employee Name</div>

            <div className="pd-breakdown-table-head-value">Salary</div>

            <div className="pd-breakdown-table-head-value">Bonuses</div>

            <div className="pd-breakdown-table-head-total">Total</div>
          </div>

          <div className="pd-breakdown-table-body">
            {employeesBreakdown.map((employee, index) => (
              <div
                className="pd-breakdown-table-name-container"
                style={{ background: index % 2 === 0 ? "#FBFAFF" : "#FFF" }}
              >
                <PayrollHistoryEmployeeTable employee={employee} />
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default PayrollHistoryEmployeeBreakdown;
