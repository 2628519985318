import React, { useState } from "react";

import { useForm, FormProvider } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import Text from "components/common/Text";
import PhoneInput from "components/common/PhoneInput";
import Button from "components/common/Button";

import { editEmergencyContactSchema } from "validations/BambooHRTabs/ContactSchema";

import "./Contact.scss";
import SearchableAutoComplete from "components/common/AutoComplete";

function EditEmergencyContact({ onSave, initialData, onCancel, countryStates }) {
  const [isLoading, setIsLoading] = useState(false);
  const methods = useForm({
    resolver: yupResolver(editEmergencyContactSchema),
    defaultValues: initialData || {},
    mode: "onChange",
  });

  const {
    handleSubmit,
    formState: { errors, isValid },
    trigger,
  } = methods;

  const onSubmit = (data) => {
    if (isValid) {
      setIsLoading(true);
      onSave({
        ...data,
        email: data.email ? data.email : null,
        phoneNumber: data.phoneNumber ? data.phoneNumber : null,
      });
    }
  };

  const handleSaveClick = async () => {
    const result = await trigger();
    if (result) {
      handleSubmit(onSubmit)();
    }
  };
  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)} className="edit-emergency-contact-form">
        <div className="my-15">
          <div className="form-row mb-1 two-columns">
            <Text
              name="name"
              label="Name"
              error={!!errors.name}
              helperText={errors.name?.message}
              placeholder="eg: John Doe"
            />
            <Text
              name="relationship"
              label="Relationship"
              error={!!errors.relationship}
              helperText={errors.relationship?.message}
              placeholder="eg: Brother"
            />
          </div>
          <div className="form-row mb-1 two-columns">
            <PhoneInput
              name="phoneNumber"
              label="Phone number"
              error={!!errors.phoneNumber}
              helperText={errors.phoneNumber?.message}
              placeholder="Enter phone number"
              isOptional
            />
            <Text
              name="email"
              label="Email address"
              error={!!errors.email}
              helperText={errors.email?.message}
              isOptional
              placeholder="name@example.com"
            />
          </div>
          <div className="form-row mb-1 three-columns">
            <SearchableAutoComplete
              name="state"
              label="State"
              options={countryStates}
              placeholder="Select"
              error={!!errors.state}
              helperText={errors.state?.message}
            />
            <Text
              name="city"
              label="City"
              placeholder="eg: Middletown"
              error={!!errors.city}
              helperText={errors.city?.message}
            />
            <Text
              name="zipCode"
              label="ZIP code"
              placeholder="eg: 07701"
              error={!!errors.zipCode}
              helperText={errors.zipCode?.message}
            />
          </div>
          <div className="form-row mb-1">
            <Text
              name="addressLine1"
              label="Residential address"
              error={!!errors.addressLine1}
              helperText={errors.addressLine1?.message}
              placeholder="e.g. House 11, 350 East Main Road"
            />
          </div>
        </div>
        <div className="form-actions">
          <Button variant="outlined" size="large" label="Cancel" onClick={onCancel} className="mr-1" />
          <Button
            isLoading={isLoading}
            variant="contained"
            color={!isValid && "disabled"}
            size="large"
            label="Save"
            type="submit"
            onClick={handleSaveClick}
            disabled={!isValid}
          />
        </div>
      </form>
    </FormProvider>
  );
}

export default EditEmergencyContact;
