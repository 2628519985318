/* eslint-disable camelcase */
import { call, put, takeEvery } from "redux-saga/effects";
import { GetEmployeeListSuccess, GetEmployeeListFailure } from "../../actions/GetEmployeeList";
import { GET_EMPLOYEE_List } from "../../constants/GetEmployeeList";
import getRequest from "../../../util/APIHelperGet";
import { baseUrl } from "../../../util/APIBaseUrl";

export const fetchGetEmployeeListRequest = async (actions) => {
  return await getRequest(
    `${baseUrl}/employees?${new URLSearchParams({
      limit: actions.payload.rowsPerPage,
      page: actions.payload.page,
      orderBy: JSON.stringify({
        field: actions.payload?.sortBy?.field,
        direction: actions.payload?.sortBy?.direction,
      }),
      filter: JSON.stringify({
        name: [actions.payload?.searchEmployee ? actions.payload?.searchEmployee : ""],
        company: actions.payload?.dist?.customers,
        country: actions.payload?.dist?.countries,
        status: actions.payload?.dist?.status,
        salaryStatus: actions?.payload?.dist?.salaryStatus,
      }),
    })}`
  )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

function* fetchGetEmployeeList(params) {
  try {
    const response = yield call(fetchGetEmployeeListRequest, params);

    yield put(GetEmployeeListSuccess(response));
  } catch (error) {
    console.error(error);
    yield put(GetEmployeeListFailure(error));
  }
}

export default function* rootSaga() {
  yield takeEvery(GET_EMPLOYEE_List, fetchGetEmployeeList);
}
