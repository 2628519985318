import { Chip, Divider, Stack, TextField, Typography, Button, IconButton, Dialog } from "@mui/material";
import Modal from "components/common/Modal";
import CustomDropdown from "components/common/Dropdown";
import NegitiveIcon from "./EmployeeTimeOffDetails/Icons/NegitiveIcon";
import PositiveIcon from "./EmployeeTimeOffDetails/Icons/PositiveIcon";
import { leavesTypesDropdown } from "./Constants";
import { useEffect, useState } from "react";
import Daterange from "components/common/Daterange";
import ArrowIcons from "./EmployeeTimeOffDetails/Icons/ArrowIcons";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { addEmployeeNewLeaveRequest, getTotalLeaveCount } from "./TimeOffAdminServices/EmployeeTimeOffDetailsService";
import { showSnackbar } from "store/actions/Utility";
// import LoadingButton from "@mui/lab/LoadingButton";

const dropdownStyles = {
  borderRadius: "33px",
  border: "1px solid var(--Primary-90, #E0E0FF)",
  // background: "var(--Primary-99, #FBFAFF)",
  minWidth: "auto",
  display: "flex",
  fontFamily: "Poppins-Medium",
  fontWeight: 600,
  // flex:"1"
};

const EditLeavesTaken = ({ open, closeModal, employeeLeavesData, onSuccess, allLeavesAndHolidaysData }) => {
  const dispatch = useDispatch();

  const [leaveTypeDropdownOptions, setLeaveTypeDropdownOptions] = useState([]);
  const [addNewLeaveRequestLoader, setAddNewLeaveRequestLoader] = useState([]);
  const [totalLeaveCount, setTotalLeaveCount] = useState(0);
  const [addLeaveRequest, setAddLeaveRequest] = useState({
    startDate: moment(),
    endDate: moment(),
    leaveTypeId: 1,
    reason: null,
  });
  const [selectedLeaveDetails, setSelectedLeaveDetails] = useState(null);

  const getEmployeeDetails = useSelector((state) => state.GetEmployeeDetails.getEmployeeDetails);

  const addNewLeaveRequest = async () => {
    setAddNewLeaveRequestLoader(true);

    try {
      const payload = {
        dateFrom: moment(addLeaveRequest?.startDate).format("YYYY-MM-DD"),
        dateTo: moment(addLeaveRequest?.endDate).format("YYYY-MM-DD"),
        noOfLeaves: totalLeaveCount,
        description: addLeaveRequest?.reason,
        customerId: getEmployeeDetails?.payload?.customerId,
        employeeId: getEmployeeDetails?.payload?.employeeId,
        leaveTypeId: selectedLeaveDetails?.leaveTypeId,
      };
      const res = await addEmployeeNewLeaveRequest(payload);

      if (res?.success) {
        setAddNewLeaveRequestLoader(false);
        closeModal();
        dispatch(showSnackbar({ dispatch, type: "success", message: "Leave Created Successfully!" }));
        if (onSuccess) onSuccess();
      } else {
        dispatch(showSnackbar({ dispatch, type: "error", message: res?.message }));
      }
      // setEmployeeLeavesData(res?.data);
    } catch (error) {
      setAddNewLeaveRequestLoader(false);
    }
  };

  const fetchTotalLeaveCount = async () => {
    try {
      const startDate = moment(addLeaveRequest?.startDate).format("YYYY-MM-DD");
      const endDate = moment(addLeaveRequest?.endDate).format("YYYY-MM-DD");

      const res = await getTotalLeaveCount(
        getEmployeeDetails?.payload?.employeeId,
        addLeaveRequest?.leaveTypeId,
        startDate,
        endDate
      );

      if (res?.success) {
        setTotalLeaveCount(res?.leaves);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getBusinessDays = (startDate, endDate) => {
    let currentDate = moment(startDate);
    const lastDate = moment(endDate);
    let businessDays = 0;

    while (currentDate.isSameOrBefore(lastDate)) {
      if (currentDate.day() !== 0 && currentDate.day() !== 6) {
        businessDays++;
      }
      currentDate.add(1, "days");
    }

    return businessDays;
  };

  const getSelectedLeaveDetails = (leaveTypeId) => {
    return employeeLeavesData?.find((leave) => leave?.leaveTypeId === leaveTypeId) || null;
  };

  useEffect(() => {
    if (addLeaveRequest?.leaveTypeId) {
      setSelectedLeaveDetails(getSelectedLeaveDetails(addLeaveRequest?.leaveTypeId));
    }
  }, [addLeaveRequest?.leaveTypeId]);

  useEffect(() => {
    fetchTotalLeaveCount();
  }, [addLeaveRequest?.startDate, addLeaveRequest?.endDate, addLeaveRequest?.leaveTypeId]);

  useEffect(() => {
    const options = employeeLeavesData?.map((leave) => ({
      key: leave?.leaveTypeId,
      label: leave?.leaveType,
    }));
    setAddLeaveRequest({
      ...addLeaveRequest,
      leaveTypeId: options?.[0]?.key,
    });

    setLeaveTypeDropdownOptions(options);
  }, [employeeLeavesData]);

  return (
    <Dialog open={open} sx={{ "& .MuiDialog-paper": { minWidth: "710px", borderRadius: "12px !important" } }}>
      <div className="request-new-leave-title" style={{ paddingLeft: "27px" }}>
        Add a request on {getEmployeeDetails?.payload?.firstName} {getEmployeeDetails?.payload?.lastName} behalf
      </div>

      <Divider sx={{ border: "1px solid #E0E0FF", mt: "10px", mb: "13px" }} />
      <Stack direction="row" spacing="26px" alignItems="center">
        <div>
          <div className="leave-taken-select-type" style={{ paddingLeft: "26px", marginTop: "0px" }}>
            Select Type
          </div>
          <div style={{ marginLeft: "26px" }}>
            {/* <CustomDropdown options={years} onSelect={() => {}} buttonLabel="Year:" /> */}
            <CustomDropdown
              options={leaveTypeDropdownOptions}
              style={{ ...dropdownStyles, width: "228px", fontSize: "14px" }}
              onSelect={(key) => {
                setAddLeaveRequest({
                  ...addLeaveRequest,
                  leaveTypeId: key,
                });
              }}
              buttonLabel={
                leaveTypeDropdownOptions?.find((leave) => leave?.key === addLeaveRequest?.leaveTypeId)?.label || ""
              }
            />
          </div>
        </div>

        <div>
          <div className="leave-taken-select-type" style={{ marginTop: "0px", paddingLeft: "0px" }}>
            Allowed time-off YTD
          </div>

          <div className="allowed-timeoff">
            {!selectedLeaveDetails?.isFinite
              ? "Unlimited"
              : selectedLeaveDetails?.noOfLeaves - selectedLeaveDetails?.scheduled - selectedLeaveDetails?.taken}
            {/* {selectedLeaveDetails?.noOfLeaves > 0 ? `/${selectedLeaveDetails?.noOfLeaves}` : ""} */}
          </div>
        </div>
      </Stack>
      <Divider sx={{ border: "1px solid #D9DAF2", my: "13px" }} />

      <Stack sx={{ px: "26px" }}>
        <Daterange
          onChange={(selectedDate) => {
            setAddLeaveRequest({
              ...addLeaveRequest,
              startDate: selectedDate?.startDate,
              endDate: selectedDate?.endDate,
            });
          }}
          leavesCalender={allLeavesAndHolidaysData?.leaves || []}
          holidaysCalender={allLeavesAndHolidaysData?.holidays || []}
          disabledFutureDates
        />
        <Stack direction="row" justifyContent="space-between" alignItems="center">
          <div className="request-timeoff-indicators-container ml-1" style={{ paddingBottom: "0px" }}>
            <div className="request-timeoff-indicator-box-label">
              <span className="request-timeoff-today-box-indicator"></span>
              <span className="title-sm">Today</span>
            </div>

            <div className="request-timeoff-indicator-box-label">
              <span className="request-timeoff-selected-box-indicator"></span>
              <span className="title-sm">Selected</span>
            </div>
          </div>

          <div className="flex gap-05 ml-1 mt-0" style={{ marginBottom: "0px !important" }}>
            <span className="align-self-center calendar-dot-indicator-orange" />

            <p className="body-md align-self-center">Leaves</p>

            <span className="align-self-center calendar-dot-indicator-green" />

            <p className="body-md align-self-center">Holidays</p>
          </div>
        </Stack>
      </Stack>

      <Divider sx={{ border: "1px solid #E0E0FF", my: "13px" }} />

      <Stack spacing="14px" sx={{ pl: "26px" }}>
        <div className="selected-timeoff-text">Selected</div>
        <div className="req-total-no-of-days">
          {/* {getBusinessDays(addLeaveRequest?.startDate, addLeaveRequest?.endDate)}{" "} */}
          {totalLeaveCount >= 0 ? totalLeaveCount : ""}{" "}
          {leaveTypeDropdownOptions?.find((leave) => leave?.key === addLeaveRequest?.leaveTypeId)?.label || ""} leaves
          (Excluding weekends & holidays){" "}
        </div>
      </Stack>

      <Stack direction="row" spacing="13px" sx={{ pl: "26px", mt: "5px" }}>
        <div className="req-leave-date-from">
          {" "}
          {addLeaveRequest?.startDate ? moment(addLeaveRequest?.startDate).format("MMM DD, YYYY") : "-"}{" "}
        </div>
        <ArrowIcons />
        <div className="req-leave-date-from">
          {" "}
          {addLeaveRequest?.endDate ? moment(addLeaveRequest?.endDate).format("MMM DD, YYYY") : "-"}{" "}
        </div>
      </Stack>

      {totalLeaveCount < 1 && (
        <div className="selected-timeoff-text" style={{ color: "red", paddingLeft: "26px", paddingTop: "5px" }}>
          Note :{" "}
          {selectedLeaveDetails?.noOfLeaves - selectedLeaveDetails?.scheduled - selectedLeaveDetails?.taken <= 0
            ? `You have already taken the maximum allowed  ${
                leaveTypeDropdownOptions?.find((leave) => leave?.key === addLeaveRequest?.leaveTypeId)?.label || ""
              } leaves `
            : `You are unable to apply for leave on the selected dates.`}
        </div>
      )}

      <Divider sx={{ border: "1px solid #E0E0FF", mt: "18px", mb: "13px" }} />

      <Stack sx={{ pl: "26px" }} spacing="13px">
        <div className="req-timeoff-reason">Enter note</div>

        <textarea
          row={3}
          className="req-leave-taken-reson"
          value={addLeaveRequest?.reason}
          onChange={(e) =>
            setAddLeaveRequest({
              ...addLeaveRequest,
              reason: e.target.value,
            })
          }
        />

        <div
          className="selected-timeoff-text"
          style={{ color: "#BA1A1A", marginTop: "7px", marginLeft: "5px", lineHeight: "19px", fontSize: "11px" }}
        >
          Add a reason behind the edits to save changes
        </div>
      </Stack>

      <Stack direction="row" spacing="13px" sx={{ ml: "26px", mt: "26px", mb: "29px" }}>
        <Button
          variant="outlined"
          sx={{
            borderRadius: "42px",
            height: "38px",
            width: "99px",
            border: "1px solid #3D3E5C",
          }}
          onClick={() => closeModal()}
        >
          <Typography variant="h5" color="#3D43BB">
            Cancel
          </Typography>
        </Button>

        <Button
          variant="contained"
          // loading={addNewLeaveRequestLoader}
          disableElevation
          sx={{
            borderRadius: "42px",
            width: "106px",
            height: "38px",
            background: "#131599",
          }}
          onClick={() => addNewLeaveRequest()}
          disabled={
            (!(selectedLeaveDetails?.noOfLeaves - selectedLeaveDetails?.scheduled - selectedLeaveDetails?.taken > 0) &&
              selectedLeaveDetails?.isFinite) ||
            totalLeaveCount < 1 ||
            !addLeaveRequest?.reason ||
            !(addLeaveRequest?.reason?.trim() !== "")
          }
        >
          <Typography variant="h6" color="white">
            Confirm
          </Typography>
        </Button>
      </Stack>
    </Dialog>
  );
};

export default EditLeavesTaken;
