import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { AlertType } from "components/common/Snackbar";
import Modal from "components/common/Modal";
import Button from "components/common/Button";

import { getCustomerEmployeeList, assignEmployeeToCustomerUsers } from "services/customer";
import { showSnackbar } from "store/actions/Utility";

import AssignEmployeeModalForm from "components/Screens/SettingV2/AddUser/AssignEmployeeModal/AssignEmployeeModalForm";

function AssignEmployeeModal({ open, handleClose, customerUserId }) {
  const [loading, setLoading] = useState(false);
  const [addBtnDisable, setAddBtnDisable] = useState(true);
  const [selectedEmployeeIds, setSelectedEmployeeIds] = useState([]);
  const [employeeList, setEmployeeList] = useState([]);

  const userAdditionalInfo = useSelector((state) => state.UserAdditionalInfo.userAdditionalInfo);
  const dispatch = useDispatch();

  const assignEmployeeToCustomer = async () => {
    try {
      setLoading(true);
      await assignEmployeeToCustomerUsers(customerUserId, selectedEmployeeIds);
      handleClose(true);
      dispatch(
        showSnackbar({
          type: AlertType.success,
          message: `${selectedEmployeeIds.length} employee${selectedEmployeeIds.length === 1 ? "" : "s"} added successfully`,
          dispatch,
        })
      );
    } catch (error) {
      dispatch(
        showSnackbar({
          type: AlertType.error,
          message: error?.message,
          dispatch,
        })
      );
    } finally {
      setLoading(false);
    }
  };

  const getEmployeeList = useCallback(async () => {
    const employees = await getCustomerEmployeeList(userAdditionalInfo.customerId);
    setEmployeeList(employees);
  }, [userAdditionalInfo.customerId]);

  useEffect(() => {
    if (userAdditionalInfo?.customerId) {
      getEmployeeList();
    }
  }, [userAdditionalInfo.customerId, getEmployeeList]);

  return (
    <Modal open={open} onClose={handleClose} title="Add Employee(s)" width="552px">
      <div className="mt-1">
        <AssignEmployeeModalForm
          setAddBtnDisable={setAddBtnDisable}
          setSelectedEmployeeIds={setSelectedEmployeeIds}
          employeeList={employeeList}
        />
      </div>
      <div className="flex justify-end mt-1">
        <div className="mr-05">
          <Button onClick={handleClose} variant="text" label="Cancel" size="large" />
        </div>
        <Button
          isLoading={loading}
          data-testid="assign-employee"
          variant="contained"
          size="large"
          onClick={assignEmployeeToCustomer}
          isDisabled={addBtnDisable}
          label={
            addBtnDisable
              ? "Add Employee(s)"
              : `Add ${selectedEmployeeIds?.length} Employee${selectedEmployeeIds?.length > 1 ? "s" : ""}`
          }
        />
      </div>
    </Modal>
  );
}

export default AssignEmployeeModal;
