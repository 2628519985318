import React from "react";
//material ui
import { Grid, IconButton, Typography } from "@mui/material";

function gridFunction(
  data,
  iconButton,
  icon,
  fontsize,
  variant,
  textColor,
  alignItem,
  type
) {
  const CheckIcon = (icon) => {
    if (icon) {
      return icon;
    } else return null;
  };

  return (
    <Grid
      item
      container
      direction="row"
      alignItems="center"
      justifyContent={alignItem ? alignItem : null}
    >
      {type != "textOnly" ? (
        iconButton == true ? (
          <IconButton
            sx={{
              borderRadius: "11px",
            }}
            size="small"
          >
            {CheckIcon(icon)}
          </IconButton>
        ) : (
          CheckIcon(icon)
        )
      ) : null}
      <Typography
        variant={variant ? variant : "paragraph"}
        style={{
          color: textColor ? textColor : "#43425D",
          marginLeft: "8px",
          //fontWeight: "500",
          fontSize: fontsize ? fontsize : "11px",
        }}
      >
        {data}
      </Typography>
    </Grid>
  );
}

function EmailWithIcon(props) {
  const {
    data,
    iconButton,
    icon,
    fontsize,
    variant,
    textColor,
    alignItem,
    type,
  } = props;

  return (
    <>
      {data
        ? gridFunction(
            data,
            iconButton,
            icon,
            fontsize,
            variant,
            textColor,
            alignItem,
            type
          )
        : null}
    </>
  );
}

export default EmailWithIcon;
